import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtBusinessGroup implements From<RtBusinessGroup> {
    constructor(
        public businessGroup?: string,
        public businessGroupName?: string,
        public fleetId?: string,
        public groupId?: string,
        public contact?: string,
        public addr1?: string,
        public addr2?: string,
        public city?: string,
        public state?: string,
        public zip?: string,
        public tele?: string,
        public fax?: string,
        public kleinBolHeader?: string,
        public createBol?: string,
        public bolCount?: number,
        public hazmatSpillContact?: string,
        public hazmatSpillPhone?: string,
        public costCenter?: string,
        public deptId?: string,
        public raRepairsGlCode?: string,
        public raLeaseGlCode?: string,
    ) {}
    from(trip: RtBusinessGroup): RtBusinessGroup {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtBusinessGroup>('businessGroup'), server: 'businessGroup' },
    { client: nameof<RtBusinessGroup>('businessGroupName'), server: 'businessGroupName' },
    { client: nameof<RtBusinessGroup>('fleetId'), server: 'fleetId' },
    { client: nameof<RtBusinessGroup>('groupId'), server: 'groupId' },
    { client: nameof<RtBusinessGroup>('contact'), server: 'contact' },
    { client: nameof<RtBusinessGroup>('addr1'), server: 'addr1' },
    { client: nameof<RtBusinessGroup>('addr2'), server: 'addr2' },
    { client: nameof<RtBusinessGroup>('city'), server: 'city' },
    { client: nameof<RtBusinessGroup>('state'), server: 'state' },
    { client: nameof<RtBusinessGroup>('zip'), server: 'zip' },
    { client: nameof<RtBusinessGroup>('tele'), server: 'tele' },
    { client: nameof<RtBusinessGroup>('fax'), server: 'fax' },
    { client: nameof<RtBusinessGroup>('kleinBolHeader'), server: 'kleinBolHeader' },
    { client: nameof<RtBusinessGroup>('createBol'), server: 'createBol' },
    { client: nameof<RtBusinessGroup>('bolCount'), server: 'bolCount' },
    { client: nameof<RtBusinessGroup>('hazmatSpillContact'), server: 'hazmatSpillContact' },
    { client: nameof<RtBusinessGroup>('hazmatSpillPhone'), server: 'hazmatSpillPhone' },
    { client: nameof<RtBusinessGroup>('costCenter'), server: 'costCenter' },
    { client: nameof<RtBusinessGroup>('deptId'), server: 'deptId' },
    { client: nameof<RtBusinessGroup>('raRepairsGlCode'), server: 'raRepairsGlCode' },
    { client: nameof<RtBusinessGroup>('raLeaseGlCode'), server: 'raLeaseGlCode' },
];

@Injectable({
    providedIn: 'root',
})
export class RtBusinessGroupAdapter extends TwoWayAdapter<RtBusinessGroup> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtBusinessGroupMetadata implements FormGroupDescription {
    constructor() {}

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtBusinessGroup>('businessGroup'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('businessGroupName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('fleetId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('groupId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('contact'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('addr1'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('addr2'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('city'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('state'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('zip'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('tele'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('fax'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('kleinBolHeader'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('createBol'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('bolCount'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('hazmatSpillContact'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('hazmatSpillPhone'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('costCenter'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('deptId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('raRepairsGlCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtBusinessGroup>('raLeaseGlCode'), new UntypedFormControl(''));

        if (!!obj) {
            formGroup.setValue(obj);
        }

        return formGroup;
    }
}
