import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { faStopCircle } from '@fortawesome/pro-regular-svg-icons';
// import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FormErrorHandlerService } from '@bds/helpers';
import { nameof } from '@bds/core';
import { EquipReportCategory, EquipReportCatgEquip, EquipReportCatgValue } from '../../../models';

@Component({
    selector: 'bds-equip-report-category',
    templateUrl: './equip-report-category.component.html',
    styleUrls: ['./equip-report-category.component.scss'],
})
export class EquipReportCategoryComponent implements OnInit, OnChanges, OnDestroy {
    equipForm: UntypedFormGroup;
    filteredValues: EquipReportCatgValue[] = [];
    iconExpire = faStopCircle;
    iconCopy = faCopy;
    showInputs = false;

    private ngUnsubscribe$: Subject<void> = new Subject<void>();

    get dxMatStyle(): string {
        switch (this.matStyle) {
            case 'fill':
                return 'filled';
            case 'outline':
                return 'outlined';
            case 'standard':
                return 'underlined';
            default:
                return 'underlined';
        }
    }

    @Input() dense = true;
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';

    @Input() editable = false;
    @Input() equipCategories: EquipReportCategory[] = [];
    @Input() equipment: EquipReportCatgEquip;
    @Input() tripCloseDate: Date;
    @Input() values: EquipReportCatgValue[] = [];

    @Output() equipCategoryChange: EventEmitter<{ equip: EquipReportCatgEquip; valid: boolean }> =
        new EventEmitter<{ equip: EquipReportCatgEquip; valid: boolean }>();
    @Output() copyClick: EventEmitter<EquipReportCatgEquip> =
        new EventEmitter<EquipReportCatgEquip>();

    constructor(private formErrorService: FormErrorHandlerService) {
        this.equipForm = this.getFormGroup();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.onDateRangeInit = this.onDateRangeInit.bind(this);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.onDateRangeBlur = this.onDateRangeBlur.bind(this);
    }

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.equipForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((c) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.equipment = this.equipForm.value;
            this.equipCategoryChange.emit({ equip: this.equipment, valid: this.equipForm.valid });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.equipment && this.equipment) {
            this.equipForm.patchValue(this.equipment);

            if (
                (!this.filteredValues || this.filteredValues.length === 0) &&
                this.equipment &&
                this.equipment.equipReportCatgId
            ) {
                this.filteredValues = this.filterValuesByCat(this.equipment.equipReportCatgId);
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    filterValuesByCat(catId: number): EquipReportCatgValue[] {
        if (catId && this.values && this.values.length) {
            return this.values.filter((val) => val.equipReportCatgId === catId);
        }
        return [];
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        return this.formErrorService.getFormError(formItem);
    }

    getFormGroup(): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<EquipReportCatgEquip>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<EquipReportCatgEquip>('equipmentId'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<EquipReportCatgEquip>('equipReportCatgId'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<EquipReportCatgEquip>('equipReportCatgValId'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<EquipReportCatgEquip>('effectDate'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<EquipReportCatgEquip>('expireDate'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<EquipReportCatgEquip>('lastModifiedUser'),
            new UntypedFormControl('', [Validators.maxLength(50)]),
        );
        formGroup.addControl(
            nameof<EquipReportCatgEquip>('lastModifiedDatetime'),
            new UntypedFormControl(''),
        );

        return formGroup;
    }

    onCategoryChanged(event: MatSelectChange): void {
        if (event) {
            this.filteredValues = this.filterValuesByCat(event.value);
        }
    }

    onCopy(): void {
        this.copyClick.emit(this.equipment);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onDateRangeBlur(event): void {
        if (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            !event.event ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            !event.event.relatedTarget ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            !event.event.relatedTarget.className.startsWith('dx-')
        ) {
            this.showInputs = false;
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onDateRangeInit(event): void {
        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (event && event.component) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                event.component.focus();
            }
        });
    }

    onRemove(): void {
        let expiryDate: Date;

        if (this.tripCloseDate) {
            expiryDate = this.tripCloseDate;
        } else {
            expiryDate = new Date();
        }

        this.equipForm.patchValue({ expireDate: expiryDate });
    }
}
