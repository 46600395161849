import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RtTripCommentService } from '@bds/data-access';
import { ErrorHandlerService } from '@bds/railtrac-internal-services';
import { RtTripComment } from '@bds/railtrac-models';
import { ErrorListItem } from '@bds/rt-advance-models';
import { RtMultiCommentFormService } from './rt-multi-comment-form.service';
import { EMPTY, catchError, finalize, tap } from 'rxjs';

export interface RtMultiCommentDialogData {
    action: string;
    comments: Array<RtTripComment>;
    title: string;
}

@Component({
    selector: 'rt-multi-comment-dialog',
    templateUrl: './rt-multi-comment-dialog.component.html',
    styleUrls: ['./rt-multi-comment-dialog.component.css'],
})
export class RtMultiCommentDialogComponent implements OnInit {
    bulkForm: UntypedFormGroup;
    form: UntypedFormGroup;
    isSaving = false;
    errors: ErrorListItem[] = [];

    constructor(
        public dialogRef: MatDialogRef<RtMultiCommentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RtMultiCommentDialogData,
        private _snackbar: MatSnackBar,
        private commentFormService: RtMultiCommentFormService,
        public service: RtTripCommentService,
        private errorHandler: ErrorHandlerService,
    ) {}

    ngOnInit(): void {
        this.commentFormService.resetForm();
        this.bulkForm = this.commentFormService.commentBulkForm;
        this.form = this.commentFormService.form;
    }

    save() {
        if (this.form.valid) {
            this.isSaving = true;
            const formVal = this.commentFormService.commentItemForms.getRawValue();
            const value = formVal as RtTripComment[];
            this.service
                .AddComments(value)
                .pipe(
                    tap({
                        next: (response) => {
                            if (response['errors'].length) {
                                this.afterError([]);
                                return EMPTY;
                            }

                            this.afterSaveComplete();
                        },
                        error: (err) => {
                            this.afterError(err);
                            return EMPTY;
                        },
                    }),
                    catchError((err) => {
                        this.afterError(err);
                        return EMPTY;
                    }),
                    finalize(() => {
                        this.isSaving = false;
                    }),
                )
                .subscribe();
        }
    }

    private afterError(error: ErrorListItem[]) {
        this.errors = error;

        this._snackbar.open('Error saving comments(s).', 'Error', {
            duration: 3000,
            panelClass: 'error-snack',
        });
        this.isSaving = false;
    }

    private afterSaveComplete(message?: string) {
        if (!message) {
            message = 'Changes saved!';
        }

        this.isSaving = false;
        this._snackbar.open(message, 'Ok', { duration: 3000 });
        //this.resetForm();
        this.dialogRef.close({ event: 'Save' });
    }

    closeDialog(): void {
        //this.resetForm();
        this.dialogRef.close();
    }

    resetForm(): void {
        this.bulkForm.reset();
        this.form.reset();
    }
}
