import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RtCommodityShipped } from '@bds/railtrac-models';

export class RtTripCommodityShippedDialogOptions {
    public product: RtCommodityShipped;
    public productOp: string;
}

@Component({
    selector: 'rt-trip-commodity-shipped-dialog',
    templateUrl: './rt-trip-commodity-shipped-dialog.component.html',
    styleUrls: ['./rt-trip-commodity-shipped-dialog.component.css'],
})
export class RtTripCommodityShippedDialogComponent {
    public title: string;
    public product: RtCommodityShipped;
    public productOp = 'add';

    constructor(
        public dialogRef: MatDialogRef<RtTripCommodityShippedDialogComponent, RtCommodityShipped>,
        @Inject(MAT_DIALOG_DATA) public data: RtTripCommodityShippedDialogOptions,
    ) {
        if (data) {
            this.product = data.product;
            this.productOp = data.productOp;
            this.title = data.productOp == 'add' ? 'Add Product' : 'Edit Product';
        }
    }

    save(): void {
        this.dialogRef.close(this.product);
    }

    onCommodityShippedChange(product: RtCommodityShipped): void {
        this.product = product;
    }
}
