import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'bds-railroad-formly',
    template: ` <bds-railroad-select
        [label]="field.templateOptions.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
    >
    </bds-railroad-select>`,
})
export class BdsRailroadFormlyComponent extends FieldType implements OnInit, OnDestroy {
    constructor() {
        super();
    }

    ngOnDestroy(): void {}

    ngOnInit(): void {}
}
