<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<dx-pivot-grid
    id="pivotgrid"
    [allowSortingBySummary]="true"
    [allowFiltering]="true"
    [showBorders]="true"
    [allowSortingBySummary]="true"
    [showColumnGrandTotals]="false"
    [showRowGrandTotals]="false"
    [showRowTotals]="false"
    [showColumnTotals]="false"
    [dataSource]="gridSource"
>
    <dxo-field-chooser [enabled]="true" [height]="400"></dxo-field-chooser>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
</dx-pivot-grid>
