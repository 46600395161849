import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RtCarHotListService } from '@bds/data-access';
import { RtCarHotList, TripCarHotListDto } from '@bds/railtrac-models';
import { faSave, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { finalize, tap } from 'rxjs/operators';

@Component({
    selector: 'bds-hot-list-details',
    templateUrl: './bds-hot-list-details.component.html',
    styleUrls: ['./bds-hot-list-details.component.css'],
})
export class BdsHotListDetailsComponent implements OnInit {
    @Input() hotLists: RtCarHotList[];
    iconSave = faSave;
    iconSpinner = faSpinner;
    isSaving = false;
    @Output() saved = new EventEmitter();

    constructor(private hotListService: RtCarHotListService, private snackBar: MatSnackBar) {}

    ngOnInit() {}

    saveChanges(form: UntypedFormArray) {
        if (form.valid) {
            this.isSaving = true;
            this.hotListService
                .acknowledgeHotLists(form.getRawValue() as TripCarHotListDto[])
                .pipe(
                    finalize(() => {
                        this.isSaving = false;
                    }),
                    tap(() => {
                        this.snackBar.open('Saved Changes', 'success', { duration: 3000 });
                        this.hotListService.emitRefreshGridChange(true);
                    }),
                    tap((response) => this.saved.emit(response)),
                )
                .subscribe();
        }
    }
}
