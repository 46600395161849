import { PageService } from '@bds/core';
import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './assignment-screen.component.html',
    styleUrls: ['./assignment-screen.component.scss'],
})
export class AssignmentScreenComponent implements OnInit {
    constructor(public pageService: PageService) {
        pageService.setHeader('Fleet Assignments');
    }

    ngOnInit() {}
}
