import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faBars, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import {
    faAddressBook,
    faComment,
    faEllipsisVAlt,
    faFlaskPotion,
    faHistory,
    faInfo,
    faRss,
    faSave,
    faTrain,
    faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { RtClm, RtClmView } from '@bds/railtrac-models';
import { RailtracClmService } from './railtrac-clm.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ClmType } from '@bds/railtrac-models';
import { ErrorListItem } from '@bds/rt-advance-models';
import { EMPTY } from 'rxjs';

// Although this has an -x, this is a valid component that is used by the CLM screen
@Component({
    selector: 'rt-clm-x',
    templateUrl: './railtrac-clm.component.html',
    styleUrls: ['./railtrac-clm.component.scss'],
})
export class RailtracClmComponent implements OnInit, OnChanges {
    @Input() clmId: number;
    @Input() clm: RtClmView;
    @Input() highlightDirty: boolean = false;

    headerSize: string = 'h3';
    iconClm = faRss;
    iconComments = faComment;
    iconCommodity = faFlaskPotion;
    iconCustomer = faAddressBook;
    iconDetail = faInfo;
    iconHistory = faHistory;
    iconMenu = faBars;
    iconProfile = faWrench;
    iconSave = faSave;
    iconSpinner = faSpinner;
    iconTrain = faTrain;
    iconVerticalEllipsis = faEllipsisVAlt;
    matFloatLabel: string = 'always';
    matInputStyle: string = 'fill';
    matResultStyle: string = 'standard';
    matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';

    isDirty: boolean = false;
    isSaving: boolean = false;
    errors: ErrorListItem[] = [];

    get isNew() {
        return this.clm && !this.clm.ormId;
    }

    constructor(private clmService: RailtracClmService, private _snackbar: MatSnackBar) {}

    ngOnChanges(changes) {
        this.errors = [];
        if (!!this.clmId) {
            this.clmService.getClm(this.clmId).subscribe((clm) => {
                this.clm = clm;
            });
        }
    }

    ngOnInit() {}

    saveChanges(clm: RtClmView) {
        this.isSaving = true;
        if (this.isNew) {
            clm.ormId = 0;
            clm.dwellTime = 0;
            clm.clmType = ClmType.Current;
        }
        const obs = this.isNew ? this.add(clm) : this.update(clm);

        obs.pipe(
            tap(() => {
                this.afterSaveComplete();
            }),
            catchError((err) => {
                this.afterError(err);
                return EMPTY;
            }),
            finalize(() => {
                this.isSaving = false;
            }),
        ).subscribe((result) => {
            console.log(result);
            this._snackbar.open('Changes saved!', 'Ok', { duration: 3000 });
        });
    }

    add(clm: RtClmView) {
        return this.clmService.addClm(clm);
    }

    update(clm: RtClmView) {
        return this.clmService.updateClm(clm);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private afterError(error: ErrorListItem[]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const customerErrors = [];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.errors = error;

        this._snackbar.open('Error saving CLM!', 'Error', {
            duration: 3000,
            panelClass: 'error-snack',
        });
        this.isSaving = false;
    }

    private afterSaveComplete(message?: string) {
        if (!message) {
            message = 'Changes saved!';
        }

        this.isSaving = false;
        this._snackbar.open(message, 'Ok', { duration: 3000 });
    }
}
