<h1 mat-dialog-title>Add Equipment to Hotlist</h1>
<div mat-dialog-content [formGroup]="form">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-label>Comment Type</mat-label>
                    <mat-select
                        formControlName="commentType"
                        (selectionChange)="getDefaultComments($event.value)"
                    >
                        <mat-option value="">-- General Comment --</mat-option>
                        <mat-option
                            *ngFor="let item of commentTypes | async"
                            [value]="item.category"
                            >{{ item.description }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-label>Default Comment</mat-label>
                    <mat-select (selectionChange)="setDefaultComment($event.value)">
                        <mat-option value=""></mat-option>
                        <mat-option
                            *ngFor="let item of defaultComments | async"
                            [value]="item.comment"
                        >
                            {{ item.code }} - {{ item.comment }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Comments</mat-label>
                    <textarea
                        matInput
                        bdsShoutcase
                        cdkTextareaAutosize
                        formControlName="comments"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="5"
                    ></textarea>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button color="accent" mat-button class="text-uppercase" (click)="onCancelClick()">
        Cancel
    </button>
    <button mat-button color="accent" mat-button class="text-uppercase" (click)="onSaveClick()">
        Save
    </button>
</mat-dialog-actions>
