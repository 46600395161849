import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtHazmat implements From<RtHazmat> {
    constructor(
        public stccCode?: string,
        public unNaNumber?: string,
        public propShipName?: string,
        public techName?: string,
        public imoClassNumber?: string,
        public packGroup?: string,
        public hazmatZone?: string,
        public dotClass?: string,
        public hazmatCert?: string,
        public placardInfo?: string,
        public placardEndorse?: string,
        public doesRequireNotice?: boolean,
        public hasNosNotice?: boolean,
        public hasResidueNotice?: boolean,
        public hasPoisonInhaleNotice?: boolean,
        public shipNameQual?: string,
        public hasInhalationHazardNotice?: boolean,
        public hasHotNotice?: boolean,
        public canadianPropShipName?: string,
        public canadianPackGroup?: string,
        public subClass1?: string,
        public subClass2?: string,
        public subClass3?: string,
        public subRiskInd?: string,
        public netExplosiveQuantity?: string,
        public hazardNotify?: string,
        public specialCommInd?: string,
        public hazardClass?: string,
        public hazardEndorsement?: string,
        public placardNotify?: string,
        public frenchPropShipName?: string,
        public frenchPlacardInfo?: string,
        public frenchPlacardEndorse?: string,
        public shipperDotHazardNumber?: string,
        public dotSubClass1?: string,
        public dotSubClass2?: string,
        public dotSubClass3?: string,
        public isIncludeErp?: boolean,
    ) {}

    from(hazmat: RtHazmat): RtHazmat {
        Object.keys(hazmat).forEach((k) => (this[k] = hazmat[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtHazmat>('stccCode'), server: 'stccCode' },
    { client: nameof<RtHazmat>('unNaNumber'), server: 'unNaNumber' },
    { client: nameof<RtHazmat>('propShipName'), server: 'propShipName' },
    { client: nameof<RtHazmat>('techName'), server: 'techName' },
    { client: nameof<RtHazmat>('imoClassNumber'), server: 'imoClassNumber' },
    { client: nameof<RtHazmat>('packGroup'), server: 'packGroup' },
    { client: nameof<RtHazmat>('hazmatZone'), server: 'hazmatZone' },
    { client: nameof<RtHazmat>('dotClass'), server: 'dotClass' },
    { client: nameof<RtHazmat>('hazmatCert'), server: 'hazmatCert' },
    { client: nameof<RtHazmat>('placardInfo'), server: 'placardInfo' },
    { client: nameof<RtHazmat>('placardEndorse'), server: 'placardEndorse' },
    { client: nameof<RtHazmat>('doesRequireNotice'), server: 'doesRequireNotice' },
    { client: nameof<RtHazmat>('hasNosNotice'), server: 'hasNosNotice' },
    { client: nameof<RtHazmat>('hasResidueNotice'), server: 'hasResidueNotice' },
    { client: nameof<RtHazmat>('hasPoisonInhaleNotice'), server: 'hasPoisonInhaleNotice' },
    { client: nameof<RtHazmat>('shipNameQual'), server: 'shipNameQual' },
    { client: nameof<RtHazmat>('hasInhalationHazardNotice'), server: 'hasInhalationHazardNotice' },
    { client: nameof<RtHazmat>('hasHotNotice'), server: 'hasHotNotice' },
    { client: nameof<RtHazmat>('canadianPropShipName'), server: 'canadianPropShipName' },
    { client: nameof<RtHazmat>('canadianPackGroup'), server: 'canadianPackGroup' },
    { client: nameof<RtHazmat>('subClass1'), server: 'subClass1' },
    { client: nameof<RtHazmat>('subClass2'), server: 'subClass2' },
    { client: nameof<RtHazmat>('subClass3'), server: 'subClass3' },
    { client: nameof<RtHazmat>('subRiskInd'), server: 'subRiskInd' },
    { client: nameof<RtHazmat>('netExplosiveQuantity'), server: 'netExplosiveQuantity' },
    { client: nameof<RtHazmat>('hazardNotify'), server: 'hazardNotify' },
    { client: nameof<RtHazmat>('specialCommInd'), server: 'specialCommInd' },
    { client: nameof<RtHazmat>('hazardClass'), server: 'hazardClass' },
    { client: nameof<RtHazmat>('hazardEndorsement'), server: 'hazardEndorsement' },
    { client: nameof<RtHazmat>('placardNotify'), server: 'placardNotify' },
    { client: nameof<RtHazmat>('frenchPropShipName'), server: 'frenchPropShipName' },
    { client: nameof<RtHazmat>('frenchPlacardInfo'), server: 'frenchPlacardInfo' },
    { client: nameof<RtHazmat>('frenchPlacardEndorse'), server: 'frenchPlacardEndorse' },
    { client: nameof<RtHazmat>('shipperDotHazardNumber'), server: 'shipperDotHazardNumber' },
    { client: nameof<RtHazmat>('dotSubClass1'), server: 'dotSubClass1' },
    { client: nameof<RtHazmat>('dotSubClass2'), server: 'dotSubClass2' },
    { client: nameof<RtHazmat>('dotSubClass3'), server: 'dotSubClass3' },
    { client: nameof<RtHazmat>('isIncludeErp'), server: 'isIncludeErp' },
];

@Injectable({
    providedIn: 'root',
})
export class RtHazmatAdapter extends TwoWayAdapter<RtHazmat> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtHazmatMetadata implements FormGroupDescription {
    constructor() {}

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});

        formGroup.addControl(nameof<RtHazmat>('stccCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('unNaNumber'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('propShipName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('techName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('imoClassNumber'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('packGroup'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('hazmatZone'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('dotClass'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('hazmatCert'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('placardInfo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('placardEndorse'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('doesRequireNotice'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('hasNosNotice'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('hasResidueNotice'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('hasPoisonInhaleNotice'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('shipNameQual'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('hasInhalationHazardNotice'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('hasHotNotice'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('canadianPropShipName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('canadianPackGroup'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('subClass1'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('subClass2'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('subClass3'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('subRiskInd'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('netExplosiveQuantity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('hazardNotify'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('specialCommInd'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('hazardClass'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('hazardEndorsement'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('placardNotify'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('frenchPropShipName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('frenchPlacardInfo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('frenchPlacardEndorse'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('shipperDotHazardNumber'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('dotSubClass1'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('dotSubClass2'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('dotSubClass3'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtHazmat>('isIncludeErp'), new UntypedFormControl(''));

        if (!!obj) {
            formGroup.setValue(obj);
        }

        return formGroup;
    }
}
