import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { PageService } from '@bds/core';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

@Component({
    selector: 'app-railroad-fact-screen',
    template: `<router-outlet></router-outlet>`,
})
export class RailroadFactScreenComponent {
    constructor(private pageService: PageService) {
        this.pageService.setHeader('Railroads');
    }
}
