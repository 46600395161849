<div class="sticky-top mat-elevation-z6 equipment-menu">
    <mat-toolbar class="commodity-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <div class="col-10">
                <span>
                    Acknowledge {{ hotLists?.length }} Hot List{{ hotLists?.length > 1 ? 's' : '' }}
                </span>
                <span *ngIf="isSaving">
                    <small>
                        (saving...)
                        <fa-icon [icon]="iconSpinner" [spin]="true"></fa-icon>
                    </small>
                </span>
            </div>
            <div class="col-2">
                <span>
                    <ng-content></ng-content>
                    &nbsp;
                    <button
                        mat-stroked-button
                        (click)="saveChanges(hotListForm.form)"
                        [disabled]="isSaving"
                    >
                        <fa-icon [icon]="iconSave"> </fa-icon>
                        Update {{ hotLists?.length }} Hot List{{ hotLists?.length > 1 ? 's' : '' }}
                    </button>
                </span>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<bds-hotlist-form #hotListForm [hotLists]="hotLists" action="acknowledge"></bds-hotlist-form>
