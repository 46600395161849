import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import { forkJoin } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { FormlyDialogData } from '../../formly-components/bds-formly-dialog/bds-formly-dialog.component';
import { BdsPrefRouteService } from '../../services/bds-pref-route.service';
import { BdsAddPreferredRouteComponent } from './bds-add-preferred-route/bds-add-preferred-route.component';

@Component({
    selector: 'bds-rt-preferred-route',
    templateUrl: './bds-preferred-route.component.html',
    styleUrls: ['./bds-preferred-route.component.css'],
})
export class BdsPreferredRouteComponent {
    @ViewChild('bdsRouteGrid') bdsRouteGrid: DxDataGridComponent;
    dataSource: DataSource;
    gridId = 9;
    @Input() originSplc: string;
    @Input() readOnly = false;

    constructor(
        private prefRouteService: BdsPrefRouteService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
    ) {
        this.dataSource = new DataSource({
            store: this.prefRouteService.getODataStore(),
            paginate: true,
            pageSize: 10,
            expand: ['rtRouteCode,customerFact'],
        });
    }

    private _routeCode: string;

    get routeCode(): string {
        return this._routeCode;
    }

    @Input() set routeCode(value: string) {
        this.dataSource.filter([['routeCode', '=', value]]);
        this._routeCode = value;
    }

    add(): void {
        const dialog = this.dialog.open(BdsAddPreferredRouteComponent, {
            data: <FormlyDialogData<{ routeCode: string; originSplc: string }>>{
                title: 'Add Customer',
                model: { routeCode: this.routeCode, originSplc: this.originSplc },
                action: this.save,
            },
            width: '1200px',
        });

        dialog
            .afterClosed()
            .pipe(
                tap((response) => {
                    if (response) {
                        this.snackbar.open('Saved Changes', 'Ok', { duration: 3000 });
                        void this.bdsRouteGrid.instance.refresh();
                    }
                }),
            )
            .subscribe();
    }

    delete(confirm: TemplateRef<any>): void {
        const selected = this.bdsRouteGrid.selectedRowKeys;
        const dialog = this.dialog.open(confirm, {
            data: {
                title: 'Delete Preferred Route',
                content: `Are you sure you would like to delete ${
                    selected.length
                } selected preferred route${selected.length > 1 ? 's' : ''}?`,
                actionText: 'Yes',
                dismissiveText: 'No',
                dataSource: this.bdsRouteGrid.instance.getSelectedRowsData(),
            },
        });

        dialog
            .afterClosed()
            .pipe(
                filter((response) => response),
                switchMap(() => forkJoin(selected.map((x) => this.prefRouteService.delete(x)))),
                tap(() => {
                    this.bdsRouteGrid.instance.refresh();
                    this.snackbar.open('Deleted Record', 'Ok', { duration: 3000 });
                }),
            )
            .subscribe();
    }

    private save = (model: any) => this.prefRouteService.add(model);
}
