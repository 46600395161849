import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtvTripGrid implements From<RtvTripGrid> {
    constructor(
        public ormId?: number,
        public allowableWeight?: number,
        public apDaysAtCustomer?: number,
        public apHoursAtCustomer?: number,
        public badOrderDays?: number,
        public beginCarStatus?: string,
        public bolNo?: string,
        public businessGroup?: string,
        public capacity?: number,
        public carAssignCarInit?: string,
        public carAssignCarNo?: string,
        public careOfName?: string,
        public carId?: string,
        public carInit?: string,
        public carMechBusinessGroup?: string,
        public carMechCarInit?: string,
        public carMechCarNo?: string,
        public carMechFleetId?: string,
        public carMechFleetName?: string,
        public carNo?: string,
        public carStatus?: string,
        public carUserId?: string,
        public chargeTimeAtDest?: number,
        public clmDateTime?: Date,
        public clmDestCity?: string,
        public clmDestCountry?: string,
        public clmDestState?: string,
        public clmLe?: string,
        public clmLocCity?: string,
        public clmLocCountry?: string,
        public clmLocState?: string,
        public clmRoad?: string,
        public clmSightCode?: string,
        public clmTrnJct?: string,
        public commAmt?: number,
        public commClass?: string,
        public commCode?: string,
        public commCompart?: number,
        public comments?: string,
        public commName?: string,
        public commRef1?: string,
        public commRef2?: string,
        public commRef3?: string,
        public commRef4?: string,
        public commShortName?: string,
        public commUnits?: string,
        public contractNo?: string,
        public cpDaysAtCustomer?: number,
        public cpHoursAtCustomer?: number,
        public csrNo?: string,
        public custDlvDateTime?: Date,
        public custDlvType?: string,
        public custFname?: string,
        public custLname?: string,
        public custName?: string,
        public custNo?: string,
        public customerHoldDays?: number,
        public customerHoldHours?: number,
        public custPoNo?: string,
        public custRelDateTime?: Date,
        public custRelType?: string,
        public custReqHrs?: number,
        public custType?: string,
        public custTypeDscr?: string,
        public custZip?: string,
        public daysAtLocation?: number,
        public daysToConsignee?: number,
        public daysToOrigin?: number,
        public deliveryStatus?: string,
        public destArrDateTime?: Date,
        public destArrType?: string,
        public destCity?: string,
        public destCountry?: string,
        public destSplc?: string,
        public destState?: string,
        public detentionAmount?: number,
        public detUom?: string,
        public dlvCarrier?: string,
        public ecommentCode?: string,
        public ecommentDate?: Date,
        public ecommentType?: string,
        public emptyToOriginDays?: number,
        public emptyToOriginHours?: number,
        public etaHrsIn?: number,
        public etaHrsOut?: number,
        public excessDays?: number,
        public excessRent?: number,
        public fleetCustomer?: string,
        public fleetId?: string,
        public fleetName?: string,
        public freeTime?: number,
        public freightPayer?: string,
        public freightPmtCode?: string,
        public hasHotListLease?: boolean,
        public hasHotListMech?: boolean,
        public hasTroubleLog?: boolean,
        public hazmatStccCode?: string,
        public hotListCommentCode?: string,
        public hotListCommentDate?: Date,
        public hotListCommentType?: string,
        public idleDaysAtOrigin?: number,
        public importSource?: string,
        public isaGsControlNo?: string,
        public isAutoClose?: boolean,
        public isBillDetention?: boolean,
        public isCarActive?: boolean,
        public isCurrent?: boolean,
        public isCustomerActive?: boolean,
        public isCustomerTypeShop?: boolean,
        public isDetentionExtract?: boolean,
        public isDiverted?: boolean,
        public isEtaManualClose?: boolean,
        public isFreeRunner?: boolean,
        public isHotList?: boolean,
        public isProcessedInTransPay?: boolean,
        public isRouteActive?: boolean,
        public isShopMove?: boolean,
        public isSupplierShipment?: boolean,
        public isTracked?: boolean,
        public lastComments?: string,
        public lastExpComments?: string,
        public lessorRent?: number,
        public loadLimit?: number,
        public loadToCustomerDays?: number,
        public loadToCustomerHours?: number,
        public masterOrigin?: string,
        public masterTripNo?: string,
        public nonmoveHours?: number,
        public offRouteCity?: string,
        public offRouteSensitivity?: number,
        public offRouteState?: string,
        public offRouteStatus?: number,
        public oneWayRound?: string,
        public origCarrier?: string,
        public origCity?: string,
        public origCountry?: string,
        public origEdaEtaCust?: Date,
        public origEdaEtaOrigin?: Date,
        public originalEtaToCust?: Date,
        public originalEtaToOrigin?: Date,
        public originHoldDays?: number,
        public originHoldHours?: number,
        public origSplc?: string,
        public origState?: string,
        public outageCompartNo?: string,
        public outageInnage?: string,
        public outageIssuerCode?: string,
        public outageTableNo?: string,
        public participatingRouteCode?: string,
        public poolCode?: string,
        public prevTripCloseDateTime?: Date,
        public releaseCity?: string,
        public releaseCountry?: string,
        public releaseSplc?: string,
        public releaseState?: string,
        public retentionAmount?: number,
        public retentionDaysCharged?: number,
        public retentionDaysFree?: number,
        public retentionRate?: number,
        public returnCity?: string,
        public returnCountry?: string,
        public returnSplc?: string,
        public returnState?: string,
        public routeCode?: string,
        public routeDescription?: string,
        public rqstDlvDateTime?: Date,
        public salesRep?: string,
        public serviceCountry?: string,
        public shipDateTime?: Date,
        public shipmentType?: string,
        public shipperNo?: string,
        public stccCode?: string,
        public stenciling?: string,
        public subFleet?: string,
        public supplierCode?: string,
        public supplierName?: string,
        public supplierRecDeleteDays?: number,
        public tare?: number,
        public totalDays?: number,
        public totalTimeAtDest?: number,
        public tripClsDateTime?: Date,
        public tripClsType?: string,
        public tripCommentCode?: string,
        public tripCommentDate?: Date,
        public tripCommentType?: string,
        public tripCommentUserId?: string,
        public tripImportSource?: string,
        public tripNo?: string,
        public tripRef1?: string,
        public tripRef2?: string,
        public tripRef3?: string,
        public tripRef4?: string,
        public tripRef5?: string,
        public tripRef6?: string,
        public tripRef7?: string,
        public tripRef8?: string,
        public tripRef9?: string,
        public tripRef10?: string,
        public tripRef11?: string,
        public tripRef12?: string,
        public tripRef13?: string,
        public tripRef14?: string,
        public tripRef15?: string,
        public tripRef16?: string,
        public tripRef17?: string,
        public tripRef18?: string,
        public tripRef19?: string,
        public tripRef20?: string,
        public tripStatus?: string,
        public unitTrainId?: string,
    ) {}

    from(trip: RtvTripGrid): RtvTripGrid {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtvTripGrid>('ormId'), server: 'ormId' },
    { client: nameof<RtvTripGrid>('allowableWeight'), server: 'allowableWeight' },
    { client: nameof<RtvTripGrid>('apDaysAtCustomer'), server: 'apDaysAtCustomer' },
    { client: nameof<RtvTripGrid>('apHoursAtCustomer'), server: 'apHoursAtCustomer' },
    { client: nameof<RtvTripGrid>('badOrderDays'), server: 'badOrderDays' },
    { client: nameof<RtvTripGrid>('beginCarStatus'), server: 'beginCarStatus' },
    { client: nameof<RtvTripGrid>('bolNo'), server: 'bolNo' },
    { client: nameof<RtvTripGrid>('businessGroup'), server: 'businessGroup' },
    { client: nameof<RtvTripGrid>('capacity'), server: 'capacity' },
    { client: nameof<RtvTripGrid>('carAssignCarInit'), server: 'carAssignCarInit' },
    { client: nameof<RtvTripGrid>('carAssignCarNo'), server: 'carAssignCarNo' },
    { client: nameof<RtvTripGrid>('careOfName'), server: 'careOfName' },
    { client: nameof<RtvTripGrid>('carId'), server: 'carId' },
    { client: nameof<RtvTripGrid>('carInit'), server: 'carInit' },
    { client: nameof<RtvTripGrid>('carMechBusinessGroup'), server: 'carMechBusinessGroup' },
    { client: nameof<RtvTripGrid>('carMechCarInit'), server: 'carMechCarInit' },
    { client: nameof<RtvTripGrid>('carMechCarNo'), server: 'carMechCarNo' },
    { client: nameof<RtvTripGrid>('carMechFleetId'), server: 'carMechFleetId' },
    { client: nameof<RtvTripGrid>('carMechFleetName'), server: 'carMechFleetName' },
    { client: nameof<RtvTripGrid>('carNo'), server: 'carNo' },
    { client: nameof<RtvTripGrid>('carStatus'), server: 'carStatus' },
    { client: nameof<RtvTripGrid>('carUserId'), server: 'carUserId' },
    { client: nameof<RtvTripGrid>('chargeTimeAtDest'), server: 'chargeTimeAtDest' },
    { client: nameof<RtvTripGrid>('clmDateTime'), server: 'clmDateTime' },
    { client: nameof<RtvTripGrid>('clmDestCity'), server: 'clmDestCity' },
    { client: nameof<RtvTripGrid>('clmDestCountry'), server: 'clmDestCountry' },
    { client: nameof<RtvTripGrid>('clmDestState'), server: 'clmDestState' },
    { client: nameof<RtvTripGrid>('clmLe'), server: 'clmLe' },
    { client: nameof<RtvTripGrid>('clmLocCity'), server: 'clmLocCity' },
    { client: nameof<RtvTripGrid>('clmLocCountry'), server: 'clmLocCountry' },
    { client: nameof<RtvTripGrid>('clmLocState'), server: 'clmLocState' },
    { client: nameof<RtvTripGrid>('clmRoad'), server: 'clmRoad' },
    { client: nameof<RtvTripGrid>('clmSightCode'), server: 'clmSightCode' },
    { client: nameof<RtvTripGrid>('clmTrnJct'), server: 'clmTrnJct' },
    { client: nameof<RtvTripGrid>('commAmt'), server: 'commAmt' },
    { client: nameof<RtvTripGrid>('commClass'), server: 'commClass' },
    { client: nameof<RtvTripGrid>('commCode'), server: 'commCode' },
    { client: nameof<RtvTripGrid>('commCompart'), server: 'commCompart' },
    { client: nameof<RtvTripGrid>('comments'), server: 'comments' },
    { client: nameof<RtvTripGrid>('commName'), server: 'commName' },
    { client: nameof<RtvTripGrid>('commRef1'), server: 'commRef1' },
    { client: nameof<RtvTripGrid>('commRef2'), server: 'commRef2' },
    { client: nameof<RtvTripGrid>('commRef3'), server: 'commRef3' },
    { client: nameof<RtvTripGrid>('commRef4'), server: 'commRef4' },
    { client: nameof<RtvTripGrid>('commShortName'), server: 'commShortName' },
    { client: nameof<RtvTripGrid>('commUnits'), server: 'commUnits' },
    { client: nameof<RtvTripGrid>('contractNo'), server: 'contractNo' },
    { client: nameof<RtvTripGrid>('cpDaysAtCustomer'), server: 'cpDaysAtCustomer' },
    { client: nameof<RtvTripGrid>('cpHoursAtCustomer'), server: 'cpHoursAtCustomer' },
    { client: nameof<RtvTripGrid>('csrNo'), server: 'csrNo' },
    { client: nameof<RtvTripGrid>('custDlvDateTime'), server: 'custDlvDateTime' },
    { client: nameof<RtvTripGrid>('custDlvType'), server: 'custDlvType' },
    { client: nameof<RtvTripGrid>('custFname'), server: 'custFname' },
    { client: nameof<RtvTripGrid>('custLname'), server: 'custLname' },
    { client: nameof<RtvTripGrid>('custName'), server: 'custName' },
    { client: nameof<RtvTripGrid>('custNo'), server: 'custNo' },
    { client: nameof<RtvTripGrid>('customerHoldDays'), server: 'customerHoldDays' },
    { client: nameof<RtvTripGrid>('customerHoldHours'), server: 'customerHoldHours' },
    { client: nameof<RtvTripGrid>('custPoNo'), server: 'custPoNo' },
    { client: nameof<RtvTripGrid>('custRelDateTime'), server: 'custRelDateTime' },
    { client: nameof<RtvTripGrid>('custRelType'), server: 'custRelType' },
    { client: nameof<RtvTripGrid>('custReqHrs'), server: 'custReqHrs' },
    { client: nameof<RtvTripGrid>('custType'), server: 'custType' },
    { client: nameof<RtvTripGrid>('custTypeDscr'), server: 'custTypeDscr' },
    { client: nameof<RtvTripGrid>('custZip'), server: 'custZip' },
    { client: nameof<RtvTripGrid>('daysAtLocation'), server: 'daysAtLocation' },
    { client: nameof<RtvTripGrid>('daysToConsignee'), server: 'daysToConsignee' },
    { client: nameof<RtvTripGrid>('daysToOrigin'), server: 'daysToOrigin' },
    { client: nameof<RtvTripGrid>('deliveryStatus'), server: 'deliveryStatus' },
    { client: nameof<RtvTripGrid>('destArrDateTime'), server: 'destArrDateTime' },
    { client: nameof<RtvTripGrid>('destArrType'), server: 'destArrType' },
    { client: nameof<RtvTripGrid>('destCity'), server: 'destCity' },
    { client: nameof<RtvTripGrid>('destCountry'), server: 'destCountry' },
    { client: nameof<RtvTripGrid>('destSplc'), server: 'destSplc' },
    { client: nameof<RtvTripGrid>('destState'), server: 'destState' },
    { client: nameof<RtvTripGrid>('detentionAmount'), server: 'detentionAmount' },
    { client: nameof<RtvTripGrid>('detUom'), server: 'detUom' },
    { client: nameof<RtvTripGrid>('dlvCarrier'), server: 'dlvCarrier' },
    { client: nameof<RtvTripGrid>('ecommentCode'), server: 'ecommentCode' },
    { client: nameof<RtvTripGrid>('ecommentDate'), server: 'ecommentDate' },
    { client: nameof<RtvTripGrid>('ecommentType'), server: 'ecommentType' },
    { client: nameof<RtvTripGrid>('emptyToOriginDays'), server: 'emptyToOriginDays' },
    { client: nameof<RtvTripGrid>('emptyToOriginHours'), server: 'emptyToOriginHours' },
    { client: nameof<RtvTripGrid>('etaHrsIn'), server: 'etaHrsIn' },
    { client: nameof<RtvTripGrid>('etaHrsOut'), server: 'etaHrsOut' },
    { client: nameof<RtvTripGrid>('excessDays'), server: 'excessDays' },
    { client: nameof<RtvTripGrid>('excessRent'), server: 'excessRent' },
    { client: nameof<RtvTripGrid>('fleetCustomer'), server: 'fleetCustomer' },
    { client: nameof<RtvTripGrid>('fleetId'), server: 'fleetId' },
    { client: nameof<RtvTripGrid>('fleetName'), server: 'fleetName' },
    { client: nameof<RtvTripGrid>('freeTime'), server: 'freeTime' },
    { client: nameof<RtvTripGrid>('freightPayer'), server: 'freightPayer' },
    { client: nameof<RtvTripGrid>('freightPmtCode'), server: 'freightPmtCode' },
    { client: nameof<RtvTripGrid>('hasHotListLease'), server: 'hasHotListLease' },
    { client: nameof<RtvTripGrid>('hasHotListMech'), server: 'hasHotListMech' },
    { client: nameof<RtvTripGrid>('hasTroubleLog'), server: 'hasTroubleLog' },
    { client: nameof<RtvTripGrid>('hazmatStccCode'), server: 'hazmatStccCode' },
    { client: nameof<RtvTripGrid>('idleDaysAtOrigin'), server: 'idleDaysAtOrigin' },
    { client: nameof<RtvTripGrid>('importSource'), server: 'importSource' },
    { client: nameof<RtvTripGrid>('isaGsControlNo'), server: 'isaGsControlNo' },
    { client: nameof<RtvTripGrid>('isAutoClose'), server: 'isAutoClose' },
    { client: nameof<RtvTripGrid>('isBillDetention'), server: 'isBillDetention' },
    { client: nameof<RtvTripGrid>('isCarActive'), server: 'isCarActive' },
    { client: nameof<RtvTripGrid>('isCurrent'), server: 'isCurrent' },
    { client: nameof<RtvTripGrid>('isCustomerActive'), server: 'isCustomerActive' },
    { client: nameof<RtvTripGrid>('isCustomerTypeShop'), server: 'isCustomerTypeShop' },
    { client: nameof<RtvTripGrid>('isDetentionExtract'), server: 'isDetentionExtract' },
    { client: nameof<RtvTripGrid>('isDiverted'), server: 'isDiverted' },
    { client: nameof<RtvTripGrid>('isEtaManualClose'), server: 'isEtaManualClose' },
    { client: nameof<RtvTripGrid>('isFreeRunner'), server: 'isFreeRunner' },
    { client: nameof<RtvTripGrid>('isHotList'), server: 'isHotList' },
    { client: nameof<RtvTripGrid>('isProcessedInTransPay'), server: 'isProcessedInTransPay' },
    { client: nameof<RtvTripGrid>('isRouteActive'), server: 'isRouteActive' },
    { client: nameof<RtvTripGrid>('isShopMove'), server: 'isShopMove' },
    { client: nameof<RtvTripGrid>('isSupplierShipment'), server: 'isSupplierShipment' },
    { client: nameof<RtvTripGrid>('isTracked'), server: 'isTracked' },
    { client: nameof<RtvTripGrid>('lastComments'), server: 'lastComments' },
    { client: nameof<RtvTripGrid>('lastExpComments'), server: 'lastExpComments' },
    { client: nameof<RtvTripGrid>('lessorRent'), server: 'lessorRent' },
    { client: nameof<RtvTripGrid>('loadLimit'), server: 'loadLimit' },
    { client: nameof<RtvTripGrid>('loadToCustomerDays'), server: 'loadToCustomerDays' },
    { client: nameof<RtvTripGrid>('loadToCustomerHours'), server: 'loadToCustomerHours' },
    { client: nameof<RtvTripGrid>('masterOrigin'), server: 'masterOrigin' },
    { client: nameof<RtvTripGrid>('masterTripNo'), server: 'masterTripNo' },
    { client: nameof<RtvTripGrid>('nonmoveHours'), server: 'nonmoveHours' },
    { client: nameof<RtvTripGrid>('offRouteCity'), server: 'offRouteCity' },
    { client: nameof<RtvTripGrid>('offRouteSensitivity'), server: 'offRouteSensitivity' },
    { client: nameof<RtvTripGrid>('offRouteState'), server: 'offRouteState' },
    { client: nameof<RtvTripGrid>('offRouteStatus'), server: 'offRouteStatus' },
    { client: nameof<RtvTripGrid>('oneWayRound'), server: 'oneWayRound' },
    { client: nameof<RtvTripGrid>('origCarrier'), server: 'origCarrier' },
    { client: nameof<RtvTripGrid>('origCity'), server: 'origCity' },
    { client: nameof<RtvTripGrid>('origCountry'), server: 'origCountry' },
    { client: nameof<RtvTripGrid>('origEdaEtaCust'), server: 'origEdaEtaCust' },
    { client: nameof<RtvTripGrid>('origEdaEtaOrigin'), server: 'origEdaEtaOrigin' },
    { client: nameof<RtvTripGrid>('originalEtaToCust'), server: 'originalEtaToCust' },
    { client: nameof<RtvTripGrid>('originalEtaToOrigin'), server: 'originalEtaToOrigin' },
    { client: nameof<RtvTripGrid>('originHoldDays'), server: 'originHoldDays' },
    { client: nameof<RtvTripGrid>('originHoldHours'), server: 'originHoldHours' },
    { client: nameof<RtvTripGrid>('origSplc'), server: 'origSplc' },
    { client: nameof<RtvTripGrid>('origState'), server: 'origState' },
    { client: nameof<RtvTripGrid>('outageCompartNo'), server: 'outageCompartNo' },
    { client: nameof<RtvTripGrid>('outageInnage'), server: 'outageInnage' },
    { client: nameof<RtvTripGrid>('outageIssuerCode'), server: 'outageIssuerCode' },
    { client: nameof<RtvTripGrid>('outageTableNo'), server: 'outageTableNo' },
    { client: nameof<RtvTripGrid>('participatingRouteCode'), server: 'participatingRouteCode' },
    { client: nameof<RtvTripGrid>('poolCode'), server: 'poolCode' },
    { client: nameof<RtvTripGrid>('prevTripCloseDateTime'), server: 'prevTripCloseDateTime' },
    { client: nameof<RtvTripGrid>('releaseCity'), server: 'releaseCity' },
    { client: nameof<RtvTripGrid>('releaseCountry'), server: 'releaseCountry' },
    { client: nameof<RtvTripGrid>('releaseSplc'), server: 'releaseSplc' },
    { client: nameof<RtvTripGrid>('releaseState'), server: 'releaseState' },
    { client: nameof<RtvTripGrid>('retentionAmount'), server: 'retentionAmount' },
    { client: nameof<RtvTripGrid>('retentionDaysCharged'), server: 'retentionDaysCharged' },
    { client: nameof<RtvTripGrid>('retentionDaysFree'), server: 'retentionDaysFree' },
    { client: nameof<RtvTripGrid>('retentionRate'), server: 'retentionRate' },
    { client: nameof<RtvTripGrid>('returnCity'), server: 'returnCity' },
    { client: nameof<RtvTripGrid>('returnCountry'), server: 'returnCountry' },
    { client: nameof<RtvTripGrid>('returnSplc'), server: 'returnSplc' },
    { client: nameof<RtvTripGrid>('returnState'), server: 'returnState' },
    { client: nameof<RtvTripGrid>('routeCode'), server: 'routeCode' },
    { client: nameof<RtvTripGrid>('routeDescription'), server: 'routeDescription' },
    { client: nameof<RtvTripGrid>('rqstDlvDateTime'), server: 'rqstDlvDateTime' },
    { client: nameof<RtvTripGrid>('salesRep'), server: 'salesRep' },
    { client: nameof<RtvTripGrid>('serviceCountry'), server: 'serviceCountry' },
    { client: nameof<RtvTripGrid>('shipDateTime'), server: 'shipDateTime' },
    { client: nameof<RtvTripGrid>('shipmentType'), server: 'shipmentType' },
    { client: nameof<RtvTripGrid>('shipperNo'), server: 'shipperNo' },
    { client: nameof<RtvTripGrid>('stccCode'), server: 'stccCode' },
    { client: nameof<RtvTripGrid>('stenciling'), server: 'stenciling' },
    { client: nameof<RtvTripGrid>('subFleet'), server: 'subFleet' },
    { client: nameof<RtvTripGrid>('supplierCode'), server: 'supplierCode' },
    { client: nameof<RtvTripGrid>('supplierName'), server: 'supplierName' },
    { client: nameof<RtvTripGrid>('supplierRecDeleteDays'), server: 'supplierRecDeleteDays' },
    { client: nameof<RtvTripGrid>('tare'), server: 'tare' },
    { client: nameof<RtvTripGrid>('totalDays'), server: 'totalDays' },
    { client: nameof<RtvTripGrid>('totalTimeAtDest'), server: 'totalTimeAtDest' },
    { client: nameof<RtvTripGrid>('tripClsDateTime'), server: 'tripClsDateTime' },
    { client: nameof<RtvTripGrid>('tripClsType'), server: 'tripClsType' },
    { client: nameof<RtvTripGrid>('tripCommentCode'), server: 'tripCommentCode' },
    { client: nameof<RtvTripGrid>('tripCommentDate'), server: 'tripCommentDate' },
    { client: nameof<RtvTripGrid>('tripCommentType'), server: 'tripCommentType' },
    { client: nameof<RtvTripGrid>('tripCommentUserId'), server: 'tripCommentUserId' },
    { client: nameof<RtvTripGrid>('tripImportSource'), server: 'tripImportSource' },
    { client: nameof<RtvTripGrid>('tripNo'), server: 'tripNo' },
    { client: nameof<RtvTripGrid>('tripRef1'), server: 'tripRef1' },
    { client: nameof<RtvTripGrid>('tripRef2'), server: 'tripRef2' },
    { client: nameof<RtvTripGrid>('tripRef3'), server: 'tripRef3' },
    { client: nameof<RtvTripGrid>('tripRef4'), server: 'tripRef4' },
    { client: nameof<RtvTripGrid>('tripRef5'), server: 'tripRef5' },
    { client: nameof<RtvTripGrid>('tripRef6'), server: 'tripRef6' },
    { client: nameof<RtvTripGrid>('tripRef7'), server: 'tripRef7' },
    { client: nameof<RtvTripGrid>('tripRef8'), server: 'tripRef8' },
    { client: nameof<RtvTripGrid>('tripRef9'), server: 'tripRef9' },
    { client: nameof<RtvTripGrid>('tripRef10'), server: 'tripRef10' },
    { client: nameof<RtvTripGrid>('tripRef11'), server: 'tripRef11' },
    { client: nameof<RtvTripGrid>('tripRef12'), server: 'tripRef12' },
    { client: nameof<RtvTripGrid>('tripRef13'), server: 'tripRef13' },
    { client: nameof<RtvTripGrid>('tripRef14'), server: 'tripRef14' },
    { client: nameof<RtvTripGrid>('tripRef15'), server: 'tripRef15' },
    { client: nameof<RtvTripGrid>('tripRef16'), server: 'tripRef16' },
    { client: nameof<RtvTripGrid>('tripRef17'), server: 'tripRef17' },
    { client: nameof<RtvTripGrid>('tripRef18'), server: 'tripRef18' },
    { client: nameof<RtvTripGrid>('tripRef19'), server: 'tripRef19' },
    { client: nameof<RtvTripGrid>('tripRef20'), server: 'tripRef20' },
    { client: nameof<RtvTripGrid>('tripStatus'), server: 'tripStatus' },
    { client: nameof<RtvTripGrid>('unitTrainId'), server: 'unitTrainId' },
];

@Injectable({
    providedIn: 'root',
})
export class RtvTripGridAdapter extends TwoWayAdapter<RtvTripGrid> {
    metadata: IModelMetadata[] = relationship;

    constructor() {
        super();
    }
}

@Injectable({
    providedIn: 'root',
})
export class RtvTripGridMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        const columns = [
            {
                dataField: nameof<RtvTripGrid>('ormId'),
                dataType: 'number',
                caption: 'ormId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('allowableWeight'),
                dataType: 'number',
                caption: 'allowableWeight',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('apDaysAtCustomer'),
                dataType: 'number',
                caption: 'apDaysAtCustomer',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('apHoursAtCustomer'),
                dataType: 'number',
                caption: 'apHoursAtCustomer',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('badOrderDays'),
                dataType: 'number',
                caption: 'badOrderDays',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('beginCarStatus'),
                dataType: 'string',
                caption: 'beginCarStatus',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('bolNo'),
                dataType: 'string',
                caption: 'bolNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('businessGroup'),
                dataType: 'string',
                caption: 'businessGroup',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('capacity'),
                dataType: 'number',
                caption: 'capacity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carAssignCarInit'),
                dataType: 'string',
                caption: 'carAssignCarInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carAssignCarNo'),
                dataType: 'string',
                caption: 'carAssignCarNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('careOfName'),
                dataType: 'string',
                caption: 'careOfName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carId'),
                dataType: 'string',
                caption: 'carId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carMechBusinessGroup'),
                dataType: 'string',
                caption: 'carMechBusinessGroup',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carMechCarInit'),
                dataType: 'string',
                caption: 'carMechCarInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carMechCarNo'),
                dataType: 'string',
                caption: 'carMechCarNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carMechFleetId'),
                dataType: 'string',
                caption: 'carMechFleetId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carMechFleetName'),
                dataType: 'string',
                caption: 'carMechFleetName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carStatus'),
                dataType: 'string',
                caption: 'carStatus',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('carUserId'),
                dataType: 'string',
                caption: 'carUserId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('chargeTimeAtDest'),
                dataType: 'number',
                caption: 'chargeTimeAtDest',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmDateTime'),
                dataType: 'Date',
                caption: 'clmDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmDestCity'),
                dataType: 'string',
                caption: 'clmDestCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmDestCountry'),
                dataType: 'string',
                caption: 'clmDestCountry',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmDestState'),
                dataType: 'string',
                caption: 'clmDestState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmLe'),
                dataType: 'string',
                caption: 'clmLe',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmLocCity'),
                dataType: 'string',
                caption: 'clmLocCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmLocCountry'),
                dataType: 'string',
                caption: 'clmLocCountry',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmLocState'),
                dataType: 'string',
                caption: 'clmLocState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmRoad'),
                dataType: 'string',
                caption: 'clmRoad',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmSightCode'),
                dataType: 'string',
                caption: 'clmSightCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('clmTrnJct'),
                dataType: 'string',
                caption: 'clmTrnJct',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commAmt'),
                dataType: 'number',
                caption: 'commAmt',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commClass'),
                dataType: 'string',
                caption: 'commClass',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commCode'),
                dataType: 'string',
                caption: 'commCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commCompart'),
                dataType: 'number',
                caption: 'commCompart',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('comments'),
                dataType: 'string',
                caption: 'comments',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commName'),
                dataType: 'string',
                caption: 'commName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commRef1'),
                dataType: 'string',
                caption: 'commRef1',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commRef2'),
                dataType: 'string',
                caption: 'commRef2',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commRef3'),
                dataType: 'string',
                caption: 'commRef3',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commRef4'),
                dataType: 'string',
                caption: 'commRef4',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commShortName'),
                dataType: 'string',
                caption: 'commShortName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('commUnits'),
                dataType: 'string',
                caption: 'commUnits',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('contractNo'),
                dataType: 'string',
                caption: 'contractNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('cpDaysAtCustomer'),
                dataType: 'number',
                caption: 'cpDaysAtCustomer',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('cpHoursAtCustomer'),
                dataType: 'number',
                caption: 'cpHoursAtCustomer',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('csrNo'),
                dataType: 'string',
                caption: 'csrNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custDlvDateTime'),
                dataType: 'Date',
                caption: 'custDlvDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custDlvType'),
                dataType: 'string',
                caption: 'custDlvType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custFname'),
                dataType: 'string',
                caption: 'custFname',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custLname'),
                dataType: 'string',
                caption: 'custLname',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custName'),
                dataType: 'string',
                caption: 'custName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custNo'),
                dataType: 'string',
                caption: 'custNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('customerHoldDays'),
                dataType: 'number',
                caption: 'customerHoldDays',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('customerHoldHours'),
                dataType: 'number',
                caption: 'customerHoldHours',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custPoNo'),
                dataType: 'string',
                caption: 'custPoNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custRelDateTime'),
                dataType: 'Date',
                caption: 'custRelDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custRelType'),
                dataType: 'string',
                caption: 'custRelType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custReqHrs'),
                dataType: 'number',
                caption: 'custReqHrs',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custType'),
                dataType: 'string',
                caption: 'custType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custTypeDscr'),
                dataType: 'string',
                caption: 'custTypeDscr',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('custZip'),
                dataType: 'string',
                caption: 'custZip',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('daysAtLocation'),
                dataType: 'number',
                caption: 'daysAtLocation',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('daysToConsignee'),
                dataType: 'number',
                caption: 'daysToConsignee',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('daysToOrigin'),
                dataType: 'number',
                caption: 'daysToOrigin',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('deliveryStatus'),
                dataType: 'string',
                caption: 'deliveryStatus',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('destArrDateTime'),
                dataType: 'Date',
                caption: 'destArrDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('destArrType'),
                dataType: 'string',
                caption: 'destArrType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('destCity'),
                dataType: 'string',
                caption: 'destCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('destCountry'),
                dataType: 'string',
                caption: 'destCountry',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('destState'),
                dataType: 'string',
                caption: 'destState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('detentionAmount'),
                dataType: 'number',
                caption: 'detentionAmount',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('detUom'),
                dataType: 'string',
                caption: 'detUom',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('dlvCarrier'),
                dataType: 'string',
                caption: 'dlvCarrier',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('ecommentCode'),
                dataType: 'string',
                caption: 'ecommentCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('ecommentDate'),
                dataType: 'Date',
                caption: 'ecommentDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('ecommentType'),
                dataType: 'string',
                caption: 'ecommentType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('emptyToOriginDays'),
                dataType: 'number',
                caption: 'emptyToOriginDays',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('emptyToOriginHours'),
                dataType: 'number',
                caption: 'emptyToOriginHours',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('etaHrsIn'),
                dataType: 'number',
                caption: 'etaHrsIn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('etaHrsOut'),
                dataType: 'number',
                caption: 'etaHrsOut',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('excessDays'),
                dataType: 'number',
                caption: 'excessDays',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('excessRent'),
                dataType: 'number',
                caption: 'excessRent',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('fleetCustomer'),
                dataType: 'string',
                caption: 'fleetCustomer',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('fleetId'),
                dataType: 'string',
                caption: 'fleetId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('fleetName'),
                dataType: 'string',
                caption: 'fleetName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('freeTime'),
                dataType: 'number',
                caption: 'freeTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('freightPayer'),
                dataType: 'string',
                caption: 'freightPayer',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('freightPmtCode'),
                dataType: 'string',
                caption: 'freightPmtCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('hasHotListLease'),
                dataType: 'boolean',
                caption: 'hasHotListLease',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('hasHotListMech'),
                dataType: 'boolean',
                caption: 'hasHotListMech',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('hasTroubleLog'),
                dataType: 'boolean',
                caption: 'hasTroubleLog',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('hazmatStccCode'),
                dataType: 'string',
                caption: 'hazmatStccCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('idleDaysAtOrigin'),
                dataType: 'number',
                caption: 'idleDaysAtOrigin',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('importSource'),
                dataType: 'string',
                caption: 'importSource',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isaGsControlNo'),
                dataType: 'string',
                caption: 'isaGsControlNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isAutoClose'),
                dataType: 'boolean',
                caption: 'isAutoClose',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isBillDetention'),
                dataType: 'boolean',
                caption: 'isBillDetention',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isCarActive'),
                dataType: 'boolean',
                caption: 'isCarActive',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isCurrent'),
                dataType: 'boolean',
                caption: 'isCurrent',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isCustomerActive'),
                dataType: 'boolean',
                caption: 'isCustomerActive',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isCustomerTypeShop'),
                dataType: 'boolean',
                caption: 'isCustomerTypeShop',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isDetentionExtract'),
                dataType: 'boolean',
                caption: 'isDetentionExtract',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isDiverted'),
                dataType: 'boolean',
                caption: 'isDiverted',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isEtaManualClose'),
                dataType: 'boolean',
                caption: 'isEtaManualClose',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isFreeRunner'),
                dataType: 'boolean',
                caption: 'isFreeRunner',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isHotList'),
                dataType: 'boolean',
                caption: 'isHotList',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isProcessedInTransPay'),
                dataType: 'boolean',
                caption: 'isProcessedInTransPay',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isRouteActive'),
                dataType: 'boolean',
                caption: 'isRouteActive',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isShopMove'),
                dataType: 'boolean',
                caption: 'isShopMove',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isSupplierShipment'),
                dataType: 'boolean',
                caption: 'isSupplierShipment',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('isTracked'),
                dataType: 'boolean',
                caption: 'isTracked',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('lastComments'),
                dataType: 'string',
                caption: 'lastComments',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('lastExpComments'),
                dataType: 'string',
                caption: 'lastExpComments',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('lessorRent'),
                dataType: 'number',
                caption: 'lessorRent',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('loadLimit'),
                dataType: 'number',
                caption: 'loadLimit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('loadToCustomerDays'),
                dataType: 'number',
                caption: 'loadToCustomerDays',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('loadToCustomerHours'),
                dataType: 'number',
                caption: 'loadToCustomerHours',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('masterOrigin'),
                dataType: 'string',
                caption: 'masterOrigin',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('masterTripNo'),
                dataType: 'string',
                caption: 'masterTripNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('nonmoveHours'),
                dataType: 'number',
                caption: 'nonmoveHours',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('offRouteCity'),
                dataType: 'string',
                caption: 'offRouteCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('offRouteSensitivity'),
                dataType: 'number',
                caption: 'offRouteSensitivity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('offRouteState'),
                dataType: 'string',
                caption: 'offRouteState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('offRouteStatus'),
                dataType: 'number',
                caption: 'offRouteStatus',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('oneWayRound'),
                dataType: 'string',
                caption: 'oneWayRound',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('origCarrier'),
                dataType: 'string',
                caption: 'origCarrier',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('origCity'),
                dataType: 'string',
                caption: 'origCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('origCountry'),
                dataType: 'string',
                caption: 'origCountry',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('origEdaEtaCust'),
                dataType: 'Date',
                caption: 'origEdaEtaCust',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('origEdaEtaOrigin'),
                dataType: 'Date',
                caption: 'origEdaEtaOrigin',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('originalEtaToCust'),
                dataType: 'Date',
                caption: 'originalEtaToCust',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('originalEtaToOrigin'),
                dataType: 'Date',
                caption: 'originalEtaToOrigin',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('originHoldDays'),
                dataType: 'number',
                caption: 'originHoldDays',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('originHoldHours'),
                dataType: 'number',
                caption: 'originHoldHours',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('origState'),
                dataType: 'string',
                caption: 'origState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('outageCompartNo'),
                dataType: 'string',
                caption: 'outageCompartNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('outageInnage'),
                dataType: 'string',
                caption: 'outageInnage',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('outageIssuerCode'),
                dataType: 'string',
                caption: 'outageIssuerCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('outageTableNo'),
                dataType: 'string',
                caption: 'outageTableNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('participatingRouteCode'),
                dataType: 'string',
                caption: 'participatingRouteCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('poolCode'),
                dataType: 'string',
                caption: 'poolCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('prevTripCloseDateTime'),
                dataType: 'Date',
                caption: 'prevTripCloseDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('releaseCity'),
                dataType: 'string',
                caption: 'releaseCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('releaseCountry'),
                dataType: 'string',
                caption: 'releaseCountry',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('releaseState'),
                dataType: 'string',
                caption: 'releaseState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('retentionAmount'),
                dataType: 'number',
                caption: 'retentionAmount',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('retentionDaysCharged'),
                dataType: 'number',
                caption: 'retentionDaysCharged',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('retentionDaysFree'),
                dataType: 'number',
                caption: 'retentionDaysFree',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('retentionRate'),
                dataType: 'number',
                caption: 'retentionRate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('returnCity'),
                dataType: 'string',
                caption: 'returnCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('returnCountry'),
                dataType: 'string',
                caption: 'returnCountry',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('returnState'),
                dataType: 'string',
                caption: 'returnState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('routeCode'),
                dataType: 'string',
                caption: 'routeCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('routeDescription'),
                dataType: 'string',
                caption: 'routeDescription',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('rqstDlvDateTime'),
                dataType: 'Date',
                caption: 'rqstDlvDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('salesRep'),
                dataType: 'string',
                caption: 'salesRep',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('serviceCountry'),
                dataType: 'string',
                caption: 'serviceCountry',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('shipDateTime'),
                dataType: 'Date',
                caption: 'shipDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('shipmentType'),
                dataType: 'string',
                caption: 'shipmentType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('shipperNo'),
                dataType: 'string',
                caption: 'shipperNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('stccCode'),
                dataType: 'string',
                caption: 'stccCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('stenciling'),
                dataType: 'string',
                caption: 'stenciling',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('subFleet'),
                dataType: 'string',
                caption: 'subFleet',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('supplierCode'),
                dataType: 'string',
                caption: 'supplierCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('supplierName'),
                dataType: 'string',
                caption: 'supplierName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('supplierRecDeleteDays'),
                dataType: 'number',
                caption: 'supplierRecDeleteDays',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tare'),
                dataType: 'number',
                caption: 'tare',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('totalDays'),
                dataType: 'number',
                caption: 'totalDays',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('totalTimeAtDest'),
                dataType: 'number',
                caption: 'totalTimeAtDest',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripClsDateTime'),
                dataType: 'Date',
                caption: 'tripClsDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripClsType'),
                dataType: 'string',
                caption: 'tripClsType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripCommentCode'),
                dataType: 'string',
                caption: 'tripCommentCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripCommentDate'),
                dataType: 'Date',
                caption: 'tripCommentDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripCommentType'),
                dataType: 'string',
                caption: 'tripCommentType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripCommentUserId'),
                dataType: 'string',
                caption: 'tripCommentUserId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripImportSource'),
                dataType: 'string',
                caption: 'tripImportSource',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripNo'),
                dataType: 'string',
                caption: 'tripNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef1'),
                dataType: 'string',
                caption: 'tripRef1',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef2'),
                dataType: 'string',
                caption: 'tripRef2',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef3'),
                dataType: 'string',
                caption: 'tripRef3',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef4'),
                dataType: 'string',
                caption: 'tripRef4',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef5'),
                dataType: 'string',
                caption: 'tripRef5',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef6'),
                dataType: 'string',
                caption: 'tripRef6',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef7'),
                dataType: 'string',
                caption: 'tripRef7',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef8'),
                dataType: 'string',
                caption: 'tripRef8',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef9'),
                dataType: 'string',
                caption: 'tripRef9',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef10'),
                dataType: 'string',
                caption: 'tripRef10',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef11'),
                dataType: 'string',
                caption: 'tripRef11',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef12'),
                dataType: 'string',
                caption: 'tripRef12',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef13'),
                dataType: 'string',
                caption: 'tripRef13',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef14'),
                dataType: 'string',
                caption: 'tripRef14',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef15'),
                dataType: 'string',
                caption: 'tripRef15',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef16'),
                dataType: 'string',
                caption: 'tripRef16',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef17'),
                dataType: 'string',
                caption: 'tripRef17',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef18'),
                dataType: 'string',
                caption: 'tripRef18',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef19'),
                dataType: 'string',
                caption: 'tripRef19',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripRef20'),
                dataType: 'string',
                caption: 'tripRef20',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('tripStatus'),
                dataType: 'string',
                caption: 'tripStatus',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvTripGrid>('unitTrainId'),
                dataType: 'string',
                caption: 'unitTrainId',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtvTripGrid>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('allowableWeight'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('apDaysAtCustomer'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('apHoursAtCustomer'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('badOrderDays'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('beginCarStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('bolNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('businessGroup'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('capacity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carAssignCarInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carAssignCarNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('careOfName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carMechBusinessGroup'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carMechCarInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carMechCarNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carMechFleetId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carMechFleetName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('carUserId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('chargeTimeAtDest'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmDestCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmDestCountry'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmDestState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmLe'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmLocCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmLocCountry'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmLocState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmRoad'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmSightCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('clmTrnJct'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commAmt'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commClass'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commCompart'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('comments'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commRef1'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commRef2'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commRef3'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commRef4'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commShortName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('commUnits'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('contractNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('cpDaysAtCustomer'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('cpHoursAtCustomer'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('csrNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custDlvDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custDlvType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custFname'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custLname'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('customerHoldDays'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('customerHoldHours'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custPoNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custRelDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custRelType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custReqHrs'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custTypeDscr'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('custZip'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('daysAtLocation'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('daysToConsignee'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('daysToOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('deliveryStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('destArrDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('destArrType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('destCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('destCountry'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('destState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('detentionAmount'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('detUom'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('dlvCarrier'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('ecommentCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('ecommentDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('ecommentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('emptyToOriginDays'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('emptyToOriginHours'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('etaHrsIn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('etaHrsOut'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('excessDays'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('excessRent'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('fleetCustomer'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('fleetId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('fleetName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('freeTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('freightPayer'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('freightPmtCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('hasHotListLease'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('hasHotListMech'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('hasTroubleLog'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('hazmatStccCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('idleDaysAtOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('importSource'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isaGsControlNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isAutoClose'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isBillDetention'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isCarActive'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isCurrent'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isCustomerActive'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isCustomerTypeShop'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isDetentionExtract'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isDiverted'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isEtaManualClose'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isFreeRunner'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isHotList'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isProcessedInTransPay'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isRouteActive'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isShopMove'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isSupplierShipment'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('isTracked'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('lastComments'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('lastExpComments'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('lessorRent'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('loadLimit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('loadToCustomerDays'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('loadToCustomerHours'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('masterOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('masterTripNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('nonmoveHours'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('offRouteCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('offRouteSensitivity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('offRouteState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('offRouteStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('oneWayRound'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('origCarrier'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('origCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('origCountry'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('origEdaEtaCust'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('origEdaEtaOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('originalEtaToCust'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('originalEtaToOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('originHoldDays'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('originHoldHours'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('origState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('outageCompartNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('outageInnage'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('outageIssuerCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('outageTableNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('participatingRouteCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('poolCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('prevTripCloseDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('releaseCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('releaseCountry'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('releaseState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('retentionAmount'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('retentionDaysCharged'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('retentionDaysFree'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('retentionRate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('returnCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('returnCountry'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('returnState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('routeCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('routeDescription'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('rqstDlvDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('salesRep'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('serviceCountry'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('shipDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('shipmentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('shipperNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('stccCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('stenciling'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('subFleet'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('supplierCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('supplierName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('supplierRecDeleteDays'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tare'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('totalDays'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('totalTimeAtDest'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripClsDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripClsType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripCommentCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripCommentDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripCommentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripCommentUserId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripImportSource'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef1'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef2'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef3'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef4'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef5'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef6'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef7'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef8'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef9'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef10'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef11'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef12'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef13'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef14'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef15'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef16'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef17'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef18'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef19'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripRef20'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('tripStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvTripGrid>('unitTrainId'), new UntypedFormControl(''));

        if (!!obj) {
            formGroup.setValue(obj);
        }

        return formGroup;
    }
}
