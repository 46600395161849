import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { VendorsModule } from '../../vendors/vendors.module';
import { EquipmentReportCategoriesComponentsModule } from '../../components';
import { EquipmentReportCategoriesDialogComponent } from './equipment-report-categories-dialog.component';

@NgModule({
    declarations: [EquipmentReportCategoriesDialogComponent],
    imports: [
        CommonModule,
        EquipmentReportCategoriesComponentsModule,
        MatDialogModule,
        VendorsModule,
    ],
    exports: [EquipmentReportCategoriesDialogComponent],
})
export class EquipmentReportCategoriesDialogModule {}
