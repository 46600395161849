import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rt-bds-business-group',
    templateUrl: './bds-business-group.component.html',
    styleUrls: ['./bds-business-group.component.css'],
})
export class BdsBusinessGroupComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
