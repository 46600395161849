import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorizeService } from '@bds/auth';
import { RtTripCommentService } from '@bds/data-access';
import { RtCommentType, RtTrip, RtTripComment } from '@bds/railtrac-models';
import { switchMap, take, tap } from 'rxjs/operators';
// import { RtTripCommentDialogComponent } from '../../rt-trip-comment/rt-trip-comment-dialog/rt-trip-comment-dialog.component';
// import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
// import { switchMap, take, tap } from 'rxjs/operators';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { MatSnackBar } from '@angular/material/snack-bar';
import {
    faCommentAlt,
    faCommentAltLines,
    faCommentAltEdit,
    faCommentAltPlus,
    faTrashAlt,
    faPencilAlt,
} from '@fortawesome/pro-duotone-svg-icons';
import { faLongArrowDown, faLongArrowUp } from '@fortawesome/pro-regular-svg-icons';
// import { AuthorizeService } from '@bds/auth';
// import { RtCommentType, RtTripComment, RtTripCommentDialogModel } from '@bds/railtrac-models';
import { RailtracTripService } from '../../../railtrac-trip.service';
import { BdsDialogConfirmComponent } from '@bds/components';
//import { RtMultiCommentEditFormComponent } from '../rt-multi-comment-edit-form/rt-multi-comment-edit-form.component';
import { UntypedFormArray } from '@angular/forms';
import { RtMultiCommentEditFormItemComponent } from '../rt-multi-comment-edit-form-item/rt-multi-comment-edit-form-item.component';
import { RtMultiCommentFormService } from '../rt-multi-comment-dialog/rt-multi-comment-form.service';
// import { Observable } from 'rxjs';

// import { BdsDialogConfirmComponent } from '@bds/components';

@Component({
    selector: 'rt-multi-comment-detail',
    templateUrl: './rt-multi-comment-detail.component.html',
    styleUrls: ['./rt-multi-comment-detail.component.css'],
})
export class RtMultiCommentDetailComponent implements OnInit, OnChanges {
    //@ViewChild('commentEditForm') commentEditFormComponent: RtMultiCommentEditFormComponent;
    @ViewChildren('commentEditFormItem')
    commentEditFormItemComponents: QueryList<RtMultiCommentEditFormItemComponent>;
    @Input()
    trip: RtTrip;
    @Input() tripId: number;
    @Output() commentPresent: EventEmitter<boolean> = new EventEmitter<boolean>();

    comments: RtTripComment[] = [];
    commentBlankIconDuo = faCommentAlt;
    commentGenericIconDuo = faCommentAltLines;
    commentEdit = faPencilAlt;
    commentAdd = faCommentAltPlus;
    commentDelete = faTrashAlt;
    sortAscending = faLongArrowUp;
    sortDescending = faLongArrowDown;
    userName: string;

    commentTypes: RtCommentType[];
    showSortButtons = false;
    showToolbar = true;

    private sortMode: 'asc' | 'desc' = 'asc';

    get sortedAscendingComments(): RtTripComment[] {
        return this.comments.sort(
            (a, b) => new Date(a.commentDate).getTime() - new Date(b.commentDate).getTime(),
        );
    }

    get sortedDescendingComments(): RtTripComment[] {
        return this.comments.sort(
            (a, b) => new Date(b.commentDate).getTime() - new Date(a.commentDate).getTime(),
        );
    }

    constructor(
        private tripService: RailtracTripService,
        private authService: AuthorizeService,
        private _snackbar: MatSnackBar,
        public dialog: MatDialog,
        public commentService: RtTripCommentService,
        public commentFormService: RtMultiCommentFormService,
    ) {
        this.authService
            .getUser()
            .pipe(take(1))
            .subscribe((user) => (this.userName = user['preferred_username']));
    }

    ngOnInit() {
        this.commentService
            .getCommentTypes()
            .pipe(
                tap((response) => {
                    this.commentTypes = response;
                }),
            )
            .subscribe();

        this.commentFormService.refreshComment$.subscribe((x) => {
            this.getComments(this.trip.ormId);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const tripIdChange = changes['tripId'];
        if (!!tripIdChange) {
            this.getComments(tripIdChange.currentValue);
        }
    }

    getComments(tripId: number) {
        this.tripService
            .getComments(tripId)
            .pipe(take(1))
            .subscribe({
                next: (c) => {
                    this.comments.splice(0, this.comments.length, ...c);
                    this.onSortDescending(); //Display newest comment on top
                },
                complete: () => {
                    this.showSortButtons = this.comments && this.comments.length > 1 ? true : false;
                    //this.showToolbar = this.showSortButtons && this.showButtons;
                    //this.commentFormService.emitCommentPresentChange(this.showSortButtons);
                    this.commentPresent.emit(this.showSortButtons);
                },
            });
    }

    onSortAscending() {
        console.log('onSortAscending');
        this.comments = [].concat(
            this.comments.sort(
                (a, b) => new Date(a.commentDate).getTime() - new Date(b.commentDate).getTime(),
            ),
        );
        this.sortMode = 'asc';
    }

    onSortDescending() {
        console.log('onSortDescending');
        this.comments = [].concat(
            this.comments.sort(
                (a, b) => new Date(b.commentDate).getTime() - new Date(a.commentDate).getTime(),
            ),
        );
        this.sortMode = 'desc';
    }

    onDeleteComment(ormId: number) {
        //let selectedTripComment = this.comments.find(i => i.ormId === ormId);
        const dialog = this.dialog.open(BdsDialogConfirmComponent, {
            data: {
                actionText: 'Delete',
                content: `Are you sure you would like to delete the comment?`,
            },
        });

        dialog
            .afterClosed()
            .pipe(
                switchMap(() => this.commentService.delete(ormId)),
                tap(() => this.getComments(this.tripId)),
            )
            .subscribe(
                () => {
                    this._snackbar.open('Deleted CLM', 'Ok', {
                        duration: 3000,
                    });
                },
                (error) => {
                    this._snackbar.open(error.error, 'Error', {
                        duration: 3000,
                    });
                },
            );
    }

    onSaved(message: string) {
        this.getComments(this.tripId);
        this._snackbar.open(message, 'Ok', { duration: 3000 });
    }

    get commentForm(): UntypedFormArray {
        const formArray = new UntypedFormArray([]);
        if (this.commentEditFormItemComponents) {
            this.commentEditFormItemComponents.map((eachComponent) => {
                formArray.push(eachComponent.formGroup);
            });
        }
        return formArray;
        //return this.commentEditFormComponent.form;
    }

    // loadComments(): void {
    //     this.getComments(this.trip.ormId);
    // }
}
