import ODataStore from 'devextreme/data/odata/store';
import { RtArrAtOrig, RtClmSightCode, RtStateCode } from '@bds/railtrac-models';

export class BdsOriginCriteriaGridDialogModel {
    originCodes: ODataStore; // from RT_ERPC_ORIGIN
    originCriteria: RtArrAtOrig;
    isClone: boolean;
    isNew: boolean;
    railroads: ODataStore;
    sightCodes: RtClmSightCode[];
    states: RtStateCode[];
}
