<h1 mat-dialog-title>
    {{ title }}
</h1>

<mat-dialog-content>
    <div class="bds-error-message">{{ errorMessage }}</div>

    <div class="buttons">
        <button mat-stroked-button (click)="onAdd()" [disabled]="isSaving">
            <fa-icon class="add-icon" [icon]="iconPlus"></fa-icon>
        </button>
    </div>

    <div>
        <bds-historic-dates-list
            [dates]="dateList"
            [hasDueDate]="data.hasDueDate"
            (editDate)="onEdit($event)"
        >
        </bds-historic-dates-list>
    </div>

    <div *ngIf="selectedDate">
        <bds-historic-dates-details
            [selectedDate]="selectedDate"
            [hasDueDate]="data.hasDueDate"
            (dateChange)="onSelectedDateChanged($event)"
            (dateDelete)="onDeleteDate($event)"
        >
        </bds-historic-dates-details>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" (click)="onCancelClick()">
        Cancel
    </button>

    <button
        mat-button
        [disabled]="!isValid || isSaving"
        class="text-uppercase"
        color="accent"
        (click)="onSaveClick()"
    >
        Save & Close
    </button>
</mat-dialog-actions>
