<div class="row">
    <div class="col-sm-6 col-lg-4 col-12">
        <div class="dtable">
            <div class="dtrow">
                <div class="dtcell">Car ID</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.carInitials }}{{ shipment.carNumber }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Shipment Date</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.shipDatetime }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Prev Ship Date</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.prevShipDatetime }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Car Status</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.carStatus }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Trip Status</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.tripStatus }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Customer</div>
                <div class="dtcell">
                    <span class="data"
                        >{{ shipment.customerNumber }} - {{ shipment.customerName }}</span
                    >
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Commodity</div>
                <div class="dtcell">
                    <span class="data"
                        >{{ shipment.commodityCode }} - {{ shipment.commodityShortName }}</span
                    >
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Route</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.routeCode }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Trip Dest</div>
                <div class="dtcell">
                    <span class="data"
                        >{{ shipment.destinationCity }}, {{ shipment.destinationState }}</span
                    >
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Free Runner</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.isFreeRunner }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Diverted?</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.isDivert }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-lg-4 col-12">
        <div class="dtable">
            <div class="dtrow">
                <div class="dtcell">Fleet</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.fleetId }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Bus Group</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.businessGroup }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Assigned Fleet</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.carAssignmentFleetId }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Assigned Bus Group</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.carAssignmentBusinessGroup }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">CLM Date</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.clmDatetime }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Sight Code</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.sightCode }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Location</div>
                <div class="dtcell">
                    <span class="data"
                        >{{ shipment.locationCity }}, {{ shipment.locationState }}</span
                    >
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">CLM Dest</div>
                <div class="dtcell">
                    <span class="data"
                        >{{ shipment.clmDestinationCity }}, {{ shipment.clmDestinationState }}</span
                    >
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Load/Empty</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.loadedOrEmpty }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Road</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.road }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Junction</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.trainJunction }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-lg-4 col-12">
        <div class="dtable">
            <div class="dtrow">
                <div class="dtcell">ETA to Dest (orig)</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.originalEtaToDestination }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">ETA to Dest</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.etaToDestination }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Dest Variance</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.destinationVariance }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">ETA to Origin (orig)</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.originalEtaToOrigin }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">ETA to Origin</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.etaToOrigin }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Origin Variance</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.originVariance }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Idle Days</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.idleDays }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">CSR</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.csrNumber }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Sales Rep</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.salesRep }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Root</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.root }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="dtable">
            <div class="dtrow">
                <div class="dtcell">Last Comment</div>
                <div class="dtcell">
                    <span class="data">{{ shipment.lastComments }}</span>
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Expediter Comment</div>
                <div class="dtcell">
                    <span class="data"
                        >{{ shipment.ecommentDate }} - ({{ shipment.ecommentType }})
                        {{ shipment.ecommentCode }}</span
                    >
                </div>
            </div>
            <div class="dtrow">
                <div class="dtcell">Comment</div>
                <div class="dtcell">
                    <span class="data"
                        >{{ shipment.tcommentDate }} - ({{ shipment.tcommentType }})
                        {{ shipment.tcommentCode }}</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>
