import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { bdsGridModeType } from '@bds/components';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-eta-performance-route',
    templateUrl: './eta-performance-route.component.html',
    styleUrl: './eta-performance-route.component.css',
})
export class EtaPerformanceRouteComponent implements OnInit {
    public gridId = 132;
    public gridMode: bdsGridModeType = 'single';
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Specialty ETA Performance-Route Report';
    public gridSource: any;
    public selectedRowKeys: Array<number> = [];
    public displayReport: boolean = false;
    public controlNames: string[] = ['Trip Close Begin Date', 'Trip Close End Date'];

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit() {
        this.pageService.setHeader(this.pageHeader);
    }

    onGetReportClick(event: any) {
        this.displayReport = true;
        this.gridSource = this.reportService.CreateAspNetDatastore(
            ReportConstants.ReportDataApiCall,
            this.gridId.toString(),
            event.startDate,
            event.endDate,
        );
    }

    onCancelClick() {
        this.displayReport = false;
    }

    onCellPrepared(e) {
        if (e.rowType === 'data') {
            if (
                e.column.dataField === 'OUT_VAR_HRS' &&
                e.data.OUT_VAR_HRS != null &&
                e.data.OUT_VAR_HRS < 0
            ) {
                e.cellElement.classList.add('negative-number-class');
            } else if (
                e.column.dataField === 'IN_VAR_HRS' &&
                e.data.IN_VAR_HRS != null &&
                e.data.IN_VAR_HRS < 0
            ) {
                e.cellElement.classList.add('negative-number-class');
            } else if (
                e.column.dataField === 'TOTAL_VAR_HRS' &&
                e.data.TOTAL_VAR_HRS != null &&
                e.data.TOTAL_VAR_HRS < 0
            ) {
                e.cellElement.classList.add('negative-number-class');
            }
        }
    }
}
