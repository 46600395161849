import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FastracScreenBaseComponent } from '@bds/fastrac';
import { FastracColsService } from '@bds/services';
import { CommunicationService } from '@bds/core';
import { CarIdTransformService } from '@bds/helpers';
import { bdsGridModeType } from '@bds/components';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-rt-consignee-car-ret-summary',
    templateUrl: './rt-consignee-car-ret-summary.component.html',
    styleUrl: './rt-consignee-car-ret-summary.component.css',
})
export class RtConsigneeCarRetSummaryComponent implements OnInit {
    public gridId = 131;

    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Consignee Car Retention Summary Report';
    public gridSource: any;

    public displayReport: boolean = false;
    public controlNames: string[] = ['Release Begin Date', 'Release End Date'];

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit() {
        this.pageService.setHeader(this.pageHeader);
    }

    onGetReportClick(event: any) {
        this.displayReport = true;
        this.gridSource = this.reportService.CreateAspNetDatastore(
            ReportConstants.ReportDataApiCall,
            this.gridId.toString(),
            event.startDate,
            event.endDate,
        );
    }

    onCancelClick() {
        this.displayReport = false;
    }
}
