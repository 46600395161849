<form [formGroup]="formGroup">
    <div class="row">
        <div class="col-6">
            <div class="row" *ngIf="!isBulkForm">
                <div class="col-6 col-sm-6 cell">
                    <mat-label>Car ID: </mat-label>
                    <ng-container>
                        {{ formGroup.controls.carInit.value }}
                        {{ formGroup.controls.carNo.value }}</ng-container
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-sm-6 cell">
                    <mat-label>User ID: </mat-label>
                    <ng-container> {{ formGroup.controls.userId.value }}</ng-container>
                </div>
                <div class="col-6 col-sm-6" *ngIf="!isBulkForm">
                    <mat-label>Comment Date </mat-label>
                    <ng-container>
                        {{
                            formGroup.controls.commentDate.value | date : 'MM/dd/yyyy HH:mm' : 'UTC'
                        }}
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <mat-form-field
                        class="highlight-dirty bds-mat-dense-form-field w-100"
                        floatLabel="always"
                        appearance="fill"
                    >
                        <mat-label>Comment Type</mat-label>
                        <mat-select
                            formControlName="commentType"
                            (selectionChange)="getDefaultComments($event.value)"
                        >
                            <mat-option value="">-- General Comment --</mat-option>
                            <mat-option
                                *ngFor="let item of commentTypes | async"
                                [value]="item.category"
                                >{{ item.description }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-3">
                    <mat-form-field
                        class="highlight-dirty bds-mat-dense-form-field w-100"
                        floatLabel="always"
                        appearance="fill"
                    >
                        <mat-label>Comment Code<span class="dirty-indicator">*</span> </mat-label>
                        <mat-select
                            formControlName="commentCode"
                            (selectionChange)="setDefaultComment($event.value)"
                            [disabled]="formGroup.controls['commentType'].value === ''"
                        >
                            <mat-option value=""></mat-option>
                            <mat-option
                                *ngFor="let item of defaultComments | async"
                                [value]="item.code"
                            >
                                {{ item.code }} - {{ item.comment }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-4">
            <mat-form-field floatLabel="always" appearance="fill" style="min-width: 100%">
                <mat-label>Comments<span class="dirty-indicator">*</span></mat-label>
                <textarea matInput formControlName="comments" rows="4" class="uppercase"></textarea>
            </mat-form-field>
            <mat-slide-toggle
                formControlName="transmitToCust"
                class="mb-3"
                [checked]="formGroup.controls.transmitToCust.value === 'Y'"
                (change)="setFlag($event.checked, formGroup?.controls?.transmitToCust)"
                >Show to Consignee in RAILTRACAdvize
            </mat-slide-toggle>
        </div>
    </div>
</form>
