import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { AuthorizeService } from '@bds/auth';
import { FastracGrid } from '@bds/models';
import { EquipmentService } from '../../data-access';

@Component({
    selector: 'bds-equipment',
    templateUrl: './equipment.component.html',
    styleUrls: ['./equipment.component.scss'],
})
export class EquipmentComponent implements OnDestroy {
    defaultFastracId: number;
    equipmentId: number;
    equipmentList: ODataStore;
    fastracId: number;
    gridId: number = FastracGrid.equipment;
    newEquipment = false;
    refreshGrid$ = new BehaviorSubject<boolean>(false);
    selectedRowKeys: Array<number> = [];
    userName: string;

    private ngUnsubscribe$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthorizeService,
        private equipmentService: EquipmentService,
    ) {
        this.route.data.pipe(take(1)).subscribe((data) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const resolvedData = data['defaultData'];
            this.defaultFastracId = +resolvedData;
        });

        this.route.params.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params) => {
            this.parseRouteParams(params);
        });

        this.authService
            .getUser()
            .pipe(take(1))
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
            .subscribe((user) => (this.userName = user['preferred_username']));

        this.equipmentList = equipmentService.getODataStore();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next(true);
        this.ngUnsubscribe$.unsubscribe();
    }

    onAddEquipment(): void {
        this.onEquipmentChanged(0);
        this.newEquipment = true;
    }

    onEquipmentChanged(event: number): void {
        const newEquipmentId = event || 0;

        if (this.equipmentId !== newEquipmentId) {
            this.equipmentId = newEquipmentId;
            this.syncRouteParams();
        }

        if (this.equipmentId !== 0) {
            this.newEquipment = false;
        }
    }

    onEquipmentSaved(event: { id: number; isNew: boolean }): void {
        this.refreshGrid$.next(true);

        if (event.isNew && event.id) {
            this.onEquipmentChanged(event.id);
        }
    }

    onFastracIdChanged(event: number): void {
        const newFastracId = event || 0;

        if (this.fastracId !== newFastracId) {
            this.fastracId = newFastracId;
            this.syncRouteParams();
        }
    }

    parseRouteParams(params: Params): void {
        if (params && params.id) {
            this.equipmentId = +params.id;
            this.selectedRowKeys = [this.equipmentId];

            if (params && params.fId) {
                this.fastracId = +params.fId;
            } else {
                this.fastracId = this.defaultFastracId;
                this.syncRouteParams();
            }
        } else {
            this.equipmentId = 0;
            const urlSegment: UrlSegment = this.route.snapshot.url[0];

            if (urlSegment && urlSegment.parameters && urlSegment.parameters.fId) {
                this.fastracId = +urlSegment.parameters.fId;
            } else {
                this.fastracId = this.defaultFastracId;
                this.syncRouteParams();
            }
        }
    }

    syncRouteParams(): void {
        let fastracParam = {};

        if (this.fastracId) {
            fastracParam = { fId: this.fastracId };
        }

        if (this.equipmentId) {
            void this.router.navigate(['/equipment', this.equipmentId, fastracParam]);
        } else {
            void this.router.navigate(['/equipment', fastracParam]);
        }
    }
}
