import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { From, IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';
import { FormGroupDescription, GridColumnDescription, IColumnDescription } from './adapter_t';

export class BdsRoutePathCode implements From<BdsRoutePathCode> {
    constructor(
        public contractNo?: string,
        public deliveryRoad?: string,
        public isTruckDeliveryLoadEmpty?: string,
        public originRoad?: string,
        public pathCode?: string,
        public pathDescription?: string,
    ) {}

    from(pathCode: BdsRoutePathCode): BdsRoutePathCode {
        Object.keys(pathCode).forEach((k) => (this[k] = pathCode[k]));
        return this;
    }
}

const relationship: IModelMetadata[] = [
    { client: nameof<BdsRoutePathCode>('contractNo'), server: 'contractNo' },
    { client: nameof<BdsRoutePathCode>('deliveryRoad'), server: 'deliveryRoad' },
    {
        client: nameof<BdsRoutePathCode>('isTruckDeliveryLoadEmpty'),
        server: 'isTruckDeliveryLoadEmpty',
    },
    { client: nameof<BdsRoutePathCode>('originRoad'), server: 'originRoad' },
    { client: nameof<BdsRoutePathCode>('pathCode'), server: 'pathCode' },
    { client: nameof<BdsRoutePathCode>('pathDescription'), server: 'pathDescription' },
];

@Injectable({
    providedIn: 'root',
})
export class BdsPathCodeAdapter extends TwoWayAdapter<BdsRoutePathCode> {
    public metadata: IModelMetadata[] = relationship;

    constructor() {
        super();
    }
}

@Injectable({
    providedIn: 'root',
})
export class BdsPathCodeMetadata implements GridColumnDescription, FormGroupDescription {
    getColumns(): IColumnDescription[] {
        return [
            {
                dataField: nameof<BdsRoutePathCode>('contractNo'),
                dataType: 'string',
                caption: 'Contract No',
                visible: false,
            },
            {
                dataField: nameof<BdsRoutePathCode>('deliveryRoad'),
                dataType: 'string',
                caption: 'Non-Line Haul Dlv Carrier',
                visible: true,
            },
            {
                dataField: nameof<BdsRoutePathCode>('isTruckDeliveryLoadEmpty'),
                dataType: 'string',
                caption: 'Truck Delivery',
                visible: true,
            },
            {
                dataField: nameof<BdsRoutePathCode>('originRoad'),
                dataType: 'string',
                caption: 'Originating Carrier',
                visible: true,
            },
            {
                dataField: nameof<BdsRoutePathCode>('pathCode'),
                dataType: 'string',
                caption: 'Path Code',
                visible: true,
            },
            {
                dataField: nameof<BdsRoutePathCode>('pathDescription'),
                dataType: 'string',
                caption: 'Path Description',
                visible: true,
            },
        ];
    }

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<BdsRoutePathCode>('contractNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<BdsRoutePathCode>('deliveryRoad'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<BdsRoutePathCode>('isTruckDeliveryLoadEmpty'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<BdsRoutePathCode>('originRoad'), new UntypedFormControl(''));
        formGroup.addControl(nameof<BdsRoutePathCode>('pathCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<BdsRoutePathCode>('pathDescription'), new UntypedFormControl(''));

        if (!!obj) {
            formGroup.setValue(obj);
        }

        return formGroup;
    }
}
