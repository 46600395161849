<dx-data-grid
    bds-grid
    id="bdsRouteSegmentGrid"
    [dataSource]="dataSource"
    [columns]="displayColumns"
    height="35vh"
    minHeight="200px"
    [(selectedRowKeys)]="selectedRowKeys"
    [bdsAllowAddRecord]="!readOnly"
    [bdsAllowDeleteRecords]="!readOnly"
    [bdsAllowAddRecord]="false"
    [bdsAllowDeleteRecords]="false"
    [bdsFastracVisible]="false"
    [bdsUseFastrac]="false"
    [needsRefresh]="false"
>
    <dxi-column dataField="segmentNo" caption="Segment No."></dxi-column>
    <dxi-column dataField="contractNo" caption="Contract No."></dxi-column>
    <dxi-column dataField="railroad" caption="Railroad"></dxi-column>
    <dxi-column dataField="junction" caption="Rule 260"></dxi-column>
    <!--    <dxi-column dataField="splcErpc.city" caption="ERPC City"></dxi-column>-->
    <!--    <dxi-column dataField="splcErpc.state" caption="ERPC State"></dxi-column>-->
    <dxi-column dataField="beginSplc" caption="Junction SPLC"></dxi-column>
    <dxi-column dataField="benchmarkHours" caption="Benchmark Hours"></dxi-column>
    <dxi-column dataField="mileageAllowanceIndicator" caption="Segment Miles"></dxi-column>
    <!--    <dxi-column dataField="segmentMiles.weightLimit" caption="Weight Limit"></dxi-column>-->
    <!--    <dxi-column dataField="segmentMiles.isActive" caption="Active Status"></dxi-column>-->
</dx-data-grid>
