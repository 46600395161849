import { Injectable } from '@angular/core';
import { RtEdiTransType } from '@bds/railtrac-models';

@Injectable({
    providedIn: 'root',
})
export class EdiTransTypeService {
    transTypes: RtEdiTransType[] = [];

    constructor() {
        this.GetAll();
    }

    GetAll(): RtEdiTransType[] {
        this.transTypes = [
            { id: 'A', name: 'Bill Only' },
            { id: 'B', name: 'Bill & Cancellation' },
            { id: 'C', name: 'Bill & Revision' },
            { id: 'R', name: 'Bill, Cancellation, & Revision' },
        ];
        return this.transTypes;
    }

    GetEDITransTypeName(transType: string): string {
        let returnValue = '';
        var result = this.transTypes.find((obj) => {
            return obj.id === transType;
        });
        if (result) {
            returnValue = result.name;
        }
        return returnValue;
    }
}
