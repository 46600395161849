import { PageService } from '@bds/core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { RtCustomerAlias, RtCustomerAliasAdapter } from '@bds/railtrac-models';
import {
    RtCustomerAliasService,
    RtCustomerAliasDialogComponent,
    RtCustomerAliasDialogOptions,
} from '@bds/railtrac';
import { MatDialog } from '@angular/material/dialog';
import { Observable, AsyncSubject } from 'rxjs';
import { faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { BdsDialogConfirmComponent, BdsDialogConfirmModel } from '@bds/components';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
    templateUrl: './customer-alias-screen.component.html',
    styleUrls: ['./customer-alias-screen.component.scss'],
})
export class CustomerAliasScreenComponent implements OnInit {
    public gridSource: DataSource | RtCustomerAlias[];
    public selectedRowKeys: Array<number | any> = [];

    public iconPlus = faPlus;
    public iconEdit = faPencilAlt;
    public iconDelete = faTrashAlt;

    public customButtons: any[] = [
        {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                hint: 'Add a record',
                onClick: this.createCustomerAlias.bind(this),
            },
        },
        {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'trash',
                hint: 'Delete records',
                onClick: this.deleteCustomerAliases.bind(this),
            },
        },
    ];

    @ViewChild('bdsCustomerAliasGrid') grid: DxDataGridComponent;

    constructor(
        public service: RtCustomerAliasService,
        public adapter: RtCustomerAliasAdapter,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
        public pageService: PageService,
    ) {
        pageService.setHeader('Customer Aliases');
    }

    ngOnInit() {
        this.gridSource = this.service.getODataStore();
    }

    createCustomerAlias() {
        const data = new RtCustomerAliasDialogOptions();
        data.beforeCloseAction = (item: RtCustomerAlias) =>
            this.createCustomerAliasCloseAction(item);
        data.title = 'Create new consignee alias';

        const dialogRef = this.dialog.open(RtCustomerAliasDialogComponent, {
            width: '850px',
            data: data,
        });
    }

    updateCustomerAlias(customerAlias: RtCustomerAlias) {
        const data = new RtCustomerAliasDialogOptions();
        data.item = customerAlias;
        data.beforeCloseAction = (item: RtCustomerAlias) =>
            this.updateCustomerAliasCloseAction(item);
        data.title = 'Edit consignee alias';

        const dialogRef = this.dialog.open(RtCustomerAliasDialogComponent, {
            width: '850px',
            data: data,
        });
    }

    deleteCustomerAlias(customerAlias: RtCustomerAlias) {
        const confirmInfo: BdsDialogConfirmModel = {
            content: `Are you sure you want to delete the alias
                ${customerAlias.custName.trim()} in ${customerAlias.custCity.trim()}, ${customerAlias.custState.trim()} as
                ${customerAlias.custNo.trim()}?`,
            actionText: 'Delete',
        };

        const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
            data: confirmInfo,
        });

        dialogRef.beforeClosed().subscribe((obs) => {
            if (!!obs) {
                this.deleteCustomerAliasCloseAction(customerAlias.ormId);
            }
        });
    }

    deleteCustomerAliases(e) {
        console.log('delete customer aliases', e);
        if (this.selectedRowKeys.length === 1) {
            const rowData = this.grid.instance.getSelectedRowsData()[0] as RtCustomerAlias;
            this.deleteCustomerAlias(rowData);
        }
        if (this.selectedRowKeys.length > 1) {
            const confirmInfo: BdsDialogConfirmModel = {
                content: `Are you sure you want to delete ${this.selectedRowKeys.length} aliases?`,
                actionText: 'Delete',
            };

            const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
                data: confirmInfo,
            });

            dialogRef.beforeClosed().subscribe((obs) => {
                if (!!obs) {
                    this.selectedRowKeys.forEach((key) => {
                        this.deleteCustomerAliasCloseAction(key);
                    });
                }
            });
        }
    }

    private createCustomerAliasCloseAction(item: RtCustomerAlias): Observable<boolean | string> {
        const subject = new AsyncSubject<boolean | string>();
        if (!!item) {
            const serverModel = this.adapter.toServer(item);
            this.service.add(serverModel).subscribe(
                (result) => {
                    subject.next(true);
                    subject.complete();
                    if (Array.isArray(this.gridSource)) {
                        this.gridSource.push(result);
                    } else {
                        this.gridSource.reload();
                    }
                },
                (err) => {
                    console.log('HTTP CLIENT ERROR', err);
                    this.snackbar.open('Unable to create alias.', 'OK', {
                        duration: 0,
                    });
                    subject.next(false);
                    subject.complete();
                },
            );
        }
        return subject.asObservable();
    }
    private updateCustomerAliasCloseAction(item: RtCustomerAlias): Observable<boolean | string> {
        const subject = new AsyncSubject<boolean | string>();
        if (!!item) {
            const serverModel = this.adapter.toServer(item);
            this.service.update(serverModel).subscribe(
                () => {
                    subject.next(true);
                    subject.complete();
                    if (Array.isArray(this.gridSource)) {
                        const idx = this.gridSource.findIndex((itm) => itm.ormId === item.ormId);
                        this.gridSource.splice(idx, 1, item);
                    } else {
                        this.gridSource.reload();
                    }
                },
                (err) => {
                    console.log('HTTP CLIENT ERROR', err);
                    this.snackbar.open('Unable to modify alias.', 'OK', {
                        duration: 0,
                    });
                    subject.next(false);
                    subject.complete();
                },
            );
        }
        return subject.asObservable();
    }
    private deleteCustomerAliasCloseAction(item: number): Observable<boolean | string> {
        const subject = new AsyncSubject<boolean | string>();
        if (!!item) {
            this.service.delete(item).subscribe(
                (result) => {
                    subject.next(true);
                    subject.complete();
                    if (Array.isArray(this.gridSource)) {
                        const idx = this.gridSource.findIndex((itm) => itm.ormId === result.ormId);
                        this.gridSource.splice(idx, 1);
                    } else {
                        this.gridSource.reload();
                    }
                },
                (err) => {
                    console.log('HTTP CLIENT ERROR', err);
                    this.snackbar.open('Unable to delete alias.', 'OK', {
                        duration: 8000,
                    });
                    subject.next(false);
                    subject.complete();
                },
            );
        }
        return subject.asObservable();
    }
}
