import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyDateFieldHelpersService, FormlyNumericFieldHelpersService } from '@bds/helpers';
import { RtTripRefFieldDef } from '@bds/railtrac-models';

@Injectable({
    providedIn: 'root',
})
export class FormlyFieldCreatorService {
    constructor(
        private numericFieldHelperService: FormlyNumericFieldHelpersService,
        private dateFieldHelperService: FormlyDateFieldHelpersService,
    ) {}

    createAlphnumericFormField(field: RtTripRefFieldDef): FormlyFieldConfig {
        const formField: FormlyFieldConfig = {
            key: field.tripRefField,
            type: 'bds-input',
            className: 'col-4',
            modelOptions: {
                debounce: {
                    default: 200,
                },
            },
            templateOptions: {
                label: field.displayName,
                appearance: 'fill',
                floatLabel: 'always',
            },
            expressionProperties: {},
        };

        if (field.dataType === 'N') {
            formField.templateOptions.type = 'number';

            // Set the decimal places
            formField.templateOptions.step = this.numericFieldHelperService.calculateStep(
                field.fractionDigits,
            );

            // Set the max
            const maxNum: number = this.numericFieldHelperService.calculateMax(
                field.totalDigits,
                field.fractionDigits,
            );
            if (maxNum) {
                formField.templateOptions.max = maxNum;
            }
        } else if (field.dataType === 'C') {
            formField.templateOptions.maxLength = 100;
        }

        return formField;
    }

    createDateFormGroup(
        field: RtTripRefFieldDef,
        displayFormat: string,
        serializationFormat: string,
    ): FormlyFieldConfig {
        const dateField: FormlyFieldConfig = {
            key: field.tripRefField,
            type: 'bds-datebox',
            className: 'col-4',
            templateOptions: {
                label: field.displayName,
                appearance: 'fill',
                floatLabel: 'always',
                displayFormat: displayFormat,
                serializationFormat: serializationFormat,
            },
            expressionProperties: {},
        };

        if ((field.repeatYorn || '').toUpperCase() === 'Y') {
            // TODO: Don't think this setup will work without a change in the data model being supplied

            const formField: FormlyFieldConfig = {
                fieldGroupClassName: 'row',
                fieldGroup: [],
            };

            const headerText: string = this.dateFieldHelperService.createRepeatIntervalText(
                field.repeatInterval,
                field.repeatIntervalUm,
            );
            const repeatHeader = {
                className: 'col-4',
                template: `<div class="bds-repeat-info text-right">${headerText}</div>`,
            };

            // TODO: Some styling based on alertPeriod
            const dueDateField: FormlyFieldConfig = {
                key: field.tripRefField + '.next',
                type: 'bds-datebox',
                className: 'col-4',
                templateOptions: {
                    label: 'Due Date',
                    appearance: 'fill',
                    floatLabel: 'always',
                    displayFormat: displayFormat,
                    serializationFormat: serializationFormat,
                },
            };

            formField.fieldGroup = [repeatHeader, dateField, dueDateField];

            return formField;
        } else {
            return dateField;
        }
    }

    createSelectFormField(field: RtTripRefFieldDef): FormlyFieldConfig {
        const formField: FormlyFieldConfig = {
            key: field.tripRefField,
            type: 'bds-select',
            className: 'col-4',
            templateOptions: {
                label: field.displayName,
                appearance: 'fill',
                floatLabel: 'always',
                options: field.rtTripFieldValues || [],
                valueProp: 'fieldValue',
                labelProp: 'fieldValue',
            },
            expressionProperties: {},
        };

        return formField;
    }
}
