<div class="sticky-top mat-elevation-z6">
    <mat-toolbar class="trip-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <span>
                <!-- <fa-icon [icon]="iconFlag" [fixedWidth]="true" size="lg"></fa-icon> -->
                Never Shipped
            </span>
            <span class="fill-remaining-space"></span>
            <span>
                <button mat-stroked-button (click)="removeFromFleet()">
                    <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
                    Remove from Fleet<span class="nyi"></span>
                </button>
                <a mat-stroked-button [routerLink]="addTripRouterLink" target="_blank">
                    <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
                    Add New Trip
                </a>
                <rt-jeopardized-shipment-reason-options
                    (resolve)="onResolve($event)"
                ></rt-jeopardized-shipment-reason-options>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<div class="container">
    <mat-expansion-panel class="mt-3">
        <mat-expansion-panel-header>
            <mat-panel-title> Interface Validations<span class="nyi"></span> </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            Interface Validations appear here<span class="nyi"></span>
        </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel class="mt-3">
        <mat-expansion-panel-header>
            <mat-panel-title> Fleet Assignment<span class="nyi"></span> </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            Fleet Assignment appear here<span class="nyi"></span>
        </ng-template>
    </mat-expansion-panel>
    <div class="container mt-3 mb-3">
        <rt-jeopardized-shipment-details [shipment]="shipment"></rt-jeopardized-shipment-details>
    </div>
</div>
