<h4 *ngIf="!!data.title" mat-dialog-title>{{ data.title }}</h4>

<div mat-dialog-content>
    <bds-fastrac-design-display
        [gridId]="gridId"
        [fastracId]="fastracId"
        [showFastrac]="showFastrac"
        (showFastracChange)="onFastracVisibleChanged($event)"
        (fastracIdChange)="onFastracIdChanged($event)"
        (fastracDesignsChange)="onFastracDesignsChanged($event)"
        (applyFastrac)="onApplyFastrac($event)"
    >
    </bds-fastrac-design-display>
    <bds-grid-details>
        <ng-container grid>
            <bds-fastrac-dx-grid-wrapper
                #dxGridWrapper
                bds-grid
                [gridId]="gridId"
                id="bdsCustomerSearchGrid"
                [dataSource]="dataSource"
                [selectedRowKeys]="selectedRowKeys"
                height="50vh"
                (onKeyDown)="onKeyDown($event)"
                (bdsShowFastrac)="onShowFastrac()"
                (fastracIdChange)="onGridFastracIdChanged($event)"
                [bdsFastracDesignList]="fastracList"
                [fastracDesign]="currentFastrac"
                [fastracId]="fastracId"
                [showColumnChooser]="true"
                [key]="'ormId'"
            >
            </bds-fastrac-dx-grid-wrapper>
        </ng-container>
    </bds-grid-details>
</div>

<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" cdkFocusInitial mat-dialog-close>
        {{ data.dismissiveText }}
    </button>
    <button mat-button class="text-uppercase" color="accent" (click)="onSelectClick()">
        {{ data.actionText }}
    </button>
</div>
