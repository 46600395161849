import { Component } from '@angular/core';
import { RtSplcErpc } from '@bds/railtrac-models';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'bds-splc-formly',
    template: `
        <bds-splc-select
            [label]="field.templateOptions.label"
            [formControl]="formControl"
            [formlyAttributes]="field"
            [valueExpr]="undefined"
            [displayValue]="displayState"
            [searchDisplay]="displayState"
            [initValue]="formControl.value"
        >
        </bds-splc-select>
    `,
})
export class BdsSplcFormlyComponent extends FieldType {
    constructor() {
        super();
    }

    displayState = (data: RtSplcErpc): string =>
        data && data.erpcCity && data.erpcState ? `${data.erpcCity}, ${data.erpcState}` : '';
}
