import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { RtSegmentMiles } from '@bds/railtrac-models';
import { SegmentMilesFormService } from '../segment-miles-form.service';

@Component({
    selector: 'bds-segment-miles-form',
    templateUrl: './bds-segment-miles-form.component.html',
    styleUrls: ['./bds-segment-miles-form.component.css'],
    providers: [SegmentMilesFormService],
})
export class BdsSegmentMilesFormComponent implements OnInit {
    constructor(private formService: SegmentMilesFormService) {}

    private _segmentMiles: RtSegmentMiles[];

    get segmentMiles() {
        return this._segmentMiles;
    }

    @Input() set segmentMiles(value: RtSegmentMiles[]) {
        this._segmentMiles = value;
    }

    get form(): UntypedFormArray {
        return this.formService.form;
    }

    get valid(): boolean {
        return this.form.controls.every((x) => x.valid);
    }

    ngOnInit(): void {}
}
