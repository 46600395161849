import { Component } from '@angular/core';
import { PageService } from '@bds/core';

@Component({
    selector: 'app-admin-screen',
    templateUrl: './admin-screen.component.html',
    styleUrls: ['./admin-screen.component.scss'],
})
export class AdminScreenComponent {
    constructor(public pageService: PageService) {
        pageService.setHeader('Admin');
    }
}
