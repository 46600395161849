import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RtArrAtDest, RtArrAtDestAdapter } from '@bds/reference-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import DataSource from 'devextreme/data/data_source';

// export abstract class BdsDestinationCriteriaApiServiceOptions {
//     apiUrl = `api/destinationcriteria/`;
//     odataUrl = `odata/destinationcriteria`;
// }

@Injectable({
    providedIn: 'root',
})
export class BdsDestinationCriteriaService {
    controllerName = 'destinationcriteria';

    constructor(
        private http: HttpClient,
        //private options: BdsDestinationCriteriaApiServiceOptions,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        public adapter: RtArrAtDestAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    create(destination: RtArrAtDest): Observable<RtArrAtDest> {
        return (
            this.http
                .post<RtArrAtDest>(
                    `${this.apibaseUrl}${this.controllerName}/`,
                    this.adapter.toServer(destination),
                )
                //.post<RtArrAtDest>(`${this.options.apiUrl}`, this.adapter.toServer(destination))
                .pipe(
                    map((data: any) => {
                        return !!data ? this.adapter.adapt(data) : null;
                    }),
                    catchError((err) => this.apiErrorHandler.handleError(err)),
                )
        );
    }

    delete(key: number): Observable<RtArrAtDest> {
        //return this.http.delete<RtArrAtDest>(`${this.options.apiUrl}/${key}`).pipe(
        return this.http
            .delete<RtArrAtDest>(`${this.apibaseUrl}${this.controllerName}/${key}`)
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getDataSource(): DataSource {
        return new DataSource({
            store: this.getODataStore(),
            paginate: true,
            pageSize: 20,
        });
    }

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtArrAtDest>('ormId'),
        ).server;

        return new ODataStore({
            version: 4,
            //url: this.options.odataUrl,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: keyField,
        });
    }

    patch(destination: RtArrAtDest): Observable<RtArrAtDest> {
        console.warn('RailtracService.patch() is a dummy method');
        return of(destination).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    read(key: number, expand?: string[]): Observable<RtArrAtDest> {
        return this.readViaOdata(key, expand);
    }

    readViaApi(key: number): Observable<RtArrAtDest> {
        //return this.http.get<RtArrAtDest>(`${this.options.apiUrl}/${key}`).pipe(
        return this.http.get<RtArrAtDest>(`${this.apibaseUrl}${this.controllerName}/${key}`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    readViaOdata(key: number, expand?: string[]): Observable<RtArrAtDest> {
        let expandUrl = '';
        if (!!expand && expand.length > 0) {
            expandUrl = '?$expand=';
            expand.forEach((child) => (expandUrl = expandUrl + child + ','));
            expandUrl = expandUrl.slice(0, expandUrl.length - 1);
        }

        //return this.http.get<RtArrAtDest>(`${this.options.odataUrl}(${key})` + expandUrl).pipe(
        return this.http
            .get<RtArrAtDest>(`${this.odatabaseUrl}${this.controllerName}(${key})` + expandUrl)
            .pipe(
                map((data: any) => this.adapter.adapt(data)),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    update(key: number, destination: RtArrAtDest): Observable<RtArrAtDest> {
        return (
            this.http
                //.put<RtArrAtDest>(`${this.options.apiUrl}/${key}`, this.adapter.toServer(destination))
                .put<RtArrAtDest>(
                    `${this.apibaseUrl}${this.controllerName}/${key}`,
                    this.adapter.toServer(destination),
                )
                .pipe(
                    map((data: any) => {
                        return !!data ? this.adapter.adapt(data) : null;
                    }),
                    catchError((err) => this.apiErrorHandler.handleError(err)),
                )
        );
    }
}
