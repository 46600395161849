<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>

<div *ngIf="displayReport">
    <div class="container-fluid">
        <bds-fastrac-design-display
            [gridId]="gridId"
            [fastracId]="fastracId"
            [showFastrac]="showFastrac"
            [showGrouping]="true"
            (showFastracChange)="onFastracVisibleChanged($event)"
            (fastracIdChange)="onFastracIdChanged($event)"
            (fastracDesignsChange)="onFastracDesignsChanged($event)"
            (applyFastrac)="onApplyFastrac($event)"
        >
        </bds-fastrac-design-display>
    </div>

    <div class="railroad-grid-container">
        <dx-data-grid
            bds-grid
            #bdsReportGrid
            id="bdsReportGrid"
            [dataSource]="gridSource"
            [(selectedRowKeys)]="selectedRowKeys"
            height="80vh"
            minHeight="200px"
            [gridId]="gridId"
            (bdsShowFastrac)="onShowFastrac()"
            (fastracIdChange)="onGridFastracIdChanged($event)"
            [bdsFastracDesignList]="fastracList"
            [bdsFastracVisible]="showFastrac"
            [bdsUseFastrac]="true"
            [fastracDesign]="currentFastrac"
            [fastracId]="fastracId"
            [bdsCustomizeColumns]="customizeColumns"
            [(bdsGridMode)]="gridMode"
            [bdsAllowAddRecord]="false"
            [bdsAllowDeleteRecords]="false"
            [bdsAllowGrouping]="true"
            [bdsUseZeroPadDates]="true"
        >
        </dx-data-grid>
    </div>
</div>
