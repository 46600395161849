import { Component, Input, OnInit } from '@angular/core';
import { bdsGridModeType } from '@bds/components';
import DataSource from 'devextreme/data/data_source';
import { BdsPathCodeService } from '../bds-path-code.service';

@Component({
    selector: 'bds-path-code-grid',
    templateUrl: './bds-path-code-grid.component.html',
    styleUrls: ['./bds-path-code-grid.component.css'],
})
export class BdsPathCodeGridComponent implements OnInit {
    dataSource: DataSource;
    fastracId: number = 0;
    @Input() gridId: number = 4; //TODO what is this?
    gridMode: bdsGridModeType = 'single';
    showFastrac = false;

    constructor(private pathCodeService: BdsPathCodeService) {}

    ngOnInit(): void {
        this.dataSource = new DataSource({
            pageSize: 15,
            paginate: true,
            store: this.pathCodeService.getODataStore(),
        });
    }
}
