import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-trip-product-screen',
    templateUrl: './trip-product-screen.component.html',
    styleUrls: ['./trip-product-screen.component.scss'],
})
export class TripProductScreenComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
