<form>
    <mat-table [dataSource]="form?.controls" style="align-content: space-between">
        <ng-container matColumnDef="acknowledgeFlag">
            <mat-header-cell *matHeaderCellDef class="w-75">
                <mat-checkbox (change)="ackAll($event)">ACK</mat-checkbox>
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element" class="w-75">
                <mat-checkbox
                    [checked]="element.controls?.acknowledgeFlag.value === 'Y'"
                    (change)="setFlag($event, element.controls?.acknowledgeFlag)"
                ></mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="carInit">
            <mat-header-cell *matHeaderCellDef>Car Init</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                <ng-container *ngIf="action === 'add'">
                    <div class="col-12">
                        <mat-form-field
                            class="highlight-dirty bds-mat-dense-form-field"
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                        >
                            <mat-label>Car Init <span class="dirty-indicator">*</span> </mat-label>
                            <input matInput [formControl]="element.controls.carInit" />
                        </mat-form-field>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="carNo">
            <mat-header-cell *matHeaderCellDef>Car No</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                <ng-container *ngIf="action === 'add'">
                    <div class="col-12">
                        <mat-form-field
                            class="highlight-dirty bds-mat-dense-form-field"
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                        >
                            <mat-label>Car No<span class="dirty-indicator">*</span> </mat-label>
                            <input matInput [formControl]="element.controls.carNo" />
                        </mat-form-field>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="carId">
            <mat-header-cell *matHeaderCellDef>Car ID</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                <ng-container *ngIf="action === 'acknowledge'">
                    {{ element.controls.carInit.value.trim()
                    }}{{ element.controls.carNo.value.trim() }}
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="commentType">
            <mat-header-cell *matHeaderCellDef>Comment Type</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                <ng-container *ngIf="action === 'acknowledge'">
                    {{ element.controls.commentType.value }}
                </ng-container>
                <ng-container *ngIf="action === 'add'">
                    <div class="col-12">
                        <mat-form-field
                            class="highlight-dirty bds-mat-dense-form-field"
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                        >
                            <mat-label
                                >Comment Type <span class="dirty-indicator">*</span>
                            </mat-label>
                            <input matInput [formControl]="element.controls.commentType" />
                        </mat-form-field>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="commentCode">
            <mat-header-cell *matHeaderCellDef>Comment Code</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                <ng-container *ngIf="action === 'acknowledge'">
                    {{ element.controls.commentCode.value }}
                </ng-container>
                <ng-container *ngIf="action === 'add'">
                    <div class="col-12">
                        <mat-form-field
                            class="highlight-dirty bds-mat-dense-form-field"
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                        >
                            <mat-label
                                >Comment Code <span class="dirty-indicator">*</span>
                            </mat-label>
                            <input matInput [formControl]="element.controls.commentCode" />
                        </mat-form-field>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="hotListDate">
            <mat-header-cell *matHeaderCellDef>Hot List Date</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                <ng-container *ngIf="action === 'acknowledge'">
                    {{ element.controls.hotListDate.value | date : 'MM/dd/yyyy HH:mm' : 'UTC' }}
                </ng-container>
                <ng-container *ngIf="action === 'add'">
                    <bds-date-time-picker
                        [formControl]="element.controls.hotListDate"
                        label="Hot List Date"
                        type="date"
                        displayFormat="MM/dd/yyyy"
                    ></bds-date-time-picker>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="hotListType">
            <mat-header-cell *matHeaderCellDef>Hot List Type</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                <ng-container *ngIf="action === 'acknowledge'">
                    {{ element.controls.hotListType.value }}
                </ng-container>
                <ng-container *ngIf="action === 'add'">
                    <div class="col-12">
                        <mat-form-field
                            class="highlight-dirty bds-mat-dense-form-field"
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                        >
                            <mat-label
                                >Hot List Type <span class="dirty-indicator">*</span>
                            </mat-label>
                            <input matInput [formControl]="element.controls.hotListType" />
                        </mat-form-field>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="issue">
            <mat-header-cell *matHeaderCellDef class="w-400">Issue</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element" class="w-400">
                <mat-form-field
                    class="highlight-dirty bds-mat-dense-form-field"
                    floatLabel="always"
                    class="w-100"
                    appearance="fill"
                >
                    <mat-label>Issue <span class="dirty-indicator">*</span> </mat-label>
                    <textarea
                        matInput
                        bdsShoutcase
                        [formControl]="element.controls.comments"
                        rows="3"
                        cols="100"
                    ></textarea>
                </mat-form-field>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="response">
            <mat-header-cell *matHeaderCellDef class="w-400">User Response</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element" class="w-400">
                <mat-form-field
                    class="response-area"
                    floatLabel="always"
                    class="w-100"
                    appearance="fill"
                >
                    <mat-label>Response<span class="dirty-indicator">*</span></mat-label>
                    <textarea
                        matInput
                        bdsShoutcase
                        [formControl]="element.controls.userResponse"
                        rows="3"
                        cols="100"
                    ></textarea>
                </mat-form-field>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="userId">
            <mat-header-cell *matHeaderCellDef>User ID</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                {{ element.controls.userId.value }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="acknowledgeDate">
            <mat-header-cell *matHeaderCellDef>Acknowledge Date</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                {{ element.controls.acknowledgeDate.value | date : 'MM/dd/yyyy HH:mm' : 'UTC' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="acknowledgeUserId">
            <mat-header-cell *matHeaderCellDef>Acknowledged By</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                {{ element.controls.acknowledgeUserId.value }}
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
</form>
<ng-template #responseDialog let-data>
    <h2 mat-dialog-title>Update Responses</h2>
    <mat-dialog-content style="min-height: 200px; min-width: 350px">
        <div>
            <mat-form-field floatLabel="always" appearance="fill" style="display: inherit">
                <mat-label>Response<span class="dirty-indicator">*</span></mat-label>
                <textarea
                    matInput
                    bdsShoutcase
                    rows="3"
                    [(ngModel)]="data['response']"
                    name="response"
                    cols="100"
                ></textarea>
            </mat-form-field>
        </div>
        <div *ngFor="let item of types; let i = index">
            <mat-checkbox
                [checked]="selected === i"
                (change)="selected = i; data['selectedType'] = item?.type ?? 'none'"
                >{{ item?.description }}</mat-checkbox
            >
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
        <button mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</ng-template>
