import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { bdsGridModeType } from '@bds/components';
import { CommunicationService } from '@bds/core';
import { FastracScreenBaseComponent } from '@bds/fastrac';
import { CarIdTransformService } from '@bds/helpers';
import { FastracGrid } from '@bds/models';
import { RtSegmentMiles } from '@bds/railtrac-models';
import { FastracColsService } from '@bds/services';
import DataSource from 'devextreme/data/data_source';
import { BdsSegmentMilesService } from '../bds-segment-miles.service';

@Component({
    selector: 'bds-segment-miles-grid',
    templateUrl: './bds-segment-miles-grid.component.html',
    styleUrls: ['./bds-segment-miles-grid.component.css'],
})
export class BdsSegmentMilesGridComponent
    extends FastracScreenBaseComponent
    implements OnInit, OnDestroy
{
    dataSource: DataSource;
    fastracId = 0;
    @Input() gridId: number = FastracGrid.segmentMiles;
    gridMode: bdsGridModeType = 'single';
    @Output() selectedSegments = new EventEmitter<RtSegmentMiles[]>();
    showFastrac = false;

    constructor(
        private segmentMilesService: BdsSegmentMilesService,
        router: Router,
        route: ActivatedRoute,
        gridColumnService: FastracColsService,
        communicationService: CommunicationService,
        carIdTransformService: CarIdTransformService,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
    }

    add() {
        this.selectedSegments.emit(null);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    ngOnInit(): void {
        this.dataSource = new DataSource({
            pageSize: 15,
            paginate: true,
            store: this.segmentMilesService.getODataStore(),
        });
        super.ngOnInit();
    }

    selectionChanged(selection) {
        this.selectedSegments.emit(selection);
    }
}
