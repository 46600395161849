<h1 mat-dialog-title>{{ data.title }}</h1>
<div class="row">
    <div *ngIf="errors && errors.length" class="error-warning">
        <div *ngFor="let error of errors">{{ error.errorMessage }}</div>
    </div>
</div>
<div mat-dialog-content style="width: 1500px">
    <ng-container *ngIf="data.hotLists?.length > 1">
        <bds-hotlist-form-item [isBulkForm]="true" [formGroup]="bulkForm"></bds-hotlist-form-item>
        <ng-container *ngIf="warningCount > 0">
            <small class="text-warning">
                <fa-icon [icon]="iconWarning"></fa-icon>
                {{ warningCount }} of the Hot Lists selected most recent trips
                {{ warningCount > 1 ? 'are' : 'is' }} closed.
            </small>
        </ng-container>
    </ng-container>
    <mat-expansion-panel *ngIf="data.hotLists?.length > 1; else formTemplate" #expansionPanel>
        <mat-expansion-panel-header>
            <mat-panel-title> Set Individual Hot List Info </mat-panel-title>
        </mat-expansion-panel-header>
        <div
            class="scrollable-volume"
            [ngStyle]="{ display: expansionPanel.expanded ? 'inherit' : 'none' }"
        >
            <ng-container
                *ngFor="let item of data.hotLists; let i = index; let l = last; let f = first"
            >
                <ng-container *ngIf="!f">
                    <br />
                </ng-container>
                <bds-hotlist-form-item [hotList]="item" [index]="i"></bds-hotlist-form-item>
                <ng-container *ngIf="!item.propagateHotListForward">
                    <small class="text-warning">
                        <fa-icon [icon]="iconWarning"></fa-icon>
                        The most recent trip for this equipment is closed.
                    </small>
                </ng-container>
                <ng-container *ngIf="!l">
                    <hr />
                </ng-container>
            </ng-container>
        </div>
    </mat-expansion-panel>

    <ng-template #formTemplate>
        <ng-container *ngIf="data.hotLists?.length === 1">
            <ng-container *ngFor="let item of data.hotLists; let i = index">
                <bds-hotlist-form-item [hotList]="item" [index]="i"></bds-hotlist-form-item>
            </ng-container>
        </ng-container>
    </ng-template>
</div>
<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" mat-dialog-close>Cancel</button>
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        (click)="save()"
        [disabled]="isSaving || !form.valid"
        cdkFocusInitial
    >
        Save
    </button>
</div>
