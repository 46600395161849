<div class="equip-card">
    <mat-card>
        <div class="card-title">Edit Selected Date</div>

        <div [formGroup]="dateForm">
            <div class="row">
                <div class="col-11">
                    <dx-date-box
                        formControlName="actualDate"
                        class="w-100 bds-dx-custom-material bds-mat-dense-form-field dx-mat-form-field-floatLabel-always"
                        label="Date Performed"
                        [stylingMode]="'filled'"
                        type="date"
                        dateSerializationFormat="yyyy-MM-dd"
                        [displayFormat]="dateFormat"
                    >
                    </dx-date-box>
                    <mat-error *ngIf="dateForm.controls.actualDate.errors">
                        {{ getError(dateForm.controls.actualDate) }}
                    </mat-error>
                </div>
                <div class="col-1">
                    <button
                        mat-flat-button
                        type="button"
                        class="delete-button"
                        color="warn"
                        title="Delete Date"
                        (click)="onDeleteClick()"
                    >
                        <fa-icon [icon]="iconDelete"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="row" *ngIf="hasDueDate">
                <div class="col-12">
                    <dx-date-box
                        formControlName="nextDueDate"
                        class="w-100 bds-dx-custom-material bds-mat-dense-form-field dx-mat-form-field-floatLabel-always"
                        label="Due Date"
                        [stylingMode]="'filled'"
                        type="date"
                        dateSerializationFormat="yyyy-MM-dd"
                        [displayFormat]="dateFormat"
                    >
                    </dx-date-box>
                    <mat-error *ngIf="dateForm.controls.nextDueDate.errors">
                        {{ getError(dateForm.controls.nextDueDate) }}
                    </mat-error>
                </div>
            </div>
        </div>
    </mat-card>
</div>
