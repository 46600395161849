<dx-select-box
    class="bds-dx-custom-material flex-fill"
    #{{id}}
    [class.bds-mat-dense-form-field]="dense"
    [class.bds-readonly-wrapper]="readonly"
    [class.dx-mat-form-field-floatLabel-always]="matFloatLabel == 'always'"
    [dataSource]="dataSource"
    [disabled]="disabled"
    [isValid]="ngControl.control.valid"
    [(selectedItem)]="selectedItem"
    [searchExpr]="searchExp"
    [searchEnabled]="true"
    [stylingMode]="style"
    [value]="value"
    [valueExpr]="valueExp"
    fieldTemplate="inputField"
    itemTemplate="item"
    label="Consignee"
    validationMessageMode="always"
    (onBlur)="onBlur()"
    (onSelectionChanged)="onSelectionChanged($event)"
>
    <dxi-button name="addConsignee" [options]="consigneeAddInlineButtonOptions"></dxi-button>
    <dxi-button name="searchConsignee" [options]="consigneeSearchInlineButtonOptions"></dxi-button>
    <dxi-button name="dropDown"></dxi-button>
    <div *dxTemplate="let data of 'item'">
        {{ getDisplayValue(data) }}
    </div>
    <ng-container *dxTemplate="let data of 'inputField'">
        <dx-text-box label="Consignee" [value]="getDisplayValue(data)"></dx-text-box>
    </ng-container>
</dx-select-box>
<mat-error *ngIf="ngControl && ngControl.control.invalid && ngControl.control.touched">
    {{ getError() }}
</mat-error>
<mat-hint *ngIf="isRequired">Required</mat-hint>
