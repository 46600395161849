<h1 mat-dialog-title>Origin Info</h1>

<mat-dialog-content>
    <div class="bds-error-message">{{ errorMessage }}</div>
    <div>
        <bds-erpc-origin-details
            [selectedOrigin]="currentOrigin"
            [splcErpcStore]="data.splcStore"
            [isNew]="data.isNew"
            (originChange)="onOriginChanged($event)"
            (validChange)="onValidChanged($event)"
            (splcChange)="onSplcChanged($event)"
        >
        </bds-erpc-origin-details>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" (click)="onCancelClick()">
        Cancel
    </button>

    <button
        mat-button
        [disabled]="!isValid"
        class="text-uppercase"
        color="accent"
        (click)="onSaveClick()"
    >
        Save Origin
    </button>
</mat-dialog-actions>
