import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtCarAssign implements From<RtCarAssign> {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public fleetId?: string,
        public businessGroup?: string,
        public carUserId?: string,
    ) {}
    from(trip: RtCarAssign): RtCarAssign {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtCarAssign>('carInit'), server: 'carInit' },
    { client: nameof<RtCarAssign>('carNo'), server: 'carNo' },
    { client: nameof<RtCarAssign>('fleetId'), server: 'fleetId' },
    { client: nameof<RtCarAssign>('businessGroup'), server: 'businessGroup' },
    { client: nameof<RtCarAssign>('carUserId'), server: 'carUserId' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCarAssignAdapter extends TwoWayAdapter<RtCarAssign> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtCarAssignMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtCarAssign>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarAssign>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarAssign>('fleetId'),
                dataType: 'string',
                caption: 'fleetId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarAssign>('businessGroup'),
                dataType: 'string',
                caption: 'businessGroup',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarAssign>('carUserId'),
                dataType: 'string',
                caption: 'carUserId',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtCarAssign>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarAssign>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarAssign>('fleetId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarAssign>('businessGroup'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarAssign>('carUserId'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
