import { RailtracTripService } from '../../../railtrac-trip/railtrac-trip.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntil, take } from 'rxjs/operators';
import { RtJeopardizedShipment, RtTrip } from '@bds/railtrac-models';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'rt-jeopardized-shipment-multiple-open',
    templateUrl: './rt-jeopardized-shipment-multiple-open.component.html',
    styleUrls: ['./rt-jeopardized-shipment-multiple-open.component.scss'],
})
export class RtJeopardizedShipmentMultipleOpenComponent implements OnInit, OnChanges {
    @Input() shipment: RtJeopardizedShipment;

    openTrips: RtTrip[] = [];

    constructor(
        public tripService: RailtracTripService,
        public snackbar: MatSnackBar,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        const shipment = changes['shipment'];
        if (!!shipment.currentValue) {
            const cv: RtJeopardizedShipment = shipment.currentValue;
            this.tripService.findOpenTrip(cv.carInitials, cv.carNumber).subscribe((trips) => {
                this.openTrips = Array.isArray(trips) ? [...trips] : [trips];
            });
        }
    }

    onPopoutTripclicked($event: number) {}

    onResolve(event: string) {
        // TODO: Based on setup, not sure how to get trip
    }

    onTripSaveClicked(tripDetails: RtTrip) {
        this.tripService.update(tripDetails.ormId, tripDetails).subscribe((upTrip) => {
            this.snackbar.open('Trip Saved!', 'Ok', {
                duration: 3000,
            });
        });
    }
}
