import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { RtArrAtDest, RtClmSightCode, RtStateCode } from '@bds/railtrac-models';
import { FormErrorHandlerService } from '@bds/helpers';

@Component({
    selector: 'bds-destination-criteria-details',
    templateUrl: './bds-destination-criteria-details.component.html',
    styleUrls: ['./bds-destination-criteria-details.component.scss'],
})
export class BdsDestinationCriteriaDetailsComponent implements OnInit, OnChanges, OnDestroy {
    customerSource: DataSource;
    // TODO: BE CAREFUL HERE!! These names are not consistent between models!!!
    //customerDisplayExpr: string[] = ['customerNo', 'customerName'];
    customerDisplayExpr: string = 'customerNo customerName';
    destinationForm: UntypedFormGroup;
    railroadSource: DataSource;
    //railroadDisplayExpr: string[] = ['rrScac', 'rrName'];
    railroadDisplayExpr: string = 'rrScac rrName';

    private ngUnsubscribe$: Subject<void> = new Subject<void>();

    @Input() cities: ODataStore;
    @Input() customerStore: ODataStore;
    @Input() destinationCriteria: RtArrAtDest;
    @Input() isClone: boolean = false;
    @Input() isNew: boolean = true;
    @Input() railroadStore: ODataStore;
    @Input() sightCodes: RtClmSightCode[] = [];
    @Input() states: RtStateCode[] = [];
    @Input() editableCustomer: boolean = true;

    // For form styles
    @Input() dense = true;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';

    @Output() destinationCriteriaChange: EventEmitter<{ value: RtArrAtDest; isValid: boolean }> =
        new EventEmitter();

    get dxMatStyle(): string {
        switch (this.matStyle) {
            case 'fill':
                return 'filled';
            case 'outline':
                return 'outlined';
            case 'standard':
                return 'underlined';
            default:
                return 'underlined';
        }
    }

    constructor(private formErrorService: FormErrorHandlerService) {
        this.destinationForm = this.getFormGroup();
    }

    ngOnInit() {
        this.destinationForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            let isValid: boolean;

            if (this.destinationForm.errors || this.destinationForm.invalid) {
                isValid = false;
            } else {
                isValid = true;
            }

            this.destinationCriteriaChange.emit({
                value: this.destinationForm.getRawValue(),
                isValid: isValid,
            });
        });

        if (this.destinationCriteria) {
            // Need to trim to match
            if (this.destinationCriteria.railroad) {
                this.destinationCriteria.railroad = this.destinationCriteria.railroad.trim();
            }

            this.destinationForm.patchValue(this.destinationCriteria);
        }

        this.enableOrDisableFields();

        if (this.customerStore) {
            this.setUpCustomerOptions();
        }

        if (this.railroadStore) {
            this.setUpRailroadOptions();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.destinationCriteria &&
            !changes.destinationCriteria.firstChange &&
            this.destinationCriteria
        ) {
            if (this.destinationCriteria.railroad) {
                this.destinationCriteria.railroad = this.destinationCriteria.railroad.trim();
            }

            this.destinationForm.patchValue(this.destinationCriteria);
            this.resetFields();
            this.enableOrDisableFields();
        }

        if (changes.customerStore && !changes.customerStore.firstChange && this.customerStore) {
            this.setUpCustomerOptions();
        }

        if (changes.railroadStore && !changes.railroadStore.firstChange && this.railroadStore) {
            this.setUpRailroadOptions();
        }

        if (
            (changes.isNew && !changes.isNew.firstChange) ||
            (changes.isClone && !changes.isClone.firstChange) ||
            (changes.editableCustomer && !changes.editableCustomer.firstChange)
        ) {
            this.enableOrDisableFields();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    enableOrDisableFields() {
        if (this.isNew) {
            this.destinationForm.get(nameof<RtArrAtDest>('railroad')).enable();
            this.destinationForm.get(nameof<RtArrAtDest>('locationState')).enable();
        } else {
            this.destinationForm.get(nameof<RtArrAtDest>('railroad')).disable();
            this.destinationForm.get(nameof<RtArrAtDest>('locationState')).disable();
        }

        if (this.editableCustomer) {
            this.destinationForm.get(nameof<RtArrAtDest>('railroad')).enable();
            this.destinationForm.get(nameof<RtArrAtDest>('locationCity')).enable();
            this.destinationForm.get(nameof<RtArrAtDest>('locationState')).enable();
        }

        if (this.isNew && this.editableCustomer) {
            this.destinationForm.get(nameof<RtArrAtDest>('customerNo')).enable();
        } else {
            this.destinationForm.get(nameof<RtArrAtDest>('customerNo')).disable();
        }

        if (this.isNew || this.isClone || this.editableCustomer) {
            this.destinationForm.get(nameof<RtArrAtDest>('sightCode')).enable();
        } else {
            this.destinationForm.get(nameof<RtArrAtDest>('sightCode')).disable();
        }
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        return this.formErrorService.getFormError(formItem);
    }

    getFormGroup(): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<RtArrAtDest>('customerNo'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(13)]),
        );
        formGroup.addControl(
            nameof<RtArrAtDest>('locationCity'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtArrAtDest>('sightCode'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(1)]),
        );
        formGroup.addControl(
            nameof<RtArrAtDest>('railroad'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(4)]),
        );
        formGroup.addControl(
            nameof<RtArrAtDest>('locationState'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(2)]),
        );
        formGroup.addControl(
            nameof<RtArrAtDest>('customerDeliveryHours'),
            new UntypedFormControl('', [Validators.max(999), Validators.min(0)]),
        );
        formGroup.addControl(nameof<RtArrAtDest>('ormId'), new UntypedFormControl(''));

        return formGroup;
    }

    resetFields() {
        this.destinationForm.get(nameof<RtArrAtDest>('sightCode')).markAsUntouched();
        this.destinationForm.get(nameof<RtArrAtDest>('railroad')).markAsUntouched();
        this.destinationForm.get(nameof<RtArrAtDest>('locationCity')).markAsUntouched();
        this.destinationForm.get(nameof<RtArrAtDest>('locationState')).markAsUntouched();
    }

    setUpCustomerOptions() {
        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        this.customerSource = new DataSource({
            store: this.customerStore,
            paginate: true,
            pageSize: 100,
            searchExpr: this.customerDisplayExpr,
            select: ['customerNo', 'customerName'],
            sort: ['customerNo'],
            key: 'customerNo',
        });
    }

    setUpRailroadOptions() {
        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        this.railroadSource = new DataSource({
            store: this.railroadStore,
            paginate: true,
            pageSize: 100,
            searchExpr: this.railroadDisplayExpr,
            select: ['rrScac', 'rrName'],
            sort: ['rrScac'],
            key: 'rrScac',
        });
    }
}
