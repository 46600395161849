import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faSave, faSpinner } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'bds-equipment-report-categories-menu',
    templateUrl: './equipment-report-categories-menu.component.html',
    styleUrls: ['./equipment-report-categories-menu.component.scss'],
})
export class EquipmentReportCategoriesMenuComponent {
    iconSave = faSave;
    iconSpinner = faSpinner;

    @Input() canSave = true;
    @Input() equipInit: string;
    @Input() equipNumber: string;
    @Input() isSaving = false;
    @Input() isValid = true;
    @Input() saveText = 'Save Equipment';

    @Output() saveChanges: EventEmitter<void> = new EventEmitter<void>();

    onSaveChanges(): void {
        this.saveChanges.emit();
    }
}
