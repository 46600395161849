<div [formGroup]="originForm" class="origin-criteria-details">
    <div class="row">
        <div class="col-8">
            <dx-lookup
                [stylingMode]="dxMatStyle"
                [ngClass]="{
                    'bds-readonly-wrapper': !isNew || !editableOriginCode,
                    'bds-mat-dense-form-field': dense,
                    'dx-mat-form-field-floatLabel-always': matFloatLabel == 'always'
                }"
                class="bds-dx-custom-material flex-fill"
                name="originCode"
                formControlName="originCode"
                [dataSource]="originCodeSource"
                [displayExpr]="originCodeDisplayExpr"
                [valueExpr]="'originCode'"
                [searchEnabled]="true"
                searchTimeout="200"
                itemTemplate="item"
                fieldTemplate="field"
                [showClearButton]="true"
                label="Return Origin Code"
            >
                <div *dxTemplate="let data of 'field'">
                    <div class="dx-placeholder" *ngIf="!data">Select Origin Code</div>
                    <div class="custom-icon" *ngIf="data">
                        {{ data.originCode }} - {{ data.erpcOrigin }}, {{ data.erpcState }}
                    </div>
                </div>
                <div *dxTemplate="let data of 'item'">
                    <div class="custom-icon">
                        {{ data.originCode }} - {{ data.erpcOrigin }}, {{ data.erpcState }}
                    </div>
                </div>
            </dx-lookup>
            <mat-error *ngIf="originForm.controls.originCode.errors">
                {{ getError($any(originForm).controls.originCode) }}
            </mat-error>
        </div>
        <div class="col-4">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Origin Delivery Hrs</mat-label>
                <input
                    matInput
                    type="number"
                    formControlName="originDeliveryHours"
                    min="0"
                    max="999"
                />
                <mat-error *ngIf="originForm.controls.originDeliveryHours.errors">
                    {{ getError($any(originForm).controls.originDeliveryHours) }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                [ngClass]="{
                    'bds-readonly-wrapper': !isClone && !isNew,
                    'bds-mat-dense-form-field': dense
                }"
                appearance="fill"
            >
                <mat-label>Sight Code</mat-label>
                <mat-select formControlName="sightCode">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let item of sightCodes" [value]="item.id">
                        {{ item.id }} - {{ item.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="originForm.controls.sightCode.errors">
                    {{ getError($any(originForm).controls.sightCode) }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <bds-railroad-select
                formControlName="railroad"
                label="Railroad"
                class="bds-readonly-wrapper"
            ></bds-railroad-select>
            <mat-error *ngIf="originForm.controls.railroad.errors">
                {{ getError($any(originForm).controls.railroad) }}
            </mat-error>
        </div>
    </div>
    <div class="row">
        <div class="col-8">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                [ngClass]="{
                    'bds-readonly-wrapper': !(isNew || isClone),
                    'bds-mat-dense-form-field': dense
                }"
                appearance="fill"
            >
                <mat-label>Location City</mat-label>
                <input
                    matInput
                    bdsShoutcase
                    formControlName="locationCity"
                    [readonly]="!(isNew || isClone)"
                    class="text-uppercase"
                />
                <mat-error *ngIf="originForm.controls.locationCity.errors">
                    {{ getError($any(originForm).controls.locationCity) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                [ngClass]="{
                    'bds-readonly-wrapper': !isClone && !isNew,
                    'bds-mat-dense-form-field': dense
                }"
                appearance="fill"
            >
                <mat-label>State</mat-label>
                <mat-select formControlName="locationState">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let item of states" [value]="item.code">
                        {{ item.code }} - {{ item.description }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="originForm.controls.locationState.errors">
                    {{ getError($any(originForm).controls.locationState) }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
