import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { RtTripComment, RtCarHotList, RtTripCommentDialogModel } from '@bds/railtrac-models';
import { RtTripCommentService } from '@bds/data-access';

@Component({
    templateUrl: './rt-trip-comment-dialog.component.html',
    styleUrls: ['./rt-trip-comment-dialog.component.scss'],
})
export class RtTripCommentDialogComponent {
    title = '';
    comment: RtTripComment | RtCarHotList;
    errorMessage = '';
    isSaving = false;
    isValid = false;

    constructor(
        public dialogRef: MatDialogRef<RtTripCommentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RtTripCommentDialogModel,
        private commentService: RtTripCommentService,
    ) {
        this.title = data.title;
        this.comment = data.tripComment;
    }

    isHotList(obj: any): boolean {
        if (obj) {
            return obj.hotListDate !== undefined || obj.hotListType !== undefined;
        }

        return false;
    }

    isTripComment(obj: any): boolean {
        if (obj) {
            return obj.shipDateTime !== undefined;
        }

        return false;
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    onCommentChanged(event: { value: RtTripComment | RtCarHotList; valid: boolean }): void {
        this.comment = event.value;
        this.isValid = event.valid;
    }

    onSaveClick(): void {
        this.isSaving = true;
        if (this.isTripComment(this.comment)) {
            if (this.title == 'Add') {
                this.addTripComment();
            } else {
                this.updateTripComment();
            }
        } else if (this.isHotList(this.comment)) {
            console.warn('Save has not set up for Car Hot List');
            this.afterError('Save has not set up for Car Hot List');
        } else {
            this.afterError('Save has not set up for this unknown type - missing Shipment Date');
        }
    }

    addTripComment(): void {
        this.commentService
            .create(this.comment)
            .pipe(take(1))
            .subscribe({
                next: () => this.afterSaveComplete(),
                error: (err) => this.afterError(err),
            });
    }

    updateTripComment(): void {
        this.commentService
            .update(this.comment.ormId, this.comment)
            .pipe(take(1))
            .subscribe({
                next: () => this.afterSaveComplete(),
                error: (err) => this.afterError(err),
            });
    }

    private afterError(error): void {
        const customerErrors = [];

        if (typeof error === 'string') {
            this.errorMessage = error;
            customerErrors.push(error);
        } else if (Array.isArray(error) && error[0].validationMessage) {
            this.errorMessage = `Comment is invalid - ${error[0].validationMessage}`;
            error.forEach((e) => customerErrors.push(e));
        } else {
            this.errorMessage = 'An unknown error occurred';
        }

        this.isSaving = false;
    }

    private afterSaveComplete(): void {
        this.isSaving = false;
        this.dialogRef.close(this.comment);
    }
}
