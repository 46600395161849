<div *ngIf="!!clm">
    <mat-toolbar class="sticky-top mat-elevation-z6">
        <mat-toolbar-row>
            <span>
                <fa-icon [icon]="iconClm" [fixedWidth]="true" size="lg"></fa-icon>
                {{ clm.carInit }} {{ clm.carNo }}
                <span class="small">{{ clm.clmDateTime | date : 'yyyy-MM-dd' : 'UTC' }}</span>
                <span *ngIf="isDirty">*</span>
                <span *ngIf="isSaving">
                    <small>
                        (saving...)
                        <fa-icon [icon]="iconSpinner" [spin]="true"></fa-icon>
                    </small>
                </span>
            </span>
            <span class="fill-remaining-space"></span>
            <span>
                <button
                    mat-button
                    (click)="saveChanges(details.clmForm.value)"
                    [disabled]="isSaving || details.clmForm.invalid"
                >
                    <fa-icon [icon]="iconSave" [fixedWidth]="true" size="lg"></fa-icon> Save CLM
                </button>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
    <section>
        <div class="container" style="margin-top: 20px">
            <div class="row">
                <div *ngIf="errors && errors.length" class="error-warning">
                    <div *ngFor="let error of errors">{{ error.errorMessage }}</div>
                </div>
            </div>
            <div class="row">
                <div [class.col-sm-6]="!isNew" [class.col-sm-12]="isNew">
                    <rt-clm-details [clm]="clm" [highlightDirty]="true" #details></rt-clm-details>
                </div>
                <div class="col-sm-6" *ngIf="!isNew">
                    <!-- <bds-map
                        [originSplc]="clm.locationSplc"
                        [originCity]="clm.locationCity"
                        [originState]="clm.locationState"
                    ></bds-map> -->
                </div>
            </div>
        </div>
    </section>
</div>
