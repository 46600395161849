import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntil, take } from 'rxjs/operators';
import { RtTrip } from '@bds/railtrac-models';
import { RtJeopardizedShipmentReasonBaseComponent } from '../rt-jeopardized-shipment-reason-base.component';
import { RailtracClmService } from '../../../railtrac-clm/railtrac-clm.service';
import { RailtracTripService } from '../../../railtrac-trip/railtrac-trip.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'rt-jeopardized-shipment-unknown',
    templateUrl: './rt-jeopardized-shipment-unknown.component.html',
    styleUrls: ['./rt-jeopardized-shipment-unknown.component.scss'],
})
export class RtJeopardizedShipmentUnknownComponent
    extends RtJeopardizedShipmentReasonBaseComponent
    implements OnInit, OnDestroy, OnChanges
{
    constructor(
        public tripService: RailtracTripService,
        public clmService: RailtracClmService,
        public snackbar: MatSnackBar,
        public dialog: MatDialog,
    ) {
        super(tripService, clmService);
    }

    ngOnInit() {
        super.ngOnInit();
        // this.trip$.pipe(takeUntil(this.destroyer$)).subscribe(trip => { });
        // this.clms$.pipe(takeUntil(this.destroyer$)).subscribe(clms => { });
    }
    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }

    onResolve(event: string) {
        // TODO: Based on setup, not sure how to get trip
    }

    onTripSaveClicked(tripDetails: RtTrip) {
        this.tripService.update(tripDetails.ormId, tripDetails).subscribe((upTrip) => {
            this.snackbar.open('Trip Saved!', 'Ok', {
                duration: 3000,
            });
        });
    }
}
