import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { nameof } from '@bds/core';
import {
    RtRouteParticipatingRailroad,
    RtRouteParticipatingRailroadMetadata,
} from '@bds/railtrac-models';
import { RtRouteParticipatingRailroadDialogOneOptions } from '../bds-route-participating-railroad-dialog-one/bds-route-participating-railroad-dialog-one.component';

@Component({
    selector: 'lib-bds-route-participating-railroad-dialog-two',
    templateUrl: './bds-route-participating-railroad-dialog-two.component.html',
    styleUrls: ['./bds-route-participating-railroad-dialog-two.component.css'],
})
export class BdsRouteParticipatingRailroadDialogTwoComponent {
    public participatingRailroad: RtRouteParticipatingRailroad;
    public participatingRailroadForm: FormGroup;
    public title: string;
    constructor(
        public dialogRef: MatDialogRef<
            BdsRouteParticipatingRailroadDialogTwoComponent,
            RtRouteParticipatingRailroad
        >,
        @Inject(MAT_DIALOG_DATA) public data: RtRouteParticipatingRailroadDialogOneOptions,
        public metadata: RtRouteParticipatingRailroadMetadata,
    ) {
        this.title = 'Add New Participating Railroad';
        this.participatingRailroad = data.item || new RtRouteParticipatingRailroad();
        this.participatingRailroadForm = metadata.getFormGroup(this.participatingRailroad);
        this.railroad.disable();
    }

    get railroad(): FormControl {
        return <FormControl>(
            this.participatingRailroadForm.get(nameof<RtRouteParticipatingRailroad>('railroad'))
        );
    }

    getErrorMessage(control: FormControl): string {
        if (control.hasError('required')) {
            return 'Required';
        }

        return control.hasError('maxLength') ? 'Too long' : '';
    }

    onSaveClick() {
        this.dialogRef.close(this.participatingRailroadForm.getRawValue());
    }
}
