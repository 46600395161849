import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { bdsGridModeType } from '@bds/components';
import { RtRailroadService, RtRouteService } from '@bds/data-access';
import { FastracColumn, FastracDxGridModel, FastracGrid, FastracLite } from '@bds/models';
import {
    RailtracTripService,
    RtJeopardizedShipmentService,
    RtTripRefFieldDefService,
} from '@bds/railtrac';
import { RtJeopardizedShipment, RtTripRefFieldDef } from '@bds/railtrac-models';
import { FastracColsService } from '@bds/services';
import DataSource from 'devextreme/data/data_source';
import { AsyncSubject, Observable, firstValueFrom } from 'rxjs';
import { CommunicationService, PageService } from '@bds/core';
import { FastracScreenBaseComponent } from '@bds/fastrac';
import { CarIdTransformService } from '@bds/helpers';
import { DxDataGridComponent } from 'devextreme-angular';
import { Clipboard } from '@angular/cdk/clipboard';
import * as moment from 'moment';

@Component({
    selector: 'jeopardized-shipment-screen',
    templateUrl: './jeopardized-shipment-screen.component.html',
    styleUrls: ['./jeopardized-shipment-screen.component.scss'],
})
export class JeopardizedShipmentScreenComponent
    extends FastracScreenBaseComponent
    implements OnInit, OnDestroy
{
    @ViewChild('bdsJeopardizedShipmentGrid') grid: DxDataGridComponent;
    public gridMode: bdsGridModeType = 'multiple';
    public gridSource: DataSource | RtJeopardizedShipment[];
    public selectedRowKeys: Array<RtJeopardizedShipment> = [];
    private destroyer$ = new AsyncSubject<void>();
    private gridInit$ = new AsyncSubject<void>();

    public reason: string;

    // GRID COLUMNS SUPPORT /////////////////////////////////////////////////////////////////////
    public gridColumnMetadata: FastracColumn[];
    public customizeColumns: (columns: any[]) => void;
    /////////////////////////////////////////////////////////////////////////////////////////////

    // FASTRAC SUPPORT //////////////////////////////////////////////////////////////////////////
    public currentFastrac: FastracDxGridModel;
    public defaultFastracId: number;
    public fastracList: FastracLite[] = [];
    public gridId = FastracGrid.jeopardized;
    public fastracId = 0;
    //public emptyFastrac: FastracDxGridModel = new FastracDxGridModel(0, [], [], []);
    public showFastrac = false;
    isReadonly = false;
    tripIds: number[] = [];
    /////////////////////////////////////////////////////////////////////////////////////////////

    public selectedRowData: Array<any> = [];
    tripRefFieldDefs$: Observable<RtTripRefFieldDef[]>;
    private keepThisShipment: RtJeopardizedShipment;
    public resolved: boolean;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
        private pageService: PageService,
        private tripService: RailtracTripService,
        public jeopardizedShipmentService: RtJeopardizedShipmentService,
        public railroadService: RtRailroadService,
        public routeService: RtRouteService,
        private clipboard: Clipboard,
        private refFieldDefService: RtTripRefFieldDefService,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
        this.pageService.setHeader('Jeopardized Shipments');

        this.gridSource = this.jeopardizedShipmentService.getODataStore();
        this.tripRefFieldDefs$ = this.refFieldDefService.getReferenceFieldDefs();
        // GRID COLUMNS SUPPORT /////////////////////////////////////////////////////////////////////
        // this.gridColumnService
        //     .getFastracColumns(this.gridId)
        //     .pipe(shareReplay())
        //     .subscribe((s) => {
        //         console.log('gridColumnService', s);
        //         this.gridColumnMetadata = s;
        //     });
        // this.customizeColumns = (columns: any[]) => {
        //     this.customizeColumnsInternal(columns);
        // };
        /////////////////////////////////////////////////////////////////////////////////////////////

        // FASTRAC SUPPORT //////////////////////////////////////////////////////////////////////////
        // this.currentFastrac = { ...this.emptyFastrac };

        // this.route.queryParams
        //     .pipe(
        //         takeUntil(this.destroyer$),
        //         switchMap((params) => {
        //             const fId = +params['fId'];
        //             if (fId) {
        //                 return of(fId);
        //             } else {
        //                 return this.route.data.pipe(
        //                     take(1),
        //                     map((data) => data['defaultData']),
        //                 );
        //             }
        //         }),
        //         tap((response) => {
        //             this.fastracId = response;
        //         }),
        //     )
        //     .subscribe();
        /////////////////////////////////////////////////////////////////////////////////////////////
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.grid?.instance) {
            this.grid.instance.repaint();
        }
        // // // // // this.gridInit$.subscribe(() => {
        // // // // //     this.route.params.pipe(takeUntil(this.destroyer$)).subscribe(params => {
        // // // // //         this.gridInit$.subscribe(s => {
        // // // // //             this.reason = params['reason'];
        // // // // //             this.filter.next((this.reason.length > 0)
        // // // // //                 ?  ['reason', '=', this.reason]
        // // // // //                 : null);
        // // // // //             });
        // // // // //             this.keepThisShipment = null;
        // // // // //             this.resolved = false;
        // // // // //     });
        // // // // // });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyer$.next();
        this.destroyer$.complete();
    }

    gridInitialized(): void {
        this.gridInit$.next();
        this.gridInit$.complete();
    }

    shipmentChanged(key: RtJeopardizedShipment): void {
        this.keepThisShipment = key;
        void this.grid.instance.refresh();
        //this.gridSource = this.jeopardizedShipmentService.list();
    }

    tabLabelString(trip: RtJeopardizedShipment): string {
        return `${trip.carInitials}${trip.carNumber} ${moment(trip.shipDatetime).format(
            'YYYY-MM-DD',
        )}`;
    }

    async selectionChanged($event): Promise<void> {
        this.isReadonly = false;
        if (!!this.keepThisShipment) {
            // this.resolved = this.gridSource
            //     .filter(f => f.reason === this.keepThisShipment.reason && f.ormId === this.keepThisShipment.ormId) === 0;
            this.resolved = true;
            if (this.resolved) {
                this.selectedRowKeys = [this.keepThisShipment];
                // this.snackbar.open('Shipment jeopardy has been resolved', 'Ok', { duration: 3000 });
            }
            this.keepThisShipment = null;
        } else {
            this.resolved = false;
        }
        this.tripIds = [];
        const carIdList = [];

        this.selectedRowKeys.forEach((x) => {
            carIdList.push(`${x.carInitials.trim()}${x.carNumber.trim()}`);
        });

        const trips = await firstValueFrom(this.tripService.findCurrentTrips(carIdList));

        this.isReadonly = this.selectedRowKeys.some(
            (x) => x.reason === 'MULTIPLE OPEN SHIPMENTS' || x.reason === 'NO BILLS',
        );

        for (const data of this.selectedRowKeys) {
            if (data.ormId && data.ormId !== 0) {
                this.tripIds.push(data.ormId);
                continue;
            }

            const ormId = trips.find(
                (x) => x.carInit === data.carInitials && x.carNo === data.carNumber,
            )?.ormId;

            if (ormId) {
                this.tripIds.push(ormId);
                continue;
            }

            // Added since No bills do not have current trip data
            const trip = await firstValueFrom(
                this.tripService.findCurrentTrip(data.carInitials, data.carNumber),
            );
            if (trip) {
                this.tripIds.push(trip.ormId ?? 0);
            }
        }

        this.tripIds = [...new Set(this.tripIds)];
    }

    onPopoutTrip($event: number): void {
        const route = ['/trip', $event];
        const url = this.router.createUrlTree(route).toString();
        window.open(url, '_blank');
    }

    onMenuItemClicked(e: any): void {
        console.log('onMenuItemClicked', e);
    }

    copyCarsToClipboard(): void {
        this.selectedRowData = this.grid.instance.getSelectedRowsData();
        if (this.selectedRowData != null && this.selectedRowData.length > 0) {
            const carIds = this.selectedRowData.map((eachRow) => {
                return eachRow.carInitials.trim() + eachRow.carNumber.trim();
            });
            const uniqueCars = [...new Set(carIds)];
            this.clipboard.copy(uniqueCars.join('\n'));
        }
    }

    // FASTRAC SUPPORT //////////////////////////////////////////////////////////////////////////
    // public onFastracDesignsChanged(event: FastracLite[]) {
    //     this.fastracList = event;
    // }

    // public onFastracIdChanged(event: number) {
    //     this.fastracId = event;
    //     this.syncRouteParams();
    // }

    // public onFastracVisibleChanged(event: boolean) {
    //     this.showFastrac = event;
    // }

    public onGridFastracIdChanged(event: number): void {
        if (this.fastracId !== event) {
            this.fastracId = event;
            this.syncRouteParam();
        }
        // Todo: temp fix until template chooser dropdown sync issue fixed
        setTimeout(() => {
            this.grid.instance.repaint();
        }, 0);
    }

    // public onShowFastrac() {
    //     this.showFastrac = true;
    // }

    // public onApplyFastrac(event: FastracDxGridModel) {
    //     if (event && event.id) {
    //         this.fastracId = event.id;
    //         this.syncRouteParams();
    //     }

    //     this.currentFastrac = { ...event };
    // }
    syncRouteParam(): void {
        let fastracParam = {};

        if (this.fastracId) {
            fastracParam = { fId: this.fastracId };
        }

        // TODO: Get rid of this magic string
        void this.router.navigate(['/jeopardized'], { queryParams: fastracParam });
    }
    /////////////////////////////////////////////////////////////////////////////////////////////

    // GRID COLUMNS SUPPORT /////////////////////////////////////////////////////////////////////
    // private customizeColumnsInternal(columns: any[]) {
    //     columns.forEach((col) => {
    //         if (!this.gridColumnMetadata) {
    //             return;
    //         }

    //         const fCol = this.gridColumnMetadata.find((f) => f.mappedField === col.dataField);
    //         if (!!fCol) {
    //             col.caption = fCol.displayField || col.caption;
    //             col.dataType = fCol.datatype || col.dataType;
    //         }
    //     });
    // }
    /////////////////////////////////////////////////////////////////////////////////////////////
}
