<h1 *ngIf="!!data.title" mat-dialog-title>{{ data.title }}</h1>
<ng-template #noEquipment>
    <div mat-dialog-content>No equipment selected.</div>
</ng-template>
<div mat-dialog-content [formGroup]="form" *ngIf="!!this.data.equipment; else noEquipment">
    <ng-container formArrayName="cats">
        <h4>Set Categories for All</h4>
        <div *ngFor="let cat of formArray.controls; let i = index">
            <ng-container [formGroup]="cat">
                <div class="d-flex">
                    <div class="flex-grow-1">
                        <mat-form-field class="w-100">
                            <mat-label>{{ cat.get('equipReportCategory').value }}</mat-label>
                            <mat-select formControlName="__tempCatgValue">
                                <mat-option
                                    *ngFor="
                                        let item of equipCatgValuesGroup[cat.get('ormId').value]
                                    "
                                    [value]="item.ormId"
                                >
                                    {{ item.equipReportCatgVal }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="pt-2">
                        <button
                            mat-button
                            color="accent"
                            class="text-uppercase"
                            (click)="expireOnAll(cat)"
                        >
                            Expire on all
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container formArrayName="equip">
        <h4>Set Categories Individually</h4>
        <div *ngFor="let equip of equipFormArray.controls; index as j; first as isFirst">
            <ng-container [formGroup]="equip">
                <div class="d-flex">
                    <div class="pr-2">
                        <mat-divider
                            *ngIf="
                                isFirst ||
                                equipFormArray.at(j - 1).get('equipmentId').value !==
                                    equip.get('equipmentId').value
                            "
                        ></mat-divider>
                        <div
                            [style.paddingTop]="
                                isFirst ||
                                equipFormArray.at(j - 1).get('equipmentId').value !==
                                    equip.get('equipmentId').value
                                    ? '8px'
                                    : 'inherit'
                            "
                        >
                            <span
                                [style.opacity]="
                                    isFirst ||
                                    equipFormArray.at(j - 1).get('equipmentId').value !==
                                        equip.get('equipmentId').value
                                        ? 1
                                        : 0
                                "
                            >
                                {{ equipmentGroup[equip.get('equipmentId').value].equipmentInit }}
                                {{ equipmentGroup[equip.get('equipmentId').value].equipmentNo }}
                            </span>
                        </div>
                        <div
                            *ngIf="
                                equip.get('__status').value === 'Delete' &&
                                equip.get('ormId').value === 0
                            "
                            class="text-uppercase text-muted"
                            style="font-size: 0.8rem"
                        >
                            Ignoring
                        </div>
                        <div
                            *ngIf="
                                equip.get('__status').value === 'Delete' &&
                                equip.get('ormId').value !== 0
                            "
                            class="text-uppercase text-warning"
                            style="font-size: 0.8rem"
                        >
                            Expiring
                        </div>
                        <div
                            *ngIf="equip.get('__status').value === 'Insert'"
                            class="text-uppercase text-warning"
                            style="font-size: 0.8rem"
                        >
                            Adding
                        </div>
                        <div
                            *ngIf="equip.get('__status').value === 'Update'"
                            class="text-uppercase text-warning"
                            style="font-size: 0.8rem"
                        >
                            Updating
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <mat-form-field class="w-100" [class.form-control-is-dirty]="equip.dirty">
                            <mat-label>{{
                                getEquipCatLabel(equip.get('equipReportCatgId'))
                            }}</mat-label>
                            <mat-select formControlName="equipReportCatgValId">
                                <mat-option
                                    *ngFor="
                                        let item of equipCatgValuesGroup[
                                            equip.get('equipReportCatgId').value
                                        ]
                                    "
                                    [value]="item.ormId"
                                >
                                    {{ item.equipReportCatgVal }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="pt-2">
                        <button
                            mat-button
                            color="accent"
                            class="text-uppercase"
                            (click)="toggleStatus(equip)"
                        >
                            Expire
                        </button>
                        <!-- <button mat-mini-fab [color]="equip.get('__status').value === 'Delete' ? 'accent' : 'primary'" (click)="toggleStatus(equip)"><fa-icon [icon]="iconDelete"></fa-icon></button> -->
                        <!-- <button mat-mini-fab color="primary" [disabled]="!equip.dirty"><fa-icon [icon]="iconUndo" (click)="resetEquipCategory(equip)"></fa-icon></button> -->
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" (click)="onNoClick()" color="accent">Cancel</button>
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        (click)="onYesClick()"
        [disabled]="form.invalid"
    >
        Save
    </button>
</div>
