import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import { MatSelectChange } from '@angular/material/select';
import { nameof } from '@bds/core';
import { FormErrorHandlerService } from '@bds/helpers';
import {
    EquipmentComponentModel,
    EquipComponentRemovalCode,
    EquipComponentModel,
} from '../../../models';

@Component({
    selector: 'bds-equipment-component-common',
    templateUrl: './equipment-component-common.component.html',
    styleUrls: ['./equipment-component-common.component.scss'],
})
export class EquipmentComponentCommonComponent implements OnInit, OnChanges, OnDestroy {
    categoryDataSource: DataSource;
    compForm: UntypedFormGroup;

    private ngUnsubscribe$: Subject<void> = new Subject<void>();

    // For form styles
    @Input() dense = true;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';

    @Input() availableComponents: EquipComponentModel[] = [];
    @Input() removalCodes: EquipComponentRemovalCode[] = [];
    @Input() selectedComponent: EquipmentComponentModel;
    @Output() componentIdChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() componentChange: EventEmitter<{ value: EquipmentComponentModel; isValid: boolean }> =
        new EventEmitter<{ value: EquipmentComponentModel; isValid: boolean }>();

    get isNew(): boolean {
        if (this.selectedComponent && this.selectedComponent.ormId) {
            return false;
        }
        return true;
    }

    constructor(private formErrorService: FormErrorHandlerService) {
        this.compForm = this.getFormGroup();
    }

    ngOnInit() {
        this.compForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            let isValid: boolean;

            if (this.compForm.errors || this.compForm.invalid) {
                isValid = false;
            } else {
                isValid = true;
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.componentChange.emit({ value: this.compForm.getRawValue(), isValid: isValid });
        });

        if (this.selectedComponent) {
            this.enableDisableFields();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedComponent && this.selectedComponent) {
            this.compForm.reset();
            this.compForm.patchValue(this.selectedComponent);
            this.enableDisableFields();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    enableDisableFields(): void {
        if (this.isNew) {
            this.compForm.get('componentId').enable();
        } else {
            this.compForm.get('componentId').disable();
        }
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        return this.formErrorService.getFormError(formItem);
    }

    getFormGroup(): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<EquipmentComponentModel>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<EquipmentComponentModel>('compartmentNo'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('componentId'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('installDatetime'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('manufacturer'),
            new UntypedFormControl('', [Validators.maxLength(100)]),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('modelNo'),
            new UntypedFormControl('', [Validators.maxLength(100)]),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('serialNo'),
            new UntypedFormControl('', [Validators.maxLength(100)]),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('documentDate'),
            new UntypedFormControl(null),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('documentId'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('expectedRemovalDatetime'),
            new UntypedFormControl(null),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('removalDatetime'),
            new UntypedFormControl(null),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('removalReasonCode'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('removalReason'),
            new UntypedFormControl('', [Validators.maxLength(255)]),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('equipmentInit'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('equipmentNo'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('equipmentOrmId'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('equipmentCompartmentOrmId'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<EquipmentComponentModel>('component'),
            new UntypedFormControl(''),
        );

        return formGroup;
    }

    onComponentIdSelected(event: MatSelectChange): void {
        if (event && event.value) {
            this.componentIdChange.emit(event.value);
        }
    }
}
