import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtCarMech implements From<RtCarMech> {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public carUserId?: string,
        public carClass?: string,
        public fleetId?: string,
        public businessGroup?: string,
        public carOwnerId?: string,
        public carLength?: number,
        public tare?: number,
        public capacity?: number,
        public insulated?: string,
        public couplerLength?: number,
        public strikerLength?: number,
        public iccAarSpec?: string,
        public truckCapacity?: number,
        public truckCenter?: string,
        public bearingType?: string,
        public couplerType?: string,
        public valveType?: string,
        public liningType?: string,
        public coilsType?: string,
        public botProtectType?: string,
        public maker?: string,
        public noCompart?: number,
        public clearTop?: number,
        public clearBottom?: number,
        public tankTestDue?: Date,
        public valveTestDue?: Date,
        public coilTestDue?: Date,
        public lastMaintDate?: Date,
        public nextMaintDate?: Date,
        public liningDate?: Date,
        public paintDue?: Date,
        public lessorNo?: string,
        public lessorRiderNo?: string,
        public lessorRent?: number,
        public lessorBegDate?: Date,
        public lessorEndDate?: Date,
        public lesseeNo?: string,
        public lesseeRiderNo?: string,
        public lesseeRent?: number,
        public lesseeBegDate?: Date,
        public lesseeEndDate?: Date,
        public purchasePrice?: number,
        public purchaseDate?: Date,
        public comments?: string,
        public commentDateTime?: Date,
        public carMileRate?: number,
        public carSize?: string,
        public brakeType?: string,
        public manwayType?: string,
        public manwaySize?: number,
        public gaugeDeviceType?: string,
        public headShieldType?: string,
        public safetyVentType?: string,
        public unloadTopType?: string,
        public unloadBotType?: string,
        public iccDate?: Date,
        public botStreamJack?: string,
        public botValveType?: string,
        public capCompart1?: number,
        public capCompart2?: number,
        public capCompart3?: number,
        public capCompart4?: number,
        public capCompart5?: number,
        public draftGearType?: string,
        public eductionType?: string,
        public facilitatorYorn?: string,
        public knuckleType?: string,
        public liningCondition?: string,
        public logoType?: string,
        public paintCondition?: string,
        public paintType?: string,
        public safeValveType?: string,
        public rebuiltDate?: Date,
        public vacRelYorn?: string,
        public insulateType?: string,
        public odometerEst?: number,
        public odometerAct?: number,
        public stubSillTestDate?: Date,
        public prevStubSillTest?: Date,
        public prevTankTest?: Date,
        public prevValveTest?: Date,
        public prevCoilTest?: Date,
        public prevLiningDate?: Date,
        public prevPaintDate?: Date,
        public activeStatus?: string,
        public liningInd?: string,
        public coilSize?: string,
        public dryCapacity?: number,
        public lessorCurrency?: string,
        public lesseeCurrency?: string,
        public actualRetireDate?: Date,
        public actualArrivalDate?: Date,
        public sparkTestDue?: Date,
        public prevSparkTest?: Date,
        public builtSplc?: string,
        public shellType?: string,
        public shellThick?: number,
        public shellNormal?: string,
        public citizenship?: string,
        public domesticInternat?: string,
        public insulateThick?: number,
        public assignedCommCode?: string,
        public assignedOrigin?: string,
        public tankMaterial?: string,
        public lastCommCode?: string,
        public commShortName?: string,
        public carStatusCheck?: string,
        public altReturnOrigin?: string,
        public capacityUnits?: string,
        public loadLimit?: number,
        public liningTestDateDue?: Date,
        public liningTestDatePrev?: Date,
        public test88b2InspectionDateDue?: Date,
        public test88b2InspectionDatePrev?: Date,
        public currentShellThickness?: number,
        public shellThicknessUnit?: string,
        public shellThicknessDueDate?: Date,
        public shellThicknessPrevDate?: Date,
        public gateType?: string,
        public antiSlipPartYorn?: string,
        public insulateThicknessUnits?: string,
        public umlerCarType?: string,
        public allowableWeight?: number,
        public capacityUm?: string,
        public canCarMileRate?: number,
        public usaYorn?: string,
        public mexYorn?: string,
        public canYorn?: string,
        public cpc1232?: string,
        public tcpd34?: string,
        public userId?: string,
        public updateDateTime?: string,
    ) {}
    from(trip: RtCarMech): RtCarMech {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtCarMech>('carInit'), server: 'carInit' },
    { client: nameof<RtCarMech>('carNo'), server: 'carNo' },
    { client: nameof<RtCarMech>('carUserId'), server: 'carUserId' },
    { client: nameof<RtCarMech>('carClass'), server: 'carClass' },
    { client: nameof<RtCarMech>('fleetId'), server: 'fleetId' },
    { client: nameof<RtCarMech>('businessGroup'), server: 'businessGroup' },
    { client: nameof<RtCarMech>('carOwnerId'), server: 'carOwnerId' },
    { client: nameof<RtCarMech>('carLength'), server: 'carLength' },
    { client: nameof<RtCarMech>('tare'), server: 'tare' },
    { client: nameof<RtCarMech>('capacity'), server: 'capacity' },
    { client: nameof<RtCarMech>('insulated'), server: 'insulated' },
    { client: nameof<RtCarMech>('couplerLength'), server: 'couplerLength' },
    { client: nameof<RtCarMech>('strikerLength'), server: 'strikerLength' },
    { client: nameof<RtCarMech>('iccAarSpec'), server: 'iccAarSpec' },
    { client: nameof<RtCarMech>('truckCapacity'), server: 'truckCapacity' },
    { client: nameof<RtCarMech>('truckCenter'), server: 'truckCenter' },
    { client: nameof<RtCarMech>('bearingType'), server: 'bearingType' },
    { client: nameof<RtCarMech>('couplerType'), server: 'couplerType' },
    { client: nameof<RtCarMech>('valveType'), server: 'valveType' },
    { client: nameof<RtCarMech>('liningType'), server: 'liningType' },
    { client: nameof<RtCarMech>('coilsType'), server: 'coilsType' },
    { client: nameof<RtCarMech>('botProtectType'), server: 'botProtectType' },
    { client: nameof<RtCarMech>('maker'), server: 'maker' },
    { client: nameof<RtCarMech>('noCompart'), server: 'noCompart' },
    { client: nameof<RtCarMech>('clearTop'), server: 'clearTop' },
    { client: nameof<RtCarMech>('clearBottom'), server: 'clearBottom' },
    { client: nameof<RtCarMech>('tankTestDue'), server: 'tankTestDue' },
    { client: nameof<RtCarMech>('valveTestDue'), server: 'valveTestDue' },
    { client: nameof<RtCarMech>('coilTestDue'), server: 'coilTestDue' },
    { client: nameof<RtCarMech>('lastMaintDate'), server: 'lastMaintDate' },
    { client: nameof<RtCarMech>('nextMaintDate'), server: 'nextMaintDate' },
    { client: nameof<RtCarMech>('liningDate'), server: 'liningDate' },
    { client: nameof<RtCarMech>('paintDue'), server: 'paintDue' },
    { client: nameof<RtCarMech>('lessorNo'), server: 'lessorNo' },
    { client: nameof<RtCarMech>('lessorRiderNo'), server: 'lessorRiderNo' },
    { client: nameof<RtCarMech>('lessorRent'), server: 'lessorRent' },
    { client: nameof<RtCarMech>('lessorBegDate'), server: 'lessorBegDate' },
    { client: nameof<RtCarMech>('lessorEndDate'), server: 'lessorEndDate' },
    { client: nameof<RtCarMech>('lesseeNo'), server: 'lesseeNo' },
    { client: nameof<RtCarMech>('lesseeRiderNo'), server: 'lesseeRiderNo' },
    { client: nameof<RtCarMech>('lesseeRent'), server: 'lesseeRent' },
    { client: nameof<RtCarMech>('lesseeBegDate'), server: 'lesseeBegDate' },
    { client: nameof<RtCarMech>('lesseeEndDate'), server: 'lesseeEndDate' },
    { client: nameof<RtCarMech>('purchasePrice'), server: 'purchasePrice' },
    { client: nameof<RtCarMech>('purchaseDate'), server: 'purchaseDate' },
    { client: nameof<RtCarMech>('comments'), server: 'comments' },
    { client: nameof<RtCarMech>('commentDateTime'), server: 'commentDateTime' },
    { client: nameof<RtCarMech>('carMileRate'), server: 'carMileRate' },
    { client: nameof<RtCarMech>('carSize'), server: 'carSize' },
    { client: nameof<RtCarMech>('brakeType'), server: 'brakeType' },
    { client: nameof<RtCarMech>('manwayType'), server: 'manwayType' },
    { client: nameof<RtCarMech>('manwaySize'), server: 'manwaySize' },
    { client: nameof<RtCarMech>('gaugeDeviceType'), server: 'gaugeDeviceType' },
    { client: nameof<RtCarMech>('headShieldType'), server: 'headShieldType' },
    { client: nameof<RtCarMech>('safetyVentType'), server: 'safetyVentType' },
    { client: nameof<RtCarMech>('unloadTopType'), server: 'unloadTopType' },
    { client: nameof<RtCarMech>('unloadBotType'), server: 'unloadBotType' },
    { client: nameof<RtCarMech>('iccDate'), server: 'iccDate' },
    { client: nameof<RtCarMech>('botStreamJack'), server: 'botStreamJack' },
    { client: nameof<RtCarMech>('botValveType'), server: 'botValveType' },
    { client: nameof<RtCarMech>('capCompart1'), server: 'capCompart1' },
    { client: nameof<RtCarMech>('capCompart2'), server: 'capCompart2' },
    { client: nameof<RtCarMech>('capCompart3'), server: 'capCompart3' },
    { client: nameof<RtCarMech>('capCompart4'), server: 'capCompart4' },
    { client: nameof<RtCarMech>('capCompart5'), server: 'capCompart5' },
    { client: nameof<RtCarMech>('draftGearType'), server: 'draftGearType' },
    { client: nameof<RtCarMech>('eductionType'), server: 'eductionType' },
    { client: nameof<RtCarMech>('facilitatorYorn'), server: 'facilitatorYorn' },
    { client: nameof<RtCarMech>('knuckleType'), server: 'knuckleType' },
    { client: nameof<RtCarMech>('liningCondition'), server: 'liningCondition' },
    { client: nameof<RtCarMech>('logoType'), server: 'logoType' },
    { client: nameof<RtCarMech>('paintCondition'), server: 'paintCondition' },
    { client: nameof<RtCarMech>('paintType'), server: 'paintType' },
    { client: nameof<RtCarMech>('safeValveType'), server: 'safeValveType' },
    { client: nameof<RtCarMech>('rebuiltDate'), server: 'rebuiltDate' },
    { client: nameof<RtCarMech>('vacRelYorn'), server: 'vacRelYorn' },
    { client: nameof<RtCarMech>('insulateType'), server: 'insulateType' },
    { client: nameof<RtCarMech>('odometerEst'), server: 'odometerEst' },
    { client: nameof<RtCarMech>('odometerAct'), server: 'odometerAct' },
    { client: nameof<RtCarMech>('stubSillTestDate'), server: 'stubSillTestDate' },
    { client: nameof<RtCarMech>('prevStubSillTest'), server: 'prevStubSillTest' },
    { client: nameof<RtCarMech>('prevTankTest'), server: 'prevTankTest' },
    { client: nameof<RtCarMech>('prevValveTest'), server: 'prevValveTest' },
    { client: nameof<RtCarMech>('prevCoilTest'), server: 'prevCoilTest' },
    { client: nameof<RtCarMech>('prevLiningDate'), server: 'prevLiningDate' },
    { client: nameof<RtCarMech>('prevPaintDate'), server: 'prevPaintDate' },
    { client: nameof<RtCarMech>('activeStatus'), server: 'activeStatus' },
    { client: nameof<RtCarMech>('liningInd'), server: 'liningInd' },
    { client: nameof<RtCarMech>('coilSize'), server: 'coilSize' },
    { client: nameof<RtCarMech>('dryCapacity'), server: 'dryCapacity' },
    { client: nameof<RtCarMech>('lessorCurrency'), server: 'lessorCurrency' },
    { client: nameof<RtCarMech>('lesseeCurrency'), server: 'lesseeCurrency' },
    { client: nameof<RtCarMech>('actualRetireDate'), server: 'actualRetireDate' },
    { client: nameof<RtCarMech>('actualArrivalDate'), server: 'actualArrivalDate' },
    { client: nameof<RtCarMech>('sparkTestDue'), server: 'sparkTestDue' },
    { client: nameof<RtCarMech>('prevSparkTest'), server: 'prevSparkTest' },
    { client: nameof<RtCarMech>('builtSplc'), server: 'builtSplc' },
    { client: nameof<RtCarMech>('shellType'), server: 'shellType' },
    { client: nameof<RtCarMech>('shellThick'), server: 'shellThick' },
    { client: nameof<RtCarMech>('shellNormal'), server: 'shellNormal' },
    { client: nameof<RtCarMech>('citizenship'), server: 'citizenship' },
    { client: nameof<RtCarMech>('domesticInternat'), server: 'domesticInternat' },
    { client: nameof<RtCarMech>('insulateThick'), server: 'insulateThick' },
    { client: nameof<RtCarMech>('assignedCommCode'), server: 'assignedCommCode' },
    { client: nameof<RtCarMech>('assignedOrigin'), server: 'assignedOrigin' },
    { client: nameof<RtCarMech>('tankMaterial'), server: 'tankMaterial' },
    { client: nameof<RtCarMech>('lastCommCode'), server: 'lastCommCode' },
    { client: nameof<RtCarMech>('commShortName'), server: 'commShortName' },
    { client: nameof<RtCarMech>('carStatusCheck'), server: 'carStatusCheck' },
    { client: nameof<RtCarMech>('altReturnOrigin'), server: 'altReturnOrigin' },
    { client: nameof<RtCarMech>('capacityUnits'), server: 'capacityUnits' },
    { client: nameof<RtCarMech>('loadLimit'), server: 'loadLimit' },
    { client: nameof<RtCarMech>('liningTestDateDue'), server: 'liningTestDateDue' },
    { client: nameof<RtCarMech>('liningTestDatePrev'), server: 'liningTestDatePrev' },
    { client: nameof<RtCarMech>('test88b2InspectionDateDue'), server: 'test88b2InspectionDateDue' },
    {
        client: nameof<RtCarMech>('test88b2InspectionDatePrev'),
        server: 'test88b2InspectionDatePrev',
    },
    { client: nameof<RtCarMech>('currentShellThickness'), server: 'currentShellThickness' },
    { client: nameof<RtCarMech>('shellThicknessUnit'), server: 'shellThicknessUnit' },
    { client: nameof<RtCarMech>('shellThicknessDueDate'), server: 'shellThicknessDueDate' },
    { client: nameof<RtCarMech>('shellThicknessPrevDate'), server: 'shellThicknessPrevDate' },
    { client: nameof<RtCarMech>('gateType'), server: 'gateType' },
    { client: nameof<RtCarMech>('antiSlipPartYorn'), server: 'antiSlipPartYorn' },
    { client: nameof<RtCarMech>('insulateThicknessUnits'), server: 'insulateThicknessUnits' },
    { client: nameof<RtCarMech>('umlerCarType'), server: 'umlerCarType' },
    { client: nameof<RtCarMech>('allowableWeight'), server: 'allowableWeight' },
    { client: nameof<RtCarMech>('capacityUm'), server: 'capacityUm' },
    { client: nameof<RtCarMech>('canCarMileRate'), server: 'canCarMileRate' },
    { client: nameof<RtCarMech>('usaYorn'), server: 'usaYorn' },
    { client: nameof<RtCarMech>('mexYorn'), server: 'mexYorn' },
    { client: nameof<RtCarMech>('canYorn'), server: 'canYorn' },
    { client: nameof<RtCarMech>('cpc1232'), server: 'cpc1232' },
    { client: nameof<RtCarMech>('tcpd34'), server: 'tcpd34' },
    { client: nameof<RtCarMech>('userId'), server: 'userId' },
    { client: nameof<RtCarMech>('updateDateTime'), server: 'updateDateTime' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCarMechAdapter extends TwoWayAdapter<RtCarMech> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtCarMechMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtCarMech>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('carUserId'),
                dataType: 'string',
                caption: 'carUserId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('carClass'),
                dataType: 'string',
                caption: 'carClass',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('fleetId'),
                dataType: 'string',
                caption: 'fleetId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('businessGroup'),
                dataType: 'string',
                caption: 'businessGroup',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('carOwnerId'),
                dataType: 'string',
                caption: 'carOwnerId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('carLength'),
                dataType: 'number',
                caption: 'carLength',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('tare'),
                dataType: 'number',
                caption: 'tare',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('capacity'),
                dataType: 'number',
                caption: 'capacity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('insulated'),
                dataType: 'string',
                caption: 'insulated',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('couplerLength'),
                dataType: 'number',
                caption: 'couplerLength',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('strikerLength'),
                dataType: 'number',
                caption: 'strikerLength',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('iccAarSpec'),
                dataType: 'string',
                caption: 'iccAarSpec',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('truckCapacity'),
                dataType: 'number',
                caption: 'truckCapacity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('truckCenter'),
                dataType: 'string',
                caption: 'truckCenter',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('bearingType'),
                dataType: 'string',
                caption: 'bearingType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('couplerType'),
                dataType: 'string',
                caption: 'couplerType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('valveType'),
                dataType: 'string',
                caption: 'valveType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('liningType'),
                dataType: 'string',
                caption: 'liningType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('coilsType'),
                dataType: 'string',
                caption: 'coilsType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('botProtectType'),
                dataType: 'string',
                caption: 'botProtectType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('maker'),
                dataType: 'string',
                caption: 'maker',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('noCompart'),
                dataType: 'number',
                caption: 'noCompart',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('clearTop'),
                dataType: 'number',
                caption: 'clearTop',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('clearBottom'),
                dataType: 'number',
                caption: 'clearBottom',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('tankTestDue'),
                dataType: 'Date',
                caption: 'tankTestDue',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('valveTestDue'),
                dataType: 'Date',
                caption: 'valveTestDue',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('coilTestDue'),
                dataType: 'Date',
                caption: 'coilTestDue',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lastMaintDate'),
                dataType: 'Date',
                caption: 'lastMaintDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('nextMaintDate'),
                dataType: 'Date',
                caption: 'nextMaintDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('liningDate'),
                dataType: 'Date',
                caption: 'liningDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('paintDue'),
                dataType: 'Date',
                caption: 'paintDue',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lessorNo'),
                dataType: 'string',
                caption: 'lessorNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lessorRiderNo'),
                dataType: 'string',
                caption: 'lessorRiderNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lessorRent'),
                dataType: 'number',
                caption: 'lessorRent',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lessorBegDate'),
                dataType: 'Date',
                caption: 'lessorBegDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lessorEndDate'),
                dataType: 'Date',
                caption: 'lessorEndDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lesseeNo'),
                dataType: 'string',
                caption: 'lesseeNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lesseeRiderNo'),
                dataType: 'string',
                caption: 'lesseeRiderNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lesseeRent'),
                dataType: 'number',
                caption: 'lesseeRent',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lesseeBegDate'),
                dataType: 'Date',
                caption: 'lesseeBegDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lesseeEndDate'),
                dataType: 'Date',
                caption: 'lesseeEndDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('purchasePrice'),
                dataType: 'number',
                caption: 'purchasePrice',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('purchaseDate'),
                dataType: 'Date',
                caption: 'purchaseDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('comments'),
                dataType: 'string',
                caption: 'comments',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('commentDateTime'),
                dataType: 'Date',
                caption: 'commentDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('carMileRate'),
                dataType: 'number',
                caption: 'carMileRate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('carSize'),
                dataType: 'string',
                caption: 'carSize',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('brakeType'),
                dataType: 'string',
                caption: 'brakeType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('manwayType'),
                dataType: 'string',
                caption: 'manwayType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('manwaySize'),
                dataType: 'number',
                caption: 'manwaySize',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('gaugeDeviceType'),
                dataType: 'string',
                caption: 'gaugeDeviceType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('headShieldType'),
                dataType: 'string',
                caption: 'headShieldType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('safetyVentType'),
                dataType: 'string',
                caption: 'safetyVentType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('unloadTopType'),
                dataType: 'string',
                caption: 'unloadTopType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('unloadBotType'),
                dataType: 'string',
                caption: 'unloadBotType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('iccDate'),
                dataType: 'Date',
                caption: 'iccDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('botStreamJack'),
                dataType: 'string',
                caption: 'botStreamJack',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('botValveType'),
                dataType: 'string',
                caption: 'botValveType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('capCompart1'),
                dataType: 'number',
                caption: 'capCompart1',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('capCompart2'),
                dataType: 'number',
                caption: 'capCompart2',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('capCompart3'),
                dataType: 'number',
                caption: 'capCompart3',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('capCompart4'),
                dataType: 'number',
                caption: 'capCompart4',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('capCompart5'),
                dataType: 'number',
                caption: 'capCompart5',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('draftGearType'),
                dataType: 'string',
                caption: 'draftGearType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('eductionType'),
                dataType: 'string',
                caption: 'eductionType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('facilitatorYorn'),
                dataType: 'string',
                caption: 'facilitatorYorn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('knuckleType'),
                dataType: 'string',
                caption: 'knuckleType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('liningCondition'),
                dataType: 'string',
                caption: 'liningCondition',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('logoType'),
                dataType: 'string',
                caption: 'logoType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('paintCondition'),
                dataType: 'string',
                caption: 'paintCondition',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('paintType'),
                dataType: 'string',
                caption: 'paintType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('safeValveType'),
                dataType: 'string',
                caption: 'safeValveType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('rebuiltDate'),
                dataType: 'Date',
                caption: 'rebuiltDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('vacRelYorn'),
                dataType: 'string',
                caption: 'vacRelYorn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('insulateType'),
                dataType: 'string',
                caption: 'insulateType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('odometerEst'),
                dataType: 'number',
                caption: 'odometerEst',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('odometerAct'),
                dataType: 'number',
                caption: 'odometerAct',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('stubSillTestDate'),
                dataType: 'Date',
                caption: 'stubSillTestDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('prevStubSillTest'),
                dataType: 'Date',
                caption: 'prevStubSillTest',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('prevTankTest'),
                dataType: 'Date',
                caption: 'prevTankTest',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('prevValveTest'),
                dataType: 'Date',
                caption: 'prevValveTest',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('prevCoilTest'),
                dataType: 'Date',
                caption: 'prevCoilTest',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('prevLiningDate'),
                dataType: 'Date',
                caption: 'prevLiningDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('prevPaintDate'),
                dataType: 'Date',
                caption: 'prevPaintDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('activeStatus'),
                dataType: 'string',
                caption: 'activeStatus',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('liningInd'),
                dataType: 'string',
                caption: 'liningInd',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('coilSize'),
                dataType: 'string',
                caption: 'coilSize',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('dryCapacity'),
                dataType: 'number',
                caption: 'dryCapacity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lessorCurrency'),
                dataType: 'string',
                caption: 'lessorCurrency',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lesseeCurrency'),
                dataType: 'string',
                caption: 'lesseeCurrency',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('actualRetireDate'),
                dataType: 'Date',
                caption: 'actualRetireDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('actualArrivalDate'),
                dataType: 'Date',
                caption: 'actualArrivalDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('sparkTestDue'),
                dataType: 'Date',
                caption: 'sparkTestDue',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('prevSparkTest'),
                dataType: 'Date',
                caption: 'prevSparkTest',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('builtSplc'),
                dataType: 'string',
                caption: 'builtSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('shellType'),
                dataType: 'string',
                caption: 'shellType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('shellThick'),
                dataType: 'number',
                caption: 'shellThick',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('shellNormal'),
                dataType: 'string',
                caption: 'shellNormal',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('citizenship'),
                dataType: 'string',
                caption: 'citizenship',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('domesticInternat'),
                dataType: 'string',
                caption: 'domesticInternat',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('insulateThick'),
                dataType: 'number',
                caption: 'insulateThick',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('assignedCommCode'),
                dataType: 'string',
                caption: 'assignedCommCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('assignedOrigin'),
                dataType: 'string',
                caption: 'assignedOrigin',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('tankMaterial'),
                dataType: 'string',
                caption: 'tankMaterial',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('lastCommCode'),
                dataType: 'string',
                caption: 'lastCommCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('commShortName'),
                dataType: 'string',
                caption: 'commShortName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('carStatusCheck'),
                dataType: 'string',
                caption: 'carStatusCheck',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('altReturnOrigin'),
                dataType: 'string',
                caption: 'altReturnOrigin',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('capacityUnits'),
                dataType: 'string',
                caption: 'capacityUnits',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('loadLimit'),
                dataType: 'number',
                caption: 'loadLimit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('liningTestDateDue'),
                dataType: 'Date',
                caption: 'liningTestDateDue',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('liningTestDatePrev'),
                dataType: 'Date',
                caption: 'liningTestDatePrev',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('test88b2InspectionDateDue'),
                dataType: 'Date',
                caption: 'test88b2InspectionDateDue',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('test88b2InspectionDatePrev'),
                dataType: 'Date',
                caption: 'test88b2InspectionDatePrev',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('currentShellThickness'),
                dataType: 'number',
                caption: 'currentShellThickness',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('shellThicknessUnit'),
                dataType: 'string',
                caption: 'shellThicknessUnit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('shellThicknessDueDate'),
                dataType: 'Date',
                caption: 'shellThicknessDueDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('shellThicknessPrevDate'),
                dataType: 'Date',
                caption: 'shellThicknessPrevDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('gateType'),
                dataType: 'string',
                caption: 'gateType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('antiSlipPartYorn'),
                dataType: 'string',
                caption: 'antiSlipPartYorn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('insulateThicknessUnits'),
                dataType: 'string',
                caption: 'insulateThicknessUnits',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('umlerCarType'),
                dataType: 'string',
                caption: 'umlerCarType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('allowableWeight'),
                dataType: 'number',
                caption: 'allowableWeight',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('capacityUm'),
                dataType: 'string',
                caption: 'capacityUm',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('canCarMileRate'),
                dataType: 'number',
                caption: 'canCarMileRate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('usaYorn'),
                dataType: 'string',
                caption: 'usaYorn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('mexYorn'),
                dataType: 'string',
                caption: 'mexYorn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('canYorn'),
                dataType: 'string',
                caption: 'canYorn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('cpc1232'),
                dataType: 'string',
                caption: 'cpc1232',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('tcpd34'),
                dataType: 'string',
                caption: 'tcpd34',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('userId'),
                dataType: 'string',
                caption: 'userId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarMech>('updateDateTime'),
                dataType: 'string',
                caption: 'updateDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtCarMech>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('carUserId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('carClass'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('fleetId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('businessGroup'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('carOwnerId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('carLength'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('tare'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('capacity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('insulated'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('couplerLength'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('strikerLength'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('iccAarSpec'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('truckCapacity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('truckCenter'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('bearingType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('couplerType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('valveType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('liningType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('coilsType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('botProtectType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('maker'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('noCompart'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('clearTop'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('clearBottom'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('tankTestDue'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('valveTestDue'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('coilTestDue'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lastMaintDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('nextMaintDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('liningDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('paintDue'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lessorNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lessorRiderNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lessorRent'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lessorBegDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lessorEndDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lesseeNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lesseeRiderNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lesseeRent'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lesseeBegDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lesseeEndDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('purchasePrice'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('purchaseDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('comments'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('commentDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('carMileRate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('carSize'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('brakeType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('manwayType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('manwaySize'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('gaugeDeviceType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('headShieldType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('safetyVentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('unloadTopType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('unloadBotType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('iccDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('botStreamJack'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('botValveType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('capCompart1'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('capCompart2'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('capCompart3'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('capCompart4'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('capCompart5'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('draftGearType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('eductionType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('facilitatorYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('knuckleType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('liningCondition'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('logoType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('paintCondition'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('paintType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('safeValveType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('rebuiltDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('vacRelYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('insulateType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('odometerEst'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('odometerAct'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('stubSillTestDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('prevStubSillTest'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('prevTankTest'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('prevValveTest'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('prevCoilTest'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('prevLiningDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('prevPaintDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('activeStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('liningInd'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('coilSize'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('dryCapacity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lessorCurrency'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lesseeCurrency'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('actualRetireDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('actualArrivalDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('sparkTestDue'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('prevSparkTest'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('builtSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('shellType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('shellThick'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('shellNormal'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('citizenship'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('domesticInternat'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('insulateThick'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('assignedCommCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('assignedOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('tankMaterial'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('lastCommCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('commShortName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('carStatusCheck'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('altReturnOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('capacityUnits'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('loadLimit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('liningTestDateDue'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('liningTestDatePrev'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('test88b2InspectionDateDue'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('test88b2InspectionDatePrev'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('currentShellThickness'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('shellThicknessUnit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('shellThicknessDueDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('shellThicknessPrevDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('gateType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('antiSlipPartYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('insulateThicknessUnits'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('umlerCarType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('allowableWeight'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('capacityUm'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('canCarMileRate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('usaYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('mexYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('canYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('cpc1232'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('tcpd34'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('userId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarMech>('updateDateTime'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
