<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <trip-commodity-shipped
                [commodityShipped]="product"
                [productOp]="productOp"
                (commodityShippedChange)="onCommodityShippedChange($event)"
            >
            </trip-commodity-shipped>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" mat-dialog-close>Cancel</button>
    <button mat-button class="text-uppercase" color="accent" (click)="save()">Save</button>
</mat-dialog-actions>

