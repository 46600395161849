export class BdsOriginCriteriaDialogModel {
    isClone: boolean;
    isNew: boolean;
    locationCity?: string;
    locationState?: string;
    originDeliveryHours?: number;
    railroad?: string;
    returnCity?: string;
    returnState?: string;
    sightCode?: string;
}
