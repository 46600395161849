import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RtArrAtDest } from '@bds/railtrac-models';
import { BdsDestinationCriteriaGridDialogModel } from '../../models/bds-destination-criteria-grid-dialog.model';
import { BdsDestinationCriteriaService } from '@bds/data-access';

@Component({
    selector: 'bds-destination-criteria-grid-dialog',
    templateUrl: './bds-destination-criteria-grid-dialog.component.html',
    styleUrls: ['./bds-destination-criteria-grid-dialog.component.scss'],
})
export class BdsDestinationCriteriaGridDialogComponent {
    cancelText: string = 'Cancel';
    customerSource: DataSource;
    destinationCriteria: RtArrAtDest;
    errorMessage: string = '';
    initialDestCriteria: RtArrAtDest;
    isSaving: boolean = false;
    isValid: boolean = false;
    successfulSave: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<BdsDestinationCriteriaGridDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BdsDestinationCriteriaGridDialogModel,
        private destinationService: BdsDestinationCriteriaService,
        private _snackbar: MatSnackBar,
    ) {
        // TODO: Cannot use spread operator with current setup of adapter pattern, so this seems to work temporarily
        this.initialDestCriteria = { ...data.destinationCriteria } as RtArrAtDest;
    }

    onAdd(createNewAfterSave: boolean = false) {
        this.destinationService
            .create(this.destinationCriteria)
            .pipe(take(1))
            .subscribe(
                (dest) => {
                    this.afterSaveComplete(dest, 'Destination Criteria Added', createNewAfterSave);
                },
                (err) => this.afterError(err),
            );
    }

    onCancelClick() {
        this.dialogRef.close(this.successfulSave);
    }

    onDestinationCriteriaChanged(event: { value: RtArrAtDest; isValid: boolean }) {
        this.destinationCriteria = event.value;
        this.isValid = event.isValid;
    }

    onSaveClick(createNewAfterSave: boolean = false) {
        this.isSaving = true;
        this.errorMessage = '';

        if (this.data.isNew || this.data.isClone) {
            this.onAdd(createNewAfterSave);
        } else {
            this.onUpdate();
        }
    }

    onUpdate() {
        this.destinationService
            .update(this.destinationCriteria.ormId, this.destinationCriteria)
            .pipe(take(1))
            .subscribe(
                (dest) => {
                    this.afterSaveComplete(dest);
                },
                (err) => this.afterError(err),
            );
    }

    private afterError(error) {
        const customerErrors = [];

        if (typeof error === 'string') {
            this.errorMessage = error;
            customerErrors.push(error);
        } else if (Array.isArray(error) && error[0].validationMessage) {
            this.errorMessage = `Destination Criteria is invalid - ${error[0].validationMessage}`;
            error.forEach((e) => customerErrors.push(e));
        } else {
            this.errorMessage = 'An unknown error occurred';
        }

        this.isSaving = false;
        this._snackbar.open(this.errorMessage, 'Error', { duration: 3000 });
    }

    private afterSaveComplete(destCriteria: RtArrAtDest, message?: string, keepOpen?: boolean) {
        if (!message) {
            message = 'Changes saved!';
        }

        this.successfulSave = true;
        this.isSaving = false;
        this._snackbar.open(message, 'Ok', { duration: 3000 });

        if (keepOpen) {
            // TODO: Cannot use spread operator with current setup of adapter pattern, so this seems to work temporarily
            this.initialDestCriteria = { ...this.data.destinationCriteria } as RtArrAtDest;
        } else {
            this.dialogRef.close(this.successfulSave);
        }
    }
}
