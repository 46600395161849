import { Injectable } from '@angular/core';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { RtShipmentType, RtShipmentTypeAdapter } from '@bds/railtrac-models';

@Injectable({ providedIn: 'root' })
export class BdsShipmentTypeApiServiceOptions {
    odataUrl: string = `odata/shipmenttype`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsShipmentTypeService {
    constructor(
        private adapter: RtShipmentTypeAdapter,
        private options: BdsShipmentTypeApiServiceOptions,
    ) {}

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtShipmentType>('shipmentType'),
        ).server;

        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: keyField,
        });
    }
}
