import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import DxDataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';

@Component({
    selector: 'bds-equipment-component-list',
    templateUrl: './equipment-component-list.component.html',
    styleUrls: ['./equipment-component-list.component.scss'],
})
export class EquipmentComponentListComponent implements OnInit, OnChanges, OnDestroy {
    gridSource: DxDataSource;
    selectedRowKeys: number[] = [];

    private ngUnsubscribe$: Subject<boolean> = new Subject<boolean>();

    @Input() componentsStore: ODataStore;
    @Input() equipmentId: number;
    @Input() needsRefresh$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    @Input() selectedItemId$: BehaviorSubject<number> = new BehaviorSubject(0);
    @Output() addEquipmentComponent: EventEmitter<void> = new EventEmitter<void>();
    @Output() selectedItemChange: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('bdsComponentGrid') dataGrid: DxDataGridComponent;

    ngOnInit() {
        this.setUpGrid();

        this.needsRefresh$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            if (this.dataGrid && this.dataGrid.instance) {
                void this.dataGrid.instance.refresh();
            }
        });

        this.selectedItemId$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((id) => {
            this.setUpSelectedKeys(id);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.componentsStore &&
                !changes.componentsStore.firstChange &&
                this.componentsStore) ||
            (changes.equipmentId && !changes.equipmentId.firstChange)
        ) {
            this.setUpGrid();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(false);
        this.ngUnsubscribe$.complete();
    }

    addNew(): void {
        this.addEquipmentComponent.emit();
    }

    onSelectedRowKeysChanged(event: number[]): void {
        // If we eventually add multiple selections, just pass the
        // value out in the emitter
        this.selectedRowKeys = event;
        if (event.length > 0) {
            this.selectedItemChange.emit(event[0]);
        } else {
            this.selectedItemChange.emit(null);
        }
    }

    setUpGrid(): void {
        this.gridSource = new DataSource({
            store: this.componentsStore,
            paginate: true,
            pageSize: 20,
        });

        this.gridSource.filter(['equipmentOrmId', '=', this.equipmentId]);
    }

    setUpSelectedKeys(id: number): void {
        if (id) {
            this.selectedRowKeys = [id];
        } else {
            this.selectedRowKeys = [];
        }
    }
}
