<div *ngIf="tripIds.length === 1 && !(getShowCreateDiversion() | async)" class="button-header">
    <button mat-stroked-button class="button-right" (click)="onCreateClick()">
        Create Diversion
    </button>
</div>

<div *ngIf="getShowCreateDiversion() | async">
    <h3>Add Trip Diversion</h3>

    <div class="bds-error-message">{{ errorMessage }}</div>

    <rt-diversion-details
        [diversion]="diversion"
        [splcStore]="splcList"
        [routeStore]="routeList"
        [businessGroups]="businessGroupList$ | async"
        [fleetNames]="fleetList$ | async"
        [whoPays]="whoPays$ | async"
        [diversionTypes]="divertTypes$ | async"
        [isSaving]="isSaving"
        (cancel)="onCancelClick()"
        (save)="onSaveClick($event)"
    >
    </rt-diversion-details>

    <mat-divider></mat-divider>
</div>
