import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
//import { CustomerDialogComponent } from '../../dialogs/customer-dialog/customer-dialog.component';
//import { RouteDialogComponent } from '../../dialogs/route-dialog/route-dialog.component';
//import { CommodityDialogComponent } from '../../dialogs/commodity-dialog/commodity-dialog.component';
//import { FastracDialogComponent } from '../../dialogs/fastrac-dialog/fastrac-dialog.component';

@NgModule({
    declarations: [],
    providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatTabsModule,
        MatCardModule,
        MatGridListModule,
        MatProgressBarModule,
        MatInputModule,
        MatDividerModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatDialogModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatListModule,
        MatRadioModule,
        MatTableModule,
        MatSortModule,
        MatRippleModule,
    ],
    exports: [
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatTabsModule,
        MatCardModule,
        MatGridListModule,
        MatProgressBarModule,
        MatInputModule,
        MatDividerModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatDialogModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatListModule,
        MatRadioModule,
        MatTableModule,
        MatSortModule,
        MatRippleModule,
    ],
})
export class CustomMaterialModule {}
