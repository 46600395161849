import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';
import { RtCustomerTypeDefault, RtCustomerTypeDefaultAdapter } from '@bds/railtrac-models';

@Injectable({
  providedIn: 'root'
})
export class RtCustomerTypeDefaultService {

  controllerName = 'rtCustomerTypeDefault';

    constructor(
        private http: HttpClient,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private adapter: RtCustomerTypeDefaultAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
    ) { }
    
    getCustomerTypeDefaults(custType: string): Observable<RtCustomerTypeDefault[]>{
        return this.http
            .get<RtCustomerTypeDefault[]>(`${this.odatabaseUrl}${this.controllerName}?$filter=custType eq '${custType}'`)
            .pipe(map((response) => response['value'].map((data) =>
                this.adapter.adapt(data))));
    }
}

