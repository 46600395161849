import { faFastrac } from '@bds/fa-svg-icons';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './fastrac-management-dialog.component.html',
    styleUrls: ['./fastrac-management-dialog.component.scss'],
})
export class FastracManagementDialogComponent implements OnInit {
    iconFastrac = faFastrac;

    constructor(public dialogRef: MatDialogRef<FastracManagementDialogComponent>) {}

    ngOnInit() {}

    onNoClick() {
        this.dialogRef.close();
    }
}
