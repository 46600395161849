import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { take } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import DxDataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { faPen, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { RtArrAtDest, RtClm, RtClmSightCode, RtStateCode } from '@bds/railtrac-models';
import {
    BdsDialogConfirmComponent,
    BdsDialogConfirmModel,
    BdsDialogInfoComponent,
    BdsDialogInfoModel,
} from '@bds/components';
import { AsciiConverterService } from '@bds/helpers';
import { BdsDestinationCriteriaGridDialogComponent } from '../bds-destination-criteria-grid-dialog/bds-destination-criteria-grid-dialog.component';
import { BdsDestinationCriteriaGridDialogModel } from '../../models/bds-destination-criteria-grid-dialog.model';
import { BdsDestinationCriteriaService } from '@bds/data-access';

@Component({
    selector: 'bds-destination-criteria-list',
    templateUrl: './bds-destination-criteria-list.component.html',
    styleUrls: ['./bds-destination-criteria-list.component.scss'],
})
export class BdsDestinationCriteriaListComponent implements OnInit, OnChanges {
    //customerDisplayExpr: string[] = ['customerNo', 'customerName'];
    //customerDisplayExpr: string = 'customerNo customerName';
    customerSource: DataSource;
    gridSource: DxDataSource;
    iconEdit = faPen;
    iconDelete = faTimes;
    selectedRowKeys: Array<number> = [];

    @Input() customerNumber: string;
    @Input() customerStore: ODataStore;
    @Input() dense = true;
    @Input() destinationStore: DataSource;
    @Input() latestClm: RtClm;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';
    @Input() railroadStore: ODataStore;
    @Input() sightCodeLookup: RtClmSightCode[] = [];
    @Input() stateLookup: RtStateCode[] = [];

    @Output() destinationDelete: EventEmitter<RtArrAtDest> = new EventEmitter();
    @Output() destinationSaved: EventEmitter<any> = new EventEmitter();

    get dxMatStyle(): string {
        switch (this.matStyle) {
            case 'fill':
                return 'filled';
            case 'outline':
                return 'outlined';
            case 'standard':
                return 'underlined';
            default:
                return 'underlined';
        }
    }

    constructor(
        public dialog: MatDialog,
        private destinationService: BdsDestinationCriteriaService,
        private asciiService: AsciiConverterService,
    ) {}

    ngOnInit() {
        this.setUpGrid();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.destinationStore &&
            !changes.destinationStore.firstChange &&
            this.destinationStore
        ) {
            this.setUpGrid();
        }
    }

    alterCriteria(selectedId: number, clone: boolean) {
        this.destinationService
            .read(selectedId)
            .pipe(take(1))
            .subscribe((dest) => {
                if (clone) {
                    dest.ormId = undefined;
                }

                const dialogData: BdsDestinationCriteriaGridDialogModel = {
                    destinationCriteria: dest,
                    customers: this.customerStore,
                    railroads: this.railroadStore,
                    sightCodes: this.sightCodeLookup,
                    states: this.stateLookup,
                    isClone: clone,
                    isNew: false,
                };

                const dialogRef = this.dialog.open(BdsDestinationCriteriaGridDialogComponent, {
                    width: '550px',
                    data: dialogData,
                });

                dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        this.destinationSaved.emit();
                    }
                });
            });
    }

    onAdd() {
        const emptyDestination: RtArrAtDest = new RtArrAtDest();

        emptyDestination.customerNo = this.customerNumber || undefined;
        emptyDestination.customerDeliveryHours = 0;

        if (this.latestClm) {
            // Figure out the sight code letter based on the API information
            const sightCodeLetter: string = this.asciiService.convertAsciiToChar(
                this.latestClm.sightCode,
            );

            emptyDestination.locationCity = this.latestClm.locationCity || undefined;
            emptyDestination.locationState = this.latestClm.locationState || undefined;
            emptyDestination.railroad = this.latestClm.road || undefined;
            emptyDestination.sightCode = sightCodeLetter || undefined;
        }

        const dialogData: BdsDestinationCriteriaGridDialogModel = {
            destinationCriteria: emptyDestination,
            customers: this.customerStore,
            railroads: this.railroadStore,
            sightCodes: this.sightCodeLookup,
            states: this.stateLookup,
            isClone: false,
            isNew: true,
        };

        const dialogRef = this.dialog.open(BdsDestinationCriteriaGridDialogComponent, {
            width: '550px',
            data: dialogData,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.destinationSaved.emit();
            }
        });
    }

    onClone() {
        if (this.selectedRowKeys && this.selectedRowKeys.length) {
            this.alterCriteria(this.selectedRowKeys[0], true);
        } else {
            this.warnNoSelection('clone');
        }
    }

    onEdit() {
        if (this.selectedRowKeys && this.selectedRowKeys.length) {
            this.alterCriteria(this.selectedRowKeys[0], false);
        } else {
            this.warnNoSelection('edit');
        }
    }

    onRemove() {
        if (this.selectedRowKeys && this.selectedRowKeys.length) {
            this.destinationService
                .read(this.selectedRowKeys[0])
                .pipe(take(1))
                .subscribe((dest) => {
                    let dcInfo: string = '';
                    if (dest && dest.customerNo && dest.sightCode) {
                        dcInfo = ` for ${dest.customerNo} with sight code ${dest.sightCode}`;
                    }

                    const confirmInfo: BdsDialogConfirmModel = {
                        content: 'Are you sure you want to delete this record' + dcInfo + '?',
                        actionText: 'Delete',
                        defaultToYes: false,
                    };

                    const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
                        width: '400px',
                        data: confirmInfo,
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                        if (result) {
                            this.destinationDelete.emit(dest);
                        }
                    });
                });
        } else {
            this.warnNoSelection('delete');
        }
    }

    setUpGrid() {
        this.gridSource = this.destinationStore;
        // this.gridSource = new DataSource({
        //     store: this.destinationStore,
        //     paginate: true,
        //     pageSize: 20,
        // });

        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        if (this.customerNumber) {
            this.gridSource.filter(['customerNo', '=', this.customerNumber]);
        }
    }

    warnNoSelection(action: string) {
        const info: BdsDialogInfoModel = {
            title: 'Destination Criteria',
            content: `Please select a record to ${action}.`,
        };
        this.dialog.open(BdsDialogInfoComponent, {
            data: info,
        });
    }
}
