import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BdsDialogConfirmComponent,
    BdsDialogConfirmModel,
    BdsDxDataGridDirective,
    bdsGridModeType,
} from '@bds/components';
import { CommunicationService } from '@bds/core';
import { RtRouteService } from '@bds/data-access';
import { FastracDxGridWrapperComponent, FastracScreenBaseComponent } from '@bds/fastrac';
import { CarIdTransformService } from '@bds/helpers';
import { BdsInternalDataResult } from '@bds/models';
import { FastracColsService } from '@bds/services';
import DataSource from 'devextreme/data/data_source';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'bds-route-screen',
    templateUrl: './route-screen.component.html',
    styleUrls: ['./route-screen.component.scss'],
})
export class RouteScreenComponent extends FastracScreenBaseComponent implements OnInit, OnDestroy {
    public createViewPath = './create';
    public detailViewPath = './detail';
    @ViewChild('targetDirective') targetDirective: BdsDxDataGridDirective;
    @ViewChild('dxGridWrapper') fastracGridComponent: FastracDxGridWrapperComponent;

    /**
     * Implemented as part of base component
     *
     * @see FastracScreenBaseComponent
     */
    public gridId = 10;
    public gridMode: bdsGridModeType = 'multiple';
    public gridSource: DataSource;
    public multiViewPath = 'routes-screen/multi';
    public selectedRowKeys: string[] = [];

    get grid() {
        return this.fastracGridComponent?.grid;
    }
    constructor(
        public routeService: RtRouteService,
        public dialog: MatDialog,
        public router: Router,
        public route: ActivatedRoute,
        public snackbar: MatSnackBar,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);

        this.getDataStore();

        this.routeService.refreshRouteGridChangeEmitted$.subscribe((x) => {
            this.refreshGrid();
        });
    }

    private getDataStore() {
        this.gridSource = new DataSource({
            store: this.routeService.getODataStore(),
            reshapeOnPush: true,
            paginate: true,
            pageSize: 20,
        });
    }

    refreshGrid(): void {
        this.getDataStore();

        if (this.grid) {
            void this.grid.instance.refresh();
        }
    }

    createRecord(): void {
        void this.router.navigate([this.createViewPath], {
            queryParamsHandling: 'preserve',
            relativeTo: this.route,
        });
    }

    onGridFastracIdChanged($event) {
        super.onGridFastracIdChanged($event);
        this.refreshGrid();
    }

    deleteRecords(): void {
        const confirmInfo: BdsDialogConfirmModel = {
            content: `Are you sure you want to delete ${this.selectedRowKeys.length} routes?`,
            actionText: 'Delete',
        };

        const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
            data: confirmInfo,
        });

        dialogRef.beforeClosed().subscribe((obs) => {
            if (obs) {
                const obsRes = this.selectedRowKeys.map((key) => this.routeService.delete(key));

                forkJoin(obsRes).subscribe((j: BdsInternalDataResult[]) => {
                    if (j?.length) {
                        if (!j[0].succeeded) {
                            const msg = j[0]?.errors[0]['description'];
                            this.snackbar.open(msg, 'Ok', { duration: 8000 });
                            return;
                        }
                    }

                    if (j.every((k) => k)) {
                        const msg =
                            (j.length === 1 ? 'Routes' : 'All routes-screen') +
                            ' deleted successfully';
                        this.snackbar.open(msg, 'Ok', { duration: 3000 });
                    } else if (j.every((k) => !k)) {
                        const msg = 'Failed to delete ' + (j.length === 1 ? 'route' : 'any route');
                        this.snackbar.open(msg, 'Ok', { duration: 8000 });
                    } else {
                        this.snackbar.open('Some routes-screen could not be deleted', 'Ok', {
                            duration: 8000,
                        });
                    }
                    void this.grid.instance.refresh();
                    this.router.navigate(['./routes'], {
                        queryParams: {},
                    });
                });
            }
        });
    }

    public navigate(currentSelectedRowKeys: number[]): void {
        if (currentSelectedRowKeys) {
            if (currentSelectedRowKeys.length === 1) {
                void this.router.navigate([this.detailViewPath, currentSelectedRowKeys[0]], {
                    queryParams: {},
                    relativeTo: this.route,
                });
            }
            if (currentSelectedRowKeys.length > 1) {
                void this.router.navigate([this.multiViewPath], {
                    queryParams: { ids: currentSelectedRowKeys.join(',') },
                });
            }
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    public onMenuItemClicked(): void {
        console.log('RouteScreenComponent: onMenuItemClicked');
    }
}
