import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtCustomerType implements From<RtCustomerType> {
    constructor(public custType?: string, public custTypeDscr?: string) {}
    from(trip: RtCustomerType): RtCustomerType {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtCustomerType>('custType'), server: 'custType' },
    { client: nameof<RtCustomerType>('custTypeDscr'), server: 'custTypeDscr' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCustomerTypeAdapter extends TwoWayAdapter<RtCustomerType> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

@Injectable({
    providedIn: 'root',
})
export class RtCustomerTypeMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        const columns = [
            {
                dataField: nameof<RtCustomerType>('custType'),
                dataType: 'string',
                caption: 'custType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCustomerType>('custTypeDscr'),
                dataType: 'string',
                caption: 'custTypeDscr',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtCustomerType>('custType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerType>('custTypeDscr'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
