import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class RtDiversionFormService {
    constructor(private fb: UntypedFormBuilder) {}

    createForm(): UntypedFormGroup {
        return this.fb.group({
            header: this.createHeader(),
            roads: this.fb.array([]),
        });
    }

    createHeader(): UntypedFormGroup {
        return this.fb.group({
            divertType: ['', Validators.required],
            divertDateTime: ['', Validators.required],
            le: ['', Validators.required],
            shipmentType: [''],
            newCustNo: [''],
            newCustName: [''],
            newCustCity: [''],
            newCustState: [''],
            newReturnCity: [''],
            newReturnSplc: [''],
            newReturnState: [''],
            whoPays: ['', Validators.required],
        });
    }

    createRoad(): UntypedFormGroup {
        return this.fb.group({
            bolNo: ['', Validators.maxLength(15)],
            businessGroup: [''],
            custNo: [''],
            fleetId: [''],
            orderNo: ['', Validators.maxLength(15)],
            returnSplc: [''],
            road: ['', [Validators.required, Validators.maxLength(4)]],
            routeCode: ['', Validators.maxLength(20)],
            routeDescription: ['', Validators.maxLength(75)],
            shipmentType: [''],
            diversions: this.fb.array([]),
        });
    }

    createDiversion(): UntypedFormGroup {
        return this.fb.group({
            bolNo: ['', Validators.maxLength(15)],
            businessGroup: [''],
            carInit: [''],
            carNo: [''],
            carStatus: [''],
            clmDateTime: [''],
            clmLe: [''],
            clmLocCity: [''],
            clmLocSplc: [''],
            clmLocState: [''],
            clmRoad: [''],
            clmSightCode: [''],
            custNo: [''],
            divert: [''],
            fleetId: [''],
            orderNo: ['', Validators.maxLength(15)],
            ormId: [''],
            prevBolNo: [''],
            prevBusinessGroup: [''],
            prevCustNo: [''],
            prevDestCity: [''],
            prevDestState: [''],
            prevFleetId: [''],
            prevOrderNo: [''],
            prevReturnCity: [''],
            prevReturnState: [''],
            prevRouteCode: [''],
            returnSplc: [''],
            routeCode: ['', Validators.maxLength(20)],
            routeDescription: ['', Validators.maxLength(75)],
            shipDateTime: [''],
            shipmentType: [''],
            tcmEquipmentOrmId: [''],
        });
    }
}
