import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BdsDialogInfoComponent, BdsDialogInfoModel } from '@bds/components';
import { FastracColumn, FastracDxGridModel, FastracLite } from '@bds/models';
import { RtErpcOrigin } from '@bds/railtrac-models';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { take } from 'rxjs/operators';
import { BdsErpcOriginService } from '@bds/data-access';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    BdsErpcOriginGridDialogComponent,
    BdsErpcOriginGridDialogModel,
} from '@bds/smart-components';

@Component({
    selector: 'bds-erpc-origin-list',
    templateUrl: './bds-erpc-origin-list.component.html',
    styleUrls: ['./bds-erpc-origin-list.component.scss'],
})
export class BdsErpcOriginListComponent implements OnInit, OnChanges {
    public customizeColumns: (columns: unknown[]) => void;
    public gridSource: DataSource;
    public selectedRowKeys: number[] = [];

    // Fastrac properties
    public currentFastrac: FastracDxGridModel;
    public emptyFastrac: FastracDxGridModel = new FastracDxGridModel(0, [], [], []);
    public fastracId = 0;
    public fastracList: FastracLite[] = [];
    public showFastrac = false;

    @Input() gridId = 5;
    @Input() needsRefresh: boolean;
    @Input() originColumns: FastracColumn[];
    @Input() originStore: ODataStore;
    @Input() selectedFastrac: number;
    @Input() splcErpcStore: ODataStore;
    @Output() originSaved: EventEmitter<unknown> = new EventEmitter();
    @Output() selectedFastracChange: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('bdsOriginGrid') dataGrid: DxDataGridComponent;

    constructor(
        public dialog: MatDialog,
        public originService: BdsErpcOriginService,
        private snackbar: MatSnackBar,
    ) {
        this.currentFastrac = { ...this.emptyFastrac };

        this.customizeColumns = (columns: unknown[]) => {
            this.customizeColumnsInternal(columns);
        };
    }

    ngOnInit() {
        this.setUpGrid();

        if (this.selectedFastrac) {
            this.fastracId = this.selectedFastrac;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.originStore && !changes.originStore.firstChange) {
            this.setUpGrid();
        }
    }

    onAddOrigin(): void {
        const dialogData: BdsErpcOriginGridDialogModel = {
            isNew: true,
            origin: new RtErpcOrigin(),
            originId: undefined,
            splcStore: this.splcErpcStore,
        };

        const dialogRef = this.dialog.open(BdsErpcOriginGridDialogComponent, {
            width: '750px',
            data: dialogData,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                this.originSaved.emit();
                void this.dataGrid.instance.refresh();
            }
        });
    }

    onApplyFastrac(event: FastracDxGridModel): void {
        if (event && event.id) {
            this.fastracId = event.id;
            this.selectedFastracChange.emit(event.id);
        }

        this.currentFastrac = { ...event };
    }

    onEditOrigin(): void {
        if (this.selectedRowKeys && this.selectedRowKeys.length) {
            this.originService
                .read(this.selectedRowKeys[0])
                .pipe(take(1))
                .subscribe((orig) => {
                    const dialogData: BdsErpcOriginGridDialogModel = {
                        isNew: false,
                        origin: orig,
                        originId: this.selectedRowKeys[0],
                        splcStore: this.splcErpcStore,
                    };

                    const dialogRef = this.dialog.open(BdsErpcOriginGridDialogComponent, {
                        width: '750px',
                        data: dialogData,
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                        if (result !== undefined) {
                            this.originSaved.emit();
                            void this.dataGrid.instance.refresh();
                        }
                    });
                });
        } else {
            const info: BdsDialogInfoModel = {
                title: 'Edit an Origin',
                content: 'Please select an Origin to edit.',
            };

            this.dialog.open(BdsDialogInfoComponent, {
                data: info,
            });
        }
    }

    openSnackBar(message: string, action: string, success: boolean): void {
        let style = 'error-snack';
        if (success) {
            style = 'success-snack';
        }

        this.snackbar.open(message, action, {
            duration: 3000,
            panelClass: [style],
            horizontalPosition: 'right',
        });
    }

    async onDeleteOrigin(): Promise<void> {
        if (this.selectedRowKeys && this.selectedRowKeys.length) {
            try {
                const result = await firstValueFrom(
                    this.originService.delete(this.selectedRowKeys[0]),
                );

                if (result) {
                    void this.dataGrid.instance.refresh();
                    this.openSnackBar('Origin deleted successfully', '', true);
                }
            } catch {
                const info: BdsDialogInfoModel = {
                    title: 'Delete an Origin',
                    content: 'Origin cannot be deleted.',
                };

                this.dialog.open(BdsDialogInfoComponent, {
                    data: info,
                });
            }
        } else {
            const info: BdsDialogInfoModel = {
                title: 'Edit an Origin',
                content: 'Please select an Origin to edit.',
            };

            this.dialog.open(BdsDialogInfoComponent, {
                data: info,
            });
        }
    }

    onFastracDesignsChanged(event: FastracLite[]): void {
        this.fastracList = event;
    }

    onFastracIdChanged(event: number): void {
        this.fastracId = event;
        this.selectedFastracChange.emit(event);
    }

    onFastracVisibleChanged(event: boolean): void {
        this.showFastrac = event;
    }

    onGridFastracIdChanged(event: number): void {
        if (this.fastracId !== event) {
            this.fastracId = event;
            this.selectedFastracChange.emit(event);
        }
    }

    onSelectedRowKeysChanged(event: number[]): void {
        this.selectedRowKeys = event;
    }

    onShowFastrac(): void {
        this.showFastrac = true;
    }

    setUpGrid(): void {
        this.gridSource = new DataSource({
            store: this.originStore,
            paginate: true,
            pageSize: 20,
        });
    }

    private customizeColumnsInternal(columns: any[]) {
        columns.forEach((col) => {
            const fCol = this.originColumns.find((f) => f.mappedField === col.dataField);
            if (!!fCol) {
                col.caption = fCol.displayField || col.caption;
                col.dataType = fCol.datatype || col.dataType;
            }
        });
    }
}
