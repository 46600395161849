import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyFieldTemplatesModule } from '@bds/components';
import { BourqueCoreModule } from '@bds/core';
import { BdsHelpersModule } from '@bds/helpers';
import { VendorsModule } from '../../vendors';
import { HistoricDatesDialogModule } from '../../internal-dialogs';
import { EquipmentDetailsMenuComponent } from './equipment-details-menu/equipment-details-menu.component';
import { EquipmentMechComponent } from './equipment-mech/equipment-mech.component';
import { EquipmentMechCommonComponent } from './equipment-mech-common/equipment-mech-common.component';
import { EquipmentMechDatesComponent } from './equipment-mech-dates/equipment-mech-dates.component';
import { EquipmentMechFieldsComponent } from './equipment-mech-fields/equipment-mech-fields.component';

@NgModule({
    declarations: [
        EquipmentDetailsMenuComponent,
        EquipmentMechComponent,
        EquipmentMechCommonComponent,
        EquipmentMechDatesComponent,
        EquipmentMechFieldsComponent,
    ],
    imports: [
        BdsHelpersModule,
        BourqueCoreModule,
        CommonModule,
        FormlyFieldTemplatesModule,
        FormlyModule,
        HistoricDatesDialogModule,
        VendorsModule,
    ],
    exports: [
        EquipmentDetailsMenuComponent,
        EquipmentMechComponent,
        EquipmentMechCommonComponent,
        EquipmentMechDatesComponent,
        EquipmentMechFieldsComponent,
    ],
})
export class EquipmentDetailsComponentsModule {}
