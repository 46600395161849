<mat-toolbar class="sticky-top mat-elevation-z6 bds-toolbar-blue">
    <mat-toolbar-row class="row">
        <div class="col-11">Create Route</div>
        <div class="col-1 text-right">
            <button mat-stroked-button [disabled]="isSaveBtnDisabled" (click)="save()">
                <fa-icon [icon]="iconSave"></fa-icon>
                Save Route
            </button>
            <button *ngIf="errors?.length > 0" mat-icon-button (click)="drawer.toggle()">
                <fa-icon [icon]="iconError"></fa-icon>
            </button>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-drawer-container class="example-container" autosize>
    <mat-tab-group>
        <mat-tab label="Build Route Code By Segment">
            <ng-template matTabContent>
                <div class="container-fluid">
                    <bds-route-code #form formType="createBySegment">
                        <h4
                            class="d-flex flex-row"
                            style="border-bottom: 1px solid rgba(0, 0, 0, 0.87)"
                        >
                            <span>Route Segments</span>
                            <span class="flex-fill"> </span>
                        </h4>
                        <bds-rt-route-segment-builder></bds-rt-route-segment-builder>
                    </bds-route-code>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Free Form">
            <ng-template matTabContent>
                <div class="container-fluid">
                    <bds-route-code formType="freeform"></bds-route-code>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <mat-drawer #drawer mode="side" position="end">
        <div class="container">
            <h4>Validation Summary</h4>
            <div *ngFor="let error of errors" class="text-danger" style="padding-bottom: 10px">
                <fa-icon [icon]="iconError"></fa-icon>
                <small> {{ error.validationMessage }}</small>
            </div>
        </div>
    </mat-drawer>
</mat-drawer-container>
