import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { BdsDxDataGridModule } from '@bds/components';
import { BdsFastracModule } from '@bds/fastrac';
import { RtDivertedTripsComponent } from '../rt-diversion-management/rt-diverted-trips/rt-diverted-trips.component';
import { RtDivertedTripListComponent } from '../rt-diversion-management/rt-diverted-trip-list/rt-diverted-trip-list.component';
import { RouterModule } from '@angular/router';
import { BdsUiModule } from '@bds/ui';
import { BdsGridDetailsModule } from '@bds/components';

@NgModule({
    declarations: [RtDivertedTripsComponent, RtDivertedTripListComponent],
    imports: [
        BdsDxDataGridModule,
        BdsFastracModule,
        CommonModule,
        DxDataGridModule,
        RouterModule,
        BdsUiModule,
        BdsGridDetailsModule,
    ],
    exports: [RtDivertedTripsComponent],
})
export class RtDiversionManagementModule {}
