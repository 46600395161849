<h1 *ngIf="!!data.title" mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
    <p>{{ data.prompt }}</p>
    <mat-form-field class="w-100">
        <mat-label>{{ data.label }}</mat-label>
        <input matInput bdsShoutcase #dialogInput [formControl]="control" cdkFocusInitial />
        <mat-hint *ngIf="!!maxLength" align="end"
            >{{ control.value?.length || 0 }}/{{ maxLength }}</mat-hint
        >
        <mat-hint *ngIf="control.errors?.required">Name is required.</mat-hint>
        <mat-hint *ngIf="control.errors?.duplicateName">Name must be unique.</mat-hint>
        <mat-hint *ngIf="control.errors?.maxlength">Name is too long.</mat-hint>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" (click)="onNoClick()" color="accent">
        {{ !!data.noLabel ? data.noLabel : 'No' }}
    </button>
    <button
        mat-button
        type="submit"
        class="text-uppercase"
        [mat-dialog-close]="control.value"
        color="accent"
        [disabled]="control.errors"
    >
        {{ !!data.yesLabel ? data.yesLabel : 'Yes' }}
    </button>
</mat-dialog-actions>
