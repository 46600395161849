import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RtRouteService } from '@bds/data-access';
import { faSave } from '@bds/fa-svg-icons';
import { ScrollSpyService } from '@bds/helpers';
import { RtRouteCode } from '@bds/railtrac-models';
import { faGlobeAmericas } from '@fortawesome/pro-light-svg-icons';
import { faArrowsH } from '@fortawesome/pro-regular-svg-icons';
import { faPen, faRoute } from '@fortawesome/pro-solid-svg-icons';
import { firstValueFrom, of } from 'rxjs';
import { RouteCodeFormService } from './bds-route-code/bds-route-code-form.service';
import { faBars, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'bds-route',
    templateUrl: './bds-route.component.html',
    styleUrls: ['./bds-route.component.css'],
    providers: [RouteCodeFormService],
})
export class BdsRouteComponent {
    public detailViewPath = 'routes/detail';
    selectedrouteCode: string;
    $selectedRoute = of(<RtRouteCode>{});
    duoIconRoute = faRoute;
    iconRouteDetail = faGlobeAmericas;
    iconRouteSegments = faArrowsH;
    iconSave = faSave;
    iconEdit = faPen;
    @Input() showMenu = true;
    @Input() readOnly = false;

    errors: [
        { validationField: string; validationMessage: string; validationMessageTypeId: number },
    ];
    iconMenu = faBars;
    iconError = faExclamationTriangle;
    showFiller = false;

    constructor(
        private routeService: RtRouteService,
        public scrollSpyService: ScrollSpyService,
        public router: Router,
        public route: ActivatedRoute,
        private routeCodeFormService: RouteCodeFormService,
        private snackBar: MatSnackBar,
        private location: Location,
    ) {}

    @Input() set routeCode(value: string) {
        this.selectedrouteCode = value;
        this.$selectedRoute = this.routeService.read(value);
    }

    async onEditClick(): Promise<void> {
        if (this.routeCodeFormService.form.valid && !this.readOnly) {
            const result = await firstValueFrom(
                this.routeService.update(this.routeCodeFormService.form.getRawValue()),
            );

            if (result) {
                if (result['relatedObject']) {
                    this.snackBar.open('Route changes saved!', 'Ok', { duration: 3000 });
                    this.routeService.refreshRouteGridSource.next(true);
                    this.navigateToReturnUrl();

                    return;
                }
                this.snackBar.open('Route changes failed!', 'Ok', { duration: 3000 });
            }

            return;
        }

        void this.router.navigate([this.detailViewPath, this.selectedrouteCode], {
            queryParams: { isReturn: btoa('true') },
        });
    }

    private navigateToReturnUrl() {
        const isReturn = this.route.snapshot.queryParams['isReturn'];

        if (atob(isReturn ?? '') === 'true') {
            this.location.back();
        }
    }
}
