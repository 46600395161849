<mat-card>
    <div class="row">
        <div class="col-12 col-sm-6 detail-area">
            <div class="row">
                <div class="col-4 item-label">Divert Date Time:</div>
                <div class="col-8">
                    {{ diversion.divertDateTime | date : dateTimeFormat : 'UTC' }}
                </div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Load/Empty:</div>
                <div class="col-8">{{ diversion.le }}</div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Road:</div>
                <div class="col-8">{{ diversion.road }}</div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Divert Type:</div>
                <div class="col-8">{{ diversion.diversionDescription }}</div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Shipment Type:</div>
                <div class="col-8">
                    {{
                        convertToKVPString(
                            diversion.shipmentType,
                            diversion.shipmentTypeDescription
                        )
                    }}
                </div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Divert Location:</div>
                <div class="col-8">
                    {{
                        convertToLocationString(
                            diversion.divLocSplc,
                            diversion.divLocCity,
                            diversion.divLocState
                        )
                    }}
                </div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Who Pays:</div>
                <div class="col-8">{{ diversion.whoPaysDescription }}</div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Div Route Dscr:</div>
                <div class="col-8">{{ diversion.divRouteDescription }}</div>
            </div>
        </div>
        <div class="col-12 col-sm-6 detail-area">
            <div class="row">
                <div class="col-4 item-label">Prev Route Code:</div>
                <div class="col-8">{{ diversion.prevRouteCode }}</div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Prev Route Dscr:</div>
                <div class="col-8">{{ diversion.prevRouteDescription }}</div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Prev Cust No:</div>
                <div class="col-8">{{ diversion.prevCustNo }}</div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Prev Cust Name:</div>
                <div class="col-8">{{ diversion.prevCustName }}</div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Prev Order No:</div>
                <div class="col-8">{{ diversion.prevOrderNo }}</div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Prev BOL No:</div>
                <div class="col-8">{{ diversion.prevBolNo }}</div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Prev Dest SPLC:</div>
                <div class="col-8">
                    {{
                        convertToLocationString(
                            diversion.prevDestSplc,
                            diversion.prevDestCity,
                            diversion.prevDestState
                        )
                    }}
                </div>
            </div>
            <div class="row">
                <div class="col-4 item-label">Prev Return SPLC:</div>
                <div class="col-8">
                    {{
                        convertToLocationString(
                            diversion.prevReturnSplc,
                            diversion.prevReturnCity,
                            diversion.prevReturnState
                        )
                    }}
                </div>
            </div>
        </div>
    </div>
</mat-card>
