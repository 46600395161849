import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtRouteCode implements From<RtRouteCode> {
    constructor(
        public routeCode?: string,
        public routeDscr?: string,
        public originCity?: string,
        public originState?: string,
        public road?: string,
        public destCity?: string,
        public destState?: string,
        public releaseCity?: string,
        public releaseState?: string,
        public returnCity?: string,
        public returnState?: string,
        public contractNo?: string,
        public etaHrsOut?: number,
        public etaHrsIn?: number,
        public mileageType?: number,
        public autoEtaCalcYorn?: string,
        public autoRteDtlCalcYorn?: string,
        public oneWayRound?: string,
        public originCode?: string,
        public autoCloseYorn?: string,
        public rule11?: string,
        public impExp?: string,
        public originSplc?: string,
        public destSplc?: string,
        public releaseSplc?: string,
        public returnSplc?: string,
        public comments?: string,
        public assignment?: string,
        public dlvRoad?: string,
        public beginCarStatus?: string,
        public activeStatus?: string,
        public revRouteDscr?: string,
        public pathCode?: string,
        public borderCrossSplc?: string,
        public lastClmPerfCalc?: Date,
        public clmPerfCalcHistDays?: number,
        public clmPerfCalcResetDays?: number,
        public blockingCode?: string,
    ) {}
    from(trip: RtRouteCode): RtRouteCode {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtRouteCode>('routeCode'), server: 'routeCode' },
    { client: nameof<RtRouteCode>('routeDscr'), server: 'routeDescription' },
    { client: nameof<RtRouteCode>('originCity'), server: 'originCity' },
    { client: nameof<RtRouteCode>('originState'), server: 'originState' },
    { client: nameof<RtRouteCode>('road'), server: 'railroad' },
    { client: nameof<RtRouteCode>('destCity'), server: 'destinationCity' },
    { client: nameof<RtRouteCode>('destState'), server: 'destinationState' },
    { client: nameof<RtRouteCode>('releaseCity'), server: 'releaseCity' },
    { client: nameof<RtRouteCode>('releaseState'), server: 'releaseState' },
    { client: nameof<RtRouteCode>('returnCity'), server: 'returnCity' },
    { client: nameof<RtRouteCode>('returnState'), server: 'returnState' },
    { client: nameof<RtRouteCode>('contractNo'), server: 'contractNo' },
    { client: nameof<RtRouteCode>('etaHrsOut'), server: 'etaHoursOut' },
    { client: nameof<RtRouteCode>('etaHrsIn'), server: 'etaHoursIn' },
    { client: nameof<RtRouteCode>('mileageType'), server: 'mileageType' },
    { client: nameof<RtRouteCode>('autoEtaCalcYorn'), server: 'useForAutomaticEtaCalc' },
    { client: nameof<RtRouteCode>('autoRteDtlCalcYorn'), server: 'usedForAutoRouteDetailCalc' },
    { client: nameof<RtRouteCode>('oneWayRound'), server: 'oneWayOrRoundTrip' },
    { client: nameof<RtRouteCode>('originCode'), server: 'originCode' },
    { client: nameof<RtRouteCode>('autoCloseYorn'), server: 'autoCloseAllowed' },
    { client: nameof<RtRouteCode>('rule11'), server: 'rule11' },
    { client: nameof<RtRouteCode>('impExp'), server: 'usedForImportExport' },
    { client: nameof<RtRouteCode>('originSplc'), server: 'originSplc' },
    { client: nameof<RtRouteCode>('destSplc'), server: 'destinationSplc' },
    { client: nameof<RtRouteCode>('releaseSplc'), server: 'releaseSplc' },
    { client: nameof<RtRouteCode>('returnSplc'), server: 'returnSplc' },
    { client: nameof<RtRouteCode>('comments'), server: 'comments' },
    { client: nameof<RtRouteCode>('assignment'), server: 'assignment' },
    { client: nameof<RtRouteCode>('dlvRoad'), server: 'deliveryRoad' },
    { client: nameof<RtRouteCode>('beginCarStatus'), server: 'beginCarStatus' },
    { client: nameof<RtRouteCode>('activeStatus'), server: 'isActive' },
    { client: nameof<RtRouteCode>('revRouteDscr'), server: 'reverseRouteDescription' },
    { client: nameof<RtRouteCode>('pathCode'), server: 'pathCode' },
    { client: nameof<RtRouteCode>('borderCrossSplc'), server: 'borderCrossSplc' },
    { client: nameof<RtRouteCode>('lastClmPerfCalc'), server: 'lastClmPerformanceCalculationDate' },
    {
        client: nameof<RtRouteCode>('clmPerfCalcHistDays'),
        server: 'clmPerformanceCalculationHistoricalDays',
    },
    {
        client: nameof<RtRouteCode>('clmPerfCalcResetDays'),
        server: 'clmPerformanceCalculationResetDays',
    },
    { client: nameof<RtRouteCode>('blockingCode'), server: 'blockingCode' },
];

@Injectable({
    providedIn: 'root',
})
export class RtRouteCodeAdapter extends TwoWayAdapter<RtRouteCode> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtRouteCodeMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtRouteCode>('routeCode'),
                dataType: 'string',
                caption: 'routeCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('routeDscr'),
                dataType: 'string',
                caption: 'routeDscr',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('originCity'),
                dataType: 'string',
                caption: 'originCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('originState'),
                dataType: 'string',
                caption: 'originState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('road'),
                dataType: 'string',
                caption: 'road',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('destCity'),
                dataType: 'string',
                caption: 'destCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('destState'),
                dataType: 'string',
                caption: 'destState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('releaseCity'),
                dataType: 'string',
                caption: 'releaseCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('releaseState'),
                dataType: 'string',
                caption: 'releaseState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('returnCity'),
                dataType: 'string',
                caption: 'returnCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('returnState'),
                dataType: 'string',
                caption: 'returnState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('contractNo'),
                dataType: 'string',
                caption: 'contractNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('etaHrsOut'),
                dataType: 'number',
                caption: 'etaHrsOut',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('etaHrsIn'),
                dataType: 'number',
                caption: 'etaHrsIn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('mileageType'),
                dataType: 'number',
                caption: 'mileageType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('autoEtaCalcYorn'),
                dataType: 'string',
                caption: 'autoEtaCalcYorn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('autoRteDtlCalcYorn'),
                dataType: 'string',
                caption: 'autoRteDtlCalcYorn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('oneWayRound'),
                dataType: 'string',
                caption: 'oneWayRound',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('originCode'),
                dataType: 'string',
                caption: 'originCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('autoCloseYorn'),
                dataType: 'string',
                caption: 'autoCloseYorn',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('rule11'),
                dataType: 'string',
                caption: 'rule11',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('impExp'),
                dataType: 'string',
                caption: 'impExp',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('originSplc'),
                dataType: 'string',
                caption: 'originSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('destSplc'),
                dataType: 'string',
                caption: 'destSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('releaseSplc'),
                dataType: 'string',
                caption: 'releaseSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('returnSplc'),
                dataType: 'string',
                caption: 'returnSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('comments'),
                dataType: 'string',
                caption: 'comments',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('assignment'),
                dataType: 'string',
                caption: 'assignment',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('dlvRoad'),
                dataType: 'string',
                caption: 'dlvRoad',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('beginCarStatus'),
                dataType: 'string',
                caption: 'beginCarStatus',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('activeStatus'),
                dataType: 'string',
                caption: 'activeStatus',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('revRouteDscr'),
                dataType: 'string',
                caption: 'revRouteDscr',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('pathCode'),
                dataType: 'string',
                caption: 'pathCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('borderCrossSplc'),
                dataType: 'string',
                caption: 'borderCrossSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('lastClmPerfCalc'),
                dataType: 'Date',
                caption: 'lastClmPerfCalc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('clmPerfCalcHistDays'),
                dataType: 'number',
                caption: 'clmPerfCalcHistDays',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('clmPerfCalcResetDays'),
                dataType: 'number',
                caption: 'clmPerfCalcResetDays',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteCode>('blockingCode'),
                dataType: 'string',
                caption: 'blockingCode',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<RtRouteCode>('routeCode'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(20)]),
        );
        formGroup.addControl(
            nameof<RtRouteCode>('routeDscr'),
            new UntypedFormControl('', [Validators.maxLength(75)]),
        );
        formGroup.addControl(nameof<RtRouteCode>('originCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('originState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('road'), new UntypedFormControl(null));
        formGroup.addControl(nameof<RtRouteCode>('destCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('destState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('releaseCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('releaseState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('returnCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('returnState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('contractNo'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtRouteCode>('etaHrsOut'),
            new UntypedFormControl('240', [Validators.maxLength(4)]),
        );
        formGroup.addControl(
            nameof<RtRouteCode>('etaHrsIn'),
            new UntypedFormControl('240', [Validators.maxLength(4)]),
        );
        formGroup.addControl(
            nameof<RtRouteCode>('mileageType'),
            new UntypedFormControl('', [
                Validators.maxLength(3),
                Validators.min(0),
                Validators.max(100),
            ]),
        );
        formGroup.addControl(nameof<RtRouteCode>('autoEtaCalcYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('autoRteDtlCalcYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('oneWayRound'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtRouteCode>('originCode'),
            new UntypedFormControl('', [Validators.maxLength(5)]),
        );
        formGroup.addControl(nameof<RtRouteCode>('autoCloseYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('rule11'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('impExp'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtRouteCode>('originSplc'),
            new UntypedFormControl(null, Validators.required),
        );
        formGroup.addControl(
            nameof<RtRouteCode>('destSplc'),
            new UntypedFormControl(null, Validators.required),
        );
        formGroup.addControl(
            nameof<RtRouteCode>('releaseSplc'),
            new UntypedFormControl(null, Validators.required),
        );
        formGroup.addControl(
            nameof<RtRouteCode>('returnSplc'),
            new UntypedFormControl(null, Validators.required),
        );
        formGroup.addControl(
            nameof<RtRouteCode>('comments'),
            new UntypedFormControl('', [Validators.maxLength(60)]),
        );
        formGroup.addControl(
            nameof<RtRouteCode>('assignment'),
            new UntypedFormControl('', [Validators.maxLength(5)]),
        );
        formGroup.addControl(nameof<RtRouteCode>('dlvRoad'), new UntypedFormControl(null));
        formGroup.addControl(nameof<RtRouteCode>('beginCarStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('activeStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteCode>('revRouteDscr'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtRouteCode>('pathCode'),
            new UntypedFormControl('', [Validators.maxLength(5)]),
        );
        formGroup.addControl(nameof<RtRouteCode>('borderCrossSplc'), new UntypedFormControl(null));
        formGroup.addControl(nameof<RtRouteCode>('lastClmPerfCalc'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtRouteCode>('clmPerfCalcHistDays'),
            new UntypedFormControl('90', [Validators.maxLength(5)]),
        );
        formGroup.addControl(
            nameof<RtRouteCode>('clmPerfCalcResetDays'),
            new UntypedFormControl('30', [Validators.maxLength(5)]),
        );
        formGroup.addControl(nameof<RtRouteCode>('blockingCode'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
