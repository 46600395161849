<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <div class="railroad-grid-container">
        <dx-data-grid id="bdsReportGrid" [dataSource]="gridSource" height="80vh" minHeight="200px">
            <dxi-column
                dataField="Consignee"
                cellTemplate="consigneeTemplate"
                caption="Consignee"
                width="auto"
                [minWidth]="100"
                allowSearch="true"
            ></dxi-column>

            <div *dxTemplate="let item of 'consigneeTemplate'">
                <p class="">{{ item.data.cust_no }} {{ item.data.cust_name }}</p>
            </div>
            <dxi-column
                dataField="Dest"
                cellTemplate="destTemplate"
                caption="Destination"
                width="auto"
                [minWidth]="100"
                width="200px"
                allowSearch="true"
            ></dxi-column>

            <div *dxTemplate="let item of 'destTemplate'">
                <p class="">{{ item.data.dest_city }} {{ item.data.dest_state }}</p>
            </div>
            <dxi-column
                dataField="cars_held"
                dataType="number"
                caption="#Cars Held"
                width="200px"
            ></dxi-column>
            <dxi-column
                dataField="avg_hold_days"
                dataType="number"
                caption="Avg. Hold Days"
                width="200px"
            ></dxi-column>
            <dxi-column dataField="std_dev_days" dataType="number" caption="Std. Dev" width="200px"
                ><dxo-format [precision]="2" valueFormat="###0.##"> </dxo-format
            ></dxi-column>
        </dx-data-grid>
    </div>
</div>
