<mat-toolbar class="mb-1">
    <div style="display: flex; justify-content: space-between" class="col-2">
        <div style="flex-grow: 1">
            <button mat-flat-button (click)="onAddComments()">
                <fa-duotone-icon
                    [icon]="commentAdd"
                    primaryColor="forestgreen"
                    secondaryColor="#97bdd3"
                ></fa-duotone-icon>
                Add Comments
            </button>
        </div>
    </div>
    <div
        *ngIf="showUpdateButton"
        style="display: flex; justify-content: space-between"
        class="col-2"
    >
        <div style="flex-grow: 1">
            <button mat-flat-button (click)="OnUpdateComments()">
                <fa-duotone-icon
                    [icon]="commentEdit"
                    primaryColor="forestgreen"
                    secondaryColor="#97bdd3"
                ></fa-duotone-icon>
                Update Comments
            </button>
        </div>
    </div>
</mat-toolbar>
<div *ngIf="showUpdateButton">
    <ng-container *ngFor="let eachTrip of trips; let i = index">
        <rt-multi-comment-detail
            #commentDetail
            [tripId]="eachTrip.ormId"
            [trip]="eachTrip"
            (commentPresent)="onCommentPresentEvent($event)"
        ></rt-multi-comment-detail>
    </ng-container>
</div>
