import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, timer } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { FormErrorHandlerService } from '@bds/helpers';
import { RtErpcOrigin } from '@bds/railtrac-models';
import { BdsErpcOriginService } from '@bds/data-access';

@Component({
    selector: 'bds-erpc-origin-details',
    templateUrl: './bds-erpc-origin-details.component.html',
    styleUrls: ['./bds-erpc-origin-details.component.scss'],
})
export class BdsErpcOriginDetailsComponent implements OnInit, OnChanges, OnDestroy {
    currentOrigin: RtErpcOrigin;
    originForm: UntypedFormGroup;
    selectedSplc: { erpcCity: string; erpcState: string; splc: string };
    splcSource: DataSource;
    splcDisplayExpr: string[] = ['erpcCity', 'erpcState', 'splc'];
    originCodeFieldDisabled = true;

    originCodeAsyncValidator = (originService: BdsErpcOriginService, time: number = 500) => {
        return (input: UntypedFormControl) => {
            return timer(time).pipe(
                switchMap(() => originService.getOriginCountByOriginCode(input.value)),
                map((result) => {
                    return result ? { originCodeExists: true } : null;
                }),
            );
        };
    };

    private ngUnsubscribe$: Subject<void> = new Subject<void>();

    // For form styles
    @Input() dense = true;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';

    @Input() isNew: boolean;
    @Input() selectedOrigin: RtErpcOrigin;
    @Input() splcErpcStore: ODataStore;
    @Output() originChange: EventEmitter<RtErpcOrigin> = new EventEmitter<RtErpcOrigin>();
    @Output() validChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() splcChange: EventEmitter<string> = new EventEmitter<string>();

    get dxMatStyle(): string {
        switch (this.matStyle) {
            case 'fill':
                return 'filled';
            case 'outline':
                return 'outlined';
            case 'standard':
                return 'underlined';
            default:
                return 'underlined';
        }
    }

    constructor(
        private formErrorService: FormErrorHandlerService,
        public originService: BdsErpcOriginService,
    ) {
        this.originForm = this.getFormGroup();
        this.selectedSplc = { erpcCity: '', erpcState: '', splc: '' };
    }

    ngOnInit() {
        this.originCodeFieldDisabled = true;

        if (this.isNew) {
            this.originForm
                .get('originCode')
                .setAsyncValidators(this.originCodeAsyncValidator(this.originService));
            this.originForm.get('originCode').updateValueAndValidity();
            this.originCodeFieldDisabled = false;
        }

        this.originForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            this.currentOrigin = this.originForm.getRawValue();
            this.currentOrigin.erpcOrigin = this.selectedSplc.erpcCity;
            this.currentOrigin.erpcState = this.selectedSplc.erpcState;
            this.originChange.emit(this.currentOrigin);
        });

        this.originForm.statusChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            let isValid: boolean;

            if (this.originForm.errors || this.originForm.invalid) {
                isValid = false;
            } else {
                isValid = true;
            }

            this.validChange.emit(isValid);
        });

        if (this.splcErpcStore) {
            this.setUpSplcOptions();
        }

        if (this.selectedOrigin) {
            this.originForm.patchValue(this.selectedOrigin);
            this.selectedSplc.splc = this.selectedOrigin.splc;

            if (!this.originForm?.get('originCode')?.value) {
                this.originCodeFieldDisabled = false;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedOrigin && !changes.selectedOrigin.firstChange && this.selectedOrigin) {
            this.originForm.patchValue(this.selectedOrigin);
        }

        if (changes.isNew && !changes.isNew.firstChange) {
            if (this.isNew) {
                this.originForm
                    .get('originCode')
                    .setAsyncValidators(this.originCodeAsyncValidator(this.originService));
                this.originForm.get('originCode').updateValueAndValidity();
            } else {
                this.originForm.get('originCode').clearAsyncValidators();
                this.originForm.get('originCode').updateValueAndValidity();
            }
        }

        if (changes.splcErpcStore && !changes.splcErpcStore.firstChange && this.splcErpcStore) {
            this.setUpSplcOptions();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    enableOrDisableFields(): void {
        if (!this.selectedOrigin || (this.isNew && !this.selectedOrigin.ormId)) {
            // TODO: Enable fields
        } else {
            // TODO: Disable fields
        }
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        return this.formErrorService.getFormError(formItem);
    }

    getFormGroup(): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<RtErpcOrigin>('erpcOrigin'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtErpcOrigin>('erpcState'),
            new UntypedFormControl('', [Validators.maxLength(2)]),
        );
        formGroup.addControl(
            nameof<RtErpcOrigin>('splc'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(6)]),
        );
        formGroup.addControl(
            nameof<RtErpcOrigin>('originCode'),
            new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(5),
                Validators.pattern(/^[A-Z|a-z|0-9]+$/),
            ]),
        );
        formGroup.addControl(nameof<RtErpcOrigin>('masterOriginYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtErpcOrigin>('ormId'), new UntypedFormControl(''));

        return formGroup;
    }

    onSplcChanged(event): void {
        console.log(event);

        if (event && event.selectedItem) {
            this.selectedSplc = event.selectedItem;
            this.splcChange.emit(this.selectedSplc.splc);
        }
    }

    setUpSplcOptions(): void {
        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        this.splcSource = new DataSource({
            store: this.splcErpcStore,
            paginate: true,
            pageSize: 10,
            searchExpr: this.splcDisplayExpr,
            select: ['erpcCity', 'erpcState', 'splc'],
            sort: ['erpcCity', 'erpcState'],
            key: 'splc',
        });
    }
}
