import { Component, OnInit } from '@angular/core';
import { RtTripRefFieldDefService } from '@bds/railtrac';
import { RtTripRefFieldDef } from '@bds/railtrac-models';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-multi-shipment-screen',
    template: ` <rt-multi-trip
        [tripIds]="tripIds$ | async"
        [tripRefFieldDefs$]="tripRefFieldDefs$"
    ></rt-multi-trip>`,
})
export class MultiShipmentScreenComponent implements OnInit {
    tripIds$: Observable<number[]>;
    tripRefFieldDefs$: Observable<RtTripRefFieldDef[]>;

    constructor(
        private route: ActivatedRoute,
        private refFieldDefService: RtTripRefFieldDefService,
    ) {}

    ngOnInit(): void {
        this.tripIds$ = this.route.queryParams.pipe(
            map((params) => params['ids'].split(',') || null),
        );
        this.tripRefFieldDefs$ = this.refFieldDefService.getReferenceFieldDefs();
    }
}

