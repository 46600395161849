import { RtRouteCode } from '@bds/railtrac-models';
import { RtRouteService } from '@bds/data-access';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BdsRouteSearchDialogModel } from './bds-route-search-dialog-model';
import { FastracScreenBaseComponent } from '@bds/fastrac';
import { ActivatedRoute, Router } from '@angular/router';
import { FastracBuilderService, FastracColsService } from '@bds/services';
import { CommunicationService } from '@bds/core';
import { CarIdTransformService } from '@bds/helpers';
import { FastracLite } from '@bds/models';
import DataSource from 'devextreme/data/data_source';

@Component({
    templateUrl: './bds-route-search-dialog.component.html',
    styleUrls: ['./bds-route-search-dialog.component.scss'],
})
export class BdsRouteSearchDialogComponent
    extends FastracScreenBaseComponent
    implements OnInit, OnDestroy
{
    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
    @Input() gridId = 10;

    public fastracName = 'SEARCH ROUTE';
    public fastracId = 0;
    public fastracItems: FastracLite[] = [];

    public dataStore: DataSource;
    public selectedRowKeys: string;

    constructor(
        public dialogRef: MatDialogRef<BdsRouteSearchDialogComponent, RtRouteCode>,
        @Inject(MAT_DIALOG_DATA) public data: BdsRouteSearchDialogModel,
        public service: RtRouteService,
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        public fastracService: FastracBuilderService,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
        this.dataStore = this.service.getRouteCodeDataSource();
        this.skipUpdatingPageUrl = true;
    }

    onSelectClick(): void {
        const data = this.dataGrid.instance.getSelectedRowsData();
        if (data.length > 0) {
            const cust = this.service.adapter.adapt(data[0]);
            this.dialogRef.close(cust);
        }
    }

    onKeyDown(e: any) {
        if (e.event.key === 'Tab') {
            e.event.preventDefault();
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.data?.splc) {
            this.dataStore.filter(['originSplc', '=', this.data?.splc]);
            setTimeout(() => {
                this.dataGrid.instance.repaint();
            }, 0);
            return;
        }
        this.fastracService.getFastracDesigns(this.gridId).subscribe((data) => {
            this.fastracItems = data;
            data.map((eachFastrac) => {
                if (eachFastrac.name == this.fastracName) {
                    this.fastracId = eachFastrac.id;
                }
            });
        });
    }
}
