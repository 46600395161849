import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog.component';

@NgModule({
    declarations: [ConfirmDeleteDialogComponent],
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule, MatToolbarModule],
    exports: [ConfirmDeleteDialogComponent],
})
export class ConfirmDeleteDialogModule {}
