import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'bds-state-formly',
    template: ` <bds-bds-state-select
        [label]="field.templateOptions.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
    >
    </bds-bds-state-select>`,
})
export class BdsStateFormlyComponent extends FieldType {
    constructor() {
        super();
    }
}
