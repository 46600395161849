<div>
    <bds-erpc-origin-list
        [originStore]="originList"
        [originColumns]="originColumns"
        [splcErpcStore]="splcErpcStore"
        [gridId]="gridId"
        [needsRefresh]="!isSaving"
        [selectedFastrac]="fastracId"
        (selectedFastracChange)="onFastracIdChanged($event)"
        (originSaved)="onOriginSaved($event)"
    >
    </bds-erpc-origin-list>
</div>
