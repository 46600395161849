import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ODataStore from 'devextreme/data/odata/store';
import { RtPreferredRoute } from '@bds/railtrac-models';
import { Observable, map } from 'rxjs';
import { nameof } from '@bds/core';

export abstract class RtPrefRouteServiceApiServiceOptions {
    apiUrl = `api/prefroute/`;
    odataUrl = `odata/prefroute`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsPrefRouteService {
    constructor(private options: RtPrefRouteServiceApiServiceOptions, private http: HttpClient) {}

    add(model) {
        return this.http.post(this.options.apiUrl, model);
    }

    edit(model) {
        return this.http.put(`${this.options.apiUrl}/${model.ormId}`, model);
    }

    manage(model) {
        if (model.ormId) {
            return this.edit(model);
        }

        return this.add(model);
    }

    getByOrmId(ormId: number): Observable<RtPreferredRoute> {
        const pathFilter = `${nameof<RtPreferredRoute>('ormId')} eq ${ormId}`;
        return this.http
            .get<{ value: RtPreferredRoute[] }>(
                `${this.options.odataUrl}?$filter=${pathFilter}&$expand=rtRouteCode,customerFact&$top=1`,
            )
            .pipe(
                map((res) => {
                    if (res.value && res.value.length > 0) {
                        return res.value[0];
                    }
                    return null;
                }),
            );
    }

    delete(id: number) {
        return this.http.delete(`${this.options.apiUrl}/${id}`);
    }

    getODataStore(): ODataStore {
        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: 'ormId',
            deserializeDates: false,
        });
    }
}

