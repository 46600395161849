import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BdsDxDataGridModule } from '@bds/components';
import { BdsHelpersModule } from '@bds/helpers';
import { BdsFastracModule } from '@bds/fastrac';
import { BdsErpcOriginComponent } from './bds-erpc-origin.component';
import { BdsErpcOriginListComponent } from './bds-erpc-origin-list/bds-erpc-origin-list.component';
import { BdsErpcOriginDetailsComponent } from './bds-erpc-origin-details/bds-erpc-origin-details.component';
import { BdsErpcOriginGridDialogComponent } from '@bds/smart-components';
import { BourqueCoreModule } from '@bds/core';

@NgModule({
    declarations: [
        BdsErpcOriginComponent,
        BdsErpcOriginGridDialogComponent,
        BdsErpcOriginListComponent,
        BdsErpcOriginDetailsComponent,
    ],
    imports: [
        BourqueCoreModule,
        BdsDxDataGridModule,
        BdsFastracModule,
        BdsHelpersModule,
        CommonModule,
        DxDataGridModule,
        DxLookupModule,
        DxSelectBoxModule,
        FontAwesomeModule,
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        MatToolbarModule,
        ReactiveFormsModule,
    ],
    exports: [BdsErpcOriginComponent, BdsErpcOriginGridDialogComponent],
})
export class BdsErpcOriginModule {}

