import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    OnDestroy,
} from '@angular/core';
import { RtJeopardizedShipment, RtTrip, RtClm } from '@bds/railtrac-models';
import { RailtracClmService } from '../../railtrac-clm/railtrac-clm.service';
import { RailtracTripService } from '../../railtrac-trip/railtrac-trip.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'rt-jeopardized-shipment-reason-base',
    template: '<div>This component should not be used directly!</div>',
})
export class RtJeopardizedShipmentReasonBaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input() shipment: RtJeopardizedShipment;
    @Input() resolved: boolean;
    @Input() addTripRouterLink: [];
    @Output() change = new EventEmitter();
    @Output() popoutTrip = new EventEmitter<number>();

    protected destroyer$ = new BehaviorSubject<boolean>(false);

    private _trip$ = new BehaviorSubject<RtTrip>(null);
    public trip$: Observable<RtTrip> = this._trip$.asObservable();

    private _clms$ = new BehaviorSubject<RtClm[]>([]);
    public clms$: Observable<RtClm[]> = this._clms$.asObservable();

    constructor(public tripService: RailtracTripService, public clmService: RailtracClmService) {
        console.log('RtJeopardizedShipmentReasonBase ctor');
    }

    ngOnInit() {
        console.log('RtJeopardizedShipmentReasonBase ngOnInit');
    }
    ngOnChanges(changes: SimpleChanges) {
        console.log('RtJeopardizedShipmentReasonBase ngOnChanges');
        if (changes['shipment']) {
            this.load(changes['shipment'].currentValue);
        }
    }
    ngOnDestroy() {
        console.log('RtJeopardizedShipmentReasonBase ngOnDestroy');
        this.destroyer$.complete();
    }

    private load(shipment: RtJeopardizedShipment) {
        this.loadTrip(shipment.ormId);
        this.loadClmForTrip(shipment.ormId);
    }

    private loadTrip(ormId: number) {
        this.tripService.read(ormId).subscribe((trip) => {
            this._trip$.next(trip);
        });
    }

    private loadClmForTrip(tripId: number) {
        this.clmService.getClmsForTrip(tripId).subscribe((clms) => {
            this._clms$.next(clms);
        });
    }

    onPopoutTripclicked($event: number) {
        this.popoutTrip.emit($event);
    }
}
