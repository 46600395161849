<div class="h2">Mechanical Profile</div>
<div *ngIf="errors && errors.length" class="error-warning">{{ errors[0] }}</div>

<div class="row">
    <div class="col-md-8 col-12">
        <div class="row equip-card">
            <mat-card>
                <div class="card-title">Common Mechanical Fields</div>
                <bds-equipment-mech-common
                    [selectedEquipment]="selectedEquipment$ | async"
                    [componentCategories]="componentCategories$ | async"
                    [unitsOfMeasure]="unitsOfMeasure$ | async"
                    (equipmentChange)="onEquipmentMechChanged($event)"
                >
                </bds-equipment-mech-common>
            </mat-card>
        </div>

        <div class="row equip-card">
            <mat-card>
                <div class="card-title">Mechanical Fields</div>
                <bds-equipment-mech-fields
                    [categories]="categories$ | async"
                    [dynamicDropdownValues]="dynamicDropdownValues$ | async"
                    [mechCategories]="(selectedEquipment$ | async)?.equipMechCategories"
                    [mechFieldsByCategory]="mechFieldsByCategory$ | async"
                    [mechValues]="(selectedEquipment$ | async)?.equipmentMechEav"
                    [mechDates]="(selectedEquipment$ | async)?.equipMechDates"
                    (modelChange)="onEquipMechValuesChanged($event)"
                    (equipCategoryChange)="onEquipCategoryChanged($event)"
                >
                </bds-equipment-mech-fields>
            </mat-card>
        </div>
    </div>

    <div class="col-md-4 col-12 equip-card">
        <mat-card>
            <div class="card-title">Equipment Dates</div>
            <bds-equipment-mech-dates
                [equipmentId]="(selectedEquipment$ | async)?.ormId"
                [equipmentInit]="(selectedEquipment$ | async)?.equipmentInit"
                [equipmentNo]="(selectedEquipment$ | async)?.equipmentNo"
                [mechFieldsByCategory]="mechFieldsByCategory$ | async"
                [mechDates]="(selectedEquipment$ | async)?.equipMechDates"
                (modelChange)="onEquipMechDatesChanged($event)"
                (historicDatesUpdate)="onMechDatesUpdated()"
            >
            </bds-equipment-mech-dates>
        </mat-card>
    </div>
</div>
