import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuLinkItem } from '@bds/core';
import { DynamicDropDownValues, RtUnitMeasure } from '@bds/models';
import { BdsDynamicDropDownValuesService, RtUnitMeasureService } from '@bds/services';
import { faObjectGroup } from '@fortawesome/pro-duotone-svg-icons';
import { Observable, of, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
    EquipmentCategoryService,
    EquipmentComponentCategoryService,
    EquipmentService,
} from '../../data-access';
import {
    EquipMechCategory,
    Equipment,
    EquipmentCategory,
    EquipmentCategoryWithFields,
    EquipmentComponentCategory,
    EquipmentMechDate,
    EquipmentMechEav,
} from '../../models';

@Component({
    selector: 'bds-equipment-details',
    templateUrl: './equipment-details.component.html',
    styleUrls: ['./equipment-details.component.scss'],
})
export class EquipmentDetailsComponent implements OnInit, OnChanges, OnDestroy {
    categories$: Observable<EquipmentCategory[]>;
    componentCategories$: Observable<EquipmentComponentCategory[]>;
    componentsLabel = 'Equipment Components';
    componentsLink = 'componentsanchor';
    componentsName = 'equipmentComponents';
    currentDate: Date = new Date();
    currentEquipmentCategories: string[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentEquipmentDates: any;
    currentEquipmentMech: Equipment;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentEquipmentValues: any;
    dynamicDropdownValues$: Observable<DynamicDropDownValues[]>;
    equipmentCategories: EquipMechCategory[] = [];
    equipmentDatesValid = true;
    equipmentInit: string;
    equipmentMechDates: EquipmentMechDate[] = [];
    equipmentMechEav: EquipmentMechEav[] = [];
    equipmentMechValid = false;
    equipmentNo: string;
    equipmentValuesValid = true;
    errors: { type: string; errors: string[] }[] = [];
    iconComponents = faObjectGroup;
    iconReports = faObjectGroup;
    isSaving = false;
    listLabel = 'Equipment Table';
    listLink = 'listanchor';
    listName = 'equipmentList';
    mechFieldsByCategory$: Observable<EquipmentCategoryWithFields[]>;
    mechLabel = 'Mechanical Profile';
    mechLink = 'mechanchor';
    mechName = 'equipmentMech';
    menuLinks: MenuLinkItem[] = [];
    reportsLabel = 'Reporting Categories';
    reportsLink = 'reportsanchor';
    reportsName = 'equipmentReports';
    selectedEquipment$: Observable<Equipment>;
    unitsOfMeasure$: Observable<RtUnitMeasure[]>;

    private ngUnsubscribe$: Subject<void> = new Subject<void>();

    @Input() selectedEquipId: number;
    @Input() userName: string;
    @Output() equipmentSave: EventEmitter<{ id: number; isNew: boolean }> = new EventEmitter<{
        id: number;
        isNew: boolean;
    }>();

    @ViewChild('componentsPanel') componentsSection: MatExpansionPanel;
    @ViewChild('reportsPanel') reportsSection: MatExpansionPanel;

    constructor(
        public equipmentService: EquipmentService,
        private categoryService: EquipmentCategoryService,
        private componentCategoryService: EquipmentComponentCategoryService,
        private dynamicDropdownService: BdsDynamicDropDownValuesService,
        private unitMeasureService: RtUnitMeasureService,
        private _snackbar: MatSnackBar,
    ) {
        this.getAllCategories();
        this.getAllComponentCategories();
        this.getAllUnitsOfMeasure();
        this.getDynamicDropDownValues();
    }

    ngOnInit() {
        this.setUpMenuLinks();
        this.resetMenuLinkHide();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedEquipId && (this.selectedEquipId || this.selectedEquipId === 0)) {
            this.getSelectedEquipment();
            this.closeAllSections();
            this.resetAllDirtyFlags();
            this.resetMenuLinkHide();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    clearEquipment(): void {
        this.currentEquipmentCategories = [];
        this.currentEquipmentDates = {};
        this.currentEquipmentMech = undefined;
        this.currentEquipmentValues = {};
        this.equipmentDatesValid = true;
        this.equipmentMechValid = false;
        this.equipmentValuesValid = true;
        this.equipmentMechEav = [];
        this.equipmentMechDates = [];
        this.equipmentCategories = [];
        this.equipmentInit = null;
        this.equipmentNo = null;
    }

    closeAllSections(): void {
        if (this.componentsSection) {
            this.componentsSection.close();
        }

        if (this.reportsSection) {
            this.reportsSection.close();
        }
    }

    createEquipment(): void {
        this.isSaving = true;

        if (!this.currentEquipmentMech.equipmentId) {
            this.currentEquipmentMech.equipmentId =
                this.currentEquipmentMech.equipmentInit + this.currentEquipmentMech.equipmentNo;
        }

        this.equipmentService
            .create(this.currentEquipmentMech)
            .pipe(take(1))
            .subscribe(
                (e) => {
                    this.isSaving = false;
                    this.equipmentSave.emit({ id: e.ormId, isNew: true });
                    this.openSnackBar('Equipment Created', '', true);
                },
                (err) => {
                    console.error('ERROR', err);
                    this.isSaving = false;
                    this.openSnackBar('Error Creating Equipment', '', false);
                },
            );
    }

    createEquipmentMechCategories(): EquipMechCategory[] {
        const newEquipmentCategories: EquipMechCategory[] = [];
        this.currentEquipmentCategories.forEach((c) => {
            const index = this.equipmentCategories.findIndex((e) => e.equipmentCategory === c);
            if (index > -1) {
                newEquipmentCategories.push(<EquipMechCategory>{
                    ormId: this.equipmentCategories[index].ormId,
                    equipmentOrmId: this.selectedEquipId,
                    equipmentCategory: c,
                });
            } else {
                newEquipmentCategories.push(<EquipMechCategory>{
                    ormId: 0,
                    equipmentOrmId: this.selectedEquipId,
                    equipmentCategory: c,
                });
            }
        });

        return newEquipmentCategories;
    }

    createMechDates(): EquipmentMechDate[] {
        // Only used for new equipment
        const newEquipMechDates: EquipmentMechDate[] = [];

        Object.entries(this.currentEquipmentDates || []).forEach((c) => {
            const values = c[1];

            // Only include the ones with values
            if (this.hasDate(values)) {
                const index = this.equipmentMechDates.findIndex((e) => e.mechField === c[0]);

                const newMechDate: EquipmentMechDate = {
                    mechField: c[0],
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    actualDate: values['actual'] || null,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    nextDueDate: values['next'] || null,
                    equipmentOrmId: this.selectedEquipId,
                    equipmentInit: this.equipmentInit || this.currentEquipmentMech.equipmentInit,
                    equipmentNo: this.equipmentNo || this.currentEquipmentMech.equipmentNo,
                };

                if (index > -1) {
                    newMechDate.ormId = this.equipmentMechDates[index].ormId;
                    newEquipMechDates.push(newMechDate);
                } else {
                    newMechDate.ormId = 0;
                    newEquipMechDates.push(newMechDate);
                }
            }
        });

        return newEquipMechDates;
    }

    createMechEavValues(): EquipmentMechEav[] {
        const newEquipMechEavValues: EquipmentMechEav[] = [];

        Object.entries(this.currentEquipmentValues || []).forEach((c) => {
            const value = (c[1] || '').toString();
            // Only include the ones with values
            if (value) {
                const index = this.equipmentMechEav.findIndex((e) => e.mechField === c[0]);

                const newMechEav: EquipmentMechEav = {
                    mechField: c[0],
                    mechValue: value,
                    equipmentOrmId: this.selectedEquipId,
                    equipmentInit: this.equipmentInit || this.currentEquipmentMech.equipmentInit,
                    equipmentNo: this.equipmentNo || this.currentEquipmentMech.equipmentNo,
                };

                if (index > -1) {
                    newMechEav.ormId = this.equipmentMechEav[index].ormId;
                    newEquipMechEavValues.push(newMechEav);
                } else {
                    newMechEav.ormId = 0;
                    newEquipMechEavValues.push(newMechEav);
                }
            }
        });

        return newEquipMechEavValues;
    }

    getAllCategories(): void {
        this.categories$ = this.categoryService.getAll();
    }

    getAllComponentCategories(): void {
        this.componentCategories$ = this.componentCategoryService.getAll();
    }

    getAllUnitsOfMeasure(): void {
        this.unitsOfMeasure$ = this.unitMeasureService.getAll();
    }

    getDynamicDropDownValues(): void {
        this.dynamicDropdownValues$ = this.dynamicDropdownService.getAllByApp(
            'EQUIPMENT',
            'TCM_EQUIP_MECH_FIELDS',
        );
    }

    getSelectedEquipment(): void {
        if (this.selectedEquipId) {
            this.selectedEquipment$ = this.equipmentService.read(this.selectedEquipId);

            this.selectedEquipment$.pipe(take(1)).subscribe((e) => {
                this.clearEquipment();
                this.equipmentInit = e.equipmentInit;
                this.equipmentNo = e.equipmentNo;

                this.equipmentMechEav = e.equipmentMechEav || [];
                this.equipmentMechEav.forEach(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    (eav) => (this.currentEquipmentValues[eav.mechField] = eav.mechValue || null),
                );

                this.equipmentMechDates = e.equipMechDates || [];
                this.equipmentMechDates.forEach(
                    (dt) =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        (this.currentEquipmentDates[dt.mechField] = {
                            actual: dt.actualDate || null,
                            next: dt.nextDueDate || null,
                        }),
                );

                if (e.equipMechCategories) {
                    this.equipmentCategories = e.equipMechCategories;
                    this.currentEquipmentCategories = e.equipMechCategories.map(
                        (c) => c.equipmentCategory,
                    );
                    this.mechFieldsByCategory$ = this.categoryService.getCatsAndFields(
                        this.currentEquipmentCategories,
                    );
                } else {
                    this.equipmentCategories = [];
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    this.mechFieldsByCategory$ = of(null);
                }
            });
        } else {
            const newEquipment: Equipment = <Equipment>{
                ormId: this.selectedEquipId,
                equipmentMechEav: [],
                equipMechDates: [],
                equipMechCategories: [],
                equipmentComponentCategories: [],
                equipmentCompart: [],
            };

            this.clearEquipment();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.mechFieldsByCategory$ = of(null);
            this.selectedEquipment$ = of(newEquipment);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    hasDate(dates: any): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (dates && (dates['actual'] || dates['next'])) {
            return true;
        }

        return false;
    }

    hasError(errorType: string): boolean {
        if (this.errors && this.errors.findIndex((e) => e.type === errorType) > -1) {
            return true;
        }
        return false;
    }

    isValid(): boolean {
        return this.equipmentDatesValid && this.equipmentMechValid && this.equipmentValuesValid;
    }

    onEquipCategoryChanged(event: string[]): void {
        if (event && event.length) {
            this.currentEquipmentCategories = event;
            this.mechFieldsByCategory$ = this.categoryService.getCatsAndFields(event);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.mechFieldsByCategory$ = of(null);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onEquipmentDatesChanged(event: { value: any; isValid: boolean }): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.currentEquipmentDates = event.value;
        this.equipmentDatesValid = event.isValid;
    }

    onEquipmentDatesSaved(): void {
        // Update equipment to get reset dates and alert parent to update grid if dynamic fields
        this.getSelectedEquipment();
        this.equipmentSave.emit({ id: this.selectedEquipId, isNew: false });
    }

    onEquipmentMechChanged(event: { value: Equipment; isValid: boolean }): void {
        this.currentEquipmentMech = event.value;
        this.equipmentMechValid = event.isValid;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onEquipmentValuesChanged(event: { value: any; isValid: boolean }): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.currentEquipmentValues = event.value;
        this.equipmentValuesValid = event.isValid;
    }

    onSaveEquipmentMech(): void {
        if (!this.isValid()) {
            this.openSnackBar('Unable to Save - Not all Equipment information is Valid', '', false);
            return;
        }

        this.currentDate = new Date();
        this.currentEquipmentMech.equipMechCategories = this.createEquipmentMechCategories();
        this.currentEquipmentMech.equipmentMechEav = this.createMechEavValues();
        this.currentEquipmentMech.equipMechDates = this.createMechDates();

        if (!this.selectedEquipId) {
            this.createEquipment();
        } else {
            this.updateEquipment();
        }
    }

    onSelectSection(target: string): void {
        if (this.componentsSection && target.toLowerCase().indexOf('component') > -1) {
            this.componentsSection.open();
        } else if (this.reportsSection && target.toLowerCase().indexOf('report') > -1) {
            this.reportsSection.open();
        }
    }

    openSnackBar(message: string, action: string, success: boolean): void {
        let style = 'error-snack';
        if (success) {
            style = 'success-snack';
        }

        this._snackbar.open(message, action, {
            duration: 3000,
            panelClass: [style],
            horizontalPosition: 'right',
        });
    }

    resetAllDirtyFlags(): void {
        this.menuLinks.forEach((m) => {
            if (!this.hasError(m.name)) {
                this.resetMenuLinkDirtyFlag(m.name, false);
            }
        });
    }

    resetMenuLinkDirtyFlag(menuName: string, isDirty: boolean): void {
        const index: number = this.menuLinks.findIndex((i) => i.name === menuName);

        if (index > -1) {
            const dirtyStatus: boolean = this.menuLinks[index].isDirty;
            if (dirtyStatus !== isDirty) {
                const updatedLink: MenuLinkItem = { ...this.menuLinks[index], isDirty: isDirty };
                this.menuLinks = [
                    ...this.menuLinks.slice(0, index),
                    updatedLink,
                    ...this.menuLinks.slice(index + 1),
                ];
            }
        }
    }

    resetMenuLinkHide(): void {
        if (this.selectedEquipId) {
            this.menuLinks.forEach((link) => (link.hide = false));
        } else if (this.selectedEquipId === 0) {
            this.menuLinks.forEach((link) => {
                if (link.name === this.componentsName || link.name === this.reportsName) {
                    link.hide = true;
                }
            });
        }
        this.menuLinks = [...this.menuLinks];
    }

    setUpMenuLinks(): void {
        this.menuLinks = [
            {
                name: this.listName,
                label: this.listLabel,
                isDirty: false,
                noSelect: true,
                jumpLink: this.listLink,
            },
            {
                name: this.mechName,
                label: this.mechLabel,
                isDirty: false,
                noSelect: false,
                jumpLink: this.mechLink,
            },
            {
                name: this.componentsName,
                label: this.componentsLabel,
                isDirty: false,
                noSelect: false,
                hide: false,
                jumpLink: this.componentsLink,
            },
            {
                name: this.reportsName,
                label: this.reportsLabel,
                isDirty: false,
                noSelect: false,
                hide: false,
                jumpLink: this.reportsLink,
            },
        ];
    }

    updateEquipment(): void {
        this.isSaving = true;
        this.errors = null;

        this.equipmentService
            .update(this.currentEquipmentMech.ormId, this.currentEquipmentMech)
            .pipe(take(1))
            .subscribe(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                (e) => {
                    this.getSelectedEquipment();
                    this.isSaving = false;
                    this.equipmentSave.emit({ id: this.selectedEquipId, isNew: false });
                    this.openSnackBar('Equipment Saved', '', true);
                },
                (err) => {
                    console.error('ERROR', err);
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    this.errors = [err];
                    this.isSaving = false;
                    this.openSnackBar('Error Saving Equipment', '', false);
                },
            );
    }
}
