<ng-container *ngIf="useFullTrip; else shortformTrip">
    <rt-trip [trip]="trip"></rt-trip>
</ng-container>
<ng-template #shortformTrip>
    <div class="container mt-3 mb-3">
        <mat-expansion-panel class="mt-3" *ngIf="showInterfaceValidations">
            <mat-expansion-panel-header>
                <mat-panel-title> Interface Validations<span class="nyi"></span> </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                Interface Validations appear here<span class="nyi"></span>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel class="mt-3" *ngIf="useExpansionPanel; else tripDetails">
            <mat-expansion-panel-header>
                <mat-panel-title> Trip Details </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <ng-container *ngTemplateOutlet="tripDetails"></ng-container>
            </ng-template>
        </mat-expansion-panel>
        <ng-template #tripDetails>
            <mat-toolbar class="mt-3 trip-header-bar mat-elevation-z3">
                <mat-toolbar-row>
                    <span *ngIf="!!trip">
                        <!-- <fa-icon [icon]="iconFlag" [fixedWidth]="true" size="lg"></fa-icon> -->
                        {{ trip.carInit }}{{ trip.carNo }}
                        <small>{{
                            trip.shipDatetime | date : 'yyyy-MM-dd HH:mm:ss' : 'UTC'
                        }}</small>
                    </span>
                    <span class="fill-remaining-space"></span>
                    <span>
                        <button mat-stroked-button (click)="onPopoutClicked()">
                            View Trip
                            <fa-icon [icon]="iconExternal"></fa-icon>
                        </button>
                        <button mat-stroked-button (click)="onSaveClicked()">
                            <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
                            Save Trip Details
                        </button>
                        <rt-jeopardized-shipment-reason-options
                            (resolve)="onResolve($event)"
                        ></rt-jeopardized-shipment-reason-options>
                    </span>
                </mat-toolbar-row>
            </mat-toolbar>
            <rt-trip-details
                *ngIf="!!trip; else loading"
                [trip]="trip"
                [showWarnings]="false"
                [showMenu]="false"
            ></rt-trip-details>
        </ng-template>
        <mat-expansion-panel class="mt-3">
            <mat-expansion-panel-header>
                <mat-panel-title> CLMs </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <rt-clm [carId]="trip.carInit + trip.carNo"></rt-clm>
            </ng-template>
        </mat-expansion-panel>
        <div class="row" *ngIf="showDestinationCriteria || showOriginCriteria">
            <div
                [class]="showDestinationCriteria && showOriginCriteria ? 'col-6' : 'col-12'"
                *ngIf="showOriginCriteria"
            >
                <mat-expansion-panel class="mt-3">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Origin Criteria<span class="nyi"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        Origin Criteria Grid Goes Here <span class="nyi"></span>
                    </ng-template>
                </mat-expansion-panel>
            </div>
            <div
                [class]="showDestinationCriteria && showOriginCriteria ? 'col-6' : 'col-12'"
                *ngIf="showDestinationCriteria"
            >
                <mat-expansion-panel class="mt-3">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Destination Criteria<span class="nyi"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        Destination Criteria Grid Goes Here <span class="nyi"></span>
                    </ng-template>
                </mat-expansion-panel>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #loading> Loading... </ng-template>
