import { From, IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';
import { Injectable } from '@angular/core';

export class RtShipmentType implements From<RtShipmentType> {
    constructor(public shipmentType?: string, public shipTypeDesc?: string) {}

    from(item: RtShipmentType): RtShipmentType {
        Object.keys(item).forEach((k) => (this[k] = item[k]));
        return this;
    }
}

@Injectable({
    providedIn: 'root',
})
export class RtShipmentTypeAdapter extends TwoWayAdapter<RtShipmentType> {
    constructor() {
        super();
    }

    public metadata: IModelMetadata[] = relationship;
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtShipmentType>('shipmentType'), server: 'shipmentType' },
    { client: nameof<RtShipmentType>('shipTypeDesc'), server: 'shipTypeDesc' },
];
