import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import { Fastrac } from '@bds/models';
import { AsyncSubject, Observable, catchError, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FastracDesignService implements OnDestroy {
    private destroyer$ = new AsyncSubject<void>();
    private controllerName = 'Fastrac';

    constructor(
        private http: HttpClient,
        private apiErrorHandler: ApiErrorHandlerService,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
    ) {}

    ngOnDestroy() {
        this.destroyer$.complete();
        this.destroyer$.unsubscribe();
    }

    getFastracsByName(fastracNames: string[]): Observable<Fastrac[]> {
        let allNames = fastracNames.join("','");
        console.log(allNames);
        return this.http
            .get<Fastrac[]>(
                `${this.odatabaseUrl}${this.controllerName}?$filter=name in ('${allNames}')`,
            )
            .pipe(
                map((s) => (s as any).value),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}

