import { RtCustomerFact } from '@bds/reference-models';
import { RtErpcOrigin } from '@bds/railtrac-models';
import { RtArrAtDest } from '@bds/railtrac-models';
import { BdsErpcOriginService, RtDestinationAliasService, RtRouteService } from '@bds/data-access';
import { RtJeopardizedShipmentService } from './../../rt-jeopardized-shipment.service';
import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {
    RtJeopardizedShipment,
    RtTrip,
    RtClm,
    RtDestinationAlias,
    RtRouteCode,
    RtArrAtOrig,
    RtTripComment,
} from '@bds/railtrac-models';
import { of, BehaviorSubject, firstValueFrom } from 'rxjs';
import { RailtracTripService } from '../../../railtrac-trip/railtrac-trip.service';
import { RailtracClmService } from '../../../railtrac-clm/railtrac-clm.service';
import { RtDestinationAliasDialogComponent } from '../../rt-destination-alias-dialog/rt-destination-alias-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BdsCustomerService } from '@bds/reference-data-access';
import { RtTripCommentDialogComponent } from '../../../rt-trip-comment/rt-trip-comment-dialog/rt-trip-comment-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RtTripCommentService } from '@bds/data-access';
import {
    BdsOriginCriteriaDialogComponent,
    BdsOriginCriteriaDialogModel,
    BdsOriginCriteriaService,
} from '../../../bds-origin-criteria';
import {
    BdsDestinationCriteriaDialogComponent,
    BdsDestinationCriteriaDialogModel,
} from '@bds/destination-criteria';

@Component({
    selector: 'rt-jeopardized-shipment-dest-diff',
    templateUrl: './rt-jeopardized-shipment-dest-diff.component.html',
    styleUrls: ['./rt-jeopardized-shipment-dest-diff.component.scss'],
})
export class RtJeopardizedShipmentDestDiffComponent implements OnChanges {
    currentClm: RtClm;
    customer: RtCustomerFact;
    destinationAliases: RtDestinationAlias[];
    erpcOrigin: RtErpcOrigin;
    isClmDestinationInError: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isCriteriaCityInError: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isToDestination: boolean;
    routeCode: RtRouteCode;

    @Input() shipment: RtJeopardizedShipment;
    @Input() resolved: boolean;
    @Output() change = new EventEmitter();

    protected _trip: RtTrip;
    public get trip(): RtTrip {
        return this._trip;
    }
    public set trip(value: RtTrip) {
        this._trip = value;
        this.verifyClmDestinationErrorStatus();
    }

    protected _clms: RtClm[];
    public get clms(): RtClm[] {
        return this._clms;
    }
    public set clms(value: RtClm[]) {
        this._clms = value;
        if (value && value.length > 0) {
            this.currentClm = this._clms.reduce((prev, curr) => {
                if (curr.clmDateTime >= prev.clmDateTime) {
                    return curr;
                }
            });
        }
        this.verifyClmDestinationErrorStatus();
        this.verifyCriteriaCityErrorStatus();
    }

    private _criteria: (RtArrAtOrig | RtArrAtDest)[];
    public get criteria(): (RtArrAtOrig | RtArrAtDest)[] {
        return this._criteria;
    }
    public set criteria(value: (RtArrAtOrig | RtArrAtDest)[]) {
        this._criteria = value;
        this.verifyCriteriaCityErrorStatus();
    }

    private _cities: Set<string>;
    public get cities(): Set<string> {
        return this._cities;
    }
    public set cities(value: Set<string>) {
        this._cities = value;
        this.verifyCriteriaCityErrorStatus();
    }

    constructor(
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
        public tripService: RailtracTripService,
        public clmService: RailtracClmService,
        public jeopardyService: RtJeopardizedShipmentService,
        public destinationAliasService: RtDestinationAliasService,
        public customerService: BdsCustomerService,
        public routeService: RtRouteService,
        public tripCommentService: RtTripCommentService,
        public erpcOriginService: BdsErpcOriginService,
        public originCriteriaService: BdsOriginCriteriaService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['shipment']) {
            this.load(changes['shipment'].currentValue);
        }
    }

    async onResolve(event: string): Promise<void> {
        switch (event) {
            case 'addOriginCriteria':
                await this.addToOriginCriteria();
                break;
            case 'addDestinationCriteria':
                this.addToDestinationCriteria();
                break;
            default:
                break;
        }
    }

    private load(shipment: RtJeopardizedShipment) {
        this.loadTrip(shipment.ormId);
        this.loadClmForTrip(shipment.ormId);
    }

    private loadTrip(ormId: number) {
        this.tripService.read(ormId).subscribe((trip) => {
            this.isToDestination = trip.carStatus !== '3';
            if (trip.carStatus === '3') {
                this.loadTripOriginInformation(
                    trip.routeCode,
                    (trip.divertYorN || 'N').toUpperCase() === 'Y',
                    trip.returnCity,
                    trip.returnState,
                );
            } else {
                this.loadCustomerInformation(trip.customerNo);
                this.loadTripDestinationInformation(trip.customerNo);
            }
            this.trip = trip;
        });
    }

    private loadTripOriginInformation(
        routeCode: string,
        isDiversion: boolean,
        returnCity: string,
        returnState: string,
    ) {
        if (!routeCode) {
            return;
        }

        if (!isDiversion) {
            this.loadRouteForTrip(routeCode);
        } else {
            this.loadErpcOriginForTrip(returnCity, returnState);
        }
    }

    private loadRouteForTrip(routeCode: string) {
        this.routeService.read(routeCode).subscribe((rc) => {
            this.loadOriginCriteria(rc.originCode);
            this.routeCode = rc;
        });
    }

    private loadErpcOriginForTrip(city: string, state: string) {
        //of(this.eo.find((f) => f.erpcOrigin === city && f.erpcState === state)).subscribe(
        this.erpcOriginService.getByCityState(city, state).subscribe((erpcOrigin) => {
            this.loadOriginCriteria(erpcOrigin?.originCode);
            this.erpcOrigin = erpcOrigin;
        });
    }

    private loadOriginCriteria(originCode: string) {
        //of(this.aao.filter((f) => f.originCode === originCode)).subscribe((originCriteria) => {
        this.originCriteriaService.getByErpcCode(originCode).subscribe((originCriteria) => {
            this.criteria = originCriteria;
            this.cities = new Set(
                originCriteria.map((m) => `${m.locationCity}, ${m.locationState}`),
            );
        });
    }

    private loadCustomerInformation(customerNumber: string) {
        this.customerService.read(customerNumber).subscribe((customer) => {
            this.customer = customer;
        });
    }

    private loadTripDestinationInformation(customerNumber: string) {
        of(this.aad.filter((f) => f.customerNo === customerNumber)).subscribe(
            (destinationCriteria) => {
                this.criteria = destinationCriteria;
                this.cities = new Set(
                    destinationCriteria.map((m) => `${m.locationCity}, ${m.locationState}`),
                );
            },
        );
    }

    private loadClmForTrip(tripOrmId: number) {
        this.clmService.getClmsForTrip(tripOrmId).subscribe((clms) => {
            clms.sort((a, b) => <any>a.clmDateTime - <any>b.clmDateTime);
            this.clms = clms;
        });
    }

    private filterFunction = (f: RtArrAtDest | RtArrAtOrig) => {
        return !!this.clms && !!this.clms[0] && f.locationCity === this.clms[0].destinationCity;
    };

    private verifyClmDestinationErrorStatus() {
        if (!this.trip || !this.clms) {
            this.isClmDestinationInError.next(false);
        } else {
            const noCity =
                !this.criteria || this.criteria.filter((f) => this.filterFunction(f)).length === 0;
            this.isClmDestinationInError.next(noCity);
        }
    }

    private verifyCriteriaCityErrorStatus() {
        if (!this.clms || !this.criteria) {
            this.isCriteriaCityInError.next(false);
        } else {
            const noCity = this.criteria.filter((f) => this.filterFunction(f)).length === 0;
            this.isCriteriaCityInError.next(noCity);
        }
    }

    generateEmailLink(trip: RtTrip, clm: RtClm): string {
        const destCityState =
            trip.carStatus === '3'
                ? `${trip.returnCity}, ${trip.returnState}`
                : `${trip.destinationCity}, ${trip.destinationState}`;

        const link =
            `mailto:?subject=${trip.carInit}${trip.carNo}` +
            `&body=The car ${trip.carInit}${trip.carNo} currently on the ${clm.road}, appears to be going to ` +
            `${clm.destinationCity}, ${clm.destinationState} when it should be going to ` +
            `${destCityState}.\n\n` +
            `Please verify the destination of this car.`;

        return encodeURI(link);
    }

    createDestinationBypass(): void {
        let tripDestCity: string;
        let tripDestState: string;

        if (!this.trip) {
            tripDestCity = '';
            tripDestState = '';
        } else if (this.trip.carStatus === '1' || this.trip.carStatus === '2') {
            tripDestCity = this.trip.destinationCity;
            tripDestState = this.trip.destinationState;
        } else {
            tripDestCity = this.trip.returnCity;
            tripDestState = this.trip.returnState;
        }

        let x: RtDestinationAlias = new RtDestinationAlias(
            0,
            tripDestCity,
            tripDestState,
            (this.currentClm || { destinationCity: '' }).destinationCity,
            (this.currentClm || { destinationState: '' }).destinationState,
        );

        const dialogRef = this.dialog.open(RtDestinationAliasDialogComponent, {
            width: '550px',
            data: { item: x },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (!!result) {
                this.destinationAliasService.create(result).subscribe(
                    (res) => {
                        this.snackbar.open('Destination alias added', 'Ok', { duration: 3000 });
                        this.change.emit();
                        // this.jeopardyService.__clearItemFromJeopardizedShipment(this.shipment.reason, this.shipment.ormId);
                    },
                    (err) => {
                        this.snackbar.open('Adding destination alias failed', 'Ok', {
                            duration: 8000,
                        });
                    },
                );
            }
            x = result;
        });
    }

    addToDestinationCriteria(): void {
        if (this.trip) {
            const destinationInfo: BdsDestinationCriteriaDialogModel = {
                customerNo: this.trip.customerNo,
                isClone: false,
                isNew: true,
            };

            const dialogRef = this.dialog.open(BdsDestinationCriteriaDialogComponent, {
                width: '550px',
                data: destinationInfo,
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result?.event == 'Save') {
                    this.snackbar.open('Destination criteria added', 'Ok', {
                        duration: 3000,
                    });
                }
            });
        }
    }

    async addToOriginCriteria(): Promise<void> {
        if (this.trip && this.currentClm) {
            const clm = await firstValueFrom(this.clmService.getClm(this.currentClm?.ormId));

            if (clm) {
                const originInfo: BdsOriginCriteriaDialogModel = {
                    // The originCode comes from city/state instead of being defined here
                    returnCity: this.currentClm?.locationCity,
                    returnState: this.currentClm?.locationState,
                    locationCity: clm?.locationCity,
                    locationState: clm?.locationState,
                    railroad: clm?.road,
                    sightCode: clm?.sightCode,
                    isClone: false,
                    isNew: true,
                };

                const dialogRef = this.dialog.open(BdsOriginCriteriaDialogComponent, {
                    width: '550px',
                    data: originInfo,
                });

                dialogRef.afterClosed().subscribe((result) => {
                    if (result?.event == 'Save') {
                        this.snackbar.open('Origin criteria added', 'Ok', {
                            duration: 3000,
                        });
                    }
                });
            }
        } else {
            const originInfo: BdsOriginCriteriaDialogModel = {
                // The originCode comes from city/state instead of being defined here
                isClone: false,
                isNew: true,
            };

            const dialogRef = this.dialog.open(BdsOriginCriteriaDialogComponent, {
                width: '550px',
                data: originInfo,
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result?.event == 'Save') {
                    this.snackbar.open('Origin criteria added', 'Ok', {
                        duration: 3000,
                    });
                }
            });
        }
    }

    createComment(): void {
        let x: RtTripComment = new RtTripComment(
            this.trip.carInit,
            this.trip.carNo,
            this.trip.shipDatetime,
        );

        const dialogRef = this.dialog.open(RtTripCommentDialogComponent, {
            width: '550px',
            data: x,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (!!result) {
                this.tripCommentService.create(result).subscribe((r) => {});
                this.shipment.lastComments = result.comments;

                // this.change.emit();
            }
            x = result;
        });
    }

    onPopoutTripclicked($event: number): void {}

    onTripSaveClicked(tripDetails: RtTrip): void {
        this.tripService.update(tripDetails.ormId, tripDetails).subscribe((upTrip: RtTrip) => {
            this.snackbar.open('Trip Saved!', 'Ok', {
                duration: 3000,
            });
        });
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private aao = [
        new RtArrAtOrig('CLYOH', 'CLYDE', 'D', 'NS', 'OH', 0),
        new RtArrAtOrig('CLYOH', 'CLYDE', 'Y', 'NS', 'OH', 0),
        new RtArrAtOrig('CLYOH', 'CLYDE', 'Z', 'NS', 'OH', 0),
        new RtArrAtOrig('CLYOH', 'CLIDE', 'Z', 'NS', 'OH', 0),
    ];

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private eo = [new RtErpcOrigin('TERRAHAUT', 'IN', '373440', 'TERIN')];

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private aad = [new RtArrAtDest()];
}
