import { IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FormGroupDescription } from './adapter_t';

export class RtJeopardizedShipment {
    constructor(
        public reason?: string,
        public ormId?: number,
        public carInitials?: string,
        public carNumber?: string,
        public commodityCode?: string,
        public commodityShortName?: string,
        public carStatus?: string,
        public fleetId?: string,
        public businessGroup?: string,
        public shipDatetime?: Date,
        public prevShipDatetime?: Date,
        public ship_date_ind?: string,
        public isShopMove?: boolean,
        public isFreeRunner?: boolean,
        public customerNumber?: string,
        public customerName?: string,
        public destinationCity?: string,
        public destinationState?: string,
        public clmDestinationCity?: string,
        public clmDestinationState?: string,
        public isDivert?: boolean,
        public idleDays?: number,
        public locationCity?: string,
        public locationState?: string,
        public sightCode?: string,
        public clmDatetime?: Date,
        public loadedOrEmpty?: string,
        public road?: string,
        public trainJunction?: string,
        public csrNumber?: string,
        public salesRep?: string,
        public carUserId?: string,
        public ecommentType?: string,
        public ecommentCode?: string,
        public ecommentDate?: Date,
        public tcommentType?: string,
        public tcommentCode?: string,
        public tcommentDate?: Date,
        public lastComments?: string,
        public isFlaggedTrouble?: boolean,
        public routeCode?: string,
        public tripStatus?: string,
        public carAssignmentFleetId?: string,
        public carAssignmentBusinessGroup?: string,
        public originalEtaToDestination?: Date,
        public etaToDestination?: Date,
        public destinationVariance?: number,
        public originalEtaToOrigin?: Date,
        public etaToOrigin?: Date,
        public originVariance?: number,
        public root?: string,
    ) {}
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtJeopardizedShipment>('reason'), server: 'reason' },
    { client: nameof<RtJeopardizedShipment>('ormId'), server: 'ormId' },
    { client: nameof<RtJeopardizedShipment>('carInitials'), server: 'carInitials' },
    { client: nameof<RtJeopardizedShipment>('carNumber'), server: 'carNumber' },
    { client: nameof<RtJeopardizedShipment>('commodityCode'), server: 'commodityCode' },
    { client: nameof<RtJeopardizedShipment>('commodityShortName'), server: 'commodityShortName' },
    { client: nameof<RtJeopardizedShipment>('carStatus'), server: 'carStatus' },
    { client: nameof<RtJeopardizedShipment>('fleetId'), server: 'fleetId' },
    { client: nameof<RtJeopardizedShipment>('businessGroup'), server: 'businessGroup' },
    { client: nameof<RtJeopardizedShipment>('shipDatetime'), server: 'shipDatetime' },
    { client: nameof<RtJeopardizedShipment>('prevShipDatetime'), server: 'prevShipDatetime' },
    { client: nameof<RtJeopardizedShipment>('ship_date_ind'), server: 'ship_date_ind' },
    { client: nameof<RtJeopardizedShipment>('isShopMove'), server: 'isShopMove' },
    { client: nameof<RtJeopardizedShipment>('isFreeRunner'), server: 'isFreeRunner' },
    { client: nameof<RtJeopardizedShipment>('customerNumber'), server: 'customerNumber' },
    { client: nameof<RtJeopardizedShipment>('customerName'), server: 'customerName' },
    { client: nameof<RtJeopardizedShipment>('destinationCity'), server: 'destinationCity' },
    { client: nameof<RtJeopardizedShipment>('destinationState'), server: 'destinationState' },
    { client: nameof<RtJeopardizedShipment>('clmDestinationCity'), server: 'clmDestinationCity' },
    { client: nameof<RtJeopardizedShipment>('clmDestinationState'), server: 'clmDestinationState' },
    { client: nameof<RtJeopardizedShipment>('isDivert'), server: 'isDivert' },
    { client: nameof<RtJeopardizedShipment>('idleDays'), server: 'idleDays' },
    { client: nameof<RtJeopardizedShipment>('locationCity'), server: 'locationCity' },
    { client: nameof<RtJeopardizedShipment>('locationState'), server: 'locationState' },
    { client: nameof<RtJeopardizedShipment>('sightCode'), server: 'sightCode' },
    { client: nameof<RtJeopardizedShipment>('clmDatetime'), server: 'clmDatetime' },
    { client: nameof<RtJeopardizedShipment>('loadedOrEmpty'), server: 'loadedOrEmpty' },
    { client: nameof<RtJeopardizedShipment>('road'), server: 'road' },
    { client: nameof<RtJeopardizedShipment>('trainJunction'), server: 'trainJunction' },
    { client: nameof<RtJeopardizedShipment>('csrNumber'), server: 'csrNumber' },
    { client: nameof<RtJeopardizedShipment>('salesRep'), server: 'salesRep' },
    { client: nameof<RtJeopardizedShipment>('carUserId'), server: 'carUserId' },
    { client: nameof<RtJeopardizedShipment>('ecommentType'), server: 'ecommentType' },
    { client: nameof<RtJeopardizedShipment>('ecommentCode'), server: 'ecommentCode' },
    { client: nameof<RtJeopardizedShipment>('ecommentDate'), server: 'ecommentDate' },
    { client: nameof<RtJeopardizedShipment>('tcommentType'), server: 'tcommentType' },
    { client: nameof<RtJeopardizedShipment>('tcommentCode'), server: 'tcommentCode' },
    { client: nameof<RtJeopardizedShipment>('tcommentDate'), server: 'tcommentDate' },
    { client: nameof<RtJeopardizedShipment>('lastComments'), server: 'lastComments' },
    { client: nameof<RtJeopardizedShipment>('isFlaggedTrouble'), server: 'isFlaggedTrouble' },
    { client: nameof<RtJeopardizedShipment>('routeCode'), server: 'routeCode' },
    { client: nameof<RtJeopardizedShipment>('tripStatus'), server: 'tripStatus' },
    {
        client: nameof<RtJeopardizedShipment>('carAssignmentFleetId'),
        server: 'carAssignmentFleetId',
    },
    {
        client: nameof<RtJeopardizedShipment>('carAssignmentBusinessGroup'),
        server: 'carAssignmentBusinessGroup',
    },
    {
        client: nameof<RtJeopardizedShipment>('originalEtaToDestination'),
        server: 'originalEtaToDestination',
    },
    { client: nameof<RtJeopardizedShipment>('etaToDestination'), server: 'etaToDestination' },
    { client: nameof<RtJeopardizedShipment>('destinationVariance'), server: 'destinationVariance' },
    { client: nameof<RtJeopardizedShipment>('originalEtaToOrigin'), server: 'originalEtaToOrigin' },
    { client: nameof<RtJeopardizedShipment>('etaToOrigin'), server: 'etaToOrigin' },
    { client: nameof<RtJeopardizedShipment>('originVariance'), server: 'originVariance' },
    { client: nameof<RtJeopardizedShipment>('root'), server: 'root' },
];

@Injectable({
    providedIn: 'root',
})
export class RtJeopardizedShipmentAdapter extends TwoWayAdapter<RtJeopardizedShipment> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtJeopardizedShipmentMetadata implements FormGroupDescription {
    constructor() {}
    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtJeopardizedShipment>('reason'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('carInitials'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('carNumber'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('commodityCode'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('commodityShortName'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtJeopardizedShipment>('carStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('fleetId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('businessGroup'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('shipDatetime'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('prevShipDatetime'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtJeopardizedShipment>('ship_date_ind'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('isShopMove'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('isFreeRunner'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('customerNumber'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('customerName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('destinationCity'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('destinationState'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('clmDestinationCity'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('clmDestinationState'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtJeopardizedShipment>('isDivert'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('idleDays'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('locationCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('locationState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('sightCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('clmDatetime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('loadedOrEmpty'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('road'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('trainJunction'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('csrNumber'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('salesRep'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('carUserId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('ecommentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('ecommentCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('ecommentDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('tcommentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('tcommentCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('tcommentDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('lastComments'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('isFlaggedTrouble'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtJeopardizedShipment>('routeCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('tripStatus'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('carAssignmentFleetId'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('carAssignmentBusinessGroup'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('originalEtaToDestination'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('etaToDestination'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('destinationVariance'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<RtJeopardizedShipment>('originalEtaToOrigin'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtJeopardizedShipment>('etaToOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('originVariance'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtJeopardizedShipment>('root'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
