import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RtCommodityShippedApiOptionsService {
    apiUrl: string;
    odataUrl: string;
    constructor(
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
    ) {
        this.apiUrl = `${this.apibaseUrl}CommodityShipped`;
        this.odataUrl = `${this.odatabaseUrl}CommodityShipped`;
    }
}
