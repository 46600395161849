<bds-grid-details>
    <ng-container grid>
        <dx-data-grid
            bdsGridDetails
            bds-grid
            id="bdsPreferredRouteGrid"
            #bdsPreferredRouteGrid
            [dataSource]="dataSource"
            height="35vh"
            minHeight="200px"
            bdsGridMode="multiple"
            [bdsAllowAddRecord]="true"
            [bdsAllowEditRecords]="isEditBtnEnable"
            [bdsAllowDeleteRecords]="isDeleteBtnEnable"
            [bdsUseZeroPadDates]="true"
            [(selectedRowKeys)]="selectedRowKeys"
            (onSelectionChanged)="onSelectionChanged($event)"
            (bdsAddRecordClicked)="add()"
            (bdsDeleteRecordsClicked)="deletePreferredRoutes()"
            (bdsEditRecordsClicked)="edit()"
        >
            <dxi-column dataField="customerNo" caption="Consignee Number"></dxi-column>
            <dxi-column dataField="customerFact.customerName" caption="Consignee Name"></dxi-column>
            <dxi-column dataField="originSplc" caption="Origin SPLC"></dxi-column>
            <dxi-column dataField="routeCode" caption="Route Code"></dxi-column>
            <dxi-column
                dataField="rtRouteCode.routeDescription"
                caption="Route Description"
            ></dxi-column>
            <dxi-column dataField="rtRouteCode.originCity" caption="Origin City"></dxi-column>
            <dxi-column dataField="rtRouteCode.originState" caption="Origin State"></dxi-column>
            <dxi-column
                dataField="rtRouteCode.destinationCity"
                caption="Destination City"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.destinationState"
                caption="Destination State"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.railroad"
                caption="Railroad"
                [visible]="false"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.releaseCity"
                caption="Release City"
                [visible]="false"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.releaseState"
                caption="Release State"
                [visible]="false"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.returnCity"
                caption="Release City"
                [visible]="false"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.returnState"
                caption="Release State"
                [visible]="false"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.oneWayOrRoundTrip"
                caption="One Way Or Round Trip"
                [visible]="false"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.originCode"
                caption="Origin Code"
                [visible]="false"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.destinationSplc"
                caption="Destination SPLC"
                [visible]="false"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.releaseSplc"
                caption="Release SPLC"
                [visible]="false"
            ></dxi-column>
            <dxi-column
                dataField="rtRouteCode.returnSplc"
                caption="Return SPLC"
                [visible]="false"
            ></dxi-column>
        </dx-data-grid>
    </ng-container>
    <ng-container details>
        <router-outlet></router-outlet>
    </ng-container>
</bds-grid-details>

