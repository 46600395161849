export class RtFleetFile {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public entryCode?: 'CLU' | 'CLR',
        public entryDate?: Date,
        public carEntryType?: 'P' | 'D', //D=Delete, P=Permanent
        public road1?: string,
        public road2?: string,
        public road3?: string,
        public road4?: string,
        public road5?: string,
        public road6?: string,
        public road7?: string,
        public road8?: string,
        public road9?: string,
        public road10?: string,
    ) {}
}
