<h1 mat-dialog-title>{{ data?.title }}</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <formly-form
            [model]="data.model"
            [fields]="fields | async"
            [options]="options"
            [form]="form"
        ></formly-form>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" mat-dialog-close>
        {{ data.cancelBtnText }}
    </button>
    <button
        mat-button
        cdkFocusInitial
        class="text-uppercase"
        color="accent"
        (click)="actionBtnClicked()"
    >
        {{ data.actionBtnText }}
    </button>
</div>
