import { Component, Inject } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import ODataStore from 'devextreme/data/odata/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RtArrAtDest, RtStateCode, RtClmSightCode } from '@bds/railtrac-models';
import {
    BdsStateService,
    ClmSightCodeService,
    RtRailroadService,
    BdsCustomerService,
} from '@bds/data-access';
import { BdsDestinationCriteriaDialogModel } from '../../models/bds-destination-criteria-dialog.model';
import { BdsDestinationCriteriaService } from '@bds/data-access';
//import DataSource from 'devextreme/data/data_source';

@Component({
    selector: 'bds-destination-criteria-dialog',
    templateUrl: './bds-destination-criteria-dialog.component.html',
    styleUrls: ['./bds-destination-criteria-dialog.component.scss'],
})
export class BdsDestinationCriteriaDialogComponent {
    //customerStore: DataSource;
    customerStore: ODataStore;
    // TODO: BE CAREFUL HERE!! These names are not consistent between models!!!
    destinationCriteria: RtArrAtDest;
    errorMessage = '';
    isClone: boolean = false;
    isNew: boolean = true;
    isSaving: boolean = false;
    isValid: boolean = false;
    railroadStore: ODataStore;
    sightCodeLookup$: Observable<RtClmSightCode[]>;
    stateLookup$: Observable<RtStateCode[]>;
    editableCustomer: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<BdsDestinationCriteriaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BdsDestinationCriteriaDialogModel,
        private destinationService: BdsDestinationCriteriaService,
        private stateService: BdsStateService,
        private clmSightCodeService: ClmSightCodeService,
        private customerService: BdsCustomerService,
        private railroadService: RtRailroadService,
        private _snackbar: MatSnackBar,
    ) {
        this.isNew = data.isNew;
        this.isClone = data.isClone;

        this.destinationCriteria = new RtArrAtDest(
            data.customerNo,
            data.locationCity,
            data.sightCode,
            data.railroad,
            data.locationState,
            data.customerDeliveryHours || 0,
        );

        if (data.customerNo) {
            this.editableCustomer = false;
        }

        this.customerStore = customerService.getODataStore();
        this.railroadStore = railroadService.getODataStore();
        this.stateLookup$ = stateService.getLookup();
        this.sightCodeLookup$ = clmSightCodeService.getAll();
    }

    onCancelClick() {
        this.dialogRef.close();
    }

    onDestinationCriteriaChanged(event: { value: RtArrAtDest; isValid: boolean }) {
        this.destinationCriteria = event.value;
        this.isValid = event.isValid;
    }

    onSaveClick() {
        this.isSaving = true;
        this.errorMessage = '';

        // For now, just adding
        this.destinationService
            .create(this.destinationCriteria)
            .pipe(take(1))
            .subscribe(
                (dest) => {
                    this.afterSaveComplete('Destination Criteria Added');
                },
                (err) => this.afterError(err),
            );
    }

    private afterError(error: any) {
        const customerErrors = [];

        if (typeof error === 'string') {
            this.errorMessage = error;
            customerErrors.push(error);
        } else if (Array.isArray(error) && error[0].validationMessage) {
            this.errorMessage = `Destination Criteria is invalid - ${error[0].validationMessage}`;
            error.forEach((e) => customerErrors.push(e));
        } else {
            this.errorMessage = 'An unknown error occurred';
        }

        this.isSaving = false;
        this._snackbar.open(this.errorMessage, 'Error', { duration: 3000 });
    }

    private afterSaveComplete(message?: string) {
        if (!message) {
            message = 'Changes saved!';
        }

        this.isSaving = false;
        this._snackbar.open(message, 'Ok', { duration: 3000 });
        this.dialogRef.close({ event: 'Save' });
    }
}
