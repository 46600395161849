import { Component, OnInit, OnDestroy, SimpleChanges, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    RtCarHotListAdapter,
    RtCarHotListMetadata,
    RtTripCommentAdapter,
    RtTripCommentMetadata,
    RtCommentType,
    RtMiscComment,
    RtTripComment,
    RtCarHotList,
} from '@bds/railtrac-models';
import { Subscription, Observable, of } from 'rxjs';
import { nameof } from '@bds/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RtTripCommentDialogComponent } from '../../rt-trip-comment/rt-trip-comment-dialog/rt-trip-comment-dialog.component';
import { RtTripCommentService } from '@bds/data-access';

@Component({
    templateUrl: './rt-car-hotlist-dialog.component.html',
    styleUrls: ['./rt-car-hotlist-dialog.component.scss'],
})
export class RtCarHotlistDialogComponent implements OnInit {
    form: UntypedFormGroup;

    commentTypes: Observable<RtCommentType[]>;
    defaultComments: Observable<RtMiscComment[]>;

    constructor(
        public adapter: RtCarHotListAdapter,
        public metadata: RtCarHotListMetadata,
        public commentAdapter: RtTripCommentAdapter,
        public commentMetadata: RtTripCommentMetadata,
        public commentService: RtTripCommentService,
        public dialogRef: MatDialogRef<RtTripCommentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RtCarHotList,
    ) {
        this.form = metadata.getFormGroup();
        this.form.patchValue(data);
    }

    ngOnInit() {
        this.commentTypes = this.commentService.getCommentTypes();
    }

    getDefaultComments(key: string) {
        console.log('running to get default comments with $event:', key);
        this.form.controls[nameof<RtTripComment>('comments')].setValue('');
        if (!!key) {
            this.defaultComments = this.commentService.getDefaultCommentsForType(key);
        } else {
            this.defaultComments = of([]);
        }
    }
    setDefaultComment(comment: string) {
        this.form.controls[nameof<RtTripComment>('comments')].setValue(comment);
    }

    onCancelClick() {
        this.dialogRef.close();
    }
    onSaveClick() {
        this.dialogRef.close(this.form.value);
    }
}
