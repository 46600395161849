import { Component, OnInit, Input } from '@angular/core';
import { RtJeopardizedShipment } from '@bds/railtrac-models';

@Component({
    selector: 'rt-jeopardized-shipment-never-shipped',
    templateUrl: './rt-jeopardized-shipment-never-shipped.component.html',
    styleUrls: ['./rt-jeopardized-shipment-never-shipped.component.scss'],
})
export class RtJeopardizedShipmentNeverShippedComponent implements OnInit {
    @Input() shipment: RtJeopardizedShipment;
    @Input() addTripRouterLink: [];

    constructor() {}

    addNewTrip() {}

    onResolve(event) {
        // TODO: this is not set up?
    }

    removeFromFleet() {}

    ngOnInit() {}
}
