import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faSave, faSpinner } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'bds-equipment-component-menu',
    templateUrl: './equipment-component-menu.component.html',
    styleUrls: ['./equipment-component-menu.component.scss'],
})
export class EquipmentComponentMenuComponent {
    iconSave = faSave;
    iconSpinner = faSpinner;

    @Input() canSave = true;
    @Input() description: string;
    @Input() isSaving = false;
    @Input() isValid = true;
    @Input() saveText = 'Save Component';

    @Output() saveChanges: EventEmitter<void> = new EventEmitter<void>();

    onSaveChanges(): void {
        this.saveChanges.emit();
    }
}
