<div class="diverted-list">
    <div class="container-fluid">
        <bds-fastrac-design-display
            [fastracId]="fastracId"
            [gridId]="gridId"
            [showFastrac]="showFastrac"
            (applyFastrac)="onApplyFastrac($event)"
            (fastracIdChange)="onFastracIdChanged($event)"
            (fastracDesignsChange)="onFastracDesignsChanged($event)"
            (showFastracChange)="onFastracVisibleChanged($event)"
            [bdsGridDirective]="targetDirective"
        >
        </bds-fastrac-design-display>
    </div>

    <div class="diverted-grid-container">
        <bds-grid-details>
            <ng-container grid>
                <dx-data-grid
                    bds-grid
                    bdsGridDetails
                    #rtDivertedTripGrid
                    id="rtDivertedTripGrid"
                    #targetDirective="bdsGridDirective"
                    [bdsAllowAddRecord]="false"
                    [bdsAllowEditRecords]="false"
                    [bdsFastracDesignList]="fastracList"
                    [bdsFastracVisible]="showFastrac"
                    [bdsUseFastrac]="true"
                    [bdsUseZeroPadDates]="true"
                    [bdsCustomizeColumns]="customizeColumns"
                    [dataSource]="gridSource"
                    [fastracId]="fastracId"
                    [fastracDesign]="currentFastrac"
                    [gridId]="gridId"
                    [needsRefresh]="needsRefresh"
                    [selectedRowKeys]="selectedRowKeys"
                    (bdsShowFastrac)="onShowFastrac()"
                    (fastracIdChange)="onGridFastracIdChanged($event)"
                    (selectedRowKeysChange)="onSelectedRowKeysChanged($event)"
                    [(bdsGridMode)]="gridMode"
                    [bdsAllowCloneRecords]="true"
                    (bdsCloneRecordsClicked)="copyCarsToClipboard()"
                    bdsCloneRecordsHint="Copy cars"
                >
                </dx-data-grid>
            </ng-container>
            <ng-container details>
                <router-outlet></router-outlet>
            </ng-container>
        </bds-grid-details>
    </div>
</div>
