<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content [formGroup]="participatingRailroadForm">
    <div class="row">
        <ng-container *ngIf="!data?.isReadOnly">
            <div class="col-sm-6" *ngIf="!data.routeCodeDataSource">
                <mat-form-field class="w-100">
                    <mat-label>Route Code</mat-label>
                    <input
                        matInput
                        bdsShoutcase
                        formControlName="routeCode"
                        *ngIf="!isInitiallyBlank"
                    />
                    <input
                        matInput
                        bdsShoutcase
                        formControlName="routeCode"
                        *ngIf="isInitiallyBlank"
                        cdkFocusInitial
                    />
                    <mat-error *ngIf="participatingRailroadForm.controls['routeCode'].invalid">{{
                        getErrorMessage(participatingRailroadForm.controls['routeCode'])
                    }}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6" *ngIf="data.routeCodeDataSource">
                <dx-select-box
                    label="Route Code"
                    [dataSource]="data.routeCodeDataSource"
                    formControlName="routeCode"
                    displayExpr="routeCode"
                    stylingMode="filled"
                    [searchMode]="'contains'"
                    [searchExpr]="['routeCode', 'routeDescription']"
                    [searchTimeout]="200"
                    [minSearchLength]="0"
                    [searchEnabled]="true"
                    itemTemplate="item"
                    fieldTemplate="field"
                >
                    <div *dxTemplate="let data of 'field'">
                        <div class="dx-placeholder">Route Code</div>
                        {{ data?.routeCode }}
                    </div>
                    <div *dxTemplate="let data of 'item'">
                        {{ data.routeCode }} - {{ data.routeDescription }}
                    </div>
                </dx-select-box>
            </div>
            <div class="col-sm-6" *ngIf="!data.railroadDataSource">
                <mat-form-field class="w-100">
                    <mat-label>Railroad</mat-label>
                    <input matInput bdsShoutcase formControlName="railroad" />
                    <mat-error *ngIf="participatingRailroadForm.controls['railroad'].invalid">{{
                        getErrorMessage(participatingRailroadForm.controls['railroad'])
                    }}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6" *ngIf="data.railroadDataSource">
                <dx-select-box
                    label="Railroad"
                    [dataSource]="data.railroadDataSource"
                    formControlName="railroad"
                    displayExpr="scac"
                    stylingMode="filled"
                    [searchMode]="'contains'"
                    [searchExpr]="['rrScac', 'rrName']"
                    [searchTimeout]="200"
                    [minSearchLength]="0"
                    [searchEnabled]="true"
                    itemTemplate="item"
                    fieldTemplate="field"
                >
                    <div *dxTemplate="let data of 'field'">
                        <div class="dx-placeholder">Railroad</div>
                        {{ data.rrScac }}
                    </div>
                    <div *dxTemplate="let data of 'item'">
                        {{ data.rrScac }} - {{ data.rrName }}
                    </div>
                </dx-select-box>
            </div>
        </ng-container>

        <ng-container *ngIf="data?.isReadOnly">
            <div class="col-sm-6" *ngIf="participatingRailroad">
                <mat-form-field class="w-100">
                    <mat-label>Route Code</mat-label>
                    <input
                        matInput
                        tabindex="-1"
                        [(ngModel)]="participatingRailroadForm.controls.routeCode.value"
                        [ngModelOptions]="{ standalone: true }"
                        [disabled]="true"
                    />
                </mat-form-field>
            </div>

            <div class="col-sm-6" *ngIf="participatingRailroad">
                <mat-form-field class="w-100">
                    <mat-label>Railroad</mat-label>
                    <input
                        matInput
                        tabindex="-1"
                        [(ngModel)]="participatingRailroadForm.controls.railroad.value"
                        [ngModelOptions]="{ standalone: true }"
                        [disabled]="true"
                    />
                </mat-form-field>
            </div>
        </ng-container>
    </div>
    <div>
        <!-- <span *ngIf="(affectedTrips | async) > 0">This will impact {{ affectedTrips | async }} shipments.</span> -->
        <mat-divider></mat-divider>
        <span *ngIf="data?.affectedTripCount"
            >This will impact {{ data?.affectedTripCount ?? 0 }} shipment{{
                data?.affectedTripCount > 1 ? 's' : ''
            }}.</span
        >
        <span>&nbsp;</span>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" (click)="onNoClick()" color="accent">Cancel</button>
    <button
        *ngIf="!isInitiallyBlank"
        mat-button
        class="text-uppercase"
        color="accent"
        [mat-dialog-close]="participatingRailroadForm.value"
        cdkFocusInitial
    >
        Save
    </button>
    <button
        *ngIf="isInitiallyBlank"
        mat-button
        class="text-uppercase"
        color="accent"
        [mat-dialog-close]="participatingRailroadForm.value"
    >
        Save
    </button>
</mat-dialog-actions>
