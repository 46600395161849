import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RtCustomerAliasAdapter, RtCustomerAlias } from '@bds/railtrac-models';
import { throwError } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { map, retry, catchError } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';

export abstract class RtCustomerAliasServiceOptions {
    apiUrl = 'api/customeralias';
    odataUrl = `odata/customeralias`;
}

@Injectable({
    providedIn: 'root',
})
export class RtCustomerAliasService {
    constructor(
        public httpClient: HttpClient,
        public options: RtCustomerAliasServiceOptions,
        public adapter: RtCustomerAliasAdapter,
        public errorService: ApiErrorHandlerService,
    ) {}

    getODataStore(): DataSource {
        return new DataSource({
            store: new ODataStore({
                version: 4,
                url: this.options.odataUrl,
                key: 'ormId',
            }),
            paginate: true,
            pageSize: 20,
        });
    }

    get() {
        return this.httpClient.get<RtCustomerAlias[]>(this.options.apiUrl).pipe(
            map((m) => [...m.map((f) => this.adapter.adapt(f))]),
            retry(1),
            catchError(this.errorService.handleError),
        );
    }

    getById(id: number) {
        return this.httpClient.get<RtCustomerAlias>(`${this.options.apiUrl}/${id}`).pipe(
            map((m) => this.adapter.adapt(m)),
            retry(1),
            catchError(this.errorService.handleError),
        );
    }

    add(CustomerAlias: RtCustomerAlias) {
        return this.httpClient.post<RtCustomerAlias>(this.options.apiUrl, CustomerAlias).pipe(
            map((m) => this.adapter.adapt(m)),
            retry(1),
            catchError(this.errorService.handleError),
        );
    }

    update(CustomerAlias: RtCustomerAlias) {
        return this.httpClient
            .put<RtCustomerAlias>(`${this.options.apiUrl}/${CustomerAlias.ormId}`, CustomerAlias)
            .pipe(retry(1), catchError(this.errorService.handleError));
    }

    delete(id: number) {
        return this.httpClient.delete<RtCustomerAlias>(`${this.options.apiUrl}/${id}`).pipe(
            map((m) => this.adapter.adapt(m)),
            retry(1),
            catchError(this.errorService.handleError),
        );
    }
}
