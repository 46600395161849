<div>
    <dx-data-grid
        id="historicDates"
        [dataSource]="gridSource"
        [selectedRowKeys]="selectedRowKeys"
        [height]="gridHeight"
        (onSelectionChanged)="onSelect($event)"
    >
        <dxo-selection mode="single"></dxo-selection>
        <dxi-column dataField="actualDate" caption="Date Performed" dataType="date"></dxi-column>
        <dxi-column
            *ngIf="hasDueDate"
            dataField="nextDueDate"
            caption="Next Due Date"
            dataType="date"
        ></dxi-column>
    </dx-data-grid>
</div>
