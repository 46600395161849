import { IModelMetadata, nameof, TwoWayAdapter, From } from '@bds/core';
import { Injectable } from '@angular/core';

export class RtCityAlias implements From<RtCityAlias> {
    constructor(
        public aliasCity?: string,
        public aliasState?: string,
        public locCity?: string,
        public locState?: string,
        public ormId?: number,
    ) {}
    from(trip: RtCityAlias): RtCityAlias {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
    static from(obj: RtCityAlias): RtCityAlias {
        const result = new RtCityAlias();
        Object.keys(obj).forEach((k) => (result[k] = obj[k]));
        return result;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtCityAlias>('aliasCity'), server: 'aliasCity' },
    { client: nameof<RtCityAlias>('aliasState'), server: 'aliasState' },
    { client: nameof<RtCityAlias>('locCity'), server: 'locCity' },
    { client: nameof<RtCityAlias>('locState'), server: 'locState' },
    { client: nameof<RtCityAlias>('ormId'), server: 'ormId' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCityAliasAdapter extends TwoWayAdapter<RtCityAlias> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}
