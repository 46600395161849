import { IModelMetadata, nameof, TwoWayAdapter, From } from '@bds/core';
import { Injectable } from '@angular/core';

export class RtCustomerAlias implements From<RtCustomerAlias> {
    static from(obj: RtCustomerAlias): RtCustomerAlias {
        const result = new RtCustomerAlias();
        Object.keys(obj).forEach((k) => (result[k] = obj[k]));
        return result;
    }

    constructor(
        public custName?: string,
        public custCity?: string,
        public custState?: string,
        public custNo?: string,
        public ormId?: number,
    ) {}

    from(trip: RtCustomerAlias): RtCustomerAlias {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtCustomerAlias>('ormId'), server: 'ormId' },
    { client: nameof<RtCustomerAlias>('custNo'), server: 'custNo' },
    { client: nameof<RtCustomerAlias>('custName'), server: 'custName' },
    { client: nameof<RtCustomerAlias>('custCity'), server: 'custCity' },
    { client: nameof<RtCustomerAlias>('custState'), server: 'custState' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCustomerAliasAdapter extends TwoWayAdapter<RtCustomerAlias> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}
