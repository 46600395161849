import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtOutageIndex implements From<RtOutageIndex> {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public compartNo?: string,
        public outageInnage?: string,
        public tableNo?: string,
        public issuerCode?: string,
    ) {}
    from(trip: RtOutageIndex): RtOutageIndex {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtOutageIndex>('carInit'), server: 'carInit' },
    { client: nameof<RtOutageIndex>('carNo'), server: 'carNo' },
    { client: nameof<RtOutageIndex>('compartNo'), server: 'compartNo' },
    { client: nameof<RtOutageIndex>('outageInnage'), server: 'outageInnage' },
    { client: nameof<RtOutageIndex>('tableNo'), server: 'tableNo' },
    { client: nameof<RtOutageIndex>('issuerCode'), server: 'issuerCode' },
];

@Injectable({
    providedIn: 'root',
})
export class RtOutageIndexAdapter extends TwoWayAdapter<RtOutageIndex> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtOutageIndexMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtOutageIndex>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtOutageIndex>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtOutageIndex>('compartNo'),
                dataType: 'string',
                caption: 'compartNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtOutageIndex>('outageInnage'),
                dataType: 'string',
                caption: 'outageInnage',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtOutageIndex>('tableNo'),
                dataType: 'string',
                caption: 'tableNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtOutageIndex>('issuerCode'),
                dataType: 'string',
                caption: 'issuerCode',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtOutageIndex>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtOutageIndex>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtOutageIndex>('compartNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtOutageIndex>('outageInnage'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtOutageIndex>('tableNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtOutageIndex>('issuerCode'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
