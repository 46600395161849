import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { RtvTripDiversion } from '@bds/railtrac-models';
import { ApiErrorHandlerService } from '@bds/helpers';

@Injectable({
    providedIn: 'root',
})
export class RtDivertedTripsService {
    controllerName: string = 'tripDiverted';

    constructor(
        private http: HttpClient,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    getDataSource(): DataSource {
        // Need to use Trip ormId because details shows Trip
        const keyField = 'rtTripOrmId';

        return new DataSource({
            store: new ODataStore({
                version: 4,
                url: `${this.odatabaseUrl}${this.controllerName}`,
                key: keyField,
            }),
            paginate: true,
            pageSize: 20,
        });
    }

    getDiversionsByTrip(tripId: number): Observable<RtvTripDiversion[]> {
        if (tripId) {
            const selectedCols: string = this.diversionFieldsToRetrieve().toString();
            const filterUrl = `?$filter=rtTripOrmId eq ${tripId}&$orderby=divertDateTime desc&$apply=groupby((${selectedCols}))`;

            return this.http
                .get<string>(`${this.odatabaseUrl}${this.controllerName}${filterUrl}`)
                .pipe(
                    map((data: any) => data.value),
                    shareReplay(),
                    catchError((err) => this.apiErrorHandler.handleError(err)),
                );
        } else {
            return of(null);
        }
    }

    private diversionFieldsToRetrieve(): string[] {
        const diversionObj: RtvTripDiversion = new RtvTripDiversion();
        return Object.keys(diversionObj).filter((k) => !k.startsWith('comm'));
    }
}
