<div [formGroup]="equipmentForm">
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Init</mat-label>
                        <input
                            matInput
                            bdsShoutcase
                            [readonly]="!isNew"
                            formControlName="equipmentInit"
                        />
                        <mat-error *ngIf="equipmentForm.controls.equipmentInit.errors">
                            {{ getError(equipmentForm.controls.equipmentInit) }}
                        </mat-error>
                        <mat-hint *ngIf="isNew">Required</mat-hint>
                        <mat-hint *ngIf="!isNew">Readonly</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-5">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Num</mat-label>
                        <input
                            matInput
                            bdsShoutcase
                            [readonly]="!isNew"
                            formControlName="equipmentNo"
                        />
                        <mat-error *ngIf="equipmentForm.controls.equipmentNo.errors">
                            {{ getError(equipmentForm.controls.equipmentNo) }}
                        </mat-error>
                        <mat-hint *ngIf="isNew">Required</mat-hint>
                        <mat-hint *ngIf="!isNew">Readonly</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Num Compartments</mat-label>
                        <input matInput type="number" formControlName="noOfCompart" />
                        <mat-error *ngIf="equipmentForm.controls.noOfCompart.errors">
                            {{ getError(equipmentForm.controls.noOfCompart) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Barcode</mat-label>
                        <input matInput bdsShoutcase formControlName="equipmentBarcode" />
                        <mat-error *ngIf="equipmentForm.controls.equipmentBarcode.errors">
                            {{ getError(equipmentForm.controls.equipmentBarcode) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Equipment Type</mat-label>
                        <input matInput bdsShoutcase formControlName="equipmentType" />
                        <mat-error *ngIf="equipmentForm.controls.equipmentType.errors">
                            {{ getError(equipmentForm.controls.equipmentType) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <dx-drop-down-box
                        [value]="equipmentComponentCategories"
                        class="w-100 bds-dx-custom-material bds-mat-dense-form-field dx-mat-form-field-floatLabel-always"
                        label="Equipment Component Categories"
                        [stylingMode]="'filled'"
                        [dataSource]="componentCategories"
                        valueExpr="componentCategory"
                        displayExpr="componentCategory"
                        (onValueChanged)="onCategoryChanged($event)"
                    >
                        <div *dxTemplate="let data of 'content'">
                            <dx-data-grid
                                [dataSource]="componentCategories"
                                keyExpr="componentCategory"
                                [selection]="{ mode: 'multiple', showCheckBoxesMode: 'always' }"
                                [(selectedRowKeys)]="equipmentComponentCategories"
                            >
                                <dxi-column
                                    caption="Category"
                                    dataField="componentCategory"
                                ></dxi-column>
                                <dxi-column
                                    caption="Description"
                                    dataField="categoryDscr"
                                ></dxi-column>
                            </dx-data-grid>
                        </div>
                    </dx-drop-down-box>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-8">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Tare Weight</mat-label>
                        <input
                            matInput
                            type="number"
                            formControlName="tareWeight"
                            (input)="setDependentValidators('tareWeight', 'tareWeightUm')"
                        />
                        <mat-error *ngIf="equipmentForm.controls.tareWeight.errors">
                            {{ getError(equipmentForm.controls.tareWeight) }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Tare Weight Um</mat-label>
                        <mat-select
                            bdsShoutcase
                            formControlName="tareWeightUm"
                            (selectionChange)="setDependentValidators('tareWeight', 'tareWeightUm')"
                        >
                            <mat-option></mat-option>
                            <mat-option
                                *ngFor="let option of weightUnits"
                                [value]="option.unitCode"
                            >
                                {{ option.unitCode }} - {{ option.unitCodeDescription.trim() }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="equipmentForm.controls.tareWeightUm.errors">
                            {{ getError(equipmentForm.controls.tareWeightUm) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Capacity</mat-label>
                        <input
                            matInput
                            type="number"
                            formControlName="capacity"
                            (input)="setDependentValidators('capacity', 'capacityUm')"
                        />
                        <mat-error *ngIf="equipmentForm.controls.capacity.errors">
                            {{ getError(equipmentForm.controls.capacity) }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Capacity Um</mat-label>
                        <mat-select
                            bdsShoutcase
                            formControlName="capacityUm"
                            (selectionChange)="setDependentValidators('capacity', 'capacityUm')"
                        >
                            <mat-option></mat-option>
                            <mat-option
                                *ngFor="let option of weightVolUnits"
                                [value]="option.unitCode"
                            >
                                {{ option.unitCode }} - {{ option.unitCodeDescription.trim() }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="equipmentForm.controls.capacityUm.errors">
                            {{ getError(equipmentForm.controls.capacityUm) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Allow Weight</mat-label>
                        <input
                            matInput
                            type="number"
                            formControlName="allowableWeight"
                            (input)="setDependentValidators('allowableWeight', 'allowableWeightUm')"
                        />
                        <mat-error *ngIf="equipmentForm.controls.allowableWeight.errors">
                            {{ getError(equipmentForm.controls.allowableWeight) }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Allow Weight Um</mat-label>
                        <mat-select
                            bdsShoutcase
                            formControlName="allowableWeightUm"
                            (selectionChange)="
                                setDependentValidators('allowableWeight', 'allowableWeightUm')
                            "
                        >
                            <mat-option></mat-option>
                            <mat-option
                                *ngFor="let option of weightUnits"
                                [value]="option.unitCode"
                            >
                                {{ option.unitCode }} - {{ option.unitCodeDescription.trim() }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="equipmentForm.controls.allowableWeightUm.errors">
                            {{ getError(equipmentForm.controls.allowableWeightUm) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
