import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import ODataStore from 'devextreme/data/odata/store';
import { DynamicDropDownValues } from '@bds/models';
import {
    Equipment,
    EquipmentComponentCategory,
    EquipmentComponentModel,
    EquipComponentRemovalCode,
    EquipmentMechField,
    EquipComponentCatgBridge,
    EquipComponentModel,
    EquipComponentDate,
    EquipComponentMechEav,
} from '../../models';
import {
    EquipmentComponentCategoryService,
    EquipmentComponentService,
    EquipComponentRemovalCodeService,
    EquipmentMechFieldsService,
    EquipmentService,
} from '../../data-access';
import { ConfirmDeleteDialogComponent, ConfirmDeleteDialogModel } from '../../internal-dialogs';

@Component({
    selector: 'bds-equipment-components',
    templateUrl: './equipment-components.component.html',
    styleUrls: [
        './equipment-components.component.scss',
        '../../styles/equipment-common-styles.scss',
    ],
})
export class EquipmentComponentsComponent implements OnInit, OnChanges {
    availableComponents$: Observable<EquipComponentModel[]>;
    componentCategories$: Observable<EquipmentComponentCategory[]>;
    componentsStore: ODataStore;
    componentDatesValid = true;
    componentFieldsValid = true;
    componentValid = false;
    currentComponent: EquipmentComponentModel;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentComponentDates: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentComponentValues: any;
    errorMessageSubject: Subject<string[]> = new Subject<string[]>();
    errorMessage$ = this.errorMessageSubject.asObservable();
    isNew = false;
    isDirty = false;
    isSaving = false;
    refreshGrid$ = new BehaviorSubject<boolean>(false);
    removalCodes$: Observable<EquipComponentRemovalCode[]>;
    selectedCompCategories: EquipComponentCatgBridge[] = [];
    selectedCompFields$: Observable<EquipmentMechField[]>;
    selectedComponent: EquipmentComponentModel;
    selectedItemId$ = new BehaviorSubject<number>(0);

    get componentDescription(): string {
        if (
            this.selectedComponent &&
            this.selectedComponent.component &&
            this.selectedComponent.component.componentDscr
        ) {
            return this.selectedComponent.component.componentDscr;
        }
        return 'Component';
    }

    @Input() selectedEquipment: Equipment;
    @Input() dynamicDropdownValues$: Observable<DynamicDropDownValues[]>;

    constructor(
        public componentService: EquipmentComponentService,
        private componentCategoryService: EquipmentComponentCategoryService,
        private compFieldService: EquipmentMechFieldsService,
        private equipmentService: EquipmentService,
        private removalCodeService: EquipComponentRemovalCodeService,
        private _snackbar: MatSnackBar,
        public dialog: MatDialog,
    ) {
        this.componentsStore = this.componentService.getODataStore();
        this.getAllRemovalCodes();
    }

    ngOnInit() {
        if (this.selectedEquipment) {
            this.getSelectedComponentCategories(this.selectedEquipment.ormId);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedEquipment && !changes.selectedEquipment.firstChange) {
            this.selectedComponent = undefined;
            this.selectedCompFields$ = this.compFieldService.getFilteredForComponent('');
            if (this.selectedEquipment) {
                this.getSelectedComponentCategories(this.selectedEquipment.ormId);
            }
        }
    }

    clearComponents(): void {
        this.currentComponentDates = {};
        this.currentComponentValues = {};
        this.componentDatesValid = true;
        this.componentFieldsValid = true;
        this.componentValid = false;
        // TODO: Do you need to set up a new empty component?
    }

    createComponent(): void {
        this.isSaving = true;

        this.componentService
            .create(this.currentComponent)
            .pipe(
                tap((data: EquipmentComponentModel) => {
                    if (data && data.ormId) {
                        this.getSelectedComponent(data.ormId);
                        this.selectedItemId$.next(data.ormId);
                    }
                    this.isSaving = false;
                    this.isDirty = false;
                    this.refreshGrid$.next(true);
                    this.openSnackBar('Equipment Component Created', '', true);
                }),
                catchError((err: string[] | string) => {
                    console.error('ERROR', err);
                    this.isSaving = false;
                    const snackMsg = 'Error Creating Equipment Component';
                    this.openSnackBar(snackMsg, '', false);
                    if (typeof err === 'string') {
                        if ((err || '').toString().toLowerCase().includes('inner exception')) {
                            this.errorMessageSubject.next([snackMsg]);
                        } else {
                            this.errorMessageSubject.next([err]);
                        }
                    } else {
                        this.errorMessageSubject.next(err);
                    }
                    return EMPTY;
                }),
            )
            .subscribe();
    }

    createComponentDates(): EquipComponentDate[] {
        // TODO: Need to alter for create vs new on edited date
        const newEquipComponentDates: EquipComponentDate[] = [];

        Object.entries(this.currentComponentDates || []).forEach((c) => {
            const values = c[1];

            // Only include the ones with values
            if (this.hasDate(values)) {
                const index = this.selectedComponent.tcmEquipComponentDates.findIndex(
                    (e) => e.mechField === c[0],
                );

                const newComponentDate: EquipComponentDate = {
                    mechField: c[0],
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    actualDate: values['actual'] || null,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    nextDueDate: values['next'] || null,
                    equipmentOrmId: this.selectedEquipment.ormId,
                    equipmentInit: this.selectedEquipment.equipmentInit,
                    equipmentNo: this.selectedEquipment.equipmentNo,
                    equipmentComponentOrmId:
                        this.selectedComponent.ormId || this.currentComponent.ormId,
                    componentId:
                        this.selectedComponent.componentId || this.currentComponent.componentId,
                };

                if (index > -1) {
                    newComponentDate.ormId =
                        this.selectedComponent.tcmEquipComponentDates[index].ormId;
                    newEquipComponentDates.push(newComponentDate);
                } else {
                    newComponentDate.ormId = 0;
                    newEquipComponentDates.push(newComponentDate);
                }
            }
        });

        return newEquipComponentDates;
    }

    createComponentEavValues(): EquipComponentMechEav[] {
        const newEquipComponentEavValues: EquipComponentMechEav[] = [];

        Object.entries(this.currentComponentValues || []).forEach((c) => {
            const value = c[1].toString();
            // Only include the ones with values
            if (value) {
                const index = this.selectedComponent.tcmEquipComponentMechEav.findIndex(
                    (e) => e.mechField === c[0],
                );

                const newComponentEav: EquipComponentMechEav = {
                    mechField: c[0],
                    mechValue: value,
                    equipmentOrmId: this.selectedEquipment.ormId,
                    equipmentInit: this.selectedEquipment.equipmentInit,
                    equipmentNo: this.selectedEquipment.equipmentNo,
                    equipmentComponentOrmId:
                        this.selectedComponent.ormId || this.currentComponent.ormId,
                    componentId:
                        this.selectedComponent.componentId || this.currentComponent.componentId,
                };

                if (index > -1) {
                    newComponentEav.ormId =
                        this.selectedComponent.tcmEquipComponentMechEav[index].ormId;
                    newEquipComponentEavValues.push(newComponentEav);
                } else {
                    newComponentEav.ormId = 0;
                    newEquipComponentEavValues.push(newComponentEav);
                }
            }
        });

        return newEquipComponentEavValues;
    }

    getAllRemovalCodes(): void {
        this.removalCodes$ = this.removalCodeService.getAll();
    }

    getAvailableComponents(selectedCompCats: EquipComponentCatgBridge[]): void {
        const selectedCats: string[] = selectedCompCats.map((c) => c.componentCategory);
        this.availableComponents$ =
            this.componentCategoryService.getComponentsForCategories(selectedCats);
    }

    getSelectedComponent(equipmentComponentId: number): void {
        this.isDirty = false;

        if (equipmentComponentId !== 0) {
            this.isNew = false;

            this.componentService
                .read(equipmentComponentId)
                .pipe(
                    tap((c: EquipmentComponentModel) => {
                        this.selectedComponent = c;
                        this.selectedCompFields$ = this.compFieldService.getFilteredForComponent(
                            c.componentId,
                        );
                    }),
                    catchError((err) => {
                        console.error(err);
                        this.errorMessageSubject.next([
                            'Unable to retrieve Equipment Component selected',
                        ]);
                        return EMPTY;
                    }),
                )
                .subscribe();
        } else {
            this.selectedComponent = <EquipmentComponentModel>{
                equipmentInit: this.selectedEquipment.equipmentInit,
                equipmentNo: this.selectedEquipment.equipmentNo,
                equipmentOrmId: this.selectedEquipment.ormId,
                ormId: 0,
                tcmEquipComponentDates: [],
                tcmEquipComponentMechEav: [],
            };

            this.selectedCompFields$ = EMPTY;
        }
    }

    getSelectedComponentCategories(equipmentId: number): void {
        if (equipmentId) {
            this.equipmentService
                .getEquipmentComponentCategories(equipmentId)
                .pipe(take(1))
                .subscribe((c) => {
                    this.selectedCompCategories = c;
                    this.getAvailableComponents(this.selectedCompCategories);
                });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    hasDate(dates: any): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (dates && (dates['actual'] || dates['next'])) {
            return true;
        }

        return false;
    }

    isValid(): boolean {
        return this.componentDatesValid && this.componentValid && this.componentFieldsValid;
    }

    onAddComponent(): void {
        this.onSelectedComponentChanged(0);
        this.isNew = true;
    }

    onComponentCategoriesChanged(event: EquipComponentCatgBridge[]): void {
        this.getAvailableComponents(event);
    }

    onComponentChanged(event: { value: EquipmentComponentModel; isValid: boolean }): void {
        if (event) {
            this.currentComponent = event.value;
            this.componentValid = event.isValid;

            if (event.value && this.selectedComponent.ormId === event.value.ormId) {
                Object.keys(event.value).forEach((key) => {
                    if (
                        !this.isDirty &&
                        event.value[key] !== this.selectedComponent[key] &&
                        (event.value[key] || this.selectedComponent[key])
                    ) {
                        this.isDirty = true;
                        return;
                    }
                });
            }
        } else {
            this.componentValid = false;
            this.isDirty = false;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onComponentDatesChanged(event: { value: any; isValid: boolean }): void {
        this.isDirty = true;
        if (event) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.currentComponentDates = event.value || {};
            this.componentDatesValid = event.isValid || false;
        } else {
            this.currentComponentDates = {};
            this.componentDatesValid = false;
        }
    }

    onComponentDatesUpdated(): void {
        if (this.selectedComponent) {
            this.componentService
                .read(this.selectedComponent.ormId)
                .pipe(take(1))
                .subscribe((c) => (this.selectedComponent = c));
        }
    }

    onComponentIdChanged(event: string): void {
        this.selectedCompFields$ = this.compFieldService.getFilteredForComponent(event);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onComponentValuesChanged(event: { value: any; isValid: boolean }): void {
        this.isDirty = true;
        if (event) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.currentComponentValues = event.value || {};
            this.componentFieldsValid = event.isValid || false;
        } else {
            this.currentComponentValues = {};
            this.componentFieldsValid = false;
        }
    }

    onSave(): void {
        if (!this.isValid()) {
            this.openSnackBar(
                'Unable to Save - Not all Equipment Component information is Valid',
                '',
                false,
            );
            return;
        }

        this.currentComponent.tcmEquipComponentMechEav = this.createComponentEavValues();
        this.currentComponent.tcmEquipComponentDates = this.createComponentDates();

        if (this.isNew) {
            this.createComponent();
        } else {
            this.updateComponent();
        }
    }

    onSelectedComponentChanged(event: number): void {
        if (this.selectedComponent?.ormId !== event) {
            if (this.isDirty) {
                const dialogData: ConfirmDeleteDialogModel = <ConfirmDeleteDialogModel>{
                    actionText: 'LEAVE',
                    content:
                        'There are unsaved changes to this component. Are you sure you want to continue and lose these changes?',
                    defaultToYes: false,
                };

                const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
                    data: dialogData,
                });

                dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        this.getSelectedComponent(event);
                    } else {
                        // Reset selected grid item
                        this.selectedItemId$.next(this.selectedComponent.ormId);
                    }
                });
            } else {
                this.getSelectedComponent(event);
            }
        }
    }

    openSnackBar(message: string, action: string, success: boolean): void {
        let style = 'error-snack';
        if (success) {
            style = 'success-snack';
        }

        this._snackbar.open(message, action, {
            duration: 3000,
            panelClass: [style],
            horizontalPosition: 'right',
        });
    }

    updateComponent(): void {
        this.isSaving = true;

        this.componentService
            .update(this.currentComponent.ormId, this.currentComponent)
            .pipe(
                tap(() => {
                    this.getSelectedComponent(this.currentComponent.ormId);
                    this.isSaving = false;
                    this.isDirty = false;
                    this.refreshGrid$.next(true);
                    this.openSnackBar('Equipment Component Saved', '', true);
                }),
                catchError((err: string[] | string) => {
                    console.error('ERROR', err);
                    this.isSaving = false;
                    const snackMsg = 'Error Saving Equipment Component';
                    this.openSnackBar(snackMsg, '', false);
                    if (typeof err === 'string') {
                        if ((err || '').toString().toLowerCase().includes('inner exception')) {
                            this.errorMessageSubject.next([snackMsg]);
                        } else {
                            this.errorMessageSubject.next([err]);
                        }
                    } else {
                        this.errorMessageSubject.next(err);
                    }
                    return EMPTY;
                }),
            )
            .subscribe();
    }
}
