<div class="container-fluid">
    <bds-fastrac-design-display
        [gridId]="gridId"
        [fastracId]="fastracId"
        [showFastrac]="showFastrac"
        (showFastracChange)="onFastracVisibleChanged($event)"
        (fastracIdChange)="onFastracIdChanged($event)"
        (fastracDesignsChange)="onFastracDesignsChanged($event)"
        (applyFastrac)="onApplyFastrac($event)"
    >
    </bds-fastrac-design-display>
</div>
<bds-grid-details>
    <ng-container grid>
        <dx-data-grid
            #dxGridWrapper
            bdsGridDetails
            bds-grid
            #bdsJeopardizedShipmentGrid
            [bdsGridMode]="gridMode ?? 'multiple'"
            (bdsMenuItemClicked)="onMenuItemClicked($event)"
            [bdsUseZeroPadDates]="true"
            id="bdsJeopardizedShipmentGrid"
            [dataSource]="gridSource"
            height="85vh"
            minHeight="200px"
            [(selectedRowKeys)]="selectedRowKeys"
            (onSelectionChanged)="selectionChanged($event)"
            (onContentReady)="gridInitialized()"
            [bdsAllowCloneRecords]="true"
            (bdsCloneRecordsClicked)="copyCarsToClipboard()"
            bdsCloneRecordsHint="Copy cars"
            [gridId]="gridId"
            [fastracId]="fastracId"
            [fastracDesign]="currentFastrac"
            [bdsFastracVisible]="showFastrac"
            [bdsFastracDesignList]="fastracList"
            (bdsShowFastrac)="onShowFastrac()"
            [bdsUseFastrac]="true"
            (fastracIdChange)="onGridFastracIdChanged($event)"
            [bdsCustomizeColumns]="customizeColumns"
        >
            <dxi-column></dxi-column>
        </dx-data-grid>
    </ng-container>
    <ng-container details>
        <mat-tab-group *ngIf="selectedRowKeys?.length > 1">
            <mat-tab label="Multi Select Detail">
                <rt-multi-trip
                    *ngIf="selectedRowKeys?.length > 1"
                    [tripIds]="tripIds"
                    [isReadOnly]="isReadonly"
                    [tripRefFieldDefs$]="tripRefFieldDefs$"
                ></rt-multi-trip>
            </mat-tab>
            <mat-tab *ngFor="let key of selectedRowKeys" [label]="tabLabelString(key)">
                <rt-jeopardized-shipment-reason
                    [shipment]="key"
                    [resolved]="resolved"
                    [addTripRouterLink]="['/trip', 0]"
                    (change)="shipmentChanged(key)"
                    (popoutTrip)="onPopoutTrip($event)"
                ></rt-jeopardized-shipment-reason>
            </mat-tab>
        </mat-tab-group>

        <ng-container *ngIf="selectedRowKeys?.length === 1">
            <div *ngFor="let key of selectedRowKeys">
                <!-- {{key|json}} -->
                <rt-jeopardized-shipment-reason
                    [shipment]="key"
                    [resolved]="resolved"
                    [addTripRouterLink]="['/trip', 0]"
                    (change)="shipmentChanged(key)"
                    (popoutTrip)="onPopoutTrip($event)"
                ></rt-jeopardized-shipment-reason>
            </div>
        </ng-container>
    </ng-container>
</bds-grid-details>
