import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { take } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import DxDataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { faPen, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { RtArrAtOrig, RtClm, RtClmSightCode, RtStateCode } from '@bds/railtrac-models';
import { AsciiConverterService } from '@bds/helpers';
import {
    BdsDialogConfirmComponent,
    BdsDialogConfirmModel,
    BdsDialogInfoComponent,
    BdsDialogInfoModel,
} from '@bds/components';
import { BdsOriginCriteriaGridDialogComponent } from '../bds-origin-criteria-grid-dialog/bds-origin-criteria-grid-dialog.component';
import { BdsOriginCriteriaGridDialogModel } from '../models/bds-origin-criteria-grid-dialog.model';
import { BdsOriginCriteriaService } from '../bds-origin-criteria.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'bds-origin-criteria-list',
    templateUrl: './bds-origin-criteria-list.component.html',
    styleUrls: ['./bds-origin-criteria-list.component.scss'],
})
export class BdsOriginCriteriaListComponent implements OnInit, OnChanges {
    originCodeDisplayExpr: string[] = ['originCode', 'erpcOrigin', 'erpcState'];
    originCodeSource: DataSource;
    gridSource: DxDataSource;
    iconEdit = faPen;
    iconDelete = faTimes;
    selectedRowKeys: Array<number> = [];

    @Input() originCode: string;
    @Input() originCodeStore: ODataStore;
    @Input() dense = true;
    @Input() originStore: DataSource;
    @Input() latestClm: RtClm;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';
    @Input() railroadStore: ODataStore;
    @Input() sightCodeLookup: RtClmSightCode[] = [];
    @Input() stateLookup: RtStateCode[] = [];

    @Output() originDelete: EventEmitter<RtArrAtOrig> = new EventEmitter();
    @Output() originSaved: EventEmitter<any> = new EventEmitter();

    @ViewChild('bdsOriginCriteriaGrid') dataGrid: DxDataGridComponent;
    get dxMatStyle(): string {
        switch (this.matStyle) {
            case 'fill':
                return 'filled';
            case 'outline':
                return 'outlined';
            case 'standard':
                return 'underlined';
            default:
                return 'underlined';
        }
    }

    constructor(
        public dialog: MatDialog,
        private originService: BdsOriginCriteriaService,
        private asciiService: AsciiConverterService,
    ) {}

    ngOnInit() {
        this.setUpGrid();

        setTimeout(() => {
            this.dataGrid?.instance.repaint();
        }, 0);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.originStore && !changes.originStore.firstChange && this.originStore) {
            this.setUpGrid();
        }
    }

    alterCriteria(selectedId: number, clone: boolean) {
        this.originService
            .read(selectedId)
            .pipe(take(1))
            .subscribe((orig) => {
                if (clone) {
                    orig.ormId = undefined;
                }

                const dialogData: BdsOriginCriteriaGridDialogModel = {
                    originCriteria: orig,
                    originCodes: this.originCodeStore,
                    railroads: this.railroadStore,
                    sightCodes: this.sightCodeLookup,
                    states: this.stateLookup,
                    isClone: clone,
                    isNew: false,
                };

                const dialogRef = this.dialog.open(BdsOriginCriteriaGridDialogComponent, {
                    width: '550px',
                    data: dialogData,
                });

                dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        this.originSaved.emit();
                    }
                });
            });
    }

    onAdd() {
        const emptyOrigin: RtArrAtOrig = new RtArrAtOrig();

        emptyOrigin.originCode = this.originCode || undefined;
        emptyOrigin.originDeliveryHours = 0;

        if (this.latestClm) {
            // Figure out the sight code letter based on the API information
            const sightCodeLetter: string = this.asciiService.convertAsciiToChar(
                this.latestClm.sightCode,
            );

            emptyOrigin.locationCity = this.latestClm.locationCity || undefined;
            emptyOrigin.locationState = this.latestClm.locationState || undefined;
            emptyOrigin.railroad = this.latestClm.road || undefined;
            emptyOrigin.sightCode = sightCodeLetter || undefined;
        }

        const dialogData: BdsOriginCriteriaGridDialogModel = {
            originCriteria: emptyOrigin,
            originCodes: this.originCodeStore,
            railroads: this.railroadStore,
            sightCodes: this.sightCodeLookup,
            states: this.stateLookup,
            isClone: false,
            isNew: true,
        };

        const dialogRef = this.dialog.open(BdsOriginCriteriaGridDialogComponent, {
            width: '550px',
            data: dialogData,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.originSaved.emit();
            }
        });
    }

    onClone() {
        if (this.selectedRowKeys && this.selectedRowKeys.length) {
            this.alterCriteria(this.selectedRowKeys[0], true);
        } else {
            this.warnNoSelection('clone');
        }
    }

    onEdit() {
        if (this.selectedRowKeys && this.selectedRowKeys.length) {
            this.alterCriteria(this.selectedRowKeys[0], false);
        } else {
            this.warnNoSelection('edit');
        }
    }

    onRemove() {
        if (this.selectedRowKeys && this.selectedRowKeys.length) {
            this.originService
                .read(this.selectedRowKeys[0])
                .pipe(take(1))
                .subscribe((orig) => {
                    let ocInfo: string = '';
                    if (orig && orig.locationCity && orig.locationState && orig.sightCode) {
                        ocInfo = ` for ${orig.locationCity}, ${orig.locationState}, with sight code ${orig.sightCode}`;
                    }

                    const confirmInfo: BdsDialogConfirmModel = {
                        content: 'Are you sure you want to delete this record' + ocInfo + '?',
                        actionText: 'Delete',
                        defaultToYes: false,
                    };

                    const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
                        width: '400px',
                        data: confirmInfo,
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                        if (result) {
                            this.originDelete.emit(orig);
                        }
                    });
                });
        } else {
            this.warnNoSelection('delete');
        }
    }

    setUpGrid() {
        this.gridSource = this.originStore;
        // this.gridSource = new DataSource({
        //     store: this.originStore,
        //     paginate: true,
        //     pageSize: 20,
        // });

        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        this.gridSource.filter(['originCode', '=', this.originCode ?? '']);
    }

    warnNoSelection(action: string) {
        const info: BdsDialogInfoModel = {
            title: 'Origin Criteria',
            content: `Please select a record to ${action}.`,
        };
        this.dialog.open(BdsDialogInfoComponent, {
            data: info,
        });
    }
}
