<h4>Origin Criteria</h4>
<div class="row origin-grid">
    <dx-data-grid
        bds-grid
        [bdsUseFastrac]="false"
        id="bdsOriginCriteriaGrid"
        [dataSource]="gridSource"
        [bdsAllowAddRecord]="true"
        [bdsAllowCloneRecords]="true"
        [bdsAllowEditRecords]="true"
        [bdsAllowDeleteRecords]="true"
        [(selectedRowKeys)]="selectedRowKeys"
        (bdsAddRecordClicked)="onAdd()"
        (bdsCloneRecordsClicked)="onClone()"
        (bdsEditRecordsClicked)="onEdit()"
        (bdsDeleteRecordsClicked)="onRemove()"
    >
        <dxi-column
            dataField="originCode"
            [minWidth]="150"
            caption="Return Origin Code"
        ></dxi-column>
        <dxi-column dataField="sightCode" [width]="105" caption="Sight Code"></dxi-column>
        <dxi-column dataField="railroad" [width]="75" caption="Road"></dxi-column>
        <dxi-column dataField="locationCity" [minWidth]="115" caption="Location City"></dxi-column>
        <dxi-column dataField="locationState" [width]="75" caption="State"></dxi-column>
        <dxi-column
            dataField="originDeliveryHours"
            [width]="110"
            caption="Delivery Hrs"
        ></dxi-column>
    </dx-data-grid>
</div>
