import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtArrAtOrig implements From<RtArrAtOrig> {
    constructor(
        public originCode?: string,
        public locationCity?: string,
        public sightCode?: string,
        public railroad?: string,
        public locationState?: string,
        public originDeliveryHours?: number,
        public ormId?: number,
    ) {}
    from(trip: RtArrAtOrig): RtArrAtOrig {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtArrAtOrig>('originCode'), server: 'originCode' },
    { client: nameof<RtArrAtOrig>('locationCity'), server: 'locationCity' },
    { client: nameof<RtArrAtOrig>('sightCode'), server: 'sightCode' },
    { client: nameof<RtArrAtOrig>('railroad'), server: 'railroad' },
    { client: nameof<RtArrAtOrig>('locationState'), server: 'locationState' },
    { client: nameof<RtArrAtOrig>('originDeliveryHours'), server: 'originDeliveryHours' },
    { client: nameof<RtArrAtOrig>('ormId'), server: 'ormId' },
];

@Injectable({
    providedIn: 'root',
})
export class RtArrAtOrigAdapter extends TwoWayAdapter<RtArrAtOrig> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}
