import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { EquipComponentDate, EquipmentMechDate } from '../../../models';

@Component({
    selector: 'bds-historic-dates-list',
    templateUrl: './historic-dates-list.component.html',
    styleUrls: ['./historic-dates-list.component.scss'],
})
export class HistoricDatesListComponent implements OnChanges {
    @Input() dates: EquipmentMechDate[] | EquipComponentDate[] = [];
    @Input() hasDueDate = false;
    @Output() editDate: EventEmitter<number> = new EventEmitter<number>();

    gridHeight = 'inherit';
    gridSource: DataSource;
    selectedRowKeys: number[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dates && this.dates) {
            this.setUpDataSource();

            const newDate: EquipmentMechDate = this.dates.find((d) => d.actualDate === null);
            if (newDate) {
                this.selectedRowKeys = [newDate.ormId];
            }

            if (this.dates && this.dates.length > 3) {
                this.gridHeight = '152px';
            } else {
                this.gridHeight = 'inherit';
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onSelect(event): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (event.selectedRowKeys && event.selectedRowKeys.length) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.editDate.emit(event.selectedRowKeys[0] || 0);
        }
    }

    setUpDataSource(): void {
        this.gridSource = new DataSource({
            store: new ArrayStore({
                key: 'ormId',
                data: this.dates,
            }),
        });
    }
}
