import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthorizeService } from '@bds/auth';
import { nameof } from '@bds/core';
import { RtSegmentMiles } from '@bds/railtrac-models';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { BdsSegmentMiles } from '../bds-segment-miles.service';
import { SegmentMilesFormService } from '../segment-miles-form.service';

@Component({
    selector: 'bds-segment-miles-form-item',
    templateUrl: './bds-segment-miles-form-item.component.html',
    styleUrls: ['./bds-segment-miles-form-item.component.css'],
})
export class BdsSegmentMilesFormItemComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    private _originalValue: RtSegmentMiles;

    constructor(private service: SegmentMilesFormService, private authService: AuthorizeService) {}

    get segmentMiles() {
        return this.form.value;
    }

    @Input() set segmentMiles(value: RtSegmentMiles) {
        this._originalValue = value;
        this.form = this.service.add(value);
        if (value.ormId > 0) {
            this.form.get(nameof<BdsSegmentMiles>('beginSplc')).disable();
            this.form.get(nameof<BdsSegmentMiles>('endSplc')).disable();
            this.form.get(nameof<BdsSegmentMiles>('railroad')).disable();
        } else {
            this.form.get(nameof<BdsSegmentMiles>('entryDate')).setValue(Date.now());
            this.form.get(nameof<BdsSegmentMiles>('isActive')).setValue('Y');
            this.setUserId();
        }
        this.form.get(nameof<BdsSegmentMiles>('entryDate')).disable();
        this.form.get(nameof<BdsSegmentMiles>('userId')).disable();
        this.form.get(nameof<BdsSegmentMiles>('isActive')).disable();
    }

    ngOnDestroy(): void {
        this.service.remove(this.form);
    }

    ngOnInit(): void {}

    setUserId() {
        this.authService
            .getUser()
            .pipe(take(1))
            .subscribe((response) => {
                this.form
                    .get(nameof<BdsSegmentMiles>('userId'))
                    .setValue(response['preferred_username']);
            });
    }
}
