import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { RtClmSightCode, RtClmSightCodeApi } from '@bds/railtrac-models';
import { ApiErrorHandlerService } from '@bds/helpers';

@Injectable({
    providedIn: 'root',
})
export class ClmSightCodeService {
    controllerName: string = 'clmsightcode';

    constructor(
        private http: HttpClient,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    getAll(): Observable<RtClmSightCode[]> {
        return this.http.get<RtClmSightCodeApi[]>(`${this.apibaseUrl}${this.controllerName}`).pipe(
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }
}
