<ng-container *ngIf="!!commodityShippedForm; else loading" [formGroup]="commodityShippedForm">
    <div class="row">
        <div class="col-1">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Compartment
                    <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input
                    type="number"
                    min="1"
                    step="1"
                    matInput
                    formControlName="commodityCompart"
                    [readonly]="true"
                />
            </mat-form-field>
        </div>
        <div class="col-4">
            <bds-commodity-code
                formControlName="commodityCode"
                [displaySearch]="true"
                (commodityChange)="onCommodityChane($event)"
            ></bds-commodity-code>
        </div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Quantity
                    <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input type="number" min="0" step="1" matInput formControlName="commodityAmt" />
            </mat-form-field>
        </div>
        <div class="col-2">
            <!-- <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Units <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput bdsShoutcase formControlName="commodityUnits" />
            </mat-form-field> -->
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Units<span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <mat-select formControlName="commodityUnits">
                    <mat-option></mat-option>
                    <mat-option
                        *ngFor="let item of unitMeasureLookup | async"
                        [value]="item.unitCode"
                    >
                        {{ item.unitCode }} - {{ item.unitCodeDescription }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-2 col-offset-2">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Ref 1 <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput bdsShoutcase formControlName="commodityRef1" />
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Ref 2 <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput bdsShoutcase formControlName="commodityRef2" />
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Ref 3 <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput bdsShoutcase formControlName="commodityRef3" />
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Ref 4 <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput bdsShoutcase formControlName="commodityRef4" />
            </mat-form-field>
        </div>
        <div class="col-1 col-offset-1">
            <button mat-stroked-button (click)="toggleCommodityFact()">
                {{ moreLessLabel ?? '' }}
            </button>
        </div>
    </div>
    <div *ngIf="showCommodityFact" class="row">
        <div class="col-12">
            <rt-bds-commodity-display
                [commodityCode]="commodityShipped.commodityCode"
                [allowEdit]="true"
                [editLink]="'/commodities'"
            >
            </rt-bds-commodity-display>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <!--Loading...-->
</ng-template>
