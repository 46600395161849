import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-trip-detention-screen',
    templateUrl: './trip-detention-screen.component.html',
    styleUrls: ['./trip-detention-screen.component.scss'],
})
export class TripDetentionScreenComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
