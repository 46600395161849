<div class="row" [formGroup]="form">
    <div class="row">
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class=""
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Car ID</mat-label>
                <input matInput [value]="carId" readonly />
            </mat-form-field>
        </div>
        <div class="col-3">
            <mat-form-field
                floatLabel="always"
                class=""
                appearance="fill"
                class="w-100"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Shipment Date</mat-label>
                <input
                    matInput
                    [value]="commodityShipped?.shipDate | date : 'MM/dd/yyyy HH:mm:ss' : 'UTC'"
                    readonly
                    class="w-100"
                />
            </mat-form-field>
        </div>
        <div class="col-3">
            <bds-commodity-code formControlName="commodityCode"></bds-commodity-code>
        </div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class=""
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Quantity
                    <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput type="number" formControlName="commodityAmt" />
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class=""
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Units <span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput bdsShoutcase formControlName="commodityUnits" />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4"></div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Ref 1<span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput bdsShoutcase formControlName="commodityRef1" />
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Ref 2<span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput bdsShoutcase formControlName="commodityRef2" />
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Ref 3<span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput bdsShoutcase formControlName="commodityRef3" />
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.highlight-dirty]="highlightDirty"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label
                    >Ref 4<span class="dirty-indicator" *ngIf="highlightDirty">*</span></mat-label
                >
                <input matInput bdsShoutcase formControlName="commodityRef4" />
            </mat-form-field>
        </div>
    </div>
</div>
<hr />
```
