import { Component, OnInit, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FastracDialogData } from '../fastrac-management-screen.component';

@Component({
    templateUrl: './fastrac-management-dialog-confirm.component.html',
    styleUrls: ['./fastrac-management-dialog-confirm.component.scss'],
})
export class FastracManagementDialogConfirmComponent implements OnInit {
    forgetMe: boolean;

    constructor(
        public dialogRef: MatDialogRef<FastracManagementDialogConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FastracDialogData,
    ) {
        if (data.allowIgnore && !data.promptId) {
            console.warn(
                `Cannot respect 'allowIgnore' flag because no 'promptId' has been specified.`,
            );
        }
    }

    ngOnInit() {}

    onNoClick() {
        this.dialogRef.close();
    }

    onYesClick() {
        this.forgetMeCheckboxChange();
    }
    onOkClick() {
        this.forgetMeCheckboxChange();
    }

    forgetMeCheckboxChange() {
        console.group('fastrac-management-dialog-confirm forgetMeCheckboxChange');
        if (!localStorage.getItem('forgottenBoxes')) {
            console.log('unable to get list, making an empty list');
            localStorage.setItem('forgottenBoxes', JSON.stringify([]));
        }

        const forgottenBoxes: string[] = JSON.parse(localStorage.getItem('forgottenBoxes'));
        console.log('forgottenBoxes', forgottenBoxes);
        const promptIsInList = forgottenBoxes.includes(this.data.promptId);
        console.log('promptIsInList', this.data.promptId, promptIsInList);

        console.log('forgetMe', this.forgetMe);
        if (this.forgetMe && !promptIsInList) {
            forgottenBoxes.push(this.data.promptId);
        } else if (!this.forgetMe && promptIsInList) {
            forgottenBoxes.splice(forgottenBoxes.indexOf(this.data.promptId), 1);
        }
        localStorage.setItem('forgottenBoxes', JSON.stringify(forgottenBoxes));
        console.groupEnd();
    }
}
