import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
    EquipReportCategory,
    EquipReportCatgEquip,
    EquipReportCatgEquipFull,
    EquipReportCatgValue,
} from '../../../models';

@Component({
    selector: 'bds-equip-report-categories-list',
    templateUrl: './equip-report-categories-list.component.html',
    styleUrls: ['./equip-report-categories-list.component.scss'],
})
export class EquipReportCategoriesListComponent implements OnInit, OnChanges {
    currentEquipCatgEquip: EquipReportCatgEquipFull[] = [];
    iconPlus = faPlus;

    @Input() equipId: number;
    @Input() equipCatgEquip: EquipReportCatgEquip[] = [];
    @Input() equipCategories: EquipReportCategory[] = [];
    @Input() equipCatgValues: EquipReportCatgValue[] = [];
    @Input() isSaving = false;
    @Input() shipmentDate: Date = new Date();
    @Input() tripCloseDate: Date;
    @Input() showHeader = true;

    @Output() equipCatgEquipChange: EventEmitter<{
        equips: EquipReportCatgEquip[];
        valid: boolean;
    }> = new EventEmitter<{
        equips: EquipReportCatgEquip[];
        valid: boolean;
    }>();

    ngOnInit() {
        this.setUpEquipment();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.equipCatgEquip ||
            changes.equipCategories ||
            changes.equipCatgValues ||
            changes.shipmentDate ||
            changes.equipId
        ) {
            this.setUpEquipment();
        }
    }

    canAddNew(): boolean {
        // Set up + button to only allow if < 5 or the # of available categories
        let maxCats = 5;
        let currentCats = 0;

        if (this.equipCategories) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            maxCats = Math.min(this.equipCategories.length, 5);
        }

        if (this.currentEquipCatgEquip) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            currentCats = this.currentEquipCatgEquip.length;
        }

        // TODO: Get more feedback to determine if multiple options for a category are allowed
        // return (maxCats - currentCats) > 0;
        return true;
    }

    getAllowedCategories(): EquipReportCategory[] {
        const allowedCats: EquipReportCategory[] = [...this.equipCategories]; // [];

        // TODO: Get more feedback to determine if multiple options for a category are allowed
        // this.equipCategories.forEach(cat => {
        //    const contains: boolean = this.currentEquipCatgEquip
        //        .some(eq => eq.equip.equipReportCatgId === cat.ormId);

        //    if (!contains) {
        //        allowedCats.push(cat);
        //    }
        // });

        return allowedCats;
    }

    onAdd(): void {
        if (this.canAddNew()) {
            const defaultEquip: EquipReportCatgEquip = <EquipReportCatgEquip>{
                ormId: undefined,
                equipmentId: this.equipId,
                effectDate: this.shipmentDate,
                expireDate: null,
            };

            // Only allow available categories
            const newEquip: EquipReportCatgEquipFull = {
                categories: this.getAllowedCategories(),
                values: this.equipCatgValues,
                equip: defaultEquip,
                editable: true,
                valid: false,
            };

            this.currentEquipCatgEquip = [...this.currentEquipCatgEquip, newEquip];
        }
    }

    onEquipCategoryChanged(
        event: { equip: EquipReportCatgEquip; valid: boolean },
        id: number,
    ): void {
        this.currentEquipCatgEquip[id].equip = event.equip;
        this.currentEquipCatgEquip[id].valid = event.valid;

        const changedEquip: EquipReportCatgEquip[] = [];
        this.currentEquipCatgEquip.forEach((eq) => changedEquip.push(eq.equip));
        const isValid = !this.currentEquipCatgEquip.some((eq) => !eq.valid);
        this.equipCatgEquipChange.emit({ equips: changedEquip, valid: isValid });
    }

    setUpEquipment(): void {
        this.currentEquipCatgEquip = [];

        if (!this.equipCategories || !this.equipCatgValues || !this.shipmentDate) {
            return;
        }

        if (!this.equipCatgEquip || !this.equipCatgEquip.length) {
            this.onAdd();
            return;
        }

        // TODO: Get more feedback to determine if expired items should be shown
        // Remove all expired
        // const currentEquip = this.equipCatgEquip.filter(eq => {
        //    if (
        //        eq.effectDate <= this.shipmentDate &&
        //        (!eq.expireDate || eq.expireDate >= this.shipmentDate)
        //    ) {
        //        return eq;
        //    }
        // });
        const currentEquip = this.equipCatgEquip;

        // For ones that are valid, only show the one category and one value
        // Add each to currentEquipCatgEquip
        currentEquip.forEach((eq) => {
            const equipCat: EquipReportCategory = this.equipCategories.find(
                (cat) => cat.ormId === eq.equipReportCatgId,
            );
            const newEquip: EquipReportCatgEquipFull = {
                categories: [equipCat],
                values: this.equipCatgValues,
                equip: eq,
                editable: false,
                valid: true,
            };
            this.currentEquipCatgEquip = [...this.currentEquipCatgEquip, newEquip];
        });
    }
}
