<h4 *ngIf="!!data.title" mat-dialog-title>{{ data.title }}</h4>

<div mat-dialog-content>
    <div class="container-fluid">
        <bds-fastrac-design-display
            [gridId]="gridId"
            [fastracId]="fastracId"
            [showFastrac]="showFastrac"
            (showFastracChange)="onFastracVisibleChanged($event)"
            (fastracIdChange)="onFastracIdChanged($event)"
            (fastracDesignsChange)="onFastracDesignsChanged($event)"
            (applyFastrac)="onApplyFastrac($event)"
        >
        </bds-fastrac-design-display>
    </div>
    <dx-data-grid
        bds-grid
        #dataGrid
        [gridId]="gridId"
        id="bdsRouteSearchGrid"
        [dataSource]="dataStore"
        [selectedRowKeys]="selectedRowKeys"
        height="50vh"
        (onKeyDown)="onKeyDown($event)"
        (bdsShowFastrac)="onShowFastrac()"
        (fastracIdChange)="onGridFastracIdChanged($event)"
        [bdsFastracDesignList]="fastracList"
        [bdsFastracVisible]="showFastrac"
        [bdsUseFastrac]="!data?.splc"
        [fastracDesign]="currentFastrac"
        [fastracId]="fastracId"
    >
    </dx-data-grid>
</div>

<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" cdkFocusInitial mat-dialog-close>
        {{ data.dismissiveText }}
    </button>
    <button mat-button class="text-uppercase" color="accent" (click)="onSelectClick()">
        {{ data.actionText }}
    </button>
</div>
