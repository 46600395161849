import { nameof } from '@bds/core';
import { PartialTripInfo, RtCommodityShippedAdapter, RtvTripGrid } from '@bds/railtrac-models';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    RtTrip,
    RtTripUK,
    RtTripAdapter,
    RtTripComment,
    RtTripCommentAdapter,
    RtCommodityShipped,
} from '@bds/railtrac-models';
import { Observable, of } from 'rxjs';
import { catchError, map, delay, shareReplay, take } from 'rxjs/operators';
import { RtTripDialogComponent } from './rt-trip-dialog/rt-trip-dialog.component';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ApiErrorHandlerService, map_to_object } from '@bds/helpers';
import { ErrorHandlerService } from '@bds/railtrac-internal-services';

export abstract class RtTripApiServiceOptions {
    apiUrl: string = `api/trip/`;
    odataUrl: string = `odata/trip`;
    odataTripUrl: string = `odata/tripDetail`;
    odataTripCommentsUrl: string = `/tripComments`;
    odataCommoditiesShippedUrl: string = `/commoditiesShipped`;
    apiJeopardizedShipmentsUrl: string;
    apiCombinedTripUrl: string = `api/uniquetrip`;
}

@Injectable({
    providedIn: 'root',
})
export class RailtracTripService {
    tripControllerName = 'trip';
    tripGridControllerName = 'tripgrid';

    constructor(
        private http: HttpClient,
        private options: RtTripApiServiceOptions,
        private adapter: RtTripAdapter,
        private tripCommentAdapter: RtTripCommentAdapter,
        private commodityShippedAdapter: RtCommodityShippedAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
        private dialog: MatDialog,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private oDatabaseUrl: string,
        private errorHandler: ErrorHandlerService,
    ) {}

    prompt(
        init?: number | RtTrip | MatDialogConfig<RtTrip | number>,
    ): MatDialogRef<RtTripDialogComponent, RtTrip> {
        console.log('prompting dialog for trip', init);
        let config: MatDialogConfig<RtTrip | number>;
        if (init instanceof MatDialogConfig) {
            config = init;
        } else {
            config.data = init;
        }
        console.log('opening dialog for trip', config);
        const dialogRef = this.dialog.open(RtTripDialogComponent, config);
        return dialogRef;
    }

    read(ormId: number): Observable<RtTrip> {
        return this.readViaOdata(ormId);
    }

    readViaApi(ormId: number): Observable<RtTrip> {
        return this.http.get<RtTrip>(`${this.options.apiUrl}/${ormId}`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    readViaOdata(ormId: number): Observable<RtTrip> {
        return this.http
            .get<RtTrip>(`${this.options.odataTripUrl}(${ormId})`)
            .pipe(catchError((err) => this.apiErrorHandler.handleError(err)));
    }

    readCombined(ormIds: number[]): Observable<{ trip: RtTrip; keys: RtTripUK[] }> {
        return this.http
            .get<{ trip: RtTrip; keys: RtTripUK[] }>(`${this.options.apiCombinedTripUrl}`, {
                params: {
                    id: ormIds.join(','),
                },
            })
            .pipe(
                map((data: any) => {
                    return {
                        trip: this.adapter.adapt(data.trip),
                        keys: [...data.keys.map((m) => this.adapter.adapt(m))],
                    };
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    setCombined(ormIds: number[], changes: Map<string, any>) {
        const mappedObject = map_to_object(changes);
        const serverModel = this.adapter.toServer(mappedObject);

        return this.http
            .put<any>(`${this.options.apiCombinedTripUrl}`, serverModel, {
                params: {
                    id: ormIds.join(','),
                },
            })
            .pipe(
                map((data) => {
                    return data;
                    // return (data as any[]).reduce((acc, item) => {
                    //     acc[item.Key] = { id: item.Key, ...item.Value };
                    //     return acc;
                    // }, {});
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    findOpenTrip(carInitials: string, carNumber: string): Observable<RtTrip | RtTrip[]> {
        const initName = this.adapter.metadata.find(
            (f) => f.client === nameof<RtTrip>('carInit'),
        ).server;
        const numbName = this.adapter.metadata.find(
            (f) => f.client === nameof<RtTrip>('carNo'),
        ).server;
        const tripStatusName = this.adapter.metadata.find(
            (f) => f.client === nameof<RtTrip>('tripStatus'),
        ).server;
        const filter = `$filter=${initName} eq '${carInitials}' and ${numbName} eq '${carNumber}' and ${tripStatusName} eq 'O'`;
        return this.http.get<RtTrip | RtTrip[]>(`${this.options.odataUrl}?${filter}`).pipe(
            map((data: any) => {
                console.log('fetched trips', data);
                if (Array.isArray(data.value)) {
                    return data.value.map((m) => {
                        return this.adapter.adapt(m);
                    });
                } else {
                    return this.adapter.adapt(data.value);
                }
            }),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    findCurrentTrip(carInitials: string, carNumber: string): Observable<RtTrip> {
        const initName = this.adapter.metadata.find(
            (f) => f.client === nameof<RtTrip>('carInit'),
        ).server;
        const numbName = this.adapter.metadata.find(
            (f) => f.client === nameof<RtTrip>('carNo'),
        ).server;
        const shipdateName = this.adapter.metadata.find(
            (f) => f.client === nameof<RtTrip>('shipDatetime'),
        ).server;
        const filter = `$filter=${initName} eq '${carInitials}' and ${numbName} eq '${carNumber}'`;
        const orderby = `$orderby=${shipdateName} desc`;
        const top = `$top=1`;
        const query = [filter, orderby, top].join('&');
        return this.http.get<RtTrip>(`${this.options.odataUrl}?${query}`).pipe(
            map((data: any) => {
                if (Array.isArray(data.value)) {
                    const mappedTrips = data.value.map((m) => this.adapter.adapt(m));
                    return mappedTrips.length > 0 ? mappedTrips[0] : null;
                } else {
                    return this.adapter.adapt(data.value);
                }
            }),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    findCurrentTrips(carIdList: string[]): Observable<RtvTripGrid[]> {
        const carNo = this.adapter.metadata.find(
            (f) => f.client === nameof<RtvTripGrid>('carNo'),
        ).server;
        let carsToFilter = '';
        carIdList.forEach((carId, i) => {
            carsToFilter =
                carsToFilter + `(carId eq '${carId}')` + (carIdList.length > i + 1 ? ` or ` : '');
        });
        const filter = `$filter=(isCurrent eq 'Y') and (${carsToFilter})`;
        const orderby = `$orderby=${carNo} desc`;
        const query = [filter, orderby].join('&');
        return this.http
            .get<RtvTripGrid[]>(`${this.oDatabaseUrl}${this.tripGridControllerName}?${query}`)
            .pipe(
                map((data: any) => {
                    if (Array.isArray(data.value)) {
                        const mappedTrips = data.value.map((m) => this.adapter.adapt(m));
                        return mappedTrips.length > 0 ? mappedTrips : [];
                    } else {
                        return this.adapter.adapt(data.value);
                    }
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getComments(ormId: number): Observable<RtTripComment[]> {
        return this.http
            .get<RtTripComment[]>(
                `${this.options.odataUrl}(${ormId})${this.options.odataTripCommentsUrl}`,
            )
            .pipe(
                map((data: any) => [
                    ...data.value.map((v: any) => this.tripCommentAdapter.adapt(v)),
                ]),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getCommoditiesShipped(ormId: number): Observable<RtCommodityShipped[]> {
        return this.http
            .get<RtCommodityShipped[]>(
                `${this.options.odataUrl}(${ormId})${this.options.odataCommoditiesShippedUrl}`,
            )
            .pipe(
                map((data: any) => [
                    ...data.value.map((v: any) => this.commodityShippedAdapter.adapt(v)),
                ]),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getTripCount(filter: string): Observable<number> {
        let filterUrl: string = '';
        if (filter) {
            filterUrl = ' & $filter=' + filter;
        }

        const queryUrl: string = '?$top=0 & $count=true' + filterUrl;

        return this.http.get(`${this.options.odataUrl}` + queryUrl).pipe(
            map((data: any) => data['@odata.count']),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    //   create(trip: RtTrip): Observable<RtTrip> {
    //     console.warn('RailtracTripService.createTrip() is a dummy method');
    //     return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    //   }

    create(trip: RtTrip): Observable<RtTrip> {
        return this.http.post<RtTrip>(`${this.options.apiUrl}`, this.adapter.toServer(trip)).pipe(
            map((data: any) => {
                console.log('create complete', data);
                return !!data ? this.adapter.adapt(data) : null;
            }),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    update(ormId: number, trip: RtTrip): Observable<RtTrip> {
        return this.http
            .put<RtTrip>(`${this.options.apiUrl}/${ormId}`, this.adapter.toServer(trip))
            .pipe(
                map((data: any) => {
                    console.log('update complete', data);
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    delete(tripId: number): Observable<boolean> {
        const obs = this.http.delete(`${this.options.apiUrl}/${tripId}`).pipe(
            take(1),
            shareReplay(),
            map((m) => !!m),
        );
        obs.subscribe(() => {});
        return obs;
    }

    patch(trip: RtTrip): Observable<RtTrip> {
        console.warn('RailtracTripService.patchTrip() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    getJeopardyStatus(ormId: number): Observable<string[]> {
        return this.http.get<string[]>(`${this.options.apiJeopardizedShipmentsUrl}/${ormId}`).pipe(
            map((data: any) => {
                return data;
            }),
        );
    }

    deleteTrips(partialTripInfo: PartialTripInfo[], deleteFreeRunner: boolean) {
        return this.http
            .put<PartialTripInfo[]>(
                `${this.apibaseUrl}${this.tripControllerName}/DeleteTrips/${deleteFreeRunner}`,
                partialTripInfo,
            )
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => this.errorHandler.parseApiError(err)),
            );
    }
}
