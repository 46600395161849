// NOTE: If this module is moved elsewhere, please move rt-route-smart-components as a sibling module or library
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import {
    BdsDialogConfirmModule,
    BdsDxDataGridModule,
    FormlyFieldDateboxComponent,
    FormlyFieldInputComponent,
    FormlyFieldSelectComponent,
} from '@bds/components';
import { BourqueCoreModule } from '@bds/core';
import { BdsFastracModule } from '@bds/fastrac';
import { BdsSmartComponentsModule } from '@bds/smart-components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import {
    BdsRouteParticipatingRailroadComponent,
    ParticipatingRailroadModule,
} from '@bds/participating-railroad';
import { FormlyFieldEditButtonComponent } from '@bds/components';
import { BdsCustomerFormlyComponent } from '../formly-components/bds-customer-formly.component';
import { BdsFormlyDialogComponent } from '../formly-components/bds-formly-dialog/bds-formly-dialog.component';
import { BdsRailroadFormlyComponent } from '../formly-components/bds-railroad-formly.component';
import { BdsSplcFormlyComponent } from '../formly-components/bds-splc-formly.component';
import { BdsStateFormlyComponent } from '../formly-components/bds-state-formly.component';
import { BdsPathCodeFormComponent } from './bds-path-code-form/bds-path-code-form.component';
import { BdsAddPreferredRouteComponent } from './bds-preferred-route/bds-add-preferred-route/bds-add-preferred-route.component';
import { BdsPreferredRouteComponent } from './bds-preferred-route/bds-preferred-route.component';
import { BdsRouteCodeComponent } from './bds-route-code/bds-route-code.component';
import { BdsRouteDetailsComponent } from './bds-route-details/bds-route-details.component';
import { BdsRouteMenuComponent } from './bds-route-menu/bds-route-menu.component';
//import { BdsRouteParticipatingRailroadDialogOneComponent } from './bds-route-participating-railroad-dialog-one/bds-route-participating-railroad-dialog-one.component';
//import { BdsRouteParticipatingRailroadDialogComponent } from './bds-route-participating-railroad-dialog/bds-route-participating-railroad-dialog.component';
//import { BdsRouteParticipatingRailroadComponent } from './bds-route-participating-railroad/bds-route-participating-railroad.component';
import { BdsRouteSearchDialogComponent } from './bds-route-search-dialog/bds-route-search-dialog.component';
import { BdsRouteSegmentBuilderComponent } from './bds-route-segment-builder/bds-route-segment-builder.component';
import { BdsRouteSegmentsComponent } from './bds-route-segments/bds-route-segments.component';
//import { BdsRouteParticipatingRailroadDialogTwoComponent } from './bds-route-participating-railroad-dialog-two/bds-route-participating-railroad-dialog-two.component';

@NgModule({
    declarations: [
        BdsRouteCodeComponent,
        BdsRouteMenuComponent,
        BdsRouteSegmentsComponent,
        //BdsRouteParticipatingRailroadDialogComponent,
        //BdsRouteParticipatingRailroadDialogOneComponent,
        BdsRouteSearchDialogComponent,
        BdsPreferredRouteComponent,
        //BdsRouteParticipatingRailroadComponent,
        BdsRouteDetailsComponent,
        BdsRouteSegmentBuilderComponent,
        BdsPathCodeFormComponent,
        BdsFormlyDialogComponent,
        BdsRailroadFormlyComponent,
        BdsCustomerFormlyComponent,
        BdsStateFormlyComponent,
        BdsSplcFormlyComponent,
        BdsAddPreferredRouteComponent,
        //BdsRouteParticipatingRailroadDialogTwoComponent,
    ],
    imports: [
        RouterModule,
        BourqueCoreModule,
        BdsDxDataGridModule,
        BdsFastracModule,
        CommonModule,
        DxDataGridModule,
        DxDateBoxModule,
        FontAwesomeModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatTableModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        BdsSmartComponentsModule,
        ParticipatingRailroadModule,
        FormsModule,
        BdsDialogConfirmModule,
        FormlyModule.forRoot({
            types: [
                { name: 'bds-railroad', component: BdsRailroadFormlyComponent },
                { name: 'bds-customer', component: BdsCustomerFormlyComponent },
                { name: 'bds-state', component: BdsStateFormlyComponent },
                { name: 'bds-splc', component: BdsSplcFormlyComponent },
                { name: 'bds-datebox', component: FormlyFieldDateboxComponent },
                { name: 'bds-edit-button', component: FormlyFieldEditButtonComponent },
                { name: 'bds-input', component: FormlyFieldInputComponent },
                { name: 'bds-select', component: FormlyFieldSelectComponent },
            ],
            extras: { lazyRender: true },
        }),
        FormlyMaterialModule,
    ],
    exports: [
        //BdsRouteParticipatingRailroadDialogComponent,
        //BdsRouteParticipatingRailroadDialogOneComponent,
        BdsRouteCodeComponent,
        BdsRouteMenuComponent,
        BdsRouteSegmentsComponent,
        BdsRouteSearchDialogComponent,
        BdsPreferredRouteComponent,
        BdsRouteParticipatingRailroadComponent,
        BdsRouteDetailsComponent,
        BdsRouteSegmentBuilderComponent,
    ],
})
export class BdsRouteModule {}
