<div class="equipment-list">
    <div class="container-fluid">
        <bds-fastrac-design-display
            [fastracId]="fastracId"
            [gridId]="gridId"
            [showFastrac]="showFastrac"
            (applyFastrac)="onApplyFastrac($event)"
            (fastracIdChange)="onFastracIdChanged($event)"
            (fastracDesignsChange)="onFastracDesignsChanged($event)"
            (showFastracChange)="onFastracVisibleChanged($event)"
        >
        </bds-fastrac-design-display>
    </div>

    <div class="equipment-grid-container">
        <dx-data-grid
            bds-grid
            id="bdsEquipmentGrid"
            #bdsEquipmentGrid
            height="400px"
            [dataSource]="gridSource"
            [gridId]="gridId"
            [bdsFastracVisible]="showFastrac"
            [bdsFastracDesignList]="fastracList"
            [bdsUseFastrac]="true"
            [fastracId]="fastracId"
            [fastracDesign]="currentFastrac"
            [selectedRowKeys]="selectedRowKeys"
            [bdsAllowAddRecord]="true"
            (bdsAddRecordClicked)="addNew()"
            (bdsShowFastrac)="onShowFastrac()"
            (fastracIdChange)="onGridFastracIdChanged($event)"
            (selectedRowKeysChange)="onSelectedRowKeysChanged($event)"
        >
        </dx-data-grid>
    </div>
</div>
