<h4 mat-dialog-title>
    <span>EDI Raw Data</span>
</h4>
<div mat-dialog-content>
    <div class="container" [formGroup]="ediDataForm">
        <div class="row">
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>Car Initial</mat-label>
                    <input matInput bdsShoutcase formControlName="carInit" readonly="true" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>Car Number</mat-label>
                    <input matInput bdsShoutcase formControlName="carNo" readonly="true" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>Shipment Date</mat-label>
                    <input matInput readonly [value]="shipDateTime" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>Import Source</mat-label>
                    <input matInput bdsShoutcase formControlName="importSource" readonly="true" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>Process In TP</mat-label>
                    <input matInput bdsShoutcase formControlName="processInTp" readonly="true" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>Freight Payer</mat-label>
                    <input matInput bdsShoutcase formControlName="freightPayer" readonly="true" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>isaGs Control Number</mat-label>
                    <input matInput bdsShoutcase formControlName="isaGsControlNo" readonly="true" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>GS Sender Code</mat-label>
                    <input matInput bdsShoutcase formControlName="gsSenderCode" readonly="true" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>GS Date</mat-label>
                    <input matInput bdsShoutcase formControlName="gsDate" readonly="true" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>GS Time</mat-label>
                    <input matInput bdsShoutcase formControlName="gsTime" readonly="true" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>EDI Type</mat-label>
                    <input matInput bdsShoutcase formControlName="ediType" readonly="true" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>ST 03</mat-label>
                    <input matInput bdsShoutcase formControlName="st03" readonly="true" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <dx-data-grid
        bds-grid
        #dataGrid
        id="ediDataGrid"
        [dataSource]="gridSource"
        [showBorders]="true"
        [columnAutoWidth]="true"
        height="34vh"
        minHeight="34vh"
        (onContentReady)="ediDataGrid_onContentReady($event)"
        [selectedRowKeys]="[]"
    >
        <dxo-selection mode="single"></dxo-selection>
        <dxi-column dataField="lineNo" caption="Line Number"></dxi-column>
        <dxi-column dataField="lineText" caption="Line Text"></dxi-column>
    </dx-data-grid>
</div>
<div mat-dialog-actions class="flex-row-reverse">
    <button mat-button class="text-uppercase" [mat-dialog-close]="" color="accent">Close</button>
</div>
