import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FastracScreenBaseComponent } from '@bds/fastrac';
import { FastracColsService } from '@bds/services';
import { CommunicationService } from '@bds/core';
import { CarIdTransformService } from '@bds/helpers';
import { bdsGridModeType } from '@bds/components';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-rt-consignee-car-ret-detail',
    templateUrl: './rt-consignee-car-ret-detail.component.html',
    styleUrl: './rt-consignee-car-ret-detail.component.css',
})
export class RtConsigneeCarRetDetailComponent
    extends FastracScreenBaseComponent
    implements OnInit, OnDestroy
{
    public gridId = 130;
    public gridMode: bdsGridModeType = 'single';
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Consignee Car Retention Detail Report';
    public gridSource: any;
    public selectedRowKeys: Array<number> = [];
    public displayReport: boolean = false;
    public controlNames: string[] = ['Release Begin Date', 'Release End Date'];

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        public reportService: RailtracReportsService,
        public pageService: PageService,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    ngOnInit() {
        super.ngOnInit();
        this.pageService.setHeader(this.pageHeader);
    }

    onGetReportClick(event: any) {
        this.displayReport = true;
        this.gridSource = this.reportService.CreateAspNetDatastore(
            ReportConstants.ReportDataApiCall,
            this.gridId.toString(),
            event.startDate,
            event.endDate,
        );
    }

    onCancelClick() {
        this.displayReport = false;
    }

    onCellPrepared(e) {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'amount' && e.data.amount != null && e.data.amount < 0) {
                e.cellElement.classList.add('negative-currency-class');
            }
        }
    }
}
