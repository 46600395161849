import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
    AbstractControl,
    UntypedFormGroup,
    UntypedFormArray,
    UntypedFormControl,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormErrorHandlerService } from '@bds/helpers';
import { RtErpcOrigin, RtRouteCode, RtSplcErpc } from '@bds/railtrac-models';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { RouteCodeFormService } from './bds-route-code-form.service';
import { BdsErpcOriginService, BdsSplcErpcService } from '@bds/data-access';

@Component({
    selector: 'bds-route-code',
    templateUrl: './bds-route-code.component.html',
    styleUrls: ['./bds-route-code.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class BdsRouteCodeComponent implements OnInit, OnDestroy {
    @Input() dense = true;
    @Input() formType: 'createBySegment' | 'freeform' | 'update' | 'display';
    matFloatLabel = 'always';
    matInputStyle = 'fill';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() readOnly = false;
    isCreate = false;
    routeCodeForm: UntypedFormGroup;
    private unsub$ = new Subject<void>();
    intlBorderCrossing = false;

    releaseCityState = '';

    constructor(
        private routeCodeFormService: RouteCodeFormService,
        private bdsErpcOriginService: BdsErpcOriginService,
        private splcService: BdsSplcErpcService,
        private formErrorService: FormErrorHandlerService,
    ) {
        this.routeCodeForm = routeCodeFormService.form;
    }

    @Input() set rtRouteCode(value: RtRouteCode) {
        if (value) {
            this.routeCodeForm.patchValue(value);
        }
    }

    displayState = (data: RtSplcErpc): string =>
        data ? `${data.erpcCity}, ${data.erpcState}` : '';

    displayValue = (data: RtSplcErpc): string => (data ? `${data.splc}` : '');

    displayOriginValue = (data: RtErpcOrigin): string => (data ? `${data.originCode}` : '');

    ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.complete();
    }

    ngOnInit(): void {
        this.isCreate = this.formType === 'createBySegment';

        this.toggleDisableForAllFields(this.readOnly);

        if (this.formType !== 'update') {
            this.routeCodeFormService.returnSplc.valueChanges
                .pipe(takeUntil(this.unsub$))
                .subscribe((data) => {
                    this.onReturnSelected(data);
                });
        }

        this.routeCodeFormService.releaseSplc.valueChanges
            .pipe(takeUntil(this.unsub$))
            .subscribe((data) => {
                this.onReleaseSplcChange(data);
            });
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        return this.formErrorService.getFormError(formItem);
    }

    async onReleaseSplcChange(data: string) {
        const result = await firstValueFrom(this.splcService.getBySplc(data));

        if (result && result.erpcCity && result?.erpcState) {
            this.releaseCityState = this.displayState(result);
        }
    }

    toggleIntlBorderCrossing($event) {
        this.intlBorderCrossing = $event?.checked;
    }

    setDestinationGeography(splc: RtSplcErpc): void {
        if (
            (splc || this.routeCodeFormService.destSplc.value) &&
            this.routeCodeFormService.releaseSplc.pristine
        ) {
            this.routeCodeFormService.releaseSplc.setValue(
                this.routeCodeFormService.destSplc.value ?? splc.splc,
            );
        }
    }

    setFlag($event: MatCheckboxChange, isActive: AbstractControl): void {
        isActive.setValue($event.checked ? 'Y' : 'N');
    }

    setOriginGeography(splc: RtSplcErpc): void {
        if (
            (this.routeCodeFormService.originSplc.value || splc) &&
            this.routeCodeFormService.returnSplc.pristine &&
            !this.readOnly
        ) {
            this.routeCodeFormService.returnSplc.setValue(
                this.routeCodeFormService.originSplc.value ?? splc.splc,
            );
        }
    }

    async onReturnSelected(splc: string): Promise<void> {
        const result = await firstValueFrom(this.bdsErpcOriginService.getBySplc(splc));
        if (result) {
            this.routeCodeForm.controls['originCode'].setValue(result?.originCode);
        }
    }

    toggleDisableForAllFields(readOnly: boolean): void {
        Object.keys(this.routeCodeForm.controls).forEach((key) => {
            readOnly
                ? this.routeCodeForm.controls[key].disable()
                : this.routeCodeForm.controls[key].enable();
        });

        this.disableReadOnlyFields();

        if (!this.isCreate) {
            this.disableFields();
        }
    }

    disableReadOnlyFields(): void {
        this.routeCodeForm.controls['releaseSplc'].disable();
        this.routeCodeForm.controls['road'].disable();
        this.routeCodeForm.controls['dlvRoad'].disable();
    }

    disableFields(): void {
        this.routeCodeForm.controls['routeCode'].disable();
        this.routeCodeForm.controls['routeDscr'].disable();
        this.routeCodeForm.controls['destSplc'].disable();
    }
}
