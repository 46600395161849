import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';
import { EquipmentMechDate } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class EquipmentMechDatesService {
    controllerName = 'EquipMechDates';

    constructor(
        private http: HttpClient,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    getFiltered(equipmentId: number, mechField: string): Observable<EquipmentMechDate[]> {
        let filterUrl = '';

        if (equipmentId) {
            filterUrl = `?$filter=(equipmentOrmId eq ${equipmentId}) and (mechField eq '${mechField}')`;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
            return (
                this.http
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .get<any>(`${this.odatabaseUrl}${this.controllerName}` + filterUrl)
                    .pipe(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
                        map((data) => data.value),
                        shareReplay(),
                        catchError((err) => this.apiErrorHandler.handleError(err)),
                    )
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return of(null);
        }
    }

    updateEquipFieldDates(
        equipmentId: number,
        mechField: string,
        equipDates: EquipmentMechDate[],
    ): Observable<EquipmentMechDate[]> {
        return this.http
            .put<EquipmentMechDate[]>(
                `${this.apibaseUrl}${this.controllerName}/${equipmentId}/${mechField}`,
                equipDates,
            )
            .pipe(
                map((data: EquipmentMechDate[]) => {
                    return data ? data : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}
