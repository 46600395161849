import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rt-bds-supplier',
    templateUrl: './bds-supplier.component.html',
    styleUrls: ['./bds-supplier.component.css'],
})
export class BdsSupplierComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
