import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { RtUnitMeasure } from '@bds/models';
import { DynamicDropDownValues } from '@bds/models';
import {
    Equipment,
    EquipmentCategory,
    EquipmentCategoryWithFields,
    EquipmentComponentCategory,
} from '../../../models';

@Component({
    selector: 'bds-equipment-mech',
    templateUrl: './equipment-mech.component.html',
    styleUrls: ['./equipment-mech.component.scss', '../../../styles/equipment-common-styles.scss'],
})
export class EquipmentMechComponent {
    @Input() categories$: Observable<EquipmentCategory>;
    @Input() componentCategories$: Observable<EquipmentComponentCategory[]>;
    @Input() dynamicDropdownValues$: Observable<DynamicDropDownValues[]>;
    @Input() errors: string[] = [];
    @Input() mechFieldsByCategory$: Observable<EquipmentCategoryWithFields[]>;
    @Input() selectedEquipment$: Observable<Equipment>;
    @Input() unitsOfMeasure$: Observable<RtUnitMeasure[]>;
    @Output() equipCategoryChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() equipmentMechChange: EventEmitter<{ value: Equipment; isValid: boolean }> =
        new EventEmitter<{ value: Equipment; isValid: boolean }>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() equipmentDatesChange: EventEmitter<{ value: any; isValid: boolean }> =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        new EventEmitter<{ value: any; isValid: boolean }>();
    @Output() equipmentDatesSave: EventEmitter<void> = new EventEmitter<void>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() equipmentValuesChange: EventEmitter<{ value: any; isValid: boolean }> =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        new EventEmitter<{ value: any; isValid: boolean }>();

    onEquipCategoryChanged(event: string[]): void {
        this.equipCategoryChange.emit(event);
    }

    onEquipmentMechChanged(event: { value: Equipment; isValid: boolean }): void {
        this.equipmentMechChange.emit(event);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onEquipMechDatesChanged(event: { value: any; isValid: boolean }): void {
        this.equipmentDatesChange.emit(event);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onEquipMechValuesChanged(event: { value: any; isValid: boolean }): void {
        this.equipmentValuesChange.emit(event);
    }

    onMechDatesUpdated(): void {
        this.equipmentDatesSave.emit();
    }
}
