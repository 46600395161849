import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import { RtDestinationAlias } from '@bds/railtrac-models';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

class RtDestinationAliasApiServiceOptions {
    constructor(public apiUrl?: string, public odataUrl?: string) {}

    /**
     * Get the URL for creating a Destination Alias.
     */
    getCreateUrl(): string {
        return this.apiUrl;
    }

    /**
     * Get the URL for deleting a Destination Alias.
     * @param id The OrmID of the Destination Alias to be deleted.
     */
    getDeleteUrl(id: number): string {
        return `${this.apiUrl}/${id}`;
    }

    /**
     * Get the URL for fetching a singly Destination Alias.
     * @param id The OrmId of the Destination Alias to be fetched.
     */
    getOdataFetchUrl(id: number): string {
        return `${this.odataUrl}('${id}')`;
    }

    /**
     * Get the URL for updating a Destination Alias.
     * @param id The OrmId of the Destination Alias to be updated.
     */
    getUpdateUrl(id: number): string {
        return `${this.apiUrl}/${id}`;
    }
}

@Injectable({
    providedIn: 'root',
})
export class RtDestinationAliasService {
    controllerRoute: string = `DestinationBypass`;
    options: RtDestinationAliasApiServiceOptions;

    constructor(
        private http: HttpClient,
        @Inject('BASE_API_URL') private apiBaseUrl: string,
        @Inject('BASE_ODATA_URL') private odataBaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {
        this.options = new RtDestinationAliasApiServiceOptions();
        this.options.apiUrl = `${apiBaseUrl}${this.controllerRoute}`;
        this.options.odataUrl = `${odataBaseUrl}${this.controllerRoute}`;
    }

    /**
     * Create the specified Destination Alias.
     * @param alias The Destination Alias to be created.
     */
    create(alias: RtDestinationAlias): Observable<RtDestinationAlias> {
        return this.http.post<RtDestinationAlias>(this.options.getCreateUrl(), alias).pipe(
            map((data: any) => {
                return !!data ? data : null;
            }),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    /**
     * Delete the specified Destination Alias.
     * @param id The OrmId of the Destination Alias to delete.
     */
    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.options.getDeleteUrl(id)).pipe(
            map((deleted) => !!deleted),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    /**
     * Get the OData store, as a DataSource, to fetch Destination Aliases.
     */
    getODataStore(): DataSource {
        return new DataSource({
            store: new ODataStore({
                version: 4,
                url: this.options.odataUrl,
                key: 'ormId',
            }),
            paginate: true,
            pageSize: 20,
        });
    }

    /**
     * Fetch a Destination Alias.
     * @param id The id of the Destination Alias to fetch.
     */
    read(id: number): Observable<RtDestinationAlias> {
        return this.readViaOdata(id);
    }

    /**
     * Fetch a Destination Alias through OData.
     * @param id The id of the Destination Alias to fetch.
     */
    readViaOdata(id: number): Observable<RtDestinationAlias> {
        return this.http.get<RtDestinationAlias>(this.options.getOdataFetchUrl(id)).pipe(
            map((data: any) => data),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    /**
     * Update the specified Destination Alias.
     * @param id - The id to change.
     * @param alias - The object to update with.
     */
    update(id: number, alias: RtDestinationAlias): Observable<RtDestinationAlias> {
        if (id !== alias.ormId) {
            return this.apiErrorHandler.handleError(
                new HttpErrorResponse({
                    error: new ErrorEvent('', {
                        message: 'Id of a Destination Alias cannot be altered',
                    }),
                }),
            );
        }

        return this.http.put<RtDestinationAlias>(this.options.getUpdateUrl(id), alias).pipe(
            map((data: any) => {
                return !!data ? data : null;
            }),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }
}
