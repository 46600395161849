import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EquipmentComponentModel, EquipmentMechField } from '../../../models';
import { FormlyFieldCreatorService } from '../../../internal-services';
import { HistoricDatesDialogModel, HistoricDatesDialogComponent } from '../../../internal-dialogs';

@Component({
    selector: 'bds-equipment-component-dates',
    templateUrl: './equipment-component-dates.component.html',
    styleUrls: ['./equipment-component-dates.component.scss'],
})
export class EquipmentComponentDatesComponent implements OnInit, OnChanges {
    displayFormat = 'MM/dd/yyyy';
    fields: FormlyFieldConfig[] = [];
    formValues = '';
    compForm: UntypedFormGroup = new UntypedFormGroup({});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: any;
    serializationFormat = 'yyyy-MM-dd';

    @Input() compFields: EquipmentMechField[];
    @Input() componentDescription: string;
    @Input() equipComponent: EquipmentComponentModel;
    @Output() dependenciesChange: EventEmitter<{ key: string; value: string }[]> = new EventEmitter<
        { key: string; value: string }[]
    >();
    @Output() historicDatesUpdate: EventEmitter<void> = new EventEmitter<void>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() modelChange: EventEmitter<{ value: any; isValid: boolean }> = new EventEmitter<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any;
        isValid: boolean;
    }>();

    constructor(
        public dialog: MatDialog,
        private formlyFieldCreatorService: FormlyFieldCreatorService,
    ) {}

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.model = this.createModel();
        this.createFields();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.equipComponent && !changes.equipComponent.firstChange) {
            if (this.equipComponent?.ormId === 0) {
                this.compForm.enable();
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.model = this.createModel();
        }

        if (changes.compFields && !changes.compFields.firstChange) {
            this.createFields();
        }
    }

    createFields(): void {
        const formlyFields: FormlyFieldConfig[] = [];
        const requiredExpressions: { id: string; value: string }[] = [];
        const dictDependencies: { key: string; value: string }[] = [];

        if (this.compFields && this.compFields.length) {
            this.compFields.forEach((f) => {
                if (f.dataType === 'D') {
                    const formField: FormlyFieldConfig =
                        this.formlyFieldCreatorService.createDateFormGroup(
                            f,
                            this.displayFormat,
                            this.serializationFormat,
                            this.onEditDate,
                            this.equipComponent.ormId !== 0,
                        );

                    if (f.requiredMechField) {
                        requiredExpressions.push({
                            id: f.requiredMechField,
                            value: `!!model.${f.mechFieldName}`,
                        });
                    }

                    formlyFields.push(formField);
                }
            });

            requiredExpressions.forEach((e) => {
                const fieldIndex: number = formlyFields.findIndex((f) =>
                    f.fieldGroup.find((g) => g.key === e.id + '.actual'),
                );
                if (fieldIndex > -1) {
                    const field: FormlyFieldConfig = formlyFields[fieldIndex];
                    if (field.fieldGroup.length === 1) {
                        // new single date
                        field.fieldGroup[0].expressionProperties['templateOptions.required'] =
                            e.value + '.actual';
                    } else if (
                        field.fieldGroup.length === 2 || // update single date
                        field.fieldGroup.length === 4
                    ) {
                        // new double date
                        field.fieldGroup[1].expressionProperties['templateOptions.required'] =
                            e.value + '.actual';
                    } else {
                        // update double date
                        field.fieldGroup[2].expressionProperties['templateOptions.required'] =
                            e.value + '.actual';
                    }
                } else {
                    dictDependencies.push({ key: e.id, value: e.value });
                }
            });

            this.dependenciesChange.emit(dictDependencies);
        }

        this.fields = [
            {
                fieldGroupClassName: 'w-100',
                fieldGroup: [...formlyFields],
            },
        ];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createModel(): any {
        const generatedModel = {};

        if (
            this.equipComponent &&
            this.equipComponent.tcmEquipComponentDates &&
            this.equipComponent.tcmEquipComponentDates.length
        ) {
            this.equipComponent.tcmEquipComponentDates.forEach((v) => {
                generatedModel[v.mechField] = { actual: v.actualDate, next: v.nextDueDate };
            });
        }

        return generatedModel;
    }

    onEditDate = (fieldName: string, fieldDescription: string, hasDueDate: boolean): void => {
        const dialogData: HistoricDatesDialogModel = {
            equipmentId: this.equipComponent.equipmentOrmId,
            equipmentInit: this.equipComponent.equipmentInit,
            equipmentNo: this.equipComponent.equipmentNo,
            mechFieldName: fieldName,
            mechFieldDescription: fieldDescription,
            hasDueDate: hasDueDate,
            componentId: this.equipComponent.componentId,
            componentDescription: this.componentDescription,
            equipmentComponentId: this.equipComponent.ormId,
            componentInstallDate: this.equipComponent.installDatetime,
        };

        const dialogRef = this.dialog.open(HistoricDatesDialogComponent, {
            width: '600px',
            data: dialogData,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.historicDatesUpdate.emit();
            }
        });
    };

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onModelChanged(event): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.modelChange.emit({ value: event, isValid: this.compForm.valid });
    }
}
