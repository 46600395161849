import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { RtArrAtOrig, RtStateCode, RtClm, RtClmSightCode } from '@bds/railtrac-models';
import {
    BdsStateService,
    ClmSightCodeService,
    RtRailroadService,
    BdsErpcOriginService,
} from '@bds/data-access';
import { BdsOriginCriteriaService } from './bds-origin-criteria.service';
import DataSource from 'devextreme/data/data_source';
import { OriginListRefreshService } from './services/origin-list-refresh.service';

@Component({
    selector: 'bds-origin-criteria',
    templateUrl: './bds-origin-criteria.component.html',
    styleUrls: ['./bds-origin-criteria.component.scss'],
})
export class BdsOriginCriteriaComponent implements OnInit, OnChanges {
    calculatedOriginCode: string;
    originCodes: ODataStore;
    origins: DataSource; //ODataStore;
    railroads: ODataStore;
    sightCodeLookup: RtClmSightCode[];
    stateLookup: RtStateCode[];

    @Input() originCode: string;
    @Input() dense = true;
    @Input() latestClm: RtClm;
    @Input() locationCity: string;
    @Input() locationState: string;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';

    constructor(
        private originCodeService: BdsErpcOriginService,
        private originService: BdsOriginCriteriaService,
        private stateService: BdsStateService,
        private clmSightCodeService: ClmSightCodeService,
        private railroadService: RtRailroadService,
        private _snackbar: MatSnackBar,
        public dialog: MatDialog,
        private listRefreshService: OriginListRefreshService,
    ) {
        this.originCodes = this.originCodeService.getODataStore();
        this.railroads = this.railroadService.getODataStore();
        this.stateService
            .getLookup()
            .pipe(take(1))
            .subscribe((s) => (this.stateLookup = s));
        this.clmSightCodeService
            .getAll()
            .pipe(take(1))
            .subscribe((sc) => (this.sightCodeLookup = sc));
    }

    ngOnInit() {
        this.getOriginCode();
        this.listRefreshService.refresh$.subscribe((data) => {
            this.getOrigins();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes.originCode && !changes.originCode.firstChange && this.originCode) ||
            (changes.locationCity && !changes.locationCity.firstChange && this.locationCity) ||
            (changes.locationState && !changes.locationState.firstChange && this.locationState)
        ) {
            this.getOriginCode();
        }
    }

    getOriginCode(): void {
        if (this.originCode) {
            this.calculatedOriginCode = this.originCode;
        } else if (this.locationCity && this.locationState) {
            this.originCodeService
                .getByCityState(this.locationCity, this.locationState)
                .pipe(take(1))
                .subscribe((oc) => {
                    this.calculatedOriginCode = oc.originCode ?? null;
                    if (this.calculatedOriginCode) {
                        this.getOrigins();
                    }
                });
        } else {
            this.calculatedOriginCode = '';
        }
    }

    getOrigins(): void {
        this.origins = this.originService.getDataSource();
    }

    onDelete(event: RtArrAtOrig): void {
        this.originService
            .delete(event.ormId)
            .pipe(take(1))
            .subscribe(
                (s) => {
                    this.afterSaveComplete('Origin Criteria Deleted');
                },
                (err) => this.afterError(err),
            );
    }

    onSaved(event: RtArrAtOrig): void {
        this.origins = this.originService.getDataSource();
    }

    private afterError(error: any, event?: RtArrAtOrig) {
        let message = '';
        const customerErrors = [];

        if (typeof error === 'string') {
            message = error;
            customerErrors.push(error);
        } else if (Array.isArray(error) && error[0].validationMessage) {
            message = `Origin Criteria is invalid - ${error[0].validationMessage}`;
            error.forEach((e) => customerErrors.push(e));
        } else {
            message = 'An unknown error occurred';
        }

        this._snackbar.open(message, 'Error', { duration: 3000 });
    }

    private afterSaveComplete(message?: string) {
        this.origins = this.originService.getDataSource();

        if (!message) {
            message = 'Changes saved!';
        }

        this._snackbar.open(message, 'Ok', { duration: 3000 });
    }
}
