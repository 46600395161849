<h1 mat-dialog-title>Destination Criteria Info</h1>

<mat-dialog-content>
    <div class="bds-error-message">{{ errorMessage }}</div>
    <div>
        <bds-destination-criteria-details
            [destinationCriteria]="destinationCriteria"
            [customerStore]="customerStore"
            [railroadStore]="railroadStore"
            [states]="stateLookup$ | async"
            [sightCodes]="sightCodeLookup$ | async"
            [isNew]="isNew"
            [isClone]="isClone"
            [editableCustomer]="editableCustomer"
            (destinationCriteriaChange)="onDestinationCriteriaChanged($event)"
        >
        </bds-destination-criteria-details>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" (click)="onCancelClick()">
        Cancel
    </button>

    <button
        mat-button
        [disabled]="!isValid || isSaving"
        class="text-uppercase"
        color="accent"
        (click)="onSaveClick()"
    >
        Save Destination Criteria
    </button>
</mat-dialog-actions>
