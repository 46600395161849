import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RtvCarHotListGrid } from '@bds/railtrac-models';
import { ApiReadService } from '@bds/odata';
import { RtvCarHotListGridApiServiceOptions } from './rtv-car-hot-list-grid.options';

@Injectable({
    providedIn: 'root',
})
export class RtvCarHotListGridService extends ApiReadService<RtvCarHotListGrid> {
    constructor(public options: RtvCarHotListGridApiServiceOptions, public http: HttpClient) {
        super(options, http);
    }
}
