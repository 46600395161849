import { RtTripCommentDialogComponent } from '../../rt-trip-comment/rt-trip-comment-dialog/rt-trip-comment-dialog.component';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    faCommentAlt,
    faCommentAltLines,
    faCommentAltPlus,
    faTrashAlt,
    faPencilAlt,
} from '@fortawesome/pro-duotone-svg-icons';
import { faLongArrowDown, faLongArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { AuthorizeService } from '@bds/auth';
import { RtCommentType, RtTripComment, RtTripCommentDialogModel } from '@bds/railtrac-models';
import { RailtracTripService } from '../railtrac-trip.service';
import { RtTripCommentService } from '@bds/data-access';
import { BdsDialogConfirmComponent } from '@bds/components';

@Component({
    selector: 'rt-comments-for-trip',
    templateUrl: './rt-trip-comments.component.html',
    styleUrls: ['./rt-trip-comments.component.css'],
})
export class RtTripCommentsComponent implements OnInit, OnChanges {
    @Input() carInit: string;
    @Input() carNumber: string;
    @Input() shipDatetime: Date;
    @Input() tripId: number;
    @Input() showButtons = true;

    comments: RtTripComment[] = [];
    commentBlankIconDuo = faCommentAlt;
    commentGenericIconDuo = faCommentAltLines;
    commentEdit = faPencilAlt;
    commentAdd = faCommentAltPlus;
    commentDelete = faTrashAlt;
    sortAscending = faLongArrowUp;
    sortDescending = faLongArrowDown;
    userName: string;

    commentTypes: RtCommentType[];
    showSortButtons = false;
    showToolbar = true;

    sortMode: 'asc' | 'desc' = 'asc';

    get sortedAscendingComments(): RtTripComment[] {
        return this.comments.sort(
            (a, b) => new Date(a.commentDate).getTime() - new Date(b.commentDate).getTime(),
        );
    }

    get sortedDescendingComments(): RtTripComment[] {
        return this.comments.sort(
            (a, b) => new Date(b.commentDate).getTime() - new Date(a.commentDate).getTime(),
        );
    }

    constructor(
        private tripService: RailtracTripService,
        private authService: AuthorizeService,
        private _snackbar: MatSnackBar,
        public dialog: MatDialog,
        public commentService: RtTripCommentService,
    ) {
        this.authService
            .getUser()
            .pipe(take(1))
            .subscribe((user) => (this.userName = user['preferred_username']));
    }

    ngOnInit() {
        this.commentService
            .getCommentTypes()
            .pipe(
                tap((response) => {
                    this.commentTypes = response;
                }),
            )
            .subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        const tripIdChange = changes['tripId'];
        if (tripIdChange) {
            this.getComments(tripIdChange.currentValue);
        }
    }

    getComments(tripId: number): void {
        this.tripService
            .getComments(tripId)
            .pipe(take(1))
            .subscribe({
                next: (c) => {
                    this.comments.splice(0, this.comments.length, ...c);
                    this.onSortDescending(); //Display newest comment on top
                },
                complete: () => {
                    this.showSortButtons = this.comments && this.comments.length > 1 ? true : false;
                    this.showToolbar = this.showSortButtons || this.showButtons;
                },
            });
    }

    onSortAscending(): void {
        console.log('onSortAscending');
        this.comments = [].concat(
            this.comments.sort(
                (a, b) => new Date(a.commentDate).getTime() - new Date(b.commentDate).getTime(),
            ),
        );
        this.sortMode = 'asc';
    }

    onSortDescending(): void {
        console.log('onSortDescending');
        this.comments = [].concat(
            this.comments.sort(
                (a, b) => new Date(b.commentDate).getTime() - new Date(a.commentDate).getTime(),
            ),
        );
        this.sortMode = 'desc';
    }

    onAddComment(): void {
        const dialogData: RtTripCommentDialogModel = {
            title: 'Add',
            tripComment: new RtTripComment(
                this.carInit,
                this.carNumber,
                this.shipDatetime,
                undefined,
                this.userName,
                undefined,
                undefined,
                '',
                undefined,
                undefined,
                this.tripId,
            ),
        };
        const dialogRef = this.dialog.open(RtTripCommentDialogComponent, {
            width: '500px',
            data: dialogData,
        } as MatDialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                this.onSaved('Comment Saved');
            }
        });
    }

    onDeleteComment(ormId: number): void {
        //let selectedTripComment = this.comments.find(i => i.ormId === ormId);
        const dialog = this.dialog.open(BdsDialogConfirmComponent, {
            data: {
                actionText: 'Delete',
                content: `Are you sure you would like to delete the comment?`,
            },
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.commentService.delete(ormId).subscribe({
                    next: (v) => {
                        this._snackbar.open('Deleted Comment', 'Ok', {
                            duration: 3000,
                        });
                    },
                    error: (error) => {
                        this._snackbar.open(error.error, 'Error', {
                            duration: 3000,
                        });
                    },
                    complete: () => {
                        this.getComments(this.tripId);
                    },
                });
            }
        });
    }

    onEditComment(ormId: number): void {
        const selectedTripComment = this.comments.find((i) => i.ormId === ormId);

        const dialogData = {
            title: 'Edit',
            tripComment: selectedTripComment,
        };
        const dialogRef = this.dialog.open(RtTripCommentDialogComponent, {
            width: '500px',
            data: dialogData,
        } as MatDialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                this.onSaved('Comment Saved');
            }
        });
    }

    onSaved(message: string): void {
        this.getComments(this.tripId);
        this._snackbar.open(message, 'Ok', { duration: 3000 });
    }
}
