import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import { RtCarMech, RtCarMechAdapter } from '@bds/railtrac-models';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export abstract class RtCarMechApiServiceOptions {
    odataUrl: string;
}

@Injectable({
    providedIn: 'root',
})
export class RtCarMechService {
    constructor(
        private http: HttpClient,
        private options: RtCarMechApiServiceOptions,
        private adapter: RtCarMechAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    /**
     * Fetch the RtCarMech information for the related init/no.
     * @param init The Equipment/Car initials.
     * @param no The Equipment/Car number.
     */
    read(init: string, no: string): Observable<RtCarMech> {
        return this.readViaOdata(init, no);
    }

    /**
     * Fetch, via OData, the RtCarMech information for the related init/no.
     * @param init The Equipment/Car initials.
     * @param no The Equipment/Car number.
     */
    readViaOdata(init: string, no: string): Observable<RtCarMech> {
        let keyFilter = `'${init}','${no}'`;
        return this.http.get<RtCarMech>(`${this.options.odataUrl}(${keyFilter})`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }
}
