import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FastracScreenBaseComponent } from '@bds/fastrac';
import { FastracColsService } from '@bds/services';
import { CommunicationService } from '@bds/core';
import { CarIdTransformService } from '@bds/helpers';
import { bdsGridModeType } from '@bds/components';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'rt-shipment-car-situation-all-report',
    templateUrl: './rt-shipment-car-situation-all-report.component.html',
    styleUrls: ['./rt-shipment-car-situation-all-report.component.css'],
})
export class RtShipmentCarSituationAllReportComponent
    extends FastracScreenBaseComponent
    implements OnInit, OnDestroy
{
    public gridId = 100;
    public gridMode: bdsGridModeType = 'single';
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Shipment Car Situation All Report';
    public gridSource: any;
    public selectedRowKeys: Array<number> = [];

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        public reportService: RailtracReportsService,
        public pageService: PageService,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    ngOnInit() {
        super.ngOnInit();
        this.pageService.setHeader(this.pageHeader);
        this.gridSource = this.reportService.CreateAspNetDatastore(
            this.apiCall,
            this.gridId.toString(),
        );
    }
}
