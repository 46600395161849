import {
    Component,
    Input,
    OnInit,
    Optional,
    Output,
    Self,
    EventEmitter,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { nameof } from '@bds/core';
import { RtRailroadService } from '@bds/data-access';
import { RtRailroadFact, RtSplcErpc } from '@bds/railtrac-models';
import { dxMatStyle } from '../../helpers/dx-mat-style';
import { DxLookupComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { BdsMatFormField } from '../../abstract-components/bds-abstract-control-value.accessor';

@Component({
    selector: 'bds-railroad-select',
    template: ` <dx-lookup
            floatLabel="always"
            [stylingMode]="'filled'"
            [class.bds-mat-dense-form-field]="true"
            class="bds-dx-custom-material flex-fill bds-readonly-wrapper"
            name="roadReturnSplc"
            [(ngModel)]="value"
            [dataSource]="dataSource"
            [valueExpr]="key"
            [displayExpr]="displayExp"
            [searchEnabled]="true"
            searchTimeout="200"
            [dropDownOptions]="popupDim"
            itemTemplate="item"
            fieldTemplate="field"
            label="{{ placeholder }}"
            [disabled]="disabled"
            (onValueChanged)="valueChanged()"
        >
            <div *dxTemplate="let data of 'field'">
                <div class="dx-placeholder" *ngIf="!data">
                    {{ placeholder }}
                </div>
                <div class="custom-icon" *ngIf="data">
                    {{ getDisplayValue(data) }}
                </div>
            </div>
            <div *dxTemplate="let data of 'item'">
                <div class="custom-icon">
                    {{ defaultDisplay(data) }}
                </div>
            </div>
        </dx-lookup>
        <ng-content></ng-content>
        <div></div>`,
    styleUrls: ['./bds-railroad-select.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: MatFormFieldControl, useExisting: BdsRailroadSelectComponent }],
})
export class BdsRailroadSelectComponent extends BdsMatFormField<string> implements OnInit {
    dataSource: DataSource;
    @Input() dense = true;
    displayExp: string[] = [nameof<RtRailroadFact>('rrName'), nameof<RtRailroadFact>('rrScac')];
    id: string;
    key = nameof<RtRailroadFact>('rrScac');
    @Input() matFloatLabel = 'always';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Output() selected = new EventEmitter<RtRailroadFact>();
    popupDim = { minWidth: '250px' };
    @ViewChild(DxLookupComponent) dxSelectBox;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private railroadService: RtRailroadService,
    ) {
        super(ngControl);
    }

    @Input() set label(plh: string) {
        super.placeholder = plh;
    }

    get style(): string {
        return dxMatStyle(this.matStyle);
    }

    defaultDisplay = (data: RtRailroadFact): string =>
        data ? `${data.rrScac} - ${data.rrName}` : '';

    @Input() displayValue = this.defaultDisplay;

    getDisplayValue(data: RtSplcErpc) {
        return this.displayValue(data);
    }

    ngOnInit(): void {
        this.dataSource = new DataSource({
            store: this.railroadService.getODataStore(),
            paginate: true,
            pageSize: 10,
            searchExpr: this.displayExp,
            select: this.displayExp,
            sort: this.displayExp,
            key: this.key,
        });
    }

    valueChanged() {
        this.selected.emit(this.dxSelectBox.selectedItem);
    }
}
