<h2 mat-dialog-title>Path Code Not Found</h2>
<mat-dialog-content>
    <div class="row">
        <mat-form-field
            floatLabel="always"
            class="w-100 bds-mat-dense-form-field col-12"
            appearance="fill"
        >
            <mat-label>Path Description</mat-label>
            <input
                matInput
                tabindex="-1"
                name="pathDescription"
                [(ngModel)]="data.pathDescription"
                [disabled]="true"
                bdsShoutcase
            />
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field
            floatLabel="always"
            class="w-100 bds-mat-dense-form-field col-6"
            appearance="fill"
        >
            <mat-label>Origin Road</mat-label>
            <input
                matInput
                tabindex="-1"
                name="originRoad"
                [(ngModel)]="data.originRoad"
                [disabled]="true"
                bdsShoutcase
            />
        </mat-form-field>
        <mat-form-field
            floatLabel="always"
            class="w-100 bds-mat-dense-form-field col-6"
            appearance="fill"
        >
            <mat-label>Delivery Road</mat-label>
            <input
                matInput
                tabindex="-1"
                name="deliveryRoad"
                [(ngModel)]="data.deliveryRoad"
                [disabled]="true"
                bdsShoutcase
            />
        </mat-form-field>
    </div>
    <div class="row">
        <div class="mb-3 col-6">
            <mat-slide-toggle [(ngModel)]="autoGenerate" name="autoGen" *ngIf="savePathCodeFlag">
                Auto Generate Path Code
            </mat-slide-toggle>
        </div>
        <mat-form-field
            floatLabel="always"
            class="w-100 bds-mat-dense-form-field col-6"
            appearance="fill"
        >
            <mat-label>Path Code</mat-label>
            <input
                matInput
                tabindex="-1"
                name="pathCode"
                [(ngModel)]="data.pathCode"
                [disabled]="autoGenerate"
                [required]="!autoGenerate"
                #pathControl="ngModel"
                type="number"
                min="1000"
                max="9999"
            />
            <mat-error *ngIf="pathControl.invalid">Path Code is required.</mat-error>
        </mat-form-field>
    </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" mat-dialog-close>Cancel</button>
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        cdkFocusInitial
        (click)="save()"
        [disabled]="pathControl.invalid"
    >
        Save
    </button>
</div>
