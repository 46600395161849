import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtTripFieldValue implements From<RtTripFieldValue> {
    constructor(public fieldName?: string, public fieldValue?: string) {}
    from(trip: RtTripFieldValue): RtTripFieldValue {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtTripFieldValue>('fieldName'), server: 'fieldName' },
    { client: nameof<RtTripFieldValue>('fieldValue'), server: 'fieldValue' },
];

@Injectable({
    providedIn: 'root',
})
export class RtTripFieldValueAdapter extends TwoWayAdapter<RtTripFieldValue> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtTripFieldValueMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtTripFieldValue>('fieldName'),
                dataType: 'string',
                caption: 'fieldName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripFieldValue>('fieldValue'),
                dataType: 'string',
                caption: 'fieldValue',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtTripFieldValue>('fieldName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTripFieldValue>('fieldValue'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
