<h1 mat-dialog-title>Add Comment</h1>
<mat-dialog-content>
    <rt-trip-comment [comment]="data" (commentChange)="commentChanged($event)"></rt-trip-comment>

    <!-- <mat-form-field class="w-100">
      <mat-label>Comment</mat-label>
      <input matInput bdsShoutcase #dialogInput [formControl]="control" cdkFocusInitial>
      <mat-hint *ngIf="!!maxLength" align="end">{{control.value?.length || 0}}/{{maxLength}}</mat-hint>
      <mat-hint *ngIf="control.errors?.required">Comment is required.</mat-hint>
      <mat-hint *ngIf="control.errors?.maxlength">Comment is too long.</mat-hint>
    </mat-form-field>
   -->
    <div *ngIf="controls.length > 1">
        <mat-divider></mat-divider>
        <h3>Individual Comments</h3>
        <mat-card
            *ngFor="let tripControl of controls; let first = first; let last = last"
            [formGroup]="tripControl.control"
            class="mb-3"
        >
            <mat-card-header>
                <mat-card-subtitle
                    >{{ tripControl.key.carInit }} {{ tripControl.key.carNo }}
                    {{ tripControl.key.shipDatetime }}</mat-card-subtitle
                >
            </mat-card-header>
            <mat-card-content>
                <mat-form-field [class.pt-1]="!first" [class.pb-1]="!last" class="w-100">
                    <mat-label>Comments</mat-label>
                    <input matInput bdsShoutcase #dialogInput formControlName="comments" />
                    <mat-hint *ngIf="!!maxLength" align="end"
                        >{{ tripControl.control.controls['comments'].value?.length || 0 }} /{{
                            maxLength
                        }}</mat-hint
                    >
                    <mat-hint *ngIf="tripControl.control.controls['comments']?.errors?.required"
                        >Comment is required.
                    </mat-hint>
                    <mat-hint *ngIf="tripControl.control.controls['comments']?.errors?.maxlength"
                        >Comment is too long.
                    </mat-hint>
                </mat-form-field>
                <mat-slide-toggle formControlName="transmitToCust" class="mb-3"
                    >Show to Consignee in RAILTRACAdvize
                </mat-slide-toggle>
                <div>
                    <button
                        mat-button
                        color="accent"
                        class="text-uppercase"
                        (click)="tripControl.expanded = !tripControl.expanded"
                    >
                        <span *ngIf="!tripControl.expanded">More</span>
                        <span *ngIf="tripControl.expanded">Less</span>
                    </button>
                </div>
                <div *ngIf="tripControl.expanded">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="w-100">
                                <mat-label>Comment Type</mat-label>
                                <mat-select formControlName="commentType">
                                    <mat-option value="">-- General Comment --</mat-option>
                                    <mat-option
                                        *ngFor="let item of commentTypes | async"
                                        [value]="item.category"
                                        >{{ item.description }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="w-100">
                                <mat-label>Default Comment</mat-label>
                                <mat-select (selectionChange)="setDefaultComment($event)">
                                    <mat-option value=""></mat-option>
                                    <mat-option
                                        *ngFor="let item of defaultComments | async"
                                        [value]="item.comment"
                                    >
                                        {{ item.code }} - {{ item.comment }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" (click)="onNoClick()" color="accent">Cancel</button>
    <button mat-button class="text-uppercase" color="accent" (click)="onYesClick()">
        Add Comment
    </button>
</mat-dialog-actions>
