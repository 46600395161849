<dx-date-box
    #datebox
    class="w-100 bds-dx-custom-material bds-mat-dense-form-field dx-mat-form-field-floatLabel-always"
    stylingMode="filled"
    [formControl]="formControl"
    [type]="type"
    [acceptCustomValue]="acceptsCustomValue"
    [showClearButton]="showClearButton"
    [useMaskBehavior]="useMaskBehavior"
    [displayFormat]="displayFormat"
    [disabled]="_innerDisabled"
    [min]="min"
    [max]="max"
    [dateSerializationFormat]="dateSerializationFormat"
    [disabledDates]="disabledDates"
    [readOnly]="readOnly"
    [label]="label"
    [isValid]="isValid"
    (onOpened)="onOpened($event)"
    validationMessageMode="auto"
    (onInput)="onOpened($event)"
    (onValueChanged)="onOpened($event)"
    (onKeyDown)="onDataChange()"
>
</dx-date-box>

<ng-content></ng-content>
