import { Injectable } from '@angular/core';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtvTripDiversion implements From<RtvTripDiversion> {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public carId?: string,
        public shipDate?: Date,
        public divertDateTime?: Date,
        public le?: string,
        public road?: string,
        public shipmentType?: string,
        public shipmentTypeDescription?: string,
        public prevFleetId?: string,
        public prevBusinessGroup?: string,
        public prevDestSplc?: string,
        public prevDestCity?: string,
        public prevDestState?: string,
        public prevReturnSplc?: string,
        public prevReturnCity?: string,
        public prevReturnState?: string,
        public prevRouteCode?: string,
        public prevRouteDescription?: string,
        public prevCustNo?: string,
        public prevCustName?: string,
        public prevCustType?: string,
        public prevCustTypeDescription?: string,
        public prevOrderNo?: string,
        public prevBolNo?: string,
        public whoPays?: string,
        public whoPaysDescription?: string,
        public divLocSplc?: string,
        public divLocCity?: string,
        public divLocState?: string,
        public divRouteDescription?: string,
        public divertType?: string,
        public diversionDescription?: string,
        public userId?: string,
        public updateDateTime?: Date,
        public currentFleetId?: string,
        public currentBusinessGroup?: string,
        public currentTripStatus?: string,
        public currentCarStatus?: string,
        public currentDestSplc?: string,
        public currentDestCity?: string,
        public currentDestState?: string,
        public currentReturnSplc?: string,
        public currentReturnCity?: string,
        public currentReturnState?: string,
        public currentRouteCode?: string,
        public currentRouteDescription?: string,
        public currentCustNo?: string,
        public currentCustName?: string,
        public currentCustType?: string,
        public currentCustTypeDescription?: string,
        public currentOrderNo?: string,
        public currentBolNo?: string,
        public commCompart?: number,
        public commCode?: string,
        public commAmt?: number,
        public commUnits?: string,
        public commShortName?: string,
        public clmLocCity?: string,
        public clmLocState?: string,
        public clmSightCode?: string,
        public clmDateTime?: Date,
        public clmLe?: string,
        public clmRoad?: string,
        public clmTrnJct?: string,
        public ormId?: number,
        public rtTripOrmId?: number,
    ) {}
    from(trip: RtvTripDiversion): RtvTripDiversion {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtvTripDiversion>('carInit'), server: 'carInit' },
    { client: nameof<RtvTripDiversion>('carNo'), server: 'carNo' },
    { client: nameof<RtvTripDiversion>('carId'), server: 'carId' },
    { client: nameof<RtvTripDiversion>('shipDate'), server: 'shipDate' },
    { client: nameof<RtvTripDiversion>('divertDateTime'), server: 'divertDateTime' },
    { client: nameof<RtvTripDiversion>('le'), server: 'le' },
    { client: nameof<RtvTripDiversion>('road'), server: 'road' },
    { client: nameof<RtvTripDiversion>('shipmentType'), server: 'shipmentType' },
    {
        client: nameof<RtvTripDiversion>('shipmentTypeDescription'),
        server: 'shipmentTypeDescription',
    },
    { client: nameof<RtvTripDiversion>('prevFleetId'), server: 'prevFleetId' },
    { client: nameof<RtvTripDiversion>('prevBusinessGroup'), server: 'prevBusinessGroup' },
    { client: nameof<RtvTripDiversion>('prevDestSplc'), server: 'prevDestSplc' },
    { client: nameof<RtvTripDiversion>('prevDestCity'), server: 'prevDestCity' },
    { client: nameof<RtvTripDiversion>('prevDestState'), server: 'prevDestState' },
    { client: nameof<RtvTripDiversion>('prevReturnSplc'), server: 'prevReturnSplc' },
    { client: nameof<RtvTripDiversion>('prevReturnCity'), server: 'prevReturnCity' },
    { client: nameof<RtvTripDiversion>('prevReturnState'), server: 'prevReturnState' },
    { client: nameof<RtvTripDiversion>('prevRouteCode'), server: 'prevRouteCode' },
    { client: nameof<RtvTripDiversion>('prevRouteDescription'), server: 'prevRouteDescription' },
    { client: nameof<RtvTripDiversion>('prevCustNo'), server: 'prevCustNo' },
    { client: nameof<RtvTripDiversion>('prevCustName'), server: 'prevCustName' },
    { client: nameof<RtvTripDiversion>('prevCustType'), server: 'prevCustType' },
    {
        client: nameof<RtvTripDiversion>('prevCustTypeDescription'),
        server: 'prevCustTypeDescription',
    },
    { client: nameof<RtvTripDiversion>('prevOrderNo'), server: 'prevOrderNo' },
    { client: nameof<RtvTripDiversion>('prevBolNo'), server: 'prevBolNo' },
    { client: nameof<RtvTripDiversion>('whoPays'), server: 'whoPays' },
    { client: nameof<RtvTripDiversion>('whoPaysDescription'), server: 'whoPaysDescription' },
    { client: nameof<RtvTripDiversion>('divLocSplc'), server: 'divLocSplc' },
    { client: nameof<RtvTripDiversion>('divLocCity'), server: 'divLocCity' },
    { client: nameof<RtvTripDiversion>('divLocState'), server: 'divLocState' },
    { client: nameof<RtvTripDiversion>('divRouteDescription'), server: 'divRouteDescription' },
    { client: nameof<RtvTripDiversion>('divertType'), server: 'divertType' },
    { client: nameof<RtvTripDiversion>('userId'), server: 'userId' },
    { client: nameof<RtvTripDiversion>('updateDateTime'), server: 'updateDateTime' },
    { client: nameof<RtvTripDiversion>('currentFleetId'), server: 'currentFleetId' },
    { client: nameof<RtvTripDiversion>('currentBusinessGroup'), server: 'currentBusinessGroup' },
    { client: nameof<RtvTripDiversion>('currentTripStatus'), server: 'currentTripStatus' },
    { client: nameof<RtvTripDiversion>('currentCarStatus'), server: 'currentCarStatus' },
    { client: nameof<RtvTripDiversion>('currentDestSplc'), server: 'currentDestSplc' },
    { client: nameof<RtvTripDiversion>('currentDestCity'), server: 'currentDestCity' },
    { client: nameof<RtvTripDiversion>('currentDestState'), server: 'currentDestState' },
    { client: nameof<RtvTripDiversion>('currentReturnSplc'), server: 'currentReturnSplc' },
    { client: nameof<RtvTripDiversion>('currentReturnCity'), server: 'currentReturnCity' },
    { client: nameof<RtvTripDiversion>('currentReturnState'), server: 'currentReturnState' },
    { client: nameof<RtvTripDiversion>('currentRouteCode'), server: 'currentRouteCode' },
    {
        client: nameof<RtvTripDiversion>('currentRouteDescription'),
        server: 'currentRouteDescription',
    },
    { client: nameof<RtvTripDiversion>('currentCustNo'), server: 'currentCustNo' },
    { client: nameof<RtvTripDiversion>('currentCustName'), server: 'currentCustName' },
    { client: nameof<RtvTripDiversion>('currentCustType'), server: 'currentCustType' },
    {
        client: nameof<RtvTripDiversion>('currentCustTypeDescription'),
        server: 'currentCustTypeDescription',
    },
    { client: nameof<RtvTripDiversion>('currentOrderNo'), server: 'currentOrderNo' },
    { client: nameof<RtvTripDiversion>('currentBolNo'), server: 'currentBolNo' },
    { client: nameof<RtvTripDiversion>('commCompart'), server: 'commCompart' },
    { client: nameof<RtvTripDiversion>('commAmt'), server: 'commAmt' },
    { client: nameof<RtvTripDiversion>('commCode'), server: 'commCode' },
    { client: nameof<RtvTripDiversion>('commUnits'), server: 'commUnits' },
    { client: nameof<RtvTripDiversion>('commShortName'), server: 'commShortName' },
    { client: nameof<RtvTripDiversion>('clmLocCity'), server: 'clmLocCity' },
    { client: nameof<RtvTripDiversion>('clmLocState'), server: 'clmLocState' },
    { client: nameof<RtvTripDiversion>('clmSightCode'), server: 'clmSightCode' },
    { client: nameof<RtvTripDiversion>('clmDateTime'), server: 'clmDateTime' },
    { client: nameof<RtvTripDiversion>('clmLe'), server: 'clmLe' },
    { client: nameof<RtvTripDiversion>('clmRoad'), server: 'clmRoad' },
    { client: nameof<RtvTripDiversion>('clmTrnJct'), server: 'clmTrnJct' },
    { client: nameof<RtvTripDiversion>('ormId'), server: 'ormId' },
    { client: nameof<RtvTripDiversion>('rtTripOrmId'), server: 'rtTripOrmId' },
];

@Injectable({
    providedIn: 'root',
})
export class RtvTripDiversionAdapter extends TwoWayAdapter<RtvTripDiversion> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}
