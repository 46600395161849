import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RtSegmentMiles } from '@bds/railtrac-models';

@Component({
    selector: 'bds-segment-miles',
    template: ` <bds-segment-miles-grid
            (selectedSegments)="navigate($event)"
        ></bds-segment-miles-grid>
        <router-outlet></router-outlet>`,
})
export class BdsSegmentMilesComponent {
    constructor(private router: Router, private route: ActivatedRoute) {}

    navigate(segments: RtSegmentMiles[]) {
        this.router.navigate(['./details'], {
            relativeTo: this.route,
            queryParams: { ids: segments ? segments.map((x) => x.ormId).join(',') : null },
        });
    }
}
