import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Directive, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

export abstract class BdsAbstractControlValueAccessor<T> implements ControlValueAccessor {
    val: T;

    protected constructor(public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    get value(): T {
        return this.val;
    }

    set value(value: T) {
        this.val = value;
        this.onChange(this.value);
        this.onTouch(this.value);
    }

    onChange(value: T): void {}

    onTouch(value: T) {}

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(obj: any): void {
        this.val = obj;
        this.val = this.value;
    }
}

@Directive()
export abstract class BdsMatFormField<T>
    extends BdsAbstractControlValueAccessor<T>
    implements MatFormFieldControl<T>
{
    errorState = false;
    focused = false;
    abstract id: string;
    stateChanges = new Subject<void>();

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('aria-describedby') userAriaDescribedBy: string;

    protected constructor(public ngControl: NgControl) {
        super(ngControl);
    }

    private _disabled = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    private _placeholder: string;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    private _required = false;

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    get empty(): boolean {
        return !this.value;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    onContainerClick(event: MouseEvent): void {}

    setDescribedByIds(ids: string[]): void {}

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}

@Component({
    selector: 'bds-dx-mat-field',
    template: ` <ng-content></ng-content>`,
})
export class DxMatField extends MatFormField {}
