import { Injectable } from '@angular/core';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtErpcOrigin implements From<RtErpcOrigin> {
    constructor(
        public erpcOrigin?: string,
        public erpcState?: string,
        public splc?: string,
        public originCode?: string,
        public masterOriginYorn?: string,
        public ormId?: number,
    ) {}
    from(trip: RtErpcOrigin): RtErpcOrigin {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtErpcOrigin>('erpcOrigin'), server: 'erpcOrigin' },
    { client: nameof<RtErpcOrigin>('erpcState'), server: 'erpcState' },
    { client: nameof<RtErpcOrigin>('splc'), server: 'splc' },
    { client: nameof<RtErpcOrigin>('originCode'), server: 'originCode' },
    { client: nameof<RtErpcOrigin>('masterOriginYorn'), server: 'masterOriginYorn' },
    { client: nameof<RtErpcOrigin>('ormId'), server: 'ormId' },
];

@Injectable({
    providedIn: 'root',
})
export class RtErpcOriginAdapter extends TwoWayAdapter<RtErpcOrigin> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}
