<h4 mat-dialog-title>{{ title }}</h4>

<div mat-dialog-content>
    <p>{{ content }}</p>
</div>
<div mat-dialog-actions align="end">
    <button
        mat-button
        *ngIf="data.defaultToYes"
        class="text-uppercase"
        color="accent"
        (click)="onCancelClick()"
    >
        CANCEL
    </button>

    <button
        mat-button
        *ngIf="data.defaultToYes"
        class="text-uppercase"
        color="accent"
        [mat-dialog-close]="'confirm'"
        cdkFocusInitial
    >
        {{ actionText }}
    </button>

    <button
        mat-button
        *ngIf="!data.defaultToYes"
        class="text-uppercase"
        color="accent"
        (click)="onCancelClick()"
        cdkFocusInitial
    >
        CANCEL
    </button>

    <button
        mat-button
        *ngIf="!data.defaultToYes"
        class="text-uppercase"
        color="accent"
        [mat-dialog-close]="'confirm'"
    >
        {{ actionText }}
    </button>
</div>
