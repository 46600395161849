<div bdsScrollSpySection="listanchor" [scrollMargin]="116" #listanchor>
    <bds-equipment-list
        [equipmentStore]="equipmentList"
        [gridId]="gridId"
        [needsRefresh$]="refreshGrid$"
        [selectedFastrac]="fastracId"
        [selectedItem]="equipmentId"
        (addEquipment)="onAddEquipment()"
        (selectedFastracChange)="onFastracIdChanged($event)"
        (selectedItemChange)="onEquipmentChanged($event)"
    >
    </bds-equipment-list>
</div>

<div *ngIf="equipmentId || newEquipment">
    <bds-equipment-details
        [selectedEquipId]="equipmentId"
        [userName]="userName"
        (equipmentSave)="onEquipmentSaved($event)"
    >
    </bds-equipment-details>
</div>

<div *ngIf="selectedRowKeys.length" style="padding-bottom: 600px"></div>
