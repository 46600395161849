<dx-data-grid
    bds-grid
    #bdsDestinationAliasGrid
    [bdsUseZeroPadDates]="true"
    id="bdsDestinationAliasGrid"
    [dataSource]="gridSource"
    class="max-vh"
    minHeight="200px"
    [(selectedRowKeys)]="selectedRowKeys"
    [bdsAllowAddRecord]="true"
    [bdsAllowDeleteRecords]="true"
    (bdsAddRecordClicked)="createRecord()"
    (bdsDeleteRecordsClicked)="deleteRecords($event)"
>
    <dxo-selection
        mode="multiple"
        [allowSelectAll]="false"
        showCheckBoxesMode="none"
    ></dxo-selection>
    <dxi-column dataField="tripCity" caption="Trip City"></dxi-column>
    <dxi-column dataField="tripState" caption="Trip State"></dxi-column>
    <dxi-column dataField="clmCity" caption="CLM City"></dxi-column>
    <dxi-column dataField="clmState" caption="CLM State"></dxi-column>
</dx-data-grid>
