import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtMiscComment implements From<RtMiscComment> {
    constructor(
        public ormId: number = 0,
        public code?: string,
        public category?: string,
        public comment?: string,
        public transactionCategory?: string,
    ) {}
    from(trip: RtMiscComment): RtMiscComment {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtMiscComment>('ormId'), server: 'ormId' },
    { client: nameof<RtMiscComment>('code'), server: 'code' },
    { client: nameof<RtMiscComment>('category'), server: 'category' },
    { client: nameof<RtMiscComment>('comment'), server: 'comment' },
    { client: nameof<RtMiscComment>('transactionCategory'), server: 'transactionCategoryCode' },
];

@Injectable({
    providedIn: 'root',
})
export class RtMiscCommentAdapter extends TwoWayAdapter<RtMiscComment> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtMiscCommentMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtMiscComment>('ormId'),
                dataType: 'string',
                caption: 'OrmID',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtMiscComment>('code'),
                dataType: 'string',
                caption: 'Code',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtMiscComment>('category'),
                dataType: 'string',
                caption: 'Category',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtMiscComment>('comment'),
                dataType: 'string',
                caption: 'Default Comment',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtMiscComment>('transactionCategory'),
                dataType: 'string',
                caption: 'Transaction Category',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtMiscComment>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtMiscComment>('code'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtMiscComment>('category'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtMiscComment>('comment'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtMiscComment>('transactionCategory'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
