import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtSupplierFact implements From<RtSupplierFact> {
    constructor(
        public code?: string,
        public name?: string,
        public city?: string,
        public state?: string,
    ) {}
    from(trip: RtSupplierFact): RtSupplierFact {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtSupplierFact>('code'), server: 'code' },
    { client: nameof<RtSupplierFact>('name'), server: 'name' },
    { client: nameof<RtSupplierFact>('city'), server: 'city' },
    { client: nameof<RtSupplierFact>('state'), server: 'state' },
];

@Injectable({
    providedIn: 'root',
})
export class RtSupplierFactAdapter extends TwoWayAdapter<RtSupplierFact> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtSupplierFactMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtSupplierFact>('code'),
                dataType: 'string',
                caption: 'code',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtSupplierFact>('name'),
                dataType: 'string',
                caption: 'name',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtSupplierFact>('city'),
                dataType: 'string',
                caption: 'city',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtSupplierFact>('state'),
                dataType: 'string',
                caption: 'state',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtSupplierFact>('code'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtSupplierFact>('name'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtSupplierFact>('city'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtSupplierFact>('state'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
