// TODO: If this is set up to handle multi-select, will need to add
// the multiTrips boolean to rt-trip-details
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { RtTrip } from '@bds/railtrac-models';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'rt-jeopardized-shipment-reason-common',
    templateUrl: './rt-jeopardized-shipment-reason-common.component.html',
    styleUrls: ['./rt-jeopardized-shipment-reason-common.component.scss'],
})
export class RtJeopardizedShipmentReasonCommonComponent implements OnInit {
    @Input() useFullTrip: boolean = true;
    @Input() trip: RtTrip;
    @Input() useExpansionPanel: boolean = true;
    @Input() showDestinationCriteria: boolean = false;
    @Input() showOriginCriteria: boolean = false;
    @Input() showInterfaceValidations: boolean = false;
    @Output() action: EventEmitter<string> = new EventEmitter();
    @Output() saveClick = new EventEmitter<RtTrip>();
    @Output() popoutTrip = new EventEmitter<number>();

    iconExternal = faExternalLink;

    constructor() {}

    ngOnInit() {}

    onResolve(event: string) {
        // TODO: This never seems to be used
        this.action.emit(event);
    }

    onSaveClicked() {
        this.saveClick.emit(this.trip);
    }

    onPopoutClicked() {
        this.popoutTrip.emit(this.trip.ormId);
    }
}
