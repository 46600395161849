import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import { RtCarAssign, RtCarAssignAdapter } from '@bds/railtrac-models';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export abstract class RtCarAssignApiServiceOptions {
    odataUrl: string; //`odata/RtCarAssign`;
}

@Injectable({
    providedIn: 'root',
})
export class RtCarAssignService {
    constructor(
        private http: HttpClient,
        private options: RtCarAssignApiServiceOptions,
        private adapter: RtCarAssignAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    /**
     * Fetch the RtCarAssign information for the related init/no.
     * @param init The Equipment/Car initials.
     * @param no The Equipment/Car number.
     */
    read(init: string, no: string): Observable<RtCarAssign> {
        return this.readViaOdata(init, no);
    }

    /**
     * Fetch, via OData, the RtCarAssign information for the related init/no.
     * @param init The Equipment/Car initials.
     * @param no The Equipment/Car number.
     */
    readViaOdata(init: string, no: string): Observable<RtCarAssign> {
        let keyFilter = `'${init}','${no}'`;
        return this.http.get<RtCarAssign>(`${this.options.odataUrl}(${keyFilter})`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }
}
