import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntil, take } from 'rxjs/operators';
import { RtJeopardizedShipment, RtTrip } from '@bds/railtrac-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RailtracTripService } from '../../../railtrac-trip/railtrac-trip.service';

@Component({
    selector: 'rt-jeopardized-shipment-no-bill',
    templateUrl: './rt-jeopardized-shipment-no-bill.component.html',
    styleUrls: ['./rt-jeopardized-shipment-no-bill.component.scss'],
})
export class RtJeopardizedShipmentNoBillComponent implements OnInit, OnChanges {
    @Input() shipment: RtJeopardizedShipment;
    public trip: RtTrip;

    constructor(
        public tripService: RailtracTripService,
        public snackbar: MatSnackBar,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        const shipment = changes['shipment'];
        if (!!shipment.currentValue) {
            const cv: RtJeopardizedShipment = shipment.currentValue;
            this.tripService.findCurrentTrip(cv.carInitials, cv.carNumber).subscribe((trip) => {
                this.trip = trip;
            });
        }
    }

    onPopoutTripclicked($event: number) {}

    onResolve(event: string) {
        // TODO: Based on setup, not sure how to get trip
    }

    onTripSaveClicked(tripDetails: RtTrip) {
        this.tripService.update(tripDetails.ormId, tripDetails).subscribe((upTrip) => {
            this.snackbar.open('Trip Saved!', 'Ok', {
                duration: 3000,
            });
        });
    }
}
