import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { From, IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';
import { FormGroupDescription } from './adapter_t';

export class RtDestinationAlias implements From<RtDestinationAlias> {
    constructor(
        public ormId?: number,
        public tripCity?: string,
        public tripState?: string,
        public clmCity?: string,
        public clmState?: string,
        public userId?: string,
        public updateDateTime?: Date,
    ) {}

    from(trip: RtDestinationAlias): RtDestinationAlias {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtDestinationAlias>('ormId'), server: 'ormId' },
    { client: nameof<RtDestinationAlias>('tripCity'), server: 'tripCity' },
    { client: nameof<RtDestinationAlias>('tripState'), server: 'tripState' },
    { client: nameof<RtDestinationAlias>('clmCity'), server: 'clmCity' },
    { client: nameof<RtDestinationAlias>('clmState'), server: 'clmState' },
    { client: nameof<RtDestinationAlias>('userId'), server: 'userId' },
    { client: nameof<RtDestinationAlias>('updateDateTime'), server: 'updateDateTime' },
];

@Injectable({
    providedIn: 'root',
})
export class RtDestinationAliasAdapter extends TwoWayAdapter<RtDestinationAlias> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtDestinationAliasMetadata implements FormGroupDescription {
    constructor() {}
    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<RtDestinationAlias>('ormId'),
            new UntypedFormControl('', [Validators.required, Validators.min(0)]),
        );

        formGroup.addControl(
            nameof<RtDestinationAlias>('tripCity'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(9)]),
        );

        formGroup.addControl(
            nameof<RtDestinationAlias>('tripState'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(2)]),
        );

        formGroup.addControl(
            nameof<RtDestinationAlias>('clmCity'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(9)]),
        );

        formGroup.addControl(
            nameof<RtDestinationAlias>('clmState'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(2)]),
        );

        formGroup.addControl(
            nameof<RtDestinationAlias>('userId'),
            new UntypedFormControl('', [Validators.maxLength(30)]),
        );

        formGroup.addControl(nameof<RtDestinationAlias>('updateDateTime'), new UntypedFormControl('', []));

        if (!!obj) {
            formGroup.patchValue(obj);
        }

        return formGroup;
    }
}
