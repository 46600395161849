import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from '../adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export enum ClmType {
    Archive = 'ARCHIVE',
    Current = 'CURRENT',
    Historical = 'HISTORICAL',
}

export class RtClm implements From<RtClm> {
    constructor(
        public ormId?: number,
        //public clmType?: ClmType,
        public carInit?: string, //
        public carNo?: string, //
        public clmDateTime?: string, //
        public sightCode?: string, //
        public locationCity?: string, //
        public locationState?: string, //
        public loadEmptyFlag?: string, //
        public trainJunction?: string, //
        public road?: string, //
        public destinationCity?: string, //
        public destinationState?: string, //
        public clmProcessedDate?: Date, //
        public clmEvent?: string, //
        public routeCode?: string, //
        public actualHoursToDestination?: number, //
        public actualHoursToOrigin?: number, //
        public locationSplc?: string, //
        public destinationSplc?: string, //
        public railRoadEtaCity?: string, //
        public railRoadEtaState?: string, //
        public railRoadEtaSightCode?: string, //
        public railRoadEtaDateTime?: Date, //
        public railRoadEtaDestinationSplc?: string, //
        public source?: string, //
        public previousSplc?: string, //
        public milesFromPrevious?: number, //
        public dwellTime?: number, //
        public clmDifferentialTime?: number, //
    ) {}

    from(trip: RtClm): RtClm {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtClmView>('ormId'), server: 'ormId' },
    { client: nameof<RtClmView>('clmType'), server: 'clmType' },
    { client: nameof<RtClmView>('carInit'), server: 'carInit' },
    { client: nameof<RtClmView>('carNo'), server: 'carNo' },
    { client: nameof<RtClmView>('clmDateTime'), server: 'clmDateTime' },
    { client: nameof<RtClmView>('sightCode'), server: 'sightCode' },
    { client: nameof<RtClmView>('locationCity'), server: 'locationCity' },
    { client: nameof<RtClmView>('locationState'), server: 'locationState' },
    { client: nameof<RtClmView>('loadEmptyFlag'), server: 'loadEmptyFlag' },
    { client: nameof<RtClmView>('trainJunction'), server: 'trainJunction' },
    { client: nameof<RtClmView>('road'), server: 'road' },
    { client: nameof<RtClmView>('destinationCity'), server: 'destinationCity' },
    { client: nameof<RtClmView>('destinationState'), server: 'destinationState' },
    { client: nameof<RtClmView>('clmProcessedDate'), server: 'clmProcessedDate' },
    { client: nameof<RtClmView>('clmEvent'), server: 'clmEvent' },
    { client: nameof<RtClmView>('routeCode'), server: 'routeCode' },
    { client: nameof<RtClmView>('actualHoursToDestination'), server: 'actualHoursToDestination' },
    { client: nameof<RtClmView>('actualHoursToOrigin'), server: 'actualHoursToOrigin' },
    { client: nameof<RtClmView>('locationSplc'), server: 'locationSplc' },
    { client: nameof<RtClmView>('destinationSplc'), server: 'destinationSplc' },
    { client: nameof<RtClmView>('railRoadEtaCity'), server: 'railRoadEtaCity' },
    { client: nameof<RtClmView>('railRoadEtaState'), server: 'railRoadEtaState' },
    { client: nameof<RtClmView>('railRoadEtaSightCode'), server: 'railRoadEtaSightCode' },
    { client: nameof<RtClmView>('railRoadEtaDateTime'), server: 'railRoadEtaDateTime' },
    {
        client: nameof<RtClmView>('railRoadEtaDestinationSplc'),
        server: 'railRoadEtaDestinationSplc',
    },
    { client: nameof<RtClmView>('source'), server: 'source' },
    { client: nameof<RtClmView>('previousSplc'), server: 'previousSplc' },
    { client: nameof<RtClmView>('milesFromPrevious'), server: 'milesFromPrevious' },
    { client: nameof<RtClmView>('dwellTime'), server: 'dwellTime' },
    { client: nameof<RtClmView>('clmDifferentialTime'), server: 'clmDifferentialTime' },
];

@Injectable({
    providedIn: 'root',
})
export class RtClmAdapter extends TwoWayAdapter<RtClm> {
    constructor() {
        super();
    }

    public metadata: IModelMetadata[] = relationship;
}

@Injectable({
    providedIn: 'root',
})
export class RtClmViewAdapter extends TwoWayAdapter<RtClmView> {
    constructor() {
        super();
    }

    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtClmMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}

    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        const columns = [
            {
                dataField: nameof<RtClmView>('ormId'),
                dataType: 'number',
                caption: 'ormId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('clmType'),
                dataType: 'ClmType',
                caption: 'clmType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('clmDateTime'),
                dataType: 'Date',
                caption: 'clmDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('sightCode'),
                dataType: 'string',
                caption: 'sightCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('locationCity'),
                dataType: 'string',
                caption: 'locationCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('locationState'),
                dataType: 'string',
                caption: 'locationState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('loadEmptyFlag'),
                dataType: 'string',
                caption: 'loadEmptyFlag',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('trainJunction'),
                dataType: 'string',
                caption: 'trainJunction',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('road'),
                dataType: 'string',
                caption: 'road',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('destinationCity'),
                dataType: 'string',
                caption: 'destinationCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('destinationState'),
                dataType: 'string',
                caption: 'destinationState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('clmProcessedDate'),
                dataType: 'Date',
                caption: 'clmProcessedDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('clmEvent'),
                dataType: 'string',
                caption: 'clmEvent',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('routeCode'),
                dataType: 'string',
                caption: 'routeCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('actualHoursToDestination'),
                dataType: 'number',
                caption: 'actualHoursToDestination',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('actualHoursToOrigin'),
                dataType: 'number',
                caption: 'actualHoursToOrigin',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('locationSplc'),
                dataType: 'string',
                caption: 'locationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('destinationSplc'),
                dataType: 'string',
                caption: 'destinationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('railRoadEtaCity'),
                dataType: 'string',
                caption: 'railRoadEtaCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('railRoadEtaState'),
                dataType: 'string',
                caption: 'railRoadEtaState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('railRoadEtaSightCode'),
                dataType: 'string',
                caption: 'railRoadEtaSightCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('railRoadEtaDateTime'),
                dataType: 'Date',
                caption: 'railRoadEtaDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('railRoadEtaDestinationSplc'),
                dataType: 'string',
                caption: 'railRoadEtaDestinationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('source'),
                dataType: 'string',
                caption: 'source',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('previousSplc'),
                dataType: 'string',
                caption: 'previousSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('milesFromPrevious'),
                dataType: 'number',
                caption: 'milesFromPrevious',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('dwellTime'),
                dataType: 'number',
                caption: 'dwellTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmView>('clmDifferentialTime'),
                dataType: 'number',
                caption: 'clmDifferentialTime',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];

        return columns;
    }

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        this.setupFormGroup(formGroup, obj);
        return formGroup;
    }

    setupFormGroup(formGroup: UntypedFormGroup, loadClm?: any) {
        formGroup.addControl(nameof<RtClmView>('ormId'), new UntypedFormControl(0));
        formGroup.addControl(nameof<RtClmView>('clmType'), new UntypedFormControl('ARCHIVE'));
        formGroup.addControl(
            nameof<RtClmView>('carInit'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(4)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('carNo'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(6)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('clmDateTime'),
            new UntypedFormControl({ value: new Date() }, [Validators.required]),
        );
        formGroup.addControl(
            nameof<RtClmView>('sightCode'),
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<RtClmView>('locationCity'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('locationState'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(2)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('loadEmptyFlag'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(1)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('trainJunction'),
            new UntypedFormControl('', [Validators.maxLength(6)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('road'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(4)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('destinationCity'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('destinationState'),
            new UntypedFormControl('', [Validators.maxLength(2)]),
        );
        formGroup.addControl(nameof<RtClmView>('clmProcessedDate'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtClmView>('clmEvent'),
            new UntypedFormControl('', [Validators.maxLength(10)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('routeCode'),
            new UntypedFormControl('', [Validators.maxLength(20)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('actualHoursToDestination'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtClmView>('actualHoursToOrigin'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtClmView>('locationSplc'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('destinationSplc'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('railRoadEtaCity'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('railRoadEtaState'),
            new UntypedFormControl('', [Validators.maxLength(2)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('railRoadEtaSightCode'),
            new UntypedFormControl('', [Validators.maxLength(1)]),
        );
        formGroup.addControl(nameof<RtClmView>('railRoadEtaDateTime'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtClmView>('railRoadEtaDestinationSplc'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('source'),
            new UntypedFormControl('', [Validators.maxLength(1)]),
        );
        formGroup.addControl(
            nameof<RtClmView>('previousSplc'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(nameof<RtClmView>('milesFromPrevious'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmView>('dwellTime'), new UntypedFormControl(0));
        formGroup.addControl(nameof<RtClmView>('clmDifferentialTime'), new UntypedFormControl(''));

        if (!!loadClm) {
            formGroup.setValue(loadClm);
        }
    }
}

export class RtClmView extends RtClm {
    constructor(public clmType: ClmType) {
        super();
    }
}
