import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BdsSplcErpcService } from '@bds/data-access';
import { RtSplcErpc } from '@bds/railtrac-models';
import { DxSelectBoxComponent } from 'devextreme-angular/ui/select-box';
import DataSource from 'devextreme/data/data_source';
import { BdsMatFormField } from '../../abstract-components/bds-abstract-control-value.accessor';
import { dxMatStyle } from '../../helpers/dx-mat-style';
import { BdsSplcSelectDialogComponent } from './bds-splc-select-dialog/bds-splc-select-dialog.component';

@Component({
    selector: 'bds-splc-select',
    template: `
        <dx-select-box
            [stylingMode]="style"
            [class.bds-mat-dense-form-field]="dense"
            [class.dx-mat-form-field-floatLabel-always]="matFloatLabel === 'always'"
            class="bds-dx-custom-material flex-fill"
            style="opacity: 1"
            [(ngModel)]="value"
            [dataSource]="dataSource"
            [valueExpr]="valueExpr"
            [displayExpr]="splcDisplayExpr"
            [searchEnabled]="true"
            searchTimeout="200"
            [dropDownOptions]="popupDim"
            itemTemplate="item"
            fieldTemplate="field"
            [label]="placeholder ?? 'SPLC'"
            [disabled]="disabled"
            (onValueChanged)="valueChanged()"
            [value]="initValue"
        >
            <dxi-button
                *ngIf="enableSearchPopup"
                name="searchConsignee"
                [options]="searchButtonOptions"
            ></dxi-button>
            <dxi-button name="dropDown"></dxi-button>
            <div *dxTemplate="let data of 'field'">
                <dx-text-box
                    style="display:inline-block"
                    width="100%"
                    [value]="getDisplayValue(data)"
                    [placeholder]="placeholder"
                ></dx-text-box>
            </div>
            <div *dxTemplate="let data of 'item'">
                <div class="custom-icon">
                    {{ searchDisplay(data) }}
                </div>
            </div>
        </dx-select-box>
        <ng-content></ng-content>
        <div></div>
    `,
    styleUrls: [],
    providers: [{ provide: MatFormFieldControl, useExisting: BdsSplcSelectComponent }],
})
export class BdsSplcSelectComponent extends BdsMatFormField<string> implements OnInit {
    dataSource: DataSource;
    @Input() dense = true;
    @Input() displayValue = this.defaultDisplay;
    @ViewChild(DxSelectBoxComponent) dxSelectBox;
    @Input() enableSearchPopup = false;
    id: string;
    @Input() matFloatLabel = 'always';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() pageSize = 10;
    @Input() initValue;
    @Input() useErpcOrigin = false;
    @Input() title: string;
    popupDim = { minWidth: '250px' };
    searchButtonOptions = {
        type: 'default',
        icon: 'search',
        stylingMode: 'text',
        onClick: () => {
            this.openSearchDialog();
        },
    };
    @Input() searchDisplay = this.defaultDisplay;
    @Output() selected = new EventEmitter<RtSplcErpc>();
    @Input() splcDisplayExpr: string[] = ['erpcCity', 'erpcState', 'splc', 'city', 'state'];
    @Input() valueExpr: 'splc' | undefined = 'splc';

    constructor(
        private splcService: BdsSplcErpcService,
        @Optional() @Self() public ngControl: NgControl,
        private dialog: MatDialog,
    ) {
        super(ngControl);
    }

    private _filter: [];

    get filter() {
        return this._filter;
    }

    @Input() set filter(value: []) {
        this._filter = value;
    }

    @Input() set label(plh: string) {
        super.placeholder = plh;
    }

    get style(): string {
        return dxMatStyle(this.matStyle);
    }

    defaultDisplay(data: RtSplcErpc) {
        return data ? `${data.erpcCity}, ${data.erpcState} (${data.splc})` : '';
    }

    getDisplayValue(data: RtSplcErpc) {
        return this.displayValue(data ?? this.initValue);
    }

    ngOnInit(): void {
        this.dataSource = new DataSource({
            store: this.splcService.getODataStore(),
            paginate: true,
            pageSize: 10,
            searchExpr: this.splcDisplayExpr,
            select: ['erpcCity', 'erpcState', 'splc', 'rule260', 'city', 'state'],
            sort: ['erpcCity', 'erpcState'],
            filter: this.filter,
            key: 'splc',
        });
    }

    valueChanged() {
        this.selected.emit(this.dxSelectBox.selectedItem);
    }

    private openSearchDialog() {
        const dialogRef = this.dialog.open(BdsSplcSelectDialogComponent, {
            data: { filter: this.filter, title: this.title },
        });

        dialogRef.afterClosed().subscribe((data) => {
            this.value = data[0].splc;
        });
    }
}
