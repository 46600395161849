import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { Fastrac } from '@bds/models';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { forkJoin, isObservable, Observable, of } from 'rxjs';

export interface FastracShareDialogData {
    available: Observable<Fastrac[]> | Fastrac[];
    selected: Observable<Fastrac[]> | Fastrac[];
}

@Component({
    templateUrl: './fastrac-dialog-default-selection.component.html',
    styleUrls: ['./fastrac-dialog-default-selection.component.scss'],
})
export class FastracDialogDefaultSelectionComponent implements OnInit {
    iconSearch = faSearch;
    available: Fastrac[] = [];
    selected: Fastrac[] = [];
    saved: Fastrac[] = [];
    initialized: boolean;

    constructor(
        public dialogRef: MatDialogRef<FastracDialogDefaultSelectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FastracShareDialogData,
    ) {}

    ngOnInit() {
        forkJoin({
            available: isObservable(this.data.available)
                ? this.data.available
                : of(this.data.available),
            selected: isObservable(this.data.selected)
                ? this.data.selected
                : of(this.data.selected),
        }).subscribe((data) => {
            this.available.push(...data.available);
            this.selected.push(...data.selected);
            this.saved.push(...data.selected);
            this.initialized = true;
        });
    }

    onNoClick() {
        this.dialogRef.close();
    }

    // onYesClick() {
    //     const added = this.selected.filter(x => !this.saved.includes(x));
    //     const removed = this.saved.filter(x => !this.selected.includes(x));
    //     const results = {
    //         added: added,
    //         removed: removed
    //     };
    //     this.dialogRef.close(results);
    // }

    onSelectedOptionsChange($event: MatSelectionListChange) {
        const user = $event.options[0].value;
        if (this.selected.includes(user)) {
            this.selected.splice(this.selected.indexOf(user), 1);
        } else {
            this.selected.push(user);
        }
    }
}
