import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ODataStore from 'devextreme/data/odata/store';
import { RtCommodityShippedApiOptionsService } from './commodity-shipped-options.service';

@Injectable({
    providedIn: 'root',
})
export class CommodityShippedService {
    constructor(private options: RtCommodityShippedApiOptionsService, private http: HttpClient) {}

    add(model) {
        return this.http.post(this.options.apiUrl, model);
    }

    delete(id: number) {
        return this.http.delete(`${this.options.apiUrl}/${id}`);
    }

    edit(model) {
        return this.http.put(`${this.options.apiUrl}/${model.ormId}`, model);
    }

    getODataStore() {
        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: 'ormId',
            deserializeDates: false,
        });
    }
}

