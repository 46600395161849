import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-trip-hot-list-screen',
    templateUrl: './trip-hot-list-screen.component.html',
    styleUrls: ['./trip-hot-list-screen.component.scss'],
})
export class TripHotListScreenComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
