import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';

@Injectable({
    providedIn: 'root',
})
export class BlPartyIdQualifierService {
    controllerName: string = 'blpartyidqualifier';

    constructor(
        private http: HttpClient,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    getLookups(): Observable<{ id: string; description: string }[]> {
        return this.http
            .get<{ id: string; description: string }[]>(
                `${this.odatabaseUrl}${this.controllerName}`,
            )
            .pipe(
                map((data: any) => data),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}
