import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule, Routes } from '@angular/router';
import { BdsDxDataGridModule } from '@bds/components';
import { BdsFastracModule } from '@bds/fastrac';
import { DxDataGridModule } from 'devextreme-angular';
import { BdsHotListDetailScreenComponent } from './bds-hot-list-details-screen/bds-hot-list-detail-screen.component';
import { BdsHotListSharedModule } from './bds-hot-list-shared/bds-hot-list-shared.module';
import { BdsHotlistGridComponent } from './bds-hotlist-grid/bds-hotlist-grid.component';
import { BdsHotlistComponent } from './bds-hotlist.component';
import { BdsUiModule } from '@bds/ui';
import { BdsGridDetailsModule } from '@bds/components';
import { BdsDateTimePickerModule } from '@bds/smart-components';

const routes: Routes = [
    {
        path: '',
        component: BdsHotlistComponent,
        children: [
            {
                path: 'details',
                component: BdsHotListDetailScreenComponent,
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        BdsFastracModule,
        DxDataGridModule,
        BdsDxDataGridModule,
        BdsHotListSharedModule,
        MatDialogModule,
        BdsUiModule,
        BdsGridDetailsModule,
    ],
    exports: [RouterModule, BdsHotlistGridComponent],
    declarations: [BdsHotlistGridComponent, BdsHotListDetailScreenComponent],
})
export class BdsHotlistRoutingModule {}

@NgModule({
    declarations: [BdsHotlistComponent],
    imports: [BdsHotlistRoutingModule],
    exports: [BdsHotlistComponent],
})
export class BdsHotlistModule {}
