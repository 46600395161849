import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { RtTrip, RtClm } from '@bds/railtrac-models';
import { RtJeopardizedShipmentReasonBaseComponent } from '../rt-jeopardized-shipment-reason-base.component';
import { RailtracClmService } from '../../../railtrac-clm/railtrac-clm.service';
import { RailtracTripService } from '../../../railtrac-trip/railtrac-trip.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
    selector: 'rt-jeopardized-shipment-non-moving',
    templateUrl: './rt-jeopardized-shipment-non-moving.component.html',
    styleUrls: ['./rt-jeopardized-shipment-non-moving.component.scss'],
})
export class RtJeopardizedShipmentNonMovingComponent
    extends RtJeopardizedShipmentReasonBaseComponent
    implements OnInit, OnDestroy, OnChanges
{
    dateDiff: number;
    currentClm: RtClm;

    constructor(
        public tripService: RailtracTripService,
        public clmService: RailtracClmService,
        public snackbar: MatSnackBar,
        public dialog: MatDialog,
    ) {
        super(tripService, clmService);
    }

    ngOnInit() {
        super.ngOnInit();
        // this.trip$.pipe(takeUntil(this.destroyer$)).subscribe(trip => { });
        this.clms$.subscribe((clms) => {
            if (clms.length !== 0) {
                this.currentClm = clms.reduce((p, c) => {
                    return !!p ? c : c.clmDateTime > p.clmDateTime ? c : p;
                });
                this.dateDiff = moment(new Date()).diff(
                    moment(this.currentClm.clmDateTime),
                    'days',
                );
            }
        });
    }
    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }

    onResolve(event: string) {
        // TODO: Based on setup, not sure how to get trip
    }

    onTripSaveClicked(tripDetails: RtTrip) {
        this.tripService.update(tripDetails.ormId, tripDetails).subscribe((upTrip) => {
            this.snackbar.open('Trip Saved!', 'Ok', {
                duration: 3000,
            });
        });
    }
}
