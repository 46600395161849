import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicDropDownValues } from '@bds/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldCreatorService } from '../../../internal-services';
import { EquipComponentMechEav, EquipmentMechField } from '../../../models';

@Component({
    selector: 'bds-equipment-component-fields',
    templateUrl: './equipment-component-fields.component.html',
    styleUrls: ['./equipment-component-fields.component.scss'],
})
export class EquipmentComponentFieldsComponent implements OnInit, OnChanges {
    fields: FormlyFieldConfig[] = [];
    formValues = '';
    compForm: UntypedFormGroup = new UntypedFormGroup({});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: any;

    @Input() compFields: EquipmentMechField[];
    @Input() compValues: EquipComponentMechEav[];
    @Input() dynamicDropdownValues: DynamicDropDownValues[];
    @Output() dependenciesChange: EventEmitter<{ key: string; value: string }[]> = new EventEmitter<
        { key: string; value: string }[]
    >();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() modelChange: EventEmitter<{ value: any; isValid: boolean }> = new EventEmitter<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any;
        isValid: boolean;
    }>();

    constructor(private formlyFieldCreatorService: FormlyFieldCreatorService) {}

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.model = this.createModel();
        this.createFields();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.compValues && !changes.compValues.firstChange) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.model = this.createModel();
        }

        if (changes.compFields && !changes.compFields.firstChange) {
            this.createFields();
        }
    }

    createFields(): void {
        const formlyFields: FormlyFieldConfig[] = [];
        const requiredExpressions: { id: string; value: string }[] = [];
        const dictDependencies: { key: string; value: string }[] = [];

        if (this.compFields && this.compFields.length) {
            this.compFields.forEach((f) => {
                if (f.dataType !== 'D') {
                    // Determine if dropdown
                    const ddValues: DynamicDropDownValues[] = (
                        this.dynamicDropdownValues || []
                    ).filter((d) => d.field === f.mechFieldName);

                    let formField: FormlyFieldConfig;

                    if (!ddValues || !ddValues.length) {
                        formField = this.formlyFieldCreatorService.createAlphnumericFormField(f);
                    } else if (ddValues.length === 1 && ddValues[0].valueDisplay === 'LOOK UP') {
                        // TODO: Need to convert this to a lookup
                        formField = this.formlyFieldCreatorService.createAlphnumericFormField(f);
                    } else {
                        formField = this.formlyFieldCreatorService.createSelectFormField(
                            f,
                            ddValues,
                        );
                    }

                    if (f.requiredMechField) {
                        requiredExpressions.push({
                            id: f.requiredMechField,
                            value: `!!model.${f.mechFieldName}`,
                        });
                    }

                    formlyFields.push(formField);
                }
            });

            requiredExpressions.forEach((e) => {
                const fieldIndex: number = formlyFields.findIndex((f) => f.key === e.id);
                if (fieldIndex > -1) {
                    formlyFields[fieldIndex].expressionProperties['templateOptions.required'] =
                        e.value;
                } else {
                    dictDependencies.push({ key: e.id, value: e.value });
                }
            });

            this.dependenciesChange.emit(dictDependencies);
        }

        this.fields = [
            {
                fieldGroupClassName: 'row',
                fieldGroup: [...formlyFields],
            },
        ];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createModel(): any {
        const generatedModel = {};

        if (this.compValues && this.compValues.length) {
            this.compValues.forEach((v) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                generatedModel[v.mechField] = v.mechValue;
            });
        }

        return generatedModel;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    onModelChanged(event: any): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.modelChange.emit({ value: event, isValid: this.compForm.valid });
    }
}
