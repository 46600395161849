import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as pbi from 'powerbi-client';

const reportContainer: HTMLElement = document.getElementById('reportContainer');

@Component({
    selector: 'app-bds-power-bi-embed',
    templateUrl: './bds-power-bi-embed.component.html',
    styleUrls: ['./bds-power-bi-embed.component.scss'],
})
export class BdsPowerBiEmbedComponent implements OnInit {
    public screenHeight: number;
    //@ViewChild('reportContainer') reportContainer: ElementRef;

    constructor(private http: HttpClient) {}

    showReport(accessToken) {
        let reportContainer = document.getElementById('reportContainer');
        // Embed URL
        let embedUrl =
            'https://api.powerbi.com/' +
            '01577e4f-6811-417f-b450-a064aa4445fc' +
            '&groupId=' +
            'bc94ac43-430a-4f9e-b1a3-de399e39a815';
        let embedReportId = '01577e4f-6811-417f-b450-a064aa4445fc';
        let config = {
            type: 'report',
            accessToken: accessToken,
            embedUrl: embedUrl,
            id: embedReportId,
            settings: {},
        };
        let powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory,
        );
        let report = powerbi.embed(reportContainer, config);
        report.off('loaded');
        report.on('loaded', () => {
            console.log('Loaded');
        });
        report.on('error', () => {
            this.getToken();
        });
    }

    getToken() {
        var result = this.http.get<string>('/api/Dashboard');
        return result;
        //return embedToken.Token
    }

    ngOnInit() {
        this.screenHeight = window.screen.height;
        var token = this.getToken();
        this.showReport(token);
    }
}
