<dx-data-grid
    bds-grid
    #bdsCityAliasGrid
    [bdsUseZeroPadDates]="true"
    id="bdsCityAliasGrid"
    [dataSource]="gridSource"
    height="100%"
    width="100%"
    minHeight="200px"
    [(selectedRowKeys)]="selectedRowKeys"
    [bdsCustomMenuItems]="customButtons"
>
    <dxo-selection
        mode="multiple"
        [allowSelectAll]="false"
        showCheckBoxesMode="none"
    ></dxo-selection>
    <dxi-column dataField="locCity" caption="Preferred City Spelling"></dxi-column>
    <dxi-column dataField="locState" caption="Preferred State"></dxi-column>
    <dxi-column dataField="aliasCity" caption="Variant City Spelling"></dxi-column>
    <dxi-column dataField="aliasState" caption="Variant State"></dxi-column>
</dx-data-grid>
<!-- <div>
    <button mat-fab color="primary" (click)="createCityAlias()" style="position: absolute; right: 16px; bottom: 16px;">
        <fa-icon [icon]="iconPlus"></fa-icon>
    </button>
</div> -->
