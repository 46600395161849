/* eslint-disable max-len */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RtCarHotlistModule } from './../rt-car-hotlist/rt-car-hotlist.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';

import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';

import { RailtracClmModule } from './../railtrac-clm/railtrac-clm.module';
import { RailtracTripModule } from './../railtrac-trip/railtrac-trip.module';
import { BdsRouteModule } from '@bds/route';
import { ParticipatingRailroadModule } from '@bds/participating-railroad';

import { RtDestinationAliasDialogComponent } from './rt-destination-alias-dialog/rt-destination-alias-dialog.component';

import { RtJeopardizedShipmentReasonBaseComponent } from './reasons/rt-jeopardized-shipment-reason-base.component';
import { RtJeopardizedShipmentBadOrderedComponent } from './reasons/rt-jeopardized-shipment-bad-ordered/rt-jeopardized-shipment-bad-ordered.component';
import { RtJeopardizedShipmentDestDiffComponent } from './reasons/rt-jeopardized-shipment-dest-diff/rt-jeopardized-shipment-dest-diff.component';
import { RtJeopardizedShipmentEtaVarianceComponent } from './reasons/rt-jeopardized-shipment-eta-variance/rt-jeopardized-shipment-eta-variance.component';
import { RtJeopardizedShipmentLoadEmptyComponent } from './reasons/rt-jeopardized-shipment-load-empty/rt-jeopardized-shipment-load-empty.component';
import { RtJeopardizedShipmentMismatchedStatusComponent } from './reasons/rt-jeopardized-shipment-mismatched-status/rt-jeopardized-shipment-mismatched-status.component';
import { RtJeopardizedShipmentMultipleOpenComponent } from './reasons/rt-jeopardized-shipment-multiple-open/rt-jeopardized-shipment-multiple-open.component';
import { RtJeopardizedShipmentNeverShippedComponent } from './reasons/rt-jeopardized-shipment-never-shipped/rt-jeopardized-shipment-never-shipped.component';
import { RtJeopardizedShipmentNoBillComponent } from './reasons/rt-jeopardized-shipment-no-bill/rt-jeopardized-shipment-no-bill.component';
import { RtJeopardizedShipmentNonMovingComponent } from './reasons/rt-jeopardized-shipment-non-moving/rt-jeopardized-shipment-non-moving.component';
import { RtJeopardizedShipmentOffRouteComponent } from './reasons/rt-jeopardized-shipment-off-route/rt-jeopardized-shipment-off-route.component';
import { RtJeopardizedShipmentDetailsComponent } from './rt-jeopardized-shipment-details/rt-jeopardized-shipment-details.component';
import { RtJeopardizedShipmentUnknownComponent } from './reasons/rt-jeopardized-shipment-unknown/rt-jeopardized-shipment-unknown.component';
//import { RtTripCommentDialogComponent } from '../rt-trip-comment/rt-trip-comment-dialog/rt-trip-comment-dialog.component';
import { RtJeopardizedShipmentReasonCommonComponent } from './reasons/rt-jeopardized-shipment-reason-common.component';
import { RtJeopardizedShipmentReasonComponent } from './rt-jeopardized-shipment-reason/rt-jeopardized-shipment-reason.component';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { RtJeopardizedShipmentReasonOptionsComponent } from './reasons/rt-jeopardized-shipment-reason-options/rt-jeopardized-shipment-reason-options.component';
import { MatMenuModule } from '@angular/material/menu';
import { BourqueCoreModule } from '@bds/core';
import { JeopardizedShipmentScreenComponent } from './jeopardized-shipment-screen/jeopardized-shipment-screen.component';
import { DxDataGridModule } from 'devextreme-angular';
import { BdsDxDataGridModule } from '@bds/components';
import { BdsFastracModule } from '@bds/fastrac';
import { BdsGridDetailsModule } from '@bds/components';

@NgModule({
    declarations: [
        RtDestinationAliasDialogComponent,
        RtJeopardizedShipmentReasonBaseComponent,
        RtJeopardizedShipmentBadOrderedComponent,
        RtJeopardizedShipmentDestDiffComponent,
        RtJeopardizedShipmentEtaVarianceComponent,
        RtJeopardizedShipmentLoadEmptyComponent,
        RtJeopardizedShipmentMismatchedStatusComponent,
        RtJeopardizedShipmentMultipleOpenComponent,
        RtJeopardizedShipmentNeverShippedComponent,
        RtJeopardizedShipmentNoBillComponent,
        RtJeopardizedShipmentNonMovingComponent,
        RtJeopardizedShipmentOffRouteComponent,
        RtJeopardizedShipmentDetailsComponent,
        RtJeopardizedShipmentUnknownComponent,
        RtJeopardizedShipmentReasonCommonComponent,
        RtJeopardizedShipmentReasonComponent,
        RtJeopardizedShipmentReasonOptionsComponent,
        JeopardizedShipmentScreenComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        BourqueCoreModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatExpansionModule,
        MatToolbarModule,
        MatTabsModule,
        MatDividerModule,
        MatMenuModule,
        DxLookupModule,
        DxSelectBoxModule,
        FontAwesomeModule,
        RailtracTripModule,
        RailtracClmModule,
        RtCarHotlistModule,
        BdsRouteModule,
        ParticipatingRailroadModule,
        DxDataGridModule,
        BdsDxDataGridModule,
        BdsFastracModule,
        BdsGridDetailsModule,
    ],
    exports: [
        RtDestinationAliasDialogComponent,
        RtJeopardizedShipmentDetailsComponent,
        RtJeopardizedShipmentReasonComponent,
        JeopardizedShipmentScreenComponent,
    ],
})
export class RtJeopardizedShipmentModule {}
