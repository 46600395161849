import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RtSupplierFact, RtSupplierFactAdapter } from '@bds/railtrac-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import DataSource from 'devextreme/data/data_source';

export abstract class BdsSupplierApiServiceOptions {
    apiUrl: string;
    odataUrl: string;
}

@Injectable({
    providedIn: 'root',
})
export class BdsSupplierService {
    constructor(
        private http: HttpClient,
        private options: BdsSupplierApiServiceOptions,
        public adapter: RtSupplierFactAdapter,
    ) {}

    read(ormId: number): Observable<RtSupplierFact> {
        return this.readViaOdata(ormId);
    }

    readViaApi(ormId: number): Observable<RtSupplierFact> {
        return this.http.get<RtSupplierFact>(`${this.options.apiUrl}/${ormId}`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            //retry(3),
            catchError(this.handleError),
        );
    }

    readViaOdata(ormId: number): Observable<RtSupplierFact> {
        return this.http.get<RtSupplierFact>(`${this.options.odataUrl}(${ormId})`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            //retry(3),
            catchError(this.handleError),
        );
    }

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtSupplierFact>('code'),
        ).server;

        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: keyField,
        });
    }

    create(trip: RtSupplierFact): Observable<RtSupplierFact> {
        console.warn('RailtracTripService.createTrip() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    update(trip: RtSupplierFact): Observable<RtSupplierFact> {
        console.warn('RailtracTripService.updateTrip() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    delete(trip: RtSupplierFact): Observable<RtSupplierFact> {
        console.warn('RailtracTripService.deleteTrip() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    patch(trip: RtSupplierFact): Observable<RtSupplierFact> {
        console.warn('RailtracTripService.patchTrip() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    getDataSource(): DataSource {
        return new DataSource({
            store: this.getODataStore(),
            map: (item) => {
                return item;
            },
        });
    }

    getSupplierByCode(code: string): Observable<RtSupplierFact[]> {
        return this.http.get(`${this.options.odataUrl}?$filter=code eq '${code}'`).pipe(
            map((response) => response['value'].map((data) => this.adapter.adapt(data))),
            shareReplay(),
            catchError((err) => this.handleError(err)),
        );
    }

    // https://angular.io/guide/http#getting-error-details
    handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);

            // return an observable with a user-facing error message
            return throwError(() => new Error('A browser or network error occurred'));
        } else if (error.status == 404) {
            // return an observable with a user-facing error message
            return throwError(() => new Error('Not Found'));
        } else if (error.status == 422) {
            console.error('Found but cannot be used');
            return throwError(() => new Error(error.error));
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}`, error);

            // return an observable with a user-facing error message
            return throwError(() => new Error('The server returned an unknown error'));
        }
    }
}
