import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RtArrAtOrig, RtArrAtOrigAdapter } from '@bds/railtrac-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import DataSource from 'devextreme/data/data_source';

export abstract class BdsOriginCriteriaApiServiceOptions {
    apiUrl = `api/OriginCriteria/`;
    odataUrl = `odata/origincriteria`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsOriginCriteriaService {
    constructor(
        private http: HttpClient,
        private options: BdsOriginCriteriaApiServiceOptions,
        public adapter: RtArrAtOrigAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    create(origin: RtArrAtOrig): Observable<RtArrAtOrig> {
        return this.http
            .post<RtArrAtOrig>(`${this.options.apiUrl}`, this.adapter.toServer(origin))
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    delete(key: number): Observable<RtArrAtOrig> {
        return this.http.delete<RtArrAtOrig>(`${this.options.apiUrl}/${key}`).pipe(
            map((data: any) => {
                return !!data ? this.adapter.adapt(data) : null;
            }),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    getDataSource(): DataSource {
        return new DataSource({
            store: this.getODataStore(),
            paginate: true,
            pageSize: 20,
        });
    }

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtArrAtOrig>('ormId'),
        ).server;

        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: keyField,
        });
    }

    patch(origin: RtArrAtOrig): Observable<RtArrAtOrig> {
        console.warn('OriginCriteriaService.patch() is a dummy method');
        return of(origin).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    read(key: number, expand?: string[]): Observable<RtArrAtOrig> {
        return this.readViaOdata(key, expand);
    }

    readViaApi(key: number): Observable<RtArrAtOrig> {
        return this.http.get<RtArrAtOrig>(`${this.options.apiUrl}/${key}`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    readViaOdata(key: number, expand?: string[]): Observable<RtArrAtOrig> {
        let expandUrl = '';
        if (!!expand && expand.length > 0) {
            expandUrl = '?$expand=';
            expand.forEach((child) => (expandUrl = expandUrl + child + ','));
            expandUrl = expandUrl.slice(0, expandUrl.length - 1);
        }

        return this.http.get<RtArrAtOrig>(`${this.options.odataUrl}(${key})` + expandUrl).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    update(key: number, origin: RtArrAtOrig): Observable<RtArrAtOrig> {
        return this.http
            .put<RtArrAtOrig>(`${this.options.apiUrl}/${key}`, this.adapter.toServer(origin))
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getByErpcCode(originCode: string): Observable<RtArrAtOrig[]> {
        if (originCode) {
            const filter = `$filter=originCode eq '${originCode}'`;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return this.http.get<any>(`${this.options.odataUrl}?${filter}`).pipe(
                map((response) => response['value'].map((data) => this.adapter.adapt(data))),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
        } else {
            return of(null);
        }
    }
}
