import { FormGroup, FormControl } from '@angular/forms';
import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    RtRouteParticipatingRailroad,
    RtRouteParticipatingRailroadMetadata,
} from '@bds/railtrac-models';
import DataSource from 'devextreme/data/data_source';
import { Observable } from 'rxjs';
import { BdsRouteParticipatingRailroadService } from '../../services/bds-route-participating-railroad.service';

export class RtRouteParticipatingRailroadDialogOptions {
    public item?: RtRouteParticipatingRailroad;
    public isReadOnly?: boolean;
    public affectedTripCount?: number;
    public affectedTrips?: Observable<number> | number;
    public railroadDataSource?: DataSource;
    public routeCodeDataSource?: DataSource;
}

@Component({
    templateUrl: './bds-route-participating-railroad-dialog.component.html',
    styleUrls: ['./bds-route-participating-railroad-dialog.component.scss'],
})
export class BdsRouteParticipatingRailroadDialogComponent {
    public participatingRailroad: RtRouteParticipatingRailroad;
    public participatingRailroadForm: FormGroup;
    public title: string;
    public isInitiallyBlank: boolean;

    @Input() dense = true;
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';

    get dxMatStyle(): string {
        switch (this.matStyle) {
            case 'fill':
                return 'filled';
            case 'outline':
                return 'outlined';
            case 'standard':
                return 'underlined';
            default:
                return 'underlined';
        }
    }

    constructor(
        public dialogRef: MatDialogRef<
            BdsRouteParticipatingRailroadDialogComponent,
            RtRouteParticipatingRailroad
        >,
        @Inject(MAT_DIALOG_DATA) public data: RtRouteParticipatingRailroadDialogOptions,
        public metadata: RtRouteParticipatingRailroadMetadata,
    ) {
        this.title = 'Add New Participating Railroad';
        this.isInitiallyBlank = !data.item || !data.item.railroad || !data.item.routeCode;
        this.participatingRailroad = data.item || new RtRouteParticipatingRailroad();
        this.participatingRailroadForm = metadata.getFormGroup(this.participatingRailroad);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    getErrorMessage(control: FormControl): string {
        if (control.hasError('required')) {
            return 'Required';
        }

        return control.hasError('maxLength') ? 'Too long' : '';
    }
}
