import { Inject, Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';

@Injectable({
  providedIn: 'root'
})
export class RtEdiInfoService {

    controllerName = 'rtvEdiInfo';

    constructor(
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,        
    ) { }

    getEDIDataSource(tripId: number): DataSource {
        return new DataSource({
            store: new ODataStore({
                version: 4,
                url: `${this.odatabaseUrl}${this.controllerName}(${tripId})`,                
            }),
            paginate: true,
            pageSize: 8,
        });
    }
}
