import { Component } from '@angular/core';
import { PageService } from '@bds/core';

@Component({
    selector: 'app-hotlist-screen',
    template: ` <router-outlet></router-outlet>`,
})
export class HotlistScreenComponent {
    constructor(private pageService: PageService) {
        this.pageService.setHeader('Hotlist');
    }
}
