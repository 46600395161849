import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';
import { EquipmentCategory, EquipmentCategoryWithFields } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class EquipmentCategoryService {
    controllerName = 'EquipmentCategory';

    constructor(
        private http: HttpClient,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    getAll(): Observable<EquipmentCategory[]> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
        return this.http.get<any>(`${this.odatabaseUrl}${this.controllerName}`).pipe(
            map((data) =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                data.value.map((i) => {
                    const adapt = i as EquipmentCategory;
                    adapt.categoryDscr = (adapt.categoryDscr || '').toUpperCase();
                    return adapt;
                }),
            ),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    getCatsAndFields(filter: string[]): Observable<EquipmentCategoryWithFields[]> {
        // https://localhost/odata/EquipmentCategory?
        //           $filter=CategoryName in ('cat1','cat2')&$expand=EquipCatgMechFields($orderby=Priority;$expand=MechFieldNavigation)
        if (filter && filter.length) {
            let cats = '';
            filter.forEach((f) => {
                cats = cats + `'` + f + `',`;
            });

            if (cats.length) {
                cats = cats.slice(0, cats.length - 1);
            }

            const filterUrl = `?$filter=CategoryName in (${cats})`;
            const expandUrl = `&$expand=EquipCatgMechFields($orderby=Priority;$expand=MechFieldNavigation($filter=fieldType eq 'E'))`;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
            return (
                this.http
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .get<any>(`${this.odatabaseUrl}${this.controllerName}${filterUrl}${expandUrl}`)
                    .pipe(
                        map((data) =>
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                            data.value.map((cat) => this.convertCategoryToWithFields(cat)),
                        ),
                        shareReplay(),
                        catchError((err) => this.apiErrorHandler.handleError(err)),
                    )
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return of(null);
        }
    }

    convertCategoryToWithFields(cat: EquipmentCategory): EquipmentCategoryWithFields {
        return <EquipmentCategoryWithFields>{
            categoryName: cat.categoryName,
            categoryDscr: cat.categoryDscr,
            transitMode: cat.transitMode,
            mechFields: cat.equipCatgMechFields.map((f) => f.mechFieldNavigation),
        };
    }
}
