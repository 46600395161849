import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, Validators, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { RtTripUK, RtMiscComment, RtCommentType, RtTripComment } from '@bds/railtrac-models';
import { Observable } from 'rxjs/internal/Observable';
import { MatSelectChange } from '@angular/material/select';
import { nameof } from '@bds/core';
import { RtTripCommentService } from '@bds/data-access';

export class TripCommentDialogData {
    maxLength?: number;
    tripUKs: RtTripUK[];
}

@Component({
    templateUrl: './rt-trip-comment-dialog.component.html',
    styleUrls: ['./rt-trip-comment-dialog.component.scss'],
})
export class RtTripCommentDialogComponent implements OnInit, OnDestroy {
    maxLength: number | null;
    tripComments: UntypedFormArray;
    controls: { key: RtTripUK; control: UntypedFormGroup; expanded: boolean }[];
    commentTypes: Observable<RtCommentType[]>;
    defaultComments: Observable<RtMiscComment[]>;

    data: RtTripComment;
    previousComment: RtTripComment;

    constructor(
        public dialogRef: MatDialogRef<RtTripCommentDialogComponent, RtTripComment[]>,
        @Inject(MAT_DIALOG_DATA) public options: TripCommentDialogData,
        public service: RtTripCommentService,
    ) {
        this.maxLength = !!options.maxLength ? options.maxLength : 255;
        this.controls = options.tripUKs.map((m) => {
            const ret = {
                key: m,
                control: new UntypedFormGroup({}),
                expanded: false,
            };
            ret.control.addControl(
                nameof<RtTripComment>('comments'),
                new UntypedFormControl('', [
                    Validators.required,
                    Validators.maxLength(this.maxLength),
                ]),
            );
            ret.control.addControl(
                nameof<RtTripComment>('transmitToCust'),
                new UntypedFormControl(false, []),
            );
            ret.control.addControl(
                nameof<RtTripComment>('commentType'),
                new UntypedFormControl('', []),
            );
            ret.control.addControl(
                nameof<RtTripComment>('commentCode'),
                new UntypedFormControl('', []),
            );
            return ret;
        });
    }

    ngOnInit() {
        this.commentTypes = this.service.getCommentTypes();
        this.previousComment = new RtTripComment();
    }

    ngOnDestroy() {}

    valueChanged($event) {
        console.log('~~~~~~~~~ valueChanged', $event);
    }
    selectedValueChanged($event) {
        console.log('~~~~~~~~~ selectedValueChanged', $event);
    }

    getDefaultComments($event: MatSelectChange) {
        console.log('~~~~~~~~~ getDefaultComments', $event);
        // console.log('running to get default comments with $event:', key);
        // this.form.controls[nameof<RtTripComment>('comments')].setValue('');
        // if (!!key) {
        //   this.defaultComments = this.service.getDefaultCommentsForType(key);
        // }
        // else {
        //   this.defaultComments = of([]);
        // }
    }
    setDefaultComment($event: MatSelectChange) {
        console.log('~~~~~~~~~ setDefaultComment', $event);
        // this.form.controls[nameof<RtTripComment>('comments')].setValue(comment);
    }

    commentChanged($event: RtTripComment) {
        this.controls.forEach((ctrl) => {
            if ($event.comments !== this.previousComment.comments) {
                ctrl.control.controls['comments'].setValue($event.comments);
            }
            if ($event.transmitToCust !== this.previousComment.transmitToCust) {
                ctrl.control.controls['transmitToCust'].setValue($event.transmitToCust);
            }
            if ($event.commentType !== this.previousComment.commentType) {
                ctrl.control.controls['commentType'].setValue($event.commentType);
            }
            if ($event.commentCode !== this.previousComment.commentCode) {
                ctrl.control.controls['commentCode'].setValue($event.commentCode);
            }
        });
        this.previousComment = $event;
    }
    onNoClick() {
        this.dialogRef.close();
    }

    onYesClick() {
        const commentDate = new Date();
        const result = this.controls.map(
            (item) =>
                new RtTripComment(
                    item.key.carInit,
                    item.key.carNo,
                    item.key.shipDatetime,
                    commentDate,
                    null,
                    item.control.controls[nameof<RtTripComment>('comments')].value,
                    item.control.controls[nameof<RtTripComment>('transmitToCust')].value
                        ? 'Y'
                        : 'N',
                    item.control.controls[nameof<RtTripComment>('commentType')].value,
                    item.control.controls[nameof<RtTripComment>('commentCode')].value,
                ),
        );

        this.dialogRef.close(result);
    }
}
