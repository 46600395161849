<!-- <div class="alert alert-danger" role="alert">
    The reason code '{{shipment.reason}}' was not recognized.
</div>
 -->
<div class="sticky-top mat-elevation-z6">
    <mat-toolbar class="trip-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <span>
                <!-- <fa-icon [icon]="iconFlag" [fixedWidth]="true" size="lg"></fa-icon> -->
                {{ shipment.reason }}
            </span>
            <span class="fill-remaining-space"></span>
            <span>
                <rt-jeopardized-shipment-reason-options
                    (resolve)="onResolve($event)"
                ></rt-jeopardized-shipment-reason-options>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<rt-jeopardized-shipment-reason-common
    [trip]="trip$ | async"
    [useExpansionPanel]="false"
    (saveClick)="onTripSaveClicked($event)"
    (popoutTrip)="onPopoutTripclicked($event)"
></rt-jeopardized-shipment-reason-common>
<ng-template #loading> Loading... </ng-template>
