<h1 mat-dialog-title>{{ data.title }}</h1>
<div class="row">
    <div *ngIf="errors && errors.length" class="error-warning">
        <div *ngFor="let error of errors">{{ error.errorMessage }}</div>
    </div>
</div>
<div mat-dialog-content style="width: 1500px">
    <ng-container *ngIf="data.comments?.length > 1">
        <rt-multi-comment-form-item
            isBulkForm="true"
            [formGroup]="bulkForm"
        ></rt-multi-comment-form-item>
    </ng-container>

    <mat-expansion-panel *ngIf="data.comments?.length > 1; else formTemplate" #expansionPanel>
        <mat-expansion-panel-header>
            <mat-panel-title> Set Individual Comment </mat-panel-title>
        </mat-expansion-panel-header>
        <div
            class="scrollable-volume"
            [ngStyle]="{ display: expansionPanel.expanded ? 'inherit' : 'none' }"
        >
            <ng-container
                *ngFor="let item of data.comments; let i = index; let l = last; let f = first"
            >
                <ng-container *ngIf="!f">
                    <br />
                </ng-container>
                <rt-multi-comment-form-item
                    [comment]="item"
                    [index]="i"
                ></rt-multi-comment-form-item>
                <ng-container *ngIf="!l">
                    <hr />
                </ng-container>
            </ng-container>
        </div>
    </mat-expansion-panel>
    <ng-template #formTemplate>
        <ng-container *ngIf="data.comments?.length === 1">
            <ng-container *ngFor="let item of data.comments; let i = index">
                <rt-multi-comment-form-item
                    [comment]="item"
                    [index]="i"
                ></rt-multi-comment-form-item>
            </ng-container>
        </ng-container>
    </ng-template>
</div>
<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" (click)="closeDialog()">Cancel</button>
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        (click)="save()"
        [disabled]="isSaving || !form.valid"
        cdkFocusInitial
    >
        Save
    </button>
</div>
