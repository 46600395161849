import { RtCommodityShippedUK } from '@bds/railtrac-models';
import { nameof, TwoWayAdapter } from '@bds/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorHandlerService, map_to_object } from '@bds/helpers';
import { Observable } from 'rxjs';
import ODataStore from 'devextreme/data/odata/store';
import Store from 'devextreme/data/abstract_store';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { RtCommodityShipped, RtCommodityShippedAdapter } from '@bds/railtrac-models';

export interface IApiServiceOptions {
    apiUrl: string;
    odataUrl: string;
    canCreate: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    canPatch: boolean;
}

export abstract class ServiceBase<TModel, TModelKey extends number | string> {
    constructor(
        protected http: HttpClient,
        protected options: IApiServiceOptions,
        public adapter: TwoWayAdapter<TModel>,
        protected apiErrorHandler: ApiErrorHandlerService,
    ) {}

    create(model: TModel): Observable<TModel> {
        if (!this.options.canCreate) {
            throw new Error('Create method not allowed');
        }

        return this.http.post<TModel>(`${this.options.apiUrl}`, this.adapter.toServer(model)).pipe(
            map((data: any) => {
                return !!data ? this.adapter.adapt(data) : null;
            }),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    read(id: TModelKey): Observable<TModel> {
        return this.readViaOData(id);
    }

    update(id: TModelKey, model: TModel): Observable<TModel> {
        if (!this.options.canUpdate) {
            throw new Error('Update method not allowed');
        }

        return this.http
            .put<TModel>(`${this.options.apiUrl}/${id}`, this.adapter.toServer(model))
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    delete(id: TModelKey): Observable<TModel> {
        if (!this.options.canDelete) {
            throw new Error('Delete method not allowed');
        }

        return this.http.delete(`${this.options.apiUrl}/${id}`).pipe(
            map((data: any) => {
                return !!data ? this.adapter.adapt(data) : null;
            }),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    patch(model: TModel): Observable<TModel> {
        if (!this.options.canPatch) {
            throw new Error('Patch method not allowed');
        }

        throw new Error('Patch not implemented yet');
    }

    abstract getStore(): Store;

    protected generateODataStore(keyField: string) {
        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: keyField,
        });
    }

    protected isString(id: number | string): id is string {
        return (id as string).length !== undefined;
    }

    protected isNumber(id: number | string): id is number {
        return !isNaN(id as number);
    }

    protected getIdForUrl(id: TModelKey) {
        if (this.isString(id)) {
            return `'${id}'`;
        }
        if (this.isNumber(id)) {
            return id;
        }
        return id;
    }

    protected readViaOData(id: TModelKey): Observable<TModel> {
        return this.http.get<TModel>(`${this.options.odataUrl}(${this.getIdForUrl(id)})`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    protected readViaApi(id: TModelKey): Observable<TModel> {
        return this.http.get<TModel>(`${this.options.apiUrl}/${id}`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export abstract class RtCommodityShippedApiServiceOptions implements IApiServiceOptions {
    apiUrl = `api/commodityshipped/`;
    odataUrl = `odata/commodityshipped`;
    canCreate = true;
    canUpdate = true;
    canDelete = true;
    canPatch = false;
    combinedApi = `api/uniquecommodityshipped`;
}

@Injectable({
    providedIn: 'root',
})
export class RtCommodityShippedService extends ServiceBase<RtCommodityShipped, number> {
    constructor(
        protected http: HttpClient,
        protected options: RtCommodityShippedApiServiceOptions,
        public adapter: RtCommodityShippedAdapter,
        protected apiErrorHandler: ApiErrorHandlerService,
    ) {
        super(http, options, adapter, apiErrorHandler);
    }

    getStore(): Store {
        return this.generateODataStore(nameof<RtCommodityShipped>('ormId'));
    }

    readCombined(
        tripOrmIds: number[],
    ): Observable<{ commoditiesShipped: RtCommodityShipped; keys: RtCommodityShippedUK[] }> {
        return this.http
            .get<{ commoditiesShipped: RtCommodityShipped; keys: RtCommodityShippedUK[] }>(
                `${this.options.combinedApi}`,
                {
                    params: {
                        id: tripOrmIds.join(','),
                    },
                },
            )
            .pipe(
                map((data: any) => {
                    return {
                        commoditiesShipped: this.adapter.adapt(data.commoditiesShipped),
                        keys: [...data.keys.map((m) => this.adapter.adapt(m))],
                    };
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    setCombined(ormIds: number[], changes: Map<string, any>) {
        const mappedObject = map_to_object(changes);
        const serverModel = this.adapter.toServer(mappedObject);

        return this.http
            .put<any>(`${this.options.combinedApi}`, serverModel, {
                params: {
                    id: ormIds.join(','),
                },
            })
            .pipe(
                map((data) => {
                    return (data as any[]).reduce((acc, item) => {
                        acc[item.Key] = { id: item.Key, ...item.Value };
                        return acc;
                    }, {});
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}
