<bds-destination-criteria-list
    [destinationStore]="destinations"
    [customerStore]="customers"
    [railroadStore]="railroads"
    [stateLookup]="stateLookup"
    [sightCodeLookup]="sightCodeLookup"
    [customerNumber]="customerNumber"
    [latestClm]="latestClm"
    (destinationDelete)="onDelete($event)"
    (destinationSaved)="onSaved($event)"
>
</bds-destination-criteria-list>
