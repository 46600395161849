import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDeleteDialogModel } from './confirm-delete-dialog.model';

@Component({
    selector: 'bds-confirm-delete-dialog',
    templateUrl: './confirm-delete-dialog.component.html',
    styleUrls: ['./confirm-delete-dialog.component.scss'],
})
export class ConfirmDeleteDialogComponent {
    actionText = 'REMOVE';
    content = 'Are you sure?';
    title = '';

    constructor(
        public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteDialogModel,
    ) {
        if (data.actionText) {
            this.actionText = data.actionText;
        }

        if (data.content) {
            this.content = data.content;
        }

        if (data.title) {
            this.title = data.title;
        }
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }
}
