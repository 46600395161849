<h4 *ngIf="!!data.title" mat-dialog-title>{{ data.title }}</h4>

<div mat-dialog-content>
    <bds-grid-details>
        <ng-container grid>
            <dx-data-grid
                bdsGridDetails
                bds-grid
                #targetDirective="bdsGridDirective"
                #dataGrid
                [bdsUseFastrac]="false"
                [bdsUseZeroPadDates]="true"
                id="bdsCustomerSearchGrid"
                [dataSource]="dataSource"
                [(selectedRowKeys)]="selectedRowKeys"
                [columnAutoWidth]="true"
            >
                <dxi-column dataField="code" caption="Supplier Code" dataType="string">
                </dxi-column>
                <dxi-column dataField="name" caption="Supplier Name" dataType="string">
                </dxi-column>
                <dxi-column dataField="city" caption="Origin City" dataType="string"> </dxi-column>
                <dxi-column dataField="state" caption="Origin State" dataType="string">
                </dxi-column>
            </dx-data-grid>
        </ng-container>
    </bds-grid-details>
</div>

<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" cdkFocusInitial mat-dialog-close>
        {{ data.dismissiveText }}
    </button>
    <button mat-button class="text-uppercase" color="accent" (click)="onSelectClick()">
        {{ data.actionText }}
    </button>
</div>
