import { Component, Inject } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import ODataStore from 'devextreme/data/odata/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RtArrAtOrig, RtStateCode, RtClmSightCode } from '@bds/railtrac-models';
import {
    BdsStateService,
    ClmSightCodeService,
    RtRailroadService,
    BdsErpcOriginService,
} from '@bds/data-access';
import { BdsOriginCriteriaDialogModel } from '../models/bds-origin-criteria-dialog.model';
import { BdsOriginCriteriaService } from '../bds-origin-criteria.service';

@Component({
    selector: 'bds-origin-criteria-dialog',
    templateUrl: './bds-origin-criteria-dialog.component.html',
    styleUrls: ['./bds-origin-criteria-dialog.component.scss'],
})
export class BdsOriginCriteriaDialogComponent {
    calculatedOriginCode: string = '';
    originCodeStore: ODataStore;
    // TODO: BE CAREFUL HERE!! These names are not consistent between models!!!
    originCriteria: RtArrAtOrig;
    errorMessage = '';
    isClone: boolean = false;
    isNew: boolean = true;
    isSaving: boolean = false;
    isValid: boolean = false;
    railroadStore: ODataStore;
    sightCodeLookup$: Observable<RtClmSightCode[]>;
    stateLookup$: Observable<RtStateCode[]>;
    editableOriginCode: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<BdsOriginCriteriaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BdsOriginCriteriaDialogModel,
        private originService: BdsOriginCriteriaService,
        private stateService: BdsStateService,
        private clmSightCodeService: ClmSightCodeService,
        private originCodeService: BdsErpcOriginService,
        private railroadService: RtRailroadService,
        private _snackbar: MatSnackBar,
    ) {
        this.isNew = data.isNew;
        this.isClone = data.isClone;
        this.getOriginCode();

        this.setOriginCriteria();

        this.originCodeStore = originCodeService.getODataStore();
        this.railroadStore = railroadService.getODataStore();
        this.stateLookup$ = stateService.getLookup();
        this.sightCodeLookup$ = clmSightCodeService.getAll();
    }

    getOriginCode() {
        if (this.data.returnCity && this.data.returnState) {
            this.originCodeService
                .getByCityState(this.data.returnCity, this.data.returnState)
                .pipe(take(1))
                .subscribe((oc) => {
                    // Had to put this as a separate function and call a second time
                    // Unable to use simple spread operator due to "from" property on RtArrAtOrig
                    if (oc && oc.originCode) {
                        // Already set as empty string otherwise
                        this.calculatedOriginCode = oc.originCode;
                        this.editableOriginCode = false;
                        this.setOriginCriteria();
                    }
                });
        } else {
            this.calculatedOriginCode = '';
        }
    }

    onCancelClick() {
        this.dialogRef.close();
    }

    onOriginCriteriaChanged(event: { value: RtArrAtOrig; isValid: boolean }) {
        this.originCriteria = event.value;
        this.isValid = event.isValid;
    }

    onSaveClick() {
        this.isSaving = true;
        this.errorMessage = '';

        // For now, just adding
        this.originService
            .create(this.originCriteria)
            .pipe(take(1))
            .subscribe(
                (orig) => {
                    this.afterSaveComplete('Origin Criteria Added');
                },
                (err) => this.afterError(err),
            );
    }

    setOriginCriteria() {
        this.originCriteria = new RtArrAtOrig(
            this.calculatedOriginCode,
            this.data.locationCity,
            this.data.sightCode,
            this.data.railroad,
            this.data.locationState,
            this.data.originDeliveryHours || 0,
        );
    }

    private afterError(error: any) {
        const customerErrors = [];

        if (typeof error === 'string') {
            this.errorMessage = error;
            customerErrors.push(error);
        } else if (Array.isArray(error) && error[0].validationMessage) {
            this.errorMessage = `Origin Criteria is invalid - ${error[0].validationMessage}`;
            error.forEach((e) => customerErrors.push(e));
        } else {
            this.errorMessage = 'An unknown error occurred';
        }

        this.isSaving = false;
        this._snackbar.open(this.errorMessage, 'Error', { duration: 3000 });
    }

    private afterSaveComplete(message?: string) {
        if (!message) {
            message = 'Changes saved!';
        }

        this.isSaving = false;
        this._snackbar.open(message, 'Ok', { duration: 3000 });
        this.dialogRef.close();
    }
}
