import { FormGroup, FormControl } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    RtRouteParticipatingRailroad,
    RtRouteParticipatingRailroadMetadata,
} from '@bds/railtrac-models';
import { nameof } from '@bds/core';

export class RtRouteParticipatingRailroadDialogOneOptions {
    public item?: RtRouteParticipatingRailroad;
}

@Component({
    templateUrl: './bds-route-participating-railroad-dialog-one.component.html',
    styleUrls: ['./bds-route-participating-railroad-dialog-one.component.scss'],
})
export class BdsRouteParticipatingRailroadDialogOneComponent {
    public participatingRailroad: RtRouteParticipatingRailroad;
    public participatingRailroadForm: FormGroup;
    public title: string;

    constructor(
        public dialogRef: MatDialogRef<
            BdsRouteParticipatingRailroadDialogOneComponent,
            RtRouteParticipatingRailroad
        >,
        @Inject(MAT_DIALOG_DATA) public data: RtRouteParticipatingRailroadDialogOneOptions,
        public metadata: RtRouteParticipatingRailroadMetadata,
    ) {
        this.title = 'Add New Participating Railroad';
        this.participatingRailroad = data.item || new RtRouteParticipatingRailroad();
        this.participatingRailroadForm = metadata.getFormGroup(this.participatingRailroad);
        this.routeCode.disable();
    }

    get routeCode(): FormControl {
        return <FormControl>(
            this.participatingRailroadForm.get(nameof<RtRouteParticipatingRailroad>('routeCode'))
        );
    }

    getErrorMessage(control: FormControl): string {
        if (control.hasError('required')) {
            return 'Required';
        }

        return control.hasError('maxLength') ? 'Too long' : '';
    }

    onSaveClick() {
        this.dialogRef.close(this.participatingRailroadForm.getRawValue());
    }
}
