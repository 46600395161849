import { Component, OnInit, Inject, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FastracDialogData } from '../fastrac-management-screen.component';
import { UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
    templateUrl: './fastrac-management-dialog-inputbox.component.html',
    styleUrls: ['./fastrac-management-dialog-inputbox.component.scss'],
})
export class FastracManagementDialogInputboxComponent implements OnInit, AfterViewInit {
    @ViewChild('dialogInput', { static: true }) dialogInput: ElementRef;
    control: UntypedFormControl;
    maxLength: number | null;

    constructor(
        public dialogRef: MatDialogRef<FastracManagementDialogInputboxComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FastracDialogData,
    ) {
        this.maxLength = !!data.maxLength ? data.maxLength : null;
    }

    ngOnInit() {
        this.control = new UntypedFormControl(this.data.value, [
            Validators.required,
            Validators.maxLength(100),
            this.duplicateNameValidator(this.data.value),
        ]);
    }

    ngAfterViewInit(): void {
        // this.dialogInput.nativeElement.select();
    }

    onNoClick() {
        this.dialogRef.close();
    }

    duplicateNameValidator(name: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const duplicate = name.toLowerCase() === control.value.toLowerCase();
            return duplicate ? { duplicateName: { value: control.value } } : null;
        };
    }
}
