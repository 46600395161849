import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { bdsGridModeType } from '@bds/components';

import { CommunicationMessage, CommunicationService } from '@bds/core';
import { FastracDxGridWrapperComponent, FastracScreenBaseComponent } from '@bds/fastrac';
import { CarIdTransformService } from '@bds/helpers';

import { RailtracTripService, RtTripRefFieldDefService } from '@bds/railtrac';

import { RtTrip, RtTripRefFieldDef } from '@bds/railtrac-models';

import { FastracColsService } from '@bds/services';

import { faBars } from '@fortawesome/pro-solid-svg-icons';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PageService } from '@bds/core';
import { CreateTripService } from '@bds/railtrac';
import { BdsDialogMultiConfirmComponent, BdsDialogMultiConfirmModel } from '@bds/smart-components';
import { ErrorListItem } from '@bds/rt-advance-models';
import { ErrorHandlerService } from '@bds/railtrac-internal-services';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'app-trip-screen',
    templateUrl: './trip-screen.component.html',
    styleUrls: ['./trip-screen.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TripScreenComponent extends FastracScreenBaseComponent implements OnInit, OnDestroy {
    @ViewChild('dxGridWrapper') fastracGridComponent: FastracDxGridWrapperComponent;

    public gridSource: DataSource;
    public allowModeToggle = true;
    public selectedRowKeys: Array<number> = [];
    public selectedRowData: Array<any> = [];
    public gridId = 1;
    public gridMode: bdsGridModeType = 'multiple';
    public gridStore: ODataStore;

    public isCreatingNew = false;
    public newTrip: RtTrip;

    public iconMenu = faBars;

    public tripRefFieldDefs$: Observable<RtTripRefFieldDef[]>;

    isDirty = false;
    errors: ErrorListItem[] = [];

    private ngUnsubscribe$: Subject<boolean> = new Subject<boolean>();

    get grid() {
        return this.fastracGridComponent?.grid;
    }

    constructor(
        public tripService: RailtracTripService,
        public dialog: MatDialog,
        public router: Router,
        public route: ActivatedRoute,
        pageService: PageService,
        public snackbar: MatSnackBar,
        public gridColumnService: FastracColsService,
        public refFieldDefService: RtTripRefFieldDefService,
        public carIdTransformService: CarIdTransformService,
        public communicationService: CommunicationService,
        @Inject('tripGridApiServiceOptions')
        private tripGridApiServiceOptions: { odataUrl: string },
        private _createTripService: CreateTripService,
        private errorHandler: ErrorHandlerService,
        private clipboard: Clipboard,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);

        pageService.setHeader('Shipments');

        communicationService
            .getData()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((m) => {
                const message: CommunicationMessage = m as CommunicationMessage;
                if (message && message.subscriberName === 'TripScreen' && message.data) {
                    if (this.grid && this.grid.instance) {
                        void this.grid.instance.refresh();
                    }
                    // TODO: The following should work and prevent recalling the API. However, not all
                    // fields are updated, so using the quick way for now until DevExpress can explain this.
                    // const gridAdaptedData = this.adapter.adapt(message.data);
                    // if (this.gridStore) {
                    //     this.gridStore.push([{ type: 'update', data: gridAdaptedData, key: message.data['ormId'] }]);
                    // }
                }
            });

        this.gridSource = this.getODataTripApiSource();

        this.tripRefFieldDefs$ = refFieldDefService.getReferenceFieldDefs();

        this._createTripService.createTripChangeEmitted$.subscribe((x) => {
            this.createRecord();
        });

        this._createTripService.refreshTripGridChangeEmitted$.subscribe((x) => {
            this.refreshGrid();
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.grid?.instance.repaint();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.ngUnsubscribe$.next(true);
        this.ngUnsubscribe$.unsubscribe();
    }

    onDirty(): void {
        this.isDirty = true;
    }

    getODataTripApiSource(): DataSource {
        this.gridStore = new ODataStore({
            version: 4,
            url: this.tripGridApiServiceOptions.odataUrl,
            key: 'ormId',
            deserializeDates: false,
        });

        const ds = new DataSource({
            store: this.gridStore,
            reshapeOnPush: true,
            paginate: true,
            pageSize: 20,
        });

        return ds;
    }

    refreshGrid(): void {
        void this.grid.instance.refresh();
    }

    createRecord(): void {
        void this.router.navigate(['trips/create'], { queryParamsHandling: 'preserve' });
    }

    deleteTrips(): void {
        this.selectedRowData = this.grid.instance.getSelectedRowsData();
        if (this.selectedRowData == null || this.selectedRowData.length == 0) {
            return;
        }

        let promptFreeRunner = false;
        let freeRunnerTrips = [];
        if (this.selectedRowData) {
            this.selectedRowData.map((eachTrip) => {
                if (eachTrip.isFreeRunner == 'Y' && eachTrip.tripStatus == 'O') {
                    promptFreeRunner = true;
                    freeRunnerTrips.push(eachTrip);
                }
            });
        }
        let confirmInfo: BdsDialogMultiConfirmModel = {
            title: 'Delete Confirmation',
            content: `Are you sure you want to delete ${this.selectedRowKeys.length} trip(s)?`,
            actionText: 'Delete',
        };
        if (promptFreeRunner) {
            confirmInfo = {
                title: 'Delete Confirmation',
                content: 'Continue receiving CLMs after deleting Open Free Runner trip(s)?',
                actionText: 'Yes',
                actionTwoText: 'No',
                showActionTwo: true,
            };
        }

        const dialogRef = this.dialog.open(BdsDialogMultiConfirmComponent, {
            data: confirmInfo,
        });

        dialogRef.beforeClosed().subscribe((obs) => {
            if (obs) {
                let partialTripInfo = this.selectedRowData.map(
                    ({ carInit, carNo, ormId, isFreeRunner, tripStatus }) => ({
                        carInit,
                        carNo,
                        ormId,
                        isFreeRunner,
                        tripStatus,
                    }),
                );
                let deleteFreeRunner = obs == 'No' ? true : false;
                this.tripService
                    .deleteTrips(partialTripInfo, deleteFreeRunner)
                    // .pipe(
                    //     tap({
                    //         next: (response) => {
                    //             let errorlist = [];
                    //             response.map((eachResult) => {
                    //                 if (!eachResult.succeeded) {
                    //                     this.errorHandler.parseResult(eachResult).subscribe({
                    //                         error: (err) => {
                    //                             errorlist = errorlist.concat(err);
                    //                         },
                    //                     });
                    //                 }
                    //             });
                    //             if (errorlist.length > 0) {
                    //                 this.afterError(errorlist);
                    //             } else {
                    //                 this.afterSaveComplete();
                    //                 void this.grid.instance.refresh();
                    //             }
                    //         },
                    //         error: (err) => {
                    //             this.afterError(err);
                    //             //return EMPTY;
                    //         },
                    //     }),
                    //     catchError((err) => {
                    //         this.afterError(err);
                    //         return EMPTY;
                    //     })
                    // )
                    .subscribe({
                        next: (response) => {
                            let errorlist = [];
                            response.map((eachResult) => {
                                if (!eachResult.succeeded) {
                                    this.errorHandler.parseResult(eachResult).subscribe({
                                        error: (err) => {
                                            errorlist = errorlist.concat(err);
                                        },
                                    });
                                }
                            });
                            if (errorlist.length > 0) {
                                this.afterError(errorlist);
                            } else {
                                this.afterSaveComplete();
                                void this.grid.instance.refresh();
                            }
                        },
                        error: (err) => {
                            this.afterError(err);
                            //return EMPTY;
                        },
                    });
            }
        });
    }

    copyCarsToClipboard(): void {
        this.selectedRowData = this.grid.instance.getSelectedRowsData();
        if (this.selectedRowData != null && this.selectedRowData.length > 0) {
            const carIds = this.selectedRowData.map((eachRow) => {
                return eachRow.carInit.trim() + eachRow.carNo.trim();
            });
            const uniqueCars = [...new Set(carIds)];
            this.clipboard.copy(uniqueCars.join('\n'));
        }
    }

    public cancelNew(): void {
        this.isCreatingNew = false;
        this.newTrip = null;
    }

    public onMenuItemClicked(e: unknown): void {
        console.log('onMenuItemClicked', e);
    }

    onGridFastracIdChanged($event: number): void {
        super.onGridFastracIdChanged($event);
        void this.grid.instance.refresh();
    }

    public navigate(currentSelectedRowKeys: number[]): void {
        this.selectedRowKeys = currentSelectedRowKeys;
        this.errors = [];
        if (currentSelectedRowKeys) {
            if (currentSelectedRowKeys.length === 1) {
                void this.router.navigate(['detail', currentSelectedRowKeys[0]], {
                    queryParamsHandling: 'preserve',
                    relativeTo: this.route,
                });
            }
            if (currentSelectedRowKeys.length > 1) {
                void this.router.navigate(['multi'], {
                    queryParams: {
                        ids: currentSelectedRowKeys.join(','),
                    },
                    queryParamsHandling: 'merge',
                    relativeTo: this.route,
                });
            }
            return;
        }
        void this.router.navigate(['trips/detail', currentSelectedRowKeys[0]], {
            queryParamsHandling: 'preserve',
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private afterError(error: ErrorListItem[]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const customerErrors = [];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.errors = error;

        this.snackbar.open('Error deleting trip(s).', 'Error', {
            duration: 3000,
            panelClass: 'error-snack',
        });
    }

    private afterSaveComplete(message?: string) {
        if (!message) {
            message = 'Trip(s) deleted successfully!';
        }

        this.snackbar.open(message, 'Ok', { duration: 3000 });
    }
}
