import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BdsErpcOriginService } from '@bds/data-access';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';

@Component({
    selector: 'bds-erpc-origin-select-dialog',
    templateUrl: './bds-erpc-origin-select-dialog.component.html',
    styleUrls: ['./bds-erpc-origin-select-dialog.component.css'],
})
export class BdsErpcOriginSelectDialogComponent implements OnInit {
    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
    dataSource: DataSource;
    selectedRowKeys: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<BdsErpcOriginSelectDialogComponent, any>,
        private erpcOriginService: BdsErpcOriginService,
    ) {}

    ngOnInit(): void {
        this.dataSource = new DataSource({
            store: this.erpcOriginService.getODataStore('splc'),
            paginate: true,
            pageSize: 15,
            select: ['erpcOrigin', 'erpcState', 'splc', 'originCode', 'masterOriginYorn'],
            key: 'splc',
        });
        this.dataSource.filter(this.data.filter);
    }

    onSelectClick(): void {
        const data = this.dataGrid.instance.getSelectedRowsData();
        if (data.length > 0) {
            this.dataGrid.instance.clearSelection();
            this.dialogRef.close(data);
        }
    }
}
