import { Component } from '@angular/core';
import { PageService } from '@bds/core';

@Component({
    selector: 'app-erpc-origin-screen',
    templateUrl: './erpc-origin-screen.component.html',
    styleUrls: ['./erpc-origin-screen.component.scss'],
})
export class ErpcOriginScreenComponent {
    constructor(public pageService: PageService) {
        pageService.setHeader('Origins');
    }
}
