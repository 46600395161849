import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtCarHotList implements From<RtCarHotList> {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public hotListDate?: Date | string,
        public hotListType?: string,
        public hotListCategory?: string,
        public acknowledgeFlag?: string,
        public comments?: string,
        public userResponse?: string,
        public userId?: string,
        public acknowledgeDate?: Date | string,
        public acknowledgeUserId?: string,
        public commentType?: string,
        public commentCode?: string,
        public ormId?: number,
    ) {}
    from(trip: RtCarHotList): RtCarHotList {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtCarHotList>('carInit'), server: 'carInit' },
    { client: nameof<RtCarHotList>('carNo'), server: 'carNo' },
    { client: nameof<RtCarHotList>('hotListDate'), server: 'hotListDate' },
    { client: nameof<RtCarHotList>('hotListType'), server: 'hotListType' },
    { client: nameof<RtCarHotList>('hotListCategory'), server: 'hotListCategory' },
    { client: nameof<RtCarHotList>('acknowledgeFlag'), server: 'acknowledgeFlag' },
    { client: nameof<RtCarHotList>('comments'), server: 'comments' },
    { client: nameof<RtCarHotList>('userResponse'), server: 'userResponse' },
    { client: nameof<RtCarHotList>('userId'), server: 'userId' },
    { client: nameof<RtCarHotList>('acknowledgeDate'), server: 'acknowledgeDate' },
    { client: nameof<RtCarHotList>('acknowledgeUserId'), server: 'acknowledgeUserId' },
    { client: nameof<RtCarHotList>('commentType'), server: 'commentType' },
    { client: nameof<RtCarHotList>('commentCode'), server: 'commentCode' },
    { client: nameof<RtCarHotList>('ormId'), server: 'ormId' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCarHotListAdapter extends TwoWayAdapter<RtCarHotList> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtCarHotListMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtCarHotList>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('hotListDate'),
                dataType: 'Date',
                caption: 'hotListDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('hotListType'),
                dataType: 'string',
                caption: 'hotListType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('hotListCategory'),
                dataType: 'string',
                caption: 'hotListCategory',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('acknowledgeFlag'),
                dataType: 'string',
                caption: 'acknowledgeFlag',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('comments'),
                dataType: 'string',
                caption: 'comments',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('userResponse'),
                dataType: 'string',
                caption: 'userResponse',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('userId'),
                dataType: 'string',
                caption: 'userId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('acknowledgeDate'),
                dataType: 'Date',
                caption: 'acknowledgeDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('acknowledgeUserId'),
                dataType: 'string',
                caption: 'acknowledgeUserId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('commentType'),
                dataType: 'string',
                caption: 'commentType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('commentCode'),
                dataType: 'string',
                caption: 'commentCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCarHotList>('ormId'),
                dataType: 'number',
                caption: 'ormId',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtCarHotList>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarHotList>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarHotList>('hotListDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarHotList>('hotListType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarHotList>('hotListCategory'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarHotList>('acknowledgeFlag'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCarHotList>('comments'),
            new UntypedFormControl('', [Validators.maxLength(250)]),
        );
        formGroup.addControl(
            nameof<RtCarHotList>('userResponse'),
            new UntypedFormControl('', [Validators.maxLength(250)]),
        );
        formGroup.addControl(nameof<RtCarHotList>('userId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarHotList>('acknowledgeDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarHotList>('acknowledgeUserId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarHotList>('commentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarHotList>('commentCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCarHotList>('ormId'), new UntypedFormControl(''));

        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
// export class TripCarHotListDto extends RtCarHotList {
//     constructor(public propagateHotListForward = false, public tripId?: number) {
//         super();
//     }
//     from(hotlistDto: TripCarHotListDto): TripCarHotListDto {
//         Object.keys(hotlistDto).forEach((k) => (this[k] = hotlistDto[k]));
//         return this;
//     }
// }
