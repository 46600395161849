import { AuthorizeService } from '@bds/auth';
import { Router, NavigationStart } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../environments/environment';
import { filter, takeUntil } from 'rxjs/operators';
import { AsyncSubject } from 'rxjs';
import { PageService } from '@bds/core';
import { LoadingService } from '@bds/smart-components';

@Component({
    selector: 'app-railtrac',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    currentSection: string;
    environment: any = environment;
    isAuthenticated = false;
    showReorder = true;
    sidenavOpened = false;

    private destroyer$ = new AsyncSubject<void>();
    loading$ = this.loader.loading$;

    constructor(
        public router: Router,
        public pageService: PageService,
        public authService: AuthorizeService,
        public loader: LoadingService
    ) {}

    ngOnInit() {
        this.authService
            .isAuthenticated()
            .pipe(takeUntil(this.destroyer$))
            .subscribe((isAuth) => {
                this.isAuthenticated = isAuth;
            });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .pipe(takeUntil(this.destroyer$))
            .subscribe((s) => {
                this.pageService.resetTitle();
            });
    }

    ngOnDestroy() {
        this.destroyer$.next(null);
        this.destroyer$.complete();
    }

    onToggleMenu() {
        this.sidenavOpened = !this.sidenavOpened;
    }

    onSidebarMoreButtonClick(event: Event, button: string) {
        event.stopPropagation();
    }

    // TODO: Not sure what this is
    beforeRunWorkflow(id: number) {}

    // TODO: Not sure what this is
    runDefaults() {}

    onSectionChange(section: string) {
        this.currentSection = section;
    }
}
