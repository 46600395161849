import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BdsCustomerService } from '@bds/reference-data-access';
import { FastracLite } from '@bds/models';
import { RtCustomerFact } from '@bds/reference-models';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { BdsCustomerSearchDialogModel } from '../../models/bds-customer-search-dialog.model';
import { FastracScreenBaseComponent, FastracDxGridWrapperComponent } from '@bds/fastrac';
import { ActivatedRoute, Router } from '@angular/router';
import { FastracBuilderService, FastracColsService } from '@bds/services';
import { CommunicationService } from '@bds/core';
import { CarIdTransformService } from '@bds/helpers';

@Component({
    templateUrl: './bds-customer-search-dialog.component.html',
    styleUrls: ['./bds-customer-search-dialog.component.scss'],
})
export class BdsCustomerSearchDialogComponent
    extends FastracScreenBaseComponent
    implements OnInit, OnDestroy
{
    @ViewChild('dxGridWrapper') fastracGridComponent: FastracDxGridWrapperComponent;
    @Input() gridId: number = 2;

    // Fastrac properties
    public fastracId = 0;
    public fastracItems: FastracLite[] = [];
    public showFastrac = false;
    public fastracName = 'SEARCH CONSIGNEE';

    public dataSource: DataSource; // | Store;
    public dataStore: ODataStore;
    public selectedRowKeys: any[];

    get dataGrid() {
        return this.fastracGridComponent?.grid;
    }

    constructor(
        public dialogRef: MatDialogRef<BdsCustomerSearchDialogComponent, RtCustomerFact>,
        @Inject(MAT_DIALOG_DATA) public data: BdsCustomerSearchDialogModel,
        public service: BdsCustomerService,
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        public fastracService: FastracBuilderService,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
        this.skipUpdatingPageUrl = true;
        this.dataSource = data.source || this.service.getDataSource();
        this.fastracName = data.defaultFastracName;
    }

    ngOnInit() {
        super.ngOnInit();
        this.fastracService.getFastracDesigns(this.gridId).subscribe((data) => {
            this.fastracItems = data;
            data.map((eachFastrac) => {
                if (eachFastrac.name == this.fastracName) {
                    this.fastracId = eachFastrac.id;
                }
            });
        });
        this.dataGrid?.instance.repaint();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    onSelectClick() {
        const data = this.dataGrid.instance.getSelectedRowsData();
        if (data.length > 0) {
            const cust = this.service.adapter.adapt(data[0]);
            this.dialogRef.close(cust);
        }
    }

    onGridFastracIdChanged(event: number) {
        if (this.fastracId !== event) {
            this.fastracId = event;
        }
        this.dataGrid?.instance.repaint();
    }

    onKeyDown(e: any) {
        if (e.event.key === 'Tab') {
            e.event.preventDefault();
        }
    }
}
