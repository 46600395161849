import { From, IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FormGroupDescription } from './adapter_t';

export class RtRouteParticipatingRailroad implements From<RtRouteParticipatingRailroad> {
    constructor(public ormId?: number, public routeCode?: string, public railroad?: string) {}
    from(trip: RtRouteParticipatingRailroad): RtRouteParticipatingRailroad {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export interface RtRouteParticipatingRailroadModel {
    ParticipatingRailroads: RtRouteParticipatingRailroad[];
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtRouteParticipatingRailroad>('ormId'), server: 'ormId' },
    { client: nameof<RtRouteParticipatingRailroad>('routeCode'), server: 'routeCode' },
    { client: nameof<RtRouteParticipatingRailroad>('railroad'), server: 'railroad' },
];

@Injectable({
    providedIn: 'root',
})
export class RtRouteParticipatingRailroadAdapter extends TwoWayAdapter<RtRouteParticipatingRailroad> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtRouteParticipatingRailroadMetadata implements FormGroupDescription {
    constructor() {}
    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<RtRouteParticipatingRailroad>('ormId'),
            new UntypedFormControl('', [Validators.required, Validators.min(0)]),
        );
        formGroup.addControl(
            nameof<RtRouteParticipatingRailroad>('routeCode'),
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<RtRouteParticipatingRailroad>('railroad'),
            new UntypedFormControl('', [Validators.required]),
        );
        if (!!obj) {
            formGroup.patchValue(obj);
        }
        return formGroup;
    }
}
