import { PageService } from '@bds/core';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FastracManagementComponent } from '@bds/fastrac';

export interface FastracDialogData {
    title: string;
    prompt: string;
    label?: string;
    value?: string;
    yesLabel?: string;
    noLabel?: string;
    id?: number;
    requireChange?: boolean;
    maxLength?: number | null;
    asAlert?: boolean;
    allowIgnore?: boolean;
    promptId?: string;
}

@Component({
    selector: 'app-fastrac-management-screen',
    templateUrl: './fastrac-management-screen.component.html',
    styleUrls: ['./fastrac-management-screen.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FastracManagementScreenComponent implements OnInit, OnDestroy {
    private test: FastracManagementComponent;
    constructor(private pageService: PageService) {
        this.pageService.setTitle('FASTRAC Manager');
        this.pageService.setHeader('FASTRAC Manager');
    }

    ngOnInit() {}

    ngOnDestroy() {}
}
