// TODO: This doesn't appear to be used anywhere. If it is later, make sure
// to add multipleTrips if this can be for multi-select.
import { Component, OnInit, Inject } from '@angular/core';
import { RtTrip } from '@bds/railtrac-models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'rt-trip-dialog',
    templateUrl: './rt-trip-dialog.component.html',
    styleUrls: ['./rt-trip-dialog.component.scss'],
})
export class RtTripDialogComponent implements OnInit {
    onNoClick(): void {
        this.dialogRef.close();
    }

    onModified(ev: RtTrip) {
        this.data = ev;
    }

    get isFullData(): boolean {
        return this.data instanceof RtTrip;
    }

    constructor(
        public dialogRef: MatDialogRef<RtTripDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RtTrip | string,
    ) {}

    ngOnInit() {
        console.log('RtTripDialogComponent ngOnInit', this.data);
        this.data = this.data || new RtTrip();
    }
}
