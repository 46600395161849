import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { UntypedFormGroup } from '@angular/forms';
import { RtEdiInfoService } from '@bds/data-access';
import { RtvEdiInfoMetadata, RtvEdiInfo } from '@bds/railtrac-models';
import * as moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
    selector: 'rt-trip-edi-dialog',
    templateUrl: './rt-trip-edi-dialog.component.html',
    styleUrls: ['./rt-trip-edi-dialog.component.css'],
})
export class RtTripEdiDialogComponent implements OnInit {
    @ViewChild('dataGrid') ediGrid: DxDataGridComponent;
    public gridSource: DataSource;
    ediDataForm: UntypedFormGroup;
    ediData: RtvEdiInfo;
    shipDateTime: string;
    //gsDate: string;
    //gsTime: string;

    constructor(
        public dialogRef: MatDialogRef<RtTripEdiDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public tripId: number,
        private ediService: RtEdiInfoService,
        private overlayContainer: OverlayContainer,
        private ediInfoMetaData: RtvEdiInfoMetadata,
    ) {
        this.ediDataForm = ediInfoMetaData.getFormGroup();
    }

    ngOnInit(): void {
        this.overlayContainer.getContainerElement().classList.add('dark-theme');
        this.gridSource = this.ediService.getEDIDataSource(this.tripId);
    }

    ediDataGrid_onContentReady(e) {
        e.component.selectRowsByIndexes(0);
        if (e.component.getSelectedRowKeys().length) {
            this.ediData = e.component.getSelectedRowsData()[0];
            if (this.ediData) {
                this.ediDataForm.patchValue(this.ediData);
                this.shipDateTime = moment(this.ediData.shipDateTime).calendar();
            }
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
