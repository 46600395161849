import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorsModule } from '../../vendors/vendors.module';
import { EquipmentReportCategoriesComponentsModule } from '../../components';
import { EquipmentReportCategoriesComponent } from './equipment-report-categories.component';

@NgModule({
    declarations: [EquipmentReportCategoriesComponent],
    imports: [CommonModule, EquipmentReportCategoriesComponentsModule, VendorsModule],
    exports: [EquipmentReportCategoriesComponent]
})
export class EquipmentReportCategoriesModule {}
