<h1 mat-dialog-title>Filter</h1>
<mat-dialog-content>
    <clm-filter-details [filterForm]="filterForm"></clm-filter-details>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" mat-dialog-close>Cancel</button>

    <button
        mat-button
        [disabled]="!filterForm.valid"
        class="text-uppercase"
        color="accent"
        (click)="onSearchClick()"
    >
        Search
    </button>
</mat-dialog-actions>

