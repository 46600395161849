<h1 mat-dialog-title>Destination Criteria Info</h1>

<mat-dialog-content>
    <div class="bds-error-message">{{ errorMessage }}</div>
    <div>
        <bds-destination-criteria-details
            [destinationCriteria]="initialDestCriteria"
            [customerStore]="data.customers"
            [railroadStore]="data.railroads"
            [states]="data.states"
            [sightCodes]="data.sightCodes"
            [isNew]="data.isNew"
            [isClone]="data.isClone"
            (destinationCriteriaChange)="onDestinationCriteriaChanged($event)"
        >
        </bds-destination-criteria-details>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-button
        [disabled]="!isValid"
        *ngIf="data.isNew || data.isClone"
        class="text-uppercase"
        color="accent"
        (click)="onSaveClick(true)"
    >
        Save and Create New Destination Criteria
    </button>

    <div class="break"></div>

    <button
        mat-button
        [disabled]="!isValid"
        class="text-uppercase"
        color="accent"
        (click)="onSaveClick(false)"
    >
        Save Destination Criteria
    </button>

    <div class="break"></div>

    <button mat-button class="text-uppercase" color="accent" (click)="onCancelClick()">
        Cancel
    </button>
</mat-dialog-actions>
