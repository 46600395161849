import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BdsHotListSharedModule } from '@bds/hotlist';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DevExtremeModule } from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { DatePipe } from '@angular/common';

import { BourqueCoreModule } from '@bds/core';
import { BdsHelpersModule, CarIdTransformService } from '@bds/helpers';
import {
    BdsDialogInfoModule,
    BdsDxDataGridModule,
    FormlyFieldTemplatesModule,
    ZeroPadModule,
} from '@bds/components';
import { EquipmentReportCategoriesDialogModule } from '@bds/equipment';
import { EquipmentReportCategoriesModule } from '@bds/equipment';
import { BdsDateTimePickerModule, BdsSmartComponentsModule } from '@bds/smart-components';

import { RailtracTripComponent } from './railtrac-trip.component';
import { RailtracTripDetailsComponent } from './railtrac-trip-details/railtrac-trip-details.component';
import { RailtracTripDetentionComponent } from './railtrac-trip-detention/railtrac-trip-detention.component';
import { BdsCustomerModule } from '@bds/customer';
import { BdsCommodityModule } from '@bds/commodity';
import { RtTripDialogComponent } from './rt-trip-dialog/rt-trip-dialog.component';

import { BdsRouteModule } from '@bds/route';
import { RtRouteScreenModule } from '@bds/route';
import { RailtracClmModule } from '../railtrac-clm/railtrac-clm.module';
import { RtTripCommentModule } from '../rt-trip-comment/rt-trip-comment.module';
import { RtTripCommentsComponent } from './rt-trip-comments/rt-trip-comments.component';
import { RtMultiTripComponent } from './rt-multi-trip/rt-multi-trip.component';
import { RtTripCommentDialogComponent } from './rt-trip-comment-dialog/rt-trip-comment-dialog.component';
import { RtTripCommodityShippedComponent } from '../rt-trip-commodity-shipped/rt-trip-commodity-shipped.component';
import { TripCommodityShippedComponent } from './rt-trip-commodity-shipped/trip-commodity-shipped/trip-commodity-shipped.component';
import { RtTripMoreOptionsComponent } from './rt-trip-more-options/rt-trip-more-options.component';
import { RtCommodityShippedModule } from '../rt-commodity-shipped/rt-commodity-shipped.module';
// eslint-disable-next-line max-len
import { RtMultiTripCommodityShippedVolumesComponent } from './rt-multi-trip-commodity-shipped-volumes/rt-multi-trip-commodity-shipped-volumes.component';
import { RtTripAdditionalRefComponent } from './rt-trip-ref/rt-trip-additional-ref/rt-trip-additional-ref.component';
import { RtDiversionModule } from '../rt-diversion';
import { RouterModule } from '@angular/router';
import { RtMultiHotListComponent } from './rt-multi-trip/rt-multi-hot-list/rt-multi-hot-list.component';
import { RtTripEdiDialogComponent } from './rt-trip-edi-dialog/rt-trip-edi-dialog.component';
import { RtTripCommodityShippedDialogComponent } from './rt-trip-commodity-shipped/rt-trip-commodity-shipped-dialog/rt-trip-commodity-shipped-dialog.component';
import { RtMultiCommentComponent } from './rt-multi-trip/rt-multi-comment/rt-multi-comment.component';
import { RtMultiCommentDetailComponent } from './rt-multi-trip/rt-multi-comment/rt-multi-comment-detail/rt-multi-comment-detail.component';
import { RtMultiCommentDialogComponent } from './rt-multi-trip/rt-multi-comment/rt-multi-comment-dialog/rt-multi-comment-dialog.component';
import { RtMultiCommentFormItemComponent } from './rt-multi-trip/rt-multi-comment/rt-multi-comment-form-item/rt-multi-comment-form-item.component';
import { RtMultiCommentEditFormItemComponent } from './rt-multi-trip/rt-multi-comment/rt-multi-comment-edit-form-item/rt-multi-comment-edit-form-item.component';

@NgModule({
    declarations: [
        RailtracTripComponent,
        RailtracTripDetailsComponent,
        RailtracTripDetentionComponent,
        RtTripDialogComponent,
        RtTripCommentsComponent,
        RtMultiTripComponent,
        RtTripCommentDialogComponent,
        RtTripCommodityShippedComponent,
        TripCommodityShippedComponent,
        RtTripMoreOptionsComponent,
        RtMultiTripCommodityShippedVolumesComponent,
        RtTripAdditionalRefComponent,
        RtMultiHotListComponent,
        RtTripEdiDialogComponent,
        RtTripCommodityShippedDialogComponent,
        RtMultiCommentComponent,
        RtMultiCommentDetailComponent,
        RtMultiCommentDialogComponent,
        RtMultiCommentFormItemComponent,
        RtMultiCommentEditFormItemComponent,
    ],
    imports: [
        BdsCommodityModule,
        BdsCustomerModule,
        BdsDialogInfoModule,
        BdsDxDataGridModule,
        EquipmentReportCategoriesDialogModule,
        EquipmentReportCategoriesModule,
        BdsHelpersModule,
        BdsHotListSharedModule,
        BdsSmartComponentsModule,
        BourqueCoreModule,
        CommonModule,
        DevExtremeModule,
        FontAwesomeModule,
        FormlyFieldTemplatesModule,
        FormlyMatDatepickerModule,
        FormlyMaterialModule,
        FormlyModule,
        FormsModule,
        MatBadgeModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        RailtracClmModule,
        ReactiveFormsModule,
        RouterModule,
        RtCommodityShippedModule,
        RtDiversionModule,
        BdsRouteModule,
        RtRouteScreenModule,
        RtTripCommentModule,
        BdsDateTimePickerModule,
        ZeroPadModule,
    ],
    providers: [CarIdTransformService, DatePipe],
    exports: [
        RailtracTripComponent,
        RailtracTripDetailsComponent,
        RailtracTripDetentionComponent,
        RtTripDialogComponent,
        RtTripCommentsComponent,
        RtMultiTripComponent,
        RtTripCommentDialogComponent,
        RtTripCommodityShippedComponent,
        TripCommodityShippedComponent,
    ],
})
export class RailtracTripModule {}
