import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { nameof } from '@bds/core';
import { BdsStateService } from '@bds/data-access';
import { RtRailroadFact, RtStateCode } from '@bds/railtrac-models';
import { dxMatStyle } from '../../helpers/dx-mat-style';
import { DxSelectBoxComponent } from 'devextreme-angular/ui/select-box';
import DataSource from 'devextreme/data/data_source';
import { BdsMatFormField } from '../../abstract-components/bds-abstract-control-value.accessor';

@Component({
    selector: 'bds-bds-state-select',
    templateUrl: './bds-state-select.component.html',
    styleUrls: ['./bds-state-select.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: BdsStateSelectComponent }],
})
export class BdsStateSelectComponent extends BdsMatFormField<string> implements OnInit {
    dataSource: DataSource;
    @Input() dense = true;
    @Input() displayExpr: string[] = [
        nameof<RtStateCode>('code'),
        nameof<RtStateCode>('description'),
    ];
    @Input() displayValue = this.defaultDisplay;
    @ViewChild(DxSelectBoxComponent) dxSelectBox;
    id: string;
    @Input() matFloatLabel = 'always';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() searchDisplay = this.defaultDisplay;
    @Output() selected = new EventEmitter<RtStateCode>();
    key = nameof<RtStateCode>('code');

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private stateService: BdsStateService,
    ) {
        super(ngControl);
    }

    get style(): string {
        return dxMatStyle(this.matStyle);
    }

    defaultDisplay(data: RtStateCode) {
        return data ? `${data.code} - ${data.description}` : '';
    }

    getDisplayValue(data: RtStateCode) {
        return this.displayValue(data);
    }

    private _filter: [];

    get filter() {
        return this._filter;
    }

    @Input() set filter(value: []) {
        this._filter = value;
    }

    @Input() set label(plh: string) {
        super.placeholder = plh;
    }

    ngOnInit(): void {
        this.dataSource = new DataSource({
            store: this.stateService.getODataStore(),
            paginate: true,
            pageSize: 10,
            searchExpr: this.displayExpr,
            select: [nameof<RtStateCode>('code'), nameof<RtStateCode>('description')],
            sort: [nameof<RtStateCode>('code'), nameof<RtStateCode>('description')],
            filter: this.filter,
            key: nameof<RtStateCode>('code'),
        });
    }

    valueChanged() {
        this.selected.emit(this.dxSelectBox.selectedItem);
    }
}
