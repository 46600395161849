import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RtCommodityShipped, RtCommodityShippedUK } from '@bds/railtrac-models';
import { reduce, isEqual } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class RtCommodityShippedForm implements OnDestroy {
    unsub$ = new Subject<void>();

    private _form = this.fb.group({
        commodityShippedBulkForm: this.fb.group({
            carInit: [],
            carNo: [],
            commodityCompart: [],
            shipDate: [],
            commodityCode: [],
            commodityAmt: [],
            commodityUnits: [],
            commodityRef1: [],
            commodityRef2: [],
            commodityRef3: [],
            commodityRef4: [],
            ormId: [],
            rtTripOrmId: [],
            userId: [],
            updateDateTime: [],
        }),
        commodityShippedItemForms: this.fb.array([]),
    });

    get form() {
        return this._form;
    }

    getCommodityShippedBulkForm() {
        return this._form.get('commodityShippedBulkForm') as UntypedFormGroup;
    }

    setCommodityShippedBulkForm(obj: any) {
        this.setValues(obj, this.getCommodityShippedBulkForm());
    }

    get commodityShippedItemForms() {
        return this._form.get('commodityShippedItemForms') as UntypedFormArray;
    }

    constructor(private fb: UntypedFormBuilder) {
        this.watchBulkFormForChanges();
    }

    setValues(obj: any, form: UntypedFormGroup) {
        Object.keys(obj).forEach((key: string) => {
            const control = form.controls[key];
            if (control) {
                form.controls[key].setValue(obj[key]);
            }
        });
    }

    addCommodityShippedItemForm(): UntypedFormGroup {
        const formGroup = this.fb.group({
            commodityCode: [],
            commodityAmt: [],
            commodityUnits: [],
            commodityRef1: [],
            commodityRef2: [],
            commodityRef3: [],
            commodityRef4: [],
            ormId: [],
        });
        this.commodityShippedItemForms.push(formGroup);
        return formGroup;
    }

    removeCommodityShippedItemForm(id: number) {
        this.commodityShippedItemForms.removeAt(this.findForm(id));
    }

    getCommodityShippedUniqueFormChanges(
        selectedCommodityShippedKeys: RtCommodityShippedUK[] = [],
    ): { key: RtCommodityShippedUK; changes: Map<string, any> | any[] | any | undefined }[] {
        return selectedCommodityShippedKeys
            .map((x) => {
                return {
                    selected: x,
                    form: this.commodityShippedItemForms.controls[this.findForm(x.ormId)].value,
                };
            })
            .map((frm) => {
                return { key: frm.selected, changes: this.buildChangeMap(frm.selected, frm.form) };
            })
            .filter((x) => x.changes.size !== 0);
    }

    private buildChangeMap(obj, form): Map<string, any> | any[] | any | undefined {
        return new Map(
            reduce(
                form,
                (result, value, key) => {
                    return isEqual(value, obj[key])
                        ? result
                        : result.concat({ key: key, value: value });
                },
                [],
            ).map((x) => [x.key, x.value]),
        );
    }

    private findForm(id: number): number {
        return this.commodityShippedItemForms.controls.findIndex(
            (x) => x.get('ormId').value === id,
        );
    }

    private watchBulkFormForChanges() {
        Object.keys(this.getCommodityShippedBulkForm().controls).forEach((key) => {
            this.getCommodityShippedBulkForm()
                .get(key)
                .valueChanges.pipe(takeUntil(this.unsub$))
                .subscribe((response) => {
                    this.commodityShippedItemForms.controls.forEach((form: UntypedFormGroup) => {
                        this.setValues({ [key]: response }, form);
                    });
                });
        });
    }

    ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.complete();
    }
}
