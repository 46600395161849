import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { VendorsModule } from '../../vendors';
import { HistoricDatesListComponent } from './historic-dates-list/historic-dates-list.component';
import { HistoricDatesDetailsComponent } from './historic-dates-details/historic-dates-details.component';

@NgModule({
    declarations: [HistoricDatesDetailsComponent, HistoricDatesListComponent],
    imports: [CommonModule, MatCardModule, VendorsModule],
    exports: [HistoricDatesDetailsComponent, HistoricDatesListComponent],
})
export class HistoricDatesComponentsModule {}
