import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { nameof } from '@bds/core';
import { RtRouteCode, RtRouteCodeMetadata, RtSplcErpc } from '@bds/railtrac-models';
import { BdsSysDefConfig, BdsSysDefService } from '@bds/sysdef';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class RouteCodeFormService implements OnDestroy {
    originGeography$ = new BehaviorSubject<RtSplcErpc>(<RtSplcErpc>{});
    segmentBuilderUnsub$ = new Subject<void>();
    unsub$ = new Subject<void>();

    private readonly _form: UntypedFormGroup;
    private config: BdsSysDefConfig;

    constructor(routeMetadata: RtRouteCodeMetadata, private sysDef: BdsSysDefService) {
        this._form = routeMetadata.getFormGroup();
        this.oneWayRound.setValue('');
        this.activeStatus.setValue('Y');
        this.routeDscr.disable();
        this.routeCode.disable();
        this.originSplc.disable();
        this.destSplc.disable();
        this.releaseSplc.disable();
        this.departingCarrier.disable();
        this.deliveringCarrier.disable();
        this.pathCode.disable();
        this.lastClmPerfCalc.disable();
        this.initializeFormSync();

        this.sysDef
            .getConfig()
            .pipe(
                takeUntil(this.unsub$),
                tap((response) => {
                    this.config = response;
                }),
            )
            .subscribe();
    }

    //#region "FormControls"
    get activeStatus(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('activeStatus'));
    }

    get assignment(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('assignment'));
    }

    get autoCloseYorn(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('autoCloseYorn'));
    }

    get autoEtaCalcYorn(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('autoEtaCalcYorn'));
    }

    get autoRteDtlCalcYorn(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('autoRteDtlCalcYorn'));
    }

    get beginCarStatus(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('beginCarStatus'));
    }

    get blockingCode(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('blockingCode'));
    }

    get borderCrossSplc(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('borderCrossSplc'));
    }

    get clmPerfCalcHistDays(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('clmPerfCalcHistDays'));
    }

    get clmPerfCalcResetDays(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('clmPerfCalcResetDays'));
    }

    get comments(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('comments'));
    }

    get contractNo(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('contractNo'));
    }

    get deliveringCarrier(): UntypedFormControl {
        return this._form.get(nameof<RtRouteCode>('dlvRoad')) as UntypedFormControl;
    }

    get deliverySwitch(): UntypedFormControl {
        return <UntypedFormControl>this._form.get('deliverySwitch');
    }

    get departingCarrier(): UntypedFormControl {
        return this._form.get(nameof<RtRouteCode>('road')) as UntypedFormControl;
    }

    get destCity(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('destCity'));
    }

    get destSplc(): UntypedFormControl {
        return this._form.get(nameof<RtRouteCode>('destSplc')) as UntypedFormControl;
    }

    get destState(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('destState'));
    }

    get dlvRoad(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('dlvRoad'));
    }

    get etaHrsIn(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('etaHrsIn'));
    }

    get etaHrsOut(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('etaHrsOut'));
    }

    get form(): UntypedFormGroup {
        return this._form;
    }

    get impExp(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('impExp'));
    }

    get lastClmPerfCalc(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('lastClmPerfCalc'));
    }

    get lastSegment(): UntypedFormGroup {
        const last = this.segments.controls.length - 1;
        return this.segments.controls[last] as UntypedFormGroup;
    }

    get mileageType(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('mileageType'));
    }

    get oneWayRound(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('oneWayRound'));
    }

    get originCity(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('originCity'));
    }

    get originCode(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('originCode'));
    }

    get originSplc(): UntypedFormControl {
        return this._form.get(nameof<RtRouteCode>('originSplc')) as UntypedFormControl;
    }

    get originState(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('originState'));
    }

    get originSwitch(): UntypedFormControl {
        return <UntypedFormControl>this._form.get('originSwitch');
    }

    get pathCode(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('pathCode'));
    }

    get releaseCity(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('releaseCity'));
    }

    get releaseSplc(): UntypedFormControl {
        return this._form.get(nameof<RtRouteCode>('releaseSplc')) as UntypedFormControl;
    }

    get releaseState(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('releaseState'));
    }

    get returnCity(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('returnCity'));
    }

    get returnSplc(): UntypedFormControl {
        return this._form.get(nameof<RtRouteCode>('returnSplc')) as UntypedFormControl;
    }

    get revRouteDscr(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('revRouteDscr'));
    }

    get road(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('road'));
    }

    get routeCode(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('routeCode'));
    }

    get routeDscr(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('routeDscr'));
    }

    get rule11(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtRouteCode>('rule11'));
    }

    get segments(): UntypedFormArray {
        return this._form.get('segments') as UntypedFormArray;
    }

    //#endregion "FormControls"

    initBuildBySegmentForm(): UntypedFormArray {
        const segments = new UntypedFormArray([]);
        this._form.addControl('segments', segments);
        this._form.addControl('originSwitch', new UntypedFormControl(null));
        this._form.addControl('deliverySwitch', new UntypedFormControl(null));
        this.routeDscr.disable();
        this.routeCode.disable();
        this.departingCarrier.disable();
        this.deliveringCarrier.disable();

        this.watchSwitchCarriers();

        return segments;
    }

    initFreeform(): void {
        this._form.removeControl('segments');
        this.routeDscr.enable();
        this.routeCode.enable();
        this.departingCarrier.enable();
        this.deliveringCarrier.enable();
        this.segmentBuilderUnsub$.next();
    }

    initializeFormSync(): void {
        [this.releaseSplc, this.destSplc, this.returnSplc, this.originSplc].forEach(
            (control: UntypedFormControl) => {
                control.valueChanges
                    .pipe(
                        takeUntil(this.unsub$),
                        distinctUntilChanged(),
                        tap((value: string | number) => {
                            control.setValue(value);
                        }),
                    )
                    .subscribe();
            },
        );
    }

    ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.complete();
    }

    setDescription(): void {
        const array = this.segments.controls.map((x) => {
            const railroad = x.get('railroad').value;
            const rule260 = x.get('junction').value;
            if (railroad) {
                return `${railroad}${
                    rule260 ? `${this.config?.routeDescriptionDelimiter ?? '-'}${rule260}` : ''
                }`;
            }
        });

        this.routeDscr.setValue(array.join(this.config?.routeDescriptionDelimiter ?? '-'));
    }

    private watchSwitchCarriers() {
        this.originSwitch.valueChanges
            .pipe(
                takeUntil(this.segmentBuilderUnsub$),
                tap((value) => {
                    this.road.setValue(value);
                }),
            )
            .subscribe();

        this.deliverySwitch.valueChanges
            .pipe(
                takeUntil(this.segmentBuilderUnsub$),
                tap((value) => {
                    this.dlvRoad.setValue(value);
                }),
            )
            .subscribe();
    }
}
