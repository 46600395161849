import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { TripCommodityShippedComponent } from './trip-commodity-shipped/trip-commodity-shipped.component';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    exports: [],
})
export class RtCommodityShippedModule {}
