<dx-data-grid
    bds-grid
    #bdsParticipatingRailroadGrid
    [bdsUseZeroPadDates]="true"
    id="bdsParticipatingRailroadGrid"
    [dataSource]="gridSource"
    class="max-vh"
    minHeight="200px"
    [(selectedRowKeys)]="selectedRowKeys"
    [bdsAllowAddRecord]="true"
    [bdsAllowDeleteRecords]="true"
    (bdsAddRecordClicked)="createRecord()"
    (bdsDeleteRecordsClicked)="deleteRecords($event)"
>
    <dxo-selection
        mode="multiple"
        [allowSelectAll]="false"
        showCheckBoxesMode="none"
    ></dxo-selection>
</dx-data-grid>
<!--
<div *ngFor="let key of selectedRowKeys">
    <bds-route [routeCode]="key.routeCode" [showMenu]="false"></bds-route>
    <rt-railroad [scac]="key.railroad"></rt-railroad>
</div> -->
