<div *ngIf="selectedComponent" [formGroup]="compForm">
    <div class="row">
        <div class="col-md-6 col-12">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [ngClass]="{ 'bds-readonly-wrapper': !isNew, 'bds-mat-dense-form-field': dense }"
            >
                <mat-label>Component Id</mat-label>
                <mat-select
                    bdsShoutcase
                    formControlName="componentId"
                    (selectionChange)="onComponentIdSelected($event)"
                >
                    <mat-option
                        *ngFor="let option of availableComponents"
                        [value]="option.componentId"
                    >
                        {{ option.componentDscr }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="compForm.controls.componentId.errors">
                    {{ getError(compForm.controls.componentId) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 col-12">
            <bds-date-time-picker
                formControlName="installDatetime"
                label="Install Date"
                type="date"
                displayFormat="MM/dd/yyyy"
            ></bds-date-time-picker>
            <mat-error *ngIf="compForm.controls.installDatetime.errors">
                {{ getError(compForm.controls.installDatetime) }}
            </mat-error>
        </div>
        <div class="col-md-6 col-12">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Manufacturer</mat-label>
                <input matInput bdsShoutcase formControlName="manufacturer" />
                <mat-error *ngIf="compForm.controls.manufacturer.errors">
                    {{ getError(compForm.controls.manufacturer) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 col-12">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Model No</mat-label>
                <input matInput bdsShoutcase formControlName="modelNo" />
                <mat-error *ngIf="compForm.controls.modelNo.errors">
                    {{ getError(compForm.controls.modelNo) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 col-12">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Serial No</mat-label>
                <input matInput bdsShoutcase formControlName="serialNo" />
                <mat-error *ngIf="compForm.controls.serialNo.errors">
                    {{ getError(compForm.controls.serialNo) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 col-12">
            <bds-date-time-picker
                formControlName="expectedRemovalDatetime"
                label="Expected Removal Date"
                type="date"
                displayFormat="MM/dd/yyyy"
            ></bds-date-time-picker>
            <mat-error *ngIf="compForm.controls.expectedRemovalDatetime.errors">
                {{ getError(compForm.controls.expectedRemovalDatetime) }}
            </mat-error>
        </div>
        <div class="col-md-6 col-12">
            <bds-date-time-picker
                formControlName="removalDatetime"
                label="Removal Date"
                type="date"
                displayFormat="MM/dd/yyyy"
            ></bds-date-time-picker>
            <mat-error *ngIf="compForm.controls.removalDatetime.errors">
                {{ getError(compForm.controls.removalDatetime) }}
            </mat-error>
        </div>
        <div class="col-md-6 col-12">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Removal Reason Code</mat-label>
                <mat-select formControlName="removalReasonCode">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let item of removalCodes" [value]="item.removalCodeId">
                        {{ item.removalCodeId }} - {{ item.removalDscr }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Removal Reason</mat-label>
                <textarea matInput bdsShoutcase formControlName="removalReason"></textarea>
                <mat-error *ngIf="compForm.controls.removalReason.errors">
                    {{ getError(compForm.controls.removalReason) }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
