import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RtTripCommentComponent } from './rt-trip-comment.component';
import { RtTripCommentDialogComponent } from './rt-trip-comment-dialog/rt-trip-comment-dialog.component';
import { BourqueCoreModule } from '@bds/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    declarations: [RtTripCommentComponent, RtTripCommentDialogComponent],
    imports: [
        CommonModule,
        BourqueCoreModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        RtTripCommentComponent,
        RtTripCommentDialogComponent,
    ],
})
export class RtTripCommentModule {}
