<div class="container-fluid">
    <bds-fastrac-design-display
        [gridId]="gridId"
        [fastracId]="fastracId"
        [showFastrac]="showFastrac"
        (showFastracChange)="onFastracVisibleChanged($event)"
        (fastracIdChange)="onFastracIdChanged($event)"
        (fastracDesignsChange)="onFastracDesignsChanged($event)"
        (applyFastrac)="onApplyFastrac($event)"
    >
    </bds-fastrac-design-display>
</div>
<div class="railroad-grid-container">
    <bds-grid-details>
        <ng-container grid>
            <bds-fastrac-dx-grid-wrapper
                #dxGridWrapper
                bdsScrollSpySection="bdsTripGrid"
                (bdsAddRecordClicked)="add()"
                (bdsShowFastrac)="onShowFastrac()"
                (fastracIdChange)="onGridFastracIdChanged($event)"
                [selectionMode]="gridMode ?? 'multiple'"
                [bdsAllowAddRecord]="true"
                [bdsAllowDeleteRecords]="true"
                [bdsAllowCloneRecords]="true"
                (bdsCloneRecordsClicked)="copyCarsToClipboard()"
                bdsCloneRecordsHint="Copy cars"
                [bdsCustomizeColumns]="customizeColumns"
                [bdsFastracDesignList]="fastracList"
                [fastracDesign]="currentFastrac"
                [fastracId]="fastracId"
                id="bdsHotlistGrid"
                [gridId]="gridId"
                [dataSource]="dataSource"
                [(selectedRowKeys)]="selectedRowKeys"
                (selectedRowKeysChange)="navigate(grid.instance.getSelectedRowsData())"
                (bdsDeleteRecordsClicked)="onDeleteRecordsClicked()"
                [showColumnChooser]="true"
            ></bds-fastrac-dx-grid-wrapper>
        </ng-container>
        <ng-container details>
            <router-outlet></router-outlet>
        </ng-container>
    </bds-grid-details>
</div>
