import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RtFleetName, RtFleetNameAdapter } from '@bds/railtrac-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';

export abstract class BdsFleetApiServiceOptions {
    apiUrl: string = `api/customer/`;
    odataUrl: string = `odata/customer`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsFleetService {
    fleetNames: RtFleetName[] = [];

    constructor(
        private http: HttpClient,
        private options: BdsFleetApiServiceOptions,
        public adapter: RtFleetNameAdapter,
    ) {}

    read(ormId: number): Observable<RtFleetName> {
        return this.readViaOdata(ormId);
    }

    readViaApi(ormId: number): Observable<RtFleetName> {
        return this.http.get<RtFleetName>(`${this.options.apiUrl}/${ormId}`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            //retry(3),
            catchError(this.handleError),
        );
    }

    readViaOdata(ormId: number): Observable<RtFleetName> {
        return this.http.get<RtFleetName>(`${this.options.odataUrl}(${ormId})`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            //retry(3),
            catchError(this.handleError),
        );
    }

    getFleetNames(): Observable<RtFleetName[]> {
        if (this.fleetNames && this.fleetNames.length > 0) {
            return of(this.fleetNames);
        }

        return this.http.get<RtFleetName[]>(`${this.options.odataUrl}`).pipe(
            map((data: any) => {
                this.fleetNames = data.value;
                return data.value;
            }),
            shareReplay(),
            catchError(this.handleError),
        );
    }

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtFleetName>('fleetId'),
        ).server;

        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: keyField,
        });
    }

    create(trip: RtFleetName): Observable<RtFleetName> {
        console.warn('BdsFleetApiService.create() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    update(trip: RtFleetName): Observable<RtFleetName> {
        console.warn('BdsFleetApiService.update() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    delete(trip: RtFleetName): Observable<RtFleetName> {
        console.warn('BdsFleetApiService.delete() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    patch(trip: RtFleetName): Observable<RtFleetName> {
        console.warn('BdsFleetApiService.patch() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    // https://angular.io/guide/http#getting-error-details
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);

            // return an observable with a user-facing error message
            return throwError('A browser or network error occurred');
        } else if (error.status == 404) {
            // return an observable with a user-facing error message
            return throwError('Not Found');
        } else if (error.status == 422) {
            console.error('Found but cannot be used');
            return throwError(error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}`, error);

            // return an observable with a user-facing error message
            return throwError('The server returned an unknown error');
        }
    }
}
