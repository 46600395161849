import { RtCarHotListService } from '@bds/data-access';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { RtTrip, TripCarHotListDto } from '@bds/railtrac-models';
import { RtJeopardizedShipmentReasonBaseComponent } from '../rt-jeopardized-shipment-reason-base.component';
import { RailtracClmService } from '../../../railtrac-clm/railtrac-clm.service';
import { RailtracTripService } from '../../../railtrac-trip/railtrac-trip.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { BdsHotListDetailsDialogComponent, BdsHotListDialogData } from '@bds/hotlist';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'rt-jeopardized-shipment-eta-variance',
    templateUrl: './rt-jeopardized-shipment-eta-variance.component.html',
    styleUrls: ['./rt-jeopardized-shipment-eta-variance.component.scss'],
})
export class RtJeopardizedShipmentEtaVarianceComponent
    extends RtJeopardizedShipmentReasonBaseComponent
    implements OnInit, OnDestroy, OnChanges
{
    private tripper: RtTrip;

    constructor(
        public tripService: RailtracTripService,
        public clmService: RailtracClmService,
        public hotlistService: RtCarHotListService,
        public snackbar: MatSnackBar,
        public dialog: MatDialog,
    ) {
        super(tripService, clmService);
        this.trip$.pipe(takeUntil(this.destroyer$)).subscribe((trip) => {
            this.tripper = trip;
        });
    }

    ngOnInit() {
        super.ngOnInit();
        // this.clms$.pipe(takeUntil(this.destroyer$)).subscribe(clms => { });
    }
    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }

    async addHotList(): Promise<void> {
        const trip = await firstValueFrom(this.trip$);
        const model = [
            <TripCarHotListDto>{
                tripId: trip.ormId,
                carNo: trip.carNo,
                carInit: trip.carInit,
                hotListType: 'GNRL',
                hotListCategory: 'GNRL',
            },
        ];
        const dialogRef = this.dialog.open(BdsHotListDetailsDialogComponent, {
            data: <BdsHotListDialogData>{
                action: 'add',
                title: 'Add Hot Lists',
                hotLists: model,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.snackbar.open('Added car to hotlist', 'Ok', { duration: 3000 });
                return;
            }
        });
    }

    onResolve(event: string) {
        // TODO: Based on setup, not sure how to get trip
    }

    onTripSaveClicked(tripDetails: RtTrip): void {
        this.tripService.update(tripDetails.ormId, tripDetails).subscribe((upTrip) => {
            this.snackbar.open('Trip Saved!', 'Ok', {
                duration: 3000,
            });
        });
    }
}
