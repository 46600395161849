<!--<div style="overflow: hidden; position: absolute; top: 0; bottom: 0; left: 0; right: 0">
    <iframe src="https://bi.test.railcl.com/home"
            class="col-12"
            style="height: 100vh; width: 100vw; padding: 0; border: 0; margin-top: -56px"></iframe>
</div>-->
<!-- This code will be uncommented when @bds/shipper-bi library is available for angular version 15, 16 and 17-->
<!-- <ng-container *ngIf="isAuthenticated | async; else splashscreen">
    <ng-container *ngIf="!!reportId && !!workspaceId; else splashscreen">
        <lib-power-bi-report
            [reportId]="reportId"
            [workspaceId]="workspaceId"
        ></lib-power-bi-report>
    </ng-container>
</ng-container> -->
<ng-container>
    <!--<ng-template #splashscreen> -->
        <div class="splash-wrapper">
            <div class="splash-positioner mat-elevation-z24">
                <div class="splash"></div>
            </div>
        </div>
   <!-- </ng-template> -->
</ng-container>