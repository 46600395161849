import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from '../adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

import { RtClm, ClmType } from './rt-clm';

export class RtClmHistorical extends RtClm {
    constructor(public clmType: ClmType) {
        super();
    }
    from(trip: RtClmHistorical | RtClm): RtClmHistorical {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtClmHistorical>('ormId'), server: 'ormId' },
    { client: nameof<RtClmHistorical>('clmType'), server: 'clmType' },
    { client: nameof<RtClmHistorical>('carInit'), server: 'carInit' },
    { client: nameof<RtClmHistorical>('carNo'), server: 'carNo' },
    { client: nameof<RtClmHistorical>('clmDateTime'), server: 'clmDateTime' },
    { client: nameof<RtClmHistorical>('sightCode'), server: 'sightCode' },
    { client: nameof<RtClmHistorical>('locationCity'), server: 'locationCity' },
    { client: nameof<RtClmHistorical>('locationState'), server: 'locationState' },
    { client: nameof<RtClmHistorical>('loadEmptyFlag'), server: 'loadEmptyFlag' },
    { client: nameof<RtClmHistorical>('trainJunction'), server: 'trainJunction' },
    { client: nameof<RtClmHistorical>('road'), server: 'road' },
    { client: nameof<RtClmHistorical>('destinationCity'), server: 'destinationCity' },
    { client: nameof<RtClmHistorical>('destinationState'), server: 'destinationState' },
    { client: nameof<RtClmHistorical>('clmProcessedDate'), server: 'clmProcessedDate' },
    { client: nameof<RtClmHistorical>('clmEvent'), server: 'clmEvent' },
    { client: nameof<RtClmHistorical>('routeCode'), server: 'routeCode' },
    {
        client: nameof<RtClmHistorical>('actualHoursToDestination'),
        server: 'actualHoursToDestination',
    },
    { client: nameof<RtClmHistorical>('actualHoursToOrigin'), server: 'actualHoursToOrigin' },
    { client: nameof<RtClmHistorical>('locationSplc'), server: 'locationSplc' },
    { client: nameof<RtClmHistorical>('destinationSplc'), server: 'destinationSplc' },
    { client: nameof<RtClmHistorical>('railRoadEtaCity'), server: 'railRoadEtaCity' },
    { client: nameof<RtClmHistorical>('railRoadEtaState'), server: 'railRoadEtaState' },
    { client: nameof<RtClmHistorical>('railRoadEtaSightCode'), server: 'railRoadEtaSightCode' },
    { client: nameof<RtClmHistorical>('railRoadEtaDateTime'), server: 'railRoadEtaDateTime' },
    {
        client: nameof<RtClmHistorical>('railRoadEtaDestinationSplc'),
        server: 'railRoadEtaDestinationSplc',
    },
    { client: nameof<RtClmHistorical>('source'), server: 'source' },
    { client: nameof<RtClmHistorical>('previousSplc'), server: 'previousSplc' },
    { client: nameof<RtClmHistorical>('milesFromPrevious'), server: 'milesFromPrevious' },
    { client: nameof<RtClmHistorical>('dwellTime'), server: 'dwellTime' },
    { client: nameof<RtClmHistorical>('clmDifferentialTime'), server: 'clmDifferentialTime' },
];

@Injectable({
    providedIn: 'root',
})
export class RtClmHistoricalAdapter extends TwoWayAdapter<RtClmHistorical> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtClmHistoricalMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtClmHistorical>('ormId'),
                dataType: 'number',
                caption: 'ormId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('clmType'),
                dataType: 'ClmType',
                caption: 'clmType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('clmDateTime'),
                dataType: 'Date',
                caption: 'clmDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('sightCode'),
                dataType: 'string',
                caption: 'sightCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('locationCity'),
                dataType: 'string',
                caption: 'locationCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('locationState'),
                dataType: 'string',
                caption: 'locationState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('loadEmptyFlag'),
                dataType: 'string',
                caption: 'loadEmptyFlag',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('trainJunction'),
                dataType: 'string',
                caption: 'trainJunction',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('road'),
                dataType: 'string',
                caption: 'road',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('destinationCity'),
                dataType: 'string',
                caption: 'destinationCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('destinationState'),
                dataType: 'string',
                caption: 'destinationState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('clmProcessedDate'),
                dataType: 'Date',
                caption: 'clmProcessedDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('clmEvent'),
                dataType: 'string',
                caption: 'clmEvent',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('routeCode'),
                dataType: 'string',
                caption: 'routeCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('actualHoursToDestination'),
                dataType: 'number',
                caption: 'actualHoursToDestination',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('actualHoursToOrigin'),
                dataType: 'number',
                caption: 'actualHoursToOrigin',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('locationSplc'),
                dataType: 'string',
                caption: 'locationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('destinationSplc'),
                dataType: 'string',
                caption: 'destinationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('railRoadEtaCity'),
                dataType: 'string',
                caption: 'railRoadEtaCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('railRoadEtaState'),
                dataType: 'string',
                caption: 'railRoadEtaState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('railRoadEtaSightCode'),
                dataType: 'string',
                caption: 'railRoadEtaSightCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('railRoadEtaDateTime'),
                dataType: 'Date',
                caption: 'railRoadEtaDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('railRoadEtaDestinationSplc'),
                dataType: 'string',
                caption: 'railRoadEtaDestinationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('source'),
                dataType: 'string',
                caption: 'source',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('previousSplc'),
                dataType: 'string',
                caption: 'previousSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('milesFromPrevious'),
                dataType: 'number',
                caption: 'milesFromPrevious',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('dwellTime'),
                dataType: 'number',
                caption: 'dwellTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmHistorical>('clmDifferentialTime'),
                dataType: 'number',
                caption: 'clmDifferentialTime',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtClmHistorical>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('clmType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('clmDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('sightCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('locationCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('locationState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('loadEmptyFlag'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('trainJunction'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('road'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('destinationCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('destinationState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('clmProcessedDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('clmEvent'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('routeCode'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtClmHistorical>('actualHoursToDestination'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtClmHistorical>('actualHoursToOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('locationSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('destinationSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('railRoadEtaCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('railRoadEtaState'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtClmHistorical>('railRoadEtaSightCode'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtClmHistorical>('railRoadEtaDateTime'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtClmHistorical>('railRoadEtaDestinationSplc'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtClmHistorical>('source'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('previousSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('milesFromPrevious'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('dwellTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmHistorical>('clmDifferentialTime'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
