import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CreateTripService {
    constructor() {}
    // Observable string sources
    private createTripSource = new Subject<any>();

    private refreshTripGridSource = new Subject<boolean>();

    // Observable string streams
    createTripChangeEmitted$ = this.createTripSource.asObservable();

    refreshTripGridChangeEmitted$ = this.refreshTripGridSource.asObservable();

    // Service message commands
    emitCreateTripChange(change: any): void {
        this.createTripSource.next(change);
    }

    emitRefreshTripGridChange(change: boolean): void {
        this.refreshTripGridSource.next(change);
    }
}
