export interface HistoricDatesDialogModel {
    equipmentId: number;
    equipmentInit: string;
    equipmentNo: string;
    mechFieldName: string;
    mechFieldDescription: string;
    hasDueDate?: boolean;
    equipmentComponentId?: number;
    componentId?: string;
    componentInstallDate?: Date;
    componentDescription?: string;
}
