import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { nameof } from '@bds/core';
import { RtCarHotList, RtCarHotListMetadata } from '@bds/railtrac-models';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { TripCarHotListDto } from '@bds/railtrac-models';

@Component({
    selector: 'bds-hotlist-form',
    templateUrl: './bds-hotlist-form.component.html',
    styleUrls: ['./bds-hotlist-form.component.css'],
})
export class BdsHotlistFormComponent {
    displayedColumns: string[] = [];
    form: UntypedFormArray;
    iconPlus = faPlus;
    selected = 2;
    // @ts-ignore
    @ViewChild('responseDialog') responseDialog: TemplateRef;
    private readonly _ackknowledgeColumns = [
        'acknowledgeFlag',
        'carId',
        'commentType',
        'commentCode',
        'hotListDate',
        'hotListType',
        'issue',
        'response',
        'userId',
        'acknowledgeDate',
        'acknowledgeUserId',
    ];
    private readonly _addColumns = [
        'carInit',
        'carNo',
        'hotListType',
        'commentType',
        'commentCode',
        'hotListDate',
        'issue',
    ];

    constructor(private metadata: RtCarHotListMetadata, private dialog: MatDialog) {}

    private _action: 'acknowledge' | 'add' | 'update';

    get action() {
        return this._action;
    }

    @Input() set action(value: 'acknowledge' | 'add' | 'update') {
        this._action = value;
        switch (value) {
            case 'acknowledge':
                this.displayedColumns = this._ackknowledgeColumns;
                break;
            case 'add':
                this.displayedColumns = this._addColumns;
                break;
        }
    }

    private _hotLists: Array<TripCarHotListDto>;

    @Input() set hotLists(value: TripCarHotListDto[]) {
        if (value) {
            const formGroup = value.map((x) => {
                const form = this.metadata.getFormGroup();
                if (x.carNo) {
                    form.get('carNo').disable();
                }
                if (x.carInit) {
                    form.get('carInit').disable();
                }
                form.addControl(nameof<TripCarHotListDto>('tripId'), new UntypedFormControl());
                form.addControl(
                    nameof<TripCarHotListDto>('propagateHotListForward'),
                    new UntypedFormControl(),
                );
                form.patchValue(x);
                return form;
            });
            this.form = new UntypedFormArray(formGroup);
            this._hotLists = value;
        }
    }

    get hotList() {
        return this._hotLists;
    }

    ackAll($event: MatCheckboxChange) {
        this.selected = 2;
        if ($event.checked) {
            const dialogRef$ = this.dialog.open(this.responseDialog, {
                height: '600',
                width: '600',
                data: {
                    response: '',
                    selectedType: 'none',
                },
            });

            dialogRef$.afterClosed().subscribe((data) => {
                if (data.response) {
                    this.form.controls.forEach((control) => {
                        const responseControl = control.get(
                            nameof<RtCarHotList>('userResponse'),
                        ) as UntypedFormControl;

                        if (
                            (responseControl.value && data.selectedType) ||
                            (!responseControl.value && data.response)
                        ) {
                            let responseValue = responseControl.value ?? '';

                            switch (data.selectedType) {
                                case 'overwrite':
                                    responseValue = data.response;
                                    break;
                                case 'append':
                                    responseValue = `${responseValue} ${data.response}`;
                                    break;
                                default:
                                    responseValue =
                                        responseValue == '' ? data.response : responseValue;

                                    break;
                            }

                            responseControl.setValue(responseValue);
                        }
                    });
                }
            });
        }

        this.form.controls.forEach((control) => {
            const ackControl = control.get(
                nameof<RtCarHotList>('acknowledgeFlag'),
            ) as UntypedFormControl;
            this.setFlag($event, ackControl);
        });
    }
    types: { description: string; type: string }[] = [
        { description: 'Overwrite All responses', type: 'overwrite' },
        { description: 'Append All responses', type: 'append' },
        { description: 'None', type: 'none' },
    ];

    setFlag($event: MatCheckboxChange, acknowledgeFlag: UntypedFormControl) {
        acknowledgeFlag.setValue($event.checked ? 'Y' : 'N');
    }
}
