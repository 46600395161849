<ng-container *ngIf="(diversions$ | async)?.length > 0; else noDiversions">
    <dx-data-grid
        class="bds-dx-custom-material"
        [dataSource]="diversions$ | async"
        [showBorders]="true"
        [columnAutoWidth]="true"
    >
        <dxi-column
            dataField="divertDateTime"
            caption="Diversion Date"
            dataType="datetime"
            [format]="dateTimeFormat"
        ></dxi-column>
        <dxi-column dataField="diversionDescription" caption="Diversion Type"></dxi-column>
        <dxi-column dataField="le" caption="L/E"></dxi-column>
        <dxi-column dataField="road" caption="Road"></dxi-column>
        <dxi-column
            [calculateCellValue]="calculateDivertLoc"
            caption="Diversion Location"
        ></dxi-column>
        <dxi-column dataField="shipmentTypeDescription" caption="Shipment Type"></dxi-column>
        <dxi-column dataField="prevOrderNo" caption="Prev Order No"></dxi-column>
        <dxi-column dataField="prevBolNo" caption="Prev BOL No"></dxi-column>
        <dxo-master-detail [enabled]="true" [template]="'masterDetail'"></dxo-master-detail>

        <div *dxTemplate="let masterDetailItem of 'masterDetail'">
            <rt-diversion-list-item
                [diversion]="masterDetailItem.data"
                [dateTimeFormat]="dateTimeFormat"
            ></rt-diversion-list-item>
        </div>
    </dx-data-grid>
</ng-container>

<ng-template #noDiversions>
    <div class="empty-diversion-list">No Diversions on this Trip</div>
</ng-template>
