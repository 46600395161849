import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyFieldTemplatesModule } from '@bds/components';
import { VendorsModule } from '../../vendors';
import { EquipmentComponentComponentsModule } from '../../components';
import { EquipmentComponentsComponent } from './equipment-components.component';

@NgModule({
    declarations: [EquipmentComponentsComponent],
    imports: [
        CommonModule,
        EquipmentComponentComponentsModule,
        FormlyFieldTemplatesModule,
        VendorsModule,
    ],
    exports: [EquipmentComponentsComponent]
})
export class EquipmentComponentsModule {}
