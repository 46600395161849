import { Component, OnInit } from '@angular/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
  selector: 'lib-rt-day-in-to-origin-report',
  templateUrl: './rt-day-in-to-origin-report.component.html',
  styleUrls: ['./rt-day-in-to-origin-report.component.css']
})
export class RtDayInToOriginReportComponent implements OnInit {

    public gridId = 125;
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Days In to Origin Report';

  constructor() { }

  ngOnInit(): void {
  }

}
