import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { RtTripRefFieldDefService } from '@bds/railtrac';
import { RtTripRefFieldDef } from '@bds/railtrac-models';

@Component({
    selector: 'app-shipment-screen',
    template: ` <rt-trip
        [tripId]="tripId$ | async"
        [tripRefFieldDefs$]="tripRefFieldDefs$"
    ></rt-trip>`,
})
export class ShipmentScreenComponent implements OnInit {
    tripId$: Observable<number>;
    tripRefFieldDefs$: Observable<RtTripRefFieldDef[]>;

    constructor(
        private route: ActivatedRoute,
        private refFieldDefService: RtTripRefFieldDefService,
    ) {}

    ngOnInit(): void {
        this.tripId$ = this.route.params.pipe(map((params) => +params['ormId'] || 0));
        this.tripRefFieldDefs$ = this.refFieldDefService.getReferenceFieldDefs();
    }
}
