import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import { RtBusinessGroup, RtBusinessGroupAdapter } from '@bds/railtrac-models';

export abstract class BdsBusinessGroupApiServiceOptions {
    apiUrl: string = `api/customer/`;
    odataUrl: string = `odata/customer`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsBusinessGroupService {
    businessGroups: RtBusinessGroup[] = [];

    constructor(
        private http: HttpClient,
        private options: BdsBusinessGroupApiServiceOptions,
        public adapter: RtBusinessGroupAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    create(trip: RtBusinessGroup): Observable<RtBusinessGroup> {
        console.warn('RailtracRouteService.createRoute() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    delete(trip: RtBusinessGroup): Observable<RtBusinessGroup> {
        console.warn('RailtracRouteService.deleteRoute() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    getBusinessGroups(): Observable<RtBusinessGroup[]> {
        if (this.businessGroups && this.businessGroups.length > 0) {
            return of(this.businessGroups);
        }

        return this.http.get<RtBusinessGroup[]>(`${this.options.odataUrl}`).pipe(
            map((data: any) => {
                this.businessGroups = data.value;
                return data.value;
            }),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtBusinessGroup>('businessGroup'),
        ).server;

        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: keyField,
        });
    }

    patch(trip: RtBusinessGroup): Observable<RtBusinessGroup> {
        console.warn('RailtracRouteService.patchRoute() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    read(ormId: number): Observable<RtBusinessGroup> {
        return this.readViaOdata(ormId);
    }

    readViaApi(ormId: number): Observable<RtBusinessGroup> {
        return this.http.get<RtBusinessGroup>(`${this.options.apiUrl}/${ormId}`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    readViaOdata(ormId: number): Observable<RtBusinessGroup> {
        return this.http.get<RtBusinessGroup>(`${this.options.odataUrl}(${ormId})`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    update(trip: RtBusinessGroup): Observable<RtBusinessGroup> {
        console.warn('RailtracRouteService.updateRoute() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }
}
