<div class="mat-elevation-z6 equipment-report-categories-menu">
    <mat-toolbar class="customer-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <div class="col-10">
                <span>{{ equipInit }} {{ equipNumber }}</span>
                <span *ngIf="isSaving">
                    <small>
                        (saving...)
                        <fa-icon [icon]="iconSpinner" [spin]="true"></fa-icon>
                    </small>
                </span>
            </div>
            <div class="col-2">
                <button
                    mat-stroked-button
                    (click)="onSaveChanges()"
                    [disabled]="isSaving || !canSave"
                >
                    <fa-icon [icon]="iconSave" [fixedWidth]="true"></fa-icon>
                    <span class="text-uppercase">{{ saveText }}</span>
                </button>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
