import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EquipmentCategoryWithFields, EquipmentMechDate } from '../../../models';
import { FormlyFieldCreatorService } from '../../../internal-services';
import { HistoricDatesDialogComponent, HistoricDatesDialogModel } from '../../../internal-dialogs';

@Component({
    selector: 'bds-equipment-mech-dates',
    templateUrl: './equipment-mech-dates.component.html',
    styleUrls: ['./equipment-mech-dates.component.scss'],
})
export class EquipmentMechDatesComponent implements OnInit, OnChanges {
    displayFormat = 'MM/dd/yyyy';
    fields: FormlyFieldConfig[] = [];
    formValues = '';
    mechForm: UntypedFormGroup = new UntypedFormGroup({});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: any;
    serializationFormat = 'yyyy-MM-dd';

    @Input() equipmentId: number;
    @Input() equipmentInit: string;
    @Input() equipmentNo: string;
    @Input() mechDates: EquipmentMechDate[];
    @Input() mechFieldsByCategory: EquipmentCategoryWithFields[];
    @Output() datesSave: EventEmitter<EquipmentMechDate[]> = new EventEmitter<
        EquipmentMechDate[]
    >();
    @Output() dependenciesChange: EventEmitter<{ key: string; value: string }[]> = new EventEmitter<
        { key: string; value: string }[]
    >();
    @Output() historicDatesUpdate: EventEmitter<void> = new EventEmitter<void>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() modelChange: EventEmitter<{ value: any; isValid: boolean }> = new EventEmitter<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any;
        isValid: boolean;
    }>();

    constructor(
        public dialog: MatDialog,
        private formlyFieldCreatorService: FormlyFieldCreatorService,
    ) {}

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.model = this.createModel();
        this.createFieldsFromCategories();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.mechDates && !changes.mechDates.firstChange) {
            if (this.equipmentId === 0) {
                this.mechForm.enable();
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.model = this.createModel();
        }

        if (changes.mechFieldsByCategory && !changes.mechFieldsByCategory.firstChange) {
            this.createFieldsFromCategories();
        }
    }

    createFieldsFromCategories(): void {
        const formlyFields: FormlyFieldConfig[] = [];
        const requiredExpressions: { id: string; value: string }[] = [];
        const dictDependencies: { key: string; value: string }[] = [];

        if (this.mechFieldsByCategory && this.mechFieldsByCategory.length) {
            this.mechFieldsByCategory.forEach((c) => {
                c.mechFields.forEach((f) => {
                    if (f.dataType === 'D') {
                        const formField: FormlyFieldConfig =
                            this.formlyFieldCreatorService.createDateFormGroup(
                                f,
                                this.displayFormat,
                                this.serializationFormat,
                                this.onEditDate,
                                this.equipmentId !== 0,
                            );

                        if (f.requiredMechField) {
                            requiredExpressions.push({
                                id: f.requiredMechField,
                                value: `!!model.${f.mechFieldName}`,
                            });
                        }

                        formlyFields.push(formField);
                    }
                });
            });

            requiredExpressions.forEach((e) => {
                const fieldIndex: number = formlyFields.findIndex((f) =>
                    f.fieldGroup.find((g) => g.key === e.id + '.actual'),
                );
                if (fieldIndex > -1) {
                    const field: FormlyFieldConfig = formlyFields[fieldIndex];
                    if (field.fieldGroup.length === 1) {
                        // new single date
                        field.fieldGroup[0].expressionProperties['templateOptions.required'] =
                            e.value + '.actual';
                    } else if (
                        field.fieldGroup.length === 2 || // update single date
                        field.fieldGroup.length === 4
                    ) {
                        // new double date
                        field.fieldGroup[1].expressionProperties['templateOptions.required'] =
                            e.value + '.actual';
                    } else {
                        // update double date
                        field.fieldGroup[2].expressionProperties['templateOptions.required'] =
                            e.value + '.actual';
                    }
                } else {
                    dictDependencies.push({ key: e.id, value: e.value });
                }
            });

            this.dependenciesChange.emit(dictDependencies);
        }

        this.fields = [
            {
                fieldGroupClassName: 'w-100',
                fieldGroup: [...formlyFields],
            },
        ];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createModel(): any {
        const generatedModel = {};

        if (this.mechDates && this.mechDates.length) {
            this.mechDates.forEach((v) => {
                generatedModel[v.mechField] = { actual: v.actualDate, next: v.nextDueDate };
            });
        }

        return generatedModel;
    }

    onEditDate = (fieldName: string, fieldDescription: string, hasDueDate: boolean): void => {
        const dialogData: HistoricDatesDialogModel = {
            equipmentId: this.equipmentId,
            equipmentInit: this.equipmentInit,
            equipmentNo: this.equipmentNo,
            mechFieldName: fieldName,
            mechFieldDescription: fieldDescription,
            hasDueDate: hasDueDate,
        };

        const dialogRef = this.dialog.open(HistoricDatesDialogComponent, {
            width: '600px',
            data: dialogData,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.historicDatesUpdate.emit();
            }
        });
    };

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    onModelChanged(event: any): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.modelChange.emit({ value: event, isValid: this.mechForm.valid });
    }

    // Potential code for setting initial value of Due Date if unassigned
    // Would put this just after adding the fieldGroup to the formField
    // Removed until further information
    // formField.hooks = {
    //   onInit(field) {
    //     if (field.fieldGroup[1].formControl.value && !field.fieldGroup[3].formControl.value) {
    //       field.fieldGroup[3].formControl.setValue(
    //         createDueDate(field.fieldGroup[1].formControl.value, field.repeateInterval, field.repeateIntervalUm)
    //       );
    //     }
    //   },
    // };
}
