import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from '../adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

import { RtClm, ClmType } from './rt-clm';

export class RtClmArchive extends RtClm {
    constructor(
        public clmType: ClmType
    ) {
        super( );
    }
    from(trip: RtClmArchive | RtClm): RtClmArchive {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtClmArchive>('ormId'), server: 'ormId' },
    { client: nameof<RtClmArchive>('clmType'), server: 'clmType' },
    { client: nameof<RtClmArchive>('carInit'), server: 'carInit' },
    { client: nameof<RtClmArchive>('carNo'), server: 'carNo' },
    { client: nameof<RtClmArchive>('clmDateTime'), server: 'clmDateTime' },
    { client: nameof<RtClmArchive>('sightCode'), server: 'sightCode' },
    { client: nameof<RtClmArchive>('locationCity'), server: 'locationCity' },
    { client: nameof<RtClmArchive>('locationState'), server: 'locationState' },
    { client: nameof<RtClmArchive>('loadEmptyFlag'), server: 'loadEmptyFlag' },
    { client: nameof<RtClmArchive>('trainJunction'), server: 'trainJunction' },
    { client: nameof<RtClmArchive>('road'), server: 'road' },
    { client: nameof<RtClmArchive>('destinationCity'), server: 'destinationCity' },
    { client: nameof<RtClmArchive>('destinationState'), server: 'destinationState' },
    { client: nameof<RtClmArchive>('clmProcessedDate'), server: 'clmProcessedDate' },
    { client: nameof<RtClmArchive>('clmEvent'), server: 'clmEvent' },
    { client: nameof<RtClmArchive>('routeCode'), server: 'routeCode' },
    {
        client: nameof<RtClmArchive>('actualHoursToDestination'),
        server: 'actualHoursToDestination',
    },
    { client: nameof<RtClmArchive>('actualHoursToOrigin'), server: 'actualHoursToOrigin' },
    { client: nameof<RtClmArchive>('locationSplc'), server: 'locationSplc' },
    { client: nameof<RtClmArchive>('destinationSplc'), server: 'destinationSplc' },
    { client: nameof<RtClmArchive>('railRoadEtaCity'), server: 'railRoadEtaCity' },
    { client: nameof<RtClmArchive>('railRoadEtaState'), server: 'railRoadEtaState' },
    { client: nameof<RtClmArchive>('railRoadEtaSightCode'), server: 'railRoadEtaSightCode' },
    { client: nameof<RtClmArchive>('railRoadEtaDateTime'), server: 'railRoadEtaDateTime' },
    {
        client: nameof<RtClmArchive>('railRoadEtaDestinationSplc'),
        server: 'railRoadEtaDestinationSplc',
    },
    { client: nameof<RtClmArchive>('source'), server: 'source' },
    { client: nameof<RtClmArchive>('previousSplc'), server: 'previousSplc' },
    { client: nameof<RtClmArchive>('milesFromPrevious'), server: 'milesFromPrevious' },
    { client: nameof<RtClmArchive>('dwellTime'), server: 'dwellTime' },
    { client: nameof<RtClmArchive>('clmDifferentialTime'), server: 'clmDifferentialTime' },
];

@Injectable({
    providedIn: 'root',
})
export class RtClmArchiveAdapter extends TwoWayAdapter<RtClmArchive> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtClmArchiveMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtClmArchive>('ormId'),
                dataType: 'number',
                caption: 'ormId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('clmType'),
                dataType: 'ClmType',
                caption: 'clmType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('clmDateTime'),
                dataType: 'Date',
                caption: 'clmDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('sightCode'),
                dataType: 'string',
                caption: 'sightCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('locationCity'),
                dataType: 'string',
                caption: 'locationCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('locationState'),
                dataType: 'string',
                caption: 'locationState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('loadEmptyFlag'),
                dataType: 'string',
                caption: 'loadEmptyFlag',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('trainJunction'),
                dataType: 'string',
                caption: 'trainJunction',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('road'),
                dataType: 'string',
                caption: 'road',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('destinationCity'),
                dataType: 'string',
                caption: 'destinationCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('destinationState'),
                dataType: 'string',
                caption: 'destinationState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('clmProcessedDate'),
                dataType: 'Date',
                caption: 'clmProcessedDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('clmEvent'),
                dataType: 'string',
                caption: 'clmEvent',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('routeCode'),
                dataType: 'string',
                caption: 'routeCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('actualHoursToDestination'),
                dataType: 'number',
                caption: 'actualHoursToDestination',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('actualHoursToOrigin'),
                dataType: 'number',
                caption: 'actualHoursToOrigin',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('locationSplc'),
                dataType: 'string',
                caption: 'locationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('destinationSplc'),
                dataType: 'string',
                caption: 'destinationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('railRoadEtaCity'),
                dataType: 'string',
                caption: 'railRoadEtaCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('railRoadEtaState'),
                dataType: 'string',
                caption: 'railRoadEtaState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('railRoadEtaSightCode'),
                dataType: 'string',
                caption: 'railRoadEtaSightCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('railRoadEtaDateTime'),
                dataType: 'Date',
                caption: 'railRoadEtaDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('railRoadEtaDestinationSplc'),
                dataType: 'string',
                caption: 'railRoadEtaDestinationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('source'),
                dataType: 'string',
                caption: 'source',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('previousSplc'),
                dataType: 'string',
                caption: 'previousSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('milesFromPrevious'),
                dataType: 'number',
                caption: 'milesFromPrevious',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('dwellTime'),
                dataType: 'number',
                caption: 'dwellTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmArchive>('clmDifferentialTime'),
                dataType: 'number',
                caption: 'clmDifferentialTime',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtClmArchive>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('clmType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('clmDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('sightCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('locationCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('locationState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('loadEmptyFlag'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('trainJunction'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('road'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('destinationCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('destinationState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('clmProcessedDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('clmEvent'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('routeCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('actualHoursToDestination'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('actualHoursToOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('locationSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('destinationSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('railRoadEtaCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('railRoadEtaState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('railRoadEtaSightCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('railRoadEtaDateTime'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtClmArchive>('railRoadEtaDestinationSplc'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtClmArchive>('source'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('previousSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('milesFromPrevious'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('dwellTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmArchive>('clmDifferentialTime'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}

1
