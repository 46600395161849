import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { BdsRouteModule } from '../bds-route/components/bds-route.module';
import { RtRouteAdvancedSelectComponent } from './components/rt-route-advanced-select/rt-route-advanced-select.component';

@NgModule({
    declarations: [RtRouteAdvancedSelectComponent],
    imports: [
        BdsRouteModule,
        CommonModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxValidatorModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSnackBarModule,
    ],
    exports: [RtRouteAdvancedSelectComponent],
})
export class RtRouteSmartComponentsModule {}
