<bds-origin-criteria-list
    [originStore]="origins"
    [originCodeStore]="originCodes"
    [railroadStore]="railroads"
    [stateLookup]="stateLookup"
    [sightCodeLookup]="sightCodeLookup"
    [originCode]="calculatedOriginCode"
    [latestClm]="latestClm"
    (originDelete)="onDelete($event)"
    (originSaved)="onSaved($event)"
>
</bds-origin-criteria-list>
