<div *ngIf="showToolbar">
    <mat-toolbar class="mb-1" [class.hidden]="showToolbar">
        <div style="display: flex; justify-content: space-between">
            <div *ngIf="showSortButtons">
                <button mat-icon-button class="mr-1">
                    <fa-icon [icon]="sortAscending" (click)="onSortAscending()"></fa-icon>
                </button>
                <button mat-icon-button class="">
                    <fa-icon [icon]="sortDescending" (click)="onSortDescending()"></fa-icon>
                </button>
            </div>
            <div *ngIf="showButtons" style="flex-grow: 1">
                <button mat-flat-button (click)="onAddComment()">
                    <fa-duotone-icon
                        [icon]="commentAdd"
                        primaryColor="forestgreen"
                        secondaryColor="#97bdd3"
                    ></fa-duotone-icon>
                    Add Comment
                </button>
            </div>
        </div>
    </mat-toolbar>
</div>

<mat-card *ngFor="let c of comments" class="mb-1">
    <mat-card-content>
        <div style="display: flex; align-items: center">
            <div>
                <fa-duotone-icon
                    *ngIf="!c.commentType"
                    [icon]="commentGenericIconDuo"
                    size="3x"
                    [fixedWidth]="true"
                    primaryColor="white"
                    secondaryColor="#97bdd3"
                    [secondaryOpacity]="0.7"
                ></fa-duotone-icon>
                <fa-layers *ngIf="!!c.commentType" size="3x" [fixedWidth]="true">
                    <fa-duotone-icon
                        [icon]="commentBlankIconDuo"
                        primaryColor="white"
                        secondaryColor="#97bdd3"
                        [secondaryOpacity]="0.7"
                    ></fa-duotone-icon>
                    <fa-layers-text
                        [content]="c.commentType"
                        transform="shrink-9 up-1.5"
                        style="color: white"
                    ></fa-layers-text>
                </fa-layers>
            </div>
            <div style="flex-grow: 1" class="ml-3">
                <div style="display: flex; justify-content: space-between">
                    <div>
                        <!-- {{ c.commentDate }} -->
                        <div style="opacity: 0.8">
                            {{ c.userId }} &mdash; {{ c.commentDate | date : 'short' : 'UTC' }}
                        </div>
                        <div style="font-weight: bold">
                            <span *ngIf="!!c.commentType">{{
                                c.commentType | lookup : commentTypes : 'category' : 'description'
                            }}</span>
                            <span *ngIf="!c.commentType">General Comment</span>
                        </div>
                    </div>
                    <div *ngIf="!hideButtons">
                        <button mat-icon-button (click)="onEditComment(c.ormId)">
                            <fa-duotone-icon
                                [icon]="commentEdit"
                                primaryColor="#005c92"
                                secondaryColor="#005c92"
                                [fixedWidth]="true"
                            ></fa-duotone-icon>
                        </button>
                        <button mat-icon-button (click)="onDeleteComment(c.ormId)">
                            <fa-duotone-icon
                                [icon]="commentDelete"
                                primaryColor="red"
                                secondaryColor="red"
                                [fixedWidth]="true"
                            ></fa-duotone-icon>
                        </button>
                    </div>
                </div>
                <div>
                    <div class="uppercase">
                        {{ c.comments }}
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
