import { Injectable } from '@angular/core';
import { From, IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';

export class RtSegmentMiles implements From<RtSegmentMiles> {
    constructor(
        public ormId?: number,
        public beginSplc?: string,
        public endSplc?: string,
        public railroad?: string,
        public entryDate?: Date,
        public miles?: number,
        public benchmarkHours?: number,
        public userId?: string,
        public isActive?: string,
        public weightLimit?: number,
    ) {}

    from(miles: RtSegmentMiles): RtSegmentMiles {
        Object.keys(miles).forEach((k) => (this[k] = miles[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtSegmentMiles>('beginSplc'), server: 'beginSplc' },
    { client: nameof<RtSegmentMiles>('endSplc'), server: 'endSplc' },
    { client: nameof<RtSegmentMiles>('railroad'), server: 'railroad' },
    { client: nameof<RtSegmentMiles>('entryDate'), server: 'entryDate' },
    { client: nameof<RtSegmentMiles>('miles'), server: 'miles' },
    { client: nameof<RtSegmentMiles>('benchmarkHours'), server: 'benchmarkHours' },
    { client: nameof<RtSegmentMiles>('userId'), server: 'userId' },
    { client: nameof<RtSegmentMiles>('isActive'), server: 'isActive' },
    { client: nameof<RtSegmentMiles>('weightLimit'), server: 'weightLimit' },
];

@Injectable({
    providedIn: 'root',
})
export class RtSegmentMilesAdapter extends TwoWayAdapter<RtSegmentMiles> {
    public metadata: IModelMetadata[] = relationship;

    constructor() {
        super();
    }
}
