import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { nameof } from '@bds/core';
import { RtCommodityShippedMetadata } from '@bds/railtrac-models';
import { RtCommodityShipped } from '@bds/railtrac-models';

@Injectable()
export class TripCommodityShippedFormService {
    private readonly _form: UntypedFormGroup;
    constructor(tcsMetadata: RtCommodityShippedMetadata) {
        this._form = tcsMetadata.getFormGroup();
    }

    initReadOnlyForm(): void {
        this.commodityAmt.disable();
        this.commodityCode.disable();
        this.commodityCompart.disable();
        this.commodityRef1.disable();
        this.commodityRef2.disable();
        this.commodityRef3.disable();
        this.commodityRef4.disable();
    }

    get form(): UntypedFormGroup {
        return this._form;
    }

    //#region "FormControls"
    get commodityAmt(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtCommodityShipped>('commodityAmt'));
    }

    get commodityCode(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtCommodityShipped>('commodityCode'));
    }

    get commodityCompart(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtCommodityShipped>('commodityCompart'));
    }

    get commodityRef1(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtCommodityShipped>('commodityRef1'));
    }

    get commodityRef2(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtCommodityShipped>('commodityRef2'));
    }

    get commodityRef3(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtCommodityShipped>('commodityRef3'));
    }

    get commodityRef4(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<RtCommodityShipped>('commodityRef4'));
    }
    //#endregion "FormControls"
}

