import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BdsDialogConfirmComponent,
    BdsDialogConfirmModel,
    BdsDxDataGridDirective,
    bdsGridModeType,
} from '@bds/components';
import { RtCarHotListService, RtvCarHotListGridService } from '@bds/data-access';
import { RtCarHotList } from '@bds/railtrac-models';
import DataSource from 'devextreme/data/data_source';
import { firstValueFrom, forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { TripCarHotListDto } from '@bds/railtrac-models';
import {
    BdsHotListDetailsDialogComponent,
    BdsHotListDialogData,
} from '../bds-hot-list-details-dialog/bds-hot-list-details-dialog.component';
import { FastracDxGridWrapperComponent, FastracScreenBaseComponent } from '@bds/fastrac';
import { FastracColsService } from '@bds/services';
import { CommunicationService } from '@bds/core';
import { CarIdTransformService } from '@bds/helpers';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'bds-hotlist-grid',
    templateUrl: './bds-hotlist-grid.component.html',
    styleUrls: ['./bds-hotlist-grid.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class BdsHotlistGridComponent
    extends FastracScreenBaseComponent
    implements OnInit, OnDestroy
{
    dataSource: DataSource;
    fastracId: number = 0;
    @Input() gridId: number = 99;
    gridMode: bdsGridModeType = 'multiple';
    showFastrac = false;
    public selectedHotLists = new Array<RtCarHotList>();
    public selectedRowKeys: Array<number> = [];

    @ViewChild('dxGridWrapper') fastracGridComponent: FastracDxGridWrapperComponent;
    @ViewChild('targetDirective') targetDirective: BdsDxDataGridDirective;

    get grid() {
        return this.fastracGridComponent?.grid;
    }
    constructor(
        private bdsCarHotListService: RtCarHotListService,
        private bdsCarHotListGridService: RtvCarHotListGridService,
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        private dialogService: MatDialog,
        private clipboard: Clipboard,
        public snackbar: MatSnackBar,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
    }

    add() {
        const uniqueEquipment = this.getUniqueEquipment();
        this.checkTripIsOpen(uniqueEquipment)
            .pipe(
                switchMap((response) => (response ? of('confirm') : this.openConfirmationDialog())),
                filter((x) => x),
                switchMap(() => this.openAddDialog(uniqueEquipment)),
            )
            .subscribe();
    }

    navigate(selection: Array<RtCarHotList>) {
        if (selection.length > 0) {
            this.selectedHotLists = selection;
            this.router.navigate(['./details'], {
                relativeTo: this.route,
                queryParams: { ids: selection.map((x) => x.ormId).join(',') },
            });
        }
    }

    ngOnInit() {
        super.ngOnInit();
        this.dataSource = this.bdsCarHotListGridService.getODataStore('ormId');

        this.bdsCarHotListService.refreshHotListGridChangeEmitted$.subscribe(() =>
            this.grid.instance.refresh(),
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private buildHotListDefaults(uniqueEquipment: TripCarHotListDto[]) {
        if (uniqueEquipment.length > 0) {
            return uniqueEquipment.map(
                (x) =>
                    <TripCarHotListDto>{
                        hotListType: 'GNRL',
                        hotListCategory: 'GNRL',
                        carNo: x.carNo,
                        carInit: x.carInit,
                        propagateHotListForward: x.propagateHotListForward,
                    },
            );
        } else {
            return [<TripCarHotListDto>{ hotListType: 'GNRL', hotListCategory: 'GNRL' }];
        }
    }

    private checkTripIsOpen(uniqueEquipment: TripCarHotListDto[]): Observable<boolean> {
        return uniqueEquipment.length === 0
            ? of(true)
            : forkJoin(uniqueEquipment.map((x) => this.getTripClosed(x))).pipe(
                  map((response) => response.every((x) => x === true)),
              );
    }

    private getTripClosed(x: TripCarHotListDto) {
        return this.bdsCarHotListService.checkEquipmentHasOpenTrip(x.carInit, x.carNo).pipe(
            tap((response) => {
                x.propagateHotListForward = response;
            }),
        );
    }

    private getUniqueEquipment(): TripCarHotListDto[] {
        const equipment = this.selectedHotLists.reduce((unique, item) => {
            const includes = unique.find(
                (x) => x.carInit === item.carInit && x.carNo === item.carNo,
            );
            return includes ? unique : [...unique, item];
        }, <RtCarHotList[]>[]);
        return equipment as TripCarHotListDto[];
    }

    private openAddDialog(uniqueEquipment: TripCarHotListDto[]) {
        const hotLists = this.buildHotListDefaults(uniqueEquipment);
        const dialogRef = this.dialogService.open(BdsHotListDetailsDialogComponent, {
            data: <BdsHotListDialogData>{
                action: 'add',
                title: `Add ${hotLists.length} Hot Lists`,
                hotLists: hotLists,
            },
            width: '100',
        });

        return dialogRef.afterClosed().pipe(
            tap((response) => {
                if (response) {
                    this.grid.instance.refresh();
                    this.navigate(response.relatedObject);
                }
            }),
        );
    }

    private openConfirmationDialog() {
        const confirmInfo: BdsDialogConfirmModel = {
            content: `One or more of the trips selected is Closed. Are you sure you would like to continue?`,
            actionText: 'Yes',
            dismissiveText: 'No',
            defaultToYes: false,
        };
        const dialogRef = this.dialogService.open(BdsDialogConfirmComponent, {
            width: '430px',
            data: confirmInfo,
        });

        return dialogRef.afterClosed();
    }

    copyCarsToClipboard(): void {
        this.selectedHotLists = this.grid.instance.getSelectedRowsData();
        if (this.selectedHotLists != null && this.selectedHotLists.length > 0) {
            const carIds = this.selectedHotLists.map((eachRow) => {
                return eachRow.carInit.trim() + eachRow.carNo.trim();
            });
            this.clipboard.copy(carIds.join('\n'));
        }
    }

    onDeleteRecordsClicked(): void {
        let confirmInfo: BdsDialogConfirmModel = {
            content: `Please select Hot lists to delete.`,
            actionText: 'Ok',
        };

        if (this.selectedRowKeys.length) {
            confirmInfo = {
                content: `Are you sure you want to delete ${this.selectedRowKeys.length} Hot List${
                    this.selectedRowKeys.length > 1 ? 's' : ''
                }?`,
                actionText: 'Delete',
            };
        }

        const dialogRef = this.dialogService.open(BdsDialogConfirmComponent, {
            data: confirmInfo,
        });

        dialogRef.beforeClosed().subscribe(async (obs) => {
            if (obs) {
                const result = await firstValueFrom(
                    this.bdsCarHotListService.deleteHotLists(this.selectedRowKeys),
                );

                if (result?.succeeded) {
                    this.snackbar.open('Hot List deleted', 'Ok', {
                        duration: 8000,
                    });
                    void this.grid.instance.refresh();
                    this.router.navigate(['/hotlist'], {});

                    return;
                }

                this.snackbar.open('Failed to delete', 'Ok', {
                    duration: 8000,
                });
                return;
            }
        });
    }
}
