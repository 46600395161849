import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { nameof } from '@bds/core';
import { FormErrorHandlerService } from '@bds/helpers';
import { EquipComponentDate, EquipmentMechDate } from '../../../models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'bds-historic-dates-details',
    templateUrl: './historic-dates-details.component.html',
    styleUrls: [
        './historic-dates-details.component.scss',
        '../../../styles/equipment-common-styles.scss',
    ],
})
export class HistoricDatesDetailsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() hasDueDate = false;
    @Input() selectedDate: EquipmentMechDate | EquipComponentDate;
    @Output() dateChange: EventEmitter<{
        value: EquipmentMechDate | EquipComponentDate;
        valid: boolean;
    }> = new EventEmitter<{
        value: EquipmentMechDate | EquipComponentDate;
        valid: boolean;
    }>();
    @Output() dateDelete: EventEmitter<number> = new EventEmitter<number>();

    dateForm: UntypedFormGroup;
    dateFormat = 'MM/dd/yyyy';
    iconDelete = faTrashAlt;
    initializingForm = true;

    private ngUnsubscribe$: Subject<void> = new Subject<void>();

    constructor(private formErrorService: FormErrorHandlerService) {
        this.dateForm = this.getFormGroup();
    }

    ngOnInit() {
        this.dateForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            if (!this.initializingForm) {
                const formDate: EquipmentMechDate = {
                    ...this.selectedDate,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    actualDate: this.dateForm.get('actualDate').value,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    nextDueDate: this.dateForm.get('nextDueDate').value,
                };
                this.dateChange.emit({ value: formDate, valid: this.dateForm.valid });
            }

            this.initializingForm = false;
        });

        if (this.selectedDate) {
            this.dateForm.patchValue(this.selectedDate);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initializingForm = true;
        if (changes.selectedDate && !changes.selectedDate.firstChange && this.selectedDate) {
            this.dateForm.patchValue(this.selectedDate);
        } else {
            this.dateForm.reset();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        return this.formErrorService.getFormError(formItem);
    }

    getFormGroup(): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<EquipmentMechDate>('actualDate'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(nameof<EquipmentMechDate>('nextDueDate'), new UntypedFormControl('', []));

        return formGroup;
    }

    onDeleteClick(): void {
        this.dateDelete.emit(this.selectedDate.ormId);
    }
}
