import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ODataStore from 'devextreme/data/odata/store';

export abstract class RtRouteSegmentsApiServiceOptions {
    apiUrl = `api/routesegment/`;
    odataUrl = `odata/routesegment`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsRouteSegmentsService {
    constructor(private options: RtRouteSegmentsApiServiceOptions, private http: HttpClient) {}

    getODataStore(): ODataStore {
        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: 'ormId',
            deserializeDates: false,
        });
    }
}
