<mat-toolbar class="mb-1" *ngIf="showSortButtons">
    <div style="display: flex; justify-content: space-between">
        <button mat-icon-button class="mr-1">
            <fa-icon [icon]="sortAscending" (click)="onSortAscending()"></fa-icon>
        </button>
        <button mat-icon-button class="">
            <fa-icon [icon]="sortDescending" (click)="onSortDescending()"></fa-icon>
        </button>
    </div>
</mat-toolbar>
<div>
    Trip: {{ trip.carInit }} {{ trip.carNo }}
    {{ trip.shipDatetime | date : 'MM-dd-yyyy HH:mm:ss' : 'UTC' }}
</div>
<ng-container *ngFor="let item of comments; let i = index; let l = last; let f = first">
    <ng-container *ngIf="!f">
        <br />
    </ng-container>
    <rt-multi-comment-edit-form-item #commentEditFormItem [comment]="item" [index]="i">
    </rt-multi-comment-edit-form-item>
</ng-container>
