import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import ODataStore from 'devextreme/data/odata/store';
import { BdsDialogInfoComponent, BdsDialogInfoModel } from '@bds/components';
import { RtBusinessGroup, RtFleetName, RtWhoPays } from '@bds/railtrac-models';
import { BdsBusinessGroupService } from '../../bds-business-group/bds-business-group.service';
import { BdsFleetService } from '../../bds-fleet/bds-fleet.service';
import { BdsSplcErpcService } from '@bds/data-access';
import { RtRouteService } from '@bds/data-access';
import { RtDiversionService } from '../services/rt-diversion.service';
import { RtTripsByRoad } from '../models/rt-trips-by-road.model';
import { RtDiversionFormModel } from '../models/rt-diversion-form.model';

@Component({
    selector: 'rt-new-diversion',
    templateUrl: './rt-new-diversion.component.html',
    styleUrls: ['./rt-new-diversion.component.scss'],
})
export class RtNewDiversionComponent implements OnChanges, OnDestroy {
    businessGroupList$: Observable<RtBusinessGroup[]>;
    diversion: RtDiversionFormModel;
    divertTypes$: Observable<{ key: string; value: string }[]>;
    errorMessage = '';
    fleetList$: Observable<RtFleetName[]>;
    hasLatestTripsToDivert = false;
    isSaving = false;
    routeList: ODataStore;
    showCreateDiversion$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    splcList: ODataStore;
    whoPays$: Observable<RtWhoPays[]>;

    @Input() creatingDiversion: boolean;
    @Input() tripIds: number[] = [];
    @Output() diversionCancelled: EventEmitter<any> = new EventEmitter();
    @Output() diversionCreated: EventEmitter<any> = new EventEmitter();

    constructor(
        public diversionService: RtDiversionService,
        public splcService: BdsSplcErpcService,
        public routeService: RtRouteService,
        public fleetService: BdsFleetService,
        public businessGroupService: BdsBusinessGroupService,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
    ) {
        this.divertTypes$ = diversionService.getDivertTypes();
        this.businessGroupList$ = businessGroupService.getBusinessGroups();
        this.fleetList$ = fleetService.getFleetNames();
        this.whoPays$ = diversionService.getWhoPaysList();
        this.splcList = splcService.getODataStore();
        this.routeList = routeService.getODataStore();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.creatingDiversion && this.creatingDiversion !== undefined) {
            if (this.tripIds && this.creatingDiversion && !this.hasLatestTripsToDivert) {
                this.getTripsForDiversion();
                this.showCreateDiversion$.next(this.creatingDiversion);
            } else if (!this.creatingDiversion) {
                this.showCreateDiversion$.next(this.creatingDiversion);
            }
        }

        if (changes.tripIds && !changes.tripIds.firstChange) {
            this.hasLatestTripsToDivert = false;
            this.cancelDiversion();
        }
    }

    ngOnDestroy() {
        this.showCreateDiversion$.complete();
    }

    cancelDiversion() {
        this.showCreateDiversion$.next(false);
        this.errorMessage = '';
        this.diversionCancelled.emit();
    }

    createDiversion(roads: RtTripsByRoad[]) {
        if (this.roadsHaveTrips(roads)) {
            this.diversion = {
                header: {
                    divertDateTime: null,
                    divertType: null,
                    le: roads[0].diversions[0].clmLe,
                    newCustCity: null,
                    newCustName: null,
                    newCustNo: null,
                    newCustState: null,
                    newReturnCity: null,
                    newReturnState: null,
                    newReturnSplc: null,
                    shipmentType: null,
                    whoPays: null,
                },

                roads: roads,
            };
        }
    }

    getShowCreateDiversion() {
        return this.showCreateDiversion$.asObservable();
    }

    getTripsForDiversion() {
        this.diversionService
            .getTripsToDivert(this.tripIds)
            .pipe(take(1))
            .subscribe(
                (roads) => {
                    this.showCreateDiversion$.next(true);
                    this.hasLatestTripsToDivert = true;
                    return this.createDiversion(roads);
                },
                (err) => {
                    if (err && err['status'] === 422) {
                        const info: BdsDialogInfoModel = {
                            title: 'Divert Error',
                            content: err['message'] || 'Unable to divert selected trip(s).',
                        };
                        this.dialog.open(BdsDialogInfoComponent, {
                            data: info,
                        });
                    } else {
                        this.snackbar.open('Error retrieving trips!', 'Ok', {
                            duration: 3000,
                            panelClass: 'error-snack',
                        });
                    }
                    this.diversionCancelled.emit();
                },
            );
    }

    onCancelClick() {
        this.cancelDiversion();
        this.diversion = { ...this.diversion };
    }

    onCreateClick() {
        if (!this.hasLatestTripsToDivert) {
            this.getTripsForDiversion();
        } else {
            this.showCreateDiversion$.next(true);
        }
    }

    onSaveClick(event: RtDiversionFormModel) {
        this.isSaving = true;
        this.errorMessage = '';

        this.diversionService
            .create(event)
            .pipe(take(1))
            .subscribe(
                (result) => this.afterSaveComplete(),
                (err) => this.afterError(err),
            );
    }

    roadsHaveTrips(roads: RtTripsByRoad[]): boolean {
        if (
            roads !== undefined &&
            roads.length > 0 &&
            roads[0].diversions !== undefined &&
            roads[0].diversions.length > 0
        ) {
            return true;
        }

        return false;
    }

    private afterError(error: any) {
        const customerErrors = [];
        this.errorMessage = 'Error saving diversions!';

        if (error && error['status'] === 422 && error['error']) {
            this.errorMessage = error['error'];
        }

        this.snackbar.open(this.errorMessage, 'Error', {
            duration: 3000,
            panelClass: 'error-snack',
        });
        this.isSaving = false;
    }

    private afterSaveComplete(message?: string) {
        if (!message) {
            message = 'Changes saved!';
        }

        this.isSaving = false;
        this.snackbar.open(message, 'Ok', { duration: 3000 });
        this.showCreateDiversion$.next(false);
        this.diversion = { ...this.diversion };
        this.diversionCreated.emit();
    }
}
