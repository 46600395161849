import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { BdsDxDataGridModule, BdsDialogConfirmModule } from '@bds/components';
import { BdsSmartComponentsModule } from '@bds/smart-components';
import { BourqueCoreModule } from '@bds/core';
import { BdsDataAccessModule } from '@bds/data-access';
import { BdsDestinationCriteriaComponent } from './bds-destination-criteria.component';
import { BdsDestinationCriteriaListComponent } from './components/bds-destination-criteria-list/bds-destination-criteria-list.component';
import { BdsDestinationCriteriaDialogComponent } from './components/bds-destination-criteria-dialog/bds-destination-criteria-dialog.component';
import { BdsDestinationCriteriaDetailsComponent } from './components/bds-destination-criteria-details/bds-destination-criteria-details.component';
import { BdsDestinationCriteriaGridDialogComponent } from './components/bds-destination-criteria-grid-dialog/bds-destination-criteria-grid-dialog.component';

@NgModule({
    declarations: [
        BdsDestinationCriteriaComponent,
        BdsDestinationCriteriaListComponent,
        BdsDestinationCriteriaDialogComponent,
        BdsDestinationCriteriaDetailsComponent,
        BdsDestinationCriteriaGridDialogComponent,
    ],
    imports: [
        BdsDataAccessModule,
        BdsSmartComponentsModule,
        BdsDialogConfirmModule,
        BdsDxDataGridModule,
        BourqueCoreModule,
        CommonModule,
        DxDataGridModule,
        DxLookupModule,
        FontAwesomeModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSnackBarModule,
        MatToolbarModule,
        ReactiveFormsModule,
    ],
    exports: [
        BdsDestinationCriteriaComponent,
        BdsDestinationCriteriaDialogComponent,
        BdsDestinationCriteriaGridDialogComponent,
    ],
})
export class BdsDestinationCriteriaModule {}
