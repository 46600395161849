<form [formGroup]="form">
    <div class="row">
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-label>Comment Type</mat-label>
                <mat-select
                    formControlName="commentType"
                    (selectionChange)="getDefaultComments($event.value)"
                >
                    <mat-option value="">-- General Comment --</mat-option>
                    <mat-option *ngFor="let item of commentTypes | async" [value]="item.category">{{
                        item.description
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-label>Comment Code</mat-label>
                <mat-select
                    formControlName="commentCode"
                    (selectionChange)="setDefaultComment($event.value)"
                    [disabled]="form.controls['commentType'].value === ''"
                >
                    <mat-option value=""></mat-option>
                    <mat-option *ngFor="let item of defaultComments | async" [value]="item.code">
                        {{ item.code }} - {{ item.comment }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- <div><mat-form-field><mat-label>Car Initials</mat-label><input matInput bdsShoutcase formControlName="carInit"></mat-form-field></div>
    <div><mat-form-field><mat-label>Car Number</mat-label><input matInput bdsShoutcase formControlName="carNo"></mat-form-field></div>
    <div><mat-form-field><mat-label>Shipment Date</mat-label><input matInput bdsShoutcase formControlName="shipDateTime"></mat-form-field></div>
    <div><mat-form-field><mat-label>Comment Date</mat-label><input matInput bdsShoutcase formControlName="commentDate"></mat-form-field></div>
    <div><mat-form-field><mat-label>User ID</mat-label><input matInput bdsShoutcase formControlName="userId"></mat-form-field></div> -->
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label>Comments</mat-label>
                <textarea
                    matInput
                    cdkTextareaAutosize
                    formControlName="comments"
                    cdkAutosizeMinRows="2"
                    cdkAutosizeMaxRows="5"
                    class="uppercase"
                ></textarea>
                <mat-error *ngIf="form.controls.comments.errors">
                    {{ getError(form.controls.comments) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="mb-3">
            <mat-slide-toggle
                formControlName="transmitToCust"
                [checked]="form.controls.transmitToCust.value === 'Y'"
                (change)="setFlag($event.checked, form?.controls?.transmitToCust)"
                >Show to Consignee in RAILTRACAdvize</mat-slide-toggle
            >
        </div>
    </div>
    <!-- <div><mat-form-field><mat-label>Transmit To Customer?</mat-label><input matInput bdsShoutcase formControlName="transmitToCust"></mat-form-field></div>
    <div><mat-form-field><mat-label>Comment Type</mat-label><input matInput bdsShoutcase formControlName="commentType"></mat-form-field></div>
    <div><mat-form-field><mat-label>Comment Code</mat-label><input matInput bdsShoutcase formControlName="commentCode"></mat-form-field></div>
    <div><mat-form-field><mat-label>Orm ID</mat-label><input matInput bdsShoutcase formControlName="ormId"></mat-form-field></div>
    <div><mat-form-field><mat-label>RtTrip Comment OrmId</mat-label><input matInput bdsShoutcase formControlName="RtTripCommentOrmId"></mat-form-field></div>
    <div><mat-form-field><mat-label>Updated Date</mat-label><input matInput bdsShoutcase formControlName="updateDateTime"></mat-form-field></div> -->
</form>
