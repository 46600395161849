<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <dx-data-grid
        id="bdsReportGrid"
        [dataSource]="gridSource"
        height="80vh"
        minHeight="200px"
        dataRowTemplate="dataRowTemplateName"
    >
        <dxi-column caption="Car Status"></dxi-column>
        <dxi-column caption="Car ID"></dxi-column>
        <dxi-column caption="Ship Date"></dxi-column>
        <dxi-column caption="Consignee Name"> </dxi-column>
        <dxi-column caption="INB/OUT"></dxi-column>
        <dxi-column caption="ETA"></dxi-column>
        <dxi-column caption="Actual Arrival"></dxi-column>
        <dxi-column caption="Variance"></dxi-column>
        <dxi-column caption="Origin"></dxi-column>
        <dxi-column caption="Destination"></dxi-column>
        <dxi-column caption="Fleet"></dxi-column>
        <dxi-column caption="Bus Group"></dxi-column>
        <dxi-column caption="Trip Status"></dxi-column>
        <dxi-column caption="Shop Move"></dxi-column>
        <dxi-column caption="Free Runner"></dxi-column>
        <dxi-column caption="Divert?"></dxi-column>
        <dxi-column caption="Bad Order Days"></dxi-column>
        <dxi-column caption="One Way Round"></dxi-column>
        <dxi-column caption="Manual Close"></dxi-column>
        <dxi-column caption="Off Route"></dxi-column>
        <dxi-column caption="----Destination----">
            <dxi-column caption="Arrival"></dxi-column>
            <dxi-column caption="Type"></dxi-column>
        </dxi-column>
        <dxi-column caption="----Cust Delivery----">
            <dxi-column caption="Date"></dxi-column>
            <dxi-column caption="Type"></dxi-column>
        </dxi-column>
        <dxi-column caption="----Cust Release----">
            <dxi-column caption="Date"></dxi-column>
            <dxi-column caption="Type"></dxi-column>
        </dxi-column>
        <dxi-column caption="----Trip Close----">
            <dxi-column caption="Date"></dxi-column>
            <dxi-column caption="Type"></dxi-column>
        </dxi-column>
        <dxi-column caption="Comments"></dxi-column>
        <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
            <tr class="main-row">
                <td>{{ item.data.car_status }}</td>
                <td>{{ item.data.car_init }} {{ item.data.car_no }}</td>
                <td>{{ item.data.ship_date_time }}</td>
                <td>{{ item.data.cust_name }}</td>
                <td>{{ item.data.inbound_outbound }}</td>
                <td>{{ item.data.original_eta_to_cust }}</td>
                <td>{{ item.data.orig_eda_eta_cust }}</td>
                <td>{{ item.data.variance }}</td>
                <td>{{ item.data.orig_city }} {{ item.data.orig_state }}</td>
                <td>{{ item.data.dest_city }} {{ item.data.dest_state }}</td>
                <td>{{ item.data.fleet_id }}</td>
                <td>{{ item.data.business_group }}</td>
                <td>{{ item.data.trip_status }}</td>
                <td>{{ item.data.shop_move }}</td>
                <td>{{ item.data.free_runner }}</td>
                <td>{{ item.data.divert_yorn }}</td>
                <td>{{ item.data.bad_order_days }}</td>
                <td>{{ item.data.one_way_round }}</td>
                <td>{{ item.data.eta_manual_close }}</td>
                <td>{{ item.data.off_route_status }}</td>
                <td>{{ item.data.dest_arr_date_time }}</td>
                <td>{{ item.data.dest_arr_type }}</td>
                <td>{{ item.data.cust_dlv_date_time }}</td>
                <td>{{ item.data.cust_dlv_type }}</td>
                <td>{{ item.data.cust_rel_date_time }}</td>
                <td>{{ item.data.cust_rel_type }}</td>
                <td>{{ item.data.trip_cls_date_time }}</td>
                <td>{{ item.data.trip_cls_type }}</td>
                <td>{{ item.data.COMMENTS }}</td>
            </tr>
            <tr class="second-row">
                <td></td>
                <td colspan="4">
                    <div>
                        <span><b>Current Location: </b></span>{{ item.data.loc_city }}
                        {{ item.data.loc_state }}
                    </div>
                </td>
                <td>{{ item.data.sight_code }}</td>
                <td>{{ item.data.clm_date_time }}</td>
                <td>{{ item.data.le }}</td>
                <td>{{ item.data.road }}</td>
                <td>{{ item.data.trn_jct }}</td>
            </tr>
        </ng-container>
    </dx-data-grid>
</div>
