import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { faSave, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { ScrollSpyService } from '@bds/helpers';
import { MenuLinkItem } from '@bds/core';
import { Equipment } from '../../../models';

@Component({
    selector: 'bds-equipment-details-menu',
    templateUrl: './equipment-details-menu.component.html',
    styleUrls: ['./equipment-details-menu.component.scss'],
})
export class EquipmentDetailsMenuComponent implements OnInit, OnChanges, OnDestroy {
    canSave = true;
    filteredMenuLinks: MenuLinkItem[] = [];
    iconSave = faSave;
    iconSpinner = faSpinner;

    @Input() equipment: Equipment;
    @Input() isSaving = false;
    @Input() menuLinks: MenuLinkItem[];
    @Output() saveChanges: EventEmitter<void> = new EventEmitter<void>();
    @Output() selectSection: EventEmitter<string> = new EventEmitter<string>();

    private ngUnsubscribe$: Subject<void> = new Subject<void>();

    constructor(public scrollSpyService: ScrollSpyService) {
        scrollSpyService.currentSection
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((section) => {
                // TODO: When user roles are defined, also account for user permissions here
                if (section === 'mechanchor' || section === 'listanchor') {
                    this.canSave = true;
                } else {
                    this.canSave = false;
                }
            });
    }

    ngOnInit() {
        this.filterMenuLinks();
        this.setFirstLink();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.menuLinks && !changes.menuLinks.firstChange) {
            this.filterMenuLinks();
            this.setFirstLink();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    filterMenuLinks(): void {
        if (this.menuLinks && this.menuLinks.length > 0) {
            this.filteredMenuLinks = this.menuLinks.filter((m) => !m.hide);
        }
    }

    onSaveChanges(): void {
        this.saveChanges.emit();
    }

    onSelectElement(target: string): void {
        this.selectSection.emit(target);
    }

    setFirstLink(): void {
        if (this.filteredMenuLinks.length > 0) {
            this.scrollSpyService.changeSection(this.filteredMenuLinks[0].jumpLink);
        }
    }
}
