import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtCustomerTypeDefault {
    constructor(public ormId: number,
        public custType?: string,
        public loadedEmptyIndicator?: string,
        public beginStatus?: string,
        public shipType?: string        
    ) { }

    from(customerType: RtCustomerTypeDefault): RtCustomerTypeDefault {
        Object.keys(customerType).forEach((k) => (this[k] = customerType[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtCustomerTypeDefault>('ormId'), server: 'ormId' },
    { client: nameof<RtCustomerTypeDefault>('custType'), server: 'custType' },
    { client: nameof<RtCustomerTypeDefault>('loadedEmptyIndicator'), server: 'loadedEmptyIndicator' },
    { client: nameof<RtCustomerTypeDefault>('beginStatus'), server: 'beginStatus' },
    { client: nameof<RtCustomerTypeDefault>('shipType'), server: 'shipType' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCustomerTypeDefaultAdapter extends TwoWayAdapter<RtCustomerTypeDefault> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

@Injectable({
    providedIn: 'root',
})
export class RtCustomerTypeDefaultMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() { }
    
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        const columns = [
            {
                dataField: nameof<RtCustomerTypeDefault>('ormId'),
                dataType: 'number',
                caption: 'ormId',
                visible: false,
            } as IColumnDescription,
            {
                dataField: nameof<RtCustomerTypeDefault>('custType'),
                dataType: 'string',
                caption: 'custType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCustomerTypeDefault>('loadedEmptyIndicator'),
                dataType: 'string',
                caption: 'loadedEmptyIndicator',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCustomerTypeDefault>('beginStatus'),
                dataType: 'string',
                caption: 'beginStatus',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCustomerTypeDefault>('shipType'),
                dataType: 'string',
                caption: 'shipType',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtCustomerTypeDefault>('custType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerTypeDefault>('loadedEmptyIndicator'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerTypeDefault>('beginStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerTypeDefault>('shipType'), new UntypedFormControl(''));
        
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
