import { Component, Input, OnInit } from '@angular/core';
import { RtvTripDiversion } from '@bds/railtrac-models';

@Component({
    selector: 'rt-diversion-list-item',
    templateUrl: './rt-diversion-list-item.component.html',
    styleUrls: ['./rt-diversion-list-item.component.scss'],
})
export class RtDiversionListItemComponent {
    @Input() diversion: RtvTripDiversion;
    @Input() dateTimeFormat: string;

    constructor() {}

    convertToKVPString(key: string, value: string): string {
        if (key && value) {
            return `${key} - ${value}`;
        }

        return '';
    }

    convertToLocationString(splc: string, city: string, state: string): string {
        if (splc && city && state) {
            return `${splc} - ${city}, ${state}`;
        } else if (city && state) {
            return `${city}, ${state}`;
        } else if (splc) {
            return `${splc}`;
        }

        return '';
    }
}
