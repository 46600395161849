import { Component, OnInit } from '@angular/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
  selector: 'lib-rt-outbound-by-customer-product-volume-report',
  templateUrl: './rt-outbound-by-customer-product-volume-report.component.html',
  styleUrls: ['./rt-outbound-by-customer-product-volume-report.component.css']
})
export class RtOutboundByCustomerProductVolumeReportComponent implements OnInit {

    public gridId = 121;
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Outbound At Customer By Customer, Product & Volume Report';

  constructor() { }

  ngOnInit(): void {
  }

}
