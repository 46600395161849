import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RtArrAtOrig } from '@bds/railtrac-models';
import { BdsOriginCriteriaGridDialogModel } from '../models/bds-origin-criteria-grid-dialog.model';
import { BdsOriginCriteriaService } from '../bds-origin-criteria.service';

@Component({
    selector: 'bds-origin-criteria-grid-dialog',
    templateUrl: './bds-origin-criteria-grid-dialog.component.html',
    styleUrls: ['./bds-origin-criteria-grid-dialog.component.scss'],
})
export class BdsOriginCriteriaGridDialogComponent {
    customerSource: DataSource;
    errorMessage: string = '';
    initialOrigCriteria: RtArrAtOrig;
    isSaving: boolean = false;
    isValid: boolean = false;
    originCriteria: RtArrAtOrig;
    successfulSave: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<BdsOriginCriteriaGridDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BdsOriginCriteriaGridDialogModel,
        private originService: BdsOriginCriteriaService,
        private _snackbar: MatSnackBar,
    ) {
        // TODO: Cannot use spread operator with current setup of adapter pattern, so this seems to work temporarily
        this.initialOrigCriteria = { ...data.originCriteria } as RtArrAtOrig;
    }

    onAdd(createNewAfterSave: boolean = false) {
        this.originService
            .create(this.originCriteria)
            .pipe(take(1))
            .subscribe(
                (dest) => {
                    this.afterSaveComplete(dest, 'Origin Criteria Added', createNewAfterSave);
                },
                (err) => this.afterError(err),
            );
    }

    onCancelClick() {
        this.dialogRef.close(this.successfulSave);
    }

    onOriginCriteriaChanged(event: { value: RtArrAtOrig; isValid: boolean }) {
        this.originCriteria = event.value;
        this.isValid = event.isValid;
    }

    onSaveClick(createNewAfterSave: boolean = false) {
        this.isSaving = true;
        this.errorMessage = '';

        if (this.data.isNew || this.data.isClone) {
            this.onAdd(createNewAfterSave);
        } else {
            this.onUpdate();
        }
    }

    onUpdate() {
        this.originService
            .update(this.originCriteria.ormId, this.originCriteria)
            .pipe(take(1))
            .subscribe(
                (dest) => {
                    this.afterSaveComplete(dest);
                },
                (err) => this.afterError(err),
            );
    }

    private afterError(error) {
        const customerErrors = [];

        if (typeof error === 'string') {
            this.errorMessage = error;
            customerErrors.push(error);
        } else if (Array.isArray(error) && error[0].validationMessage) {
            this.errorMessage = `Origin Criteria is invalid - ${error[0].validationMessage}`;
            error.forEach((e) => customerErrors.push(e));
        } else {
            this.errorMessage = 'An unknown error occurred';
        }

        this.isSaving = false;
        this._snackbar.open(this.errorMessage, 'Error', { duration: 3000 });
    }

    private afterSaveComplete(origCriteria: RtArrAtOrig, message?: string, keepOpen?: boolean) {
        if (!message) {
            message = 'Changes saved!';
        }

        this.successfulSave = true;
        this.isSaving = false;
        this._snackbar.open(message, 'Ok', { duration: 3000 });

        if (keepOpen) {
            // TODO: Cannot use spread operator with current setup of adapter pattern, so this seems to work temporarily
            this.initialOrigCriteria = { ...this.data.originCriteria } as RtArrAtOrig;
        } else {
            this.dialogRef.close(this.successfulSave);
        }
    }
}
