import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { BdsDxDataGridModule, FormlyFieldTemplatesModule } from '@bds/components';
import { BourqueCoreModule } from '@bds/core';
import { BdsFastracModule } from '@bds/fastrac';
import { VendorsModule } from '../../vendors/vendors.module';
import { HistoricDatesDialogModule } from '../../internal-dialogs/historic-dates-dialog/historic-dates-dialog.module';
import { EquipmentComponentCommonComponent } from './equipment-component-common/equipment-component-common.component';
import { EquipmentComponentDatesComponent } from './equipment-component-dates/equipment-component-dates.component';
import { EquipmentComponentFieldsComponent } from './equipment-component-fields/equipment-component-fields.component';
import { EquipmentComponentListComponent } from './equipment-component-list/equipment-component-list.component';
import { EquipmentComponentMenuComponent } from './equipment-component-menu/equipment-component-menu.component';

@NgModule({
    declarations: [
        EquipmentComponentCommonComponent,
        EquipmentComponentDatesComponent,
        EquipmentComponentFieldsComponent,
        EquipmentComponentListComponent,
        EquipmentComponentMenuComponent,
    ],
    imports: [
        BdsDxDataGridModule,
        BdsFastracModule,
        BourqueCoreModule,
        CommonModule,
        FormlyFieldTemplatesModule,
        FormlyModule,
        HistoricDatesDialogModule,
        VendorsModule,
    ],
    exports: [
        EquipmentComponentCommonComponent,
        EquipmentComponentDatesComponent,
        EquipmentComponentFieldsComponent,
        EquipmentComponentListComponent,
        EquipmentComponentMenuComponent,
    ],
})
export class EquipmentComponentComponentsModule {}
