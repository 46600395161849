import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, shareReplay } from 'rxjs/operators';

export enum BDSFormlyForms {
    BdsRouteDetailsAdd = 'bds-route-details',
    BdsPrefCustomerRoute = 'bds-pref-route',
}

@Injectable()
export abstract class FormlyServiceApiServiceOptions {
    apiUrl = `api/formlyforms/`;
    odataUrl = `odata/formlyforms`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsFormlyService {
    private cache$: { [key: string]: Observable<FormlyFieldConfig[]> } = {};

    constructor(private options: FormlyServiceApiServiceOptions, private http: HttpClient) {}

    getForm(name: BDSFormlyForms): Observable<FormlyFieldConfig[]> {
        if (!this.cache$[name]) {
            this.cache$[name] = this.requestForm(name).pipe(shareReplay(CACHE_SIZE));
        }
        return this.cache$[name];
    }

    private requestForm(name: BDSFormlyForms): Observable<FormlyFieldConfig[]> {
        return this.http
            .get(`${this.options.odataUrl}('${name}')`)
            .pipe(
                map(
                    (x: {
                        '@odata.context': string;
                        formName: string;
                        ormId: number;
                        form: string;
                    }) => <FormlyFieldConfig[]>JSON.parse(x.form),
                ),
            );
    }
}

export const CACHE_SIZE = 1;
