import { Inject, Injectable } from '@angular/core';
import ODataStore from 'devextreme/data/odata/store';
import { RtSplcErpc, RtSplcErpcAdapter } from '@bds/railtrac-models';
import { nameof } from '@bds/core';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiErrorHandlerService } from '@bds/helpers';

@Injectable({
    providedIn: 'root',
})
@Injectable({
    providedIn: 'root',
})
export class BdsSplcErpcService {
    controllerName = 'RtSplcErpc';

    constructor(
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        public adapter: RtSplcErpcAdapter,
        private http: HttpClient,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtSplcErpc>('splc'),
        ).server;

        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: keyField,
        });
    }

    getRtSplcErpcODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtSplcErpc>('splc'),
        ).server;

        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}/GetAll`,
            key: keyField,
        });
    }

    getBySplc(splc: string): Observable<RtSplcErpc> {
        return this.http
            .get<RtSplcErpc>(
                `${this.odatabaseUrl}${this.controllerName}('${splc}')?$select=erpcCity,erpcState,splc,rule260,city,state`,
            )
            .pipe(
                map((data: any) => this.adapter.adapt(data)),
                // retry(3),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}
