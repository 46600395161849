import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FleetFileService {
    controllerName = 'fleeFile';
    constructor(@Inject('BASE_API_URL') private apibaseUrl: string, private http: HttpClient) {}

    add(model) {
        return this.http.post(`${this.apibaseUrl}${this.controllerName}`, model);
    }
}

