import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BdsHelpersModule } from '@bds/helpers';

import { VendorsModule } from '../../vendors/vendors.module';
import { HistoricDatesComponentsModule } from '../../components/historic-dates/historic-dates-components.module';
import { HistoricDatesDialogComponent } from './historic-dates-dialog.component';

@NgModule({
    declarations: [HistoricDatesDialogComponent],
    imports: [
        BdsHelpersModule,
        CommonModule,
        FontAwesomeModule,
        HistoricDatesComponentsModule,
        VendorsModule,
    ],
    exports: [HistoricDatesDialogComponent]
})
export class HistoricDatesDialogModule {}
