import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { RtCommentType, RtMiscComment, RtTripComment } from '@bds/railtrac-models';
import { Subject } from 'rxjs/internal/Subject';
import { RtMultiCommentFormService } from '../rt-multi-comment-dialog/rt-multi-comment-form.service';
import { nameof } from '@bds/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { RtTripCommentService } from '@bds/data-access';
import { Observable, of } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'rt-multi-comment-form-item',
    templateUrl: './rt-multi-comment-form-item.component.html',
    styleUrls: ['./rt-multi-comment-form-item.component.css'],
})
export class RtMultiCommentFormItemComponent implements OnInit {
    commentTypes: Observable<RtCommentType[]>;
    defaultComments: Observable<RtMiscComment[]>;

    @Input() formGroup: UntypedFormGroup;
    @Input() index: number;
    @Input() isBulkForm = false;

    private unsub$ = new Subject<void>();

    constructor(
        public commentFormService: RtMultiCommentFormService,
        private tripCommentService: RtTripCommentService,
    ) {}

    ngOnInit(): void {
        this.commentTypes = this.tripCommentService.getCommentTypes();
        const commentTypeControl = this.formGroup.get(nameof<RtTripComment>('commentType'));
        commentTypeControl.reset();
        this.defaultComments = this.tripCommentService.getDefaultCommentsForType(
            commentTypeControl.value,
        );
        const commentCodeControl = this.formGroup.get(nameof<RtTripComment>('commentCode'));
        const commentsControl = this.formGroup.get(nameof<RtTripComment>('comments'));
        commentTypeControl.valueChanges.subscribe((s: string) => {
            this.defaultComments = this.tripCommentService.getDefaultCommentsForType(s);
            // .subscribe((response) => {
            //     this.defaultComments = response;
            // });
        });

        commentCodeControl.valueChanges.subscribe((s: string) => {
            //commentsControl.setValue('');
            if (this.defaultComments) {
                this.defaultComments.subscribe((response) => {
                    var commentCodes = response;
                    const comment = commentCodes.find((x) => x.code === s);
                    if (comment) {
                        commentsControl.setValue(comment.comment);
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.complete();
    }

    @Input() set comment(value: RtTripComment) {
        this.formGroup = this.commentFormService.addCommentForm(value, this.unsub$);
        this.watchCommentType();
        this.formGroup.patchValue(value);
    }

    private watchCommentType() {
        const commentTypeControl = this.formGroup.get(nameof<RtTripComment>('commentType'));
        const commentCodeControl = this.formGroup.get(nameof<RtTripComment>('commentCode'));
        const commentsControl = this.formGroup.get(nameof<RtTripComment>('comments'));

        commentTypeControl.valueChanges.pipe(
            takeUntil(this.unsub$),
            tap(() => {
                commentCodeControl.setValue('');
            }),
            switchMap(
                (response) =>
                    (this.defaultComments =
                        this.tripCommentService.getDefaultCommentsForType(response)),
            ),
        );
        // .subscribe((response) => {
        //     this.defaultComments = response;
        // });

        commentCodeControl.valueChanges
            .pipe(
                takeUntil(this.unsub$),
                tap((selectedCommentCode) => {
                    //commentsControl.setValue('');
                    if (this.defaultComments) {
                        this.defaultComments.subscribe((commentCodeResult) => {
                            const comment = commentCodeResult.find(
                                (x) => x.code === selectedCommentCode,
                            );
                            //const comment = this.defaultComments.find((x) => x.code === selectedCommentCode);
                            if (comment) {
                                commentsControl.setValue(comment.comment);
                            }
                        });
                    }
                }),
            )
            .subscribe();
    }

    getDefaultComments(key: string) {
        if (!!key) {
            this.defaultComments = this.tripCommentService.getDefaultCommentsForType(key);
            // .subscribe((response) => {
            //     this.defaultComments = response;
            // });
        } else {
            this.defaultComments = of([]);
        }
    }

    setDefaultComment(comment: string) {
        this.formGroup.controls[nameof<RtTripComment>('comments')].setValue(comment);
    }

    setFlag($event: MatCheckboxChange, toggleControl: AbstractControl): void {
        toggleControl.setValue($event ? 'Y' : 'N');
    }
}
