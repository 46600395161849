import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { nameof } from '@bds/core';
import { RtTripCommentService } from '@bds/data-access';
import { RtCarHotListMetadata, RtCommentType, RtMiscComment } from '@bds/railtrac-models';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TripCarHotListDto } from '@bds/railtrac-models';
import { HotListFormService } from '../bds-hot-list-details-dialog/hot-list-form.service';

@Component({
    selector: 'bds-hotlist-form-item',
    templateUrl: './bds-hotlist-form-item.component.html',
    styleUrls: ['./bds-hotlist-form-item.component.css'],
})
export class BdsHotlistFormItemComponent implements OnInit, OnDestroy, OnChanges {
    commentTypes: Observable<RtCommentType[]>;
    defaultComments: RtMiscComment[];
    @Input() formGroup: UntypedFormGroup;
    @Input() index: number;
    @Input() isBulkForm = false;

    private unsub$ = new Subject<void>();

    constructor(
        private metadata: RtCarHotListMetadata,
        private hotListFormService: HotListFormService,
        private tripCommentService: RtTripCommentService,
    ) {}

    @Input() set hotList(value: TripCarHotListDto) {
        this.formGroup = this.hotListFormService.addHotListForm(value, this.unsub$);
        this.watchCommentType();
        if (!value.carInit && !value.carNo) {
            this.watchForClosedShipment();
        }
        this.formGroup.patchValue(value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formGroup) {
            this.watchCommentType();
        }
    }

    ngOnDestroy(): void {
        if (this.index) {
            this.hotListFormService.hotListItemForms.removeAt(this.index);
        }
        this.unsub$.next();
        this.unsub$.complete();
    }

    ngOnInit() {
        this.commentTypes = this.tripCommentService.getCommentTypes();
    }

    private setWarning() {
        // TODO do I throw validation message here?
        console.log('warning thrown');
    }

    private watchCommentType() {
        const commentTypeControl = this.formGroup.get(nameof<TripCarHotListDto>('commentType'));
        const commentCodeControl = this.formGroup.get(nameof<TripCarHotListDto>('commentCode'));
        const commentsControl = this.formGroup.get(nameof<TripCarHotListDto>('comments'));

        commentTypeControl.valueChanges
            .pipe(
                takeUntil(this.unsub$),
                tap(() => {
                    commentCodeControl.setValue('');
                }),
                switchMap((response) =>
                    this.tripCommentService.getDefaultCommentsForType(response),
                ),
            )
            .subscribe((response) => {
                this.defaultComments = response;
            });

        commentCodeControl.valueChanges
            .pipe(
                takeUntil(this.unsub$),
                tap((response) => {
                    commentsControl.setValue('');
                    if (this.defaultComments) {
                        const comment = this.defaultComments.find((x) => x.code === response);
                        if (comment) {
                            commentsControl.setValue(comment.comment);
                        }
                    }
                }),
            )
            .subscribe();
    }

    private watchForClosedShipment() {
        const carInit = this.formGroup.get(nameof<TripCarHotListDto>('carInit'));
        const carNo = this.formGroup.get(nameof<TripCarHotListDto>('carNo'));

        carInit.valueChanges
            .pipe(
                takeUntil(this.unsub$),
                debounceTime(500),
                tap(() => {
                    if (carNo.value) {
                        this.setWarning();
                    }
                }),
            )
            .subscribe();

        carNo.valueChanges
            .pipe(
                takeUntil(this.unsub$),
                debounceTime(500),
                tap(() => {
                    if (carInit.value) {
                        this.setWarning();
                    }
                }),
            )
            .subscribe();
    }
}
