import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'bds-preferred',
    template: `<bds-preferred-routes-grid></bds-preferred-routes-grid>`,
})
export class PreferredRoutesComponent {
    constructor() {}
}

