<h4 *ngIf="!!data.title" mat-dialog-title>{{ data.title }}</h4>

<div mat-dialog-content>{{ data.content }}</div>

<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" [mat-dialog-close]="data.actionText">
        {{ data.actionText }}
    </button>
    <span *ngIf="data.showActionTwo">
        <button
            mat-button
            class="text-uppercase"
            color="accent"
            [mat-dialog-close]="data.actionTwoText"
        >
            {{ data.actionTwoText }}
        </button>
    </span>
    <button mat-button class="text-uppercase" color="accent" cdkFocusInitial mat-dialog-close>
        {{ data.dismissiveText }}
    </button>
</div>

