import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BdsDxDataGridDirective, bdsGridModeType } from '@bds/components';
import { FastracColumn, FastracDxGridModel, FastracGrid, FastracLite } from '@bds/models';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'rt-diverted-trip-list',
    templateUrl: './rt-diverted-trip-list.component.html',
    styleUrls: ['./rt-diverted-trip-list.component.scss'],
})
export class RtDivertedTripListComponent implements OnInit {
    public customizeColumns: (columns: any[]) => void;
    public selectedRowKeys: number[] = [];
    public selectedRowData: Array<any> = [];

    // Fastrac properties
    public currentFastrac: FastracDxGridModel;
    public emptyFastrac: FastracDxGridModel = new FastracDxGridModel(0, [], [], []);
    public fastracId: number = 0;
    public fastracList: FastracLite[] = [];
    public showFastrac = false;
    public gridMode: bdsGridModeType = 'multiple';

    @Input() gridId: number = FastracGrid.diversions;
    @Input() needsRefresh: boolean;
    @Input() divertedTripColumns: FastracColumn[];
    @Input() gridSource: DataSource;
    @Input() selectedFastrac: number;
    @Input() selectedItem: number;
    @Output() selectedFastracChange: EventEmitter<number> = new EventEmitter();
    @Output() selectedItemChange: EventEmitter<any> = new EventEmitter();

    @ViewChild('rtDivertedTripGrid') dataGrid: DxDataGridComponent;
    @ViewChild('targetDirective') targetDirective: BdsDxDataGridDirective;

    constructor(private clipboard: Clipboard) {
        this.currentFastrac = { ...this.emptyFastrac };
        this.fastracId = 0;

        this.customizeColumns = (columns: any[]) => {
            this.customizeColumnsInternal(columns);
        };
    }

    ngOnInit() {
        if (this.selectedFastrac) {
            this.fastracId = this.selectedFastrac;
        }

        if (this.selectedItem) {
            this.selectedRowKeys = [this.selectedItem];
        }
    }

    onApplyFastrac(event: FastracDxGridModel) {
        if (event && event.id) {
            this.fastracId = event.id;
            this.selectedFastracChange.emit(event.id);
        }

        this.currentFastrac = { ...event };
    }

    onFastracDesignsChanged(event: FastracLite[]) {
        this.fastracList = event;
    }

    onFastracIdChanged(event: number) {
        this.fastracId = event;
        this.selectedFastracChange.emit(event);
    }

    onFastracVisibleChanged(event: boolean) {
        this.showFastrac = event;
    }

    onGridFastracIdChanged(event: number) {
        if (this.fastracId !== event) {
            this.fastracId = event;
            this.selectedFastracChange.emit(event);
        }
    }

    onSelectedRowKeysChanged(event) {
        // If we eventually add multiple selections, just pass the
        // value out in the emitter
        this.selectedRowKeys = event;
        let selectedRowsData = [];

        if (event.length > 0) {
            selectedRowsData = this.dataGrid.instance.getSelectedRowsData();
            this.selectedItemChange.emit(this.selectedRowKeys);
        } else {
            this.selectedItemChange.emit(null);
        }
    }

    onShowFastrac() {
        this.showFastrac = true;
    }

    setUpSelectedKeys() {
        // If we eventually add multiple selections, replace with
        // this.selectedRowKeys = [...this.selectedItem];
        if (this.selectedItem) {
            // TODO: Figure out if you can set this
            // this.selectedRowKeys = [this.selectedItem];
            this.selectedRowKeys = [this.selectedItem];
        } else {
            this.selectedRowKeys = [];
        }
    }

    private customizeColumnsInternal(columns: any[]) {
        columns.forEach((col) => {
            const fCol = this.divertedTripColumns.find((f) => f.mappedField === col.dataField);
            if (!!fCol) {
                col.caption = fCol.displayField || col.caption;
                col.dataType = fCol.datatype || col.dataType;
            }
        });
    }

    copyCarsToClipboard(): void {
        this.selectedRowData = this.dataGrid.instance.getSelectedRowsData();
        if (this.selectedRowData != null && this.selectedRowData.length > 0) {
            const carIds = this.selectedRowData.map((eachRow) => {
                return eachRow.carInit + ' ' + eachRow.carNo;
            });
            this.clipboard.copy(carIds.join('\n'));
        }
    }
}
