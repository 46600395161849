import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BdsDxDataGridModule } from '@bds/components';
import { BdsFastracModule } from '@bds/fastrac';
import { VendorsModule } from '../../vendors';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';

@NgModule({
    declarations: [EquipmentListComponent],
    imports: [BdsDxDataGridModule, BdsFastracModule, CommonModule, VendorsModule],
    exports: [EquipmentListComponent],
})
export class EquipmentListComponentsModule {}
