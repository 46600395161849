<div class="sticky-top mat-elevation-z6">
    <mat-toolbar class="trip-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <span>
                <!-- <fa-icon [icon]="iconFlag" [fixedWidth]="true" size="lg"></fa-icon> -->
                No Bill
            </span>
            <span class="fill-remaining-space"></span>
            <span>
                <rt-jeopardized-shipment-reason-options
                    (resolve)="onResolve($event)"
                ></rt-jeopardized-shipment-reason-options>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<h3 class="mini-header">Previous Trip Details</h3>
<rt-jeopardized-shipment-reason-common
    [trip]="trip"
    [useExpansionPanel]="false"
    [showInterfaceValidations]="true"
    [showOriginCriteria]="true"
    [showDestinationCriteria]="true"
    (saveClick)="onTripSaveClicked($event)"
    (popoutTrip)="onPopoutTripclicked($event)"
></rt-jeopardized-shipment-reason-common>

<h3 class="mini-header">Jeopardized Shipment Details</h3>
<rt-jeopardized-shipment-details [shipment]="shipment"></rt-jeopardized-shipment-details>
