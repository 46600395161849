import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { BdsDxDataGridModule, BdsDialogConfirmModule } from '@bds/components';
import { BourqueCoreModule } from '@bds/core';
import { BdsDataAccessModule } from '@bds/data-access';
import { BdsSmartComponentsModule } from '@bds/smart-components';
import { BdsOriginCriteriaComponent } from './bds-origin-criteria.component';
import { BdsOriginCriteriaDetailsComponent } from './bds-origin-criteria-details/bds-origin-criteria-details.component';
import { BdsOriginCriteriaDialogComponent } from './bds-origin-criteria-dialog/bds-origin-criteria-dialog.component';
import { BdsOriginCriteriaGridDialogComponent } from './bds-origin-criteria-grid-dialog/bds-origin-criteria-grid-dialog.component';
import { BdsOriginCriteriaListComponent } from './bds-origin-criteria-list/bds-origin-criteria-list.component';

@NgModule({
    declarations: [
        BdsOriginCriteriaComponent,
        BdsOriginCriteriaDetailsComponent,
        BdsOriginCriteriaDialogComponent,
        BdsOriginCriteriaGridDialogComponent,
        BdsOriginCriteriaListComponent,
    ],
    imports: [
        BdsDataAccessModule,
        BdsDialogConfirmModule,
        BdsDxDataGridModule,
        BourqueCoreModule,
        CommonModule,
        DxDataGridModule,
        DxLookupModule,
        FontAwesomeModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSnackBarModule,
        MatToolbarModule,
        ReactiveFormsModule,
        BdsSmartComponentsModule,
    ],
    exports: [
        BdsOriginCriteriaComponent,
        BdsOriginCriteriaDialogComponent,
        BdsOriginCriteriaGridDialogComponent,
    ],
})
export class BdsOriginCriteriaModule {}
