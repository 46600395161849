import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RtCityAliasAdapter, RtCityAlias } from '@bds/railtrac-models';
import { throwError } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { map, retry, catchError } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';

export abstract class RtCityAliasServiceOptions {
    apiUrl = 'api/cityalias';
    odataUrl = `odata/cityalias`;
}

@Injectable({
    providedIn: 'root',
})
export class RtCityAliasService {
    constructor(
        public httpClient: HttpClient,
        public options: RtCityAliasServiceOptions,
        public adapter: RtCityAliasAdapter,
        public errorService: ApiErrorHandlerService,
    ) {}

    getODataStore(): DataSource {
        return new DataSource({
            store: new ODataStore({
                version: 4,
                url: this.options.odataUrl,
                key: 'ormId',
            }),
            paginate: true,
            pageSize: 20,
        });
    }

    get() {
        return this.httpClient.get<RtCityAlias[]>(this.options.apiUrl).pipe(
            map((m) => [...m.map((f) => this.adapter.adapt(f))]),
            retry(1),
            catchError(this.errorService.handleError),
        );
    }

    getById(id: number) {
        return this.httpClient.get<RtCityAlias>(`${this.options.apiUrl}/${id}`).pipe(
            map((m) => this.adapter.adapt(m)),
            retry(1),
            catchError(this.errorService.handleError),
        );
    }

    add(cityAlias: RtCityAlias) {
        return this.httpClient.post<RtCityAlias>(this.options.apiUrl, cityAlias).pipe(
            map((m) => this.adapter.adapt(m)),
            retry(1),
            catchError(this.errorService.handleError),
        );
    }

    update(cityAlias: RtCityAlias) {
        return this.httpClient
            .put<RtCityAlias>(`${this.options.apiUrl}/${cityAlias.ormId}`, cityAlias)
            .pipe(retry(1), catchError(this.errorService.handleError));
    }

    delete(id: number) {
        return this.httpClient.delete<RtCityAlias>(`${this.options.apiUrl}/${id}`).pipe(
            map((m) => this.adapter.adapt(m)),
            retry(1),
            catchError(this.errorService.handleError),
        );
    }
}
