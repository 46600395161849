<mat-toolbar class="sticky-top mat-elevation-z6 bds-toolbar-blue">
    <mat-toolbar-row class="row">
        <div class="col-6">
            <fa-icon [icon]="duoIconRoute" [fixedWidth]="true" size="lg"></fa-icon>
            {{ route?.routeCode }}
        </div>
        <div class="col-6 text-right">
            <ng-content></ng-content>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar class="mat-elevation-z3 bds-toolbar-buttons">
    <button mat-button>
        <fa-icon [icon]="iconRouteSegments" class="bds-toolbar-button-icon"></fa-icon>
        Route Segments
    </button>
    <button mat-button>
        <fa-icon [icon]="iconRouteDetail" class="bds-toolbar-button-icon"></fa-icon>
        Route Detail
    </button>
    <button mat-button>Preferred Route</button>
    <button mat-button>Participating Railroad</button>
    <span>
        <!-- Route  with icons will go here -->
    </span>
</mat-toolbar>
