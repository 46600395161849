import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RtUnitMeasure } from '@bds/models';
import { RtCommodityShipped } from '@bds/railtrac-models';
import { RtUnitMeasureService } from '@bds/services';
import { Observable, firstValueFrom } from 'rxjs';
import { TripCommodityShippedFormService } from './trip-commodity-shipped-form.service';

@Component({
    selector: 'trip-commodity-shipped',
    templateUrl: './trip-commodity-shipped.component.html',
    styleUrls: ['./trip-commodity-shipped.component.css'],
    providers: [TripCommodityShippedFormService],
})
export class TripCommodityShippedComponent implements OnInit {
    matFloatLabel = 'always';
    matStyle = 'fill';
    dense = true;
    commodityShippedForm: UntypedFormGroup;
    @Input() productOp: 'add' | 'edit' | 'display' = 'display';
    @Output() commodityShippedChange = new EventEmitter<RtCommodityShipped>();

    private _commodityShipped: RtCommodityShipped;
    showMoreButton = false;
    showCommodityFact = false;
    moreLessLabel = 'More';
    readonly = false;

    unitMeasureList: RtUnitMeasure[] = [];

    constructor(
        private formService: TripCommodityShippedFormService,
        public unitMeasureService: RtUnitMeasureService,
    ) {
        this.commodityShippedForm = formService.form;
    }

    @Input() set commodityShipped(value: RtCommodityShipped) {
        if (value) {
            this.commodityShippedForm.patchValue(value);
            this._commodityShipped = value;
        }
    }

    get commodityShipped(): RtCommodityShipped {
        return this._commodityShipped;
    }

    ngOnInit(): void {
        if (this.productOp == 'display') {
            this.formService.initReadOnlyForm();
            this.showMoreButton = true;
            this.readonly = true;
        } else {
            this.commodityShippedForm.statusChanges.subscribe((res) => {
                if (res === 'VALID') {
                    this.commodityShippedChange.emit(this.commodityShippedForm.getRawValue());
                }
            });
        }

        void this.getAllUnits();
    }

    async getAllUnits(): Promise<void> {
        const results = await firstValueFrom(this.unitMeasureService.getAll());
        this.unitMeasureList =
            results.filter((unit) => unit?.unitType === 'W' || unit?.unitType === 'V') ?? [];
    }

    toggleCommodityFact(): void {
        this.showCommodityFact = !this.showCommodityFact;
        this.moreLessLabel = this.showCommodityFact ? 'Less' : 'More';
    }
}
