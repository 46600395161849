import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { BdsCustomerModule } from '@bds/customer';
import { BdsCustomerAdvancedSelectComponent } from './components/bds-customer-advanced-select/bds-customer-advanced-select.component';

@NgModule({
    declarations: [BdsCustomerAdvancedSelectComponent],
    imports: [
        BdsCustomerModule,
        CommonModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxValidatorModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSnackBarModule,
    ],
    exports: [BdsCustomerAdvancedSelectComponent],
})
export class BdsCustomerSmartComponentsModule {}
