import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { nameof } from '@bds/core';
import { RtRailroadFact, RtSplcErpc } from '@bds/railtrac-models';
import { faStopCircle } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BdsSegmentMilesService } from '../../../bds-segment-miles/bds-segment-miles.service';
import { RouteCodeFormService } from '../bds-route-code/bds-route-code-form.service';
import { BdsSplcErpcService } from '../../../../../../data-access/src/public-api';

@Component({
    selector: 'bds-rt-route-segment-builder',
    templateUrl: './bds-route-segment-builder.component.html',
    styleUrls: ['./bds-route-segment-builder.component.scss'],
})
export class BdsRouteSegmentBuilderComponent implements OnInit, OnDestroy {
    beginSplc = this.routeCodeFormService.originSplc;
    @Input() formArray = new UntypedFormArray([]);
    iconExpire = faStopCircle;
    iconPlus = faPlus;
    private unsub$ = new Subject<void>();

    constructor(
        public routeCodeFormService: RouteCodeFormService,
        private routeSegmentsService: BdsSegmentMilesService,
        private bdsSplcErpcService: BdsSplcErpcService,
    ) {}

    addForm(): void {
        const newForm = this.getForm();
        this.formArray.push(newForm);
    }

    displayValue = (data: RtSplcErpc): string => (data ? `${data.rule260}` : '');

    ngOnDestroy(): void {
        this.routeCodeFormService.initFreeform();
        this.unsub$.next();
        this.unsub$.complete();
    }

    ngOnInit(): void {
        this.formArray = this.routeCodeFormService.initBuildBySegmentForm();
        this.addForm();

        this.routeCodeFormService.originGeography$.pipe(
            takeUntil(this.unsub$),
            tap(() => this.setDefaultSegmentMiles(0, this.formArray[0])),
        );
    }

    removeForm(i: number): void {
        this.routeCodeFormService.setDescription();
        this.formArray.removeAt(i);
        this.formArray.controls.forEach((form: UntypedFormGroup, index) => {
            this.setDefaultSegmentMiles(index, form);
        });
    }

    searchValue = (data: RtSplcErpc): string => (data ? `${data.rule260}` : '');

    setDefaultSegmentMiles(index: number, group: UntypedFormGroup): void {
        let beginSplc: string;
        if (index === 0) {
            beginSplc = <string>this.routeCodeFormService.originSplc.value;
        } else {
            beginSplc = <string>(
                this.formArray.controls[index - 1].get(nameof<RouteSegment>('endSplc')).value
            );
        }
        const endSplc = <string>group.get(nameof<RouteSegment>('endSplc')).value;
        const road = <string>group.get(nameof<RouteSegment>('railroad')).value;
        if (endSplc && beginSplc && road) {
            this.routeSegmentsService
                .getDefaultSegmentData(beginSplc, endSplc, road)
                .pipe(
                    tap((response) => {
                        if (response) {
                            group
                                .get(nameof<RouteSegmentBuilder>('benchmarkHours'))
                                .setValue(response.benchmarkHours);
                            group
                                .get(nameof<RouteSegmentBuilder>('segmentMiles'))
                                .setValue(response.miles);
                            group
                                .get(nameof<RouteSegmentBuilder>('weightLimit'))
                                .setValue(response.weightLimit);
                        }
                    }),
                )
                .subscribe();
        }
    }

    async setERPCValues(
        splc: RtSplcErpc,
        formGroup: UntypedFormGroup,
        index: number,
    ): Promise<void> {
        if (!splc) {
            splc = await firstValueFrom(
                this.bdsSplcErpcService.getBySplc(
                    formGroup.get(nameof<RouteSegmentBuilder>('endSplc')).value,
                ),
            );
        }
        formGroup.get(nameof<RouteSegmentBuilder>('erpcCity')).setValue(splc.erpcCity);
        formGroup.get(nameof<RouteSegmentBuilder>('erpcState')).setValue(splc.erpcState);
        formGroup.get(nameof<RouteSegmentBuilder>('junction')).setValue(splc.rule260);
        if (splc.rule260 && index === this.formArray.controls.length - 1) {
            this.addForm();
        }
    }

    setRoad($event: RtRailroadFact): void {
        if ($event) {
            if (!this.routeCodeFormService.originSwitch.value) {
                this.routeCodeFormService.departingCarrier.setValue($event.rrScac);
            }
        }
    }

    private getForm() {
        const form = RouteSegmentForm();
        form.addControl(
            nameof<RouteSegmentBuilder>('erpcCity'),
            new UntypedFormControl({ value: null, disabled: true }),
        );
        form.addControl(
            nameof<RouteSegmentBuilder>('erpcState'),
            new UntypedFormControl({ value: null, disabled: true }),
        );
        form.addControl(
            nameof<RouteSegmentBuilder>('segmentMiles'),
            new UntypedFormControl({ value: null, disabled: true }),
        );
        form.addControl(
            nameof<RouteSegmentBuilder>('weightLimit'),
            new UntypedFormControl({ value: null, disabled: true }),
        );
        form.addControl(
            nameof<RouteSegmentBuilder>('activeStatus'),
            new UntypedFormControl({ value: 'Y', disabled: true }),
        );

        [
            nameof<RouteSegmentBuilder>('endSplc'),
            nameof<RouteSegmentBuilder>('railroad'),
            nameof<RouteSegmentBuilder>('junction'),
        ].map((val) => {
            form.get(val)
                .valueChanges.pipe(
                    takeUntil(this.unsub$),
                    tap(() => this.routeCodeFormService.setDescription()),
                )
                .subscribe();
        });

        return form;
    }
}

//#region "SegmentForm"
interface RouteSegmentBuilder extends RouteSegment {
    activeStatus: string;
    erpcCity: string;
    erpcState: string;
    segmentMiles: number;
    weightLimit: number;
}

export interface RouteSegment {
    beginSplc: string;
    benchmarkHours: number;
    contractNo: string;
    endSplc: string;
    junction: string;
    mileageAllowanceIndicator: string;
    mileageAllowanceType: string;
    ormId: number;
    railroad: string;
    routeCode: string;
    segmentNo: number;
}

export function RouteSegmentForm(): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    form.addControl(nameof<RouteSegment>('beginSplc'), new UntypedFormControl(null));
    form.addControl(nameof<RouteSegment>('benchmarkHours'), new UntypedFormControl(null));
    form.addControl(nameof<RouteSegment>('contractNo'), new UntypedFormControl(null));
    form.addControl(nameof<RouteSegment>('endSplc'), new UntypedFormControl(null));
    form.addControl(
        nameof<RouteSegment>('junction'),
        new UntypedFormControl({ value: null, disabled: true }),
    );
    form.addControl(
        nameof<RouteSegment>('mileageAllowanceIndicator'),
        new UntypedFormControl(null),
    );
    form.addControl(nameof<RouteSegment>('mileageAllowanceType'), new UntypedFormControl(null));
    form.addControl(nameof<RouteSegment>('ormId'), new UntypedFormControl(null));
    form.addControl(nameof<RouteSegment>('railroad'), new UntypedFormControl(null));
    form.addControl(nameof<RouteSegment>('routeCode'), new UntypedFormControl(null));
    form.addControl(nameof<RouteSegment>('segmentNo'), new UntypedFormControl(null));
    return form;
}

//#endregion
