<button mat-icon-button [matMenuTriggerFor]="jeopshipreasonoptsmenu">
    <fa-icon [icon]="iconMore"></fa-icon>
</button>
<mat-menu #jeopshipreasonoptsmenu="matMenu">
    <button mat-menu-item (click)="addDestinationCriteria()">
        <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
        <span>Add Destination Criteria</span>
    </button>
    <button mat-menu-item (click)="addOriginCriteria()">
        <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
        <span>Add Origin Criteria</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="createComment()">
        <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
        <span>Add Comment</span>
    </button>
    <button mat-menu-item (click)="addToHotlist()">
        <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
        <span>Hotlist Car</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="addParticipatingRailroad()">
        <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
        <span>Add Participating Railroad</span>
    </button>
    <button mat-menu-item (click)="createDestinationBypass()">
        <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
        <span>Create Destination Alias Bypass</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="newTrip()">
        <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
        <span>Add New Trip</span>
    </button>
    <button mat-menu-item (click)="removeFromFleet()">
        <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
        <span>Remove from Fleet</span><span class="nyi"></span>
    </button>
    <!-- <a mat-menu-item *ngIf="!!trip && !!currentClm" [href]="generateEmailLink(trip, currentClm)">Send Email to {{ currentClm.road }}</a> -->
</mat-menu>
