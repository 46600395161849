<dx-data-grid
    bds-grid
    id="bdsRouteSegmentGrid"
    [dataSource]="dataSource"
    [columns]="displayColumns"
    height="35vh"
    minHeight="200px"
    [(selectedRowKeys)]="selectedRowKeys"
    [bdsAllowAddRecord]="!readOnly"
    [bdsAllowEditRecords]="!readOnly"
    [bdsAllowDeleteRecords]="!readOnly"
    (bdsAddRecordClicked)="add()"
    (bdsEditRecordsClicked)="edit()"
    (bdsDeleteRecordsClicked)="delete()"
>
    <dxi-column dataField="routeCode" caption="Route Code"></dxi-column>
    <dxi-column dataField="routeSeq" caption="Sequence No"></dxi-column>
    <dxi-column dataField="locCityType" caption="Junction Type"></dxi-column>
    <dxi-column dataField="road" caption="Road"></dxi-column>
    <dxi-column dataField="locCity" caption="City"></dxi-column>
    <dxi-column dataField="locState" caption="State"></dxi-column>
    <dxi-column dataField="etaHours" caption="Eta Hours"></dxi-column>
</dx-data-grid>
