import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RtTripCommentService } from '@bds/data-access';
import { RtTrip, RtTripComment } from '@bds/railtrac-models';
import { faCommentAltPlus, faPencilAlt, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import { finalize, tap } from 'rxjs/operators';
import { RtMultiCommentDetailComponent } from './rt-multi-comment-detail/rt-multi-comment-detail.component';
import {
    RtMultiCommentDialogComponent,
    RtMultiCommentDialogData,
} from './rt-multi-comment-dialog/rt-multi-comment-dialog.component';
//import { RtMultiCommentEditFormComponent } from './rt-multi-comment-edit-form/rt-multi-comment-edit-form.component';
import { RtMultiCommentFormService } from './rt-multi-comment-dialog/rt-multi-comment-form.service';

@Component({
    selector: 'rt-multi-comment',
    templateUrl: './rt-multi-comment.component.html',
    styleUrls: ['./rt-multi-comment.component.css'],
})
export class RtMultiCommentComponent {
    isSaving = false;
    showUpdateButton = true;
    form: UntypedFormArray = new UntypedFormArray([]);
    @ViewChildren('commentDetail')
    commentDetailComponents: QueryList<RtMultiCommentDetailComponent>;

    commentEdit = faPencilAlt;
    commentAdd = faCommentAltPlus;
    commentDelete = faTrashAlt;

    private _trips: RtTrip[];

    get trips(): RtTrip[] {
        return this._trips;
    }

    @Input() set trips(value: RtTrip[]) {
        this._trips = value;
    }

    constructor(
        private dialogService: MatDialog,
        public commentService: RtTripCommentService,
        private _snackbar: MatSnackBar,
        public commentFormService: RtMultiCommentFormService,
    ) {}

    onAddComments(): void {
        const model = this.trips.map(
            (trip) =>
                <RtTripComment>{
                    carInit: trip.carInit,
                    carNo: trip.carNo,
                    shipDateTime: trip.shipDatetime,
                    rtTripOrmId: trip.ormId,
                    commentDate: new Date(),
                    commentType: 'GE',
                    commentCode: 'GE',
                },
        );
        const dialogRef = this.dialogService.open(RtMultiCommentDialogComponent, {
            data: <RtMultiCommentDialogData>{
                action: 'add',
                title: 'Add Comments',
                comments: model,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                tap((response) => {
                    if (response) {
                        this.loadComments();
                    }
                }),
            )
            .subscribe();
    }

    OnUpdateComments(): void {
        if (this.commentDetailComponents) {
            this.form = new UntypedFormArray([]);
            this.commentDetailComponents.map((eachComponent) => {
                eachComponent.commentForm.controls.forEach((control) => {
                    this.form.push(control);
                });
            });
            if (this.form.valid) {
                this.isSaving = true;
                this.commentService
                    .updateComments(this.form.getRawValue() as RtTripComment[])
                    .pipe(
                        finalize(() => {
                            this.isSaving = false;
                        }),
                        tap(() =>
                            this._snackbar.open('Saved Changes', 'success', { duration: 3000 }),
                        ),
                    )
                    .subscribe();
            }
        }
    }

    loadComments(): void {
        this.commentFormService.emitRefreshCommentChange(true);
        //     if (this.commentDetailComponents) {
        //         this.commentDetailComponents.map((eachComponent) => {
        //             eachComponent.loadComments();
        //         });
        //     }
    }

    onCommentPresentEvent(e: boolean): void {
        //this.showUpdateButton = e;
    }
}
