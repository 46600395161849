import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { ApiErrorHandlerService } from '@bds/helpers';
import { EquipReportCategory } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class EquipReportCategoryService {
    controllerName = 'EquipReportCategory';

    constructor(
        private http: HttpClient,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    create(equipCategory: EquipReportCategory): Observable<EquipReportCategory> {
        return this.http
            .post<EquipReportCategory>(`${this.apibaseUrl}${this.controllerName}`, equipCategory)
            .pipe(
                map((data: EquipReportCategory) => {
                    return data ? data : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getAll(expand?: string[]): Observable<EquipReportCategory[]> {
        let expandUrl = '';
        if (expand && expand.length > 0) {
            expandUrl = '?$expand=';
            expand.forEach((child) => (expandUrl = expandUrl + child + ','));
            expandUrl = expandUrl.slice(0, expandUrl.length - 1);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
        return this.http.get<any>(`${this.odatabaseUrl}${this.controllerName}` + expandUrl).pipe(
            map((data) =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
                data.value.map((i) => {
                    const adapt = i as EquipReportCategory;
                    adapt.equipReportCategory = (adapt.equipReportCategory || '').toUpperCase();
                    return adapt;
                }),
            ),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    getODataStore(): ODataStore {
        const keyField = 'ormId';

        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: keyField,
        });
    }

    read(key: number, expand: string[]): Observable<EquipReportCategory> {
        let expandUrl = '';
        if (expand.length > 0) {
            expandUrl = '?$expand=';
            expand.forEach((child) => (expandUrl = expandUrl + child + ','));
            expandUrl = expandUrl.slice(0, expandUrl.length - 1);
        }
        return this.http
            .get<EquipReportCategory>(
                `${this.odatabaseUrl}${this.controllerName}(${key})` + expandUrl,
            )
            .pipe(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((data: any) => {
                    const adapt = data as EquipReportCategory;
                    adapt.equipReportCategory = adapt.equipReportCategory.toUpperCase();
                    return adapt;
                }),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    update(key: number, equipCategory: EquipReportCategory): Observable<EquipReportCategory> {
        return this.http
            .put<EquipReportCategory>(
                `${this.apibaseUrl}${this.controllerName}/${key}`,
                equipCategory,
            )
            .pipe(
                map((data: EquipReportCategory) => {
                    return data ? data : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}
