import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { nameof } from '@bds/core';
import { RtStateCode, RtStateCodeAdapter } from '@bds/railtrac-models';
import ODataStore from 'devextreme/data/odata/store';
import { Observable, of } from 'rxjs';
import { catchError, map, delay, shareReplay } from 'rxjs/operators';
import { ApiErrorHandlerService, ArrayFunctionsService } from '@bds/helpers';

// export abstract class BdsStateApiServiceOptions {
//     apiUrl: string = `api/customer/`;
//     odataUrl: string = `odata/customer`;
// }

@Injectable({
    providedIn: 'root',
})
export class BdsStateService {
    controllerName = 'state';
    apiControllerName = 'RtStateCode';

    constructor(
        private http: HttpClient,
        //private options: BdsStateApiServiceOptions,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private adapter: RtStateCodeAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
        private arrayFunctions: ArrayFunctionsService,
    ) {}

    create(trip: RtStateCode): Observable<RtStateCode> {
        console.warn('RailtracTripService.createTrip() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    delete(trip: RtStateCode): Observable<RtStateCode> {
        console.warn('RailtracTripService.deleteTrip() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    getGroupedLookup(): Observable<{ key: string; values: RtStateCode[] }[]> {
        return this.http.get<RtStateCode>(`${this.odatabaseUrl}${this.controllerName}`).pipe(
            map((data: any) => {
                let lookup: RtStateCode[] = data.value.map((stateCode) =>
                    this.adapter.adapt(stateCode),
                );
                lookup = this.arrayFunctions.sortAlphabetically(lookup, 'code');
                let groupedStates: { key: string; values: RtStateCode[] }[] =
                    this.arrayFunctions.groupBy(lookup || [], (state) => state.countryCode);
                // Only keep USA, Canada, and Mexico
                groupedStates = groupedStates.filter(
                    (g) =>
                        g.key.trim().toUpperCase() === 'USA' ||
                        g.key.trim().toUpperCase() === 'CAN' ||
                        g.key.trim().toUpperCase() === 'MEX',
                );
                groupedStates = this.arrayFunctions.sortAlphabetically(groupedStates, 'key', false);
                return groupedStates;
            }),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    getLookup(): Observable<RtStateCode[]> {
        return this.http.get<RtStateCode>(`${this.odatabaseUrl}${this.controllerName}`).pipe(
            map((data: any) => {
                let lookup: RtStateCode[] = data.value.map((stateCode) =>
                    this.adapter.adapt(stateCode),
                );
                lookup = this.arrayFunctions.sortAlphabetically(lookup, 'code');
                return lookup;
            }),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    patch(trip: RtStateCode): Observable<RtStateCode> {
        console.warn('RailtracTripService.patchTrip() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    read(code: string): Observable<RtStateCode> {
        return this.readViaOdata(code);
    }

    readViaApi(code: string): Observable<RtStateCode> {
        return this.http
            .get<RtStateCode>(`${this.apibaseUrl}${this.apiControllerName}/${code}`)
            .pipe(
                map((data: any) => this.adapter.adapt(data)),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    readViaOdata(code: string): Observable<RtStateCode> {
        return this.http
            .get<RtStateCode>(`${this.odatabaseUrl}${this.controllerName}('${code}')`)
            .pipe(
                map((data: any) => this.adapter.adapt(data)),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    update(trip: RtStateCode): Observable<RtStateCode> {
        console.warn('RailtracTripService.updateTrip() is a dummy method');
        return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    }

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtStateCode>('code'),
        ).server;

        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: keyField,
        });
    }
}
