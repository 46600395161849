import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';
import {
    FormGroupDescription,
    GridColumnDescription,
    IColumnDescription,
    RtSegmentMiles,
} from '@bds/railtrac-models';
import ODataStore from 'devextreme/data/odata/store';
import { map } from 'rxjs/operators';

export class BdsSegmentMiles {
    constructor(
        public ormId?: number,
        public beginSplc?: string,
        public endSplc?: string,
        public railroad?: string,
        public entryDate?: Date,
        public miles?: number,
        public benchmarkHours?: number,
        public userId?: string,
        public isActive?: string,
        public weightLimit?: number,
    ) {}
}

export const relationship: IModelMetadata[] = [
    { client: nameof<BdsSegmentMiles>('ormId'), server: 'ormId' },
    { client: nameof<BdsSegmentMiles>('beginSplc'), server: 'beginSplc' },
    { client: nameof<BdsSegmentMiles>('endSplc'), server: 'endSplc' },
    { client: nameof<BdsSegmentMiles>('railroad'), server: 'railroad' },
    { client: nameof<BdsSegmentMiles>('entryDate'), server: 'entryDate' },
    { client: nameof<BdsSegmentMiles>('miles'), server: 'miles' },
    { client: nameof<BdsSegmentMiles>('benchmarkHours'), server: 'benchmarkHours' },
    { client: nameof<BdsSegmentMiles>('userId'), server: 'userId' },
    { client: nameof<BdsSegmentMiles>('isActive'), server: 'isActive' },
    { client: nameof<BdsSegmentMiles>('weightLimit'), server: 'weightLimit' },
];

@Injectable({
    providedIn: 'root',
})
export class BdsSegmentMilesAdapter extends TwoWayAdapter<BdsSegmentMiles> {
    metadata: IModelMetadata[] = relationship;

    constructor() {
        super();
    }
}

@Injectable({
    providedIn: 'root',
})
export class BdsSegmentMilesMetadata implements GridColumnDescription, FormGroupDescription {
    getColumns(): IColumnDescription[] {
        return [
            // {dataField: nameof<BdsSegmentMiles>('ormId'), dataType: 'number', caption: 'OrmId', visible: false},
            // {dataField: nameof<BdsSegmentMiles>('beginSplc'), dataType: 'string', caption: 'Begin Splc', visible: true},
            // {dataField: nameof<BdsSegmentMiles>('endSplc'), dataType: 'string', caption: 'End Splc', visible: true},
            // {dataField: nameof<BdsSegmentMiles>('railroad'), dataType: 'string', caption: 'Railroad', visible: true},
            // {dataField: nameof<BdsSegmentMiles>('entryDate'), dataType: 'datetime', caption: 'Entry Date', visible: true},
            // {dataField: nameof<BdsSegmentMiles>('miles'), dataType: 'number', caption: 'Miles', visible: true},
            // {dataField: nameof<BdsSegmentMiles>('benchmarkHours'), dataType: 'number', caption: 'Benchmark Hours', visible: true},
            // {dataField: nameof<BdsSegmentMiles>('userId'), dataType: 'string', caption: 'User', visible: true},
            // {dataField: nameof<BdsSegmentMiles>('isActive'), dataType: 'string', caption: 'Is Active', visible: true},
            // {dataField: nameof<BdsSegmentMiles>('weightLimit'), dataType: 'number', caption: 'Weight Limit', visible: true},
        ];
    }

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<BdsSegmentMiles>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<BdsSegmentMiles>('beginSplc'),
            new UntypedFormControl('', Validators.required),
        );
        formGroup.addControl(
            nameof<BdsSegmentMiles>('endSplc'),
            new UntypedFormControl('', Validators.required),
        );
        formGroup.addControl(
            nameof<BdsSegmentMiles>('railroad'),
            new UntypedFormControl('', Validators.required),
        );
        formGroup.addControl(nameof<BdsSegmentMiles>('entryDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<BdsSegmentMiles>('miles'), new UntypedFormControl(''));
        formGroup.addControl(nameof<BdsSegmentMiles>('benchmarkHours'), new UntypedFormControl(''));
        formGroup.addControl(nameof<BdsSegmentMiles>('userId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<BdsSegmentMiles>('isActive'), new UntypedFormControl(''));
        formGroup.addControl(nameof<BdsSegmentMiles>('weightLimit'), new UntypedFormControl(''));

        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}

@Injectable({ providedIn: 'root' })
export class BdsSegmentMilesApiServiceOptions {
    apiUrl: string = `api/segmentmiles/`;
    odataUrl: string = `odata/segmentmiles`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsSegmentMilesService {
    constructor(
        private adapter: BdsSegmentMilesAdapter,
        private http: HttpClient,
        private options: BdsSegmentMilesApiServiceOptions,
    ) {}

    create(value: RtSegmentMiles) {
        return this.http.post(`${this.options.apiUrl}`, value);
    }

    getById(id: number) {
        return this.http
            .get(`${this.options.odataUrl}(${id})`)
            .pipe(map((x) => this.adapter.adapt(x)));
    }

    getByIds(ids: number[]) {
        return this.http
            .get(`${this.options.odataUrl}?$filter=ormId in (${ids.join(',')})`)
            .pipe(map((response: any) => response.value.map((x) => this.adapter.adapt(x))));
    }

    getODataStore() {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<BdsSegmentMiles>('ormId'),
        ).server;

        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: keyField,
        });
    }

    update(value: RtSegmentMiles) {
        return this.http.put(`${this.options.apiUrl}/${value.ormId}`, value);
    }

    getDefaultSegmentData(beginSplc: string, endSplc: string, road: string) {
        return this.http
            .get<{ value: RtSegmentMiles[] }>(
                `${this.options.odataUrl}?$filter=beginSplc eq '${beginSplc}'` +
                    ` and endSplc eq '${endSplc}' and railroad eq '${road}'`,
            )
            .pipe(
                map((response) => {
                    if (response.value.length > 0) {
                        return response.value[0];
                    }
                    return null;
                }),
            );
    }
}
