import { Component } from '@angular/core';
import { PageService } from '@bds/core';

@Component({
    selector: 'app-trip-diverted-screen',
    templateUrl: './trip-diverted-screen.component.html',
    styleUrls: ['./trip-diverted-screen.component.scss'],
})
export class TripDivertedScreenComponent {
    constructor(public pageService: PageService) {
        pageService.setHeader('Diversions');
    }
}
