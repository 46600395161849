import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BdsCustomerService } from '@bds/reference-data-access';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import { forkJoin } from 'rxjs';
import { FormlyDialogData } from '../../../formly-components/bds-formly-dialog/bds-formly-dialog.component';

@Component({
    selector: 'bds-add-customer-dialog',
    templateUrl: './bds-add-preferred-route.component.html',
    styleUrls: ['./bds-add-preferred-route.component.css'],
})
export class BdsAddPreferredRouteComponent implements OnInit {
    dataSource: DataSource;
    @ViewChild('grid') grid: DxDataGridComponent;

    constructor(
        private customerService: BdsCustomerService,
        private dialogRef: MatDialogRef<BdsAddPreferredRouteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FormlyDialogData<any>,
    ) {}

    add() {
        forkJoin(
            this.grid.selectedRowKeys
                .map((x) => {
                    return {
                        routeCode: this.data.model['routeCode'],
                        originSplc: this.data.model['originSplc'],
                        customerNo: x,
                    };
                })
                .map((x) => this.data.action(x)),
        ).subscribe((response) => {
            this.dialogRef.close(response);
        });
    }

    ngOnInit(): void {
        this.dataSource = new DataSource({
            store: this.customerService.getODataStore(),
            paginate: true,
            pageSize: 10,
        });
    }
}
