import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { RtJeopardizedShipment } from '@bds/railtrac-models';

@Component({
    selector: 'rt-jeopardized-shipment-reason',
    templateUrl: './rt-jeopardized-shipment-reason.component.html',
    styleUrls: ['./rt-jeopardized-shipment-reason.component.scss'],
})
export class RtJeopardizedShipmentReasonComponent implements OnInit {
    @Input() shipment: RtJeopardizedShipment;
    @Input() resolved: boolean;
    @Input() addTripRouterLink: [];
    @Output() change = new EventEmitter();
    @Output() popoutTrip = new EventEmitter<number>();

    constructor() {}

    ngOnInit() {}

    onPopoutTrip($event: number) {
        this.popoutTrip.emit($event);
    }

    shipmentChanged() {
        this.change.emit();
    }
}
