import { Component } from '@angular/core';
import { PageService } from '@bds/core';

@Component({
    selector: 'app-path-code-screen',
    template: ` <router-outlet></router-outlet>`,
})
export class PathCodeScreenComponent {
    constructor(private pageService: PageService) {
        this.pageService.setHeader('Path codes');
    }
}

