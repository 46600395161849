import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from '../adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

import { RtClm, ClmType } from './rt-clm';

export class RtClmCurrent extends RtClm {
    constructor(public clmType: ClmType) {
        super();
    }
    from(trip: RtClmCurrent): RtClmCurrent {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtClmCurrent>('ormId'), server: 'ormId' },
    { client: nameof<RtClmCurrent>('clmType'), server: 'clmType' },
    { client: nameof<RtClmCurrent>('carInit'), server: 'carInit' },
    { client: nameof<RtClmCurrent>('carNo'), server: 'carNo' },
    { client: nameof<RtClmCurrent>('clmDateTime'), server: 'clmDateTime' },
    { client: nameof<RtClmCurrent>('sightCode'), server: 'sightCode' },
    { client: nameof<RtClmCurrent>('locationCity'), server: 'locationCity' },
    { client: nameof<RtClmCurrent>('locationState'), server: 'locationState' },
    { client: nameof<RtClmCurrent>('loadEmptyFlag'), server: 'loadEmptyFlag' },
    { client: nameof<RtClmCurrent>('trainJunction'), server: 'trainJunction' },
    { client: nameof<RtClmCurrent>('road'), server: 'road' },
    { client: nameof<RtClmCurrent>('destinationCity'), server: 'destinationCity' },
    { client: nameof<RtClmCurrent>('destinationState'), server: 'destinationState' },
    { client: nameof<RtClmCurrent>('clmProcessedDate'), server: 'clmProcessedDate' },
    { client: nameof<RtClmCurrent>('clmEvent'), server: 'clmEvent' },
    { client: nameof<RtClmCurrent>('routeCode'), server: 'routeCode' },
    {
        client: nameof<RtClmCurrent>('actualHoursToDestination'),
        server: 'actualHoursToDestination',
    },
    { client: nameof<RtClmCurrent>('actualHoursToOrigin'), server: 'actualHoursToOrigin' },
    { client: nameof<RtClmCurrent>('locationSplc'), server: 'locationSplc' },
    { client: nameof<RtClmCurrent>('destinationSplc'), server: 'destinationSplc' },
    { client: nameof<RtClmCurrent>('railRoadEtaCity'), server: 'railRoadEtaCity' },
    { client: nameof<RtClmCurrent>('railRoadEtaState'), server: 'railRoadEtaState' },
    { client: nameof<RtClmCurrent>('railRoadEtaSightCode'), server: 'railRoadEtaSightCode' },
    { client: nameof<RtClmCurrent>('railRoadEtaDateTime'), server: 'railRoadEtaDateTime' },
    {
        client: nameof<RtClmCurrent>('railRoadEtaDestinationSplc'),
        server: 'railRoadEtaDestinationSplc',
    },
    { client: nameof<RtClmCurrent>('source'), server: 'source' },
    { client: nameof<RtClmCurrent>('previousSplc'), server: 'previousSplc' },
    { client: nameof<RtClmCurrent>('milesFromPrevious'), server: 'milesFromPrevious' },
    { client: nameof<RtClmCurrent>('dwellTime'), server: 'dwellTime' },
    { client: nameof<RtClmCurrent>('clmDifferentialTime'), server: 'clmDifferentialTime' },
];

@Injectable({
    providedIn: 'root',
})
export class RtClmCurrentAdapter extends TwoWayAdapter<RtClmCurrent> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtClmCurrentMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtClmCurrent>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('clmDateTime'),
                dataType: 'Date',
                caption: 'clmDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('sightCode'),
                dataType: 'string',
                caption: 'sightCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('locationCity'),
                dataType: 'string',
                caption: 'locationCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('locationState'),
                dataType: 'string',
                caption: 'locationState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('loadEmptyFlag'),
                dataType: 'string',
                caption: 'loadEmptyFlag',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('trainJunction'),
                dataType: 'string',
                caption: 'trainJunction',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('road'),
                dataType: 'string',
                caption: 'road',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('destinationCity'),
                dataType: 'string',
                caption: 'destinationCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('destinationState'),
                dataType: 'string',
                caption: 'destinationState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('clmProcessedDate'),
                dataType: 'Date',
                caption: 'clmProcessedDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('clmEvent'),
                dataType: 'string',
                caption: 'clmEvent',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('locationSplc'),
                dataType: 'string',
                caption: 'locationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('destinationSplc'),
                dataType: 'string',
                caption: 'destinationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('railRoadEtaCity'),
                dataType: 'string',
                caption: 'railRoadEtaCity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('railRoadEtaState'),
                dataType: 'string',
                caption: 'railRoadEtaState',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('railRoadEtaSightCode'),
                dataType: 'string',
                caption: 'railRoadEtaSightCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('railRoadEtaDateTime'),
                dataType: 'Date',
                caption: 'railRoadEtaDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('railRoadEtaDestinationSplc'),
                dataType: 'string',
                caption: 'railRoadEtaDestinationSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('source'),
                dataType: 'string',
                caption: 'source',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('dwellTime'),
                dataType: 'number',
                caption: 'dwellTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtClmCurrent>('clmDifferentialTime'),
                dataType: 'number',
                caption: 'clmDifferentialTime',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtClmCurrent>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('clmDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('sightCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('locationCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('locationState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('loadEmptyFlag'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('trainJunction'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('road'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('destinationCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('destinationState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('clmProcessedDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('clmEvent'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('locationSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('destinationSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('railRoadEtaCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('railRoadEtaState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('railRoadEtaSightCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('railRoadEtaDateTime'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtClmCurrent>('railRoadEtaDestinationSplc'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtClmCurrent>('source'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('dwellTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtClmCurrent>('clmDifferentialTime'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
