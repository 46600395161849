<h4>Destination Criteria</h4>
<div class="row destination-grid">
    <dx-data-grid
        bds-grid
        [bdsUseFastrac]="false"
        id="bdsDestinationCriteriaGrid"
        [dataSource]="gridSource"
        [bdsAllowAddRecord]="true"
        [bdsAllowCloneRecords]="true"
        [bdsAllowEditRecords]="true"
        [bdsAllowDeleteRecords]="true"
        [(selectedRowKeys)]="selectedRowKeys"
        (bdsAddRecordClicked)="onAdd()"
        (bdsCloneRecordsClicked)="onClone()"
        (bdsEditRecordsClicked)="onEdit()"
        (bdsDeleteRecordsClicked)="onRemove()"
    >
        <dxi-column dataField="customerNo" [minWidth]="150" caption="Consignee No."></dxi-column>
        <dxi-column dataField="sightCode" [width]="105" caption="Sight Code"></dxi-column>
        <dxi-column dataField="railroad" [width]="75" caption="Road"></dxi-column>
        <dxi-column dataField="locationCity" [minWidth]="115" caption="Location City"></dxi-column>
        <dxi-column dataField="locationState" [width]="75" caption="State"></dxi-column>
        <dxi-column
            dataField="customerDeliveryHours"
            [width]="110"
            caption="Delivery Hrs"
        ></dxi-column>
    </dx-data-grid>
</div>
