<div class="container-fluid">
    <bds-fastrac-design-display
        [gridId]="gridId"
        [fastracId]="fastracId"
        [showFastrac]="showFastrac"
        (showFastracChange)="onFastracVisibleChanged($event)"
        (fastracIdChange)="onFastracIdChanged($event)"
        (fastracDesignsChange)="onFastracDesignsChanged($event)"
        (applyFastrac)="onApplyFastrac($event)"
    >
    </bds-fastrac-design-display>
</div>
<bds-grid-details>
    <ng-container grid>
        <bds-fastrac-dx-grid-wrapper
            #dxGridWrapper
            (bdsAddRecordClicked)="createRecord()"
            (bdsShowFastrac)="onShowFastrac()"
            (fastracIdChange)="onGridFastracIdChanged($event)"
            [bdsAllowAddRecord]="true"
            [bdsAllowDeleteRecords]="true"
            [bdsCustomizeColumns]="customizeColumns"
            [bdsFastracDesignList]="fastracList"
            [fastracDesign]="currentFastrac"
            [fastracId]="fastracId"
            id="bdsRouteGrid"
            [gridId]="gridId"
            [dataSource]="gridSource"
            (bdsDeleteRecordsClicked)="deleteRecords()"
            [(selectedRowKeys)]="selectedRowKeys"
            (selectedRowKeysChange)="navigate(grid.instance.getSelectedRowKeys())"
            [showColumnChooser]="true"
        ></bds-fastrac-dx-grid-wrapper>
    </ng-container>
    <ng-container details>
        <router-outlet></router-outlet>
    </ng-container>
</bds-grid-details>
