<div class="container-fluid">
    <mat-toolbar>
        <mat-toolbar-row>
            <span></span>
            <span class="fill-remaining-space"></span>
            <span>
                <button
                    mat-raised-button
                    (click)="beforeRunSelectedFastracs()"
                    [disabled]="!selectedFastracIds || selectedFastracIds.length < 1"
                >
                    <fa-icon [icon]="iconRunFastrac" [fixedWidth]="true" size="lg"></fa-icon>Run
                    FASTRACs <fa-icon [icon]="iconExternalLink" transform="up-5 shrink-3"></fa-icon>
                </button>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div></div>
    <dx-data-grid
        #fastracGrid
        bds-grid
        [gridId]="5"
        [minHeight]="200"
        [bdsUseFastrac]="false"
        id="bdsFastracGrid"
        [dataSource]="gridSource"
        height="50vh"
        minHeight="200px"
        [dateFormat]="dateFormat"
        [(selectedRowKeys)]="selectedFastracIds"
        (onRowDblClick)="rowDblclick($event)"
        bdsGridMode="multiple"
    >
        <dxi-column dataField="id" caption="ID" [visible]="false"></dxi-column>
        <dxi-column dataField="name" cellTemplate="nameCellTemplate"></dxi-column>
        <dxi-column dataField="gridId" caption="Grid ID" [visible]="false"></dxi-column>
        <dxi-column dataField="grid.appName" caption="Application"></dxi-column>
        <dxi-column dataField="grid.gridName" caption="Page"></dxi-column>
        <dxi-column
            dataField="isClassic"
            caption="Classic"
            cellTemplate="isClassicCellTemplate"
            [visible]="false"
        ></dxi-column>
        <dxi-column dataField="iconId" caption="Icon ID" [visible]="false"></dxi-column>
        <dxi-column dataField="ownerId" caption="Owner"></dxi-column>
        <dxi-column
            dataField="createdDateTime"
            caption="Created On"
            dataType="datetime"
        ></dxi-column>
        <dxi-column
            dataField="lastModifiedDateTime"
            caption="Last Modified On"
            dataType="datetime"
        ></dxi-column>
        <dxi-column dataField="lastModifiedUserId" caption="Last Modified By"></dxi-column>

        <div *dxTemplate="let data of 'nameCellTemplate'">
            <ng-container [ngSwitch]="getIcon(data.data)">
                <span *ngSwitchCase="'lock'"
                    ><fa-icon [icon]="iconLock" [fixedWidth]="true"></fa-icon
                ></span>
                <span *ngSwitchCase="'classic'"
                    ><fa-icon [icon]="iconClassic" [fixedWidth]="true"></fa-icon
                ></span>
                <span *ngSwitchCase="'favorite'"
                    ><fa-icon [icon]="iconFavorite" [fixedWidth]="true"></fa-icon
                ></span>
                <span *ngSwitchCase="'shared'"
                    ><fa-icon [icon]="iconShared" [fixedWidth]="true"></fa-icon
                ></span>
                <span *ngSwitchDefault
                    ><fa-icon [icon]="iconFavorite" [fixedWidth]="true" class="invisible"></fa-icon
                ></span>
            </ng-container>
            <span>{{ data.displayValue }}</span>
        </div>

        <div *dxTemplate="let data of 'isClassicCellTemplate'">
            <span *ngIf="!!data.value">Yes</span>
            <span *ngIf="!data.value">No</span>
        </div>
    </dx-data-grid>

    <div *ngFor="let id of selectedFastracIds" class="mt-3">
        <app-fastrac-management-details
            [fastracId]="id"
            [dateFormat]="dateFormat"
            (fastracChanged)="fastracChanged($event)"
            (fastracDeleted)="fastracDeleted($event)"
        ></app-fastrac-management-details>
    </div>

    <!-- <mat-tab-group class="mt-3">
        <mat-tab [label]="getNameFromGrid(id) | async" *ngFor="let id of selectedFastracIds">
            <app-fastrac-management-details [fastracId]="id"
                                            [dateFormat]="dateFormat"
                                            (fastracChanged)="fastracChanged($event)"
                                            (fastracDeleted)="fastracDeleted($event)"></app-fastrac-management-details>
        </mat-tab>
      </mat-tab-group> -->
</div>
