// TODO: Find out what this does - just guessing on the returns of some methods - all were "void" initially
// This has errors when run, even prior to move!!
// TODO: Nothing was set up for unit test. Set it up to compile, but tests nothing else.

import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { shareReplay, take, tap } from 'rxjs/operators';
import { faStop, faUndo } from '@fortawesome/pro-solid-svg-icons';
import { FormErrorHandlerService } from '@bds/helpers';
import {
    EquipReportCategoryService,
    EquipReportCatgEquipService,
    EquipReportCatgValueService,
} from '../../data-access';
import {
    Equipment,
    EquipReportCategory,
    EquipReportCatgEquip,
    EquipReportCatgValue,
} from '../../models';
import { EquipmentReportCategoriesDialogOptions } from './equipment-report-categories-dialog.model';

@Component({
    templateUrl: './equipment-report-categories-dialog.component.html',
    styleUrls: ['./equipment-report-categories-dialog.component.scss'],
})
export class EquipmentReportCategoriesDialogComponent implements OnInit {
    iconUndo = faUndo;
    iconDelete = faStop;

    equipmentGroup: { [key: number]: Equipment } = {};
    equipCategoriesDict: { [key: number]: EquipReportCategory } = {};
    equipCatgValuesGroup: { [key: number]: EquipReportCatgValue[] } = {};
    equipCatgEquipGroupOrig: { [key: number]: EquipReportCatgEquip } = {};

    form: UntypedFormGroup = new UntypedFormGroup({
        cats: new UntypedFormArray([]),
        equip: new UntypedFormArray([]),
    });

    formArray: UntypedFormArray = this.form.controls['cats'] as UntypedFormArray;
    equipFormArray: UntypedFormArray = this.form.controls['equip'] as UntypedFormArray;

    constructor(
        public dialogRef: MatDialogRef<
            EquipmentReportCategoriesDialogComponent,
            EquipReportCatgEquip[]
        >,
        @Inject(MAT_DIALOG_DATA) public data: EquipmentReportCategoriesDialogOptions,
        public errorService: FormErrorHandlerService,
        private equipReportCategoryService: EquipReportCategoryService,
        private equipReportCatgEquipService: EquipReportCatgEquipService,
        private equipReportCatgValueService: EquipReportCatgValueService,
    ) {
        this.equipReportCategoryService
            .getAll()
            .pipe(
                tap((v: EquipReportCategory[]) => {
                    const grp: {
                        [key: number]: EquipReportCatgValue;
                    } = {};
                    v.map((s) => {
                        grp[s.ormId] = s;
                    });
                    this.equipCategoriesDict = grp;
                }),
                tap((v: EquipReportCategory[]) => this.buildForm(v)),
                take(1),
                shareReplay(1),
            )
            .subscribe();

        this.getCategoryValues();
    }

    ngOnInit() {
        if (this.data && this.data.equipment) {
            this.data.equipment.forEach((f) => (this.equipmentGroup[f.ormId] = f));
        } else {
            console.warn('No equipment passed to dialog box');
        }

        if (this.data && this.data.item) {
            if (Array.isArray(this.data.item)) {
                this.data.item.sort(this.sortEquipCatgEquip);
                this.data.item.forEach((f) => {
                    this.equipFormArray.push(this.buildEquipReportCatgEquipForm(f));
                    this.equipCatgEquipGroupOrig[f.ormId] = f;
                });
            } else if (this.data.item instanceof Observable) {
                this.data.item.subscribe((s) => {
                    s.sort(this.sortEquipCatgEquip);
                    s.forEach((f) => {
                        this.equipFormArray.push(this.buildEquipReportCatgEquipForm(f));
                        this.equipCatgEquipGroupOrig[f.ormId] = f;
                    });
                });
            } else {
                this.equipFormArray.push(this.buildEquipReportCatgEquipForm(this.data.item));
                this.equipCatgEquipGroupOrig[this.data.item.ormId] = this.data.item;
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    sortEquipCatgEquip = (a: EquipReportCatgEquip, b: EquipReportCatgEquip) => {
        const eqSort = a.equipmentId - b.equipmentId;
        return eqSort !== 0 ? eqSort : a.equipReportCatgId - a.equipReportCatgId;
    };

    buildEquipReportCatgEquipForm(eqCat: EquipReportCatgEquip): UntypedFormGroup {
        const newForm = new UntypedFormGroup({
            ormId: new UntypedFormControl(eqCat.ormId, []),
            equipmentId: new UntypedFormControl(eqCat.equipmentId, []),
            equipReportCatgId: new UntypedFormControl(eqCat.equipReportCatgId, []),
            equipReportCatgValId: new UntypedFormControl(eqCat.equipReportCatgValId, [
                // eslint-disable-next-line @typescript-eslint/unbound-method
                Validators.required,
                Validators.min(1),
            ]),
            effectDate: new UntypedFormControl(eqCat.effectDate, []),
            expireDate: new UntypedFormControl(eqCat.expireDate, []),
            lastModifiedUser: new UntypedFormControl(eqCat.lastModifiedUser, []),
            lastModifiedDatetime: new UntypedFormControl(eqCat.lastModifiedDatetime, []),

            __status: new UntypedFormControl('', []),
        });

        newForm.get('equipReportCatgValId').valueChanges.subscribe((s) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const ormId = newForm.get('ormId').value;
            if (ormId) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const valId = this.equipCatgEquipGroupOrig[ormId].equipReportCatgValId;
                if (s === valId) {
                    newForm.get('__status').setValue('');
                } else {
                    newForm.get('__status').setValue('Update');
                }
            }
        });

        return newForm;
    }

    buildForm(fields: EquipReportCategory[]): void {
        const fieldMap = fields.map((m) => {
            const newFormGroup = new UntypedFormGroup({
                ormId: new UntypedFormControl(m.ormId, []),
                equipReportCategory: new UntypedFormControl(m.equipReportCategory, []),
                transitMode: new UntypedFormControl(m.transitMode, []),
                categoryDscr: new UntypedFormControl(m.categoryDscr, []),
                tcmEquipReportCatgEquip: new UntypedFormControl(m.tcmEquipReportCatgEquip, []),
                tcmEquipReportCatgValue: new UntypedFormControl(m.tcmEquipReportCatgValue, []),

                __tempCatgValue: new UntypedFormControl(0, []),
            });

            newFormGroup.get('__tempCatgValue').valueChanges.subscribe((s) => {
                this.getEquipmentList().map((eq) => {
                    let found = false;
                    for (let i = 0; i < this.equipFormArray.length; i++) {
                        const element = this.equipFormArray.at(i);
                        if (
                            element.get('equipmentId').value === eq &&
                            element.get('equipReportCatgId').value ===
                                newFormGroup.get('ormId').value
                        ) {
                            element.get('equipReportCatgValId').setValue(s);
                            found = true;
                        }
                    }
                    if (!found) {
                        const n = <EquipReportCatgEquip>{
                            ormId: 0,
                            equipmentId: eq,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            equipReportCatgValId: s,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            equipReportCatgId: newFormGroup.get('ormId').value,
                        };
                        const ng = this.buildEquipReportCatgEquipForm(n);
                        ng.get('__status').setValue('Insert');
                        this.equipFormArray.push(ng);
                    }
                });

                this.equipFormArray.patchValue(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    this.equipFormArray.value.sort(this.sortEquipCatgEquip),
                );
            });

            return newFormGroup;
        });
        this.formArray.clear();
        fieldMap.forEach((fm) => {
            this.formArray.push(fm);
        });
    }

    getEquipCatLabel(ctrl: UntypedFormControl): string {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const ctrlValue: number = ctrl?.value;

        if (this.equipCategoriesDict && ctrlValue) {
            const equipCat = this.equipCategoriesDict[ctrlValue];
            if (equipCat) {
                return equipCat.equipReportCategory;
            }
        }
        return '';
    }

    getEquipmentList(): number[] {
        return Object.values(this.equipmentGroup).map((m) => m.ormId);
    }

    getCategories(): Observable<EquipReportCategory[]> {
        return this.equipReportCategoryService.getAll().pipe(take(1));
    }

    getCategoryValues(): void {
        this.equipReportCatgValueService
            .getAll()
            .pipe(
                tap((v: EquipReportCatgValue[]) => {
                    const grp: { [key: number]: EquipReportCatgValue[] } = {};
                    v.map((s) => {
                        (grp[s.equipReportCatgId] = grp[s.equipReportCatgId] || []).push(s);
                    });
                    this.equipCatgValuesGroup = grp;
                }),
                take(1),
                shareReplay(1),
            )
            .subscribe();
    }

    getErrorMessage(control: UntypedFormControl): string {
        return this.errorService.getFormError(control);
    }

    expireOnAll(control: UntypedFormControl): void {
        for (let i = 0; i < this.equipFormArray.length; i++) {
            const element = this.equipFormArray.at(i) as UntypedFormControl;
            if (element.get('equipReportCatgId').value === control.get('ormId').value) {
                element.get('__status').setValue('Delete');
            }
        }
    }

    toggleStatus(control: UntypedFormControl): void {
        const statusControl = control.get('__status');
        if (statusControl.value !== 'Delete') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const ormId = control.get('ormId').value;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const valId = this.equipCatgEquipGroupOrig[ormId].equipReportCatgValId;
            control.get('equipReportCatgValId').setValue(valId);
            statusControl.setValue('Delete');
        } else if (control.get('ormId').value === 0) {
            statusControl.setValue('Insert');
        } else {
            statusControl.setValue('Update');
        }
        control.markAsDirty();
    }

    resetEquipCategory(control: UntypedFormControl): void {
        const status = control.get('__status');
        switch (status.value) {
            case 'Delete': {
                if (control.get('ormId').value === 0) {
                    status.setValue('Insert');
                } else {
                    status.setValue('');
                    control.markAsPristine();
                }
                break;
            }
            case 'Insert': {
                status.setValue('Delete');
                break;
            }
            case 'Update': {
                status.setValue('');
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const uOrmId = control.get('ormId').value;
                control
                    .get('equipReportCatgValId')
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    .setValue(this.equipCatgEquipGroupOrig[uOrmId].equipReportCatgValId);
                control.markAsPristine();
                break;
            }
        }
    }

    onYesClick(): void {
        if (this.form.valid) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const result = this.equipFormArray.value;
            if (!!this.data && !!this.data.beforeCloseAction) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const action = this.data.beforeCloseAction(result);
                if (typeof action === 'boolean') {
                    if (action) {
                        this.dialogRef.close(result);
                    }
                } else if (typeof action === 'string') {
                    // TODO: According to Shawn, this is an error message
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    action.subscribe((actResult) => {
                        if (typeof actResult === 'boolean') {
                            if (actResult) {
                                this.dialogRef.close(result);
                            }
                        }
                    });
                }
            }
            this.dialogRef.close(result);
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
