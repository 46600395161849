<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <dx-data-grid
        id="bdsReportGrid"
        [dataSource]="gridSource"
        height="80vh"
        minHeight="200px"
        dataRowTemplate="dataRowTemplateName"
        (onCellPrepared)="onCellPrepared($event)"
    >
        <dxi-column dataField="route_detail" caption="Route Detail"></dxi-column>
        <dxi-column dataField="NO_OF_TRIPS" caption="No.of Trips"></dxi-column>
        <dxi-column dataField="route_code" caption="Route Code"></dxi-column>
        <dxi-column dataField="origin_city" caption="Origin"> </dxi-column>
        <dxi-column dataField="dest_city" caption="Destination"></dxi-column>
        <dxi-column caption="Outbound" alignment="center">
            <dxi-column dataField="OUT_ACT_HRS" caption="Act Hrs"></dxi-column>
            <dxi-column dataField="eta_hrs_out" caption="ETA Hrs"></dxi-column>
            <dxi-column dataField="OUT_VAR_HRS" caption="Var Hrs"></dxi-column>
        </dxi-column>
        <dxi-column caption="Inbound" alignment="center">
            <dxi-column dataField="IN_ACT_HRS" caption="Act Hrs"></dxi-column>
            <dxi-column dataField="eta_hrs_in" caption="ETA Hrs"></dxi-column>
            <dxi-column dataField="IN_VAR_HRS" caption="Var Hrs"></dxi-column>
        </dxi-column>
        <dxi-column dataField="TOTAL_VAR_HRS" caption="Total Var Hrs"></dxi-column>
        <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
            <tr class="main-row">
                <td>{{ item.data.route_detail }}</td>
                <td>{{ item.data.NO_OF_TRIPS }}</td>
                <td>{{ item.data.route_code }}</td>
                <td>{{ item.data.origin_city }} {{ item.data.origin_state }}</td>
                <td>{{ item.data.dest_city }} {{ item.data.dest_state }}</td>
                <td>{{ item.data.OUT_ACT_HRS }}</td>
                <td>{{ item.data.eta_hrs_out }}</td>
                <td>{{ item.data.OUT_VAR_HRS }}</td>
                <td>{{ item.data.IN_ACT_HRS }}</td>
                <td>{{ item.data.eta_hrs_in }}</td>
                <td>{{ item.data.IN_VAR_HRS }}</td>
                <td>{{ item.data.TOTAL_VAR_HRS }}</td>
            </tr>
            <tr class="second-row">
                <td></td>
                <td colspan="4">
                    <div>
                        <span><b>Route Dscr: </b></span>{{ item.data.route_dscr }}
                    </div>
                </td>
                <td colspan="7">
                    <div>
                        <span><b>Dlv Carrier: </b></span>{{ item.data.dlv_road }}
                    </div>
                </td>
            </tr>
        </ng-container>
    </dx-data-grid>
</div>
