<div class="equipment-grid-container">
    <dx-data-grid
        bds-grid
        [bdsUseFastrac]="false"
        id="bdsComponentGrid"
        #bdsComponentGrid
        [dataSource]="gridSource"
        maxHeight="400px"
        [selectedRowKeys]="selectedRowKeys"
        [bdsAllowAddRecord]="true"
        (bdsAddRecordClicked)="addNew()"
        (selectedRowKeysChange)="onSelectedRowKeysChanged($event)"
    >
    </dx-data-grid>
</div>
