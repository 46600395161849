<button bdsSidebarItem [routerLink]="['/']" [routerLinkActive]="['active']" title="Home">
    <fa-icon bdsSidebarItemIcon [fixedWidth]="true" [icon]="iconHome"></fa-icon>
    <span bdsSidebarItemText>Home</span>
</button>
<mat-expansion-panel
    bdsSidebarMenu
    [hideToggle]="true"
    class="bg-transparent"
    style="color: #ced4da"
>
    <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
        <div bdsSidebarItem title="FASTRAC">
            <div>
                <fa-icon
                    bdsSidebarItemIcon
                    [icon]="iconFastrac"
                    [fixedWidth]="true"
                    class="duoIconFavorites"
                ></fa-icon>
                <span bdsSidebarItemText>FASTRAC</span>
                <fa-icon
                    [icon]="caretRight"
                    transform="rotate-45 down-4 right-9 shrink-3"
                ></fa-icon>
            </div>
        </div>
    </mat-expansion-panel-header>
    <a
        bdsSidebarItem
        [routerLink]="['/fastracs']"
        [routerLinkActive]="['active']"
        title="Manage FASTRAC"
        class="no-text-deco"
    >
        Manage FASTRAC
    </a>
    <mat-divider></mat-divider>
    <div
        class="fastracFavList"
        cdkDropList
        (cdkDropListDropped)="fastracFavListDrop($event)"
        (cdkDropListSorted)="fastracFavListSorted($event)"
        (cdkDropListEntered)="fastracFavListEntered($event)"
        (cdkDropListExited)="fastracFavListExited($event)"
    >
        <div
            *ngFor="let fastrac of fastracs"
            cdkDragLockAxis="y"
            cdkDragBoundary=".fastracFavList"
            cdkDrag
        >
            <a
                bdsSidebarItem
                (click)="onFastracLinkClicked(fastrac)"
                [title]="fastrac.name"
                class="no-text-deco"
            >
                <div class="d-flex side-button-hover-only">
                    <div bdsSidebarItemText>{{ fastrac.name }}</div>
                    <div class="flex-fill"></div>
                    <fa-icon
                        *ngIf="!showReorder"
                        [icon]="iconMore"
                        [fixedWidth]="true"
                        class="side-item-menu"
                        (click)="onSidebarMoreButtonClick($event)"
                        [matMenuTriggerFor]="fastracFavMenu"
                        [matMenuTriggerData]="{ fastracId: fastrac.id }"
                    >
                    </fa-icon>
                    <fa-icon
                        *ngIf="showReorder"
                        [icon]="iconGrip"
                        [fixedWidth]="true"
                        class="side-item-gripper"
                        cdkDragHandle
                    >
                    </fa-icon>
                </div>
            </a>
        </div>
    </div>
</mat-expansion-panel>
<a
    bdsSidebarItem
    [routerLink]="['/trips']"
    [routerLinkActive]="['active']"
    title="Shipments"
    class="no-text-deco"
>
    <fa-icon
        bdsSidebarItemIcon
        [fixedWidth]="true"
        [icon]="iconTrips"
        class="duoIconTrips"
    ></fa-icon>
    <span bdsSidebarItemText>Shipments</span>
</a>
<mat-expansion-panel
    bdsSidebarMenu
    [hideToggle]="true"
    class="bg-transparent"
    style="color: #ced4da"
>
    <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
        <div bdsSidebarItem title="Manage Diversions">
            <div>
                <fa-icon
                    bdsSidebarItemIcon
                    [fixedWidth]="true"
                    [icon]="iconDiversions"
                    class="duoIconRoutes"
                ></fa-icon>
                <span bdsSidebarItemText>Manage Diversions</span>
                <fa-icon
                    [icon]="caretRight"
                    transform="rotate-45 down-4 right-9 shrink-3"
                ></fa-icon>
            </div>
        </div>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <a
        bdsSidebarItem
        [routerLink]="['/diversions']"
        title="Diverted Shipments"
        class="no-text-deco"
    >
        Diverted Shipments
    </a>
    <a
        bdsSidebarItem
        [routerLink]="['/trips']"
        [queryParams]="{ fId: divertibleShipmentsFastrac?.id ?? 0 }"
        title="Divertible Shipments"
        class="no-text-deco"
    >
        Divertible Shipments
    </a>
</mat-expansion-panel>

<a
    bdsSidebarItem
    [routerLink]="['/clms']"
    [routerLinkActive]="['active']"
    title="CLMs"
    class="no-text-deco"
>
    <fa-icon bdsSidebarItemIcon [fixedWidth]="true" [icon]="iconClms" class="duoIconClms"></fa-icon>
    <span bdsSidebarItemText>CLMs</span>
</a>
<mat-expansion-panel
    bdsSidebarMenu
    [hideToggle]="true"
    class="bg-transparent"
    style="color: #ced4da"
>
    <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
        <div bdsSidebarItem title="Jeopardized Shipments">
            <div>
                <fa-icon
                    bdsSidebarItemIcon
                    [icon]="iconJeopardizedShipments"
                    [fixedWidth]="true"
                    class="duoIconJeopardy"
                ></fa-icon>
                <span bdsSidebarItemText>Jeopardized Shipments</span>
                <fa-icon
                    [icon]="caretRight"
                    transform="rotate-45 down-4 right-9 shrink-3"
                ></fa-icon>
            </div>
        </div>
    </mat-expansion-panel-header>
    <!-- <button *ngFor="let ft of jeopardizedShipmentFastracs" bdsSidebarItem [routerLink]="['/jeopardized', { fId: ft.id }]" [title]="ft.name">{{ ft.name }}</button> -->
    <ng-container *ngFor="let ft of jeopardizedShipmentFastracs">
        <a
            *ngIf="!!ft"
            bdsSidebarItem
            [routerLink]="['/jeopardized']"
            [queryParams]="{ fId: ft?.id }"
            [title]="ft['name']"
            class="no-text-deco"
        >
            {{ ft['name'] }}
        </a>
    </ng-container>
    <mat-divider></mat-divider>
    <a
        bdsSidebarItem
        [routerLink]="['/dest-diff']"
        [routerLinkActive]="['active']"
        title="Destination Different Aliases"
        class="no-text-deco"
    >
        Destination Aliases
    </a>
    <a
        bdsSidebarItem
        [routerLink]="['/participating-rr']"
        [routerLinkActive]="['active']"
        title="Participating Railroads"
        class="no-text-deco"
    >
        Participating Railroads
    </a>
</mat-expansion-panel>

<mat-expansion-panel
    bdsSidebarMenu
    [hideToggle]="true"
    class="bg-transparent"
    style="color: #ced4da"
>
    <!--Level 1-->
    <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
        <div bdsSidebarItem title="Reports">
            <div>
                <fa-icon
                    bdsSidebarItemIcon
                    [icon]="iconFile"
                    [fixedWidth]="true"
                    class="duoIconJeopardy"
                ></fa-icon>
                <span bdsSidebarItemText>Reports</span>
                <fa-icon
                    [icon]="caretRight"
                    transform="rotate-45 down-4 right-9 shrink-3"
                ></fa-icon>
            </div>
        </div>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <!--Level 2-->
    <!--Speciality-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Speciality">
                <div>
                    <span bdsSidebarItemText>Speciality</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <button
            bdsSidebarItem
            [routerLink]="['/etaPerformanceRoute']"
            title="ETA Performance By Route"
        >
            ETA Performance By Route
        </button>
        <button
            bdsSidebarItem
            [routerLink]="['/etaPerformanceShipment']"
            title="ETA Performance By Shipment"
        >
            ETA Performance By Shipment
        </button>
        <button bdsSidebarItem [routerLink]="['/originPerformance']" title="Origin Performance">
            Origin Performance
        </button>
    </mat-expansion-panel>
    <!--Speciality - Request Delivery Performance-->
    <!-- <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Speciality - Request Delivery Performance">
                <div>
                    <span bdsSidebarItemText>Speciality - Request Delivery Performance</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <button bdsSidebarItem [routerLink]="['/shipment-car-situation-current']" title="By Fleet &amp; Business Group">
            By Fleet &amp; Business Group
        </button>
        <button bdsSidebarItem [routerLink]="['/report']" title="By Consignee">
            By Consignee
        </button>
    </mat-expansion-panel>  -->
    <!--Transit Time - By Cycle-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Transit Time - By Cycle">
                <div>
                    <span bdsSidebarItemText>Transit Time - By Cycle</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeCycleCustomerSummary']"
            [routerLinkActive]="['active']"
            title="Customer Summary"
            class="no-text-deco"
        >
            Customer Summary
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeCycleCustomerSummaryAll']"
            [routerLinkActive]="['active']"
            title="Customer Summary - All"
            class="no-text-deco"
        >
            Customer Summary - All
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeCycleDetail']"
            [routerLinkActive]="['active']"
            title="Detail"
            class="no-text-deco"
        >
            Detail
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeCycleDetailAll']"
            [routerLinkActive]="['active']"
            title="Detail - All"
            class="no-text-deco"
        >
            Detail - All
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeCycleSummary']"
            [routerLinkActive]="['active']"
            title="Summary"
            class="no-text-deco"
        >
            Summary
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeCycleSummaryAll']"
            [routerLinkActive]="['active']"
            title="Summary - All"
            class="no-text-deco"
        >
            Summary - All
        </a>
    </mat-expansion-panel>
    <!--Transit Time-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Transit Time">
                <div>
                    <span bdsSidebarItemText>Transit Time</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeDayOfWeek']"
            [routerLinkActive]="['active']"
            title="By Day of Week"
            class="no-text-deco"
        >
            By Day of Week
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeDestinationState']"
            title="By Destination State"
			[routerLinkActive]="['active']"            
            class="no-text-deco"
        >
            By Destination State
        </a>
    </mat-expansion-panel>
    <!--Transit Time - By Route Segment-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Transit Time - By Route Segment">
                <div>
                    <span bdsSidebarItemText>Transit Time - By Route Segment</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeRouteSegmentDetail']"
            [routerLinkActive]="['active']"
            title="Detail"
            class="no-text-deco"
        >
            Detail
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeRouteSegmentSummary']"
            [routerLinkActive]="['active']"
            title="Summary"
            class="no-text-deco"
        >
            Summary
        </a>
    </mat-expansion-panel>
    <!--Transit Time - Car Turn Analysis-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Transit Time - Car Turn Analysis">
                <div>
                    <span bdsSidebarItemText>Transit Time - Car Turn Analysis</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeCtaBusiness']"
            [routerLinkActive]="['active']"
            title="By Business Group"
            class="no-text-deco"
        >
            By Business Group
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeCtaFleet']"
            [routerLinkActive]="['active']"
            title="By Fleet"
            class="no-text-deco"
        >
            By Fleet
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/transitTimeCtaFleetBusiness']"
            [routerLinkActive]="['active']"
            title="By Fleet And Business Group"
            class="no-text-deco"
        >
            By Fleet And Business Group
        </a>
    </mat-expansion-panel>
    <!--Transit Time - Trip Cycle-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Transit Time - Trip Cycle">
                <div>
                    <span bdsSidebarItemText>Transit Time - Trip Cycle</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <button bdsSidebarItem [routerLink]="['/transitTimeTripCycleDetail']" title="Detail">
            Detail
        </button>
        <button bdsSidebarItem [routerLink]="['/transitTimeTripCycleSummary']" title="Summary">
            Summary
        </button>
    </mat-expansion-panel>
    <!-- Consignee Car Retention-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Consignee Car Retention">
                <div>
                    <span bdsSidebarItemText>Consignee Car Retention</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <button bdsSidebarItem [routerLink]="['/dayConsigneeCarRetentionDetail']" title="Detail">
            Detail
        </button>
        <button bdsSidebarItem [routerLink]="['/dayConsigneeCarRetentionSummary']" title="Summary">
            Summary
        </button>
    </mat-expansion-panel>
    <!--Days-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Days">
                <div>
                    <span bdsSidebarItemText>Days</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <a
            bdsSidebarItem
            [routerLink]="['/dayInToOrigin']"
            [routerLinkActive]="['active']"
            title="Days in to Origin"
            class="no-text-deco"
        >
            Days in to Origin
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/dayOutToConsignee']"
            [routerLinkActive]="['active']"
            title="Days Out to Consignee"
            class="no-text-deco"
        >
            Days Out to Consignee
        </a>
        <!-- <button bdsSidebarItem [routerLink]="['/shipment-car-situation-current']" title="Origin Idle Days">
            Origin Idle Days
        </button>   -->
    </mat-expansion-panel>
    <!--Fleet Operations - Analysis-->
    <!-- <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Fleet Operations">
                <div>
                    <span bdsSidebarItemText>Fleet Operations</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <button bdsSidebarItem [routerLink]="['/shipment-car-situation-current']" title="Sizing Queries">
            Sizing Queries
        </button>
        <button bdsSidebarItem [routerLink]="['/shipment-car-situation-current']" title="Sizing Detail By Query">
            Sizing Detail By Query
        </button>
        <button bdsSidebarItem [routerLink]="['/shipment-car-situation-current']" title="Sizing Detail">
            Sizing Detail
        </button>
        <button bdsSidebarItem [routerLink]="['/report']" title="Sizing Detail All">
            Sizing Detail All
        </button>
    </mat-expansion-panel> -->
    <!--Fleet Operations - Sizing-->
    <!--Detention-->
    <!-- <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Detention">
                <div>
                    <span bdsSidebarItemText>Detention</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <button bdsSidebarItem [routerLink]="['/shipment-car-situation-current']" title="Proofing &amp; Editing">
            Proofing &amp; Editing
        </button>
        <button bdsSidebarItem [routerLink]="['/shipment-car-situation-current']" title="Printing &amp; Posting">
            Printing &amp; Posting
        </button>
        <button bdsSidebarItem [routerLink]="['/report']" title="Invoice Review">
            Invoice Review
        </button>
        <button bdsSidebarItem [routerLink]="['/shipment-car-situation-current']" title="Summary Analysis">
            Summary Analysis
        </button>
    </mat-expansion-panel>  -->
    <!--Other Reports-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Reports">
                <div>
                    <span bdsSidebarItemText>Reports</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <button bdsSidebarItem [routerLink]="['/badOrderHistory']" title="Bad Order History">
            Bad Order History
        </button>
        <button
            bdsSidebarItem
            [routerLink]="['/shipment-car-situation-current']"
            title="Cars With No Outage Table"
        >
            Cars With No Outage Table
        </button>
        <button
            bdsSidebarItem
            [routerLink]="['/shipment-car-situation-current']"
            title="Current Status Summary - History"
        >
            Current Status Summary - History
        </button>
        <button bdsSidebarItem [routerLink]="['/shipment-car-situation-current']" title="Off Lease">
            Off Lease
        </button>
        <button
            bdsSidebarItem
            [routerLink]="['/shipment-car-situation-current']"
            title="Operational Metrics"
        >
            Operational Metrics
        </button>
        <button
            bdsSidebarItem
            [routerLink]="['/shipment-car-situation-current']"
            title="Trip Comments"
        >
            Trip Comments
        </button>
    </mat-expansion-panel>
    <!--Car Status Summary-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Car Status Summary">
                <div>
                    <span bdsSidebarItemText>Car Status Summary</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <button
            bdsSidebarItem
            [routerLink]="['/shipment-car-situation-current']"
            title="Car Status Summary By Fleet &amp; Business Group"
        >
            Current By Fleet &amp; Business Group
        </button>
        <button
            bdsSidebarItem
            [routerLink]="['/shipment-car-situation-current']"
            title="Car Status Summary By Fleet &amp; Commodity Class"
        >
            Current By Fleet &amp; Commodity Class
        </button>
        <button
            bdsSidebarItem
            [routerLink]="['/shipment-car-situation-current']"
            title="Car Status Summary - Current Destination"
        >
            Current Destination
        </button>
        <button
            bdsSidebarItem
            [routerLink]="['/shipment-car-situation-current']"
            title="Car Status Summary - Current Origin"
        >
            Current Origin
        </button>
        <button
            bdsSidebarItem
            [routerLink]="['/shipment-car-situation-current']"
            title="Current Status Summary - History"
        >
            History
        </button>
        <button
            bdsSidebarItem
            [routerLink]="['/shipment-car-situation-current']"
            title="Car Status Summary - Origin History"
        >
            Origin History
        </button>
    </mat-expansion-panel>
    <!--Shipment-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Shipment">
                <div>
                    <span bdsSidebarItemText>Shipment</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <a
            bdsSidebarItem
            [routerLink]="['/shipmentCarSituationAll']"
            [routerLinkActive]="['active']"
            title="Car Situation All"
            class="no-text-deco"
        >
            Car Situation All
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/shipmentCarSituationCurrent']"
            [routerLinkActive]="['active']"
            title="Car Situation Current"
            class="no-text-deco"
        >
            Car Situation Current
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/shipmentCarSituationCurrentExtended']"
            [routerLinkActive]="['active']"
            title="Car Situation Current - Extended"
            class="no-text-deco"
        >
            Car Situation Current - Extended
        </a>
    </mat-expansion-panel>
    <!--Completed Shipments-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Completed Shipments">
                <div>
                    <span bdsSidebarItemText>Completed Shipments</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <a
            bdsSidebarItem
            [routerLink]="['/completedShipmentsAll']"
            [routerLinkActive]="['active']"
            title="All"
            class="no-text-deco"
        >
            All
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/completedShipmentsNoDiversions']"
            [routerLinkActive]="['active']"
            title="No Diversions"
            class="no-text-deco"
        >
            No Diversions
        </a>
    </mat-expansion-panel>
    <!--Shipment Outbound and At Customer-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Reports">
                <div>
                    <span bdsSidebarItemText>Shipment Outbound &amp; At Customer</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <a
            bdsSidebarItem
            [routerLink]="['/outboundByCustomer']"
            [routerLinkActive]="['active']"
            title="By Customer"
            class="no-text-deco"
        >
            By Customer
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/outboundByCustomerCsr']"
			[routerLinkActive]="['active']"
            title="By Customer &amp; CSR"
			class="no-text-deco"
        >
            By Customer &amp; CSR
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/outboundByCustomerProduct']"
			[routerLinkActive]="['active']"
            title="By Customer &amp; Product"
			class="no-text-deco"
        >
            By Customer &amp; Product
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/outboundByCustomerProductVolume']"
			[routerLinkActive]="['active']"
            title="By Customer, Product &amp; Volume"
			class="no-text-deco"
        >
            By Customer, Product &amp; Volume
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/outboundByFleet']"
            [routerLinkActive]="['active']"
            title="By Fleet"
            class="no-text-deco"
        >
            By Fleet
        </a>
    </mat-expansion-panel>
    <!--Shipment history-->
    <mat-expansion-panel
        bdsSidebarMenu
        [hideToggle]="true"
        class="bg-transparent"
        style="color: #ced4da"
    >
        <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
            <div bdsSidebarItem title="Shipment History">
                <div>
                    <span bdsSidebarItemText>Shipment History</span>
                    <fa-icon
                        [icon]="caretRight"
                        transform="rotate-45 down-4 right-9 shrink-3"
                    ></fa-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <a
            bdsSidebarItem
            [routerLink]="['/shipmentHistoryDetail']"
            [routerLinkActive]="['active']"
            title="Detail"
            class="no-text-deco"
        >
            Detail
        </a>
        <a
            bdsSidebarItem
            [routerLink]="['/shipmentHistorySummary']"
            [routerLinkActive]="['active']"
            title="Summary"
            class="no-text-deco"
        >
            Summary
        </a>
    </mat-expansion-panel>
</mat-expansion-panel>
<!-- </ng-container> -->
<a
    bdsSidebarItem
    [routerLink]="['/hotlist']"
    [routerLinkActive]="['active']"
    title="Hot List"
    class="no-text-deco"
>
    <fa-icon
        bdsSidebarItemIcon
        [fixedWidth]="true"
        [icon]="iconHotListGeneral"
        class="duoIconHotListGeneral"
    ></fa-icon>
    <span bdsSidebarItemText>Hot List</span>
</a>
<mat-divider bdsSidebarItem></mat-divider>
<a
    [href]="referenceAppPathProvider.Commodities() | async"
    target="_blank"
    style="text-decoration: none"
>
    <a bdsSidebarItem title="Commodities" class="no-text-deco">
        <fa-icon
            bdsSidebarItemIcon
            [fixedWidth]="true"
            [icon]="iconCommodities"
            class="duoIconCommodities"
        ></fa-icon>
        <span bdsSidebarItemText>Commodities</span>
    </a>
</a>
<a
    [href]="referenceAppPathProvider.Customers() | async"
    target="_blank"
    style="text-decoration: none"
>
    <a bdsSidebarItem title="Consignees" class="no-text-deco">
        <fa-icon
            bdsSidebarItemIcon
            [fixedWidth]="true"
            [icon]="iconCustomers"
            class="duoIconCustomers"
        ></fa-icon>
        <span bdsSidebarItemText>Consignees</span>
    </a>
</a>
<a
    [href]="equipmentAppPathProvider.Equipment() | async"
    target="_blank"
    style="text-decoration: none"
>
    <a bdsSidebarItem title="Equipment" class="no-text-deco">
        <fa-icon
            bdsSidebarItemIcon
            [fixedWidth]="true"
            [icon]="iconEquipment"
            class="duoIconEquipment"
        ></fa-icon>
        <span bdsSidebarItemText>Equipment</span>
    </a>
</a>
<mat-expansion-panel
    bdsSidebarMenu
    [hideToggle]="true"
    class="bg-transparent"
    style="color: #ced4da"
>
    <mat-expansion-panel-header expandedHeight="100%" collapsedHeight="100%">
        <div bdsSidebarItem title="Jeopardized Shipments">
            <div>
                <fa-icon
                    bdsSidebarItemIcon
                    [fixedWidth]="true"
                    [icon]="iconRoutes"
                    class="duoIconRoutes"
                ></fa-icon>
                <span bdsSidebarItemText>Routes</span>
                <fa-icon
                    [icon]="caretRight"
                    transform="rotate-45 down-4 right-9 shrink-3"
                ></fa-icon>
            </div>
        </div>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <a
        bdsSidebarItem
        (click)="onFastracLinkClicked(defaultRouteFastrac)"
        [routerLink]="['/routes']"
        [routerLinkActive]="['active']"
        title="Routes"
        class="no-text-deco"
    >
        Route Codes
    </a>
    <a
        bdsSidebarItem
        [routerLink]="['/segment-miles']"
        [routerLinkActive]="['active']"
        title="Segment Miles"
        class="no-text-deco"
    >
        Segment Miles
    </a>
    <a
        bdsSidebarItem
        [routerLink]="['/path-codes']"
        [routerLinkActive]="['active']"
        title="Path Codes"
        class="no-text-deco"
    >
        Path Codes
    </a>

    <a
        bdsSidebarItem
        [routerLink]="['/preferred-route']"
        [routerLinkActive]="['active']"
        title="Preferred Routes"
        class="no-text-deco"
    >
        Preferred Routes
    </a>
</mat-expansion-panel>

<a bdsSidebarItem [routerLink]="['/origins']" title="Origins" class="no-text-deco">
    <fa-icon
        bdsSidebarItemIcon
        [fixedWidth]="true"
        [icon]="iconOrigins"
        class="duoIconOrigins"
    ></fa-icon>
    <span bdsSidebarItemText>Origins</span>
</a>
<a
    bdsSidebarItem
    [routerLink]="['/railroads']"
    [routerLinkActive]="['active']"
    title="Railroad Facts"
    class="no-text-deco"
>
    <fa-icon
        bdsSidebarItemIcon
        [fixedWidth]="true"
        [icon]="iconInfoSquare"
        class="duoIconRailroad"
    ></fa-icon>
    <span bdsSidebarItemText>Railroad Facts</span>
</a>
<a
    *ngIf="false"
    bdsSidebarItem
    (click)="onItemClicked('/admin')"
    title="Admin"
    class="no-text-deco"
>
    <fa-icon
        bdsSidebarItemIcon
        [fixedWidth]="true"
        [icon]="iconAdmin"
        class="duoIconOrigins"
    ></fa-icon>
    <span bdsSidebarItemText>Admin</span>
</a>

<mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="[]">item.displayText</button>
    <button mat-menu-item [matMenuTriggerFor]="secondaryMenu">item.displayText</button>
    <button mat-menu-item>item.displayText</button>
    <button mat-menu-item>item.displayText</button>
    <button mat-menu-item>item.displayText</button>
</mat-menu>
<mat-menu #secondaryMenu="matMenu">
    <button mat-menu-item>item.displayText</button>
    <button mat-menu-item>item.displayText</button>
    <button mat-menu-item>item.displayText</button>
    <button mat-menu-item>item.displayText</button>
    <button mat-menu-item>item.displayText</button>
</mat-menu>

<mat-menu #fastracFavMenu="matMenu">
    <ng-template matMenuContent let-fastracId="fastracId">
        <button mat-menu-item>Settings</button>
        <button mat-menu-item>Log off {{ fastracId }}</button>
    </ng-template>
</mat-menu>

<mat-menu #fastracManageMenu="matMenu">
    <button mat-menu-item>Set Default FASTRACs</button>
    <button mat-menu-item>Reorder Favorites</button>
</mat-menu>
