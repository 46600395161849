import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import ODataStore from 'devextreme/data/odata/store';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export abstract class RtRoutePathCodeServiceApiServiceOptions {
    apiUrl = `api/routepathcode/`;
    odataUrl = `odata/routepathcode`;
}

export interface RtRoutePathCode {
    contractNo: string;
    deliveryRoad: string;
    iIsTruckDeliveryLoadEmpty: string;
    originRoad: string;
    pathCode: string;
    pathDescription: string;
}

@Injectable({
    providedIn: 'root',
})
export class BdsRoutePathCodeService {
    constructor(
        private options: RtRoutePathCodeServiceApiServiceOptions,
        private http: HttpClient,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    create(model: RtRoutePathCode) {
        return this.http
            .post(this.options.apiUrl, model)
            .pipe(catchError((err) => this.apiErrorHandler.handleError(err)));
    }

    getByRouteDscr(
        routeDscr: string,
        departingCarrier: string = null,
        deliveringCarrier: string = null,
    ): Observable<RtRoutePathCode> {
        const pathFilter = `${nameof<RtRoutePathCode>('pathDescription')} eq '${routeDscr}'`;
        const departingCarrierFilter = departingCarrier
            ? `originRoad eq '${departingCarrier}'`
            : `(originRoad eq null or originRoad eq '')`;
        const deliveringCarrierFilter = deliveringCarrier
            ? `deliveryRoad eq '${deliveringCarrier}'`
            : `(deliveryRoad eq null or deliveryRoad eq '')`;

        return this.http
            .get<{ value: RtRoutePathCode[] }>(
                `${this.options.odataUrl}?$filter=${pathFilter} and ${departingCarrierFilter} and ${deliveringCarrierFilter} &$orderBy=pathCode&$top=1`,
            )
            .pipe(
                map((res) => {
                    if (res.value && res.value.length > 0) {
                        return res.value[0];
                    }
                    return null;
                }),
            );
    }

    getODataStore(): ODataStore {
        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: 'ormId',
            deserializeDates: false,
        });
    }
}
