<div [formGroup]="originForm" class="origin-details">
    <div class="row">
        <div class="col-6">
            <dx-lookup
                [stylingMode]="dxMatStyle"
                [ngClass]="{
                    'bds-mat-dense-form-field': dense,
                    'dx-mat-form-field-floatLabel-always': matFloatLabel == 'always'
                }"
                class="bds-dx-custom-material flex-fill"
                name="splc"
                formControlName="splc"
                [dataSource]="splcSource"
                [valueExpr]="'splc'"
                [displayExpr]="splcDisplayExpr"
                [searchEnabled]="true"
                searchTimeout="200"
                itemTemplate="item"
                fieldTemplate="field"
                [showClearButton]="true"
                (onSelectionChanged)="onSplcChanged($event)"
                label="Origin City, Origin State (SPLC)"
            >
                <div *dxTemplate="let data of 'field'">
                    <div class="custom-icon" *ngIf="data">
                        {{ data.erpcCity }}, {{ data.erpcState }} ({{ data.splc }})
                    </div>
                </div>
                <div *dxTemplate="let data of 'item'">
                    <div class="custom-icon">
                        {{ data.erpcCity }}, {{ data.erpcState }} ({{ data.splc }})
                    </div>
                </div>
            </dx-lookup>
            <mat-error *ngIf="originForm.controls.splc.errors">
                {{ getError(originForm.controls.splc) }}
            </mat-error>
        </div>
        <div class="col-4">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Origin Code</mat-label>
                <input
                    matInput
                    bdsShoutcase
                    formControlName="originCode"
                    maxLength="5"
                    [readonly]="originCodeFieldDisabled"
                />
                <mat-error *ngIf="originForm.controls.originCode?.errors">
                    <ng-container *ngIf="originForm.controls.originCode.errors?.originCodeExists">
                        Unique Origin Code required
                    </ng-container>
                    <ng-container>
                        {{ getError(originForm.controls.originCode) }}
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                [ngClass]="{ 'bds-mat-dense-form-field': dense }"
                appearance="fill"
            >
                <mat-label>Master Origin</mat-label>
                <mat-select formControlName="masterOriginYorn">
                    <mat-option></mat-option>
                    <mat-option value="Y">YES</mat-option>
                    <mat-option value="N">NO</mat-option>
                </mat-select>
                <mat-error *ngIf="originForm.controls.masterOriginYorn.errors">
                    {{ getError(originForm.controls.masterOriginYorn) }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>

