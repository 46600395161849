import { Component, OnInit } from '@angular/core';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
    selector: 'lib-transit-time-trip-cycle-summary',
    templateUrl: './transit-time-trip-cycle-summary.component.html',
    styleUrl: './transit-time-trip-cycle-summary.component.css',
})
export class TransitTimeTripCycleSummaryComponent implements OnInit {
    public gridId = 129;
    public gridSource: any;
    public displayReport: boolean = false;
    public controlNames: string[] = ['Start Date', 'End Date'];
    displayedColumns: string[] = [];

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit(): void {
        this.pageService.setHeader('Transit Time - Trip Cycle Summary Report');
    }

    onGetReportClick(event: any) {
        this.displayReport = true;
        this.gridSource = this.reportService.CreateAspNetDatastore(
            ReportConstants.ReportDataApiCall,
            this.gridId.toString(),
            event.startDate,
            event.endDate,
        );
    }

    onCancelClick() {
        this.displayReport = false;
    }
}
