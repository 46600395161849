import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Optional,
    Output,
    Self,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BdsShipmentTypeService } from '@bds/data-access';
import { RtShipmentType } from '@bds/railtrac-models';
import DataSource from 'devextreme/data/data_source';
import { BdsAbstractControlValueAccessor } from '../../abstract-components/bds-abstract-control-value.accessor';
import { dxMatStyle } from '../../helpers/dx-mat-style';

@Component({
    selector: 'bds-shipment-type-select',
    template: `
        <dx-select-box
            [stylingMode]="style"
            [class.bds-mat-dense-form-field]="dense"
            [class.dx-mat-form-field-floatLabel-always]="matFloatLabel == 'always'"
            class="bds-dx-custom-material flex-fill"
            [dataSource]="dataSource"
            valueExpr="shipmentType"
            searchExpr="shipTypeDesc"
            [searchEnabled]="true"
            label="Shipment Type"
            [(ngModel)]="value"
            fieldTemplate="inputField"
            (onItemClick)="emitSelectionChange()"
        >
            <div *dxTemplate="let data of 'item'">
                {{ getDisplayValue(data) }}
            </div>
            <div *dxTemplate="let data of 'inputField'">
                <dx-text-box
                    style="display:inline-block"
                    width="100%"
                    label="Shipment Type"
                    [value]="getDisplayValue(data)"
                ></dx-text-box>
            </div>
        </dx-select-box>
    `,
})
export class BdsShipmentTypeSelectComponent
    extends BdsAbstractControlValueAccessor<string>
    implements OnInit
{
    dataSource: DataSource;
    @Input() dense = true;
    @Input() matFloatLabel = 'always';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() pageSize = 10;
    @Output() selectionChange = new EventEmitter();

    constructor(
        private bdsShipmentTypeService: BdsShipmentTypeService,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(ngControl);
    }

    get style(): string {
        return dxMatStyle(this.matStyle);
    }

    @Input() displayValue = function (data: RtShipmentType) {
        return data ? `${data.shipmentType} - ${data.shipTypeDesc}` : '';
    };

    getDisplayValue(data: RtShipmentType) {
        return this.displayValue(data);
    }

    emitSelectionChange() {
        this.selectionChange.emit();
    }

    ngOnInit() {
        this.dataSource = new DataSource({
            store: this.bdsShipmentTypeService.getODataStore(),
            paginate: true,
            pageSize: this.pageSize,
        });
    }
}
