import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import { ArrayFunctionsService } from '@bds/helpers';
import { RtClm } from '@bds/railtrac-models';
import { RailtracClmService } from '../railtrac-clm/railtrac-clm.service';

@Component({
    selector: 'rt-trip-criteria',
    templateUrl: './rt-trip-criteria.component.html',
    styleUrls: ['./rt-trip-criteria.component.scss'],
})
export class RtTripCriteriaComponent implements OnInit, OnChanges {
    latestClm: RtClm;

    @Input() customerNumber: string;
    @Input() destinationCity: string;
    @Input() destinationState: string;
    @Input() originCity: string;
    @Input() originState: string;
    @Input() tripId: number;

    constructor(
        private clmService: RailtracClmService,
        private arrayService: ArrayFunctionsService,
    ) {}

    ngOnInit() {
        if (this.tripId) {
            this.getLatestClmForTrip();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tripId && !changes.tripId.firstChange && this.tripId) {
            this.getLatestClmForTrip();
        }
    }

    getLatestClmForTrip() {
        this.clmService
            .getClmsForTrip(this.tripId)
            .pipe(take(1))
            .subscribe((clms) => {
                this.latestClm = this.arrayService.sortAlphabetically(
                    clms,
                    'clmDateTime',
                    false,
                )[0];
            });
    }
}
