import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ClmFilterFormService } from './clm-filter-form.service';

@Component({
    selector: 'clm-filter-dialog',
    templateUrl: './clm-filter-dialog.component.html',
    styleUrls: ['./clm-filter-dialog.component.css'],
})
export class ClmFilterDialogComponent implements OnInit {
    filterForm: UntypedFormGroup;

    constructor(
        public dialogRef: MatDialogRef<ClmFilterDialogComponent>,
        public formService: ClmFilterFormService,
    ) {}

    ngOnInit(): void {
        this.filterForm = this.formService.form;
        this.filterForm.reset();
    }

    onCancelClick(): void {
        this.dialogRef.close(null);
    }

    onSearchClick(): void {
        if (this.filterForm.valid) {
            this.dialogRef.close(this.filterForm.getRawValue());
        }
    }
}
