import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata } from '@bds/core';
import { RtArrAtDest } from './rt-arr-at-dest';

export class RtCustomerFact {
    constructor(
        public customerNo?: string,
        public customerName?: string,
        public customerAddress1?: string,
        public customerAddress2?: string,
        public customerCity?: string,
        public customerState?: string,
        public customerZip?: string,
        public customerTelephone?: string,
        public customerFax?: string,
        public comments?: string,
        public customerRequiredHrs?: number,
        public shopYorN?: string,
        public freightPmtCode?: string,
        public bolComments?: string,
        public customerType?: string,
        public customerSplc?: string,
        public deliveryRoad?: string,
        public freightBillToNo?: string,
        public deliveryStatus?: string,
        public customerFname?: string,
        public customerLname?: string,
        public customerEmail?: string,
        public customerName2?: string,
        public customerAddress3?: string,
        public canadianErPlanNo?: string,
        public canadianErPlanPhoneNo?: string,
        public customerSplc9?: string,
        public railStation?: string,
        public railSubStation?: string,
        public railSiding?: string,
        public activeStatus?: string,
        public careOfName?: string,
        public geoSplc?: string,
        public geoCity?: string,
        public monday?: boolean,
        public tuesday?: boolean,
        public wednesday?: boolean,
        public thursday?: boolean,
        public friday?: boolean,
        public saturday?: boolean,
        public sunday?: boolean,
        public careOfName2?: string,
        public soldToAddress1?: string,
        public soldToAddress2?: string,
        public soldToAddress3?: string,
        public soldToCity?: string,
        public soldToState?: string,
        public soldToCountryCode?: string,
        public soldToZip?: string,
        public contactExt?: string,
        public ediIdQualifier?: string,
        public ediIdCode?: string,
        public ediContactCode?: string,
        public carWeightLimit?: number,
        public carWeightLimitUm?: string,
        public customerCountryCode?: string,
        public tcmRouteAbbr?: string,
        public tierDet?: string,
        public transitMode?: string,
        public destinations?: RtArrAtDest,
    ) {}
    from(trip: RtCustomerFact): RtCustomerFact {
        //Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        Object.assign(this, trip);
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtCustomerFact>('customerNo'), server: 'customerNo' },
    { client: nameof<RtCustomerFact>('customerName'), server: 'customerName' },
    { client: nameof<RtCustomerFact>('customerAddress1'), server: 'customerAddress1' },
    { client: nameof<RtCustomerFact>('customerAddress2'), server: 'customerAddress2' },
    { client: nameof<RtCustomerFact>('customerCity'), server: 'customerCity' },
    { client: nameof<RtCustomerFact>('customerState'), server: 'customerState' },
    { client: nameof<RtCustomerFact>('customerZip'), server: 'customerZip' },
    { client: nameof<RtCustomerFact>('customerTelephone'), server: 'customerTelephone' },
    { client: nameof<RtCustomerFact>('customerFax'), server: 'customerFax' },
    { client: nameof<RtCustomerFact>('comments'), server: 'comments' },
    { client: nameof<RtCustomerFact>('customerRequiredHrs'), server: 'customerRequiredHrs' },
    { client: nameof<RtCustomerFact>('shopYorN'), server: 'shopYorN' },
    { client: nameof<RtCustomerFact>('freightPmtCode'), server: 'freightPmtCode' },
    { client: nameof<RtCustomerFact>('bolComments'), server: 'bolComments' },
    { client: nameof<RtCustomerFact>('customerType'), server: 'customerType' },
    { client: nameof<RtCustomerFact>('customerSplc'), server: 'customerSplc' },
    { client: nameof<RtCustomerFact>('deliveryRoad'), server: 'deliveryRoad' },
    { client: nameof<RtCustomerFact>('freightBillToNo'), server: 'freightBillToNo' },
    { client: nameof<RtCustomerFact>('deliveryStatus'), server: 'deliveryStatus' },
    { client: nameof<RtCustomerFact>('customerFname'), server: 'customerFname' },
    { client: nameof<RtCustomerFact>('customerLname'), server: 'customerLname' },
    { client: nameof<RtCustomerFact>('customerEmail'), server: 'customerEmail' },
    { client: nameof<RtCustomerFact>('customerName2'), server: 'customerName2' },
    { client: nameof<RtCustomerFact>('customerAddress3'), server: 'customerAddress3' },
    { client: nameof<RtCustomerFact>('canadianErPlanNo'), server: 'canadianErPlanNo' },
    { client: nameof<RtCustomerFact>('canadianErPlanPhoneNo'), server: 'canadianErPlanPhoneNo' },
    { client: nameof<RtCustomerFact>('customerSplc9'), server: 'customerSplc9' },
    { client: nameof<RtCustomerFact>('railStation'), server: 'railStation' },
    { client: nameof<RtCustomerFact>('railSubStation'), server: 'railSubStation' },
    { client: nameof<RtCustomerFact>('railSiding'), server: 'railSiding' },
    { client: nameof<RtCustomerFact>('activeStatus'), server: 'activeStatus' },
    { client: nameof<RtCustomerFact>('careOfName'), server: 'careOfName' },
    { client: nameof<RtCustomerFact>('geoSplc'), server: 'geoSplc' },
    { client: nameof<RtCustomerFact>('geoCity'), server: 'geoCity' },
    { client: nameof<RtCustomerFact>('monday'), server: 'monday' },
    { client: nameof<RtCustomerFact>('tuesday'), server: 'tuesday' },
    { client: nameof<RtCustomerFact>('wednesday'), server: 'wednesday' },
    { client: nameof<RtCustomerFact>('thursday'), server: 'thursday' },
    { client: nameof<RtCustomerFact>('friday'), server: 'friday' },
    { client: nameof<RtCustomerFact>('saturday'), server: 'saturday' },
    { client: nameof<RtCustomerFact>('sunday'), server: 'sunday' },
    { client: nameof<RtCustomerFact>('careOfName2'), server: 'careOfName2' },
    { client: nameof<RtCustomerFact>('soldToAddress1'), server: 'soldToAddress1' },
    { client: nameof<RtCustomerFact>('soldToAddress2'), server: 'soldToAddress2' },
    { client: nameof<RtCustomerFact>('soldToAddress3'), server: 'soldToAddress3' },
    { client: nameof<RtCustomerFact>('soldToCity'), server: 'soldToCity' },
    { client: nameof<RtCustomerFact>('soldToState'), server: 'soldToState' },
    { client: nameof<RtCustomerFact>('soldToZip'), server: 'soldToZip' },
    { client: nameof<RtCustomerFact>('soldToCountryCode'), server: 'soldToCountryCode' },
    { client: nameof<RtCustomerFact>('contactExt'), server: 'contactExt' },
    { client: nameof<RtCustomerFact>('ediIdQualifier'), server: 'ediIdQualifier' },
    { client: nameof<RtCustomerFact>('ediIdCode'), server: 'ediIdCode' },
    { client: nameof<RtCustomerFact>('ediContactCode'), server: 'ediContactCode' },
    { client: nameof<RtCustomerFact>('carWeightLimit'), server: 'carWeightLimit' },
    { client: nameof<RtCustomerFact>('carWeightLimitUm'), server: 'carWeightLimitUm' },
    { client: nameof<RtCustomerFact>('customerCountryCode'), server: 'customerCountryCode' },
    { client: nameof<RtCustomerFact>('tcmRouteAbbr'), server: 'tcmRouteAbbr' },
    { client: nameof<RtCustomerFact>('tierDet'), server: 'tierDet' },
    { client: nameof<RtCustomerFact>('transitMode'), server: 'transitModeService' },
    { client: nameof<RtCustomerFact>('destinations'), server: 'destinations' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCustomerFactAdapter extends TwoWayAdapter<RtCustomerFact> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

export const careOfPartyAddressValidator: ValidatorFn = (
    control: AbstractControl,
): ValidationErrors | null => {
    const careOfName = control.get('careOfName');
    const soldToCity = control.get('soldToCity');
    const soldToState = control.get('soldToState');

    const missingAddress =
        careOfName &&
        soldToCity &&
        soldToState &&
        careOfName.value &&
        (!soldToCity.value || !soldToState.value);

    return missingAddress ? { soldToAddressRequired: true } : null;
};

export const careOfPartyValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const careOfName = control.get('careOfName');
    const careOfName2 = control.get('careOfName2');

    return careOfName && careOfName2 && careOfName2.value && !careOfName.value
        ? { careOfNameRequired: true }
        : null;
};

export const canErPhoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const canErPlan = control.get('canadianErPlanNo');
    const canErPhone = control.get('canadianErPlanPhoneNo');

    return canErPlan && canErPhone && canErPlan.value && !canErPhone.value
        ? { canErPhoneRequired: true }
        : null;
};

export const canErPlanValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const canErPlan = control.get('canadianErPlanNo');
    const canErPhone = control.get('canadianErPlanPhoneNo');

    return canErPlan && canErPhone && canErPhone.value && !canErPlan.value
        ? { canErPlanRequired: true }
        : null;
};

export const carWeightValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const carWeight = control.get('carWeightLimit');
    const carWeightUm = control.get('carWeightLimitUm');

    return carWeight && carWeightUm && carWeightUm.value && !carWeight.value
        ? { carWeightRequired: true }
        : null;
};

export const carWeightUmValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const carWeight = control.get('carWeightLimit');
    const carWeightUm = control.get('carWeightLimitUm');

    return carWeight && carWeightUm && carWeight.value && !carWeightUm.value
        ? { carWeightUmRequired: true }
        : null;
};

export const contactTypeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const contactType = control.get('ediContactCode');
    const contactPhone = control.get('customerTelephone');
    const contactFName = control.get('customerFname');
    const contactLName = control.get('customerLname');

    const missingInfo =
        contactType &&
        contactPhone &&
        contactLName &&
        contactFName &&
        contactType.value &&
        (!contactPhone.value || !contactLName.value || !contactFName.value);

    return missingInfo ? { contactInfoRequired: true } : null;
};

export const customerAddressValidator: ValidatorFn = (
    control: AbstractControl,
): ValidationErrors | null => {
    const address1 = control.get('customerAddress1');
    const address2 = control.get('customerAddress2');
    const address3 = control.get('customerAddress3');

    const address2Disallowed = address1 && address2 && address2.value && !address1.value;
    const address3Disallowed = address2 && address3 && address3.value && !address2.value;

    return address2Disallowed || address3Disallowed ? { addressDisallowed: true } : null;
};

export const ediCodeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const ediType = control.get('ediIdQualifier');
    const ediCode = control.get('ediIdCode');

    return ediType && ediCode && ediType.value && !ediCode.value ? { ediCodeRequired: true } : null;
};

export const ediTypeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const ediType = control.get('ediIdQualifier');
    const ediCode = control.get('ediIdCode');

    return ediType && ediCode && ediCode.value && !ediType.value ? { ediTypeRequired: true } : null;
};

export const soldAddressValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const address1 = control.get('soldToAddress1');
    const address2 = control.get('soldToAddress2');
    const address3 = control.get('soldToAddress3');

    const address2Disallowed = address1 && address2 && address2.value && !address1.value;
    const address3Disallowed = address2 && address3 && address3.value && !address2.value;

    return address2Disallowed || address3Disallowed ? { addressSoldDisallowed: true } : null;
};

@Injectable({
    providedIn: 'root',
})
export class RtCustomerFactMetadata implements FormGroupDescription {
    constructor() {}

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<RtCustomerFact>('customerNo'),
            new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(13),
                Validators.pattern('^[a-zA-Z0-9]*$'),
            ]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerName'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(60)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerAddress1'),
            new UntypedFormControl('', [Validators.maxLength(55)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerAddress2'),
            new UntypedFormControl('', [Validators.maxLength(55)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerCity'),
            new UntypedFormControl('', [Validators.maxLength(25)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerState'),
            new UntypedFormControl('', [Validators.maxLength(2)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerZip'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(nameof<RtCustomerFact>('customerTelephone'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerFact>('customerFax'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCustomerFact>('comments'),
            new UntypedFormControl('', [Validators.maxLength(255)]),
        );
        formGroup.addControl(nameof<RtCustomerFact>('customerRequiredHrs'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerFact>('shopYorN'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCustomerFact>('freightPmtCode'),
            new UntypedFormControl('', [Validators.maxLength(1)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('bolComments'),
            new UntypedFormControl('', [Validators.maxLength(100)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerType'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(1)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerSplc'),
            new UntypedFormControl('', [Validators.maxLength(6)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('deliveryRoad'),
            new UntypedFormControl('', [Validators.maxLength(4)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('freightBillToNo'),
            new UntypedFormControl('', [Validators.maxLength(13)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('deliveryStatus'),
            new UntypedFormControl('', [Validators.maxLength(1)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerFname'),
            new UntypedFormControl('', [Validators.maxLength(20)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerLname'),
            new UntypedFormControl('', [Validators.maxLength(20)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerEmail'),
            new UntypedFormControl('', [Validators.maxLength(50)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerName2'),
            new UntypedFormControl('', [Validators.maxLength(60)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerAddress3'),
            new UntypedFormControl('', [Validators.maxLength(55)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('canadianErPlanNo'),
            new UntypedFormControl('', [Validators.maxLength(12)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('canadianErPlanPhoneNo'),
            new UntypedFormControl('', [Validators.maxLength(25)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerSplc9'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('railStation'),
            new UntypedFormControl('', [Validators.maxLength(30)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('railSubStation'),
            new UntypedFormControl('', [Validators.maxLength(30)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('railSiding'),
            new UntypedFormControl('', [Validators.maxLength(30)]),
        );
        formGroup.addControl(nameof<RtCustomerFact>('activeStatus'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCustomerFact>('careOfName'),
            new UntypedFormControl('', [Validators.maxLength(60)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('geoSplc'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('geoCity'),
            new UntypedFormControl('', [Validators.maxLength(20)]),
        );
        formGroup.addControl(nameof<RtCustomerFact>('monday'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerFact>('tuesday'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerFact>('wednesday'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerFact>('thursday'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerFact>('friday'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerFact>('saturday'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerFact>('sunday'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCustomerFact>('careOfName2'),
            new UntypedFormControl('', [Validators.maxLength(60)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('soldToAddress1'),
            new UntypedFormControl('', [Validators.maxLength(55)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('soldToAddress2'),
            new UntypedFormControl('', [Validators.maxLength(55)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('soldToAddress3'),
            new UntypedFormControl('', [Validators.maxLength(55)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('soldToCity'),
            new UntypedFormControl('', [Validators.maxLength(20)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('soldToState'),
            new UntypedFormControl('', [Validators.maxLength(2)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('soldToZip'),
            new UntypedFormControl('', [Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('contactExt'),
            new UntypedFormControl('', [Validators.maxLength(60)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('ediIdQualifier'),
            new UntypedFormControl('', [Validators.maxLength(2)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('ediIdCode'),
            new UntypedFormControl('', [Validators.maxLength(80)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('ediContactCode'),
            new UntypedFormControl('', [Validators.maxLength(2)]),
        );
        formGroup.addControl(nameof<RtCustomerFact>('carWeightLimit'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCustomerFact>('carWeightLimitUm'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('customerCountryCode'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(nameof<RtCustomerFact>('tcmRouteAbbr'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCustomerFact>('tierDet'),
            new UntypedFormControl('', [Validators.maxLength(1)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('soldToCountryCode'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(
            nameof<RtCustomerFact>('transitMode'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(5)]),
        );

        formGroup.setValidators([
            canErPhoneValidator,
            canErPlanValidator,
            careOfPartyValidator,
            careOfPartyAddressValidator,
            carWeightValidator,
            carWeightUmValidator,
            contactTypeValidator,
            customerAddressValidator,
            ediCodeValidator,
            ediTypeValidator,
            soldAddressValidator,
        ]);

        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
