import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BdsDialogConfirmComponent, BdsDialogConfirmModel } from '@bds/components';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import { filter, switchMap, tap } from 'rxjs/operators';
import {
    BdsFormlyDialogComponent,
    FormlyDialogData,
} from '../../formly-components/bds-formly-dialog/bds-formly-dialog.component';
import { BDSFormlyForms } from '../../services/bds-formly.service';
import { BdsRouteDetailService, RtRouteDetail } from '../../services/bds-route-detail.service';

@Component({
    selector: 'bds-rt-route-details',
    templateUrl: './bds-route-details.component.html',
    styleUrls: ['./bds-route-details.component.css'],
})
export class BdsRouteDetailsComponent {
    dataSource: DataSource;
    displayColumns: string[];
    @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
    @Input() readOnly = false;
    selectedRowKeys: Array<number> = [];
    test: string;

    constructor(
        private routeDetailService: BdsRouteDetailService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
    ) {
        this.dataSource = new DataSource({
            store: this.routeDetailService.getODataStore(),
            paginate: true,
            pageSize: 10,
        });
    }

    private _routeCode: string;

    get routeCode() {
        return this._routeCode;
    }

    @Input() set routeCode(value: string) {
        this.dataSource.filter(['routeCode', '=', value]);
        this._routeCode = value;
    }

    add() {
        const dialog = this.dialog.open(BdsFormlyDialogComponent, {
            data: <FormlyDialogData<any>>{
                title: 'Add Route Details',
                actionBtnText: 'Save',
                cancelBtnText: 'Cancel',
                model: new RtRouteDetail({ routeCode: this._routeCode }),
                action: this.save,
                formName: BDSFormlyForms.BdsRouteDetailsAdd,
            },
        });
        this.closeDialog(dialog, 'Saved Record');
    }

    delete(): void {
        if (this.grid.selectedRowKeys.length != 1) {
            return;
        }
        const dialog = this.dialog.open(BdsDialogConfirmComponent, {
            data: <BdsDialogConfirmModel>{
                title: 'Delete Route Detail',
                content: 'Are you sure you would like to delete the selected route detail?',
                actionText: 'Yes',
                dismissiveText: 'No',
            },
        });

        dialog
            .afterClosed()
            .pipe(
                filter((response) => response),
                switchMap(() => this.routeDetailService.delete(this.grid.selectedRowKeys[0])),
                tap(() => {
                    this.grid.instance.refresh();
                    this.snackbar.open('Deleted Record', 'Ok', { duration: 3000 });
                }),
            )
            .subscribe();
    }

    edit() {
        if (this.grid.instance.getSelectedRowsData().length > 0) {
            const selection = this.grid.instance.getSelectedRowsData()[0];
            const dialog = this.dialog.open(BdsFormlyDialogComponent, {
                data: <FormlyDialogData<any>>{
                    title: 'Edit Route Details',
                    actionBtnText: 'Save',
                    cancelBtnText: 'Cancel',
                    model: new RtRouteDetail(selection),
                    action: this.update,
                    formName: BDSFormlyForms.BdsRouteDetailsAdd,
                },
            });
            this.closeDialog(dialog, 'Updated Record');
        }
    }

    private closeDialog(dialog: MatDialogRef<BdsFormlyDialogComponent, any>, message: string) {
        dialog
            .afterClosed()
            .pipe(
                filter((response) => response),
                tap(() => {
                    this.grid.instance.refresh();
                    this.snackbar.open(message, 'Ok', { duration: 3000 });
                }),
            )
            .subscribe();
    }

    private save = (model: any) => this.routeDetailService.add(model);

    private update = (model: any) => this.routeDetailService.edit(model);
}
