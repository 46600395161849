import { Component, OnInit } from '@angular/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
  selector: 'lib-rt-shipment-car-situation-current-report',
  templateUrl: './rt-shipment-car-situation-current-report.component.html',
  styleUrls: ['./rt-shipment-car-situation-current-report.component.css']
})
export class RtShipmentCarSituationCurrentReportComponent implements OnInit {

    public gridId = 101;
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Shipment Car Situation Current Report';

    constructor() { }

    ngOnInit(): void {
    }

}
