import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import ODataStore from 'devextreme/data/odata/store';
import { ApiErrorHandlerService } from '@bds/helpers';
import {
    RtRailroadFact,
    RtRailroadFactAdapter,
    RtRailroadFactMetadata,
} from '@bds/railtrac-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RtRailroadService {
    constructor(
        private http: HttpClient,
        public adapter: RtRailroadFactAdapter,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    controllerName = 'Railroad';
    secondControllerName = 'RtRailroad';

    getODataStore(): ODataStore {
        const keyField = 'rrScac';

        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: keyField,
        });
    }

    create(railroad: RtRailroadFact): Observable<Object> {
        const model = this.adapter.toServer(railroad);

        return this.http
            .post(`${this.apibaseUrl}${this.secondControllerName}`, model)
            .pipe(catchError((err) => this.apiErrorHandler.handleError(err)));
    }

    update(scac: string, railroad: RtRailroadFact): Observable<RtRailroadFact> {
        return this.http
            .put<RtRailroadFact>(
                `${this.apibaseUrl}${this.secondControllerName}/${scac}`,
                this.adapter.toServer(railroad),
            )
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    delete(railroadSCAC: number | RtRailroadFact): Observable<Object> {
        return this.http
            .delete<RtRailroadFact>(
                `${this.apibaseUrl}${this.secondControllerName}/${railroadSCAC}`,
            )
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    read(railroadSCAC: string): Observable<RtRailroadFact> {
        return this.readViaOdata(railroadSCAC);
    }

    readViaApi(railroadSCAC: string): Observable<RtRailroadFact> {
        return this.http
            .get<RtRailroadFact>(`${this.apibaseUrl}${this.controllerName}/${railroadSCAC}`)
            .pipe(
                map((data: any) => this.adapter.adapt(data)),
                catchError(this.handleError),
            );
    }

    readViaOdata(railroadSCAC: string): Observable<RtRailroadFact> {
        return this.http
            .get<RtRailroadFact>(`${this.odatabaseUrl}${this.controllerName}('${railroadSCAC}')`)
            .pipe(
                map((data: any) => this.adapter.adapt(data)),
                catchError(this.handleError),
            );
    }

    // https://angular.io/guide/http#getting-error-details
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);

            // return an observable with a user-facing error message
            return throwError('A browser or network error occurred');
        } else if (error.status === 404) {
            // return an observable with a user-facing error message
            return throwError('Not Found');
        } else if (error.status === 422) {
            console.error('Found but cannot be used');
            return throwError(error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}`, error);

            // return an observable with a user-facing error message
            return throwError('The server returned an unknown error');
        }
    }
}
