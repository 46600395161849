import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxTemplateModule } from 'devextreme-angular/core';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxMapModule } from 'devextreme-angular/ui/map';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        DxButtonModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxTemplateModule,
        DxNumberBoxModule,
        DxValidatorModule,
        DxDataGridModule,
        DxDateBoxModule,
        DxValidationGroupModule,
        DxMapModule,
        DxDropDownButtonModule,
    ],
    exports: [
        DxButtonModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxTemplateModule,
        DxNumberBoxModule,
        DxValidatorModule,
        DxDataGridModule,
        DxDateBoxModule,
        DxValidationGroupModule,
        DxMapModule,
        DxDropDownButtonModule,
    ],
})
export class CustomDevExtremeModule {}
