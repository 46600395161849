import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { RtDivertedTripsService } from '@bds/data-access';
import { FastracColumn, FastracGrid } from '@bds/models';
import { FastracColsService } from '@bds/services';
import DataSource from 'devextreme/data/data_source';
import { of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { RailtracTripService } from '../../railtrac-trip/railtrac-trip.service';

@Component({
    selector: 'rt-diverted-trips',
    templateUrl: './rt-diverted-trips.component.html',
    styleUrls: ['./rt-diverted-trips.component.scss'],
})
export class RtDivertedTripsComponent implements OnDestroy {
    defaultFastracId: number;
    fastracId: number;
    gridId: number = FastracGrid.diversions;
    isSaving = false;
    divertedTripColumns: FastracColumn[] = [];
    divertedTripSource: DataSource;
    initialSelectedRowKeys: number[] = [];
    selectedRowKeys: number[] = [];

    private ngUnsubscribe$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public gridColumnService: FastracColsService,
        public tripService: RailtracTripService,
        public divertedTripService: RtDivertedTripsService,
        private _snackbar: MatSnackBar,
    ) {
        if (this.route.snapshot.firstChild) {
            const params = this.route.snapshot.firstChild.params || {};
            this.initialSelectedRowKeys = [+params['ormId']] || [];
            this.selectedRowKeys = [+params['ormId']] || [];
        }

        this.route.data.pipe(take(1)).subscribe((data) => {
            const resolvedData = data['defaultData'];
            this.defaultFastracId = +resolvedData;
        });

        this.route.queryParams
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                switchMap((params) => {
                    const fId = +params['fId'];
                    if (fId) {
                        return of(fId);
                    } else {
                        return this.route.data.pipe(map((data) => data['defaultData']));
                    }
                }),
                tap((response) => {
                    this.fastracId = response;
                }),
            )
            .subscribe();

        if (!this.route.snapshot.queryParams['fId']) {
            this.syncRouteParams();
        }

        gridColumnService
            .getFastracColumns(this.gridId)
            .pipe(take(1))
            .subscribe((cols) => {
                this.divertedTripColumns = cols;
            });

        this.divertedTripSource = divertedTripService.getDataSource();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next(true);
        this.ngUnsubscribe$.complete();
    }

    onFastracIdChanged(event) {
        const newFastracId = event || 0;

        if (this.fastracId !== newFastracId) {
            this.fastracId = newFastracId;
            this.syncRouteParams();
        }
    }

    onSelectedDiversionChanged(event) {
        this.selectedRowKeys = event ?? [];

        if (this.selectedRowKeys.length === 1) {
            this.router.navigate(['diversions/trip', this.selectedRowKeys[0]], {
                queryParams: { fId: this.fastracId },
            });
            return;
        }

        this.router.navigate(['diversions/multi'], {
            queryParams: { fId: this.fastracId, ids: this.selectedRowKeys.join(',') },
        });
    }

    openSnackBar(message: string, action: string, success: boolean) {
        let style = 'error-snack';
        if (success) {
            style = 'success-snack';
        }

        this._snackbar.open(message, action, {
            duration: 3000,
            panelClass: [style],
            horizontalPosition: 'right',
        });
    }

    syncRouteParams() {
        const fastracId: number = this.fastracId || this.defaultFastracId;
        const fastracParam = { fId: fastracId };

        if (this.selectedRowKeys && this.selectedRowKeys.length) {
            this.router.navigate(['diversions/trip', this.selectedRowKeys[0]], {
                queryParams: fastracParam,
            });
        } else {
            this.router.navigate(['diversions'], { queryParams: fastracParam });
        }
    }
}
