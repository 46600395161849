export type TripPopoutComponent =
    | 'trip'
    | 'trip-assignment'
    | 'trip-clm'
    | 'trip-comments'
    | 'trip-details'
    | 'trip-detention'
    | 'trip-diversion'
    | 'trip-hotList'
    | 'trip-commodity'
    | 'trip-route'
    | 'trip-customer'
    | 'trip-criteria'
    | 'equipment-details';

export class PopoutEvent<T> {
    constructor(public component: TripPopoutComponent, public id: T) {}
}
