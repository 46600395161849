import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'any',
})
export class AnyPipe implements PipeTransform {
    transform(array: [], ...args: any[]): boolean {
        const prop = args[1];
        const value = args[0];
        if (array && value && prop) {
            return array.some((x) => x[prop] === value);
        } else if (array && value) {
            return array.some((x) => x === value);
        } else {
            return false;
        }
    }
}
