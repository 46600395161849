import { PageService } from '@bds/core';
import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
// import {
//     PowerBiReportComponent,
//     Report,
//     ReportBookmark,
//     ReportService,
//     WorkspaceChangeService,
// } from '@bds/shipper-bi';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizeService } from '@bds/auth';

@Component({
    selector: 'app-home-screen',
    templateUrl: './home-screen.component.html',
    styleUrls: ['./home-screen.component.scss'],
})
export class HomeScreenComponent implements OnInit, OnDestroy {
    reportId = '';
    workspaceId = '';
    isAuthenticated: Observable<boolean>;
    buttonActive = false;
    //selectedBookmark: ReportBookmark | undefined;
    //@ViewChild(PowerBiReportComponent) child!: PowerBiReportComponent;
    private reportSubscription = new Subscription();
    private workspaceSubscription = new Subscription();

    constructor(
        public pageService: PageService,
        //private readonly reportService: ReportService,
        //private readonly workspaceChangeService: WorkspaceChangeService,
        public readonly authorizeService: AuthorizeService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        @Optional() @Inject('TEMP_DASHBOARD_REPORT_ID') private tempReportId: string,
        @Optional() @Inject('TEMP_DASHBOARD_WORKSPACE_ID') private tempWorkspaceId: string,
    ) {
        pageService.setHeader('Home', true);
        this.isAuthenticated = authorizeService.isAuthenticated();
        this.reportId = tempReportId;
        this.workspaceId = tempWorkspaceId;
    }

    ngOnInit(): void {
        // this.workspaceSubscription = this.workspaceChangeService.selectedWorkspaceId$.subscribe(
        //     (workspaceId: string) => {
        //         this.reportSubscription = this.reportService
        //             .getReportsByWorkspaceId(workspaceId)
        //             .subscribe((reports: Report[]) => {
        //                 this.workspaceId = workspaceId;
        //                 const report = reports.find((rep) => rep.categoryId === 0);
        //                 if (report) {
        //                     this.reportId = report.reportId;
        //                 } else {
        //                     this.reportId = 'none';
        //                 }
        //             });
        //     },
        // );
    }

    ngOnDestroy(): void {
        // this.reportSubscription.unsubscribe();
        // this.workspaceSubscription.unsubscribe();
    }

    // async applyFilter(filter: ReportBookmark): Promise<void> {
    //     if (filter === this.selectedBookmark) {
    //         await this.child.resoreBookmark(filter.state);
    //     } else if (filter.state === '') {
    //         await this.child.resoreBookmark('');
    //     } else {
    //         this.selectedBookmark = filter;
    //     }
    //     if (filter.state !== '') {
    //         void this.router.navigate(['.'], {
    //             relativeTo: this.route,
    //             queryParams: { filterId: filter.id },
    //             queryParamsHandling: 'merge',
    //             skipLocationChange: false,
    //         });
    //     }
    // }

    bottonLoad(event: boolean): void {
        this.buttonActive = event;
    }
}
