import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BdsPowerBiEmbedComponent } from './bds-power-bi-embed.component';

@NgModule({
    declarations: [BdsPowerBiEmbedComponent],
    imports: [CommonModule],
    exports: [BdsPowerBiEmbedComponent],
})
export class BdsPowerBiEmbedModule {}
