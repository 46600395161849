<h1 mat-dialog-title>Share FASTRAC</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
    <div
        class="search-bar-wrapper"
        style="position: sticky; top: 0; background-color: white; z-index: 1"
    >
        <mat-form-field appearance="standard" class="w-100">
            <fa-icon matPrefix [icon]="iconSearch" [fixedWidth]="true"></fa-icon>
            <input matInput bdsShoutcase #shareUserSearchBox placeholder="Search" />
        </mat-form-field>
    </div>
    <div *ngIf="!initialized">Loading...</div>
    <mat-selection-list *ngIf="initialized" (selectionChange)="onSelectedOptionsChange($event)">
        <mat-list-option
            *ngFor="let user of available | fastracSearch: shareUserSearchBox.value"
            [class.pre-selected]="saved.includes(user)"
            [class.changed-selected]="saved.includes(user) !== selected.includes(user)"
            [value]="user"
            [selected]="selected.includes(user)"
            checkboxPosition="before"
        >
            {{ user }}
            <ng-container *ngIf="saved.includes(user) !== selected.includes(user)">*</ng-container>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" (click)="onNoClick()" color="accent">Cancel</button>
    <button mat-button class="text-uppercase" [mat-dialog-close]="selected" color="accent">
        Ok
    </button>
</mat-dialog-actions>
