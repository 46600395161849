<h4 mat-dialog-title>
    <span>Edit Customer</span>
</h4>
<div mat-dialog-content>
    <rt-trip-details
        *ngIf="isFullData"
        matStyle="outline"
        matFloatLabel="auto"
        [readonly]="false"
        [trip]="data"
        (customerModified)="onModified($event)"
    ></rt-trip-details>
    <rt-trip-details
        *ngIf="!isFullData"
        matStyle="outline"
        matFloatLabel="auto"
        [readonly]="false"
        [tripId]="data"
        (customerModified)="onModified($event)"
    ></rt-trip-details>
</div>
<div mat-dialog-actions class="flex-row-reverse">
    <button mat-button [mat-dialog-close]="data" class="text-uppercase order-0" color="accent">
        Save
    </button>
    <!--<button mat-button [mat-dialog-close]="data" cdkFocusInitial class="text-uppercase order-0" color="accent">Save</button>-->
    <button
        mat-button
        (click)="onNoClick()"
        class="text-uppercase order-1"
        style="color: rgba(0, 0, 0, 0.5)"
    >
        Cancel
    </button>
</div>
