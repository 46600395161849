import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import {
    BdsRoutePathCodeService,
    RtRoutePathCode,
} from '../../services/bds-route-path-code.service';

@Component({
    selector: 'bds-bds-path-code-form',
    templateUrl: './bds-path-code-form.component.html',
    styleUrls: ['./bds-path-code-form.component.css'],
})
export class BdsPathCodeFormComponent implements OnInit {
    autoGenerate = true;
    savePathCodeFlag = true;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            pathDescription: string;
            pathCode: string;
            originRoad: string;
            deliveryRoad: string;
            routeOverride: string;
            pathCodeSearchEnabled: string;
        },
        public dialogRef: MatDialogRef<BdsPathCodeFormComponent>,
        private pathCodeService: BdsRoutePathCodeService,
    ) {}

    ngOnInit(): void {
        if (this.data.routeOverride !== 'Y' && this.data.pathCodeSearchEnabled !== 'Y') {
            this.autoGenerate = false;
            this.savePathCodeFlag = false;
        }
    }

    save() {
        if (this.savePathCodeFlag) {
            this.savePathCode();
        } else {
            this.dialogRef.close(this.data.pathCode);
        }
    }

    savePathCode() {
        const pathCode = <RtRoutePathCode>{
            pathDescription: this.data.pathDescription,
            originRoad: this.data.originRoad,
            deliveryRoad: this.data.deliveryRoad,
        };
        if (!this.autoGenerate) {
            pathCode.pathCode = this.data.pathCode;
        }
        this.pathCodeService
            .create(pathCode)
            .pipe(
                tap((response: { relatedObject: RtRoutePathCode }) => {
                    this.dialogRef.close(response.relatedObject.pathCode);
                }),
            )
            .subscribe();
    }
}
