import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RtCarHotListService } from '@bds/data-access';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { catchError, finalize, tap } from 'rxjs/operators';
import { TripCarHotListDto } from '@bds/railtrac-models';
import { HotListFormService } from './hot-list-form.service';
import { ErrorListItem } from '@bds/rt-advance-models';
import { EMPTY } from 'rxjs';
import { ErrorHandlerService } from '@bds/railtrac-internal-services';

export interface BdsHotListDialogData {
    hotLists: Array<TripCarHotListDto>;
    title: string;
}

@Component({
    selector: 'bds-bds-hot-list-details-dialog',
    templateUrl: './bds-hot-list-details-dialog.component.html',
    styleUrls: ['./bds-hot-list-details-dialog.component.css'],
    providers: [HotListFormService],
})
export class BdsHotListDetailsDialogComponent implements OnInit {
    bulkForm: UntypedFormGroup;
    form: UntypedFormGroup;
    iconWarning = faExclamationTriangle;
    isSaving = false;
    errors: ErrorListItem[] = [];

    constructor(
        public dialogRef: MatDialogRef<BdsHotListDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BdsHotListDialogData,
        private bdsCarHotListService: RtCarHotListService,
        private _snackbar: MatSnackBar,
        private hotListFormService: HotListFormService,
        private errorHandler: ErrorHandlerService,
    ) {}

    get warningCount() {
        return this.data.hotLists.filter((x) => x.propagateHotListForward === false).length;
    }

    ngOnInit() {
        this.bulkForm = this.hotListFormService.hotListBulkForm;
        this.form = this.hotListFormService.form;
    }

    save() {
        if (this.form.valid) {
            this.isSaving = true;
            const formVal = this.hotListFormService.hotListItemForms.getRawValue();
            const value = formVal as TripCarHotListDto[];
            this.bdsCarHotListService
                .addRtCarHotListsForTrips(value)
                .pipe(
                    tap({
                        next: (response) => {
                            if (!response.bourqueDataResult.succeeded) {
                                //Parsing for any other errors generated by DB triggers
                                this.errorHandler.parseResultWithObject(response).subscribe({
                                    next: () => {
                                        this.afterSaveComplete();
                                        this.dialogRef.close(response);
                                    },
                                    error: (err) => {
                                        this.afterError(err);
                                        return EMPTY;
                                    },
                                });
                            } else {
                                this.afterSaveComplete();
                                this.dialogRef.close(response);
                            }
                        },
                        error: (err) => {
                            this.afterError(err);
                            return EMPTY;
                        },
                    }),
                    catchError((err) => {
                        this.afterError(err);
                        return EMPTY;
                    }),
                    finalize(() => {
                        this.isSaving = false;
                    }),
                )
                .subscribe();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private afterError(error: ErrorListItem[]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const customerErrors = [];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.errors = error;

        this._snackbar.open('Error saving hot list(s).', 'Error', {
            duration: 3000,
            panelClass: 'error-snack',
        });
        this.isSaving = false;
    }

    private afterSaveComplete(message?: string) {
        if (!message) {
            message = 'Changes saved!';
        }
        this.isSaving = false;
        this._snackbar.open(message, 'Ok', { duration: 3000 });
    }
}
