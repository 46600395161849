import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BdsDxDataGridModule } from '@bds/components';
import { BdsFastracModule } from '@bds/fastrac';
import { DxDataGridModule } from 'devextreme-angular';
import { BdsPathCodeGridComponent } from './bds-path-code-grid/bds-path-code-grid.component';
import { BdsPathCodeComponent } from './bds-path-code.component';

@NgModule({
    declarations: [BdsPathCodeComponent, BdsPathCodeGridComponent],
    imports: [CommonModule, DxDataGridModule, BdsDxDataGridModule, BdsFastracModule],
})
export class BdsPathCodeModule {}

const routes: Routes = [
    {
        path: '',
        component: BdsPathCodeComponent,
    },
];

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forChild(routes), BdsPathCodeModule],
})
export class BdsPathCodeRoutedModule {}
