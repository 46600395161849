<bds-equipment-report-categories-menu
    *ngIf="showMenu"
    [equipInit]="equipInit"
    [equipNumber]="equipNumber"
    [isSaving]="isSaving"
    saveText="Save Reporting Categories"
    (saveChanges)="onSave()"
>
</bds-equipment-report-categories-menu>

<bds-equip-report-categories-list
    [equipId]="equipId"
    [equipCatgEquip]="equipCatgEquip"
    [equipCategories]="equipCategories$ | async"
    [equipCatgValues]="equipCatgValues$ | async"
    [shipmentDate]="startDate"
    [tripCloseDate]="tripCloseDate"
    [isSaving]="isSaving"
    [showHeader]="showHeader"
    (equipCatgEquipChange)="onEquipCatgEquipChanged($event)"
>
</bds-equip-report-categories-list>
