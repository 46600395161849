import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { RtTrip, RtRouteParticipatingRailroad, RtClm } from '@bds/railtrac-models';
import { RtJeopardizedShipmentReasonBaseComponent } from '../rt-jeopardized-shipment-reason-base.component';
import { RailtracClmService } from '../../../railtrac-clm/railtrac-clm.service';
import { RailtracTripService } from '../../../railtrac-trip/railtrac-trip.service';
import { RtRailroadService, RtRouteService } from '@bds/data-access';
import { MatSnackBar } from '@angular/material/snack-bar';
import DataSource from 'devextreme/data/data_source';
import {
    RtRouteParticipatingRailroadDialogOptions,
    BdsRouteParticipatingRailroadDialogComponent,
    BdsRouteParticipatingRailroadService,
} from '@bds/participating-railroad';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { firstValueFrom, forkJoin } from 'rxjs';
import { RtJeopardizedShipmentService } from '../../rt-jeopardized-shipment.service';

@Component({
    selector: 'rt-jeopardized-shipment-off-route',
    templateUrl: './rt-jeopardized-shipment-off-route.component.html',
    styleUrls: ['./rt-jeopardized-shipment-off-route.component.scss'],
})
export class RtJeopardizedShipmentOffRouteComponent
    extends RtJeopardizedShipmentReasonBaseComponent
    implements OnInit, OnDestroy, OnChanges
{
    constructor(
        public tripService: RailtracTripService,
        public jeopardizedShipmentService: RtJeopardizedShipmentService,
        public clmService: RailtracClmService,
        public railroadService: RtRailroadService,
        public participatingRailroadService: BdsRouteParticipatingRailroadService,
        public routeCodeService: RtRouteService,
        public snackbar: MatSnackBar,
        public dialog: MatDialog,
    ) {
        super(tripService, clmService);
    }

    ngOnInit() {
        super.ngOnInit();
    }
    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }

    async getAffectedTripCount(): Promise<number> {
        const selectedTrip = await firstValueFrom(this.trip$);
        const trips = await firstValueFrom(
            this.jeopardizedShipmentService.findJeopardizedShipmentByRouteCodes(
                [selectedTrip.routeCode],
                'OFF ROUTE',
            ),
        );

        if (trips) {
            return trips.length;
        }
    }

    private findCurrentClm(clms: RtClm[]) {
        return (clms ?? []).reduce(
            (p, c) => (!p ? c : p?.clmDateTime < c?.clmDateTime ? c : p),
            null,
        );
    }

    addParticipatingRailroad(): void {
        forkJoin({
            trip: this.trip$.pipe(take(1)),
            clms: this.clms$.pipe(take(1)),
            count: this.getAffectedTripCount(),
        }).subscribe((r) => {
            const routeCode = (r.trip || { routeCode: '' }).routeCode;
            const currentClm = this.findCurrentClm(r.clms);
            const road = ((currentClm ? currentClm.road : r.trip.road) ?? '').trim();
            const x: RtRouteParticipatingRailroad = new RtRouteParticipatingRailroad(
                null,
                routeCode,
                road,
            );
            const y: RtRouteParticipatingRailroadDialogOptions = {
                item: x,
                isReadOnly: true,
                affectedTripCount: r.count ?? 0,
                railroadDataSource: new DataSource({
                    store: this.railroadService.getODataStore(),
                }),
                routeCodeDataSource: new DataSource({
                    store: this.routeCodeService.getODataStore(),
                    paginate: true,
                    pageSize: 20,
                }),
            };

            const dialogRef = this.dialog.open(BdsRouteParticipatingRailroadDialogComponent, {
                width: '550px',
                data: y,
            });

            dialogRef.afterClosed().subscribe((result: RtRouteParticipatingRailroad) => {
                if (result) {
                    this.participatingRailroadService
                        .add(result)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.change.emit();
                            this.snackbar.open('Participating Railroad added', 'Ok');
                        });
                }
            });
        });
    }

    onTripSaveClicked(tripDetails: RtTrip): void {
        this.tripService.update(tripDetails.ormId, tripDetails).subscribe(() => {
            this.snackbar.open('Trip Saved!', 'Ok', {
                duration: 3000,
            });
        });
    }
}
