import ODataStore from 'devextreme/data/odata/store';
import { RtArrAtDest, RtClmSightCode, RtStateCode } from '@bds/railtrac-models';

export class BdsDestinationCriteriaGridDialogModel {
    customers: ODataStore;
    destinationCriteria: RtArrAtDest;
    isClone: boolean;
    isNew: boolean;
    railroads: ODataStore;
    sightCodes: RtClmSightCode[];
    states: RtStateCode[];
}
