import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtRouteSegment implements From<RtRouteSegment> {
    constructor(
        public routeCode?: string,
        public segmentNo?: number,
        public railroad?: string,
        public junction?: string,
        public beginSplc?: string,
        public endSplc?: string,
        public benchmarkHours?: number,
        public contractNo?: string,
        public mileageAllowanceIndicator?: number,
        public mileageAllowanceType?: string,
    ) {}

    from(trip: RtRouteSegment): RtRouteSegment {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtRouteSegment>('routeCode'), server: 'routeCode' },
    { client: nameof<RtRouteSegment>('segmentNo'), server: 'segmentNo' },
    { client: nameof<RtRouteSegment>('railroad'), server: 'railroad' },
    { client: nameof<RtRouteSegment>('junction'), server: 'junction' },
    { client: nameof<RtRouteSegment>('beginSplc'), server: 'beginSplc' },
    { client: nameof<RtRouteSegment>('endSplc'), server: 'endSplc' },
    { client: nameof<RtRouteSegment>('benchmarkHours'), server: 'benchmarkHours' },
    { client: nameof<RtRouteSegment>('contractNo'), server: 'contractNo' },
    {
        client: nameof<RtRouteSegment>('mileageAllowanceIndicator'),
        server: 'mileageAllowanceIndicator',
    },
    { client: nameof<RtRouteSegment>('mileageAllowanceType'), server: 'mileageAllowanceType' },
];

@Injectable({
    providedIn: 'root',
})
export class RtRouteSegmentAdapter extends TwoWayAdapter<RtRouteSegment> {
    constructor() {
        super();
    }

    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtRouteSegmentMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}

    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        const columns = [
            {
                dataField: nameof<RtRouteSegment>('routeCode'),
                dataType: 'string',
                caption: 'routeCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteSegment>('segmentNo'),
                dataType: 'number',
                caption: 'segmentNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteSegment>('railroad'),
                dataType: 'string',
                caption: 'road',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteSegment>('junction'),
                dataType: 'string',
                caption: 'junc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteSegment>('beginSplc'),
                dataType: 'string',
                caption: 'begSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteSegment>('endSplc'),
                dataType: 'string',
                caption: 'endSplc',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteSegment>('benchmarkHours'),
                dataType: 'number',
                caption: 'benchmarkHours',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteSegment>('contractNo'),
                dataType: 'string',
                caption: 'contractNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteSegment>('mileageAllowanceIndicator'),
                dataType: 'number',
                caption: 'mileAllowInd',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRouteSegment>('mileageAllowanceType'),
                dataType: 'string',
                caption: 'mileAllowType',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtRouteSegment>('routeCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteSegment>('segmentNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteSegment>('railroad'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteSegment>('junction'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteSegment>('beginSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteSegment>('endSplc'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteSegment>('benchmarkHours'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRouteSegment>('contractNo'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtRouteSegment>('mileageAllowanceIndicator'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtRouteSegment>('mileageAllowanceType'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
