import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ErrorListItem, ErrorResult, ErrorResultWithObject } from '@bds/rt-advance-models';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    // Parses the HttpErrorResponse. If it is one of the structured types, pull the error information off of it. This information
    // will be either a list of errors and/or a list of validation errors. If the response is not a structured error, retrieve the
    // error message and send a a new error. If it is unknown, throw a general error. In all cases, the result returned will be an
    // array of type ErrorListItem with the errorMessage filled in so that the component can parse through these messages. If it
    // includes validation errors, the field will be included on each ErrorListItem so that components can show errors on the form.
    public parseApiError(error: HttpErrorResponse): Observable<never> {
        if (!error) {
            return throwError(<ErrorListItem>{
                errorMessage: 'An unknown error occurred',
            });
        }

        let errorResult: ErrorResult;

        if (error.error && this.isErrorResultWithObject(error.error)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            errorResult = error.error.bourqueDataResult;
        } else if (error.error && this.isErrorResult(error.error)) {
            errorResult = error.error;
        } else {
            const errItem: ErrorListItem = this.setErrorMessageFromStatus(error);
            return throwError([errItem]);
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const errorList: ErrorListItem[] = errorResult.errors
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
            .map((e) => <ErrorListItem>{ errorMessage: e.description })
            .concat(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                errorResult.validationErrors.map(
                    (v) =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        <ErrorListItem>{
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            field: v.validationField,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            errorMessage: v.validationMessage,
                        },
                ),
            );

        return throwError(errorList);
    }

    public parseResultWithObject(data: ErrorResultWithObject): Observable<never> {
        if (!data) {
            return throwError(<ErrorListItem>{
                errorMessage: 'An unknown error occurred',
            });
        }
        const errorList: ErrorListItem[] = data.bourqueDataResult.errors
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
            .map((e) => <ErrorListItem>{ errorMessage: e.description })
            .concat(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                data.bourqueDataResult.validationErrors.map(
                    (v) =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        <ErrorListItem>{
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            field: v.validationField,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            errorMessage: v.validationMessage,
                        },
                ),
            );

        return throwError(errorList);
    }

    public parseResult(data: ErrorResult): Observable<never> {
        if (!data) {
            return throwError(<ErrorListItem>{
                errorMessage: 'An unknown error occurred',
            });
        }
        const errorList: ErrorListItem[] = data.errors
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
            .map((e) => <ErrorListItem>{ errorMessage: e.description })
            .concat(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                data.validationErrors.map(
                    (v) =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        <ErrorListItem>{
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            field: v.validationField,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            errorMessage: v.validationMessage,
                        },
                ),
            );

        return throwError(errorList);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isErrorResult(response: any): response is ErrorResult {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return (<ErrorResult>response).validationErrors !== undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isErrorResultWithObject(response: any): response is ErrorResultWithObject {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return (<ErrorResultWithObject>response).bourqueDataResult !== undefined;
    }

    private setErrorMessageFromStatus(error: HttpErrorResponse): ErrorListItem {
        let errMessage: string;

        switch (error.status) {
            case 401:
                // Unauthenticated result
                errMessage = error.message || 'User not logged in';
                break;
            case 403:
                // Unauthorized result
                errMessage = error.message || 'User or application not authorized for access';
                break;
            case 404:
                // return an observable with a user-facing error message
                errMessage = error.message || 'Not Found';
                break;
            case 422:
                errMessage = error.message || 'Found but cannot be used';
                break;
            default: {
                // The backend returned an unsuccessful response code.
                errMessage = error.message || `Backend returned code ${error.status}`;
            }
        }

        return <ErrorListItem>{
            errorMessage: errMessage,
        };
    }
}
