<h1 *ngIf="!!data.title" mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
    <div class="row" *ngFor="let form of forms; let i = index" [formGroup]="form">
        <div class="col-sm-4">
            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Consignee No.</mat-label>
                        <input
                            matInput
                            bdsShoutcase
                            formControlName="custNo"
                            [id]="i === 0 ? 'firstField' : ''"
                        />
                        <mat-error *ngIf="form.controls['custNo'].invalid">{{
                            getErrorMessage(form.controls['custNo'])
                        }}</mat-error>
                        <mat-hint *ngIf="showSuccess" class="text-success">Saved!</mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-sm-8">
            <div class="row">
                <div class="col-sm-6">
                    <mat-form-field class="w-100">
                        <mat-label>Consignee Name</mat-label>
                        <input matInput bdsShoutcase formControlName="custName" />
                        <mat-error *ngIf="form.controls['custName'].invalid">{{
                            getErrorMessage(form.controls['custName'])
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-4">
                    <mat-form-field class="w-100">
                        <mat-label>Consignee City</mat-label>
                        <input matInput bdsShoutcase formControlName="custCity" />
                        <mat-error *ngIf="form.controls['custCity'].invalid">{{
                            getErrorMessage(form.controls['custCity'])
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-2">
                    <mat-form-field class="w-100">
                        <mat-label>State</mat-label>
                        <mat-select formControlName="custState">
                            <mat-option *ngFor="let option of stateList" [value]="option.code">
                                {{ option.code }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.controls['custState'].invalid">{{
                            getErrorMessage(form.controls['custState'])
                        }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" (click)="onNoClick()" color="accent">Cancel</button>
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        (click)="onSaveAndNewClick()"
        [disabled]="formArray.invalid"
        *ngIf="forms.length === 1"
    >
        Save and Add New
    </button>
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        (click)="onSaveAndCloseClick()"
        [disabled]="formArray.invalid"
    >
        Save and Close
    </button>
</mat-dialog-actions>
