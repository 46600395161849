import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorsModule } from '../../vendors';
import { EquipmentReportCategoriesMenuComponent } from './equipment-report-categories-menu/equipment-report-categories-menu.component';
import { EquipReportCategoriesListComponent } from './equip-report-categories-list/equip-report-categories-list.component';
import { EquipReportCategoryComponent } from './equip-report-category/equip-report-category.component';

@NgModule({
    declarations: [
        EquipmentReportCategoriesMenuComponent,
        EquipReportCategoriesListComponent,
        EquipReportCategoryComponent,
    ],
    imports: [CommonModule, VendorsModule],
    exports: [
        EquipmentReportCategoriesMenuComponent,
        EquipReportCategoriesListComponent,
        EquipReportCategoryComponent,
    ],
})
export class EquipmentReportCategoriesComponentsModule {}
