import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { nameof } from '@bds/core';
import { FormErrorHandlerService } from '@bds/helpers';
import { RtUnitMeasure } from '@bds/models';
import { BdsDialogConfirmComponent, BdsDialogConfirmModel } from '@bds/components';
import { EquipComponentCatgBridge, Equipment, EquipmentComponentCategory } from '../../../models';

@Component({
    selector: 'bds-equipment-mech-common',
    templateUrl: './equipment-mech-common.component.html',
    styleUrls: ['./equipment-mech-common.component.scss'],
})
export class EquipmentMechCommonComponent implements OnInit, OnChanges, OnDestroy {
    equipmentForm: UntypedFormGroup;
    equipmentComponentCategories: string[] = [];
    initializingCategories = true;
    weightUnits: RtUnitMeasure[] = [];
    weightVolUnits: RtUnitMeasure[] = [];

    private ngUnsubscribe$: Subject<void> = new Subject<void>();

    // For form styles
    @Input() dense = true;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';

    @Input() componentCategories: EquipmentComponentCategory[];
    @Input() selectedEquipment: Equipment;
    @Input() unitsOfMeasure: RtUnitMeasure[];
    @Output() equipmentChange: EventEmitter<{ value: Equipment; isValid: boolean }> =
        new EventEmitter<{ value: Equipment; isValid: boolean }>();

    get isNew(): boolean {
        if (this.selectedEquipment && this.selectedEquipment.ormId) {
            return false;
        }
        return true;
    }

    constructor(private formErrorService: FormErrorHandlerService, public dialog: MatDialog) {
        this.equipmentForm = this.getFormGroup();
    }

    ngOnInit() {
        if (this.componentCategories && this.selectedEquipment) {
            this.createEquipCategories();
        }

        this.equipmentForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            let isValid: boolean;

            if (this.equipmentForm.errors || this.equipmentForm.invalid) {
                isValid = false;
            } else {
                isValid = true;
            }

            this.equipmentChange.emit({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value: this.equipmentForm.getRawValue(),
                isValid: isValid,
            });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.componentCategories || changes.selectedEquipment) &&
            this.componentCategories &&
            this.selectedEquipment
        ) {
            this.initializingCategories = true;
            this.createEquipCategories();
        }

        if (changes.selectedEquipment && this.selectedEquipment) {
            this.equipmentForm.reset();
            this.equipmentForm.patchValue(this.selectedEquipment);
            this.setDependentValidators('allowableWeight', 'allowableWeightUm');
            this.setDependentValidators('capacity', 'capacityUm');
            this.setDependentValidators('tareWeight', 'tareWeightUm');
        }

        if (changes.unitsOfMeasure && this.unitsOfMeasure) {
            this.weightUnits = this.filterUnitsOfMeasure(['W']);
            this.weightVolUnits = this.filterUnitsOfMeasure(['V', 'W']);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    createEquipCategories(): void {
        if (this.selectedEquipment && this.selectedEquipment.equipmentComponentCategories) {
            this.equipmentComponentCategories = this.selectedEquipment.equipmentComponentCategories
                .map(
                    (c) =>
                        this.componentCategories.find(
                            (cc) => cc.componentCategory === c.componentCategory,
                        ).componentCategory,
                )
                .sort();
        } else {
            this.equipmentComponentCategories = [];
        }
    }

    convertEquipComponentCategories(values: string[]): void {
        const catValues: EquipComponentCatgBridge[] = values.map((v) => {
            return (
                this.selectedEquipment.equipmentComponentCategories.find(
                    (c) => c.componentCategory === v,
                ) ||
                <EquipComponentCatgBridge>{
                    ormId: 0,
                    equipmentOrmId: this.selectedEquipment.ormId,
                    componentCategory: v,
                }
            );
        });

        const categoriesControl = this.equipmentForm.get(
            'equipmentComponentCategories',
        ) as UntypedFormControl;
        if (categoriesControl) {
            categoriesControl.setValue(catValues);
        }
    }

    filterUnitsOfMeasure(filter: string[]): RtUnitMeasure[] {
        return this.unitsOfMeasure.filter(
            (u) => filter.indexOf(u.unitType) > -1 && u.rateOnly === 'N',
        );
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        return this.formErrorService.getFormError(formItem);
    }

    getFormGroup(): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<Equipment>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<Equipment>('equipmentInit'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required, Validators.maxLength(10)]),
        );
        formGroup.addControl(
            nameof<Equipment>('equipmentNo'),
            // eslint-disable-next-line @typescript-eslint/unbound-method
            new UntypedFormControl('', [Validators.required, Validators.maxLength(30)]),
        );
        formGroup.addControl(
            nameof<Equipment>('equipmentType'),
            new UntypedFormControl('', [Validators.maxLength(24)]),
        );
        formGroup.addControl(
            nameof<Equipment>('rtCarInit'),
            new UntypedFormControl('', [Validators.maxLength(4)]),
        );
        formGroup.addControl(
            nameof<Equipment>('rtCarNo'),
            new UntypedFormControl('', [Validators.maxLength(6)]),
        );
        formGroup.addControl(
            nameof<Equipment>('equipmentBarcode'),
            new UntypedFormControl('', [Validators.maxLength(255)]),
        );
        formGroup.addControl(
            nameof<Equipment>('tareWeight'),
            new UntypedFormControl('', [Validators.min(0)]),
        );
        formGroup.addControl(
            nameof<Equipment>('tareWeightUm'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(
            nameof<Equipment>('capacity'),
            new UntypedFormControl('', [Validators.min(0)]),
        );
        formGroup.addControl(
            nameof<Equipment>('capacityUm'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(
            nameof<Equipment>('allowableWeight'),
            new UntypedFormControl('', [Validators.min(0)]),
        );
        formGroup.addControl(
            nameof<Equipment>('allowableWeightUm'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(
            nameof<Equipment>('noOfCompart'),
            new UntypedFormControl('', [Validators.min(0)]),
        );
        formGroup.addControl(nameof<Equipment>('extLength'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<Equipment>('extLengthUm'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(nameof<Equipment>('extMaxWidth'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<Equipment>('extMaxWidthUm'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(nameof<Equipment>('extMaxHeight'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<Equipment>('extMaxHeight'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(nameof<Equipment>('intLength'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<Equipment>('intLengthUm'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(nameof<Equipment>('intWidth'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<Equipment>('intWidthUm'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(nameof<Equipment>('intHeight'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<Equipment>('intHeightUm'),
            new UntypedFormControl('', [Validators.maxLength(3)]),
        );
        formGroup.addControl(
            nameof<Equipment>('lastModifiedUserId'),
            new UntypedFormControl('', [Validators.maxLength(50)]),
        );
        formGroup.addControl(nameof<Equipment>('lastModifiedDatetime'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<Equipment>('activeStatus'),
            new UntypedFormControl('', [Validators.maxLength(1)]),
        );
        formGroup.addControl(
            nameof<Equipment>('carClass'),
            new UntypedFormControl('', [Validators.maxLength(2)]),
        );
        formGroup.addControl(
            nameof<Equipment>('umlerMechDesignation'),
            new UntypedFormControl('', [Validators.maxLength(4)]),
        );
        formGroup.addControl(
            nameof<Equipment>('umlerPlateCode'),
            new UntypedFormControl('', [Validators.maxLength(1)]),
        );
        formGroup.addControl(
            nameof<Equipment>('manufacturer'),
            new UntypedFormControl('', [Validators.maxLength(100)]),
        );
        formGroup.addControl(nameof<Equipment>('mfgDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<Equipment>('equipMechCategories'), new UntypedFormControl([]));
        formGroup.addControl(
            nameof<Equipment>('equipmentComponentCategories'),
            new UntypedFormControl([]),
        );

        return formGroup;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onCategoryChanged(event): void {
        if (event) {
            // If a category is deleted, alert the user that fields will be lost
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (!this.initializingCategories && event.value.length < event.previousValue.length) {
                let catName = 'this category';

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (event.previousValue.length - event.value.length === 1) {
                    // Find the missing category
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    event.previousValue.forEach((p) => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                        if (event.value.findIndex((v) => v === p) === -1) {
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            catName = `category ${p}`;
                            const cat: EquipmentComponentCategory = (
                                this.componentCategories || []
                            ).find((c) => c.componentCategory === p);
                            if (cat && cat.componentCategory) {
                                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                catName = `category ${p} (${cat.categoryDscr})`;
                            }
                        }
                    });
                } else {
                    catName = 'these categories';
                }

                const confirmInfo: BdsDialogConfirmModel = {
                    content: `Removing ${catName} may result in the loss of data`,
                    actionText: 'Remove',
                    defaultToYes: false,
                };

                const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
                    width: '400px',
                    data: confirmInfo,
                });

                dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        this.convertEquipComponentCategories(event.value);
                    } else {
                        // revert change
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                        this.equipmentComponentCategories = event.previousValue;
                    }
                });
            } else if (!this.initializingCategories) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-unsafe-call
                event.value = event.value.sort();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                this.convertEquipComponentCategories(event.value);
            }

            this.initializingCategories = false;
        }
    }

    setDependentValidators(controlName1: string, controlName2: string): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const controlValue1: string = (this.equipmentForm.get(controlName1).value || '')
            .toString()
            .trim();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const controlValue2: string = (this.equipmentForm.get(controlName2).value || '')
            .toString()
            .trim();

        if (controlValue1 || controlValue2) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            this.equipmentForm.get(controlName1).setValidators(Validators.required);
            // eslint-disable-next-line @typescript-eslint/unbound-method
            this.equipmentForm.get(controlName2).setValidators(Validators.required);
        } else {
            this.equipmentForm.get(controlName1).clearValidators();
            this.equipmentForm.get(controlName2).clearValidators();
        }

        this.equipmentForm.get(controlName1).updateValueAndValidity();
        this.equipmentForm.get(controlName2).updateValueAndValidity();
        this.equipmentForm.get(controlName1).markAsTouched();
        this.equipmentForm.get(controlName2).markAsTouched();
    }
}
