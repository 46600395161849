<div class="container-fluid">
    <div class="col-12" [formGroup]="reportForm">
        <div class="row">
            <div class="col-3">
                <div class="dx-field-label">{{ controlNames && controlNames[0] }}</div>
                <!-- <dx-date-box
                    placeholder="MM/dd/yy"
                    [showClearButton]="true"
                    [useMaskBehavior]="true"
                    displayFormat="MM/dd/yy"
                    [inputAttr]="{ 'aria-label': 'Date' }"
                    name="startDate"
                    dateSerializationFormat="yyyy-MM-dd"
                >
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="{{ controlNames && controlNames[1] }} is required."
                        ></dxi-validation-rule>
                    </dx-validator>
                </dx-date-box> -->
                <dx-date-box
                    name="startDate"
                    formControlName="startDate"
                    class="w-100 bds-dx-custom-material"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    placeholder="MM/dd/yy"
                    displayFormat="MM/dd/yy"
                    [showClearButton]="true"
                    [useMaskBehavior]="true"
                >
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="{{ controlNames && controlNames[1] }} is required."
                        ></dxi-validation-rule>
                    </dx-validator>
                </dx-date-box>
            </div>
            <div class="col-3">
                <div class="dx-field-label">{{ controlNames && controlNames[1] }}</div>
                <dx-date-box
                    name="endDate"
                    formControlName="endDate"
                    class="w-100 bds-dx-custom-material"
                    [stylingMode]="dxMatStyle"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    placeholder="MM/dd/yy"
                    displayFormat="MM/dd/yy"
                    [showClearButton]="true"
                    [useMaskBehavior]="true"
                >
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="{{ controlNames && controlNames[1] }} is required."
                        ></dxi-validation-rule>
                    </dx-validator>
                </dx-date-box>
            </div>
            <div class="col-1">
                <button
                    mat-button
                    class="text-uppercase"
                    color="accent"
                    (click)="onGetReportClick()"
                    [disabled]="!reportForm.valid"
                >
                    Get Report
                </button>
            </div>
            <div class="col-1">
                <button mat-button class="text-uppercase" color="accent" (click)="onCancelClick()">
                    Cancel
                </button>
            </div>
        </div>
        <div class="row" *ngIf="reportForm.errors && reportForm.errors.dateRangeInvalid">
            <mat-error>
                {{ controlNames && controlNames[0] }} must be less than or equal to
                {{ controlNames && controlNames[1] }}
            </mat-error>
        </div>
    </div>
</div>
