import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lookup',
})
export class LookupPipe implements PipeTransform {
    transform(lookup: any, ...args: any[]): any {
        const array: Array<any> = args[0];
        const property: string = args[1];

        if (array && property) {
            const value: string = args[2] || null;

            const item = array.find((x) => x[property] === lookup);
            return value ? item[value] : item;
        }
    }
}
