import { Injectable } from '@angular/core';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtTripDiverted implements From<RtTripDiverted> {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public shipDate?: Date,
        public divertDateTime?: Date,
        public le?: string,
        public road?: string,
        public shipmentType?: string,
        public prevDestSplc?: string,
        public prevReturnSplc?: string,
        public prevRouteCode?: string,
        public prevCustNo?: string,
        public prevOrderNo?: string,
        public prevBolNo?: string,
        public whoPays?: string,
        public divLocationSplc?: string,
        public divRouteDscr?: string,
        public divertType?: string,
        public divLocCity?: string,
        public divLocState?: string,
        public ormId?: number,
        public rtTripOrmId?: number,
        public userId?: string,
        public updateDateTime?: Date,
    ) {}
    from(trip: RtTripDiverted): RtTripDiverted {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtTripDiverted>('carInit'), server: 'carInit' },
    { client: nameof<RtTripDiverted>('carNo'), server: 'carNo' },
    { client: nameof<RtTripDiverted>('shipDate'), server: 'shipDate' },
    { client: nameof<RtTripDiverted>('divertDateTime'), server: 'divertDateTime' },
    { client: nameof<RtTripDiverted>('le'), server: 'le' },
    { client: nameof<RtTripDiverted>('road'), server: 'road' },
    { client: nameof<RtTripDiverted>('shipmentType'), server: 'shipmentType' },
    { client: nameof<RtTripDiverted>('prevDestSplc'), server: 'prevDestSplc' },
    { client: nameof<RtTripDiverted>('prevReturnSplc'), server: 'prevReturnSplc' },
    { client: nameof<RtTripDiverted>('prevRouteCode'), server: 'prevRouteCode' },
    { client: nameof<RtTripDiverted>('prevCustNo'), server: 'prevCustNo' },
    { client: nameof<RtTripDiverted>('prevOrderNo'), server: 'prevOrderNo' },
    { client: nameof<RtTripDiverted>('prevBolNo'), server: 'prevBolNo' },
    { client: nameof<RtTripDiverted>('whoPays'), server: 'whoPays' },
    { client: nameof<RtTripDiverted>('divLocationSplc'), server: 'divLocationSplc' },
    { client: nameof<RtTripDiverted>('divRouteDscr'), server: 'divRouteDscr' },
    { client: nameof<RtTripDiverted>('divertType'), server: 'divertType' },
    { client: nameof<RtTripDiverted>('divLocCity'), server: 'divLocCity' },
    { client: nameof<RtTripDiverted>('divLocState'), server: 'divLocState' },
    { client: nameof<RtTripDiverted>('ormId'), server: 'ormId' },
    { client: nameof<RtTripDiverted>('rtTripOrmId'), server: 'rtTripOrmId' },
    { client: nameof<RtTripDiverted>('userId'), server: 'userId' },
    { client: nameof<RtTripDiverted>('updateDateTime'), server: 'updateDateTime' },
];

@Injectable({
    providedIn: 'root',
})
export class RtTripDivertedAdapter extends TwoWayAdapter<RtTripDiverted> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}
