<div class="sticky-top mat-elevation-z6">
    <mat-toolbar class="trip-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <span *ngIf="!ormId">
                Create Preferred Route
                <span *ngIf="isSaving">
                    <small>
                        (saving...)
                        <fa-icon [icon]="icons.spinner" [spin]="true"></fa-icon>
                    </small>
                </span>
            </span>
            <span *ngIf="ormId">
                {{ selectedPreferredRoute?.customerNo }}
                <span>
                    <small>
                        {{ selectedPreferredRoute?.routeCode }} ({{
                            selectedPreferredRoute?.rtRouteCode?.routeDescription
                        }})
                    </small>
                </span>
                <span *ngIf="isSaving">
                    <small>
                        (saving...)
                        <fa-icon [icon]="icons.spinner" [spin]="true"></fa-icon>
                    </small>
                </span>
            </span>
            <span class="fill-remaining-space"></span>
            <span>
                <button
                    mat-stroked-button
                    (click)="saveChanges()"
                    [disabled]="isSaving || !this.form.valid"
                >
                    <fa-icon [icon]="icons.save"></fa-icon> Save
                </button>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
</div>

<dx-validation-group #dxvg>
    <div class="container mt-1" [formGroup]="form">
        <div class="row">
            <div class="col-12 col-lg-4">
                <dx-select-box
                    #consigneeSelectBox
                    [stylingMode]="dxMatStyle"
                    [class.bds-mat-dense-form-field]="true"
                    [class.dx-mat-form-field-floatLabel-always]="matFloatLabel === 'always'"
                    class="bds-dx-custom-material flex-fill"
                    formControlName="customerNo"
                    [dataSource]="customerFactDxLookupSource"
                    fieldTemplate="inputField"
                    itemTemplate="item"
                    [valueExpr]="customerFactDxLookupValueExpr"
                    [searchExpr]="customerFactDxLookupSearchExpr"
                    [(selectedItem)]="selectedCustomer"
                    [searchEnabled]="true"
                    label="Consignee Number"
                    validationMessageMode="always"
                    [isValid]="form.controls.customerNo.valid"
                >
                    <dxi-button
                        name="addConsignee"
                        [options]="consigneeAddInlineButtonOptions"
                    ></dxi-button>
                    <dxi-button
                        *ngIf="selectedCustomer"
                        name="editConsignee"
                        [options]="consigneeEditInlineButtonOptions"
                    ></dxi-button>
                    <dxi-button
                        name="searchConsignee"
                        [options]="consigneeSearchInlineButtonOptions"
                    ></dxi-button>
                    <dxi-button name="dropDown"></dxi-button>
                    <div *dxTemplate="let data of 'item'">
                        <span>{{ customerFactDxLookupDisplayExpr(data) }}</span>
                    </div>
                    <div *dxTemplate="let data of 'inputField'">
                        <dx-text-box
                            style="display: inline-block"
                            width="100%"
                            [value]="customerFactDxLookupDisplayExpr(data)"
                            label="Consignee"
                        ></dx-text-box>
                    </div>
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="Consignee is required."
                        ></dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>

            <div class="col-12 col-lg-4">
                <mat-form-field
                    floatLabel="always"
                    class="w-100"
                    appearance="fill"
                    [class.highlight-dirty]="false"
                    [class.bds-mat-dense-form-field]="true"
                >
                    <mat-label> Consignee Name </mat-label>

                    <input
                        matInput
                        tabindex="-1"
                        [value]="selectedCustomer?.customerName"
                        [disabled]="true"
                    />
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-4">
                <bds-splc-select
                    formControlName="originSplc"
                    label=" Origin SPLC"
                    [displayValue]="displayValue"
                    (selected)="setOriginGeography($event)"
                    [disabled]="false"
                    [useErpcOrigin]="true"
                    [enableSearchPopup]="true"
                >
                    <mat-error *ngIf="form.controls.originSplc.invalid"
                        >Origin SPLC is required</mat-error
                    >
                </bds-splc-select>
            </div>

            <div class="col-12 col-lg-4">
                <div class="d-flex flex-row">
                    <div class="flex-grow-1">
                        <dx-select-box
                            #routeCodeSelectBox
                            [stylingMode]="dxMatStyle"
                            [class.bds-mat-dense-form-field]="false"
                            [class.dx-mat-form-field-floatLabel-always]="matFloatLabel === 'always'"
                            class="bds-dx-custom-material flex-fill"
                            formControlName="routeCode"
                            [dataSource]="routeCodeDxLookupSource"
                            fieldTemplate="inputField"
                            itemTemplate="item"
                            [valueExpr]="routeCodeDxLookupValueExpr"
                            [(selectedItem)]="selectedRoute"
                            [searchEnabled]="true"
                            [searchExpr]="routeCodeDxLookupSearchExpr"
                            label="Route"
                            validationMessageMode="always"
                            [isValid]="form.controls.routeCode.valid"
                            (onSelectionChanged)="onRouteSelectionChanged($event)"
                            [disabled]="!this.form.controls.originSplc.value"
                        >
                            <dxi-button
                                name="addRoute"
                                [options]="routeAddInlineButtonOptions"
                            ></dxi-button>
                            <dxi-button
                                *ngIf="selectedRoute"
                                name="editRoute"
                                [options]="routeEditInlineButtonOptions"
                            ></dxi-button>
                            <dxi-button
                                name="searchConsignee"
                                [options]="routeCodeSearchInlineButtonOptions"
                            ></dxi-button>
                            <dxi-button name="dropDown"></dxi-button>
                            <div *dxTemplate="let data of 'item'">
                                <span>{{ routeCodeDxLookupDisplayExpr(data) }}</span>
                            </div>
                            <div *dxTemplate="let data of 'inputField'">
                                <dx-text-box
                                    style="display: inline-block"
                                    width="100%"
                                    [value]="routeCodeDxLookupDisplayExpr(data)"
                                    label="Route"
                                ></dx-text-box>
                            </div>
                            <dx-validator>
                                <dxi-validation-rule
                                    type="required"
                                    message="Route Code is required."
                                ></dxi-validation-rule>
                            </dx-validator>
                        </dx-select-box>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-4">
                <mat-form-field
                    floatLabel="always"
                    class="w-100"
                    appearance="fill"
                    [class.highlight-dirty]="false"
                    [class.bds-mat-dense-form-field]="true"
                >
                    <mat-label> Route Description </mat-label>

                    <input
                        matInput
                        tabindex="-1"
                        [value]="selectedRoute?.routeDescription"
                        [disabled]="true"
                    />
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-4">
                <mat-form-field
                    floatLabel="always"
                    class="w-100"
                    appearance="fill"
                    [class.highlight-dirty]="false"
                    [class.bds-mat-dense-form-field]="true"
                >
                    <mat-label> Origin City </mat-label>

                    <input
                        matInput
                        tabindex="-1"
                        [value]="selectedOriginSplc?.city"
                        [disabled]="true"
                    />
                </mat-form-field>
            </div>

            <div class="col-12 col-lg-2">
                <mat-form-field
                    floatLabel="always"
                    class="w-100"
                    appearance="fill"
                    [class.highlight-dirty]="false"
                    [class.bds-mat-dense-form-field]="true"
                >
                    <mat-label> Origin State </mat-label>

                    <input
                        matInput
                        tabindex="-1"
                        [value]="selectedOriginSplc?.state"
                        [disabled]="true"
                    />
                </mat-form-field>
            </div>

            <div class="col-12 col-lg-4">
                <mat-form-field
                    floatLabel="always"
                    class="w-100"
                    appearance="fill"
                    [class.highlight-dirty]="false"
                    [class.bds-mat-dense-form-field]="true"
                >
                    <mat-label> Destination City </mat-label>

                    <input
                        matInput
                        tabindex="-1"
                        [value]="selectedRoute?.destinationCity"
                        [disabled]="true"
                    />
                </mat-form-field>
            </div>

            <div class="col-12 col-lg-2">
                <mat-form-field
                    floatLabel="always"
                    class="w-100"
                    appearance="fill"
                    [class.highlight-dirty]="false"
                    [class.bds-mat-dense-form-field]="true"
                >
                    <mat-label> Destination State </mat-label>

                    <input
                        matInput
                        tabindex="-1"
                        [value]="selectedRoute?.destinationState"
                        [disabled]="true"
                    />
                </mat-form-field>
            </div>
        </div>
    </div>
</dx-validation-group>
