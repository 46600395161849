import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BourqueCoreModule } from '@bds/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DxDateBoxModule } from 'devextreme-angular';
import { BdsHotListDetailsDialogComponent } from '../bds-hot-list-details-dialog/bds-hot-list-details-dialog.component';
import { BdsHotListDetailsComponent } from '../bds-hot-list-details/bds-hot-list-details.component';
import { BdsHotlistFormItemComponent } from '../bds-hotlist-form-item/bds-hotlist-form-item.component';
import { BdsHotlistFormComponent } from '../bds-hotlist-form/bds-hotlist-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BdsDateTimePickerModule } from '@bds/smart-components';
import { ZeroPadModule } from '@bds/components';

@NgModule({
    declarations: [
        BdsHotListDetailsComponent,
        BdsHotlistFormComponent,
        BdsHotListDetailsDialogComponent,
        BdsHotlistFormItemComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatCheckboxModule,
        MatToolbarModule,
        FontAwesomeModule,
        MatButtonModule,
        MatDialogModule,
        DxDateBoxModule,
        BourqueCoreModule,
        MatSelectModule,
        MatExpansionModule,
        MatSnackBarModule,
        BdsDateTimePickerModule,
        ZeroPadModule,
    ],
    exports: [
        BdsHotListDetailsComponent,
        BdsHotlistFormComponent,
        BdsHotListDetailsDialogComponent,
    ],
})
export class BdsHotListSharedModule {}
