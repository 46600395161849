import { PageService } from '@bds/core';
import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './recipient-screen.component.html',
    styleUrls: ['./recipient-screen.component.scss'],
})
export class RecipientScreenComponent implements OnInit {
    constructor(public pageService: PageService) {
        pageService.setHeader('Recipients');
    }

    ngOnInit() {}
}
