import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';
import { RtTripRefFieldDef } from '@bds/railtrac-models';

@Injectable({
    providedIn: 'root',
})
export class RtTripRefFieldDefService {
    controllerName: string = 'rtTripRefFieldsDef';

    constructor(
        private http: HttpClient,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    getReferenceFieldDefs(): Observable<RtTripRefFieldDef[]> {
        const expandUrl = '?$expand=rtTripFieldValues';
        return this.http
            .get<RtTripRefFieldDef[]>(`${this.odatabaseUrl}${this.controllerName}${expandUrl}`)
            .pipe(
                map((data: any) => data.value),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}
