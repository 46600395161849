import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RtCustomerAliasDialogComponent } from './rt-customer-alias-dialog/rt-customer-alias-dialog.component';
import { BourqueCoreModule } from '@bds/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [RtCustomerAliasDialogComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        BourqueCoreModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatAutocompleteModule,
    ],
    exports: [RtCustomerAliasDialogComponent],
})
export class RtCustomerAliasModule {}
