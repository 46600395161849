<dx-data-grid
    id="bdsReportGrid"
    [dataSource]="gridSource"
    height="80vh"
    minHeight="200px"
    dataRowTemplate="dataRowTemplateName"
>
    <dxi-column dataField="cust_no" [groupIndex]="0" groupCellTemplate="groupTemplate"></dxi-column>
    <dxi-column dataField="cust_name" visible="false" [groupIndex]="1"></dxi-column>
    <dxi-column caption="CSR" dataField="csr_no" visible="false" [groupIndex]="2"></dxi-column>
    <dxi-column
        visible="false"
        caption="car_status"
        dataField="car_status"
        [groupIndex]="3"
    ></dxi-column>
    <dxi-column caption="Status"></dxi-column>
    <dxi-column caption="Car ID"></dxi-column>
    <dxi-column caption="Ship Date"></dxi-column>
    <dxi-column caption="Origin"></dxi-column>
    <dxi-column caption="Order No."></dxi-column>
    <dxi-column caption="L/E"></dxi-column>
    <dxi-column caption="SC"></dxi-column>
    <dxi-column caption="Location"></dxi-column>
    <dxi-column caption="CLM Date"></dxi-column>
    <dxi-column caption="Road"></dxi-column>
    <dxi-column caption="ETA"></dxi-column>
    <dxi-column caption="Request Delivery Date"></dxi-column>
    <dxi-column caption="Var"></dxi-column>
    <dxi-column caption="CLM Age (days)"></dxi-column>
    <dxi-column caption="Commodity"></dxi-column>
    <dxi-column caption="Comments"></dxi-column>
    <dxi-column caption="Sales Rep."></dxi-column>
    <dxi-column caption="Cust PO No."></dxi-column>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true"> </dxo-grouping>
    <div *dxTemplate="let item of 'groupTemplate'">
        <div>
            <div>
                <span>Consignee No.: </span>
                <span>{{ item.data.items[0].items[0].items[0].items[0].cust_no }}</span>
            </div>
            <div>
                <span>Consignee Name: </span>
                <span>{{ item.data.items[0].items[0].items[0].items[0].cust_name }}</span>
            </div>
            <div>
                <span>Care of Name: </span>
                <span>{{ item.data.items[0].items[0].items[0].items[0].care_of_name }}</span>
            </div>
            <div>
                <span>Destination: </span>
                <span
                    >{{ item.data.items[0].items[0].items[0].items[0].dest_city }}
                    {{ item.data.items[0].items[0].items[0].items[0].dest_state }}</span
                >
            </div>
            <div>
                <span>CSR: </span>
                <span>{{ item.data.items[0].items[0].items[0].items[0].csr_no }}</span>
            </div>
        </div>
        <div>
            <div>
                <span>Contact: </span>
                <span
                    >{{ item.data.items[0].items[0].items[0].items[0].cust_fname }}
                    {{ item.data.items[0].items[0].items[0].items[0].cust_lname }}</span
                >
            </div>
            <div>
                <span>Phone: </span>
                <span>{{ item.data.items[0].items[0].items[0].items[0].cust_tele }}</span>
            </div>
            <div>
                <span>Fax: </span>
                <span>{{ item.data.items[0].items[0].items[0].items[0].cust_fax }}</span>
            </div>
            <div>
                <span>Email: </span
                ><span>{{ item.data.items[0].items[0].items[0].items[0].cust_email }}</span>
            </div>
        </div>
    </div>
    <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
        <tr class="second-row">
            <td>{{ item.data.car_status }}</td>
            <td>{{ item.data.car_init }} {{ item.data.car_no }}</td>
            <td>{{ item.data.ship_date_time }}</td>
            <td>{{ item.data.orig_city_state }}</td>
            <td>{{ item.data.trip_no }}</td>
            <td>{{ item.data.le }}</td>
            <td>{{ item.data.sight_code }}</td>
            <td>{{ item.data.loc_city_state }}</td>
            <td>{{ item.data.clm_date_time }}</td>
            <td>{{ item.data.road }}</td>
            <td>{{ item.data.orig_eda_eta_cust }}</td>
            <td>{{ item.data.rqst_dlv_date_time }}</td>
            <td>{{ item.data | CalculateVariance }}</td>
            <td>{{ item.data | CalculateCLMAge }}</td>
            <td>{{ item.data.comm_name }}</td>
            <td>{{ item.data.comments }}</td>
            <td>{{ item.data.sales_rep }}</td>
            <td>{{ item.data.cust_po_no }}</td>
        </tr>
        <tr class="third-row">
            <td colspan="5"><b>Exp Comments</b> {{ item.data.comments }}</td>
            <td colspan="5"><b>Ship Comments</b> {{ item.data.last_comments }}</td>
        </tr>
    </ng-container>
</dx-data-grid>
