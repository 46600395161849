<div class="row">
    <div class="col-12">
        <bds-destination-criteria
            [customerNumber]="customerNumber"
            [locationCity]="destinationCity"
            [locationState]="destinationState"
            [latestClm]="latestClm"
        >
        </bds-destination-criteria>
    </div>
    <div class="col-12">
        <bds-origin-criteria
            [locationCity]="originCity"
            [locationState]="originState"
            [latestClm]="latestClm"
        >
        </bds-origin-criteria>
    </div>
</div>
