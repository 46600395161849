import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtCustomerTransitMode implements From<RtCustomerTransitMode> {
    constructor(
        public transitMode?: string,
        public description?: string,
        public railYorn?: boolean,
        public truckYorn?: boolean,
        public internationalYorn?: boolean,
        public updateDateTime?: Date,
        public userId?: string,
    ) {}
    from(transitMode: RtCustomerTransitMode): RtCustomerTransitMode {
        Object.keys(transitMode).forEach((k) => (this[k] = transitMode[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtCustomerTransitMode>('transitMode'), server: 'transitMode' },
    { client: nameof<RtCustomerTransitMode>('description'), server: 'description' },
    { client: nameof<RtCustomerTransitMode>('railYorn'), server: 'railYorn' },
    { client: nameof<RtCustomerTransitMode>('truckYorn'), server: 'truckYorn' },
    { client: nameof<RtCustomerTransitMode>('internationalYorn'), server: 'internationalYorn' },
    { client: nameof<RtCustomerTransitMode>('updateDateTime'), server: 'updateDateTime' },
    { client: nameof<RtCustomerTransitMode>('userId'), server: 'userId' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCustomerTransitModeAdapter extends TwoWayAdapter<RtCustomerTransitMode> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtCustomerTransitModeMetadata implements FormGroupDescription {
    constructor() {}

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<RtCustomerTransitMode>('transitMode'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(5)]),
        );
        formGroup.addControl(
            nameof<RtCustomerTransitMode>('description'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(30)]),
        );
        formGroup.addControl(nameof<RtCustomerTransitMode>('railYorn'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCustomerTransitMode>('truckYorn'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCustomerTransitMode>('internationalYorn'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtCustomerTransitMode>('updateDateTime'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCustomerTransitMode>('userId'),
            new UntypedFormControl('', [Validators.maxLength(30)]),
        );

        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
