import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnInit,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    RtRouteParticipatingRailroadDialogOneOptions,
    BdsRouteParticipatingRailroadDialogOneComponent,
} from '../bds-route-participating-railroad-dialog-one/bds-route-participating-railroad-dialog-one.component';
import { BdsRouteParticipatingRailroadService } from '../../services/bds-route-participating-railroad.service';
import { BdsDialogConfirmComponent, BdsDialogConfirmModel } from '@bds/components';
import { RtRailroadService, RtRouteService } from '@bds/data-access';
import { take } from 'rxjs/operators';

import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import { forkJoin } from 'rxjs';

import { RtRouteParticipatingRailroad } from '@bds/railtrac-models';
import { BdsRouteParticipatingRailroadDialogTwoComponent } from '../bds-route-participating-railroad-dialog-two/bds-route-participating-railroad-dialog-two.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'rt-route-participating-railroad',
    templateUrl: './bds-route-participating-railroad.component.html',
    styleUrls: ['./bds-route-participating-railroad.component.css'],
})
export class BdsRouteParticipatingRailroadComponent implements OnInit, OnChanges {
    @ViewChild('bdsRouteRailroadGrid') grid: DxDataGridComponent;
    @Input() readOnly = false;
    @Input() fromRailroad = false;
    @Output() change: EventEmitter<void> = new EventEmitter();

    dataSource: DataSource;
    displayColumns: string[];
    selectedRowKeys: Array<number> = [];
    private _routeCode = '';
    private _railroad = '';

    constructor(
        private participatingRailroadService: BdsRouteParticipatingRailroadService,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
        public railroadService: RtRailroadService,
        public routeCodeService: RtRouteService,
    ) {
        this.dataSource = new DataSource({
            store: this.participatingRailroadService.getODataStore(),
            paginate: true,
            pageSize: 10,
            expand: ['rtRrFact'],
        });
    }

    @Input() set routeCode(value: string) {
        this._routeCode = value;
        this.dataSource.filter(['routeCode', '=', value]);
    }

    @Input() set railroad(value: string) {
        this._railroad = value;
        this.dataSource.filter(['railroad', '=', value]);
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {}

    addNewRecord(): void {
        var dialogRef;
        if (this.fromRailroad) {
            dialogRef = this.addRailroadToRoute();
        } else {
            dialogRef = this.addRouteToRailroad();
        }

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                //result.routeCode = this._routeCode;
                this.participatingRailroadService
                    .create(result)
                    .pipe(take(1))
                    .subscribe((s) => {
                        this.change.emit();
                        this.snackbar.open('Participating Railroad added', 'Ok');
                        void this.grid.instance.refresh();
                    });
            }
        });
    }

    addRailroadToRoute() {
        const road = null;
        let x: RtRouteParticipatingRailroad = new RtRouteParticipatingRailroad(
            0,
            this._routeCode,
            road,
        );
        const y: RtRouteParticipatingRailroadDialogOneOptions = {
            item: x,
        };

        const dialogRef = this.dialog.open(BdsRouteParticipatingRailroadDialogOneComponent, {
            width: '600px',
            data: y,
        });

        return dialogRef;

        // dialogRef.afterClosed().subscribe((result) => {
        //     x = result;
        //     if (result) {
        //         result.routeCode = this._routeCode;
        //         this.participatingRailroadService
        //             .create(result)
        //             .pipe(take(1))
        //             .subscribe((s) => {
        //                 this.change.emit();
        //                 this.snackbar.open('Participating Railroad added', 'Ok');
        //                 void this.grid.instance.refresh();
        //             });
        //     }
        // });
    }

    addRouteToRailroad() {
        const route = null;
        let x: RtRouteParticipatingRailroad = new RtRouteParticipatingRailroad(
            0,
            route,
            this._railroad,
        );
        const y: RtRouteParticipatingRailroadDialogOneOptions = {
            item: x,
        };

        const dialogRef = this.dialog.open(BdsRouteParticipatingRailroadDialogTwoComponent, {
            width: '600px',
            data: y,
        });
        return dialogRef;
    }

    onRemoveRailRoad(): void {
        const confirmInfo: BdsDialogConfirmModel = {
            content: `Are you sure you want to delete the participating railroad?`,
            actionText: 'Delete',
        };

        const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
            data: confirmInfo,
        });

        dialogRef.beforeClosed().subscribe((obs) => {
            if (obs) {
                const obsRes = this.selectedRowKeys.map((key) =>
                    this.participatingRailroadService.deleteByOrmID(key),
                );

                forkJoin(obsRes).subscribe((j) => {
                    const msg = 'Railroad deleted successfully';
                    this.snackbar.open(msg, 'Ok', { duration: 3000 });
                    void this.grid.instance.refresh();
                });
            }
        });
    }
}
