import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rt-railtrac-trip-detention',
    templateUrl: './railtrac-trip-detention.component.html',
    styleUrls: ['./railtrac-trip-detention.component.scss'],
})
export class RailtracTripDetentionComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
