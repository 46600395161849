<h4 mat-dialog-title>{{ data?.title ?? 'Select SPLC' }}</h4>
<div mat-dialog-content>
    <dx-data-grid
        bds-grid
        #dataGrid
        id="bdsErpcOriginSearchGrid"
        [dataSource]="dataSource"
        height="35vh"
        minHeight="300px"
        [selectedRowKeys]="selectedRowKeys"
    >
    </dx-data-grid>
</div>
<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" cdkFocusInitial mat-dialog-close>
        Cancel
    </button>
    <button mat-button class="text-uppercase" color="accent" (click)="onSelectClick()">
        Select
    </button>
</div>
