import { From, IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';

export class RtRailroadFact implements From<RtRailroadFact> {
    constructor(
        public rrScac?: string,
        public rrName?: string,
        public rrTele?: string,
        public isEdi?: string,
        public ediTransType?: string,
        public ediOrigSwitchJct?: string,
        public ediDestSwitchJct?: string,
    ) {}
    from(trip: RtRailroadFact): RtRailroadFact {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtRailroadFact>('rrScac'), server: 'rrScac' },
    { client: nameof<RtRailroadFact>('rrName'), server: 'rrName' },
    { client: nameof<RtRailroadFact>('rrTele'), server: 'rrTele' },
    { client: nameof<RtRailroadFact>('isEdi'), server: 'isEdi' },
    { client: nameof<RtRailroadFact>('ediTransType'), server: 'ediTransType' },
    { client: nameof<RtRailroadFact>('ediOrigSwitchJct'), server: 'ediOrigSwitchJct' },
    { client: nameof<RtRailroadFact>('ediDestSwitchJct'), server: 'ediDestSwitchJct' },
];

@Injectable({
    providedIn: 'root',
})
export class RtRailroadFactAdapter extends TwoWayAdapter<RtRailroadFact> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtRailroadFactMetadata implements /*GridColumnDescription, */ FormGroupDescription {
    constructor() {}

    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtRailroadFact>('rrScac'),
                dataType: 'string',
                caption: 'rrscac',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRailroadFact>('rrName'),
                dataType: 'string',
                caption: 'rrName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRailroadFact>('rrTele'),
                dataType: 'string',
                caption: 'rrTele',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRailroadFact>('isEdi'),
                dataType: 'string',
                caption: 'isEdi',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRailroadFact>('ediTransType'),
                dataType: 'string',
                caption: 'ediTransType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRailroadFact>('ediOrigSwitchJct'),
                dataType: 'string',
                caption: 'ediOrigSwitchJct',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtRailroadFact>('ediDestSwitchJct'),
                dataType: 'string',
                caption: 'ediDestSwitchJct',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }

    getFormGroup(obj?): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<RtRailroadFact>('rrScac'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(4)]),
        );
        formGroup.addControl(
            nameof<RtRailroadFact>('rrName'),
            new UntypedFormControl('', [Validators.maxLength(50)]),
        );
        formGroup.addControl(nameof<RtRailroadFact>('rrTele'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRailroadFact>('isEdi'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRailroadFact>('ediTransType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRailroadFact>('ediOrigSwitchJct'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtRailroadFact>('ediDestSwitchJct'), new UntypedFormControl(''));

        if (!!obj) {
            formGroup.setValue(obj);
        }

        return formGroup;
    }
}
