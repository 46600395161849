<div class="col-12">
    <div class="row">
        <h5 class="col-2">Railroad</h5>
        <h5 class="col-1">Rule 260 Code</h5>
        <h5 class="col-1">ERPC City</h5>
        <h5 class="col-1">ERPC State</h5>
        <h5 class="col-1">Junction SPLC</h5>
        <h5 class="col-1">Contract No.</h5>
        <h5 class="col-1">Benchmark Hours</h5>
        <h5 class="col-1">Segment Miles</h5>
        <h5 class="col-1">Weight Limit</h5>
        <h5 class="col-1">Active Status</h5>
        <h5 class="col-1">
            <div class="buttons">
                <button mat-stroked-button (click)="addForm()">
                    <fa-icon class="add-icon" [icon]="iconPlus"></fa-icon>
                </button>
            </div>
        </h5>
    </div>
    <div class="row">
        <div class="col-2 px-1 mb-3">
            <bds-railroad-select
                label="Origin Switch"
                [formControl]="routeCodeFormService.originSwitch"
            ></bds-railroad-select>
        </div>
    </div>
    <div
        class="row"
        *ngFor="let form of formArray.controls; let i = index; let first = first; let last = last"
    >
        <div class="col-2 px-1">
            <bds-railroad-select
                *ngIf="!first"
                [formControl]="form.controls.railroad"
                (selected)="setDefaultSegmentMiles(i, form)"
            ></bds-railroad-select>
            <bds-railroad-select
                *ngIf="first"
                [formControl]="form.controls.railroad"
                (selected)="setRoad($event); setDefaultSegmentMiles(i, form)"
            ></bds-railroad-select>
        </div>
        <div class="col-1 px-1">
            <bds-splc-select
                [formControl]="form.controls.endSplc"
                [displayValue]="displayValue"
                [searchDisplay]="searchValue"
                (selected)="setERPCValues($event, form, i); setDefaultSegmentMiles(i, form)"
                [splcDisplayExpr]="['rule260']"
                [filter]="[
                    ['!', ['rule260', '=', null]],
                    ['!', ['rule260', '=', '']]
                ]"
                [enableSearchPopup]="true"
            ></bds-splc-select>
        </div>
        <div class="col-1 px-1">
            <mat-form-field
                floatLabel="always"
                class="w-100 bds-mat-dense-form-field"
                appearance="fill"
            >
                <input matInput tabindex="-1" bdsShoutcase [formControl]="form.controls.erpcCity" />
            </mat-form-field>
        </div>
        <div class="col-1 px-1">
            <mat-form-field
                floatLabel="always"
                class="w-100 bds-mat-dense-form-field"
                appearance="fill"
            >
                <input
                    matInput
                    tabindex="-1"
                    bdsShoutcase
                    [formControl]="form.controls.erpcState"
                />
            </mat-form-field>
        </div>
        <div class="col-1 px-1">
            <mat-form-field
                floatLabel="always"
                class="w-100 bds-mat-dense-form-field"
                appearance="fill"
            >
                <input
                    matInput
                    tabindex="-1"
                    bdsShoutcase
                    [value]="form.controls.endSplc?.value"
                    [disabled]="true"
                />
            </mat-form-field>
        </div>
        <div class="col-1 px-1">
            <mat-form-field
                floatLabel="always"
                class="w-100 bds-mat-dense-form-field"
                appearance="fill"
            >
                <input
                    matInput
                    tabindex="-1"
                    bdsShoutcase
                    [formControl]="form.controls.contractNo"
                />
            </mat-form-field>
        </div>
        <div class="col-1 px-1">
            <mat-form-field
                floatLabel="always"
                class="w-100 bds-mat-dense-form-field"
                appearance="fill"
            >
                <input
                    matInput
                    tabindex="-1"
                    bdsShoutcase
                    [formControl]="form.controls.benchmarkHours"
                />
            </mat-form-field>
        </div>
        <div class="col-1 px-1">
            <mat-form-field
                floatLabel="always"
                class="w-100 bds-mat-dense-form-field"
                appearance="fill"
            >
                <input
                    matInput
                    tabindex="-1"
                    bdsShoutcase
                    [formControl]="form.controls.segmentMiles"
                />
            </mat-form-field>
        </div>
        <div class="col-1 px-1">
            <mat-form-field
                floatLabel="always"
                class="w-100 bds-mat-dense-form-field"
                appearance="fill"
            >
                <input
                    matInput
                    tabindex="-1"
                    bdsShoutcase
                    [formControl]="form.controls.weightLimit"
                />
            </mat-form-field>
        </div>
        <div class="col-1 px-1">
            <mat-form-field
                floatLabel="always"
                class="w-100 bds-mat-dense-form-field"
                appearance="fill"
            >
                <input
                    matInput
                    tabindex="-1"
                    bdsShoutcase
                    [formControl]="form.controls.activeStatus"
                />
            </mat-form-field>
        </div>
        <div class="col-1 px-1">
            <button *ngIf="!first" mat-icon-button (click)="removeForm(i)">
                <fa-icon class="remove-icon" [icon]="iconExpire"></fa-icon>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-2 px-1">
            <bds-railroad-select
                label="Delivery Switch"
                [formControl]="routeCodeFormService.deliverySwitch"
            ></bds-railroad-select>
        </div>
    </div>
</div>
