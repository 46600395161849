import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

export const CAR_INITIALS_LENGTH = new InjectionToken<number>('CAR_INITIALS_LENGTH');
export const CAR_NUMBER_LENGTH = new InjectionToken<number>('CAR_NUMBER_LENGTH');

@Injectable({
    providedIn: 'root',
})
export class CarIdTransformService {
    constructor(
        @Optional() @Inject('CAR_INITIALS_LENGTH') private carInitialsLength?: number,
        @Optional() @Inject('CAR_NUMBER_LENGTH') private carNumberLength?: number,
    ) {
        if (carInitialsLength == null || isNaN(carInitialsLength)) {
            carInitialsLength = 4;
        }
        if (carNumberLength == null || isNaN(carNumberLength)) {
            carNumberLength = 6;
        }
        this.carInitialsLength = carInitialsLength;
        this.carNumberLength = carNumberLength;
    }

    formatCarId(carInit: string, carNo: string): string {
        carInit = (carInit.toUpperCase() + '    ').slice(0, this.carInitialsLength);
        carNo = `${'0'.repeat(this.carNumberLength)}${carNo}`.slice(0 - this.carNumberLength);
        return carInit + carNo;
    }

    parseCarId(carId: string): { carInit: string; carNo: string } {
        const startNo: number = carId.search(/\d/);
        const carInit: string = (carId.toUpperCase().slice(0, startNo) + '    ').slice(
            0,
            this.carInitialsLength,
        );
        const carNo: string = `${'0'.repeat(this.carNumberLength)}${carId.slice(startNo)}`.slice(
            0 - this.carNumberLength,
        );

        return {
            carInit: carInit,
            carNo: carNo,
        };
    }

    parsePasteData(data: string): string[] {
        const rows: string[] = data.split(/[\n\r]+/).filter((f: string) => f.length > 0);
        const items: string[] = rows.map((r) => {
            const duplex = r.split(/\t/).map((d) => d.trim());
            if (duplex.length === 2) {
                duplex[1] = `${'0'.repeat(this.carNumberLength)}${duplex[1]}`.slice(
                    0 - this.carNumberLength,
                );
            }
            return duplex.join('');
        });
        return items;
    }
}
