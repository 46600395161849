import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDropDownValues } from '@bds/models';
import { FormlyDateFieldHelpersService, FormlyNumericFieldHelpersService } from '@bds/helpers';
import { EquipmentMechField } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class FormlyFieldCreatorService {
    constructor(
        private numericFieldHelperService: FormlyNumericFieldHelpersService,
        private dateFieldHelperService: FormlyDateFieldHelpersService,
    ) {}

    createAlphnumericFormField(field: EquipmentMechField): FormlyFieldConfig {
        const formField: FormlyFieldConfig = {
            key: field.mechFieldName,
            type: 'bds-input',
            className: 'col-6',
            templateOptions: {
                label: field.displayName,
                appearance: 'fill',
                floatLabel: 'always',
            },
            expressionProperties: {},
        };

        if (field.dataType === 'N') {
            formField.templateOptions.type = 'number';

            // Set the decimal places
            formField.templateOptions.step = this.numericFieldHelperService.calculateStep(
                field.fractionDigits,
            );

            // Set the max
            const maxNum: number = this.numericFieldHelperService.calculateMax(
                field.totalDigits,
                field.fractionDigits,
            );
            if (maxNum) {
                formField.templateOptions.max = maxNum;
            }
        } else if (field.dataType === 'C') {
            formField.templateOptions.maxLength = 100;
        }

        return formField;
    }

    createDateFormGroup(
        field: EquipmentMechField,
        displayFormat: string,
        serializationFormat: string,
        click: (fieldName: string, fieldDescription: string, hasDueDate: boolean) => void,
        equipExists = true,
    ): FormlyFieldConfig {
        const formField: FormlyFieldConfig = {
            fieldGroupClassName: 'row',
            fieldGroup: [],
        };

        const hasDueDate: boolean = (field.repeatYorn || '').toUpperCase() === 'Y';

        let dateFieldClassName = 'col-11';
        if (!equipExists) {
            dateFieldClassName = 'col-12';
        }

        const editButton: FormlyFieldConfig = {
            className: 'col-1',
            type: 'bds-edit-button',
            templateOptions: {
                label: field.displayName,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onClick: ($event) => click(field.mechFieldName, field.displayName, hasDueDate),
            },
        };

        const dateField: FormlyFieldConfig = {
            key: field.mechFieldName + '.actual',
            type: 'bds-datebox',
            className: dateFieldClassName,
            templateOptions: {
                label: field.displayName,
                appearance: 'fill',
                floatLabel: 'always',
                displayFormat: displayFormat,
                serializationFormat: serializationFormat,
            },
            expressionProperties: {},
        };

        if (hasDueDate) {
            const dueHeader: FormlyFieldConfig = {
                className: 'col-2 bds-repeat-info',
                template: `<div class="text-right due-label">DUE</div>`,
            };

            const headerText: string = this.dateFieldHelperService.createRepeatIntervalText(
                field.repeatInterval,
                field.repeatIntervalUm,
            );
            const repeatHeader = {
                className: 'col-12',
                template: `<div class="bds-repeat-info text-right">${headerText}</div>`,
            };

            // TODO: Some styling based on alertPeriod
            const dueDateField: FormlyFieldConfig = {
                key: field.mechFieldName + '.next',
                type: 'bds-datebox',
                className: 'col-10',
                templateOptions: {
                    label: 'Due Date',
                    appearance: 'fill',
                    floatLabel: 'always',
                    displayFormat: displayFormat,
                    serializationFormat: serializationFormat,
                },
            };

            if (equipExists) {
                dateField.templateOptions.disabled = true;
                dueDateField.templateOptions.disabled = true;

                formField.fieldGroup = [
                    repeatHeader,
                    editButton,
                    dateField,
                    dueHeader,
                    dueDateField,
                ];
            } else {
                formField.fieldGroup = [repeatHeader, dateField, dueHeader, dueDateField];
            }
        } else {
            if (equipExists) {
                dateField.templateOptions.disabled = true;

                formField.fieldGroup = [editButton, dateField];
            } else {
                formField.fieldGroup = [dateField];
            }
        }

        return formField;
    }

    createSelectFormField(
        field: EquipmentMechField,
        ddValues: DynamicDropDownValues[],
    ): FormlyFieldConfig {
        const formField: FormlyFieldConfig = {
            key: field.mechFieldName,
            type: 'bds-select',
            className: 'col-6',
            templateOptions: {
                label: field.displayName,
                appearance: 'fill',
                floatLabel: 'always',
                options: ddValues || [],
                valueProp: 'valueCode',
                labelProp: 'valueDisplay',
            },
            expressionProperties: {},
        };

        return formField;
    }
}
