import { Component } from '@angular/core';
import { PageService } from '@bds/core';

@Component({
    selector: 'app-segment-miles-screen',
    template: ` <router-outlet></router-outlet>`,
})
export class SegmentMilesScreenComponent {
    constructor(private pageService: PageService) {
        this.pageService.setHeader('Segment Miles');
    }
}
