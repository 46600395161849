import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import {
    RtRouteParticipatingRailroad,
    RtRouteParticipatingRailroadAdapter,
} from '@bds/railtrac-models';
import ODataStore from 'devextreme/data/odata/store';
import { Observable } from 'rxjs';
import { catchError, map, retry, shareReplay } from 'rxjs/operators';

//@Injectable({ providedIn: 'root' })
// export abstract class RtRouteParticipatingRailroadServiceOptions {
//     apiUrl: string = `api/ParticipatingRailroad/`;
//     odataUrl: string = `odata/ParticipatingRailroad`;
//     restApiUrl: string = `api/ParticipatingRailroad/`;
// }

@Injectable({
    providedIn: 'root',
})
export class BdsRouteParticipatingRailroadService {
    controllerName = 'ParticipatingRailroad';
    constructor(
        private httpClient: HttpClient,
        //private options: RtRouteParticipatingRailroadServiceOptions,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private adapter: RtRouteParticipatingRailroadAdapter,
        public errorService: ApiErrorHandlerService,
    ) {}

    add(alias: RtRouteParticipatingRailroad): Observable<RtRouteParticipatingRailroad> {
        const svcCall = this.httpClient
            .post<RtRouteParticipatingRailroad>(`${this.apibaseUrl}${this.controllerName}`, alias)
            .pipe(
                shareReplay(),
                map((m) => this.adapter.adapt(m)),
                retry(1),
                catchError(this.errorService.handleError),
            );

        svcCall.subscribe((s) => {});
        return svcCall;
    }

    delete(id: number): Observable<boolean> {
        const svcCall = this.httpClient
            .delete<boolean>(`${this.apibaseUrl}${this.controllerName}/${id}`)
            .pipe(
                shareReplay(),
                map(() => true),
                retry(1),
                catchError(this.errorService.handleError),
            );

        svcCall.subscribe((s) => {});
        return svcCall;
    }

    get(): Observable<RtRouteParticipatingRailroad[]> {
        return this.httpClient
            .get<RtRouteParticipatingRailroad[]>(`${this.apibaseUrl}${this.controllerName}`)
            .pipe(
                map((m) => [...m.map((f) => this.adapter.adapt(f))]),
                retry(1),
                catchError(this.errorService.handleError),
            );
    }

    getById(id: number): Observable<RtRouteParticipatingRailroad> {
        return this.httpClient
            .get<RtRouteParticipatingRailroad>(`${this.apibaseUrl}${this.controllerName}/${id}`)
            .pipe(
                map((m) => this.adapter.adapt(m)),
                retry(1),
                catchError(this.errorService.handleError),
            );
    }

    getODataStore() {
        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: 'ormId',
        });
    }

    update(alias: RtRouteParticipatingRailroad): Observable<RtRouteParticipatingRailroad> {
        const svcCall = this.httpClient
            .put<RtRouteParticipatingRailroad>(
                `${this.apibaseUrl}${this.controllerName}/${alias.ormId}`,
                alias,
            )
            .pipe(shareReplay(), retry(1), catchError(this.errorService.handleError));

        svcCall.subscribe((s) => {});
        return svcCall;
    }

    create(railroad: RtRouteParticipatingRailroad): Observable<any> {
        return this.httpClient
            .post<RtRouteParticipatingRailroad>(
                `${this.apibaseUrl}${this.controllerName}`,
                this.adapter.toServer(railroad),
            )
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                shareReplay(),
                catchError((err) => this.errorService.handleError(err)),
            );
    }

    deleteByOrmID(id: number): Observable<RtRouteParticipatingRailroad> {
        return this.httpClient
            .delete<RtRouteParticipatingRailroad>(`${this.apibaseUrl}${this.controllerName}/${id}`)
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                catchError((err) => this.errorService.handleError(err)),
            );
    }
}
