import { Component, OnInit } from '@angular/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
  selector: 'lib-rt-day-out-to-consignee-report',
  templateUrl: './rt-day-out-to-consignee-report.component.html',
  styleUrls: ['./rt-day-out-to-consignee-report.component.css']
})
export class RtDayOutToConsigneeReportComponent implements OnInit {

    public gridId = 126;
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Days Out to Consignee Report';

  constructor() { }

  ngOnInit(): void {
  }

}
