import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtStateCode implements From<RtStateCode> {
    constructor(
        public code?: string,
        public description?: string,
        public longAbbr?: string,
        public countryCode?: string,
        public ediCountryCode?: string,
    ) {}
    from(trip: RtStateCode): RtStateCode {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtStateCode>('code'), server: 'code' },
    { client: nameof<RtStateCode>('description'), server: 'description' },
    { client: nameof<RtStateCode>('longAbbr'), server: 'longAbbr' },
    { client: nameof<RtStateCode>('countryCode'), server: 'countryCode' },
    { client: nameof<RtStateCode>('ediCountryCode'), server: 'ediCountryCode' },
];

@Injectable({
    providedIn: 'root',
})
export class RtStateCodeAdapter extends TwoWayAdapter<RtStateCode> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtStateCodeMetadata implements GridColumnDescription, FormGroupDescription {
    getColumns(defaultVisibility = true): IColumnDescription[] {
        const columns = [
            {
                dataField: nameof<RtStateCode>('code'),
                dataType: 'string',
                caption: 'code',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtStateCode>('description'),
                dataType: 'string',
                caption: 'description',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtStateCode>('longAbbr'),
                dataType: 'string',
                caption: 'longAbbr',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtStateCode>('countryCode'),
                dataType: 'string',
                caption: 'countryCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtStateCode>('ediCountryCode'),
                dataType: 'string',
                caption: 'ediCountryCode',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }

    getFormGroup(obj?): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtStateCode>('code'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtStateCode>('description'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtStateCode>('longAbbr'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtStateCode>('countryCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtStateCode>('ediCountryCode'), new UntypedFormControl(''));
        if (obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
