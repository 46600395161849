import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { nameof } from '@bds/core';
import { BdsPathCodeAdapter, BdsRoutePathCode } from '@bds/railtrac-models';
import ODataStore from 'devextreme/data/odata/store';

@Injectable({ providedIn: 'root' })
export class BdsPathCodeApiServiceOptions {
    apiUrl: string = `api/routePathCode/`;
    odataUrl: string = `odata/routePathCode`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsPathCodeService {
    constructor(
        private adapter: BdsPathCodeAdapter,
        private http: HttpClient,
        private options: BdsPathCodeApiServiceOptions,
    ) {}

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<BdsRoutePathCode>('pathCode'),
        ).server;

        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: keyField,
        });
    }
}
