import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RtDestinationAlias, RtDestinationAliasMetadata } from '@bds/railtrac-models';
import { nameof } from '@bds/core';

export class RtDestinationAliasDialogOptions {
    public item?: RtDestinationAlias;
}

@Component({
    templateUrl: './rt-destination-alias-dialog.component.html',
    styleUrls: ['./rt-destination-alias-dialog.component.scss'],
})
export class RtDestinationAliasDialogComponent implements OnInit {
    public destinationAlias: RtDestinationAlias;
    public destinationAliasForm: UntypedFormGroup;
    public title: string;
    public isInitiallyBlank: boolean;

    constructor(
        public dialogRef: MatDialogRef<RtDestinationAliasDialogComponent, RtDestinationAlias>,
        public metadata: RtDestinationAliasMetadata,
        @Inject(MAT_DIALOG_DATA) public data: RtDestinationAliasDialogOptions,
    ) {
        this.title = 'Create new alias';
        this.isInitiallyBlank =
            !data.item ||
            !data.item.clmCity ||
            !data.item.clmState ||
            !data.item.tripCity ||
            !data.item.tripState;
        this.destinationAlias = data.item || new RtDestinationAlias();
        this.destinationAliasForm = metadata.getFormGroup(this.destinationAlias);
    }

    ngOnInit() {}

    getErrorMessage(control: UntypedFormControl) {
        if (control.hasError('required')) {
            return 'Required';
        }

        return control.hasError('maxLength') ? 'Too long' : '';
    }
}
