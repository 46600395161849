import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { RtArrAtOrig, RtClmSightCode, RtStateCode } from '@bds/railtrac-models';
import { FormErrorHandlerService } from '@bds/helpers';

@Component({
    selector: 'bds-origin-criteria-details',
    templateUrl: './bds-origin-criteria-details.component.html',
    styleUrls: ['./bds-origin-criteria-details.component.scss'],
})
export class BdsOriginCriteriaDetailsComponent implements OnInit, OnChanges, OnDestroy {
    originCodeSource: DataSource;
    originCodeDisplayExpr: string[] = ['originCode', 'erpcOrigin', 'erpcState'];
    originForm: UntypedFormGroup;
    railroadSource: DataSource;
    railroadDisplayExpr: string[] = ['rrScac', 'rrName'];

    private ngUnsubscribe$: Subject<void> = new Subject<void>();

    @Input() cities: ODataStore;
    @Input() originCodeStore: ODataStore;
    @Input() originCriteria: RtArrAtOrig;
    @Input() isClone: boolean = false;
    @Input() isNew: boolean = true;
    @Input() railroadStore: ODataStore;
    @Input() editableOriginCode: boolean = true;
    @Input() sightCodes: RtClmSightCode[] = [];
    @Input() states: RtStateCode[] = [];

    // For form styles
    @Input() dense = true;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';

    @Output() originCriteriaChange: EventEmitter<{ value: RtArrAtOrig; isValid: boolean }> =
        new EventEmitter();

    get dxMatStyle(): string {
        switch (this.matStyle) {
            case 'fill':
                return 'filled';
            case 'outline':
                return 'outlined';
            case 'standard':
                return 'underlined';
            default:
                return 'underlined';
        }
    }

    constructor(private formErrorService: FormErrorHandlerService) {
        this.originForm = this.getFormGroup();
    }

    ngOnInit() {
        this.originForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            let isValid: boolean;

            if (this.originForm.errors || this.originForm.invalid) {
                isValid = false;
            } else {
                isValid = true;
            }

            this.originCriteriaChange.emit({
                value: this.originForm.getRawValue(),
                isValid: isValid,
            });
        });

        if (this.originCriteria) {
            if (this.originCriteria.railroad) {
                this.originCriteria.railroad = this.originCriteria.railroad.trim();
            }

            this.originForm.patchValue(this.originCriteria);
        }

        this.enableOrDisableFields();

        if (this.originCodeStore) {
            this.setUpOriginCodeOptions();
        }

        if (this.railroadStore) {
            this.setUpRailroadOptions();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.originCriteria && !changes.originCriteria.firstChange && this.originCriteria) {
            if (this.originCriteria.railroad) {
                this.originCriteria.railroad = this.originCriteria.railroad.trim();
            }

            this.originForm.patchValue(this.originCriteria);
            this.resetFields();
            this.enableOrDisableFields();
        }

        if (
            changes.originCodeStore &&
            !changes.originCodeStore.firstChange &&
            this.originCodeStore
        ) {
            this.setUpOriginCodeOptions();
        }

        if (changes.railroadStore && !changes.railroadStore.firstChange && this.railroadStore) {
            this.setUpRailroadOptions();
        }

        if (
            (changes.isNew && !changes.isNew.firstChange) ||
            (changes.isClone && !changes.isClone.firstChange) ||
            (changes.editableOriginCode && !changes.editableOriginCode.firstChange)
        ) {
            this.enableOrDisableFields();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    enableOrDisableFields() {
        if (this.isNew) {
            this.originForm.get(nameof<RtArrAtOrig>('railroad')).enable();
            this.originForm.get(nameof<RtArrAtOrig>('locationState')).enable();
        } else {
            this.originForm.get(nameof<RtArrAtOrig>('railroad')).disable();
            this.originForm.get(nameof<RtArrAtOrig>('locationState')).disable();
        }

        if (this.isNew && this.editableOriginCode) {
            this.originForm.get(nameof<RtArrAtOrig>('originCode')).enable();
        } else {
            this.originForm.get(nameof<RtArrAtOrig>('originCode')).disable();
        }

        if (this.isNew || this.isClone) {
            this.originForm.get(nameof<RtArrAtOrig>('sightCode')).enable();
        } else {
            this.originForm.get(nameof<RtArrAtOrig>('sightCode')).disable();
        }

        if (this.isClone) {
            this.originForm.get(nameof<RtArrAtOrig>('locationState')).enable();
            this.originForm.get(nameof<RtArrAtOrig>('locationCity')).enable();
            this.originForm.get(nameof<RtArrAtOrig>('railroad')).enable();
        }
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        return this.formErrorService.getFormError(formItem);
    }

    getFormGroup(): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<RtArrAtOrig>('originCode'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(5)]),
        );
        formGroup.addControl(
            nameof<RtArrAtOrig>('locationCity'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(9)]),
        );
        formGroup.addControl(
            nameof<RtArrAtOrig>('sightCode'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(1)]),
        );
        formGroup.addControl(
            nameof<RtArrAtOrig>('railroad'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(4)]),
        );
        formGroup.addControl(
            nameof<RtArrAtOrig>('locationState'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(2)]),
        );
        formGroup.addControl(
            nameof<RtArrAtOrig>('originDeliveryHours'),
            new UntypedFormControl('', [Validators.max(999), Validators.min(0)]),
        );
        formGroup.addControl(nameof<RtArrAtOrig>('ormId'), new UntypedFormControl(''));

        return formGroup;
    }

    resetFields() {
        this.originForm.get(nameof<RtArrAtOrig>('sightCode')).markAsUntouched();
        this.originForm.get(nameof<RtArrAtOrig>('railroad')).markAsUntouched();
        this.originForm.get(nameof<RtArrAtOrig>('locationCity')).markAsUntouched();
        this.originForm.get(nameof<RtArrAtOrig>('locationState')).markAsUntouched();
    }

    setUpOriginCodeOptions() {
        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        // TODO: Determine what these are
        this.originCodeSource = new DataSource({
            store: this.originCodeStore,
            paginate: true,
            pageSize: 100,
            searchExpr: this.originCodeDisplayExpr,
            select: ['originCode', 'erpcOrigin', 'erpcState'],
            sort: ['erpcOrigin'],
            key: 'originCode',
        });
    }

    setUpRailroadOptions() {
        // NOTE: These values MUST be the API field names and NOT the client field names
        // This is a requirement of the grid
        this.railroadSource = new DataSource({
            store: this.railroadStore,
            paginate: true,
            pageSize: 100,
            searchExpr: this.railroadDisplayExpr,
            select: ['rrScac', 'rrName'],
            sort: ['rrScac'],
            key: 'rrScac',
        });
    }
}
