import { PageService } from '@bds/core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { RtCityAlias, RtCityAliasAdapter } from '@bds/railtrac-models';
import {
    RtCityAliasService,
    RtCityAliasDialogComponent,
    RtCityAliasDialogOptions,
} from '@bds/railtrac';
import { MatDialog } from '@angular/material/dialog';
import { Observable, AsyncSubject, forkJoin } from 'rxjs';
import { faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { BdsDialogConfirmComponent, BdsDialogConfirmModel } from '@bds/components';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
    templateUrl: './city-alias-screen.component.html',
    styleUrls: ['./city-alias-screen.component.scss'],
})
export class CityAliasScreenComponent implements OnInit {
    public gridSource: DataSource | RtCityAlias[];
    public selectedRowKeys: Array<number | any> = [];

    public iconPlus = faPlus;
    public iconEdit = faPencilAlt;
    public iconDelete = faTrashAlt;

    public customButtons: any[] = [
        {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'edit',
                hint: 'Edit records',
                onClick: this.updateCityAliases.bind(this),
            },
        },
        {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                hint: 'Add a record',
                onClick: this.createCityAlias.bind(this),
            },
        },
        {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'trash',
                hint: 'Delete records',
                onClick: this.deleteCityAliases.bind(this),
            },
        },
    ];

    @ViewChild('bdsCityAliasGrid') grid: DxDataGridComponent;

    constructor(
        public service: RtCityAliasService,
        public adapter: RtCityAliasAdapter,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
        public pageService: PageService,
    ) {
        pageService.setHeader('City Aliases');
    }

    ngOnInit() {
        this.gridSource = this.service.getODataStore();
    }

    createCityAlias() {
        const data = new RtCityAliasDialogOptions();
        data.beforeCloseAction = (item: RtCityAlias) => this.createCityAliasCloseAction(item);
        data.title = 'Create new city alias';

        const dialogRef = this.dialog.open(RtCityAliasDialogComponent, {
            width: '550px',
            data: data,
        });
    }

    updateCityAliases() {
        const data = new RtCityAliasDialogOptions();
        data.beforeCloseAction = (items: RtCityAlias[]) => this.updateCityAliasesCloseAction(items);
        data.title = `Edit city alias${this.selectedRowKeys.length === 1 ? '' : 'es'}`;
        const rowData: RtCityAlias[] = [];
        this.grid.instance.getSelectedRowsData().forEach((itm) => {
            rowData.push(itm);
        });
        data.item = rowData;
        const dialogRef = this.dialog.open(RtCityAliasDialogComponent, {
            width: '550px',
            data: data,
        });
    }
    updateCityAlias(cityAlias: RtCityAlias) {
        const data = new RtCityAliasDialogOptions();
        data.item = cityAlias;
        data.beforeCloseAction = (item: RtCityAlias) => this.updateCityAliasCloseAction(item);
        data.title = 'Edit city alias';

        const dialogRef = this.dialog.open(RtCityAliasDialogComponent, {
            width: '550px',
            data: data,
        });
    }

    deleteCityAlias(cityAlias: RtCityAlias) {
        const confirmInfo: BdsDialogConfirmModel = {
            content: `Are you sure you want to delete the city alias
                ${cityAlias.aliasCity.trim()}, ${cityAlias.aliasState.trim()} to
                ${cityAlias.locCity.trim()}, ${cityAlias.locState.trim()}?`,
            actionText: 'Delete',
        };

        const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
            data: confirmInfo,
        });

        dialogRef.beforeClosed().subscribe((obs) => {
            if (!!obs) {
                this.deleteCityAliasCloseAction(cityAlias.ormId);
            }
        });
    }
    deleteCityAliases(e) {
        console.log('delete city aliases', e);
        if (this.selectedRowKeys.length === 1) {
            const rowData = this.grid.instance.getSelectedRowsData()[0] as RtCityAlias;
            this.deleteCityAlias(rowData);
        }
        if (this.selectedRowKeys.length > 1) {
            const confirmInfo: BdsDialogConfirmModel = {
                content: `Are you sure you want to delete ${this.selectedRowKeys.length} city aliases?`,
                actionText: 'Delete',
            };

            const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
                data: confirmInfo,
            });

            dialogRef.beforeClosed().subscribe((obs) => {
                if (!!obs) {
                    this.selectedRowKeys.forEach((key) => {
                        this.deleteCityAliasCloseAction(key);
                    });
                }
            });
        }
    }

    private createCityAliasCloseAction(item: RtCityAlias): Observable<boolean | string> {
        const subject = new AsyncSubject<boolean | string>();
        if (!!item) {
            const serverModel = this.adapter.toServer(item);
            this.service.add(serverModel).subscribe(
                (result) => {
                    subject.next(true);
                    subject.complete();
                    if (Array.isArray(this.gridSource)) {
                        this.gridSource.push(result);
                    } else {
                        this.gridSource.reload();
                    }
                },
                (err) => {
                    console.log('HTTP CLIENT ERROR', err);
                    this.snackbar.open('Unable to create alias.', 'OK', {
                        duration: 0,
                    });
                    subject.next(false);
                    subject.complete();
                },
            );
        }
        return subject.asObservable();
    }
    private updateCityAliasCloseAction(item: RtCityAlias): Observable<boolean | string> {
        const subject = new AsyncSubject<boolean | string>();
        if (!!item) {
            const serverModel = this.adapter.toServer(item);
            this.service.update(serverModel).subscribe(
                () => {
                    subject.next(true);
                    subject.complete();
                    if (Array.isArray(this.gridSource)) {
                        const idx = this.gridSource.findIndex((itm) => itm.ormId === item.ormId);
                        this.gridSource.splice(idx, 1, item);
                    } else {
                        this.gridSource.reload();
                    }
                },
                (err) => {
                    console.log('HTTP CLIENT ERROR', err);
                    this.snackbar.open('Unable to modify alias.', 'OK', {
                        duration: 0,
                    });
                    subject.next(false);
                    subject.complete();
                },
            );
        }
        return subject.asObservable();
    }
    private callUpdateServer(item: RtCityAlias): AsyncSubject<boolean | string> {
        const serverModel = this.adapter.toServer(item);
        const subject = new AsyncSubject<boolean | string>();
        this.service.update(serverModel).subscribe(
            () => {
                subject.next(true);
                subject.complete();
                if (Array.isArray(this.gridSource)) {
                    const idx = this.gridSource.findIndex((itm) => itm.ormId === item.ormId);
                    this.gridSource.splice(idx, 1, item);
                } else {
                    this.gridSource.reload();
                }
            },
            (err) => {
                console.log('HTTP CLIENT ERROR', err);
                this.snackbar.open('Unable to modify alias.', 'OK', {
                    duration: 0,
                });
                subject.next(false);
                subject.complete();
            },
        );
        return subject;
    }
    private updateCityAliasesCloseAction(items: RtCityAlias[]): Observable<(boolean | string)[]> {
        console.log('updateCityAliasesCloseAction items', items);
        const subjects: AsyncSubject<boolean | string>[] = [];
        if (!!items) {
            if (Array.isArray(items)) {
                items.forEach((item) => {
                    subjects.push(this.callUpdateServer(item));
                });
            } else {
                subjects.push(this.callUpdateServer(items));
            }
        }
        return forkJoin(subjects);
    }
    private deleteCityAliasCloseAction(item: number): Observable<boolean | string> {
        const subject = new AsyncSubject<boolean | string>();
        if (!!item) {
            this.service.delete(item).subscribe(
                (result) => {
                    subject.next(true);
                    subject.complete();
                    if (Array.isArray(this.gridSource)) {
                        const idx = this.gridSource.findIndex((itm) => itm.ormId === result.ormId);
                        this.gridSource.splice(idx, 1);
                    } else {
                        this.gridSource.reload();
                    }
                },
                (err) => {
                    console.log('HTTP CLIENT ERROR', err);
                    this.snackbar.open('Unable to delete alias.', 'OK', {
                        duration: 8000,
                    });
                    subject.next(false);
                    subject.complete();
                },
            );
        }
        return subject.asObservable();
    }
}
