import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import { EquipComponentCatgBridge, Equipment } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class EquipmentService {
    controllerName = 'Equipment';

    constructor(
        private http: HttpClient,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    create(equipment: Equipment): Observable<Equipment> {
        return this.http
            .post<Equipment>(`${this.apibaseUrl}${this.controllerName}`, equipment)
            .pipe(
                map((data: Equipment) => {
                    return data ? data : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getEquipmentComponentCategories(key: number): Observable<EquipComponentCatgBridge[]> {
        const expandUrl = '$expand=equipmentComponentCategories';
        const selectUrl = '$select=equipmentComponentCategories';
        return this.http
            .get<Equipment>(
                `${this.odatabaseUrl}${this.controllerName}(${key})?${selectUrl}&${expandUrl}`,
            )
            .pipe(
                map((data) => data.equipmentComponentCategories),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getEquipmentInitials(): Observable<string[]> {
        const ei = nameof<Equipment>('equipmentInit');
 
        //const selectUrl = `?$apply=groupby((${ei}))&$orderby=${ei}`;
        const selectUrl = `?$orderby=${ei}`;
        return this.getFieldFromOData(nameof<Equipment>('equipmentInit'), selectUrl);
    }

    getEquipmentNumbersForInitials(initials: string): Observable<string[]> {
        const ei = nameof<Equipment>('equipmentInit');
        const en = nameof<Equipment>('equipmentNo');
        //const selectUrl = `?$apply=filter(${ei} eq '${initials}')/groupby((${en}))&$orderby=${en}`;
        const selectUrl = `?$apply=filter(${ei} eq '${initials}')&$orderby=${en}`;
        return this.getFieldFromOData(nameof<Equipment>('equipmentNo'), selectUrl);
    }

    getODataStore(): ODataStore {
        const keyField = nameof<Equipment>('ormId');

        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: keyField,
        });
    }

    getOrmIdForEquipmentId(eqInitials: string, eqNumber: string): Observable<number> {
        const ei = nameof<Equipment>('equipmentInit');
        const en = nameof<Equipment>('equipmentNo');
        const selectUrl = `?$select=ormId&$apply=filter(${ei} eq '${eqInitials}' and ${en} eq '${eqNumber}')`;
        return this.getFieldFromOData<number>(nameof<Equipment>('ormId'), selectUrl);
    }

    getEquipmentInfo(eqInitials: string, eqNumber: string): Observable<Equipment[]>{
        const ei = nameof<Equipment>('equipmentInit');
        const en = nameof<Equipment>('equipmentNo');
        if (!eqInitials) { eqInitials = ''; }
        if (!eqNumber) { eqNumber = ''; }
        
        const filterUrl = `?$filter=(equipmentInit eq '${eqInitials
                .trim()
                .toUpperCase()}') and (equipmentNo eq '${eqNumber.trim().toUpperCase()}')`;
       
        return this.http
            .get<Equipment[]>(`${this.odatabaseUrl}${this.controllerName}${filterUrl}`)
            .pipe(
                map((data: any) => {
                    return data.value;
                })
            );           
    }

    read(key: number): Observable<Equipment> {
        return this.http.get<Equipment>(`${this.apibaseUrl}${this.controllerName}/${key}`).pipe(
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    update(key: number, equipment: Equipment): Observable<Equipment> {
        return this.http
            .put<Equipment>(`${this.apibaseUrl}${this.controllerName}/${key}`, equipment)
            .pipe(
                map((data: Equipment) => {
                    return data ? data : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    private getFieldFromOData<t>(fieldName: string, selectUrl: string): Observable<t> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.http.get<t[]>(`${this.odatabaseUrl}${this.controllerName}` + selectUrl).pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((data: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                const processData = !data.value ? data : data.value;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                return processData.map((m: { [x: string]: t }) => m[fieldName]);
            }),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }
}
