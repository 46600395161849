<div class="sticky-top mat-elevation-z6">
    <mat-toolbar class="trip-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <span>
                <!-- <fa-icon [icon]="iconFlag" [fixedWidth]="true" size="lg"></fa-icon> -->
                Off Route
            </span>
            <span class="fill-remaining-space"></span>
            <span>
                <button mat-stroked-button (click)="addParticipatingRailroad()">
                    <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
                    Add Participating Railroad
                </button>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<rt-jeopardized-shipment-reason-common
    [trip]="trip$ | async"
    [useExpansionPanel]="false"
    (saveClick)="onTripSaveClicked($event)"
    (popoutTrip)="onPopoutTripclicked($event)"
></rt-jeopardized-shipment-reason-common>
<ng-template #loading> Loading... </ng-template>
