import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'rt-diversion',
    templateUrl: './rt-diversion.component.html',
    styleUrls: ['./rt-diversion.component.scss'],
})
export class RtDiversionComponent implements OnChanges, OnDestroy {
    currentTripId$: BehaviorSubject<number> = new BehaviorSubject(0);

    @Input() tripId: number;
    @Output() diversionCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tripId && this.tripId) {
            this.currentTripId$.next(this.tripId);
        }
    }

    ngOnDestroy() {
        this.currentTripId$.complete();
    }

    getCurrentTripId(): Observable<number> {
        return this.currentTripId$.asObservable();
    }

    onDiversionCreated(): void {
        this.currentTripId$.next(0);
        setTimeout(() => this.currentTripId$.next(this.tripId));
        this.diversionCreated.emit(true);
    }
}
