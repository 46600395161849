import { Component, Inject } from '@angular/core';
import { take } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RtErpcOrigin } from '@bds/railtrac-models';
import { BdsErpcOriginService } from '@bds/data-access';
import { BdsErpcOriginGridDialogModel } from '../../models/bds-erpc-origin-grid-dialog.model';

@Component({
    selector: 'bds-erpc-origin-grid-dialog',
    templateUrl: './bds-erpc-origin-grid-dialog.component.html',
    styleUrls: ['./bds-erpc-origin-grid-dialog.component.scss'],
})
export class BdsErpcOriginGridDialogComponent {
    currentOrigin: RtErpcOrigin;
    errorMessage: string = '';
    isSaving: boolean = false;
    isValid: boolean = false;
    isNew = true;

    constructor(
        public dialogRef: MatDialogRef<BdsErpcOriginGridDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BdsErpcOriginGridDialogModel,
        public originService: BdsErpcOriginService,
    ) {
        if (data) {
            this.currentOrigin = data.origin;
            this.isNew = data.isNew;
        }
    }

    onAdd() {
        this.isSaving = true;
        this.originService
            .create(this.currentOrigin)
            .pipe(take(1))
            .subscribe({
                next: (origin) => {
                    console.log(this.currentOrigin);

                    this.afterSaveComplete('Origin Criteria Added.', this.currentOrigin);
                },
                error: (err) => this.afterError(err),
            });
    }

    onCancelClick() {
        this.dialogRef.close();
    }

    onOriginChanged(event: RtErpcOrigin) {
        this.currentOrigin = event;
    }

    onSaveClick() {
        this.errorMessage = '';
        this.isSaving = true;

        if (this.data.isNew) {
            this.onAdd();
        } else {
            this.onUpdate();
        }
    }

    onUpdate() {
        this.isSaving = true;
        this.originService
            .update(this.currentOrigin.ormId, this.currentOrigin)
            .pipe(take(1))
            .subscribe({
                next: (origin) => {
                    console.log(this.currentOrigin);

                    this.afterSaveComplete('Origin Criteria updated.', origin);
                },
                error: (err) => this.afterError(err),
            });
    }

    onValidChanged(event: boolean) {
        this.isValid = event;
    }

    private afterError(error) {
        const customerErrors = [];

        if (typeof error === 'string') {
            this.errorMessage = error;
            customerErrors.push(error);
        } else if (Array.isArray(error) && error[0].validationMessage) {
            this.errorMessage = `Destination Criteria is invalid - ${error[0].validationMessage}`;
            error.forEach((e) => customerErrors.push(e));
        } else {
            this.errorMessage = 'An unknown error occurred';
        }

        this.isSaving = false;
    }

    private afterSaveComplete(message: string, origin: RtErpcOrigin) {
        this.isSaving = false;
        this.dialogRef.close({ message, origin });
    }

    onSplcChanged(value: string): void {
        if (value) {
            this.originService.getBySplc(value).subscribe({
                next: (result) => {
                    this.currentOrigin = result;
                },
                complete: () => {
                    if (this.currentOrigin) {
                        this.isNew = false;
                    }
                },
            });
        }
    }
}
