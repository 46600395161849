import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtTripComment implements From<RtTripComment> {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public shipDateTime?: Date,
        public commentDate?: Date,
        public userId?: string,
        public comments?: string,
        public transmitToCust?: string,
        public commentType?: string,
        public commentCode?: string,
        public ormId?: number,
        public rtTripOrmId?: number,
        public updateDateTime?: Date,
    ) {}
    from(trip: RtTripComment): RtTripComment {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtTripComment>('carInit'), server: 'carInit' },
    { client: nameof<RtTripComment>('carNo'), server: 'carNo' },
    { client: nameof<RtTripComment>('shipDateTime'), server: 'shipDateTime' },
    { client: nameof<RtTripComment>('commentDate'), server: 'commentDate' },
    { client: nameof<RtTripComment>('userId'), server: 'userId' },
    { client: nameof<RtTripComment>('comments'), server: 'comments' },
    { client: nameof<RtTripComment>('transmitToCust'), server: 'transmitToCust' },
    { client: nameof<RtTripComment>('commentType'), server: 'commentType' },
    { client: nameof<RtTripComment>('commentCode'), server: 'commentCode' },
    { client: nameof<RtTripComment>('ormId'), server: 'ormId' },
    { client: nameof<RtTripComment>('rtTripOrmId'), server: 'rtTripOrmId' },
    { client: nameof<RtTripComment>('updateDateTime'), server: 'updateDateTime' },
];

@Injectable({
    providedIn: 'root',
})
export class RtTripCommentAdapter extends TwoWayAdapter<RtTripComment> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtTripCommentMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtTripComment>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('shipDateTime'),
                dataType: 'Date',
                caption: 'shipDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('commentDate'),
                dataType: 'Date',
                caption: 'commentDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('userId'),
                dataType: 'string',
                caption: 'userId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('comments'),
                dataType: 'string',
                caption: 'comments',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('transmitToCust'),
                dataType: 'string',
                caption: 'transmitToCust',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('commentType'),
                dataType: 'string',
                caption: 'commentType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('commentCode'),
                dataType: 'string',
                caption: 'commentCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('ormId'),
                dataType: 'number',
                caption: 'ormId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('rtTripOrmId'),
                dataType: 'number',
                caption: 'rtTripOrmId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTripComment>('updateDateTime'),
                dataType: 'Date',
                caption: 'updateDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtTripComment>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTripComment>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTripComment>('shipDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTripComment>('commentDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTripComment>('userId'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtTripComment>('comments'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
        );
        formGroup.addControl(nameof<RtTripComment>('transmitToCust'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTripComment>('commentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTripComment>('commentCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTripComment>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTripComment>('rtTripOrmId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTripComment>('updateDateTime'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
