import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Observable, forkJoin, isObservable, of } from 'rxjs';

export interface FastracShareDialogData {
    availableUsers: Observable<string[]> | string[];
    selectedUsers: Observable<string[]> | string[];
}

@Component({
    templateUrl: './fastrac-management-dialog-share.component.html',
    styleUrls: ['./fastrac-management-dialog-share.component.scss'],
})
export class FastracManagementDialogShareComponent implements OnInit, OnDestroy {
    iconSearch = faSearch;
    availableUsers: string[] = [];
    selectedUsers: string[] = [];
    savedUsers: string[] = [];
    initialized: boolean;

    constructor(
        public dialogRef: MatDialogRef<FastracManagementDialogShareComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FastracShareDialogData,
    ) {}

    ngOnInit() {
        forkJoin({
            available: isObservable(this.data.availableUsers)
                ? this.data.availableUsers
                : of(this.data.availableUsers),
            selected: isObservable(this.data.selectedUsers)
                ? this.data.selectedUsers
                : of(this.data.selectedUsers),
        }).subscribe((data) => {
            this.availableUsers.push(...data.available);
            this.selectedUsers.push(...data.selected);
            this.savedUsers.push(...data.selected);
            this.initialized = true;
        });
    }

    ngOnDestroy() {}

    onNoClick() {
        this.dialogRef.close();
    }

    // onYesClick() {
    //     const added = this.selectedUsers.filter(x => !this.savedUsers.includes(x));
    //     const removed = this.savedUsers.filter(x => !this.selectedUsers.includes(x));
    //     const results = {
    //         added: added,
    //         removed: removed
    //     };
    //     this.dialogRef.close(results);
    // }

    onSelectedOptionsChange($event: MatSelectionListChange) {
        const user = $event.options[0].value;
        if (this.selectedUsers.includes(user)) {
            this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);
        } else {
            this.selectedUsers.push(user);
        }
    }
}
