import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { nameof } from '@bds/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
    Validators,
    FormGroup,
    FormControl,
} from '@angular/forms';
import { ReportParameterModel } from '../../models/report-parameters.model';

@Component({
    selector: 'rt-report-parameters',
    templateUrl: './rt-report-parameters.component.html',
    styleUrls: ['./rt-report-parameters.component.css'],
})
export class RtReportParametersComponent implements OnInit {
    reportForm: UntypedFormGroup;

    @Input() controlNames: string[] = ['Begin Date', 'End Date'];
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Output() getReport: EventEmitter<any> = new EventEmitter();
    @Output() cancelReport: EventEmitter<any> = new EventEmitter();

    constructor() {}

    get dxMatStyle(): string {
        switch (this.matStyle) {
            case 'fill':
                return 'filled';
            case 'outline':
                return 'outlined';
            case 'standard':
                return 'underlined';
            default:
                return 'underlined';
        }
    }

    ngOnInit(): void {
        this.reportForm = this.getFormGroup();
    }

    getFormGroup(): FormGroup {
        const formGroup = new FormGroup({});
        formGroup.addControl(
            nameof<ReportParameterModel>('startDate'),
            new FormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<ReportParameterModel>('endDate'),
            new FormControl('', [Validators.required]),
        );
        formGroup.setValidators([dateRangeValidator]);
        return formGroup;
    }

    onGetReportClick() {
        this.getReport.emit({
            startDate: this.reportForm.controls.startDate.value,
            endDate: this.reportForm.controls.endDate.value,
        });
    }

    onCancelClick() {
        this.reportForm.reset();
        this.cancelReport.emit();
    }
}

export const dateRangeValidator: ValidatorFn = (
    formGrp: UntypedFormGroup,
): ValidationErrors | null => {
    const fromCtrl = formGrp.get('startDate');
    const toCtrl = formGrp.get('endDate');

    var rangeCondition: boolean = new Date(fromCtrl.value) > new Date(toCtrl.value);
    return rangeCondition ? { dateRangeInvalid: true } : null;
};
