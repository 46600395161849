import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { FastracColumn, FastracGrid } from '@bds/models';
import { FastracColsService } from '@bds/services';

import ODataStore from 'devextreme/data/odata/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BdsSplcErpcService, BdsErpcOriginService } from '@bds/data-access';

@Component({
    selector: 'bds-erpc-origin',
    templateUrl: './bds-erpc-origin.component.html',
    styleUrls: ['./bds-erpc-origin.component.scss'],
})
export class BdsErpcOriginComponent implements OnDestroy {
    defaultFastracId: number;
    fastracId: number;
    gridId: number = FastracGrid.origins;
    isSaving = false;
    originColumns: FastracColumn[] = [];
    originId: number;
    originList: ODataStore;
    selectedRowKeys: number[] = [];
    splcErpcStore: ODataStore;

    private ngUnsubscribe$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public gridColumnService: FastracColsService,
        public originService: BdsErpcOriginService,
        public splcService: BdsSplcErpcService,
        private _snackbar: MatSnackBar,
    ) {
        this.route.data.pipe(take(1)).subscribe((data) => {
            const resolvedData = data['defaultData'];
            this.defaultFastracId = +resolvedData;
        });

        this.route.params.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params) => {
            this.parseRouteParams(params);
        });

        gridColumnService
            .getFastracColumns(this.gridId)
            .pipe(take(1))
            .subscribe((cols) => {
                this.originColumns = cols;
            });

        this.originList = this.originService.getODataStore();
        this.splcErpcStore = this.splcService.getRtSplcErpcODataStore();
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(true);
        this.ngUnsubscribe$.unsubscribe();
    }

    onFastracIdChanged(event) {
        const newFastracId = event || 0;

        if (this.fastracId !== newFastracId) {
            this.fastracId = newFastracId;
            this.syncRouteParams();
        }
    }

    onOriginSaved(event) {
        this.openSnackBar('Origin saved successfully', '', true);
    }

    openSnackBar(message: string, action: string, success: boolean) {
        let style = 'error-snack';
        if (success) {
            style = 'success-snack';
        }

        this._snackbar.open(message, action, {
            duration: 3000,
            panelClass: [style],
            horizontalPosition: 'right',
        });
    }

    parseRouteParams(params: Params) {
        if (params && params.id) {
            this.originId = +params.id;
            this.selectedRowKeys = [this.originId];

            if (params && params.fId) {
                this.fastracId = +params.fId;
            } else {
                this.fastracId = this.defaultFastracId;
                this.syncRouteParams();
            }
        } else {
            this.originId = 0;
            const urlSegment: UrlSegment = this.route.snapshot.url[0];

            if (urlSegment && urlSegment.parameters && urlSegment.parameters.fId) {
                this.fastracId = +urlSegment.parameters.fId;
            } else {
                this.fastracId = this.defaultFastracId;
                this.syncRouteParams();
            }
        }
    }

    syncRouteParams() {
        let fastracParam = {};

        if (this.fastracId) {
            fastracParam = { fId: this.fastracId };
        }

        if (this.originId) {
            this.router.navigate(['/origins', this.originId, fastracParam]);
        } else {
            this.router.navigate(['/origins', fastracParam]);
        }
    }
}
