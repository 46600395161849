import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizeService, IUser } from '@bds/auth';
import { faFastrac } from '@bds/fa-svg-icons';
import { Fastrac } from '@bds/models';
import { FastracBuilderService, GenerateFilterConditionsService } from '@bds/services';
import {
    faExternalLinkAlt,
    faFileImport,
    faLock,
    faStar,
    faUserFriends,
} from '@fortawesome/pro-regular-svg-icons';
import { faRunning } from '@fortawesome/pro-solid-svg-icons';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import { AsyncSubject, Observable, of } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FastracManagementDialogConfirmComponent } from './../fastrac-management-dialog-confirm/fastrac-management-dialog-confirm.component';
import { FastracDialogData } from './../fastrac-management-screen.component';

@Component({
    selector: 'app-fastrac-management-page',
    templateUrl: './fastrac-management-page.component.html',
    styleUrls: ['./fastrac-management-page.component.scss'],
})
export class FastracManagementPageComponent implements OnInit, OnDestroy {
    unsubscriber$: AsyncSubject<void> = new AsyncSubject<void>();

    @ViewChild('fastracGrid') fastracGrid: DxDataGridComponent;

    @Output() runFastrac = new EventEmitter<number | number[]>();

    user: IUser;
    dateFormat = 'MM/DD/YYYY';
    gridSource: DataSource;
    selectedFastracIds: number[];

    iconFastrac = faFastrac;
    iconRunFastrac = faRunning;
    iconExternalLink = faExternalLinkAlt;

    iconLock = faLock;
    iconFavorite = faStar;
    iconClassic = faFileImport;
    iconShared = faUserFriends;

    constructor(
        public filterConditionsService: GenerateFilterConditionsService,
        public fastracService: FastracBuilderService,
        public dialog: MatDialog,
        public route: ActivatedRoute,
        public router: Router,
        public authService: AuthorizeService,
    ) {
        console.group('fastrac-management-screen ctor');
        //this.fastracService.getFastracDataSourceAsync({ expandGrid: true }).then((dataSource) => {
        //    this.gridSource = dataSource;
        //});
        this.gridSource = this.fastracService.getFastracDataSource({ expandGrid: true });
        this.authService
            .getUser()
            .pipe(take(1))
            .subscribe((s) => {
                this.user = s;
            });
        console.log('gridSource', this.gridSource);
        console.groupEnd();
    }

    ngOnInit() {
        console.group('fastrac-management-screen ngOnInit');
        console.groupEnd();
        this.route.params.pipe(takeUntil(this.unsubscriber$)).subscribe((params) => {
            // this.fastracId = +params['fId'];
        });
    }

    ngOnDestroy() {
        this.unsubscriber$.next();
        this.unsubscriber$.complete();
    }

    rowDblclick($event: any) {
        console.log('row double click', $event);
        const fastracId = $event.data.id as number;
        const url = this.router.createUrlTree(['/trips', { fId: fastracId }]);
        this.openPopup(url.toString(), '_blank');
    }

    fastracChanged($event: Fastrac) {
        console.log('fastrac-management-screen fastracChanged', $event);
        this.fastracGrid.instance.refresh();
        this.selectedFastracIds = [$event.id];
    }
    fastracDeleted($event: number) {
        console.log('fastrac-management-screen fastracDeleted', $event);
        this.fastracGrid.instance.refresh();
        this.selectedFastracIds = [];
    }

    getIcon(fastracData: Fastrac) {
        if (fastracData.ownerId === 'BDS') {
            return 'lock';
        }
        if (fastracData.ownerId !== this.user.name) {
            return 'shared';
        }
        if (fastracData.isClassic) {
            return 'classic';
        }
        if (fastracData.isFavorite) {
            return 'favorite';
        }
        return 'none';
    }

    getNameFromGrid(fastracId: number): Observable<string> {
        const rowData = this.fastracGrid.instance
            .getSelectedRowsData()
            .find((f) => f.id === fastracId);
        console.log('getNameFromGrid', fastracId, rowData);
        const obs = of(rowData.name);
        return obs;
    }

    openPopup(...args): Promise<unknown> {
        return new Promise<Window | void>((s) => {
            const win = window.open(...args);
            if (!win || win.closed) {
                return s();
            }
            setTimeout(() => (win.innerHeight > 0 && !win.closed ? s(win) : s()), 200);
        });
    }

    beforeRunSelectedFastracs() {
        const forgottenBoxesInStorage = localStorage.getItem('forgottenBoxes');
        const forgottenBoxesArray: string[] = JSON.parse(forgottenBoxesInStorage || '[]');
        const showPrePrompt = !forgottenBoxesArray.includes(
            'FastracManager-WarnOnMultipleFastracs',
        );
        if (showPrePrompt && this.selectedFastracIds.length > 1) {
            const dialogRef = this.dialog.open(FastracManagementDialogConfirmComponent, {
                data: {
                    title: 'Allow pop-ups',
                    prompt: "Running multiple FASTRACs may trigger your browser's pop-up blocker.",
                    // asAlert: true,
                    allowIgnore: true,
                    noLabel: 'Never mind',
                    yesLabel: 'I understand',
                    promptId: 'FastracManager-WarnOnMultipleFastracs',
                } as FastracDialogData,
            });
            dialogRef.afterClosed().subscribe((s) => {
                if (s) {
                    this.runSelectedFastracs();
                }
            });
        } else {
            this.runSelectedFastracs();
        }
    }

    async runSelectedFastracs() {
        let hasPopupWarningOpen = false;
        const promiseList: Promise<unknown>[] = [];
        for (let i: number = 0; i < this.selectedFastracIds.length; i++) {
            const fastracId = this.selectedFastracIds[i];
            const url = this.router.createUrlTree(['/trips', { fId: fastracId }]);
            console.log(`opening window ${i}`);
            const wdw = await this.openPopup(url.toString(), '_blank');
            console.log(`window ${i} open`);
            if (!wdw && !hasPopupWarningOpen) {
                hasPopupWarningOpen = true;
                this.dialog.open(FastracManagementDialogConfirmComponent, {
                    data: {
                        title: 'Popups are blocked',
                        prompt: 'You need to enable popups for this site to run multiple FASTRAC reports.',
                        asAlert: true,
                        yesLabel: 'Got it',
                    } as FastracDialogData,
                });
            }
        }
        console.log('attempting to focus self');
        window.focus();
        console.log('after focus attempt');
    }

    onSelectionChanged($event: any) {
        // console.group('fastrac-management-screen onSelectionChanged');
        // console.group('$event', $event);
        // if ($event.currentSelectedRowKeys.length === 0) {
        //     this.selectedFastrac = null;
        // }
        // else {
        //     this.selectedFastrac = $event.selectedRowsData[0];
        // }
        // console.log('selectedFastrac', this.selectedFastrac);
        // console.groupEnd();
    }
}
