import { PageService } from '@bds/core';
import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './railcar-profile-screen.component.html',
    styleUrls: ['./railcar-profile-screen.component.scss'],
})
export class RailcarProfileScreenComponent implements OnInit {
    constructor(public pageService: PageService) {
        pageService.setHeader('Railcar Profiles');
    }

    ngOnInit() {}
}
