import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyFieldTemplatesModule } from '@bds/components';
import { BdsHelpersModule } from '@bds/helpers';
import { VendorsModule } from '../../vendors/vendors.module';
import { EquipmentDetailsComponentsModule } from '../../components';
import { EquipmentComponentsModule, EquipmentReportCategoriesModule } from '../../screens';
import { EquipmentDetailsComponent } from './equipment-details.component';

@NgModule({
    declarations: [EquipmentDetailsComponent],
    imports: [
        BdsHelpersModule,
        CommonModule,
        EquipmentComponentsModule,
        EquipmentDetailsComponentsModule,
        EquipmentReportCategoriesModule,
        FormlyFieldTemplatesModule,
        VendorsModule,
    ],
    exports: [EquipmentDetailsComponent]
})
export class EquipmentDetailsModule {}
