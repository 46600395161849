import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { RtJeopardizedShipment } from '@bds/railtrac-models';

@Component({
    selector: 'rt-jeopardized-shipment-details',
    templateUrl: './rt-jeopardized-shipment-details.component.html',
    styleUrls: ['./rt-jeopardized-shipment-details.component.scss'],
})
export class RtJeopardizedShipmentDetailsComponent implements OnInit {
    @Input() shipment: RtJeopardizedShipment;

    constructor() {}

    ngOnInit() {}
}
