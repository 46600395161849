export class BdsDestinationCriteriaDialogModel {
    customerDeliveryHours?: number;
    customerNo?: string;
    isClone: boolean;
    isNew: boolean;
    locationCity?: string;
    locationState?: string;
    railroad?: string;
    sightCode?: string;
}
