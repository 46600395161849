import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subject, of, forkJoin } from 'rxjs';
import { takeUntil, take, mergeMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizeService } from '@bds/auth';
import { EquipReportCategory, EquipReportCatgEquip, EquipReportCatgValue } from '../../models';
import {
    EquipReportCategoryService,
    EquipReportCatgEquipService,
    EquipReportCatgValueService,
} from '../../data-access';

@Component({
    selector: 'bds-equipment-report-categories',
    templateUrl: './equipment-report-categories.component.html',
    styleUrls: ['./equipment-report-categories.component.scss'],
})
export class EquipmentReportCategoriesComponent implements OnInit, OnChanges, OnDestroy {
    currentEquipCatgEquip: EquipReportCatgEquip[] = [];
    equipCatgEquip: EquipReportCatgEquip[] = [];
    equipCategories$: Observable<EquipReportCategory[]>;
    equipCatgValues$: Observable<EquipReportCatgValue[]>;
    isSaving = false;
    isValid: boolean;
    startDate: Date;
    userName: string;

    private ngUnsubscribe$: Subject<void> = new Subject<void>();

    @Input() showMenu = true;
    @Input() showHeader = true;
    @Input() currentUser = '';
    @Input() equipId: number;
    @Input() equipInit: string;
    @Input() equipNumber: string;
    @Input() shipmentDate: Date;
    @Input() tripCloseDate: Date;

    constructor(
        private equipReportCategoryService: EquipReportCategoryService,
        private equipReportCatgEquipService: EquipReportCatgEquipService,
        private equipReportCatgValueService: EquipReportCatgValueService,
        public authService: AuthorizeService,
        public dialog: MatDialog,
        private _snackbar: MatSnackBar,
    ) {}

    ngOnInit() {
        if (!this.currentUser) {
            this.authService
                .getUser()
                .pipe(take(1))
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
                .subscribe((user) => (this.userName = user['preferred_username']));
        } else {
            this.userName = this.currentUser;
        }

        this.setStartDate();
        this.equipCategories$ = this.getCategories();
        this.equipCatgValues$ = this.getCategoryValues();
        this.getCategoriesForEquipment();
    }

    ngOnChanges(changes: SimpleChanges) {
        // Should rerun if ormId or shipmentDate changes

        if (changes.shipmentDate && !changes.shipmentDate.firstChange) {
            this.setStartDate();
        }

        if (
            (changes.equipId && !changes.equipId.firstChange) ||
            (changes.shipmentDate && !changes.shipmentDate.firstChange)
        ) {
            this.getCategoriesForEquipment();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    createEquips(equips: EquipReportCatgEquip[]): Observable<EquipReportCatgEquip[]> {
        // Bulk create
        if (equips.length > 0) {
            const createSource = of(equips);
            return createSource.pipe(
                mergeMap((q) =>
                    forkJoin(q.map((eq) => this.equipReportCatgEquipService.create(eq))),
                ),
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return of([]);
        }
    }

    getCategories(): Observable<EquipReportCategory[]> {
        return this.equipReportCategoryService.getAll().pipe(take(1));
    }

    getCategoriesForEquipment(): void {
        const filters: string = this.getFilters();

        if (filters) {
            this.equipReportCatgEquipService
                .getFiltered(filters)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe((eq) => {
                    eq.sort((a, b) => (a.ormId > b.ormId ? 1 : b.ormId > a.ormId ? -1 : 0));
                    this.equipCatgEquip = eq;
                });
        } else {
            this.equipCatgEquip = [];
        }
    }

    getCategoryValues(): Observable<EquipReportCatgValue[]> {
        return this.equipReportCatgValueService.getAll().pipe(take(1));
    }

    getFilters(): string {
        let filter = '';

        // TODO: Get feedback to determine what items should be returned here
        // Do we want only ones that are valid during the time the trip is open?
        // Do we need all items for resetting dates of prior items when new items are added or
        // when the date is expired? Or is that handled on the backend ?
        if (this.equipId && this.shipmentDate) {
            filter =
                `equipmentId eq ${this.equipId}` +
                ` and (expireDate ge ${this.shipmentDate.toString()} or expireDate eq null)`;

            if (this.tripCloseDate) {
                filter = filter + ` and effectDate le ${this.tripCloseDate.toString()}`;
            }
        } else if (this.equipId) {
            filter = `equipmentId eq ${this.equipId}`;
        } else {
            console.warn(
                'No filters applied for retrieving equipment categories. Equipment ID is not defined.',
            );
        }

        return filter;
    }

    onEquipCatgEquipChanged(event: { equips: EquipReportCatgEquip[]; valid: boolean }): void {
        this.currentEquipCatgEquip = [...event.equips];
        this.isValid = event.valid;
    }

    onSave(): void {
        if (!this.isValid) {
            this.openSnackBar('Unable to Save - Not all Reporting Categories are Valid', '', false);
            return;
        }

        this.isSaving = true;

        const equipToUpdate: EquipReportCatgEquip[] = [];
        const equipToCreate: EquipReportCatgEquip[] = [];

        for (let i = 0; i < this.currentEquipCatgEquip.length; i++) {
            if (this.currentEquipCatgEquip[i].ormId === undefined) {
                this.currentEquipCatgEquip[i].lastModifiedUser = this.userName;
                this.currentEquipCatgEquip[i].lastModifiedDatetime = new Date();
                equipToCreate.push(this.currentEquipCatgEquip[i]);
            } else {
                const index = this.equipCatgEquip.findIndex(
                    (eq) => eq.ormId === this.currentEquipCatgEquip[i].ormId,
                );
                if (
                    index > -1 &&
                    (this.currentEquipCatgEquip[i].effectDate !==
                        this.equipCatgEquip[index].effectDate ||
                        this.currentEquipCatgEquip[i].equipReportCatgValId !==
                            this.equipCatgEquip[index].equipReportCatgValId ||
                        this.currentEquipCatgEquip[i].equipReportCatgId !==
                            this.equipCatgEquip[index].equipReportCatgId ||
                        this.currentEquipCatgEquip[i].expireDate !==
                            this.equipCatgEquip[index].expireDate)
                ) {
                    this.currentEquipCatgEquip[i].lastModifiedUser = this.userName;
                    this.currentEquipCatgEquip[i].lastModifiedDatetime = new Date();
                    equipToUpdate.push(this.currentEquipCatgEquip[i]);
                }
            }
        }

        forkJoin([this.updateEquips(equipToUpdate), this.createEquips(equipToCreate)]).subscribe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([u, c]) => {
                this.getCategoriesForEquipment();
                this.isSaving = false;
                this.openSnackBar('All Reporting Categories Saved', '', true);
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (error) => {
                this.isSaving = false;
                this.openSnackBar('Error Saving Reporting Categories', '', false);
            },
        );
    }

    openSnackBar(message: string, action: string, success: boolean): void {
        let style = 'error-snack';
        if (success) {
            style = 'success-snack';
        }

        this._snackbar.open(message, action, {
            duration: 3000,
            panelClass: [style],
            horizontalPosition: 'right',
        });
    }

    setStartDate(): void {
        if (this.shipmentDate) {
            this.startDate = this.shipmentDate;
        } else {
            this.startDate = new Date();
        }
    }

    updateEquips(equips: EquipReportCatgEquip[]): Observable<EquipReportCatgEquip[]> {
        // Bulk save
        if (equips.length > 0) {
            const updateSource = of(equips);
            return updateSource.pipe(
                mergeMap((q) =>
                    forkJoin(q.map((eq) => this.equipReportCatgEquipService.update(eq.ormId, eq))),
                ),
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return of([]);
        }
    }
}
