<bds-equipment-component-list
    [equipmentId]="selectedEquipment?.ormId"
    [componentsStore]="componentsStore"
    [needsRefresh$]="refreshGrid$"
    [selectedItemId$]="selectedItemId$"
    (selectedItemChange)="onSelectedComponentChanged($event)"
    (addEquipmentComponent)="onAddComponent()"
>
</bds-equipment-component-list>

<div *ngIf="selectedComponent">
    <bds-equipment-component-menu
        [description]="componentDescription"
        [isSaving]="isSaving"
        [saveText]="'Save Component'"
        (saveChanges)="onSave()"
    >
    </bds-equipment-component-menu>

    <div *ngIf="errorMessage$ | async as errorMessages">
        <div *ngFor="let errorMessage of errorMessages" class="error-warning">
            {{ errorMessage }}
        </div>
    </div>

    <div class="row">
        <div class="col-md-8 col-12 equip-card">
            <div class="equip-card">
                <mat-card>
                    <div class="card-title">Common Component Fields</div>
                    <bds-equipment-component-common
                        [selectedComponent]="selectedComponent"
                        [availableComponents]="availableComponents$ | async"
                        [removalCodes]="removalCodes$ | async"
                        (componentIdChange)="onComponentIdChanged($event)"
                        (componentChange)="onComponentChanged($event)"
                    >
                    </bds-equipment-component-common>
                </mat-card>
            </div>

            <div class="equip-card">
                <mat-card>
                    <div class="card-title">{{ componentDescription }} Fields</div>
                    <bds-equipment-component-fields
                        [compValues]="selectedComponent?.tcmEquipComponentMechEav"
                        [compFields]="selectedCompFields$ | async"
                        [dynamicDropdownValues]="dynamicDropdownValues$ | async"
                        (modelChange)="onComponentValuesChanged($event)"
                    >
                    </bds-equipment-component-fields>
                </mat-card>
            </div>
        </div>

        <div class="col-md-4 col-12 equip-card no-left-pad">
            <mat-card>
                <div class="card-title">Equipment Component Dates</div>
                <bds-equipment-component-dates
                    [equipComponent]="selectedComponent"
                    [componentDescription]="componentDescription"
                    [compFields]="selectedCompFields$ | async"
                    (modelChange)="onComponentDatesChanged($event)"
                    (historicDatesUpdate)="onComponentDatesUpdated()"
                >
                </bds-equipment-component-dates>
            </mat-card>
        </div>
    </div>
</div>
