import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { BdsPrefRouteService } from '../../bds-route/services/bds-pref-route.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, catchError, firstValueFrom, of, take, tap } from 'rxjs';
import { RtCustomerFact, RtPreferredRoute, RtRouteCode, RtSplcErpc } from '@bds/railtrac-models';
import { faSave, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { UntypedFormGroup } from '@angular/forms';
import { RtPreferredRouteMetadata } from '@bds/railtrac-models';
import {
    BdsCustomerDialogComponent,
    BdsCustomerDialogModel,
    BdsCustomerSearchDialogComponent,
    BdsCustomerSearchDialogModel,
} from '@bds/customer';
import { nameof } from '@bds/core';
import { MatDialog } from '@angular/material/dialog';
import { BdsCustomerService } from '@bds/reference-data-access';
import { ReferenceApplicationPathProvider } from '@bds/reference-pathing';
import { dxMatStyle } from '@bds/helpers';
import { BdsSplcErpcService, RtRouteService } from '@bds/data-access';
import { BdsRouteSearchDialogComponent } from '../../bds-route/components/bds-route-search-dialog/bds-route-search-dialog.component';
import { BdsRouteSearchDialogModel } from '../../bds-route/components/bds-route-search-dialog/bds-route-search-dialog-model';
import { MatSnackBar } from '@angular/material/snack-bar';

export class TripIcons {
    save = faSave;
    spinner = faSpinner;
}

@Component({
    selector: 'bds-preferred-route',
    templateUrl: './bds-preferred-route.component.html',
    styleUrls: ['./bds-preferred-route.component.css'],
})
export class BdsPreferredRouteComponent implements OnInit, OnDestroy {
    @ViewChild('routeCodeSelectBox') routeCodeSelectBox;

    dataSource: DataSource;
    customerFactDxLookupSource: DataSource;
    routeCodeDxLookupSource: DataSource;

    selectedPreferredRoute: RtPreferredRoute;
    ormId = 0;
    isSaving = false;
    icons = new TripIcons();
    matInputStyle = 'fill';
    matResultStyle = 'standard';
    matFloatLabel = 'always';
    matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    form: UntypedFormGroup;
    selectedCustomer: RtCustomerFact;
    selectedOriginSplc: RtSplcErpc;
    selectedRoute: any;
    customerNoFieldServerName: string;
    customerFactDxLookupValueExpr: string;
    customerNameFieldServerName: string;
    careOfNameFieldServerName: string;
    routeCodeDxLookupValueExpr: string;
    routeCodeFieldServerName: string;
    routeDescriptionFieldServerName: string;
    customerFactDxLookupSearchExpr: string[];
    routeCodeDxLookupSearchExpr: string[];

    private sub: Subscription;

    get dxMatStyle(): string {
        return dxMatStyle(this.matStyle);
    }
    consigneeSearchInlineButtonOptions = {
        type: 'default',
        icon: 'search',
        stylingMode: 'text',
        onClick: (): void => {
            this.searchForConsignee();
        },
    };

    consigneeAddInlineButtonOptions = {
        type: 'default',
        icon: 'add',
        stylingMode: 'text',
        onClick: (): void => {
            this.openConsigneeManager();
        },
    };

    consigneeEditInlineButtonOptions = {
        type: 'default',
        icon: 'edit',
        stylingMode: 'text',
        onClick: (): void => {
            this.openConsigneeManager(this.selectedCustomer);
        },
    };

    routeCodeSearchInlineButtonOptions = {
        type: 'default',
        icon: 'search',
        stylingMode: 'text',
        onClick: (): void => {
            this.searchForRouteCode();
        },
    };

    routeAddInlineButtonOptions = {
        type: 'default',
        icon: 'add',
        stylingMode: 'text',
        onClick: (): void => {
            this.openRouteManager();
        },
    };

    routeEditInlineButtonOptions = {
        type: 'default',
        icon: 'edit',
        stylingMode: 'text',
        onClick: (): void => {
            this.openRouteManager(this.selectedRoute);
        },
    };

    constructor(
        private prefRouteService: BdsPrefRouteService,
        private prefRouteMetaData: RtPreferredRouteMetadata,
        private activatedRoute: ActivatedRoute,
        private customerApiService: BdsCustomerService,
        public dialog: MatDialog,
        public referenceAppPathProvider: ReferenceApplicationPathProvider,
        private splcService: BdsSplcErpcService,
        public routeApiService: RtRouteService,
        private snackbar: MatSnackBar,
        private router: Router,
    ) {}

    ngOnInit() {
        this.form = this.prefRouteMetaData.getFormGroup();

        this.sub = this.activatedRoute.params.subscribe((params) => {
            if (!params) {
                return;
            }
            this.ormId = +params['ormId'];
            this.getPreferredRoute();
            this.form.reset();
        });

        this.customerFactDxLookupSource = new DataSource({
            store: this.customerApiService.getODataStore(),
            searchExpr: [this.customerNoFieldServerName, this.customerNameFieldServerName],
            paginate: true,
            pageSize: 100,
        });

        this.customerNoFieldServerName = this.customerApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtCustomerFact>('customerNo'),
        ).server;

        this.routeCodeFieldServerName = this.routeApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtRouteCode>('routeCode'),
        ).server;

        this.routeDescriptionFieldServerName = this.routeApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtRouteCode>('routeDscr'),
        ).server;

        this.customerFactDxLookupValueExpr = this.customerNoFieldServerName;

        this.routeCodeDxLookupValueExpr = this.routeCodeFieldServerName;

        this.setRouteCodeDataSource();

        this.routeCodeDxLookupSearchExpr = [
            this.routeCodeFieldServerName,
            this.routeDescriptionFieldServerName,
        ];
    }

    setRouteCodeDataSource() {
        this.routeCodeDxLookupSource = new DataSource({
            store: this.routeApiService.getODataStore(),
            searchExpr: [this.routeCodeFieldServerName, this.routeDescriptionFieldServerName],
            paginate: true,
            pageSize: 100,
            filter: ['originSplc', '=', this.selectedOriginSplc?.splc],
        });
    }

    customerFactDxLookupDisplayExpr = (cust: any) => {
        if (!cust) {
            return '';
        }
        const custNo = cust[this.customerNoFieldServerName];
        const custName = cust['customerName'];
        return `${custNo} - ${custName}`;
    };

    openConsigneeManager(item?: RtCustomerFact): void {
        if (item) {
            this.referenceAppPathProvider
                .Customer(item.customerNo)
                .pipe(take(1))
                .subscribe((refUrl) => {
                    window.open(refUrl, '_blank');
                });
        } else {
            this.referenceAppPathProvider
                .Customers()
                .pipe(take(1))
                .subscribe((refUrl) => {
                    window.open(refUrl, '_blank');
                });
        }
    }

    createConsignee(item?: RtCustomerFact): void {
        const data: BdsCustomerDialogModel = {
            title: item ? 'Edit Consignee' : 'Create Consignee',
            actionText: item ? 'Edit Consignee' : 'Create Consignee',
            action: item ? 'edit' : 'create',
            item: item,
        };
        const dialogRef = this.dialog.open(BdsCustomerDialogComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((s) => {
            if (!!s) {
                this.form.controls[nameof<RtPreferredRoute>('customerNo')].setValue(s.customerNo);
            }
        });
    }

    searchForConsignee(): void {
        const data = new BdsCustomerSearchDialogModel();
        data.actionText = 'Select Consignee';
        data.title = 'Select Consignee';
        data.source = this.customerFactDxLookupSource;
        //data.defaultFastracName = 'SEARCH CONSIGNEE';

        const dialogRef = this.dialog.open(BdsCustomerSearchDialogComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((s: RtCustomerFact) => {
            if (!!s) {
                this.form.controls[nameof<RtPreferredRoute>('customerNo')].setValue(s.customerNo);
            }
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    async getPreferredRoute() {
        if (!this.ormId) {
            return;
        }
        const result = await firstValueFrom(this.prefRouteService.getByOrmId(this.ormId));
        if (result) {
            this.selectedPreferredRoute = result;
            this.setControllerValues();
        }
    }

    setControllerValues() {
        this.form.controls.customerNo.setValue(this.selectedPreferredRoute.customerNo);
        this.form.controls.originSplc.setValue(this.selectedPreferredRoute.originSplc);
        this.form.controls.routeCode.setValue(this.selectedPreferredRoute.routeCode);
        this.form.controls.ormId.setValue(this.selectedPreferredRoute.ormId);
    }

    async setOriginGeography(splc: RtSplcErpc): Promise<void> {
        this.form.controls.routeCode.disable();
        // if (!this.ormId) {
        //     this.form.controls.routeCode.reset();
        // }

        if (splc?.splc) {
            this.selectedOriginSplc = splc;
            this.setRouteCodeDataSource();
            this.form.controls.routeCode.enable();
            return;
        }

        if (!this.form.controls.originSplc.value) {
            return;
        }

        const result = await firstValueFrom(
            this.splcService.getBySplc(this.form.controls.originSplc.value ?? splc?.splc),
        );

        if (result) {
            this.selectedOriginSplc = result;
            this.setRouteCodeDataSource();
            this.form.controls.routeCode.enable();
        }
    }

    displayValue = (data: RtSplcErpc): string => (data ? `${data.splc}` : '');

    openRouteManager(item?: RtRouteCode): void {
        if (item) {
            window.open('routes/detail/' + item.routeCode, '_blank').focus();
        } else {
            window.open('routes/create', '_blank').focus();
        }
    }

    searchForRouteCode(): void {
        this.routeCodeSelectBox.instance.close();
        const data = new BdsRouteSearchDialogModel();
        data.actionText = 'Select Route';
        data.title = 'Select Route';
        data.splc = this.selectedOriginSplc.splc;

        const dialogRef = this.dialog.open(BdsRouteSearchDialogComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((s: RtRouteCode) => {
            if (!!s) {
                this.form.controls[nameof<RtPreferredRoute>('routeCode')].setValue(s.routeCode);
            }
        });
    }

    routeCodeDxLookupDisplayExpr = (route: any) => {
        if (!route) {
            return '';
        }
        const routeCode = route[this.routeCodeFieldServerName];
        const routeDescription = route[this.routeDescriptionFieldServerName];
        return `${routeCode} ${routeDescription}`;
    };

    async onRouteSelectionChanged($event) {
        if ($event?.selectedItem) {
            this.selectedRoute = $event.selectedItem;

            return;
        }

        if (!this.form.controls.routeCode.value) {
            return;
        }

        const result = await firstValueFrom(
            this.routeApiService.readViaApi(this.form.controls.routeCode.value),
        );

        if (result) {
            this.selectedRoute = result;
        }
    }

    async saveChanges() {
        const request = this.form.getRawValue();
        request.ormId = request.ormId ?? 0;
        this.isSaving = true;
        this.prefRouteService
            .manage(request)
            .pipe(
                tap((s) => {
                    this.snackbar.open(
                        `Preferred route ${request.ormId ? 'updated' : 'created'}!`,
                        'Ok',
                        {
                            duration: 3000,
                        },
                    );
                    this.isSaving = false;

                    void this.router.navigate([`preferred-route/${s['relatedObject']?.ormId}`], {
                        queryParamsHandling: 'preserve',
                    });
                }),
                catchError((error) => {
                    // this.errorMessage = error.toString();
                    this.snackbar.open('Error saving changes!', 'Ok', {
                        duration: 3000,
                        panelClass: 'error-snack',
                    });
                    this.isSaving = false;
                    return of(null);
                }),
            )
            .subscribe();
    }
}
