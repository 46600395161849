import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { BdsPrefRouteService } from '../../bds-route/services/bds-pref-route.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, filter, forkJoin, switchMap, tap } from 'rxjs';
import { BdsDialogConfirmComponent, BdsDialogConfirmModel } from '@bds/components';
import { RtPreferredRoute } from '@bds/railtrac-models';

@Component({
    selector: 'bds-preferred-routes-grid',
    templateUrl: './preferred-routes-grid.component.html',
    styleUrls: ['./preferred-routes-grid.component.css'],
})
export class BdsPreferredRoutesGridComponent implements OnInit, OnDestroy {
    @ViewChild('bdsPreferredRouteGrid') bdsRouteGrid: DxDataGridComponent;
    dataSource: DataSource;
    selectedPreferredRoute: RtPreferredRoute;
    selectedRowKeys: number[] = [];
    isEditBtnEnable = false;
    isDeleteBtnEnable = false;
    ormId: number;

    private sub: Subscription;
    constructor(
        private prefRouteService: BdsPrefRouteService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        this.dataSource = new DataSource({
            store: this.prefRouteService.getODataStore(),
            paginate: true,
            pageSize: 10,
            expand: ['rtRouteCode,customerFact'],
        });
    }

    private _routeCode: string;

    get routeCode(): string {
        return this._routeCode;
    }

    @Input() set routeCode(value: string) {
        this.dataSource.filter([['routeCode', '=', value]]);
        this._routeCode = value;
    }

    add(): void {
        this.router.navigate(['./create'], {
            relativeTo: this.activatedRoute,
        });
    }

    async edit(): Promise<void> {
        if (this.selectedPreferredRoute) {
            this.router.navigate(['./', this.selectedPreferredRoute?.ormId], {
                relativeTo: this.activatedRoute,
            });
        }
    }

    onSelectionChanged = (event) => {
        this.selectedPreferredRoute = event.selectedRowsData[0];
        // this.isEditBtnEnable = event.selectedRowsData.length === 1;
        this.isDeleteBtnEnable = event.selectedRowsData.length > 0;
        this.edit();
        setTimeout(() => {
            this.bdsRouteGrid.instance.repaint();
        }, 0);
    };

    deletePreferredRoutes(): void {
        const selected = this.bdsRouteGrid.selectedRowKeys;

        const confirmInfo: BdsDialogConfirmModel = {
            content:
                selected.length === 0
                    ? 'No preferred route(s) selected.'
                    : `Are you sure you would like to delete ${
                          selected.length
                      } selected preferred route${selected.length > 1 ? 's' : ''}?`,
            actionText: `Yes`,
            dismissiveText: 'No',
        };

        const noDataInfo: BdsDialogConfirmModel = {
            content: 'No preferred route(s) selected.',
            actionText: 'Ok',
        };

        const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
            data: selected.length === 0 ? noDataInfo : confirmInfo,
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((response) => response),
                switchMap(() => forkJoin(selected.map((x) => this.prefRouteService.delete(x)))),
                tap(() => {
                    this.bdsRouteGrid.instance.refresh();
                    this.snackbar.open('Deleted Record', 'Ok', { duration: 3000 });
                }),
            )
            .subscribe();
    }

    ngOnInit(): void {
        this.activatedRoute.firstChild?.params.subscribe((params) => {
            if (!params || !this.bdsRouteGrid) {
                return;
            }

            this.ormId = +params['ormId'];
            if (this.ormId) {
                this.selectedRowKeys = [this.ormId];
                void this.bdsRouteGrid.instance.refresh();
            }
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
