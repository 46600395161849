<div class="sticky-top mat-elevation-z6">
    <mat-toolbar class="trip-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <span *ngIf="!!trip">
                <!-- <fa-icon [icon]="iconFlag" [fixedWidth]="true" size="lg"></fa-icon> -->
                Destination Difference
            </span>
            <span class="fill-remaining-space"></span>
            <span>
                <button mat-stroked-button (click)="createDestinationBypass()">
                    <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
                    Create Destination Alias Bypass
                </button>
                <button
                    mat-stroked-button
                    (click)="addToOriginCriteria()"
                    *ngIf="isToDestination === false"
                >
                    <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
                    Add Origin Criteria
                </button>
                <button
                    mat-stroked-button
                    (click)="addToDestinationCriteria()"
                    *ngIf="isToDestination === true"
                >
                    <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
                    Add Destination Criteria
                </button>
                <button mat-stroked-button (click)="createComment()">
                    <!-- <fa-icon [icon]="iconSave"></fa-icon> -->
                    Add Comment
                </button>
                <a
                    mat-stroked-button
                    *ngIf="!!trip && !!currentClm"
                    [href]="generateEmailLink(trip, currentClm)"
                    >Send Email to {{ currentClm.road }}</a
                >
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<div class="container mt-3" *ngIf="false">
    <div class="row" *ngIf="resolved">
        <div class="col-12">
            <div class="alert alert-success" role="alert">This issue has been resolved.</div>
        </div>
    </div>
    <div class="row" *ngIf="!!shipment">
        <div class="col-12">
            <h3 class="mini-header">Shipment Details</h3>
            <div class="dtable">
                <div class="dtrow">
                    <div class="dtcell">Car:</div>
                    <div class="dtcell">
                        <span class="data" *ngIf="!!trip; else loading"
                            >{{ trip.carInit }}{{ trip.carNo }}</span
                        >
                    </div>
                </div>
                <div class="dtrow">
                    <div class="dtcell">Shipment Date:</div>
                    <div class="dtcell">
                        <span class="data" *ngIf="!!trip; else loading">{{
                            trip.shipDatetime | date : 'MM/dd/yyyy HH:mm:ss' : 'UTC'
                        }}</span>
                        <p>Hello</p>
                    </div>
                </div>
                <div class="dtrow">
                    <div class="dtcell">Comments:</div>
                    <div class="dtcell">
                        <ng-container *ngIf="!!shipment.lastComments; else nocomment">
                            <span class="data">{{ shipment.lastComments }}</span>
                        </ng-container>
                        <ng-template #nocomment>
                            <i class="data">None</i>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-12">
            <h3 class="mini-header">Trip Destination</h3>
            <div class="dtable">
                <div class="dtrow">
                    <div class="dtcell">Trip Status:</div>
                    <div class="dtcell" [ngSwitch]="trip.tripStatus" *ngIf="!!trip; else loading">
                        <span class="data" *ngSwitchCase="'O'">Open</span>
                        <span class="data" *ngSwitchCase="'C'">Close</span>
                    </div>
                </div>
                <div class="dtrow">
                    <div class="dtcell">Car Status:</div>
                    <div class="dtcell" [ngSwitch]="trip.carStatus" *ngIf="!!trip; else loading">
                        <span class="data" *ngSwitchCase="'0'">0 - At origin</span>
                        <span class="data" *ngSwitchCase="'1'">1 - To consignee</span>
                        <span class="data" *ngSwitchCase="'2'">2 - At consignee</span>
                        <span class="data" *ngSwitchCase="'3'">3 - To origin</span>
                    </div>
                </div>
                <div class="dtrow">
                    <div class="dtcell">Road:</div>
                    <div class="dtcell">
                        <ng-container *ngIf="!!currentClm; else loading">
                            <span class="data">{{ currentClm.road }}</span>
                        </ng-container>
                    </div>
                </div>
                <div class="dtrow">
                    <div class="dtcell">CLM Destination:</div>
                    <div class="dtcell">
                        <ng-container *ngIf="!!currentClm; else loading">
                            <span
                                class="data"
                                [class.text-danger]="!resolved && isClmDestinationInError | async"
                                >{{ currentClm.destinationCity }},
                                {{ currentClm.destinationState }}</span
                            >
                        </ng-container>
                    </div>
                </div>
                <div class="dtrow">
                    <div class="dtcell">
                        <span *ngIf="!!trip">
                            <ng-container *ngIf="trip.carStatus === '3'; else shipToDestLabel"
                                >Return</ng-container
                            >
                            <ng-template #shipToDestLabel>Trip</ng-template>
                        </span>
                        Destination:
                    </div>
                    <div class="dtcell">
                        <ng-container *ngIf="!!clms && !!trip; else loading">
                            <ng-container *ngIf="trip.carStatus === '3'; else shipToTripDest">
                                <ng-container *ngIf="!!trip.returnCity; else noTripReturn">
                                    <span
                                        class="data"
                                        [class.text-danger]="
                                            !resolved && isClmDestinationInError | async
                                        "
                                        >{{ trip.returnCity }}, {{ trip.returnState }}</span
                                    >
                                </ng-container>
                                <ng-template #noTripReturn>
                                    <span><i class="text-danger">Return not set</i></span>
                                </ng-template>
                            </ng-container>
                            <ng-template #shipToTripDest>
                                <ng-container *ngIf="!!trip.destinationCity; else noTripDest">
                                    <span
                                        class="data"
                                        [class.text-danger]="
                                            !resolved && isClmDestinationInError | async
                                        "
                                        >{{ trip.destinationCity }},
                                        {{ trip.destinationState }}</span
                                    >
                                </ng-container>
                                <ng-template #noTripDest>
                                    <span><i class="text-danger">Destination not set</i></span>
                                </ng-template>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12" *ngIf="!!trip">
            <ng-container *ngIf="trip.carStatus === '3'; else toDestinationInfo">
                <h3 class="mini-header">Origin Criteria</h3>
                <div class="dtable">
                    <div class="dtrow">
                        <div class="dtcell">Has Been Diverted:</div>
                        <ng-container
                            [ngSwitch]="trip.divertYorN.toUpperCase()"
                            *ngIf="!!trip; else loading"
                        >
                            <span class="data" *ngSwitchCase="'Y'">Yes</span>
                            <span class="data" *ngSwitchDefault>No</span>
                        </ng-container>
                    </div>
                    <ng-container
                        *ngIf="
                            (trip.divertYorN || 'N').toUpperCase() === 'N';
                            else isDivertedCriteria
                        "
                    >
                        <div class="dtrow">
                            <div class="dtcell">Route:</div>
                            <span class="data" *ngIf="!!routeCode; else loading">{{
                                routeCode.routeCode
                            }}</span>
                        </div>
                        <div class="dtrow">
                            <div class="dtcell">Origin Code:</div>
                            <span class="data" *ngIf="!!routeCode; else loading">{{
                                routeCode.originCode
                            }}</span>
                        </div>
                        <div class="dtrow">
                            <div class="dtcell">Arrival Criteria Cities:</div>
                            <div class="dtcell">
                                <ng-container *ngTemplateOutlet="listOfCities"></ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #isDivertedCriteria>
                        <div class="dtrow">
                            <div class="dtcell">Return City:</div>
                            <span class="data">{{ trip.returnCity }}, {{ trip.returnState }}</span>
                        </div>
                        <div class="dtrow">
                            <div class="dtcell">ERPC Origin Code:</div>
                            <span class="data" *ngIf="!!erpcOrigin; else loading">{{
                                erpcOrigin.originCode
                            }}</span>
                        </div>
                        <div class="dtrow">
                            <div class="dtcell">Arrival Criteria Cities:</div>
                            <div class="dtcell">
                                <ng-container *ngTemplateOutlet="listOfCities"></ng-container>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
            <ng-template #toDestinationInfo>
                <h3 class="mini-header">Destination Criteria</h3>
                <div class="dtable">
                    <div class="dtrow">
                        <div class="dtcell">Customer:</div>
                        <div class="dtcell">
                            <span class="data" *ngIf="!!customer; else loading">{{
                                customer.customerName
                            }}</span>
                        </div>
                    </div>
                    <div class="dtrow">
                        <div class="dtcell">Destination Criteria Cities:</div>
                        <div class="dtcell">
                            <ng-container *ngTemplateOutlet="listOfCities"></ng-container>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <ng-template #listOfCities>
        <span class="data" *ngIf="!!cities; else loading">
            <i class="data" *ngIf="cities.size === 0; else listOfCities">None</i>
            <ng-template #listOfCities>
                <div *ngFor="let cs of cities">{{ cs }}</div>
            </ng-template>
        </span>
    </ng-template>
</div>
<rt-jeopardized-shipment-reason-common
    [trip]="trip"
    [useExpansionPanel]="false"
    (saveClick)="onTripSaveClicked($event)"
    (popoutTrip)="onPopoutTripclicked($event)"
></rt-jeopardized-shipment-reason-common>
<ng-template #loading> Loading... </ng-template>
