<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <dx-data-grid id="bdsReportGrid" [dataSource]="gridSource" height="91vh" minHeight="200px">
        <dxi-column caption="From Location" dataField="from_location"></dxi-column>
        <dxi-column caption="To Location" dataField="to_location"></dxi-column>
        <dxi-column caption="Master Origin" dataField="master_origin"></dxi-column>
        <dxi-column caption="Business Group" dataField="business_group"></dxi-column>
        <dxi-column caption="Fleet" dataField="fleet_id"></dxi-column>
        <dxi-column caption="No. of Cars" dataField="no_of_cars"></dxi-column>
        <dxi-column caption="Duration Days" dataField="duration"></dxi-column>
    </dx-data-grid>
    <!-- need to figure out a way to calculate summary columns-->
</div>
