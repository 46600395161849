import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-trip-car-assignment-screen',
    templateUrl: './trip-car-assignment-screen.component.html',
    styleUrls: ['./trip-car-assignment-screen.component.scss'],
})
export class TripCarAssignmentScreenComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
