<div class="row display-grouping">
    <div class="col-md-6 col-12">
        <dx-drop-down-box
            [value]="selectedCategories"
            class="w-100 bds-dx-custom-material bds-mat-dense-form-field dx-mat-form-field-floatLabel-always"
            label="Display Grouping"
            [stylingMode]="'filled'"
            [dataSource]="categoryDataSource"
            valueExpr="categoryName"
            displayExpr="categoryName"
            (onValueChanged)="onCategoryChanged($event)"
        >
            <div *dxTemplate="let data of 'content'">
                <dx-data-grid
                    [dataSource]="categoryDataSource"
                    [selection]="{ mode: 'multiple', showCheckBoxesMode: 'always' }"
                    [(selectedRowKeys)]="selectedCategories"
                >
                    <dxi-column caption="Category" dataField="categoryName"></dxi-column>
                    <dxi-column caption="Description" dataField="categoryDscr"></dxi-column>
                </dx-data-grid>
            </div>
        </dx-drop-down-box>
    </div>
</div>

<div class="dynamic-fields">
    <formly-form
        [form]="mechForm"
        [fields]="fields"
        [model]="model"
        (modelChange)="onModelChanged($event)"
    ></formly-form>
</div>
