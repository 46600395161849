import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { nameof } from '@bds/core';
import { RtCarHotListMetadata, TripCarHotListDtoMetadata } from '@bds/railtrac-models';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TripCarHotListDto } from '@bds/railtrac-models';
import { toDatabaseTime } from '@bds/smart-components';

@Injectable()
export class HotListFormService implements OnDestroy {
    private readonly _default = <TripCarHotListDto>{
        hotListCategory: 'GNRL',
        hotListType: 'GNRL',
    };
    private unsub$ = new Subject<void>();

    constructor(
        private fb: UntypedFormBuilder,
        private metadata: RtCarHotListMetadata,
        private dtoMetadata: TripCarHotListDtoMetadata,
    ) {}

    private _form = this.fb.group({
        hotListBulkForm: this.getBulkForm(),
        hotListItemForms: this.fb.array([]),
    });

    get form() {
        return this._form;
    }

    get hotListBulkForm() {
        return this._form.get('hotListBulkForm') as UntypedFormGroup;
    }

    get hotListItemForms() {
        return this._form.get('hotListItemForms') as UntypedFormArray;
    }

    addHotListForm(value: TripCarHotListDto, unsub$: Subject<any>) {
        const formGroup = this.dtoMetadata.getFormGroup();
        // formGroup.addControl(nameof<TripCarHotListDto>('tripId'), new FormControl());
        // formGroup.addControl(
        //     nameof<TripCarHotListDto>('propagateHotListForward'),
        //     new FormControl(),
        // );
        formGroup.get(nameof<TripCarHotListDto>('hotListDate')).disable();
        formGroup.get(nameof<TripCarHotListDto>('hotListCategory')).disable();
        formGroup.get(nameof<TripCarHotListDto>('hotListType')).disable();

        if (!value.hotListDate) {
            value.hotListDate = toDatabaseTime();
        }

        if (!value.ormId) {
            value.ormId = 0;
        }

        if (value.carInit) {
            formGroup.get(nameof<TripCarHotListDto>('carInit')).disable();
        }
        if (value.carNo) {
            formGroup.get(nameof<TripCarHotListDto>('carNo')).disable();
        }

        this.hotListItemForms.push(formGroup);
        return formGroup;
    }

    ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.complete();
    }

    private getBulkForm() {
        const form = this.metadata.getFormGroup();
        form.get(nameof<TripCarHotListDto>('carNo')).disable();
        form.get(nameof<TripCarHotListDto>('carInit')).disable();
        form.patchValue(this._default);

        this.watchChanges(nameof<TripCarHotListDto>('commentType'), form);
        this.watchChanges(nameof<TripCarHotListDto>('commentCode'), form);
        this.watchChanges(nameof<TripCarHotListDto>('comments'), form);

        return form;
    }

    private watchChanges(controlName: string, form: UntypedFormGroup) {
        form.get(controlName)
            .valueChanges.pipe(
                takeUntil(this.unsub$),
                tap((value) => {
                    this.hotListItemForms.controls.forEach((control) => {
                        control.get(controlName).setValue(value);
                    });
                }),
            )
            .subscribe();
    }
}
