import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RtCarHotListService } from '@bds/data-access';
import { RtCarHotList } from '@bds/railtrac-models';
import { firstValueFrom } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'bds-hot-list-details-screen',
    template: ` <bds-hot-list-details
        [hotLists]="hotLists$ | async"
        (saved)="ngOnInit()"
    ></bds-hot-list-details>`,
})
export class BdsHotListDetailScreenComponent implements OnInit {
    hotLists$: Observable<RtCarHotList[]>;

    constructor(private bdsCarHotListService: RtCarHotListService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.hotLists$ = this.route.queryParams.pipe(
            switchMap((response: Params) => {
                const ids = response['ids'];
                return this.bdsCarHotListService.getByIds(ids);
            }),
        );
    }
}
