import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
} from '@angular/core';
import { dxMatStyle } from '../../helpers/dx-mat-style';
import DataSource from 'devextreme/data/data_source';
import { BdsAbstractControlValueAccessor } from '../../abstract-components/bds-abstract-control-value.accessor';
import { RtShipmentType, RtSupplierFact } from '@bds/railtrac-models';
import { nameof } from '@bds/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BdsSupplierService } from '@bds/data-access';

@Component({
    selector: 'bds-supplier-select',
    template: ` <dx-select-box
        [stylingMode]="style"
        [class.bds-mat-dense-form-field]="dense"
        [class.dx-mat-form-field-floatLabel-always]="matFloatLabel == 'always'"
        class="bds-dx-custom-material flex-fill"
        [dataSource]="dataSource"
        [valueExpr]="valueExp"
        [searchExpr]="searchExp"
        [searchEnabled]="true"
        [placeholder]="label"
        [(ngModel)]="value"
        fieldTemplate="inputField"
        (onSelectionChanged)="onSelectionChanged($event)"
    >
        <div *dxTemplate="let data of 'item'">
            {{ getDisplayValue(data) }}
        </div>
        <div *dxTemplate="let data of 'inputField'">
            <dx-text-box
                style="display:inline-block"
                width="100%"
                [placeholder]="label"
                [value]="getDisplayValue(data)"
            ></dx-text-box>
        </div>
    </dx-select-box>`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BdsSupplierSelectComponent),
            multi: true,
        },
    ],
})
export class BdsSupplierSelectComponent
    extends BdsAbstractControlValueAccessor<string>
    implements OnInit, OnChanges
{
    dataSource: DataSource;
    searchExp: string[];
    valueExp: string;

    @Input() filter: [] = null;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() dense = true;
    @Input() matFloatLabel = 'always';
    @Input() label = 'Supplier';
    @Input() pageSize = 10;
    @Input() displayValue = function (data: RtSupplierFact) {
        return data ? `${data.name} - ${data.code}` : '';
    };
    @Output() selectionChange: EventEmitter<RtSupplierFact> = new EventEmitter();

    get style(): string {
        return dxMatStyle(this.matStyle);
    }

    constructor(
        private supplierApiService: BdsSupplierService,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(ngControl);
    }

    ngOnInit() {
        this.searchExp = [
            this.supplierApiService.adapter.metadata.find(
                (f) => f.client === nameof<RtSupplierFact>('code'),
            ).server,
            this.supplierApiService.adapter.metadata.find(
                (f) => f.client === nameof<RtSupplierFact>('name'),
            ).server,
        ];
        this.valueExp = this.supplierApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtSupplierFact>('code'),
        ).server;

        this.setDataSource();
    }

    getDisplayValue(data: RtShipmentType) {
        return this.displayValue(data);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filter) {
            this.value = null;
            this.setDataSource();
        }
    }

    onSelectionChanged(event) {
        if (event && event.selectedItem) {
            this.selectionChange.emit(event.selectedItem);
        }
    }

    setDataSource() {
        this.dataSource = new DataSource({
            store: this.supplierApiService.getODataStore(),
            filter: this.filter,
            paginate: true,
            pageSize: 10,
        });
    }
}
