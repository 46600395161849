import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rt-bds-fleet',
    templateUrl: './bds-fleet.component.html',
    styleUrls: ['./bds-fleet.component.css'],
})
export class BdsFleetComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
