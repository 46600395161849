import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class DateOnlyFixService {
    public addDaysToDate(date: Date, daysToAdd: number): Date {
        const adjustDate = new Date(date);
        return new Date(
            adjustDate.getFullYear(),
            adjustDate.getMonth(),
            adjustDate.getDate() + daysToAdd,
        );
    }

    public addDaysToUtcDate(date: Date, daysToAdd: number): Date {
        const momentDate = moment.utc(date).add(daysToAdd, 'days');
        return new Date(momentDate.year(), momentDate.month(), momentDate.date());
    }

    public createDateOnly(date: Date): Date {
        const adjDate = new Date(date);
        return new Date(adjDate.getFullYear(), adjDate.getMonth(), adjDate.getDate());
    }

    public createUtcDateOnly(initialDate: Date | undefined | null): Date | undefined | null {
        if (initialDate) {
            const momentDate = moment.utc(initialDate);

            if (momentDate.isValid()) {
                const adjustedDate = new Date(
                    momentDate.year(),
                    momentDate.month(),
                    momentDate.date(),
                );
                return adjustedDate;
            }
        }

        return initialDate;
    }

    public datesOnlyEqual(date1: Date | undefined, date2: Date | undefined): boolean {
        if (date1 && date2) {
            const compareDate1 = new Date(date1);
            const compareDate2 = new Date(date2);

            return (
                compareDate1.getFullYear() === compareDate2.getFullYear() &&
                compareDate1.getMonth() === compareDate2.getMonth() &&
                compareDate1.getDate() === compareDate2.getDate()
            );
        }
        return false;
    }
}
