<h1 mat-dialog-title>Add Customer</h1>
<div mat-dialog-content>
    <dx-data-grid
        #grid
        bds-grid
        id="bdsRouteGrid"
        [dataSource]="dataSource"
        height="35vh"
        minHeight="200px"
        bdsGridMode="multiple"
        [bdsUseZeroPadDates]="true"
    >
    </dx-data-grid>
</div>
<div mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" mat-dialog-close>Cancel</button>
    <button mat-button cdkFocusInitial class="text-uppercase" color="accent" (click)="add()">
        Add
    </button>
</div>
