import { IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { FormGroupDescription } from './adapter_t';

export class RtvEdiInfo {
    constructor(
        public ormId: number,
        public carInit: string,
        public carNo: string,
        public carId: string,
        public shipDateTime: Date,
        public importSource: string,
        public processInTp: string,
        public freightPayer: string,
        public isaGsControlNo: string,
        public gsSenderCode: string,
        public gsDate: string,
        public gsTime: string,
        public ediType: string,
        public st03: string,
        public lineText: string,
        public lineNo?: number,        
    ) {}
    from(ediInfo: RtvEdiInfo): RtvEdiInfo {
        Object.keys(ediInfo).forEach((k) => (this[k] = ediInfo[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtvEdiInfo>('ormId'), server: 'ormId' },
    { client: nameof<RtvEdiInfo>('carInit'), server: 'carInit' },
    { client: nameof<RtvEdiInfo>('carNo'), server: 'carNo' },
    { client: nameof<RtvEdiInfo>('carId'), server: 'carId' },
    { client: nameof<RtvEdiInfo>('shipDateTime'), server: 'shipDateTime' },
    { client: nameof<RtvEdiInfo>('importSource'), server: 'importSource' },
    { client: nameof<RtvEdiInfo>('processInTp'), server: 'processInTp' },
    { client: nameof<RtvEdiInfo>('freightPayer'), server: 'freightPayer' },
    { client: nameof<RtvEdiInfo>('isaGsControlNo'), server: 'isaGsControlNo' },
    { client: nameof<RtvEdiInfo>('gsSenderCode'), server: 'gsSenderCode' },
    { client: nameof<RtvEdiInfo>('gsDate'), server: 'gsDate' },
    { client: nameof<RtvEdiInfo>('gsTime'), server: 'gsTime' },
    { client: nameof<RtvEdiInfo>('ediType'), server: 'ediType' },
    { client: nameof<RtvEdiInfo>('st03'), server: 'st03' },
    { client: nameof<RtvEdiInfo>('lineText'), server: 'lineText' },
    { client: nameof<RtvEdiInfo>('lineNo'), server: 'lineNo' },
];

@Injectable({
    providedIn: 'root',
})
export class RtvEdiInfoAdapter extends TwoWayAdapter<RtvEdiInfo> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtvEdiInfoMetadata implements FormGroupDescription {
    constructor() {}

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtvEdiInfo>('ormId'), new UntypedFormControl(''));        
        formGroup.addControl(nameof<RtvEdiInfo>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('carId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('shipDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('importSource'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('processInTp'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('freightPayer'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('isaGsControlNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('gsSenderCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('gsDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('gsTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('ediType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('st03'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('lineText'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvEdiInfo>('lineNo'), new UntypedFormControl(''));

        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
