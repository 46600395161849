/*
 * Public API Surface of bds-equipment
 */
export * from './lib/models';
export * from './lib/data-access';

export { EquipmentModule } from './lib/screens/equipment/equipment.module';
export { EquipmentComponentsModule } from './lib/screens/equipment-components/equipment-components.module';
export { EquipmentDetailsModule } from './lib/screens/equipment-details/equipment-details.module';
export { EquipmentReportCategoriesModule } from './lib/screens/equipment-report-categories/equipment-report-categories.module';

export { EquipmentReportCategoriesDialogModule } from './lib/screens/equipment-report-categories-dialog/equipment-report-categories-dialog.module';
export * from './lib/screens/equipment-report-categories-dialog/equipment-report-categories-dialog.component';
export * from './lib/screens/equipment-report-categories-dialog/equipment-report-categories-dialog.model';
