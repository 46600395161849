<div [formGroup]="destinationForm" class="destination-criteria-details">
    <div class="row">
        <div class="col-8">
            <dx-lookup
                [stylingMode]="dxMatStyle"
                [ngClass]="{
                    'bds-readonly-wrapper': !isNew || !editableCustomer,
                    'bds-mat-dense-form-field': dense,
                    'dx-mat-form-field-floatLabel-always': matFloatLabel == 'always'
                }"
                class="bds-dx-custom-material flex-fill"
                name="customerNo"
                formControlName="customerNo"
                [dataSource]="customerSource"
                [displayExpr]="customerDisplayExpr"
                [valueExpr]="'customerNo'"
                [searchEnabled]="true"
                [searchTimeout]="200"
                itemTemplate="item"
                fieldTemplate="field"
                [showClearButton]="true"
                label="Customer"
            >
                <div *dxTemplate="let data of 'field'">
                    <div class="dx-placeholder" *ngIf="!data">Select Customer</div>
                    <div class="custom-icon" *ngIf="data">
                        {{ data.customerNo }} - {{ data.customerName }}
                    </div>
                </div>
                <div *dxTemplate="let data of 'item'">
                    <div class="custom-icon">{{ data.customerNo }} - {{ data.customerName }}</div>
                </div>
            </dx-lookup>
            <mat-error *ngIf="destinationForm.controls.customerNo.errors">
                {{ getError($any(destinationForm).controls.customerNo) }}
            </mat-error>
        </div>
        <div class="col-4">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Cust Delivery Hrs</mat-label>
                <input
                    matInput
                    type="number"
                    formControlName="customerDeliveryHours"
                    min="0"
                    max="999"
                />
                <mat-error *ngIf="destinationForm.controls.customerDeliveryHours.errors">
                    {{ getError($any(destinationForm).controls.customerDeliveryHours) }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                [ngClass]="{
                    'bds-readonly-wrapper': !isClone && !isNew,
                    'bds-mat-dense-form-field': dense
                }"
                appearance="fill"
            >
                <mat-label>Sight Code</mat-label>
                <mat-select formControlName="sightCode">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let item of sightCodes" [value]="item.id">
                        {{ item.id }} - {{ item.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="destinationForm.controls.sightCode.errors">
                    {{ getError($any(destinationForm).controls.sightCode) }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <bds-railroad-select
                formControlName="railroad"
                label="Railroad"
                class="bds-readonly-wrapper"
            ></bds-railroad-select>
            <mat-error *ngIf="destinationForm.controls.railroad.errors">
                {{ getError($any(destinationForm).controls.railroad) }}
            </mat-error>
        </div>
    </div>
    <div class="row">
        <div class="col-8">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                [ngClass]="{
                    'bds-readonly-wrapper': !isNew,
                    'bds-mat-dense-form-field': dense
                }"
                appearance="fill"
            >
                <mat-label>Location City</mat-label>
                <input
                    matInput
                    bdsShoutcase
                    formControlName="locationCity"
                    [readonly]="!isNew && !editableCustomer"
                    class="text-uppercase"
                />
                <mat-error *ngIf="destinationForm.controls.locationCity.errors">
                    {{ getError($any(destinationForm).controls.locationCity) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                [ngClass]="{
                    'bds-readonly-wrapper': !isNew,
                    'bds-mat-dense-form-field': dense
                }"
                appearance="fill"
            >
                <mat-label>State</mat-label>
                <mat-select formControlName="locationState">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let item of states" [value]="item.code">
                        {{ item.code }} - {{ item.description }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="destinationForm.controls.locationState.errors">
                    {{ getError($any(destinationForm).controls.locationState) }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
