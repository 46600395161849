import { Component, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faSave } from '@bds/fa-svg-icons';
import { RtSegmentMiles } from '@bds/railtrac-models';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap, tap } from 'rxjs/operators';
import { BdsSegmentMilesService } from '../bds-segment-miles.service';

@Component({
    selector: 'bds-segment-miles-details',
    templateUrl: './bds-segment-miles-details.component.html',
    styleUrls: ['./bds-segment-miles-details.component.css'],
})
export class BdsSegmentMilesDetailsComponent implements OnInit {
    iconSave = faSave;
    segmentMiles$: Observable<RtSegmentMiles[]>;

    constructor(
        private route: ActivatedRoute,
        private service: BdsSegmentMilesService,
        private router: Router,
    ) {}

    getSegmentMiles(idParam: string) {
        const ids = idParam.split(',').map((x) => +x);
        return this.service.getByIds(ids);
    }

    navigate(ids: number[]) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { ids: ids.join(',') },
            queryParamsHandling: 'merge',
        });
    }

    ngOnInit(): void {
        this.segmentMiles$ = this.route.queryParams.pipe(
            switchMap((params) => {
                if (params.ids) {
                    return this.getSegmentMiles(params.ids);
                } else {
                    return of(<RtSegmentMiles[]>[{}]);
                }
            }),
        );
    }

    save(form: UntypedFormArray) {
        if (form.valid) {
            const values = form.getRawValue();
            if (values.length >= 1 && values[0].ormId > 0) {
                forkJoin(values.map((value) => this.service.update(value)))
                    .pipe(
                        tap((response) => {
                            const ids = response.map((x: any) => x.relatedObject.ormId);
                            this.navigate(ids);
                        }),
                    )
                    .subscribe();
            } else {
                forkJoin(values.map((value) => this.service.create(value)))
                    .pipe(
                        tap((response) => {
                            const ids = response.map((x: any) => x.relatedObject.ormId);
                            this.navigate(ids);
                        }),
                    )
                    .subscribe();
            }
        }
    }
}
