import { Injectable, Inject } from '@angular/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

@Injectable({
    providedIn: 'root',
})
export class RailtracReportsService {
    controllername = 'Reports';
    constructor(
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        @Inject('BASE_DEV_EXPRESS_URL') private devExpressBaseUrl: string,
    ) {}

    CreateAspNetDatastore(
        apiCall: string,
        reportName: string = null,
        startDate: string = null,
        endDate: string = null,
        queryType: string = null,
    ): any {
        return AspNetData.createStore({
            key: '',
            loadUrl: `${this.devExpressBaseUrl}${this.controllername}/${apiCall}`,
            onBeforeSend(method, ajaxOptions) {
                ajaxOptions.headers = {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Credentials': 'true',
                    'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,POST,PUT',
                    'Access-Control-Allow-Headers':
                        'Origin, X-Requested-With, Content-Type, Accept, Authorization',
                };
                if (reportName) {
                    ajaxOptions.data.reportName = reportName;
                }
                if (startDate) {
                    ajaxOptions.data.startDate = startDate;
                }
                if (endDate) {
                    ajaxOptions.data.endDate = endDate;
                }
                if (queryType) {
                    ajaxOptions.data.queryType = queryType;
                }
            },
        });
    }
}
