import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import { EquipReportCatgEquip } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class EquipReportCatgEquipService {
    controllerName = 'EquipReportCategoryEquip';

    constructor(
        private http: HttpClient,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    create(equipcategoryequip: EquipReportCatgEquip): Observable<EquipReportCatgEquip> {
        return this.http
            .post<EquipReportCatgEquip>(
                `${this.apibaseUrl}${this.controllerName}`,
                equipcategoryequip,
            )
            .pipe(
                map((data: EquipReportCatgEquip) => {
                    return data ? data : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getFiltered(filter: string, expand?: string[]): Observable<EquipReportCatgEquip[]> {
        let filterUrl = '';

        if (filter) {
            filterUrl = '&$filter=' + filter;
        }

        let expandUrl = `?$expand=equipReportCatgValue`;

        if (expand && expand.length > 0) {
            expand.forEach((child) => (expandUrl = expandUrl + ',' + child));
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
        return (
            this.http
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .get<any>(`${this.odatabaseUrl}${this.controllerName}` + expandUrl + filterUrl)
                .pipe(
                    map((data) =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                        data.value.map((i) => {
                            const equip = i as EquipReportCatgEquip;
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            equip.equipReportCatgId =
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                i.equipReportCatgValue.equipReportCatgId || null;
                            return equip;
                        }),
                    ),
                    shareReplay(),
                    catchError((err) => this.apiErrorHandler.handleError(err)),
                )
        );
    }

    getODataStore(): ODataStore {
        const keyField = nameof<EquipReportCatgEquip>('ormId');

        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: keyField,
        });
    }

    read(key: number, expand?: string[]): Observable<EquipReportCatgEquip> {
        let expandUrl = '';
        if (expand && expand.length > 0) {
            expandUrl = '?$expand=';
            expand.forEach((child) => (expandUrl = expandUrl + child + ','));
            expandUrl = expandUrl.slice(0, expandUrl.length - 1);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
        return this.http
            .get<EquipReportCatgEquip>(
                `${this.odatabaseUrl}${this.controllerName}(${key})` + expandUrl,
            )
            .pipe(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
                map((data: any) => data),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    // TODO: Why doesn't this use the key in the API call?
    update(
        key: number,
        equipcategoryequip: EquipReportCatgEquip,
    ): Observable<EquipReportCatgEquip> {
        return this.http
            .put<EquipReportCatgEquip>(
                `${this.apibaseUrl}${this.controllerName}`,
                equipcategoryequip,
            )
            .pipe(
                map((data: EquipReportCatgEquip) => {
                    return data ? data : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}
