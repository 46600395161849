export function dxMatStyle(matStyle: string): string {
    switch (matStyle) {
        case 'fill':
            return 'filled';
        case 'outline':
            return 'outlined';
        case 'standard':
            return 'underlined';
        default:
            return 'underlined';
    }
}
