import { FormErrorHandlerService } from '@bds/helpers';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { RtCustomerAlias, RtStateCode } from '@bds/railtrac-models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BdsStateService } from '@bds/data-access';
import { DialogOptionsBase } from '@bds/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export class RtCustomerAliasDialogOptions extends DialogOptionsBase<
    RtCustomerAlias | RtCustomerAlias[]
> {
    title: string;
}

@Component({
    templateUrl: './rt-customer-alias-dialog.component.html',
    styleUrls: ['./rt-customer-alias-dialog.component.scss'],
})
export class RtCustomerAliasDialogComponent implements OnInit {
    public stateList: RtStateCode[] = [];
    public showSuccess: boolean = false;

    forms: UntypedFormGroup[] = [];
    formArray: UntypedFormArray = new UntypedFormArray([]);

    // bannerMessage: string;

    constructor(
        public dialogRef: MatDialogRef<
            RtCustomerAliasDialogComponent,
            RtCustomerAlias | RtCustomerAlias[]
        >,
        private renderer: Renderer2,
        @Inject(MAT_DIALOG_DATA) public data: RtCustomerAliasDialogOptions,
        public snackbar: MatSnackBar,
        public errorService: FormErrorHandlerService,
        public stateService: BdsStateService,
    ) {}

    ngOnInit() {
        this.stateService.getLookup().subscribe((scl) => {
            this.stateList.push(...scl);
        });

        if (this.data && this.data.item) {
            if (Array.isArray(this.data.item)) {
                this.data.item.map((m) => this.forms.push(this.createFormGroup(m)));
            } else {
                this.forms.push(this.createFormGroup(this.data.item));
            }
            this.forms.map((m) => this.formArray.push(m));
        }
    }

    createFormGroup(obj?: RtCustomerAlias) {
        const alias: RtCustomerAlias = !!obj ? obj : new RtCustomerAlias();

        const newForm = new UntypedFormGroup({
            custName: new UntypedFormControl(obj.custName, [
                Validators.required,
                Validators.maxLength(60),
            ]),
            custCity: new UntypedFormControl(obj.custCity, [
                Validators.required,
                Validators.maxLength(25),
            ]),
            custState: new UntypedFormControl(obj.custState, [
                Validators.required,
                Validators.maxLength(2),
            ]),
            custNo: new UntypedFormControl(obj.custNo, [
                Validators.required,
                Validators.maxLength(13),
            ]),
            ormId: new UntypedFormControl(obj.ormId, [Validators.required, Validators.min(0)]),
        });

        return newForm;
    }

    displayStateCode(stateCode: RtStateCode): string {
        return stateCode && stateCode.code ? stateCode.code : null;
    }

    getErrorMessage(control: UntypedFormControl) {
        let msg = this.errorService.getShortFormError(control);
        if (!msg) {
            msg = 'Unable to save';
        }

        return msg;
    }

    processConcreteResults(action, result) {
        if (typeof action === 'boolean') {
            if (action) {
                this.dialogRef.close(result);
            }
        } else if (typeof action === 'string') {
            console.warn('Not yet sure how to process (string) results = ', result, action);
            // Not yet used for anything
        } else if (Array.isArray(action)) {
            console.warn('Not yet sure how to process (Array) results = ', result, action);

            const allTrue = action.reduce((pv, cv, idx) => pv === true && cv === true, true);
            if (allTrue === true) {
                this.snackbar.open(
                    `${result.length > 1 ? `${result.length} a` : 'A'}lias${
                        result.length > 1 ? `es` : ''
                    } saved!`,
                    'OK',
                    { duration: 3000 },
                );
                this.dialogRef.close(result);
            }

            for (let idx: number = 0; idx < action.length; idx++) {
                if (action[idx] !== true) {
                    this.forms[idx].setErrors({ apiFault: action[idx] });
                }
            }
            // Array is a string of information
            // Could also be array of objects
        } else {
            console.warn('Not yet sure how to process (any) results = ', result, action);
            // Assume it's a model state dictionary
        }
    }

    onSaveAndCloseClick() {
        console.log('save and close', this.formArray.value);
        if (this.formArray.valid) {
            let result: RtCustomerAlias | RtCustomerAlias[];
            if (this.forms.length === 1) {
                result = RtCustomerAlias.from(this.forms[0].value);
            } else {
                result = this.forms.map((m) => RtCustomerAlias.from(m.value));
            }
            if (!!this.data && !!this.data.beforeCloseAction) {
                const action = this.data.beforeCloseAction(result);
                if (action instanceof Observable) {
                    action.subscribe((actResult) => {
                        this.processConcreteResults(actResult, result);
                    });
                } else {
                    this.processConcreteResults(action, result);
                }
            }
        }
    }

    resetForm() {
        this.formArray.clear();
        this.forms.splice(0, this.forms.length, this.createFormGroup());
        this.formArray.push(this.forms[0]);
    }
    onSaveAndNewClick() {
        console.log('save and new', this.formArray.value);
        if (this.formArray.valid) {
            let result: RtCustomerAlias;
            result = RtCustomerAlias.from(this.forms[0].value);
            if (!!this.data && !!this.data.beforeCloseAction) {
                const action = this.data.beforeCloseAction(result);
                if (typeof action === 'boolean') {
                    if (action) {
                        this.snackbar.open('Alias saved!', 'OK', { duration: 3000 });
                        this.showSaved();
                        this.resetForm();
                        this.focusFirstField();
                    } else {
                    }
                } else if (typeof action === 'string') {
                } else {
                    action.subscribe((actResult) => {
                        if (typeof actResult === 'boolean') {
                            if (actResult) {
                                this.snackbar.open('Alias saved!', 'OK', { duration: 3000 });
                                this.showSaved();
                                this.resetForm();
                                this.focusFirstField();
                            }
                        }
                    });
                }
            }
        }
    }

    focusFirstField() {
        const firstField: HTMLInputElement = this.renderer.selectRootElement('#firstField');
        firstField.focus();
    }
    showSaved(duration: number = 3000) {
        this.showSuccess = true;
        setTimeout(() => {
            this.showSuccess = false;
        }, duration);
    }

    onNoClick() {
        this.dialogRef.close();
    }
}
