import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';
import { FormGroupDescription } from './adapter_t';
import { RtCarHotList } from './rt-car-hot-list';

@Injectable({
    providedIn: 'root',
})
export class TripCarHotListDto extends RtCarHotList {
    constructor(public propagateHotListForward: boolean = false, public tripId?: number) {
        super();
    }
    from(hotlistDto: TripCarHotListDto): TripCarHotListDto {
        Object.keys(hotlistDto).forEach((k) => (this[k] = hotlistDto[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    {
        client: nameof<TripCarHotListDto>('propagateHotListForward'),
        server: 'propagateHotListForward',
    },
    { client: nameof<TripCarHotListDto>('tripId'), server: 'tripId' },
    { client: nameof<TripCarHotListDto>('carInit'), server: 'carInit' },
    { client: nameof<TripCarHotListDto>('carNo'), server: 'carNo' },
    { client: nameof<TripCarHotListDto>('hotListDate'), server: 'hotListDate' },
    { client: nameof<TripCarHotListDto>('hotListType'), server: 'hotListType' },
    { client: nameof<TripCarHotListDto>('hotListCategory'), server: 'hotListCategory' },
    { client: nameof<TripCarHotListDto>('acknowledgeFlag'), server: 'acknowledgeFlag' },
    { client: nameof<TripCarHotListDto>('comments'), server: 'comments' },
    { client: nameof<TripCarHotListDto>('userResponse'), server: 'userResponse' },
    { client: nameof<TripCarHotListDto>('userId'), server: 'userId' },
    { client: nameof<TripCarHotListDto>('acknowledgeDate'), server: 'acknowledgeDate' },
    { client: nameof<TripCarHotListDto>('acknowledgeUserId'), server: 'acknowledgeUserId' },
    { client: nameof<TripCarHotListDto>('commentType'), server: 'commentType' },
    { client: nameof<TripCarHotListDto>('commentCode'), server: 'commentCode' },
    { client: nameof<TripCarHotListDto>('ormId'), server: 'ormId' },
];

@Injectable({
    providedIn: 'root',
})
export class TripCarHotListDtoMetadata implements FormGroupDescription {
    constructor() {}

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<TripCarHotListDto>('propagateHotListForward'),
            new UntypedFormControl(false),
        );
        formGroup.addControl(nameof<TripCarHotListDto>('tripId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('hotListDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('hotListType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('hotListCategory'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('acknowledgeFlag'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<TripCarHotListDto>('comments'),
            new UntypedFormControl('', [Validators.maxLength(250)]),
        );
        formGroup.addControl(
            nameof<TripCarHotListDto>('userResponse'),
            new UntypedFormControl('', [Validators.maxLength(250)]),
        );
        formGroup.addControl(nameof<TripCarHotListDto>('userId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('acknowledgeDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('acknowledgeUserId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('commentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('commentCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<TripCarHotListDto>('ormId'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}

@Injectable({
    providedIn: 'root',
})
export class TripCarHotListDtoAdapter extends TwoWayAdapter<TripCarHotListDto> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}
