import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { nameof } from '@bds/core';
import { RtRouteService } from '@bds/data-access';
import { RtRouteCode } from '@bds/railtrac-models';
import { DxLookupComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { BdsMatFormField } from '../../abstract-components/bds-abstract-control-value.accessor';
import { dxMatStyle } from '../../helpers/dx-mat-style';

@Component({
    selector: 'bds-route-select',
    templateUrl: './bds-route-select.component.html',
    styleUrls: ['./bds-route-select.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: BdsRouteSelectComponent }],
})
export class BdsRouteSelectComponent extends BdsMatFormField<string> implements OnInit {
    dataSource: DataSource;
    @Input() dense = true;
    @Input() matFloatLabel = 'always';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';

    displayExp: string[] = [nameof<RtRouteCode>('routeCode'), nameof<RtRouteCode>('routeDscr')];
    otherDisplayExp: string[] = [nameof<RtRouteCode>('routeCode'), 'routeDescription'];
    id: string;
    key = nameof<RtRouteCode>('routeCode');
    popupDim = { minWidth: '250px' };

    @Output() selected = new EventEmitter<RtRouteCode>();
    @ViewChild(DxLookupComponent) dxSelectBox;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private routeService: RtRouteService,
    ) {
        super(ngControl);
    }

    @Input() set label(plh: string) {
        super.placeholder = plh;
    }

    get style(): string {
        return dxMatStyle(this.matStyle);
    }

    defaultDisplay = (data: any): string =>
        data ? `${data.routeCode} - ${data.routeDescription}` : '';
    @Input() displayValue = this.defaultDisplay;
    getDisplayValue(data: any) {
        return this.displayValue(data);
    }

    ngOnInit(): void {
        this.dataSource = new DataSource({
            store: this.routeService.getODataStore(),
            paginate: true,
            pageSize: 10,
            searchExpr: this.otherDisplayExp,
            select: this.otherDisplayExp,
            sort: this.otherDisplayExp,
            key: this.key,
        });
    }

    valueChanged() {
        this.selected.emit(this.dxSelectBox.selectedItem);
    }
}
