<h1 mat-dialog-title>Origin Criteria Info</h1>

<mat-dialog-content>
    <div class="bds-error-message">{{ errorMessage }}</div>
    <div>
        <bds-origin-criteria-details
            [originCriteria]="originCriteria"
            [originCodeStore]="originCodeStore"
            [railroadStore]="railroadStore"
            [states]="stateLookup$ | async"
            [sightCodes]="sightCodeLookup$ | async"
            [isNew]="isNew"
            [isClone]="isClone"
            [editableOriginCode]="editableOriginCode"
            (originCriteriaChange)="onOriginCriteriaChanged($event)"
        >
        </bds-origin-criteria-details>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" (click)="onCancelClick()">
        Cancel
    </button>

    <button
        mat-button
        [disabled]="!isValid || isSaving"
        class="text-uppercase"
        color="accent"
        (click)="onSaveClick()"
    >
        Save Origin Criteria
    </button>
</mat-dialog-actions>
