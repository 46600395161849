<form [formGroup]="form">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-sm-4">
                        <bds-splc-select formControlName="beginSplc" label="Begin Splc">
                            <mat-error *ngIf="form.controls.beginSplc.invalid"
                                >Begin Splc is required</mat-error
                            >
                        </bds-splc-select>
                    </div>
                    <div class="col-sm-4">
                        <bds-splc-select formControlName="endSplc" label="End Splc">
                            <mat-error *ngIf="form.controls.endSplc.invalid"
                                >End Splc is required</mat-error
                            >
                        </bds-splc-select>
                    </div>
                    <div class="col-sm-4">
                        <bds-railroad-select formControlName="railroad" label="Railroad">
                            <mat-error *ngIf="form.controls.railroad.invalid"
                                >Railroad is required</mat-error
                            >
                        </bds-railroad-select>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-sm-4">
                        <bds-date-time-picker
                            formControlName="entryDate"
                            label="Entry Date"
                            type="date"
                            displayFormat="MM/dd/yyyy"
                        ></bds-date-time-picker>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field
                            class="highlight-dirty bds-mat-dense-form-field w-100"
                            floatLabel="always"
                            appearance="fill"
                        >
                            <mat-label>User Id <span class="dirty-indicator">*</span> </mat-label>
                            <input matInput bdsShoutcase formControlName="userId" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4">
                        <mat-form-field
                            class="highlight-dirty bds-mat-dense-form-field w-100"
                            floatLabel="always"
                            appearance="fill"
                        >
                            <mat-label>Miles <span class="dirty-indicator">*</span> </mat-label>
                            <input matInput bdsShoutcase formControlName="miles" type="number" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field
                            class="highlight-dirty bds-mat-dense-form-field w-100"
                            floatLabel="always"
                            appearance="fill"
                        >
                            <mat-label
                                >Benchmark Hours <span class="dirty-indicator">*</span>
                            </mat-label>
                            <input
                                matInput
                                bdsShoutcase
                                formControlName="benchmarkHours"
                                type="number"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-sm-4">
                        <mat-form-field
                            class="highlight-dirty bds-mat-dense-form-field w-100"
                            floatLabel="always"
                            appearance="fill"
                        >
                            <mat-label
                                >Weight Limit <span class="dirty-indicator">*</span>
                            </mat-label>
                            <input
                                matInput
                                bdsShoutcase
                                formControlName="weightLimit"
                                type="number"
                            />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field
                            class="highlight-dirty bds-mat-dense-form-field w-100"
                            floatLabel="always"
                            appearance="fill"
                        >
                            <mat-label>Is Active <span class="dirty-indicator">*</span> </mat-label>
                            <input matInput bdsShoutcase formControlName="isActive" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4"></div>
                </div>
            </div>
        </div>
    </div>
</form>
