<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <dx-data-grid
        id="bdsReportGrid"
        [dataSource]="gridSource"
        height="91vh"
        minHeight="200px"
        dataRowTemplate="dataRowTemplateName"
    >
        <dxi-column caption="Master Trip">
            <dxi-column caption="Car ID"></dxi-column>
        </dxi-column>
        <dxi-column caption="Master Origin">
            <dxi-column caption="Ship Date"></dxi-column>
            <dxi-column caption="Prev Cls"></dxi-column>
        </dxi-column>
        <dxi-column caption="Count">
            <dxi-column caption="Trip Cls"></dxi-column>
            <dxi-column caption="O/R"></dxi-column>
            <dxi-column caption="Origin"></dxi-column>
        </dxi-column>
        <dxi-column caption="">
            <dxi-column caption="Destination"></dxi-column>
        </dxi-column>
        <dxi-column caption="">
            <dxi-column caption="Customer Type"></dxi-column>
        </dxi-column>
        <dxi-column caption="">
            <dxi-column caption="Idle Days"></dxi-column>
        </dxi-column>
        <dxi-column caption="">
            <dxi-column caption="Days Out"></dxi-column>
        </dxi-column>
        <dxi-column caption="">
            <dxi-column caption="Hold Days"></dxi-column>
        </dxi-column>
        <dxi-column caption="">
            <dxi-column caption="Days In"></dxi-column>
        </dxi-column>
        <dxi-column caption="">
            <dxi-column caption="Customer"></dxi-column>
        </dxi-column>
        <ng-container *dxTemplate="let item of 'dataRowTemplateName'">
            <tr class="main-row">
                <td>{{ item.data.master_trip_no }}</td>
                <td colspan="2">{{ item.data.master_origin }}</td>
                <td>{{ item.data.master_origin }}</td>
                <td colspan="9"></td>
            </tr>
            <tr class="second-row">
                <td>{{ item.data.car_init }} {{ item.data.car_no }}</td>
                <td>{{ item.data.ship_date_time }}</td>
                <td>{{ item.data.prev_trip_close_date_time }}</td>
                <td>{{ item.data.trip_cls_date_time }}</td>
                <td>{{ item.data.one_way_round }}</td>
                <td>{{ item.data.orig_city }} {{ item.data.orig_state }}</td>
                <td>{{ item.data.dest_city }} {{ item.data.dest_state }}</td>
                <td>{{ item.data.cust_type }}</td>
                <td>{{ item.data.idle_days }}</td>
                <td>{{ item.data.days_out }}</td>
                <td>{{ item.data.hold_days }}</td>
                <td>{{ item.data.days_in }}</td>
                <td>{{ item.data.cust_no }} {{ item.data.cust_name }}</td>
            </tr>
            <tr class="third-row">
                <td><b>Total Days this trip</b> {{ item.data | CalculateTotalDays }}</td>
            </tr>
        </ng-container>
    </dx-data-grid>
</div>
