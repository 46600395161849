import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtvCarHotListGrid implements From<RtvCarHotListGrid> {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public hotListDate?: Date | string,
        public hotListType?: string,
        public hotListCategory?: string,
        public acknowledgeFlag?: string,
        public comments?: string,
        public userResponse?: string,
        public userId?: string,
        public acknowledgeDate?: Date | string,
        public acknowledgeUserId?: string,
        public commentType?: string,
        public commentCode?: string,
        public ormId?: number,
        public fleetId?: string,
        public businessGroup?: string,
    ) {}
    from(trip: RtvCarHotListGrid): RtvCarHotListGrid {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtvCarHotListGrid>('carInit'), server: 'carInit' },
    { client: nameof<RtvCarHotListGrid>('carNo'), server: 'carNo' },
    { client: nameof<RtvCarHotListGrid>('hotListDate'), server: 'hotListDate' },
    { client: nameof<RtvCarHotListGrid>('hotListType'), server: 'hotListType' },
    { client: nameof<RtvCarHotListGrid>('hotListCategory'), server: 'hotListCategory' },
    { client: nameof<RtvCarHotListGrid>('acknowledgeFlag'), server: 'acknowledgeFlag' },
    { client: nameof<RtvCarHotListGrid>('comments'), server: 'comments' },
    { client: nameof<RtvCarHotListGrid>('userResponse'), server: 'userResponse' },
    { client: nameof<RtvCarHotListGrid>('userId'), server: 'userId' },
    { client: nameof<RtvCarHotListGrid>('acknowledgeDate'), server: 'acknowledgeDate' },
    { client: nameof<RtvCarHotListGrid>('acknowledgeUserId'), server: 'acknowledgeUserId' },
    { client: nameof<RtvCarHotListGrid>('commentType'), server: 'commentType' },
    { client: nameof<RtvCarHotListGrid>('commentCode'), server: 'commentCode' },
    { client: nameof<RtvCarHotListGrid>('ormId'), server: 'ormId' },
    { client: nameof<RtvCarHotListGrid>('fleetId'), server: 'fleetId' },
    { client: nameof<RtvCarHotListGrid>('businessGroup'), server: 'businessGroup' },
];

@Injectable({
    providedIn: 'root',
})
export class RtvCarHotListGridAdapter extends TwoWayAdapter<RtvCarHotListGrid> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtvCarHotListGridMetadata implements GridColumnDescription, FormGroupDescription {
    getColumns(defaultVisibility = true): IColumnDescription[] {
        const columns = [
            {
                dataField: nameof<RtvCarHotListGrid>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('hotListDate'),
                dataType: 'Date',
                caption: 'hotListDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('hotListType'),
                dataType: 'string',
                caption: 'hotListType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('hotListCategory'),
                dataType: 'string',
                caption: 'hotListCategory',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('acknowledgeFlag'),
                dataType: 'string',
                caption: 'acknowledgeFlag',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('comments'),
                dataType: 'string',
                caption: 'comments',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('userResponse'),
                dataType: 'string',
                caption: 'userResponse',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('userId'),
                dataType: 'string',
                caption: 'userId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('acknowledgeDate'),
                dataType: 'Date',
                caption: 'acknowledgeDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('acknowledgeUserId'),
                dataType: 'string',
                caption: 'acknowledgeUserId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('commentType'),
                dataType: 'string',
                caption: 'commentType',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('commentCode'),
                dataType: 'string',
                caption: 'commentCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('ormId'),
                dataType: 'number',
                caption: 'ormId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('fleetId'),
                dataType: 'string',
                caption: 'fleetId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtvCarHotListGrid>('businessGroup'),
                dataType: 'string',
                caption: 'businessGroup',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtvCarHotListGrid>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvCarHotListGrid>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvCarHotListGrid>('hotListDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvCarHotListGrid>('hotListType'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtvCarHotListGrid>('hotListCategory'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<RtvCarHotListGrid>('acknowledgeFlag'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<RtvCarHotListGrid>('comments'),
            new UntypedFormControl('', [Validators.maxLength(250)]),
        );
        formGroup.addControl(
            nameof<RtvCarHotListGrid>('userResponse'),
            new UntypedFormControl('', [Validators.maxLength(250)]),
        );
        formGroup.addControl(nameof<RtvCarHotListGrid>('userId'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtvCarHotListGrid>('acknowledgeDate'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(
            nameof<RtvCarHotListGrid>('acknowledgeUserId'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtvCarHotListGrid>('commentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvCarHotListGrid>('commentCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvCarHotListGrid>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtvCarHotListGrid>('fleetId'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtvCarHotListGrid>('businessGroup'),
            new UntypedFormControl(''),
        );

        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
