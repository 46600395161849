import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { FastracDxGridModel } from '@bds/models';
import { FastracLite } from '@bds/models';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'bds-equipment-list',
    templateUrl: './equipment-list.component.html',
    styleUrls: ['./equipment-list.component.scss'],
})
export class EquipmentListComponent implements OnInit, OnChanges, OnDestroy {
    public gridSource: DataSource;
    public selectedRowKeys: number[] = [];

    // Fastrac properties
    public currentFastrac: FastracDxGridModel;
    public emptyFastrac: FastracDxGridModel = new FastracDxGridModel(0, [], [], []);
    public fastracId = 0;
    public fastracList: FastracLite[] = [];
    public showFastrac = false;

    private ngUnsubscribe$: Subject<boolean> = new Subject<boolean>();

    @Input() equipmentStore: ODataStore;
    @Input() gridId = 4;
    @Input() needsRefresh$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    @Input() selectedFastrac: number;
    @Input() selectedItem: number;
    @Output() addEquipment: EventEmitter<void> = new EventEmitter<void>();
    @Output() selectedFastracChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() selectedItemChange: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('bdsEquipmentGrid') dataGrid: DxDataGridComponent;

    constructor() {
        this.currentFastrac = { ...this.emptyFastrac };
    }

    ngOnInit() {
        this.setUpSelectedKeys();
        this.setUpGrid();

        if (this.selectedFastrac) {
            this.fastracId = this.selectedFastrac;
        }

        this.needsRefresh$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            if (this.dataGrid && this.dataGrid.instance) {
                void this.dataGrid.instance.refresh();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.equipmentStore && !changes.equipmentStore.firstChange) {
            this.setUpGrid();
        }

        if (changes.selectedItem && !changes.selectedItem.firstChange) {
            this.setUpSelectedKeys();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(false);
        this.ngUnsubscribe$.complete();
    }

    addNew(): void {
        this.addEquipment.emit();
    }

    onApplyFastrac(event: FastracDxGridModel): void {
        if (event && event.id) {
            this.fastracId = event.id;
            this.selectedFastracChange.emit(event.id);
        }

        this.currentFastrac = { ...event };
    }

    onFastracDesignsChanged(event: FastracLite[]): void {
        this.fastracList = event;
    }

    onFastracIdChanged(event: number): void {
        this.fastracId = event;
        this.selectedFastracChange.emit(event);
    }

    onFastracVisibleChanged(event: boolean): void {
        this.showFastrac = event;
    }

    onGridFastracIdChanged(event: number): void {
        if (this.fastracId !== event) {
            this.fastracId = event;
            this.selectedFastracChange.emit(event);
        }
    }

    onSelectedRowKeysChanged(event: number[]): void {
        // If we eventually add multiple selections, just pass the
        // value out in the emitter
        this.selectedRowKeys = event;
        if (event.length > 0) {
            this.selectedItemChange.emit(event[0]);
        } else {
            this.selectedItemChange.emit(null);
        }
    }

    onShowFastrac(): void {
        this.showFastrac = true;
    }

    setUpGrid(): void {
        this.gridSource = new DataSource({
            store: this.equipmentStore,
            paginate: true,
            pageSize: 20,
        });
    }

    setUpSelectedKeys(): void {
        // If we eventually add multiple selections, replace with
        // this.selectedRowKeys = [...this.selectedItem];
        if (this.selectedItem) {
            this.selectedRowKeys = [this.selectedItem];
        } else {
            this.selectedRowKeys = [];
        }
    }
}
