import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { EquipComponentDate, EquipmentMechDate } from '../../models';
import { EquipComponentDatesService, EquipmentMechDatesService } from '../../data-access';
import { HistoricDatesDialogModel } from './historic-dates-dialog.model';

@Component({
    selector: 'bds-historic-dates-dialog',
    templateUrl: './historic-dates-dialog.component.html',
    styleUrls: ['./historic-dates-dialog.component.scss'],
})
export class HistoricDatesDialogComponent {
    currentNewId = 0;
    dateList: EquipmentMechDate[] | EquipComponentDate[] = [];
    errorMessage = '';
    iconPlus = faPlus;
    isSaving = false;
    isValid = true;
    selectedDate: EquipmentMechDate | EquipComponentDate = null;
    title = 'Add / Edit Dates';

    constructor(
        public dialogRef: MatDialogRef<HistoricDatesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: HistoricDatesDialogModel,
        public equipDatesService: EquipmentMechDatesService,
        public equipComponentDatesService: EquipComponentDatesService,
        private _snackbar: MatSnackBar,
    ) {
        if (!data.componentId) {
            this.title = `Add / Edit Equipment Dates - (${data.mechFieldDescription}) - (${data.equipmentInit} ${data.equipmentNo})`;

            equipDatesService
                .getFiltered(data.equipmentId, data.mechFieldName)
                .pipe(take(1))
                .subscribe((dates) => {
                    this.dateList = dates.sort((a, b) =>
                        a.actualDate > b.actualDate ? -1 : b.actualDate > a.actualDate ? 1 : 0,
                    );
                });
        } else {
            this.title = `Add / Edit Component Dates - (${data.mechFieldDescription}) - (${data.componentDescription})`;

            equipComponentDatesService
                .getFiltered(data.equipmentComponentId, data.equipmentId, data.mechFieldName)
                .pipe(take(1))
                .subscribe((dates) => {
                    this.dateList = dates.sort((a, b) =>
                        a.actualDate > b.actualDate ? -1 : b.actualDate > a.actualDate ? 1 : 0,
                    );
                });
        }
    }

    onAdd(): void {
        // Set up a negative ormId which will be reset prior to save
        this.currentNewId--;

        if (!this.data.componentId) {
            this.selectedDate = {
                ormId: this.currentNewId,
                mechField: this.data.mechFieldName,
                actualDate: null,
                nextDueDate: null,
                nextDueDateSource: null,
                equipmentOrmId: this.data.equipmentId,
                equipmentInit: this.data.equipmentInit,
                equipmentNo: this.data.equipmentNo,
            } as EquipmentMechDate;
        } else {
            this.selectedDate = {
                ormId: this.currentNewId,
                mechField: this.data.mechFieldName,
                installDate: this.data.componentInstallDate,
                actualDate: null,
                nextDueDate: null,
                nextDueDateSource: null,
                componentId: this.data.componentId,
                equipmentComponentOrmId: this.data.equipmentComponentId,
                equipmentOrmId: this.data.equipmentId,
                equipmentInit: this.data.equipmentInit,
                equipmentNo: this.data.equipmentNo,
            } as EquipComponentDate;
        }

        this.dateList = [this.selectedDate, ...this.dateList];
        this.isValid = false;
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    onDeleteDate(event: number): void {
        const index: number = this.dateList.findIndex(
            (d: EquipmentMechDate | EquipComponentDate) => d.ormId === event,
        );
        this.dateList = [...this.dateList.slice(0, index), ...this.dateList.slice(index + 1)];
        this.selectedDate = null;
    }

    onEdit(event: number): void {
        if (this.dateList) {
            const index: number = this.dateList.findIndex(
                (d: EquipmentMechDate | EquipComponentDate) => d.ormId === event,
            );
            this.selectedDate = this.dateList[index];
        }
    }

    onSaveClick(): void {
        this.isSaving = true;
        this.errorMessage = '';

        // Convert all negative ormID's to 0 to indicate new
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const dateListToSave: EquipmentMechDate[] | EquipComponentDate[] = this.dateList.map(
            (d: EquipmentMechDate | EquipComponentDate) => {
                if (d.ormId < 0) {
                    d.ormId = 0;
                }

                return d;
            },
        );

        if (!this.data.componentId) {
            this.equipDatesService
                .updateEquipFieldDates(
                    this.data.equipmentId,
                    this.data.mechFieldName,
                    dateListToSave,
                )
                .pipe(
                    tap(() => this.afterSaveComplete()),
                    catchError((err) => {
                        this.afterError(err);
                        return EMPTY;
                    }),
                )
                .subscribe();
        } else {
            this.equipComponentDatesService
                .updateEquipComponentFieldDates(
                    this.data.equipmentComponentId,
                    this.data.mechFieldName,
                    dateListToSave,
                )
                .pipe(
                    tap(() => this.afterSaveComplete()),
                    catchError((err) => {
                        this.afterError(err);
                        return EMPTY;
                    }),
                )
                .subscribe();
        }
    }

    onSelectedDateChanged(event: {
        value: EquipmentMechDate | EquipComponentDate;
        valid: boolean;
    }): void {
        if (this.dateList && this.dateList.length) {
            const index: number = this.dateList.findIndex(
                (d: EquipmentMechDate | EquipComponentDate) => d.ormId === event.value.ormId,
            );
            this.dateList = [
                ...this.dateList.slice(0, index),
                event.value,
                ...this.dateList.slice(index + 1),
            ];
            this.isValid = event.valid;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private afterError(error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const customerErrors = [];
        this.errorMessage = 'Error saving Mechanical Dates!';

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.errorMessage = error;

        this._snackbar.open(this.errorMessage, 'Error', {
            duration: 3000,
            panelClass: 'error-snack',
        });
        this.isSaving = false;
    }

    private afterSaveComplete(message?: string) {
        if (!message) {
            message = 'Changes saved!';
        }

        this.isSaving = false;
        this._snackbar.open(message, 'Ok', { duration: 3000 });
        this.dialogRef.close(true);
    }
}
