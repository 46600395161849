import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bds-path-code',
    templateUrl: './bds-path-code.component.html',
    styleUrls: ['./bds-path-code.component.css'],
})
export class BdsPathCodeComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
