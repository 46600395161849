import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    RtClm,
    RtClmAdapter,
    RtClmViewAdapter,
    RtClmSightCode,
    RtClmView,
    RtTrip,
} from '@bds/railtrac-models';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { ErrorHandlerService } from '@bds/railtrac-internal-services';
import { BdsInternalDataResult } from '@bds/models';

export abstract class RtClmApiServiceOptions {
    apiUrl = `api/clm/`;
    odataUrl = `odata/clm/`;
    sightCodeUrl = `api/clmsightcode`;
    latestByTripUrl = `api/clm/trip/`;
}

@Injectable({
    providedIn: 'root',
})
export class RailtracClmService {
    constructor(
        private httpClient: HttpClient,
        private options: RtClmApiServiceOptions,
        private adapter: RtClmAdapter,
        private adapterClmView: RtClmViewAdapter,
        private errorHandler: ErrorHandlerService,
    ) {}

    getODataStore(): DataSource {
        return new DataSource({
            store: new ODataStore({
                version: 4,
                url: this.options.odataUrl,
                key: 'ormId',
            }),
            paginate: true,
            pageSize: 20,
        });
    }

    addClm(clm: RtClmView): Observable<any> {
        return this.httpClient.post<RtClm>(`${this.options.apiUrl}`, clm).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => this.errorHandler.parseApiError(err)),
        );
    }

    updateClm(clm: RtClmView): Observable<any> {
        var vClm = this.adapter.toServer(clm);
        return this.httpClient.put<RtClm>(`${this.options.apiUrl}`, vClm).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => this.errorHandler.parseApiError(err)),
        );
    }

    deleteClm(clm: RtClm): Observable<any> {
        return this.httpClient.delete(`${this.options.apiUrl}/${clm.ormId}`);
    }

    batchDeleteClms(ids: number[]): Observable<any> {
        return this.httpClient.post(`${this.options.apiUrl}/BatchDelete`, ids);
    }

    getClm(ormId: number): Observable<RtClmView> {
        return this.readViaApi(ormId);
    }

    getClmsViaOdata(ormIds: number[]): Observable<RtClmView[]> {
        let filter = `(clmType eq Bourque.RAILTRAC.Data.Models.Enum.ClmType'Current') and (`;
        ormIds.forEach((id, i) => {
            filter = filter + `(ormId eq ${id}) ` + (ormIds.length > i + 1 ? `or` : ')');
        });

        return this.httpClient
            .get<{ value: RtClmView[] }>(
                `${this.options.odataUrl}?$select=carInit,carNo,clmDateTime,sightCode,locationCity,locationState,loadEmptyFlag,trainJunction,road,destinationCity,destinationState,clmType,ormId&$filter=${filter}`,
            )
            .pipe(
                map((data: { value: RtClmView[] }) =>
                    (data.value ?? []).map((m) => this.adapterClmView.adapt(m)),
                ),
                retry(1),
                catchError(this.handleError),
            );
    }

    getClmsForTrip(ormId: number) {
        return this.httpClient.get<RtClm[]>(`${this.options.latestByTripUrl}${ormId}`).pipe(
            map((m) => [...m.map((f) => this.adapter.adapt(f))]),
            retry(1),
            catchError(this.handleError),
        );
    }

    getClmSightCodes(): Observable<Array<RtClmSightCode>> {
        return this.httpClient
            .get<Array<RtClmSightCode>>(`${this.options.sightCodeUrl}`)
            .pipe(retry(1), catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        // https://angular.io/guide/http#getting-error-details

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);

            // return an observable with a user-facing error message
            return throwError('A browser or network error occurred');
        } else if (error.status == 404) {
            // return an observable with a user-facing error message
            return throwError('Not Found');
        } else if (error.status == 422) {
            console.error('Found but cannot be used');
            return throwError(error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}`, error);

            // return an observable with a user-facing error message
            return throwError('The server returned an unknown error');
        }
    }

    readViaApi(ormId: number): Observable<RtClmView> {
        return this.httpClient
            .get<RtClmView>(`${this.options.apiUrl}/${ormId}`)
            .pipe(retry(1), catchError(this.handleError));
    }

    readViaOdata(ormId: number): Observable<RtClm> {
        return this.httpClient.get<RtClm>(`${this.options.odataUrl}(${ormId})`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            // retry(3),
            catchError(this.handleError),
        );
    }

    reapplyClms(carInit: string, carNo: string): Observable<Object> {
        const trip = new RtTrip(carInit, carNo);
        return this.httpClient.post(`${this.options.apiUrl}/reapplyClms`, trip);
    }

    bulkReapplyClms(trips: number[]): Observable<BdsInternalDataResult> {
        return this.httpClient.post(`${this.options.apiUrl}/bulkReapplyClms`, trips).pipe(
            map((data: BdsInternalDataResult) => data),
            // retry(3),
            catchError(this.handleError),
        );
    }

    getCurrentClm(carInit: string, carNo: string): Observable<RtClmView> {
        return this.httpClient
            .get<RtClmView>(`${this.options.apiUrl}/${carInit}/${carNo}`)
            .pipe(retry(1), catchError(this.handleError));
    }
}
