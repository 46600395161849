import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import { RtCarHotList, RtCarHotListAdapter, TripCarHotListDtoAdapter } from '@bds/railtrac-models';
import ODataStore from 'devextreme/data/odata/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, retry, shareReplay } from 'rxjs/operators';
import { TripCarHotListDto } from '@bds/railtrac-models';
import { RtCarHotListApiServiceOptions } from './rt-car-hot-list.options';
import { ErrorHandlerService } from '@bds/railtrac-internal-services';
import { BdsInternalDataResult } from '@bds/models';

@Injectable({
    providedIn: 'root',
})
export class RtCarHotListService {
    constructor(
        private options: RtCarHotListApiServiceOptions,
        private adapter: RtCarHotListAdapter,
        private dtoAdapter: TripCarHotListDtoAdapter,
        private http: HttpClient,
        private apiErrorHandler: ApiErrorHandlerService,
        private errorHandler: ErrorHandlerService,
    ) {}

    private refreshHotListGridSource = new Subject<boolean>();

    refreshHotListGridChangeEmitted$ = this.refreshHotListGridSource.asObservable();

    emitRefreshGridChange(change: boolean): void {
        this.refreshHotListGridSource.next(change);
    }

    acknowledgeHotLists(hotLists: RtCarHotList[]) {
        const newHotLists = [];
        hotLists.forEach((element) => {
            newHotLists.push(this.adapter.toServer(element));
        });
        return this.http
            .post(`${this.options.apiUrl}/acknowledgeHotLists`, newHotLists)
            .pipe(catchError((err) => this.apiErrorHandler.handleError(err)));
    }

    addRange(hotLists: RtCarHotList[]) {
        return this.http
            .post(`${this.options.apiUrl}/addRtCarHotLists`, hotLists)
            .pipe(catchError((err) => this.apiErrorHandler.handleError(err)));
    }

    deleteHotLists(ids: number[]): Observable<BdsInternalDataResult> {
        return this.http.put(`${this.options.apiUrl}/deleteHotLists`, ids).pipe(
            map((response: BdsInternalDataResult) => response),
            // retry(1),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    addRtCarHotListsForTrips(hotLists: TripCarHotListDto[]) {
        return this.http.post(`${this.options.apiUrl}/addRtCarHotListsForTrips`, hotLists).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => this.errorHandler.parseApiError(err)),
        );
    }

    checkEquipmentHasOpenTrip(carInit: string, carNo: string): Observable<boolean> {
        return this.http
            .get(
                `${this.options.tripUrl}/$count?$filter=carInit eq '${carInit}'` +
                    `and carNo eq '${carNo}' and tripStatus eq 'O'`,
            )
            .pipe(map((response) => response !== 0));
    }

    create(comment: RtCarHotList): Observable<unknown> {
        return of(comment);
    }

    getByIds(key: number[] | string): Observable<RtCarHotList[]> {
        return this.http
            .get(
                `${this.options.odataUrl}?$filter=ormId in (${key})&$orderby=acknowledgeFlag,hotListDate desc`,
            )
            .pipe(map((response) => response['value'].map((data) => this.adapter.adapt(data))));
    }

    getHotListsByTripIds(ormIds: number[]): Observable<TripCarHotListDto[]> {
        return this.http
            .get(
                //`${this.options.odataUrl}/GetByTripIds(tripIds=[${ormIds.join(',')}])` +
                `${this.options.odataUrl}('${ormIds.join(',')}')/GetByTripIdsOther` +
                    `?$orderby=acknowledgeFlag,hotListDate desc`,
            )
            .pipe(
                map((data: any) => {
                    return [...data.map((eachObj) => this.dtoAdapter.adapt(eachObj))];
                }),
            );
    }

    getODataStore(): ODataStore {
        const keyField = this.adapter.metadata.find(
            (f) => f.client === nameof<RtCarHotList>('ormId'),
        ).server;

        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: keyField,
        });
    }

    getShipmentHotLists(carInit: string, carNo: string): Observable<RtCarHotList[]> {
        return this.http
            .get(
                `${this.options.odataUrl}?$filter=carNo eq '${carNo}' and carInit eq '${carInit}'` +
                    `&$orderby=acknowledgeFlag,hotListDate desc`,
            )
            .pipe(
                map((response) => response['value'].map((data) => this.adapter.adapt(data))),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    readViaOdata(key: number): Observable<RtCarHotList> {
        return this.http.get<RtCarHotList>(`${this.options.odataUrl}(${key})`).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }
}
