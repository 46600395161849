import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    BdsDialogConfirmComponent,
    BdsDialogInfoComponent,
    BdsDialogInfoModel,
    bdsGridModeType,
} from '@bds/components';
import { AsciiConverterService, CarIdTransformService } from '@bds/helpers';
import {
    BdsOriginCriteriaDialogComponent,
    BdsOriginCriteriaDialogModel,
    CLMFilterModel,
    ClmFilterDialogComponent,
    RailtracClmService,
} from '@bds/railtrac';
import { ClmType, RtClmSightCode, RtClmView } from '@bds/railtrac-models';
import {
    BdsDestinationCriteriaDialogComponent,
    BdsDestinationCriteriaDialogModel,
} from '@bds/destination-criteria';
import { faExternalLinkAlt, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { faClipboardCheck, faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { default as DataSource, default as DxDataSource } from 'devextreme/data/data_source';
import { filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { CommunicationService, PageService } from '@bds/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { FastracDxGridWrapperComponent, FastracScreenBaseComponent } from '@bds/fastrac';
import { ActivatedRoute, Router } from '@angular/router';
import { FastracColsService } from '@bds/services';
import { Clipboard } from '@angular/cdk/clipboard';
import { FastracDesignService } from '@bds/data-access';
import { Fastrac } from '@bds/models';
import { DatePipe } from '@angular/common';
import { EdmLiteral } from 'devextreme/data/odata/utils';
import * as moment from 'moment';

@Component({
    selector: 'app-clm-screen',
    templateUrl: './clm-screen.component.html',
    styleUrls: ['./clm-screen.component.scss'],
})
export class ClmScreenComponent extends FastracScreenBaseComponent implements OnInit, OnDestroy {
    @ViewChild('dxGridWrapper') fastracGridComponent: FastracDxGridWrapperComponent;

    allowModeToggle = false;
    clmSightCodes: Array<RtClmSightCode> = [];
    clmTypes: unknown;
    gridColumns: unknown;
    gridMode: bdsGridModeType = 'multiple';
    gridSource: DxDataSource;
    iconAdd = faPlus;
    iconDelete = faTrashAlt;
    iconEdit = faEdit;
    iconReapply = faClipboardCheck;
    iconPopout = faExternalLinkAlt;
    iconSpinner = faSpinner;
    selectedClmId: number;
    selectedRowKeys: number[] = [];
    selectedRowsData: RtClmView[] = [];
    public selectedRowData: any[] = [];
    clm: RtClmView;
    clms: RtClmView[] = [];
    reapplyClmsLoading = false;
    fastracId = 0;
    gridId = 98;
    dataSource$ = new BehaviorSubject<DataSource>(new DataSource(new Array<unknown>()));
    systemFastracsNames = ['SYSTEM-HISTORICAL CLMS', 'SYSTEM-ARCHIVED CLMS'];
    systemFastrac: Fastrac[];
    isDeleteEnabled = false;

    get grid() {
        return this.fastracGridComponent?.grid;
    }

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        public dialog: MatDialog,
        public clmService: RailtracClmService,
        private asciiService: AsciiConverterService,
        public pageService: PageService,
        private _snackbar: MatSnackBar,
        private clipboard: Clipboard,
        private fastracService: FastracDesignService,
        private datePipe: DatePipe,
    ) {
        super(router, route, gridColumnService, communicationService, carIdTransformService);
        pageService.setHeader('CLMs');

        this.clmTypes = [
            { clmType: 'Archive', clmTypeDisplay: 'Archived' },
            { clmType: 'Current', clmTypeDisplay: 'Current' },
            { clmType: 'Historical', clmTypeDisplay: 'History' },
        ];

        clmService.getClmSightCodes().subscribe((sightCodes: Array<RtClmSightCode>) => {
            sightCodes.sort((a, b) => (a.id < b.id ? -1 : 1));

            for (const sc of sightCodes) {
                if (sc.id === 'Q') {
                    sc.description = 'Start of a Shipment.';
                    break;
                }
            }

            this.clmSightCodes = sightCodes;
        });
    }

    ngOnInit(): void {
        //this.gridSource = this.getODataClmApiSource();
        //this.dataSource$.next(this.gridSource);
        this.loadGridSource(null);
        this.fastracService.getFastracsByName(this.systemFastracsNames).subscribe((s) => {
            this.systemFastrac = s;
        });
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    loadGridSource(filter: string[]): void {
        this.gridSource = this.getODataClmApiSource();
        this.gridSource.filter(filter);
        this.dataSource$.next(this.gridSource);
    }

    addClm(): void {
        this.clm = new RtClmView(ClmType.Current);
    }

    deleteClm(): void {
        const dialog = this.dialog.open(BdsDialogConfirmComponent, {
            data: {
                actionText: 'Delete',
                content: `Do you want to delete the highlighted CLM(s)?`,
            },
        });

        dialog
            .afterClosed()
            .pipe(
                filter((response) => !!response),
                switchMap(() => this.clmService.batchDeleteClms(this.selectedRowKeys)),
                tap(() => this.reloadGrid()),
            )
            .subscribe(
                () => {
                    this._snackbar.open('Deleted CLM', 'Ok', {
                        duration: 3000,
                    });
                },
                () => {
                    this._snackbar.open('Unable to delete CLM', 'Error', {
                        duration: 3000,
                    });
                },
            );
    }

    reapplyClms(): void {
        this.reapplyClmsLoading = true;
        this.clmService
            .reapplyClms(this.clm.carInit, this.clm.carNo)
            .pipe(
                finalize(() => {
                    this.reapplyClmsLoading = false;
                }),
            )
            .subscribe(
                () => {
                    this._snackbar.open('CLMs Reapplied!', 'Ok', {
                        duration: 3000,
                    });
                },
                (error) => {
                    this._snackbar.open(error.error, 'Error', {
                        duration: 3000,
                    });
                },
            );
    }

    disableDelete(): void {
        this.isDeleteEnabled =
            this.clms.length === 0 ||
            this.clms.filter((x) => x.sightCode === 'Q' || x.clmType?.toString() === 'Archive')
                .length === 0;
    }

    addToDestinationCriteria(): void {
        if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
            this.clmService
                .getClm(this.selectedRowKeys[0])
                .pipe(take(1))
                .subscribe((clm) => {
                    // TODO: Remove this check once backend fixed to return char(1)
                    const sightCodeLetter: string = this.asciiService.convertAsciiToChar(
                        clm.sightCode,
                    );

                    const destinationInfo: BdsDestinationCriteriaDialogModel = {
                        locationCity: clm.locationCity,
                        locationState: clm.locationState,
                        railroad: clm.road,
                        sightCode: sightCodeLetter,
                        isClone: false,
                        isNew: true,
                    };

                    this.dialog.open(BdsDestinationCriteriaDialogComponent, {
                        width: '550px',
                        data: destinationInfo,
                    });
                });
        } else {
            const info: BdsDialogInfoModel = {
                title: 'Add to Destination Criteria',
                content: 'Please select a CLM to add.',
            };

            this.dialog.open(BdsDialogInfoComponent, {
                data: info,
            });
        }
    }

    addToOriginCriteria(): void {
        if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
            this.clmService
                .getClm(this.selectedRowKeys[0])
                .pipe(take(1))
                .subscribe((clm) => {
                    // TODO: Remove this check once backend fixed to return char(1)
                    const sightCodeLetter: string = this.asciiService.convertAsciiToChar(
                        clm.sightCode,
                    );

                    const originInfo: BdsOriginCriteriaDialogModel = {
                        locationCity: clm.locationCity,
                        locationState: clm.locationState,
                        railroad: clm.road,
                        sightCode: sightCodeLetter,
                        isClone: false,
                        isNew: true,
                    };

                    this.dialog.open(BdsOriginCriteriaDialogComponent, {
                        width: '550px',
                        data: originInfo,
                    });
                });
        } else {
            const info: BdsDialogInfoModel = {
                title: 'Add to Origin Criteria',
                content: 'Please select a CLM to add.',
            };

            this.dialog.open(BdsDialogInfoComponent, {
                data: info,
            });
        }
    }

    getODataClmApiSource(): DataSource {
        return this.clmService.getODataStore();
    }

    async onSelectionChanged(ids: number[]): Promise<void> {
        if (ids.length == 1) {
            this.selectedClmId = ids[0];
            const clmResult = await firstValueFrom(this.clmService.getClm(this.selectedClmId));

            if (clmResult) {
                this.clm = clmResult;
                this.clms = [this.clm];
                this.disableDelete();
            }
        }

        if (ids.length > 1) {
            this.selectedClmId = null;
            this.clms = [];
            const clmResult: RtClmView[] = await firstValueFrom(
                this.clmService.getClmsViaOdata(ids),
            );

            if (clmResult) {
                this.clms = clmResult ?? [];
                this.disableDelete();
            }
        }
    }

    tabLabelString(clm: RtClmView): string {
        return `${clm.carInit}${clm.carNo} ${moment(clm.clmDateTime).format('YYYY-MM-DD')}`;
    }

    reloadGrid(): void {
        void this.grid.instance.refresh();
        this.isDeleteEnabled = false;
    }

    formatClmSightCodeDisplay(val: RtClmSightCode): string {
        return `${val.id} - ${val.name}`;
    }

    copyCarsToClipboard(): void {
        this.selectedRowData = this.grid.instance.getSelectedRowsData();
        if (this.selectedRowData != null && this.selectedRowData.length > 0) {
            const carIds = this.selectedRowData.map((eachRow) => {
                return eachRow.carInit + ' ' + eachRow.carNo;
            });
            this.clipboard.copy(carIds.join('\n'));
        }
    }

    onGridFastracIdChanged(e: any): void {
        const result = this.systemFastrac.map((a) => a.id);
        if (e && result.indexOf(e) > -1) {
            const dialogRef = this.dialog.open(ClmFilterDialogComponent, {
                width: '700px',
                disableClose: false,
            });

            dialogRef.afterClosed().subscribe((filterValue) => {
                if (filterValue) {
                    const clmFilter = filterValue as CLMFilterModel;
                    const filter = [];

                    if (clmFilter.carInit) {
                        filter.push(['CarInit', '=', clmFilter.carInit]);
                    }
                    if (clmFilter.carNo) {
                        if (filter.length > 0) {
                            filter.push('and');
                        }
                        filter.push('CarNo', '=', clmFilter.carNo);
                    }

                    if (filter.length > 0) {
                        filter.push('and');
                    }

                    const fromDateLiteral = this.datePipe.transform(
                        clmFilter.clmFromDate,
                        "yyyy-MM-dd'T'HH:mm:ss.SSZZZZZ",
                        'UTC',
                    );

                    const toDateLiteral = this.datePipe.transform(
                        clmFilter.clmToDate,
                        "yyyy-MM-dd'T'HH:mm:ss.SSZZZZZ",
                        'UTC',
                    );
                    filter.push(['clmDateTime', '>=', new EdmLiteral(fromDateLiteral)]);
                    filter.push('and');
                    filter.push(['clmDateTime', '<=', new EdmLiteral(toDateLiteral)]);

                    this.loadGridSource(filter);
                    super.onFastracIdChanged(e);
                }
            });
        } else {
            this.loadGridSource(null);
            super.onFastracIdChanged(e);
        }
    }
}
