<div [formGroup]="equipForm">
    <div class="row">
        <div *ngIf="editable" class="col-12 col-sm-3">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-select
                    formControlName="equipReportCatgId"
                    (selectionChange)="onCategoryChanged($event)"
                >
                    <mat-option></mat-option>
                    <mat-option *ngFor="let item of equipCategories" [value]="item.ormId">
                        {{ item.equipReportCategory }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="equipForm.controls.equipReportCatgId.errors">
                    {{ getError(equipForm.controls.equipReportCatgId) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="!editable" class="col-12 col-sm-3">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Category</mat-label>
                <input
                    matInput
                    bdsShoutcase
                    readonly="readonly"
                    value="{{ equipCategories[0]?.equipReportCategory }}"
                />
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-3">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-select formControlName="equipReportCatgValId">
                    <mat-option *ngFor="let item of filteredValues" [value]="item.ormId">
                        {{ item.equipReportCatgVal }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="equipForm.controls.equipReportCatgValId.errors">
                    {{ getError(equipForm.controls.equipReportCatgValId) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-6">
            <div class="row">
                <div class="col-12 col-sm-5" *ngIf="showInputs">
                    <dx-date-box
                        name="effectDate"
                        class="w-100 bds-dx-custom-material"
                        formControlName="effectDate"
                        [stylingMode]="dxMatStyle"
                        type="datetime"
                        displayFormat="MM/dd/yyyy HH:mm:ss"
                        label="Effect Date"
                        [isValid]="!equipForm.controls.effectDate.errors"
                        (onFocusOut)="onDateRangeBlur($event)"
                        (onInitialized)="onDateRangeInit($event)"
                    >
                    </dx-date-box>
                </div>
                <div class="col-12 col-sm-5" *ngIf="showInputs">
                    <dx-date-box
                        name="expireDate"
                        class="w-100 bds-dx-custom-material"
                        formControlName="expireDate"
                        [stylingMode]="dxMatStyle"
                        type="datetime"
                        displayFormat="MM/dd/yyyy HH:mm:ss"
                        label="Expire Date"
                        [isValid]="!equipForm.controls.expireDate.errors"
                        (onFocusOut)="onDateRangeBlur($event)"
                    >
                    </dx-date-box>
                </div>
                <div class="col-12 col-sm-10 justify-content-center" *ngIf="!showInputs">
                    <div class="date-range" (click)="showInputs = true">
                        {{ equipment?.effectDate | date : 'MM/dd/yyyy HH:mm:ss' : 'UTC' }}
                        &nbsp;-&nbsp;
                        {{
                            equipment?.expireDate
                                ? (equipment.expireDate | date : 'MM/dd/yyyy HH:mm:ss' : 'UTC')
                                : 'PRESENT'
                        }}
                    </div>
                </div>
                <div class="col-12 col-sm-2 text-right">
                    <button mat-icon-button (click)="onRemove()">
                        <fa-icon class="remove-icon" [icon]="iconExpire"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
