import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDateBoxModule } from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BdsDateTimePickerComponent } from './bds-date-time-picker.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    declarations: [BdsDateTimePickerComponent],
    imports: [DxDateBoxModule, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule],
    exports: [BdsDateTimePickerComponent],
})
export class BdsDateTimePickerModule {}
