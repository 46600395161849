import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { BdsAdminModule } from '@bds/admin';
import { BdsApplicationApiService, BdsApplicationsModule } from '@bds/applications';
import { AuthorizeGuard, AuthorizeModule, AuthorizeOptions } from '@bds/auth';
import { DevExtremeAuthorizeModule } from '@bds/auth-devextreme';

import {
    BdsDxDataGridModule,
    BdsGridWrapperModule,
    BdsMapModule,
    BdsSmartdateModule,
    LeafletSateliteLayerOptions,
    LeafletStreetLayerOptions,
} from '@bds/components';

import {
    BourqueCoreModule,
    CommunicationService,
    THIS_APPLICATION_CODE,
    THIS_APPLICATION_NAME,
} from '@bds/core';
import {
    BdsAppSettingsModule,
    AppSettingsLoader,
    AppConfig,
    ApiOptions,
    ChangelogInfo,
} from '@bds/app-settings';
import { BdsCustomerModule } from '@bds/customer';

import {
    BdsErpcOriginApiServiceOptions,
    //BdsCommodityApiServiceOptions,
    //BdsCustomerApiServiceOptions,
    BdsShipmentTypeApiServiceOptions,
    BdsSupplierApiServiceOptions,
    //BdsStateApiServiceOptions,
    RtCarAssignApiServiceOptions,
    RtCarHotListApiServiceOptions,
    RtCarMechApiServiceOptions,
    //RtRouteApiServiceOptions,
    RtTripCommentServiceOptions,
    RtvCarHotListGridApiServiceOptions,
    //BdsSplcErpcApiServiceOptions
} from '@bds/data-access';

import { BdsDestinationCriteriaModule } from '@bds/destination-criteria';

import { EquipmentModule } from '@bds/equipment';

import { BdsFastracModule } from '@bds/fastrac';
import { BdsHelpersModule } from '@bds/helpers';
import { BDSINTERNAL_API_BASE_URL } from '@bds/internal-api';
import { BdsPowerBiEmbedModule } from '@bds/power-bi-embed';
import {
    BdsBusinessGroupApiServiceOptions,
    //BdsCommodityClassApiServiceOptions,
    //BdsCommodityModule,
    BdsErpcOriginModule,
    BdsFleetApiServiceOptions,
    //BdsHazmatApiServiceOptions,
    BdsOriginCriteriaApiServiceOptions,
    BdsOriginCriteriaModule,
    //BdsSplcErpcApiServiceOptions,
    JeopardizedShipmentScreenComponent,
    RailtracClmModule,
    RailtracTripModule,
    RtCityAliasModule,
    RtCityAliasServiceOptions,
    RtClmApiServiceOptions,
    RtCommodityShippedApiServiceOptions,
    RtCustomerAliasModule,
    RtCustomerAliasServiceOptions,
    RtDiversionManagementModule,
    RtDiversionModule,
    RtJeopardizedShipmentApiServiceOptions,
    RtJeopardizedShipmentModule,
    //RtRailroadModule,
    RtTripApiServiceOptions,
} from '@bds/railtrac';
//import { BdsCommodityModule } from '@bds/commodity';
import {
    BdsSegmentMilesApiServiceOptions,
    FormlyServiceApiServiceOptions,
    RtPrefRouteServiceApiServiceOptions,
    RtRouteDetailServiceOptions,
    //RtRouteParticipatingRailroadServiceOptions,
    RtRoutePathCodeServiceApiServiceOptions,
    RtRouteSegmentsApiServiceOptions,
} from '@bds/route';

import {} from '@bds/railroad';
import {
    BdsCoreApiServiceOptionsConfig,
    BdsFastracBuilderApiServiceOptions,
    FastracDefaultResolver,
} from '@bds/services';
// import {
//     ShipperBiModule,
//     ShipperBiOptions,
//     ImageCropperOptions,
//     WorkspaceSwitcherModule,
//     ReportDisplayModule,
// } from '@bds/shipper-bi';
import { BdsSysDefApiServiceOptions } from '@bds/sysdef';
import { BdsUiModule } from '@bds/ui';
import { BdsGridDetailsModule } from '@bds/components';
import { PageServiceOptions } from '@bds/core';
import {
    ApplicationApiService,
    ApplicationPathParamService,
    BdsSwitchboardModule,
} from '@bds/application-pathing';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BdsPathCodeApiServiceOptions } from '@bds/route';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { CustomDevExtremeModule } from './modules/custom-devextreme/custom-devextreme.module';
import { CustomMaterialModule } from './modules/custom-material/custom-material.module';
import { RtSidenavModule } from './rt-sidenav/rt-sidenav.module';

import { AdminScreenComponent } from './screens/admin-screen/admin-screen.component';
import { AssignmentScreenComponent } from './screens/assignment-screen/assignment-screen.component';
import { CityAliasScreenComponent } from './screens/city-alias-screen/city-alias-screen.component';
import { ClmScreenComponent } from './screens/clm-screen/clm-screen.component';
//import { CommodityScreenComponent } from './screens/commodity-screen/commodity-screen.component';
import { CustomerAliasScreenComponent } from './screens/customer-alias-screen/customer-alias-screen.component';
//import { CustomerScreenComponent } from './screens/customer-screen/customer-screen.component';
import { DestinationAliasScreenComponent } from './screens/destination-alias-screen/destination-alias-screen.component';
import { EquipmentScreenComponent } from './screens/equipment-screen/equipment-screen.component';
import { ErpcOriginScreenComponent } from './screens/erpc-origin-screen/erpc-origin-screen.component';
import { FastracDialogDefaultSelectionComponent } from './screens/fastrac-management-screen/fastrac-dialog-default-selection/fastrac-dialog-default-selection.component';
import { FastracManagementDetailsComponent } from './screens/fastrac-management-screen/fastrac-management-details/fastrac-management-details.component';
import { FastracManagementDialogConfirmComponent } from './screens/fastrac-management-screen/fastrac-management-dialog-confirm/fastrac-management-dialog-confirm.component';
import { FastracManagementDialogInputboxComponent } from './screens/fastrac-management-screen/fastrac-management-dialog-inputbox/fastrac-management-dialog-inputbox.component';
import { FastracManagementDialogShareComponent } from './screens/fastrac-management-screen/fastrac-management-dialog-share/fastrac-management-dialog-share.component';
import { FastracManagementDialogComponent } from './screens/fastrac-management-screen/fastrac-management-dialog/fastrac-management-dialog.component';
import { FastracManagementPageComponent } from './screens/fastrac-management-screen/fastrac-management-page/fastrac-management-page.component';
import { FastracManagementScreenComponent } from './screens/fastrac-management-screen/fastrac-management-screen.component';
import { HomeScreenComponent } from './screens/home-screen/home-screen.component';
import { HotlistScreenComponent } from './screens/hotlist-screen/hotlist-screen.component';
import { ParticipatingRailroadScreenComponent } from './screens/participating-railroad-screen/participating-railroad-screen.component';
import { PathCodeScreenComponent } from './screens/path-code-screen/path-code-screen.component';
import { RailcarProfileScreenComponent } from './screens/railcar-profile-screen/railcar-profile-screen.component';
import { RecipientScreenComponent } from './screens/recipient-screen/recipient-screen.component';
import { RoutesScreenComponent } from './screens/routes-screen/routes-screen.component';
//import { RtJeopardizedShipmentScreenComponent } from './screens/rt-jeopardized-shipment-screen/rt-jeopardized-shipment-screen.component';
import { SegmentMilesScreenComponent } from './screens/segment-miles-screen/segment-miles-screen.component';
import { PreferredRouteScreenComponent } from './screens/preferred-route-screen/preferred-route-screen.component';
import { TripDivertedScreenComponent } from './screens/trip-diverted-screen/trip-diverted-screen.component';
import { TripCarAssignmentScreenComponent } from './screens/trip-screen/trip-car-assignment-screen/trip-car-assignment-screen.component';
import { TripClmHistoryScreenComponent } from './screens/trip-screen/trip-clm-history-screen/trip-clm-history-screen.component';
import { TripCommentsScreenComponent } from './screens/trip-screen/trip-comments-screen/trip-comments-screen.component';
import { TripDetailScreenComponent } from './screens/trip-screen/trip-detail-screen/trip-detail-screen.component';
import { TripDetentionScreenComponent } from './screens/trip-screen/trip-detention-screen/trip-detention-screen.component';
import { TripHotListScreenComponent } from './screens/trip-screen/trip-hot-list-screen/trip-hot-list-screen.component';
import { TripProductScreenComponent } from './screens/trip-screen/trip-product-screen/trip-product-screen.component';
import { TripRouteScreenComponent } from './screens/trip-screen/trip-route-screen/trip-route-screen.component';
import { MultiShipmentScreenComponent } from './screens/trip-screen/trip-route/multi-shipment-screen.component';
import { ShipmentScreenComponent } from './screens/trip-screen/trip-route/shipment-screen.component';

import { TripScreenComponent } from './screens/trip-screen/trip-screen.component';
import { TripSingleScreenComponent } from './screens/trip-screen/trip-single-screen/trip-single-screen.component';
import { RailroadFactScreenComponent } from './screens/railroad-fact-screen/railroad-fact-screen.component';
import {
    RailtracReportsModule,
    RtShipmentCarSituationAllReportComponent,
    RtShipmentCarSituationCurrentReportComponent,
    RtShipmentCarSituationCurrentExtendedReportComponent,
    RtTransitTimeCycleCustomerSummaryReportComponent,
    RtTransitTimeCycleCustomerSummaryAllReportComponent,
    RtTransitTimeCycleDetailReportComponent,
    RtTransitTimeCycleDetailAllReportComponent,
    RtTransitTimeCycleSummaryReportComponent,
    RtTransitTimeCycleSummaryAllReportComponent,
    //RtTransitTimeCycleDayOfWeekComponent
    RtTransitTimeCtaBusinessComponent,
    RtTransitTimeCtaFleetComponent,
    RtTransitTimeCtaFleetBusinessComponent,
    RtOutboundByCustomerReportComponent,
    RtOutboundByCustomerCsrReportComponent,
    RtOutboundByCustomerProductReportComponent,
    RtOutboundByCustomerProductVolumeReportComponent,
    RtOutboundByFleetReportComponent,
    RtCompletedShipmentsAllComponent,
    RtCompletedShipmentsNoDiversionsComponent,
    RtDayInToOriginReportComponent,
    RtDayOutToConsigneeReportComponent,
    RtShipmentHistoryDetailReportComponent,
    RtShipmentHistorySummaryReportComponent,
    TransitTimeTripCycleDetailComponent,
    TransitTimeTripCycleSummaryComponent,
    EtaPerformanceRouteComponent,
    EtaPerformanceShipmentComponent,
    OriginPerformanceComponent,
    BadOrderHistoryComponent,
} from '@bds/railtrac-reports';
import {
    //RtShipmentCarSituationAllReportComponent,
    //RtShipmentCarSituationCurrentReportComponent,
    //RtShipmentCarSituationCurrentExtendedReportComponent,
    RtTransitTimeDayOfWeekComponent,
    RtTransitTimeDestinationStateComponent,
    RtTransitTimeRsDetailComponent,
    RtTransitTimeRsSummaryComponent,
} from '@bds/railtrac-reports';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BDS_DATE_PICKER_CONFIG, NetworkInterceptor } from '@bds/smart-components';
import { BdsReportingCategoryMaintenanceRoutingModule } from '@bds/reporting-category-maintenance';

function addBaseUrl(x: {}, options: ApiOptions) {
    Object.keys(x)
        .filter((z) => Object.prototype.hasOwnProperty.call(x, z))
        .forEach((z) => {
            if (typeof x[z] === 'string') {
                x[z] = x[z]
                    .replace(/^~api~\//, options.apiBaseUrl)
                    .replace(/^~odata~\//, options.odataBaseUrl);
            }
        });
    return x;
}

/*******************************************************************************************************************************/
import { TempAppSettingsLoader } from './tempAppSettingsLoader';
import { RtConsigneeCarRetDetailComponent } from 'projects/bds/railtrac-reports/src/lib/days/rt-consignee-car-ret-detail/rt-consignee-car-ret-detail.component';
import { RtConsigneeCarRetSummaryComponent } from 'projects/bds/railtrac-reports/src/lib/days/rt-consignee-car-ret-summary/rt-consignee-car-ret-summary.component';
/*******************************************************************************************************************************/

@NgModule({
    declarations: [
        AdminScreenComponent,
        AppComponent,
        TripScreenComponent,
        //CommodityScreenComponent,
        //CustomerScreenComponent,
        HomeScreenComponent,
        ClmScreenComponent,
        EquipmentScreenComponent,
        TripDetailScreenComponent,
        TripDivertedScreenComponent,
        TripProductScreenComponent,
        TripCarAssignmentScreenComponent,
        TripClmHistoryScreenComponent,
        TripDetentionScreenComponent,
        TripHotListScreenComponent,
        TripCommentsScreenComponent,
        TripRouteScreenComponent,
        TripSingleScreenComponent,
        AssignmentScreenComponent,
        RailcarProfileScreenComponent,
        RecipientScreenComponent,
        FastracManagementScreenComponent,
        FastracManagementDialogConfirmComponent,
        FastracManagementDialogInputboxComponent,
        FastracManagementDialogShareComponent,
        FastracManagementDetailsComponent,
        FastracManagementDialogComponent,
        FastracManagementPageComponent,
        FastracDialogDefaultSelectionComponent,
        //RtJeopardizedShipmentScreenComponent,
        ParticipatingRailroadScreenComponent,
        DestinationAliasScreenComponent,
        CityAliasScreenComponent,
        CustomerAliasScreenComponent,
        ErpcOriginScreenComponent,
        ShipmentScreenComponent,
        MultiShipmentScreenComponent,
        HotlistScreenComponent,
        PathCodeScreenComponent,
        SegmentMilesScreenComponent,
        RoutesScreenComponent,
        RailroadFactScreenComponent,
        PreferredRouteScreenComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        AuthorizeModule,
        DevExtremeAuthorizeModule,
        //ShipperBiModule,
        //ReportDisplayModule,
        BdsUiModule,
        //RtJeopardizedShipmentModule,
        //RtCityAliasModule,
        //RtCustomerAliasModule,
        //RailtracReportsModule,
        MatProgressSpinnerModule,
        RtSidenavModule,
        BdsAppSettingsModule.forRoot(environment.appConfig),
        BdsSwitchboardModule,
        RouterModule.forRoot(
            [
                { path: '', component: HomeScreenComponent, pathMatch: 'full' },
                {
                    path: 'trips',
                    component: TripScreenComponent,
                    canActivate: [AuthorizeGuard],
                    resolve: { defaultData: FastracDefaultResolver },
                    children: [
                        {
                            path: 'detail/:ormId',
                            component: ShipmentScreenComponent,
                            canActivate: [AuthorizeGuard],
                        },
                        {
                            path: 'create',
                            component: ShipmentScreenComponent,
                            canActivate: [AuthorizeGuard],
                        },
                        {
                            path: 'multi',
                            component: MultiShipmentScreenComponent,
                            canActivate: [AuthorizeGuard],
                        },
                    ],
                },
                {
                    path: 'trip/:ormId',
                    component: TripSingleScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'trip/details/:ormId',
                    component: TripDetailScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'trip/assignment/:ormId',
                    component: TripCarAssignmentScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'trip/clm/:ormId',
                    component: TripClmHistoryScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'trip/comments/:ormId',
                    component: TripCommentsScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'trip/detention/:ormId',
                    component: TripDetentionScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'trip/hotlist/:ormId',
                    component: TripHotListScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'trip/commodity/:ormId',
                    component: TripProductScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'trip/route/:ormId',
                    component: TripRouteScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'routes',
                    component: RoutesScreenComponent,
                    canActivate: [AuthorizeGuard],
                    resolve: { defaultData: FastracDefaultResolver },
                    loadChildren: () => import('@bds/route').then((x) => x.RtRouteScreenModule),
                },
                {
                    path: 'railroads',
                    component: RailroadFactScreenComponent,
                    canActivate: [AuthorizeGuard],
                    resolve: { defaultData: FastracDefaultResolver },
                    loadChildren: () =>
                        import('@bds/railroad').then((x) => x.BdsRailroadRoutedModule),
                },
                {
                    path: 'path-codes',
                    component: PathCodeScreenComponent,
                    canActivate: [AuthorizeGuard],
                    loadChildren: () => import('@bds/route').then((x) => x.BdsPathCodeRoutedModule),
                },
                {
                    path: 'segment-miles',
                    component: SegmentMilesScreenComponent,
                    canActivate: [AuthorizeGuard],
                    resolve: { defaultData: FastracDefaultResolver },
                    loadChildren: () =>
                        import('@bds/route').then((x) => x.BdsSegmentMilesRoutedModule),
                },
                {
                    path: 'preferred-route',
                    component: PreferredRouteScreenComponent,
                    canActivate: [AuthorizeGuard],
                    resolve: { defaultData: FastracDefaultResolver },
                    loadChildren: () =>
                        import('@bds/route').then((x) => x.BdsPreferredRoutesRoutedModule),
                },
                {
                    path: 'hotlist',
                    component: HotlistScreenComponent,
                    canActivate: [AuthorizeGuard],
                    resolve: { defaultData: FastracDefaultResolver },
                    loadChildren: () => import('@bds/hotlist').then((x) => x.BdsHotlistModule),
                },
                {
                    path: 'clms',
                    component: ClmScreenComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'fleet-assignment',
                    component: AssignmentScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'railcar-profile',
                    component: RailcarProfileScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'recipients',
                    component: RecipientScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'fastracs',
                    component: FastracManagementScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'jeopardized',
                    component: JeopardizedShipmentScreenComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'participating-rr',
                    component: ParticipatingRailroadScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'dest-diff',
                    component: DestinationAliasScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'city-alias',
                    component: CityAliasScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'customer-alias',
                    component: CustomerAliasScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                { path: 'equipment', redirectTo: 'equipment/', pathMatch: 'full' },
                {
                    path: 'equipment/:id',
                    component: EquipmentScreenComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'origins',
                    component: ErpcOriginScreenComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'diversions',
                    component: TripDivertedScreenComponent,
                    canActivate: [AuthorizeGuard],
                    resolve: { defaultData: FastracDefaultResolver },
                    children: [
                        {
                            path: 'trip/:ormId',
                            component: ShipmentScreenComponent,
                            canActivate: [AuthorizeGuard],
                        },
                        {
                            path: 'multi',
                            component: MultiShipmentScreenComponent,
                            canActivate: [AuthorizeGuard],
                        },
                    ],
                },
                { path: 'admin', component: AdminScreenComponent, canActivate: [AuthorizeGuard] },
                {
                    path: 'transitTimeCycleCustomerSummary',
                    component: RtTransitTimeCycleCustomerSummaryReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeCycleCustomerSummaryAll',
                    component: RtTransitTimeCycleCustomerSummaryAllReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeCycleDetail',
                    component: RtTransitTimeCycleDetailReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeCycleDetailAll',
                    component: RtTransitTimeCycleDetailAllReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeCycleSummary',
                    component: RtTransitTimeCycleSummaryReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeCycleSummaryAll',
                    component: RtTransitTimeCycleSummaryAllReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeDayOfWeek',
                    component: RtTransitTimeDayOfWeekComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeDestinationState',
                    component: RtTransitTimeDestinationStateComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeRouteSegmentDetail',
                    component: RtTransitTimeRsDetailComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeRouteSegmentSummary',
                    component: RtTransitTimeRsSummaryComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeCtaBusiness',
                    component: RtTransitTimeCtaBusinessComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeCtaFleet',
                    component: RtTransitTimeCtaFleetComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeCtaFleetBusiness',
                    component: RtTransitTimeCtaFleetBusinessComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeTripCycleDetail',
                    component: TransitTimeTripCycleDetailComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'transitTimeTripCycleSummary',
                    component: TransitTimeTripCycleSummaryComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'shipmentCarSituationAll',
                    component: RtShipmentCarSituationAllReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'shipmentCarSituationCurrent',
                    component: RtShipmentCarSituationCurrentReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'shipmentCarSituationCurrentExtended',
                    component: RtShipmentCarSituationCurrentExtendedReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'outboundByCustomer',
                    component: RtOutboundByCustomerReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'outboundByCustomerCsr',
                    component: RtOutboundByCustomerCsrReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'outboundByCustomerProduct',
                    component: RtOutboundByCustomerProductReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'outboundByCustomerProductVolume',
                    component: RtOutboundByCustomerProductVolumeReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'outboundByFleet',
                    component: RtOutboundByFleetReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'completedShipmentsAll',
                    component: RtCompletedShipmentsAllComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'completedShipmentsNoDiversions',
                    component: RtCompletedShipmentsNoDiversionsComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'shipmentHistoryDetail',
                    component: RtShipmentHistoryDetailReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'shipmentHistorySummary',
                    component: RtShipmentHistorySummaryReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'dayConsigneeCarRetentionDetail',
                    component: RtConsigneeCarRetDetailComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'dayConsigneeCarRetentionSummary',
                    component: RtConsigneeCarRetSummaryComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'dayInToOrigin',
                    component: RtDayInToOriginReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'dayOutToConsignee',
                    component: RtDayOutToConsigneeReportComponent,
                    resolve: { defaultData: FastracDefaultResolver },
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'etaPerformanceRoute',
                    component: EtaPerformanceRouteComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'etaPerformanceShipment',
                    component: EtaPerformanceShipmentComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'originPerformance',
                    component: OriginPerformanceComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'badOrderHistory',
                    component: BadOrderHistoryComponent,
                    canActivate: [AuthorizeGuard],
                },
                {
                    path: 'reporting-category-maintenance',
                    canActivate: [AuthorizeGuard],
                    loadChildren: () =>
                        import('@bds/reporting-category-maintenance').then(
                            (x) => x.BdsReportingCategoryMaintenanceRoutingModule,
                        ),
                },
            ],
            { enableTracing: false },
        ),
        BrowserAnimationsModule,
        FontAwesomeModule,
        CustomMaterialModule,
        CustomDevExtremeModule,
        RailtracTripModule,
        //BdsCommodityModule,
        BdsCustomerModule,
        BdsDestinationCriteriaModule,
        BdsErpcOriginModule,
        BdsOriginCriteriaModule,
        EquipmentModule,
        RailtracClmModule,
        RtDiversionManagementModule,
        RtDiversionModule,
        //RtRailroadModule,
        BdsFastracModule,
        BdsPowerBiEmbedModule,
        BdsSmartdateModule,
        BdsDxDataGridModule,
        BdsGridWrapperModule,
        BourqueCoreModule,
        BdsHelpersModule,
        BdsApplicationsModule,
        BdsMapModule,
        BdsAdminModule,
        BdsGridDetailsModule,
        //BdsReportingCategoryMaintenanceRoutingModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NetworkInterceptor,
            multi: true,
        },
        CommunicationService,
        // {
        //     provide: ShipperBiOptions,
        //     useValue: {
        //         shipperBiApiUri: 'https://bi-api.test.railcl.com',
        //         shipperBiRoles: {
        //             roles: {
        //                 'shipper-bi-admin': [
        //                     'shipper-bi.admin.navigation',
        //                     'shipper-bi.report.list',
        //                     'shipper-bi.report.create',
        //                     'shipper-bi.report.edit',
        //                     'shipper-bi.report.delete',
        //                     'shipper-bi.workspace.read',
        //                     'shipper-bi.workspace.list',
        //                     'shipper-bi.workspace.change',
        //                     'shipper-bi.report.navigation',
        //                     'shipper-bi.report.read',
        //                     'shipper-bi.access-control.group-assignment',
        //                     'shipper-bi.access-control.configuration',
        //                 ],
        //                 'shipper-bi-user': [
        //                     'shipper-bi.report.navigation',
        //                     'shipper-bi.report.read',
        //                 ],
        //             },
        //         },
        //     },
        // },
        // {
        //     provide: ImageCropperOptions,
        //     useValue: {
        //         quality: 90,
        //         ratio: 1,
        //         sizeLimit: 500, //in kilobyte
        //     },
        // },
        {
            provide: BdsBusinessGroupApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.bdsBusinessGroupApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: BdsSysDefApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.bdsSysDefApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: BdsOriginCriteriaApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.bdsOriginCriteriaApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: BdsErpcOriginApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.bdsErpcOriginApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: BdsFleetApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(environment.bdsFleetApiServiceOptions, appSettings.appConfig.apiOptions),
            deps: [AppSettingsLoader],
        },
        {
            provide: BdsSupplierApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.bdsSupplierApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: LeafletSateliteLayerOptions,
            useValue: environment.leafletSateliteLayerOptions,
        },
        {
            provide: LeafletStreetLayerOptions,
            useValue: environment.leafletStreetLayerOptions,
        },
        {
            provide: RtClmApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(environment.rtClmApiServiceOptions, appSettings.appConfig.apiOptions),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtTripCommentServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtCommentTypeApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtCarHotListApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtCarHotlistApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtvCarHotListGridApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtCarHotlistGridApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtTripApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(environment.rtTripApiServiceOptions, appSettings.appConfig.apiOptions),
            deps: [AppSettingsLoader],
        },
        {
            provide: PageServiceOptions,
            useValue: environment.pageServiceOptions,
        },
        {
            provide: BdsFastracBuilderApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.bdsFastracBuilderApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtCityAliasServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(environment.rtCityAliasServiceOptions, appSettings.appConfig.apiOptions),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtJeopardizedShipmentApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtJeopardizedShipmentApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtCustomerAliasServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtCustomerAliasServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: BdsShipmentTypeApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.bdsShipmentTypeApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtCommodityShippedApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtCommodityShippedApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtCarAssignApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtCarAssignApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtCarMechApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtCarMechApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: BdsPathCodeApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.bdsPathCodeApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: BdsSegmentMilesApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.bdsSegmentMilesApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtPrefRouteServiceApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtPrefRouteServiceApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtRouteSegmentsApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtRouteSegmentsApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtRouteDetailServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtRouteDetailServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: 'tripGridApiServiceOptions',
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(environment.tripGridApiServiceOptions, appSettings.appConfig.apiOptions),
            deps: [AppSettingsLoader],
        },
        {
            provide: RtRoutePathCodeServiceApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtRoutePathCodeServiceApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: FormlyServiceApiServiceOptions,
            useFactory: (appSettings: AppSettingsLoader) =>
                addBaseUrl(
                    environment.rtFormlyServiceApiServiceOptions,
                    appSettings.appConfig.apiOptions,
                ),
            deps: [AppSettingsLoader],
        },
        {
            provide: 'BASE_API_URL',
            useFactory: (appSettings: AppSettingsLoader) =>
                appSettings.appConfig.apiOptions.apiBaseUrl,
            deps: [AppSettingsLoader],
        },
        {
            provide: 'BASE_ODATA_URL',
            useFactory: (appSettings: AppSettingsLoader) =>
                appSettings.appConfig.apiOptions.odataBaseUrl,
            deps: [AppSettingsLoader],
        },
        {
            provide: 'BASE_DEV_EXPRESS_URL',
            useFactory: (appSettings: AppSettingsLoader) =>
                appSettings.appConfig.custom.devExpressBaseUrl,
            deps: [AppSettingsLoader],
        },
        /********************************************************************************************/
        {
            provide: AppSettingsLoader,
            useClass: AppSettingsLoader,
        },
        {
            provide: 'TEMP_DASHBOARD_REPORT_ID',
            useFactory: (appSettings: AppSettingsLoader) =>
                appSettings.appConfig.custom.tempReportId,
            deps: [AppSettingsLoader],
        },
        {
            provide: 'TEMP_DASHBOARD_WORKSPACE_ID',
            useFactory: (appSettings: AppSettingsLoader) => {
                console.debug(
                    '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
                );
                console.debug(appSettings);
                console.debug(
                    '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
                );
                return appSettings.appConfig.custom.tempWorkspaceId;
            },
            deps: [AppSettingsLoader],
        },
        {
            provide: BdsCoreApiServiceOptionsConfig,
            useFactory: (appSettings: AppSettingsLoader) => {
                return <BdsCoreApiServiceOptionsConfig>{
                    baseApiUrl: appSettings.appConfig.apiOptions.apiBaseUrl,
                    baseOdataUrl: appSettings.appConfig.apiOptions.odataBaseUrl,
                };
            },
            deps: [AppSettingsLoader],
        },
        /********************************************************************************************/
        { provide: 'CAR_INITIALS_LENGTH', useValue: 4 },
        { provide: 'CAR_NUMBER_LENGTH', useValue: 6 },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
            } as MatSnackBarConfig<any>,
        },
        {
            provide: 'BDS_DATE_PICKER_CONFIG',
            useValue: <BDS_DATE_PICKER_CONFIG>{
                displayFormat: 'MM/dd/yyyy HH:mm:ss.SSS',
            },
        },
        ApplicationPathParamService,
        { provide: ApplicationApiService, useClass: BdsApplicationApiService },
        {
            provide: BDSINTERNAL_API_BASE_URL,
            useValue: 'https://api.core.railcl.com',
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {}

declare global {
    interface Array<T> {
        firstOrDefault: T | any;
    }
}

Array.prototype.firstOrDefault = function () {
    return !!this && !!this[0] ? this[0] : void 0;
};
