import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'bds-customer-formly',
    template: ` <bds-customer-select
        [label]="field.templateOptions.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
    >
    </bds-customer-select>`,
})
export class BdsCustomerFormlyComponent extends FieldType implements OnInit, OnDestroy {
    constructor() {
        super();
    }

    ngOnDestroy(): void {}

    ngOnInit(): void {}
}
