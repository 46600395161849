<ng-container [ngSwitch]="shipment.reason" *ngIf="!!shipment">
    <rt-jeopardized-shipment-bad-ordered
        *ngSwitchCase="'BAD ORDER'"
        [shipment]="shipment"
        (popoutTrip)="onPopoutTrip($event)"
    ></rt-jeopardized-shipment-bad-ordered>
    <rt-jeopardized-shipment-dest-diff
        *ngSwitchCase="'DEST DIFFERENCE'"
        [shipment]="shipment"
        (change)="shipmentChanged()"
        [resolved]="resolved"
        (popoutTrip)="onPopoutTrip($event)"
    ></rt-jeopardized-shipment-dest-diff>
    <rt-jeopardized-shipment-eta-variance
        *ngSwitchCase="'ETA VARIANCE'"
        [shipment]="shipment"
        (popoutTrip)="onPopoutTrip($event)"
    ></rt-jeopardized-shipment-eta-variance>
    <rt-jeopardized-shipment-load-empty
        *ngSwitchCase="'LOAD/EMPTY'"
        [shipment]="shipment"
        [addTripRouterLink]="addTripRouterLink"
        (popoutTrip)="onPopoutTrip($event)"
    ></rt-jeopardized-shipment-load-empty>
    <rt-jeopardized-shipment-mismatched-status
        *ngSwitchCase="'MISMATCH STATUS'"
        [shipment]="shipment"
        (popoutTrip)="onPopoutTrip($event)"
    ></rt-jeopardized-shipment-mismatched-status>
    <rt-jeopardized-shipment-multiple-open
        *ngSwitchCase="'MULTIPLE OPEN SHIPMENTS'"
        [shipment]="shipment"
        (popoutTrip)="onPopoutTrip($event)"
    ></rt-jeopardized-shipment-multiple-open>
    <rt-jeopardized-shipment-never-shipped
        *ngSwitchCase="'NEVER SHIPPED'"
        [shipment]="shipment"
        [addTripRouterLink]="addTripRouterLink"
        (popoutTrip)="onPopoutTrip($event)"
    ></rt-jeopardized-shipment-never-shipped>
    <rt-jeopardized-shipment-no-bill
        *ngSwitchCase="'NO BILLS'"
        [shipment]="shipment"
        (popoutTrip)="onPopoutTrip($event)"
    ></rt-jeopardized-shipment-no-bill>
    <rt-jeopardized-shipment-non-moving
        *ngSwitchCase="'NON MOVING'"
        [shipment]="shipment"
        (popoutTrip)="onPopoutTrip($event)"
    ></rt-jeopardized-shipment-non-moving>
    <rt-jeopardized-shipment-off-route
        *ngSwitchCase="'OFF ROUTE'"
        [shipment]="shipment"
        (popoutTrip)="onPopoutTrip($event)"
        (change)="shipmentChanged()"
    ></rt-jeopardized-shipment-off-route>
    <rt-jeopardized-shipment-unknown
        *ngSwitchDefault
        [shipment]="shipment"
        (popoutTrip)="onPopoutTrip($event)"
    ></rt-jeopardized-shipment-unknown>
</ng-container>

