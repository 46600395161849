import { Observable } from 'rxjs';
import { DialogOptionsBase } from '@bds/core';
import { Equipment, EquipReportCatgEquip } from '../../models';

export class EquipmentReportCategoriesDialogOptions extends DialogOptionsBase<
    EquipReportCatgEquip | EquipReportCatgEquip[] | Observable<EquipReportCatgEquip[]>
> {
    title: string;
    equipment?: Equipment[];
}
