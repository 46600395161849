import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import {
    RtDestinationAlias,
    RtJeopardizedShipment,
    RtJeopardizedShipmentAdapter,
} from '@bds/railtrac-models';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { Observable, catchError, map } from 'rxjs';

export abstract class RtJeopardizedShipmentApiServiceOptions {
    apiUrl: string = `api/jeopardizedshipment/`;
    odataUrl: string = `odata/jeopardizedshipment`;
    participatingRailroadOdataUrl: string = `odata/participatingrailroad`;
    destinationAliasOdataUrl: string = `odata/destinationalias`;
}

@Injectable({
    providedIn: 'root',
})
export class RtJeopardizedShipmentService {
    constructor(
        private options: RtJeopardizedShipmentApiServiceOptions,
        private httpClient: HttpClient,
        private adapter: RtJeopardizedShipmentAdapter,
        private errorService: ApiErrorHandlerService,
    ) {}

    getODataStore(): DataSource {
        return new DataSource({
            store: new ODataStore({
                version: 4,
                url: this.options.odataUrl,
                //key: 'ormId',
            }),
            paginate: true,
            pageSize: 20,
        });
    }

    findJeopardizedShipmentByRouteCodes(
        routeCodes: string[],
        reasonValue?: string,
    ): Observable<RtJeopardizedShipment[]> {
        const routeCode = this.adapter.metadata.find(
            (f) => f.client === nameof<RtJeopardizedShipment>('routeCode'),
        ).server;
        const reason = this.adapter.metadata.find(
            (f) => f.client === nameof<RtJeopardizedShipment>('reason'),
        ).server;
        let carsToFilter = reasonValue ? `(${reason} eq '${reasonValue}') and (` : '(';
        routeCodes.forEach((routeCode, i) => {
            carsToFilter =
                carsToFilter +
                `(routeCode eq '${routeCode}')` +
                (routeCodes.length > i + 1 ? ` or ` : '');
        });
        const filter = `$filter=${carsToFilter})`;
        const orderby = `$orderby=${routeCode} desc`;
        const query = [filter, orderby].join('&');
        return this.httpClient
            .get<RtJeopardizedShipment[]>(`${this.options.odataUrl}?${query}`)
            .pipe(
                map((data: any) => {
                    if (Array.isArray(data.value)) {
                        const mappedTrips = data.value.map((m) => this.adapter.adapt(m));
                        return mappedTrips.length > 0 ? mappedTrips : [];
                    } else {
                        return this.adapter.adapt(data.value);
                    }
                }),
                catchError((err) => this.errorService.handleError(err)),
            );
    }
}

