import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BdsHelpersModule } from '@bds/helpers';
import { VendorsModule } from '../../vendors/vendors.module';
import { EquipmentListComponentsModule } from '../../components/equipment-list/equipment-list-components.module';
import { EquipmentDetailsModule } from '../../screens/equipment-details/equipment-details.module';
import { EquipmentComponent } from './equipment.component';

@NgModule({
    declarations: [EquipmentComponent],
    imports: [
        BdsHelpersModule,
        CommonModule,
        EquipmentDetailsModule,
        EquipmentListComponentsModule,
        VendorsModule,
    ],
    exports: [EquipmentComponent]
})
export class EquipmentModule {}
