<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <dx-data-grid id="bdsReportGrid" [dataSource]="gridSource" height="80vh" minHeight="200px">
        <dxi-column
            dataField="carID"
            cellTemplate="carIDTemplate"
            caption="Car ID"
            width="auto"
            [minWidth]="100"
            allowSearch="true"
        ></dxi-column>
        <div *dxTemplate="let item of 'carIDTemplate'">
            <p class="">{{ item.data.car_init }} {{ item.data.car_no }}</p>
        </div>
        <dxi-column dataField="ship_date_time" dataType="date" caption="Ship Date"></dxi-column>
        <dxi-column caption="Bad Order">
            <dxi-column
                dataField="bad_order_start"
                dataType="date"
                caption="Start Date"
            ></dxi-column>
            <dxi-column dataField="bad_order_end" dataType="date" caption="End Date"></dxi-column>
        </dxi-column>
        <dxi-column dataField="bad_order_delay" caption="Delay Days"></dxi-column>
        <dxi-column dataField="fleet_id" caption="Fleet"></dxi-column>
        <dxi-column dataField="business_group" caption="Business Group"></dxi-column>
        <dxi-column dataField="le" caption="L/E"></dxi-column>
        <dxi-column dataField="road" caption="Road"></dxi-column>
        <dxi-column
            dataField="Location"
            cellTemplate="locationTemplate"
            caption="Location"
            width="auto"
            [minWidth]="100"
            allowSearch="true"
        ></dxi-column>

        <div *dxTemplate="let item of 'consigneeTemplate'">
            <p class="">{{ item.data.loc_city }} {{ item.data.loc_state }}</p>
        </div>

        <dxi-column dataField="trn_jct" caption="Trn/Jct"></dxi-column>
        <dxi-column dataField="bad_order_reason" caption="Reason"></dxi-column>
        <dxi-column dataField="comments" caption="Comments"></dxi-column>
    </dx-data-grid>
</div>
