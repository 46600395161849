import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule, Routes } from '@angular/router';
import { BdsDxDataGridModule } from '@bds/components';
import { BdsFastracModule } from '@bds/fastrac';
import { BdsSmartComponentsModule } from '@bds/smart-components';
import { DxDataGridModule } from 'devextreme-angular';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { BdsSegmentMilesDetailsComponent } from './bds-segment-miles-details/bds-segment-miles-details.component';
import { BdsSegmentMilesFormItemComponent } from './bds-segment-miles-form-item/bds-segment-miles-form-item.component';
import { BdsSegmentMilesFormComponent } from './bds-segment-miles-form/bds-segment-miles-form.component';
import { BdsSegmentMilesGridComponent } from './bds-segment-miles-grid/bds-segment-miles-grid.component';
import { BdsSegmentMilesComponent } from './bds-segment-miles.component';

@NgModule({
    declarations: [
        BdsSegmentMilesGridComponent,
        BdsSegmentMilesFormItemComponent,
        BdsSegmentMilesFormComponent,
    ],
    exports: [
        BdsSegmentMilesFormItemComponent,
        BdsSegmentMilesGridComponent,
        BdsSegmentMilesFormComponent,
    ],
    imports: [
        CommonModule,
        DxDataGridModule,
        BdsDxDataGridModule,
        BdsFastracModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        DxDateBoxModule,
        BdsSmartComponentsModule,
    ],
})
export class BdsSegmentMilesModule {}

const routes: Routes = [
    {
        path: '',
        component: BdsSegmentMilesComponent,
        children: [{ path: 'details', component: BdsSegmentMilesDetailsComponent }],
    },
];

@NgModule({
    imports: [
        CommonModule,
        BdsSegmentMilesModule,
        MatToolbarModule,
        MatButtonModule,
        BdsSmartComponentsModule,
        RouterModule.forChild(routes),
    ],
    declarations: [BdsSegmentMilesDetailsComponent, BdsSegmentMilesComponent],
})
export class BdsSegmentMilesRoutedModule {}
