import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RtSplcErpc } from '@bds/railtrac-models';
import ODataStore from 'devextreme/data/odata/store';

@Injectable({
    providedIn: 'root',
})
export class RtRouteDetailServiceOptions {
    apiUrl = `api/routedetail/`;
    odataUrl = `odata/routedetail`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsRouteDetailService {
    constructor(private options: RtRouteDetailServiceOptions, private http: HttpClient) {}

    add(model) {
        return this.http.post(this.options.apiUrl, model);
    }

    delete(id: number) {
        return this.http.delete(`${this.options.apiUrl}/${id}`);
    }

    edit(model) {
        return this.http.put(`${this.options.apiUrl}/${model.ormId}`, model);
    }

    getODataStore() {
        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: 'ormId',
            deserializeDates: false,
        });
    }
}

export class RtRouteDetail {
    etaHours: number;
    locCity: string;
    locCityType: string;
    locState: string;
    ormId: number;
    road: string;
    routeCode: string;
    routeSeq: number;

    constructor(data: {} = null) {
        Object.assign(this, data);
    }

    private _cityState: RtSplcErpc;

    get cityState(): RtSplcErpc {
        return <RtSplcErpc>{
            erpcState: this.locState,
            erpcCity: this.locCity,
        };
    }

    set cityState(value: RtSplcErpc) {
        this._cityState = value;
        if (value) {
            this.locCity = value.erpcCity;
            this.locState = value.erpcState;
        }
    }
}
