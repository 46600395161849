<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content [formGroup]="destinationAliasForm">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h5 class="mini-headline">Trip Location</h5>
                <div class="row">
                    <div class="col-sm-6">
                        <mat-form-field class="w-100">
                            <mat-label>Trip City</mat-label>
                            <input
                                matInput
                                bdsShoutcase
                                formControlName="tripCity"
                                *ngIf="!isInitiallyBlank"
                            />
                            <input
                                matInput
                                bdsShoutcase
                                formControlName="tripCity"
                                *ngIf="isInitiallyBlank"
                                cdkFocusInitial
                            />
                            <mat-error *ngIf="destinationAliasForm.controls['tripCity'].invalid">{{
                                getErrorMessage(destinationAliasForm.controls['tripCity'])
                            }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="w-100">
                            <mat-label>Trip State</mat-label>
                            <input matInput bdsShoutcase formControlName="tripState" />
                            <mat-error *ngIf="destinationAliasForm.controls['tripState'].invalid">{{
                                getErrorMessage(destinationAliasForm.controls['tripState'])
                            }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <h5 class="mini-headline">CLM Location</h5>
                <div class="row">
                    <div class="col-sm-6">
                        <mat-form-field class="w-100">
                            <mat-label>CLM City</mat-label>
                            <input matInput bdsShoutcase formControlName="clmCity" />
                            <mat-error *ngIf="destinationAliasForm.controls['clmCity'].invalid">{{
                                getErrorMessage(destinationAliasForm.controls['clmCity'])
                            }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="w-100">
                            <mat-label>CLM State</mat-label>
                            <input matInput bdsShoutcase formControlName="clmState" />
                            <mat-error *ngIf="destinationAliasForm.controls['clmState'].invalid">{{
                                getErrorMessage(destinationAliasForm.controls['clmState'])
                            }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" color="accent" mat-dialog-close>Cancel</button>
    <button
        *ngIf="!isInitiallyBlank"
        mat-button
        class="text-uppercase"
        color="accent"
        [mat-dialog-close]="destinationAliasForm.value"
        cdkFocusInitial
    >
        Save
    </button>
    <button
        *ngIf="isInitiallyBlank"
        mat-button
        class="text-uppercase"
        color="accent"
        [mat-dialog-close]="destinationAliasForm.value"
    >
        Save
    </button>
</mat-dialog-actions>
