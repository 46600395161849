<h1 mat-dialog-title>{{title}} Comment for {{ comment.carInit }}{{ comment.carNo }}</h1>
<div mat-dialog-content>
    <div class="bds-error-message">{{ errorMessage }}</div>
    <rt-trip-comment [comment]="comment" (commentChange)="onCommentChanged($event)"> </rt-trip-comment>
</div>
<div mat-dialog-actions align="end">
    <button mat-button color="accent" (click)="onCancelClick()">Cancel</button>
    <button mat-button color="accent" (click)="onSaveClick()" [disabled]="!isValid || isSaving">
        Save
    </button>
</div>
