import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { RtSegmentMiles } from '@bds/railtrac-models';
import { BdsSegmentMilesMetadata } from './bds-segment-miles.service';

@Injectable()
export class SegmentMilesFormService {
    constructor(private metaData: BdsSegmentMilesMetadata) {}

    private _form = new UntypedFormArray([]);

    get form() {
        return this._form;
    }

    add(obj: RtSegmentMiles) {
        const form = this.metaData.getFormGroup();
        this._form.controls.push(form);
        form.patchValue(obj);
        return form;
    }

    remove(form: UntypedFormGroup) {
        const index = this._form.controls.indexOf(form);
        this._form.controls.splice(index, 1);
    }
}
