<bds-fastrac-design-display [fastracId]="fastracId" [gridId]="gridId" [showFastrac]="showFastrac">
</bds-fastrac-design-display>
<dx-data-grid
    bds-grid
    #grid
    [gridId]="gridId"
    [fastracId]="fastracId"
    [bdsFastracVisible]="showFastrac"
    [bdsUseFastrac]="true"
    id="bdsEquipmentGrid"
    [dataSource]="dataSource"
    height="800px"
    [bdsAllowAddRecord]="true"
>
</dx-data-grid>
