<div>
    <rt-diverted-trip-list
        [gridSource]="divertedTripSource"
        [divertedTripColumns]="divertedTripColumns"
        [gridId]="gridId"
        [needsRefresh]="!isSaving"
        [selectedItem]="initialSelectedRowKeys[0]"
        [selectedFastrac]="fastracId"
        (selectedFastracChange)="onFastracIdChanged($event)"
        (selectedItemChange)="onSelectedDiversionChanged($event)"
    >
    </rt-diverted-trip-list>
</div>
