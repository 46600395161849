// TODO: Left in getFilteredForEquipment in case need for other feature - remove otherwise
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';
import { EquipmentMechField } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class EquipmentMechFieldsService {
    controllerName = 'EquipMechFields';

    constructor(
        private http: HttpClient,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    getFilteredForComponent(compId: string): Observable<EquipmentMechField[]> {
        // TODO: Find out how to add $orderby
        // https://localhost/odata/EquipMechFields?$filter=tcmEquipComponentMechFields/any(t: t/ComponentId eq 'comp1')
        if (compId) {
            const filterUrl = `?$filter=TcmEquipComponentMechFields/any(t: t/ComponentId eq '${compId}')`;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
            return (
                this.http
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .get<any>(`${this.odatabaseUrl}${this.controllerName}` + filterUrl)
                    .pipe(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
                        map((data) => data.value),
                        shareReplay(),
                        catchError((err) => this.apiErrorHandler.handleError(err)),
                    )
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return of(null);
        }
    }

    getFilteredForEquipment(filter: string[]): Observable<EquipmentMechField[]> {
        let filterUrl = '';
        // TODO: Find out how to add $orderby
        // https://localhost/odata/EquipMechField?$filter=tcmEquipCatgMechFields/any(t: t/EquipmentCategory in (cat1, cat2))
        if (filter && filter.length) {
            let cats = '';
            filter.forEach((f) => {
                cats = cats + `'` + f + `',`;
            });

            if (cats.length) {
                cats = cats.slice(0, cats.length - 1);
            }

            filterUrl = `t/EquipmentCategory in (${cats})`;

            const expandUrl = '?$filter=TcmEquipCatgMechFields/any(t: ' + filterUrl + ')';

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
            return (
                this.http
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .get<any>(`${this.odatabaseUrl}${this.controllerName}` + expandUrl)
                    .pipe(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
                        map((data) => data.value),
                        shareReplay(),
                        catchError((err) => this.apiErrorHandler.handleError(err)),
                    )
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return of(null);
        }
    }
}
