<div class="origin-list">
    <div class="container-fluid">
        <bds-fastrac-design-display
            [fastracId]="fastracId"
            [gridId]="gridId"
            [showFastrac]="showFastrac"
            (applyFastrac)="onApplyFastrac($event)"
            (fastracIdChange)="onFastracIdChanged($event)"
            (fastracDesignsChange)="onFastracDesignsChanged($event)"
            (showFastracChange)="onFastracVisibleChanged($event)"
        >
        </bds-fastrac-design-display>
    </div>

    <div class="origin-grid-container">
        <dx-data-grid
            bds-grid
            #bdsOriginGrid
            [gridId]="gridId"
            [needsRefresh]="needsRefresh"
            [fastracId]="fastracId"
            [fastracDesign]="currentFastrac"
            [bdsFastracVisible]="showFastrac"
            [bdsFastracDesignList]="fastracList"
            [bdsUseFastrac]="true"
            [bdsAllowAddRecord]="true"
            [bdsAllowEditRecords]="true"
            [bdsAllowDeleteRecords]="true"
            [bdsCustomizeColumns]="customizeColumns"
            id="bdsOriginGrid"
            [dataSource]="gridSource"
            height="91vh"
            [selectedRowKeys]="selectedRowKeys"
            (bdsShowFastrac)="onShowFastrac()"
            (fastracIdChange)="onGridFastracIdChanged($event)"
            (selectedRowKeysChange)="onSelectedRowKeysChanged($event)"
            (bdsAddRecordClicked)="onAddOrigin()"
            (bdsEditRecordsClicked)="onEditOrigin()"
            (bdsDeleteRecordsClicked)="onDeleteOrigin()"
        >
        </dx-data-grid>
    </div>
</div>
