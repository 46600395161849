<div class="container-fluid">
    <bds-fastrac-design-display
        [fastracId]="fastracId"
        [gridId]="gridId"
        [showFastrac]="showFastrac"
        (applyFastrac)="onApplyFastrac($event)"
        (fastracIdChange)="onFastracIdChanged($event)"
        (fastracDesignsChange)="onFastracDesignsChanged($event)"
        (showFastracChange)="onFastracVisibleChanged($event)"
    >
    </bds-fastrac-design-display>
</div>
<dx-data-grid
    bds-grid
    #grid
    [gridId]="gridId"
    [fastracId]="fastracId"
    [bdsFastracVisible]="showFastrac"
    [bdsUseFastrac]="true"
    id="bdsEquipmentGrid"
    [dataSource]="dataSource"
    height="400px"
    [bdsAllowAddRecord]="true"
    (onSelectionChanged)="selectionChanged(grid?.instance?.getSelectedRowsData())"
    [bdsGridMode]="gridMode"
    (bdsAddRecordClicked)="add()"
    (bdsShowFastrac)="onShowFastrac()"
    (fastracIdChange)="onGridFastracIdChanged($event)"
    [bdsFastracDesignList]="fastracList"
    [fastracDesign]="currentFastrac"
    [bdsCustomizeColumns]="customizeColumns"
>
</dx-data-grid>
