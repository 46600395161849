import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ApiErrorHandlerService, ArrayFunctionsService } from '@bds/helpers';
import { EquipComponentModel, EquipmentComponentCategory } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class EquipmentComponentCategoryService {
    controllerName = 'EquipComponentCategory';

    constructor(
        private http: HttpClient,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
        private arrayService: ArrayFunctionsService,
    ) {}

    getAll(): Observable<EquipmentComponentCategory[]> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
        return this.http.get<any>(`${this.odatabaseUrl}${this.controllerName}`).pipe(
            map((data) =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                data.value.map((i) => {
                    const adapt = i as EquipmentComponentCategory;
                    adapt.categoryDscr = (adapt.categoryDscr || '').toUpperCase();
                    return adapt;
                }),
            ),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    getComponentsForCategories(filter: string[]): Observable<EquipComponentModel[]> {
        if (filter && filter.length) {
            let cats = '';
            filter.forEach((f) => {
                cats = cats + `'` + f + `',`;
            });

            if (cats.length) {
                cats = cats.slice(0, cats.length - 1);
            }

            const filterUrl = `?$filter=ComponentCategory in (${cats})`;
            const expandUrl = `&$expand=EquipComponentCatgComponents($expand=Component)`;

            return (
                this.http
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .get<any>(`${this.odatabaseUrl}${this.controllerName}${filterUrl}${expandUrl}`)
                    .pipe(
                        map((data) => {
                            const components: EquipComponentModel[] = [];
                            const adapt: EquipmentComponentCategory[] =
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                (data.value as EquipmentComponentCategory[]) || [];
                            adapt.forEach((e) => {
                                e.equipComponentCatgComponents.forEach((c) => {
                                    components.push(c.component);
                                });
                            });

                            const distinctComponents: EquipComponentModel[] = components.filter(
                                (value, index, array) =>
                                    array.findIndex((c) => c.componentId === value.componentId) ===
                                    index,
                            );

                            return this.arrayService.sortAlphabetically(
                                distinctComponents,
                                'componentDscr',
                            ) as EquipComponentModel[];
                        }),
                        shareReplay(),
                        catchError((err) => this.apiErrorHandler.handleError(err)),
                    )
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return of(null);
        }
    }
}
