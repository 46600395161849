import { Injectable } from '@angular/core';
import { nameof, IModelMetadata, ClientAdapter } from '@bds/core';

export class RtSysDef {
    tripReferenceFieldName: [];

    constructor() {}
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtSysDef>('tripReferenceFieldName'), server: 'tripReferenceFieldName' },
];

@Injectable({
    providedIn: 'root',
})
export class RtSysDefAdapter extends ClientAdapter<RtSysDef> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;

    public adapt(item: any): RtSysDef {
        const obj: any = super.adapt(item);
        return obj;
    }
}
