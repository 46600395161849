import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-trip-route-screen',
    templateUrl: './trip-route-screen.component.html',
    styleUrls: ['./trip-route-screen.component.scss'],
})
export class TripRouteScreenComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
