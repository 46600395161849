<dx-data-grid
    bds-grid
    #bdsRouteGrid
    id="bdsRouteGrid"
    [dataSource]="dataSource"
    height="35vh"
    minHeight="200px"
    bdsGridMode="multiple"
    [bdsAllowAddRecord]="!readOnly"
    [bdsAllowDeleteRecords]="!readOnly"
    [bdsUseZeroPadDates]="true"
    (bdsAddRecordClicked)="add()"
    (bdsDeleteRecordsClicked)="delete(confirm)"
>
    <dxi-column dataField="customerNo" caption="Customer Number"></dxi-column>
    <dxi-column dataField="customerFact.customerName" caption="Customer Name"></dxi-column>
    <dxi-column dataField="customerFact.customerCity" caption="Customer City"></dxi-column>
    <dxi-column dataField="customerFact.customerState" caption="Customer State"></dxi-column>
</dx-data-grid>

<ng-template #confirm let-data>
    <bds-bds-dialog-confirm [data]="data">
        <div>
            <table mat-table [dataSource]="data.dataSource" class="table-container">
                <ng-container matColumnDef="customerNo">
                    <th mat-header-cell *matHeaderCellDef>Customer Number</th>
                    <td mat-cell *matCellDef="let element">{{ element.customerNo }}</td>
                </ng-container>
                <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef>Customer Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.customerFact.customerName }}
                    </td>
                </ng-container>
                <tr
                    mat-header-row
                    *matHeaderRowDef="['customerNo', 'customerName']; sticky: true"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: ['customerNo', 'customerName']"
                    style="height: 30px"
                ></tr>
            </table>
        </div>
    </bds-bds-dialog-confirm>
</ng-template>
