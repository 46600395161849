import { Injectable } from '@angular/core';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtSplcErpc implements From<RtSplcErpc> {
    constructor(
        public splc?: string,
        public city?: string,
        public state?: string,
        public erpcCity?: string,
        public erpcState?: string,
        public rule260?: string,
        public county?: string,
        public countyCode?: string,
        public region?: string,
        public regionCode?: string,
        public rateBaseSplc?: string,
        public primaryRateBaseYorn?: string,
        public hhgMmSplc?: string,
    ) {}
    from(splc: RtSplcErpc): RtSplcErpc {
        Object.keys(splc).forEach((k) => (this[k] = splc[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtSplcErpc>('splc'), server: 'splc' },
    { client: nameof<RtSplcErpc>('city'), server: 'city' },
    { client: nameof<RtSplcErpc>('state'), server: 'state' },
    { client: nameof<RtSplcErpc>('erpcCity'), server: 'erpcCity' },
    { client: nameof<RtSplcErpc>('erpcState'), server: 'erpcState' },
    { client: nameof<RtSplcErpc>('rule260'), server: 'rule260' },
    { client: nameof<RtSplcErpc>('county'), server: 'county' },
    { client: nameof<RtSplcErpc>('countyCode'), server: 'countyCode' },
    { client: nameof<RtSplcErpc>('region'), server: 'region' },
    { client: nameof<RtSplcErpc>('regionCode'), server: 'mileAllowType' },
    { client: nameof<RtSplcErpc>('rateBaseSplc'), server: 'rateBaseSplc' },
    { client: nameof<RtSplcErpc>('primaryRateBaseYorn'), server: 'primaryRateBaseYorn' },
    { client: nameof<RtSplcErpc>('hhgMmSplc'), server: 'hhgMmSplc' },
];

@Injectable({
    providedIn: 'root',
})
export class RtSplcErpcAdapter extends TwoWayAdapter<RtSplcErpc> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}
