<form [formGroup]="routeCodeForm">
    <div class="row mt-3">
        <div class="col-3">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Route Code</mat-label>
                <input matInput tabindex="-1" formControlName="routeCode" bdsShoutcase />
                <mat-error *ngIf="routeCodeForm.controls.routeCode.errors">
                    {{ getError(routeCodeForm.controls.routeCode) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-3">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Route Description</mat-label>
                <input matInput tabindex="-1" formControlName="routeDscr" bdsShoutcase />
                <mat-error *ngIf="routeCodeForm.controls.routeDscr.errors">
                    {{ getError(routeCodeForm.controls.routeDscr) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-3">
            <mat-form-field
                floatLabel="always"
                class="w-100"
                appearance="fill"
                [class.bds-mat-dense-form-field]="dense"
            >
                <mat-label>Assignment</mat-label>
                <input matInput tabindex="-1" formControlName="assignment" bdsShoutcase />
                <mat-error *ngIf="routeCodeForm.controls.assignment.errors">
                    {{ getError(routeCodeForm.controls.assignment) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-3">
            <mat-checkbox
                [checked]="routeCodeForm?.controls?.activeStatus?.value === 'Y'"
                (change)="setFlag($event, routeCodeForm?.controls?.activeStatus)"
                [disabled]="readOnly"
            >
                Active
            </mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-4">
            <div class="column">
                <h4 class="d-flex flex-row">
                    <span>Route Locations</span>
                    <span class="flex-fill"></span>
                </h4>
                <div class="row">
                    <div class="col-7 mb-3">
                        <bds-splc-select
                            formControlName="originSplc"
                            label="Origin City, State"
                            [enableSearchPopup]="true"
                            [useErpcOrigin]="true"
                            [displayValue]="displayState"
                            (selected)="setOriginGeography($event)"
                            [title]="'Find Origin'"
                            [disabled]="!isCreate"
                        >
                            <mat-error *ngIf="routeCodeForm.controls.originSplc.invalid"
                                >Origin City, State is required</mat-error
                            >
                        </bds-splc-select>
                    </div>
                    <div class="col-5">
                        <bds-splc-select
                            *ngIf="!isCreate"
                            formControlName="originSplc"
                            label="Origin City, State"
                            [displayValue]="displayValue"
                            (selected)="setOriginGeography($event)"
                            [disabled]="true"
                            [useErpcOrigin]="true"
                        >
                            <mat-error *ngIf="routeCodeForm.controls.originSplc.invalid"
                                >Origin SPLC is required</mat-error
                            >
                        </bds-splc-select>

                        <mat-form-field
                            *ngIf="isCreate"
                            floatLabel="always"
                            class="w-100 padding-bottom-0"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>SPLC</mat-label>
                            <input
                                matInput
                                tabindex="-1"
                                [(ngModel)]="routeCodeForm.controls.originSplc.value"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="true"
                            />
                            <mat-error *ngIf="routeCodeForm.controls.originSplc.invalid"
                                >Origin SPLC is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-7 mb-3">
                        <bds-splc-select
                            formControlName="destSplc"
                            label="Destination City, State"
                            [enableSearchPopup]="true"
                            [useErpcOrigin]="true"
                            [displayValue]="displayState"
                            [title]="'Destination search'"
                            (selected)="setDestinationGeography($event)"
                        >
                            <mat-error *ngIf="routeCodeForm.controls.destSplc.invalid"
                                >Destination City, State is required</mat-error
                            >
                        </bds-splc-select>
                    </div>
                    <div class="col-5 mb-3">
                        <bds-splc-select
                            *ngIf="!isCreate"
                            formControlName="destSplc"
                            label="Destination City, State"
                            [useErpcOrigin]="true"
                            [displayValue]="displayValue"
                            (selected)="setDestinationGeography($event)"
                            [disabled]="true"
                        >
                            <mat-error *ngIf="routeCodeForm.controls.destSplc.invalid"
                                >Destination SPLC is required</mat-error
                            >
                        </bds-splc-select>

                        <mat-form-field
                            *ngIf="isCreate"
                            floatLabel="always"
                            class="w-100 padding-bottom-0"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>SPLC</mat-label>
                            <input
                                matInput
                                tabindex="-1"
                                [(ngModel)]="routeCodeForm.controls.destSplc.value"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="true"
                            />
                            <mat-error *ngIf="routeCodeForm.controls.destSplc.invalid"
                                >Destination SPLC is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-7 mb-3">
                        <bds-splc-select
                            *ngIf="!isCreate"
                            formControlName="releaseSplc"
                            label="Release City, State"
                            [enableSearchPopup]="true"
                            [useErpcOrigin]="true"
                            [displayValue]="displayState"
                        >
                            <mat-error *ngIf="routeCodeForm.controls.releaseSplc.invalid"
                                >Release City, State is required</mat-error
                            >
                        </bds-splc-select>

                        <mat-form-field
                            *ngIf="isCreate"
                            floatLabel="always"
                            class="w-100 padding-bottom-0"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Release City, State</mat-label>
                            <input
                                matInput
                                tabindex="-1"
                                [(ngModel)]="releaseCityState"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="true"
                            />
                            <mat-error *ngIf="routeCodeForm.controls.releaseSplc.invalid"
                                >Release City, State is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <div class="col-5">
                        <bds-splc-select
                            *ngIf="!isCreate"
                            formControlName="releaseSplc"
                            label="SPLC"
                            [displayValue]="displayValue"
                            [disabled]="true"
                            [useErpcOrigin]="true"
                        >
                            <mat-error *ngIf="routeCodeForm.controls.releaseSplc.invalid"
                                >Release SPLC is required</mat-error
                            >
                        </bds-splc-select>

                        <mat-form-field
                            *ngIf="isCreate"
                            floatLabel="always"
                            class="w-100 padding-bottom-0"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>SPLC</mat-label>
                            <input
                                matInput
                                tabindex="-1"
                                [(ngModel)]="routeCodeForm.controls.releaseSplc.value"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="true"
                            />
                            <mat-error *ngIf="routeCodeForm.controls.releaseSplc.invalid"
                                >Release SPLC is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-7 mb-3">
                        <bds-splc-select
                            formControlName="returnSplc"
                            label="Return City, State"
                            [enableSearchPopup]="true"
                            [useErpcOrigin]="true"
                            [displayValue]="displayState"
                        >
                            <mat-error *ngIf="routeCodeForm.controls.returnSplc.invalid"
                                >Return City, State is required</mat-error
                            >
                        </bds-splc-select>
                    </div>
                    <div class="col-5 mb-3">
                        <bds-splc-select
                            *ngIf="!isCreate"
                            formControlName="returnSplc"
                            label="SPLC"
                            [displayValue]="displayValue"
                            [disabled]="true"
                            [useErpcOrigin]="true"
                        >
                            <mat-error *ngIf="routeCodeForm.controls.returnSplc.invalid"
                                >Return SPLC is required</mat-error
                            >
                        </bds-splc-select>

                        <mat-form-field
                            *ngIf="isCreate"
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>SPLC</mat-label>
                            <input
                                matInput
                                tabindex="-1"
                                [(ngModel)]="routeCodeForm.controls.returnSplc.value"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="true"
                            />
                            <mat-error *ngIf="routeCodeForm.controls.returnSplc.invalid"
                                >Return SPLC is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-2">
            <div class="column">
                <h4 class="d-flex flex-row">
                    <span>Route Carriers</span>
                    <span class="flex-fill"></span>
                </h4>
                <div class="row">
                    <div class="col-12">
                        <bds-railroad-select
                            *ngIf="!isCreate"
                            formControlName="road"
                            label="Departing Carrier"
                            class="bds-readonly-wrapper"
                            bdsScrollSpyLink="routeSegmentBuilder"
                        ></bds-railroad-select>

                        <mat-form-field
                            *ngIf="isCreate"
                            floatLabel="always"
                            class="w-100 padding-bottom-0"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                            bdsScrollSpyLink="routeSegmentBuilder"
                        >
                            <mat-label>Departing Carrier</mat-label>
                            <input
                                matInput
                                tabindex="-1"
                                [value]="routeCodeForm.controls.road.value"
                                disabled="true"
                            />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3">
                        <bds-railroad-select
                            *ngIf="!isCreate"
                            formControlName="dlvRoad"
                            label="Delivering Carrier"
                            bdsScrollSpyLink="routeSegmentBuilder"
                        ></bds-railroad-select>

                        <mat-form-field
                            *ngIf="isCreate"
                            floatLabel="always"
                            class="w-100 padding-bottom-0"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                            bdsScrollSpyLink="routeSegmentBuilder"
                        >
                            <mat-label>Delivering Carrier</mat-label>
                            <input
                                matInput
                                tabindex="-1"
                                [value]="routeCodeForm.controls.dlvRoad.value"
                                disabled="true"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="column">
                <h4 class="d-flex flex-row">
                    <span>Route Specifications</span>
                    <span class="flex-fill"></span>
                </h4>
                <div class="row">
                    <div class="col-4 mb-3">
                        <bds-erpc-origin-select
                            formControlName="originCode"
                            label="Origin Code"
                            [displayValue]="displayOriginValue"
                            [valueExpr]="'originCode'"
                            [enableAdd]="true"
                            [enableEdit]="routeCodeForm.controls.originCode.value"
                            [enableSearchPopup]="true"
                            [newWindowForAdd]="true"
                            [newWindowForEdit]="true"
                            [title]="'Select Origin'"
                        >
                            <mat-error *ngIf="routeCodeForm.controls.originCode.errors">
                                {{ getError(routeCodeForm.controls.originCode) }}
                            </mat-error>
                        </bds-erpc-origin-select>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <mat-slide-toggle
                            [disabled]="readOnly"
                            labelPosition="before"
                            [checked]="routeCodeForm?.controls?.autoCloseYorn?.value === 'Y'"
                            (change)="
                                setFlag($event.checked, routeCodeForm?.controls?.autoCloseYorn)
                            "
                        >
                            Auto Close
                        </mat-slide-toggle>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <mat-slide-toggle
                            labelPosition="before"
                            [disabled]="readOnly"
                            [checked]="routeCodeForm?.controls?.autoRteDtlCalcYorn?.value === 'Y'"
                            (change)="
                                setFlag($event.checked, routeCodeForm?.controls?.autoRteDtlCalcYorn)
                            "
                        >
                            Route Detail Auto Calc
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 pb-3">
                        <bds-splc-select
                            *ngIf="intlBorderCrossing"
                            formControlName="borderCrossSplc"
                            label="Intl Border Crossing SPLC"
                            [displayValue]="displayValue"
                            [useErpcOrigin]="true"
                        ></bds-splc-select>

                        <mat-form-field
                            *ngIf="!intlBorderCrossing"
                            floatLabel="always"
                            class="w-100 padding-bottom-0"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Intl Border Crossing SPLC</mat-label>
                            <input
                                matInput
                                tabindex="-1"
                                [(ngModel)]="routeCodeForm.controls.borderCrossSplc.value"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="true"
                            />
                        </mat-form-field>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <mat-slide-toggle
                            labelPosition="before"
                            [disabled]="readOnly"
                            (change)="toggleIntlBorderCrossing($event)"
                        >
                            Intl Border Crossing
                        </mat-slide-toggle>
                    </div>
                    <div class="col-4 d-flex justify-content-end">
                        <mat-slide-toggle
                            labelPosition="before"
                            [disabled]="readOnly"
                            [checked]="routeCodeForm?.controls?.rule11?.value === 'Y'"
                            (change)="setFlag($event.checked, routeCodeForm?.controls?.rule11)"
                        >
                            Rule 11
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Mileage Type</mat-label>
                            <input
                                matInput
                                bdsNumbersOnly
                                tabindex="-1"
                                formControlName="mileageType"
                            />
                            <mat-error *ngIf="routeCodeForm.controls.mileageType.errors">
                                {{ getError(routeCodeForm.controls.mileageType) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field
                            floatLabel="always"
                            class="w-100"
                            appearance="fill"
                            [class.bds-mat-dense-form-field]="dense"
                        >
                            <mat-label>Path Code</mat-label>
                            <input
                                *ngIf="isCreate"
                                matInput
                                tabindex="-1"
                                formControlName="pathCode"
                                bdsShoutcase
                            />

                            <input
                                *ngIf="!isCreate"
                                matInput
                                tabindex="-1"
                                [value]="routeCodeForm.controls.pathCode.value"
                                [disabled]="true"
                            />
                            <mat-error *ngIf="routeCodeForm.controls.pathCode.errors">
                                {{ getError(routeCodeForm.controls.pathCode) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4"></div>
                </div>
            </div>
        </div>
    </div>
    <ng-content></ng-content>
    <div class="row">
        <div class="col-12 col-lg-6">
            <h4 class="d-flex flex-row">
                <span>ETA Specifications</span>
                <span class="flex-fill"></span>
            </h4>
            <div class="row">
                <div class="col-4 col-lg-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Transit Time Out</mat-label>
                        <input matInput bdsNumbersOnly tabindex="-1" formControlName="etaHrsOut" />
                        <mat-error *ngIf="routeCodeForm.controls.etaHrsOut.errors">
                            {{ getError(routeCodeForm.controls.etaHrsOut) }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4 col-lg-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Transit Time In</mat-label>
                        <input matInput bdsNumbersOnly tabindex="-1" formControlName="etaHrsIn" />
                        <mat-error *ngIf="routeCodeForm.controls.etaHrsIn.errors">
                            {{ getError(routeCodeForm.controls.etaHrsIn) }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4 col-lg-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Hist Trip Calc Days</mat-label>
                        <input
                            matInput
                            bdsNumbersOnly
                            tabindex="-1"
                            formControlName="clmPerfCalcHistDays"
                        />
                        <mat-error *ngIf="routeCodeForm.controls.clmPerfCalcHistDays.errors">
                            {{ getError(routeCodeForm.controls.clmPerfCalcHistDays) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-lg-4">
                    <bds-date-time-picker
                        formControlName="lastClmPerfCalc"
                        label="Date Average Calc"
                        type="date"
                        displayFormat="MM/dd/yyyy"
                    ></bds-date-time-picker>
                </div>
                <div class="col-4 col-lg-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Recalc Reset Days</mat-label>
                        <input
                            matInput
                            bdsNumbersOnly
                            tabindex="-1"
                            formControlName="clmPerfCalcResetDays"
                        />
                        <mat-error *ngIf="routeCodeForm.controls.clmPerfCalcResetDays.errors">
                            {{ getError(routeCodeForm.controls.clmPerfCalcResetDays) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-12">
                    <h4 class="d-flex flex-row">
                        <span>Comments</span>
                        <span class="flex-fill"></span>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="dense"
                    >
                        <mat-label>Comments</mat-label>
                        <textarea
                            matInput
                            tabindex="-1"
                            formControlName="comments"
                            rows="5"
                            style="resize: none"
                            bdsShoutcase
                        ></textarea>
                        <mat-error *ngIf="routeCodeForm.controls.comments.errors">
                            {{ getError(routeCodeForm.controls.comments) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</form>
