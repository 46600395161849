import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { nameof } from '@bds/core';
import { RtTripCommentService } from '@bds/data-access';
import { RtCommentType, RtMiscComment, RtTripComment } from '@bds/railtrac-models';
import { Observable, Subject, finalize, of, switchMap, takeUntil, tap } from 'rxjs';
import { RtMultiCommentFormService } from '../rt-multi-comment-dialog/rt-multi-comment-form.service';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { BdsDialogConfirmComponent } from '@bds/components';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'rt-multi-comment-edit-form-item',
    templateUrl: './rt-multi-comment-edit-form-item.component.html',
    styleUrls: ['./rt-multi-comment-edit-form-item.component.css'],
})
export class RtMultiCommentEditFormItemComponent implements OnInit, OnDestroy, OnChanges {
    commentTypes: Observable<RtCommentType[]>;
    defaultComments: Observable<RtMiscComment[]>;

    formGroup: UntypedFormGroup;
    @Input() index: number;
    commentDelete = faTrashAlt;

    private unsub$ = new Subject<void>();
    //private _comment: RtTripComment;

    constructor(
        public commentFormService: RtMultiCommentFormService,
        private tripCommentService: RtTripCommentService,
        private _snackbar: MatSnackBar,
        public dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.commentTypes = this.tripCommentService.getCommentTypes();

        const commentTypeControl = this.formGroup.get(nameof<RtTripComment>('commentType'));
        this.defaultComments = this.tripCommentService.getDefaultCommentsForType(
            commentTypeControl.value,
        );
        const commentCodeControl = this.formGroup.get(nameof<RtTripComment>('commentCode'));
        const commentsControl = this.formGroup.get(nameof<RtTripComment>('comments'));

        commentTypeControl.valueChanges.subscribe((s: string) => {
            this.defaultComments = this.tripCommentService.getDefaultCommentsForType(s);
        });

        commentCodeControl.valueChanges.subscribe((s: string) => {
            if (this.defaultComments) {
                this.defaultComments.subscribe((response) => {
                    var commentCodes = response;
                    const comment = commentCodes.find((x) => x.code === s);
                    if (comment) {
                        commentsControl.setValue(comment.comment);
                    }
                });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {}

    ngOnDestroy(): void {
        if (this.index) {
            this.commentFormService.commentItemForms.removeAt(this.index);
        }
        this.unsub$.next();
        this.unsub$.complete();
    }

    @Input() set comment(value: RtTripComment) {
        this.formGroup = this.commentFormService.addCommentForm(value, this.unsub$, false);
        this.defaultComments = this.tripCommentService.getDefaultCommentsForType(value.commentType);
        this.formGroup.patchValue(value);
    }

    private watchCommentType(value: RtTripComment) {
        const commentTypeControl = this.formGroup.get(nameof<RtTripComment>('commentType'));
        const commentCodeControl = this.formGroup.get(nameof<RtTripComment>('commentCode'));
        const commentsControl = this.formGroup.get(nameof<RtTripComment>('comments'));

        commentTypeControl.valueChanges.pipe(
            takeUntil(this.unsub$),
            tap(() => {
                //commentCodeControl.setValue('');
            }),
            switchMap(
                (response) =>
                    (this.defaultComments =
                        this.tripCommentService.getDefaultCommentsForType(response)),
            ),
        );
        // .subscribe((response) => {
        //     this.defaultComments = response;
        // });

        commentCodeControl.valueChanges.pipe(
            takeUntil(this.unsub$),
            tap((response) => {
                commentsControl.setValue('');
                if (this.defaultComments) {
                    this.defaultComments.subscribe((commentTypeResult) => {
                        var commentCodes = commentTypeResult;
                        const comment = commentCodes.find((x) => x.code === response);
                        if (comment) {
                            commentsControl.setValue(comment.comment);
                        }
                    });
                }
            }),
            finalize(() => {
                this.formGroup.patchValue(value);
            }),
        );
        //     .subscribe({
        //     complete:() =>
        // });
    }

    onDeleteComment(ormId: number) {
        //let selectedTripComment = this.comments.find(i => i.ormId === ormId);
        const dialog = this.dialog.open(BdsDialogConfirmComponent, {
            data: {
                actionText: 'Delete',
                content: `Are you sure you would like to delete the comment?`,
            },
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.tripCommentService.delete(ormId).subscribe({
                    next: (v) => {
                        this._snackbar.open('Deleted Comment', 'Ok', {
                            duration: 3000,
                        });
                    },
                    error: (error) => {
                        this._snackbar.open(error.error, 'Error', {
                            duration: 3000,
                        });
                    },
                    complete: () => {
                        this.commentFormService.emitRefreshCommentChange(true);
                    },
                });
            }
        });
    }

    getDefaultComments(key: string) {
        if (!!key) {
            this.defaultComments = this.tripCommentService.getDefaultCommentsForType(key);
            //     .subscribe((response) => {
            //      = response;
            // });
        } else {
            this.defaultComments = of([]);
        }
    }

    setDefaultComment(comment: string) {
        this.formGroup.controls[nameof<RtTripComment>('comments')].setValue(comment);
    }

    setFlag($event: MatCheckboxChange, toggleControl: AbstractControl): void {
        toggleControl.setValue($event ? 'Y' : 'N');
    }
}
