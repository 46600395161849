import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { RtArrAtDest, RtStateCode, RtClm, RtClmSightCode } from '@bds/railtrac-models';
import {
    BdsCustomerService,
    BdsStateService,
    ClmSightCodeService,
    RtRailroadService,
} from '@bds/data-access';
import { BdsDestinationCriteriaService } from '@bds/data-access';
import DataSource from 'devextreme/data/data_source';
import { ListRefreshService } from '../lib/services/list-refresh.service';

@Component({
    selector: 'bds-destination-criteria',
    templateUrl: './bds-destination-criteria.component.html',
    styleUrls: ['./bds-destination-criteria.component.scss'],
})
export class BdsDestinationCriteriaComponent implements OnInit, OnChanges {
    customers: ODataStore;
    //destinations: ODataStore;
    destinations: DataSource;
    railroads: ODataStore;
    sightCodeLookup: RtClmSightCode[];
    stateLookup: RtStateCode[];

    @Input() customerNumber: string;
    @Input() dense = true;
    @Input() latestClm: RtClm;
    @Input() locationCity: string;
    @Input() locationState: string;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() matFloatLabel: 'auto' | 'always' | 'never' = 'always';

    constructor(
        private customerService: BdsCustomerService,
        private destinationService: BdsDestinationCriteriaService,
        private stateService: BdsStateService,
        private clmSightCodeService: ClmSightCodeService,
        private railroadService: RtRailroadService,
        private _snackbar: MatSnackBar,
        public dialog: MatDialog,
        private listRefreshService: ListRefreshService,
    ) {
        this.customers = customerService.getODataStore();
        this.railroads = railroadService.getODataStore();
        stateService
            .getLookup()
            .pipe(take(1))
            .subscribe((s) => (this.stateLookup = s));
        clmSightCodeService
            .getAll()
            .pipe(take(1))
            .subscribe((sc) => (this.sightCodeLookup = sc));
    }

    ngOnInit() {
        if (this.customerNumber) {
            this.getDestinations();
        }
        this.listRefreshService.refresh$.subscribe((data) => {
            this.getDestinations();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customerNumber && !changes.customerNumber.firstChange && this.customerNumber) {
            this.getDestinations();
        }
    }

    getDestinations() {
        this.destinations = this.destinationService.getDataSource();
    }

    onDelete(event: RtArrAtDest) {
        this.destinationService
            .delete(event.ormId)
            .pipe(take(1))
            .subscribe(
                (s) => {
                    this.afterSaveComplete('Destination Criteria Deleted');
                },
                (err) => this.afterError(err),
            );
    }

    onSaved(event: RtArrAtDest) {
        this.destinations = this.destinationService.getDataSource();
    }

    private afterError(error: any, event?: RtArrAtDest) {
        let message: string = '';
        const customerErrors = [];

        if (typeof error === 'string') {
            message = error;
            customerErrors.push(error);
        } else if (Array.isArray(error) && error[0].validationMessage) {
            message = `Destination Criteria is invalid - ${error[0].validationMessage}`;
            error.forEach((e) => customerErrors.push(e));
        } else {
            message = 'An unknown error occurred';
        }

        this._snackbar.open(message, 'Error', { duration: 3000 });
    }

    private afterSaveComplete(message?: string) {
        this.destinations = this.destinationService.getDataSource();

        if (!message) {
            message = 'Changes saved!';
        }

        this._snackbar.open(message, 'Ok', { duration: 3000 });
    }
}
