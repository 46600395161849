import { Component, OnInit, ViewChild } from '@angular/core';
import { RtRouteParticipatingRailroad } from '@bds/railtrac-models';
import { RtJeopardizedShipmentService } from '@bds/railtrac';
import {
    BdsRouteParticipatingRailroadService,
    BdsRouteParticipatingRailroadDialogComponent,
} from '@bds/participating-railroad';
import DataSource from 'devextreme/data/data_source';
import Store from 'devextreme/data/abstract_store';
import { PageService } from '@bds/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BdsDialogConfirmComponent, BdsDialogConfirmModel } from '@bds/components';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
    templateUrl: './participating-railroad-screen.component.html',
    styleUrls: ['./participating-railroad-screen.component.scss'],
})
export class ParticipatingRailroadScreenComponent implements OnInit {
    public gridSource: DataSource | Store | RtRouteParticipatingRailroad[];
    public selectedRowKeys: Array<number | any> = [];

    @ViewChild('bdsParticipatingRailroadGrid') grid: DxDataGridComponent;

    constructor(
        public service: BdsRouteParticipatingRailroadService,
        public pageService: PageService,
        public jeopardyService: RtJeopardizedShipmentService,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
    ) {
        pageService.setHeader('Participating Railroads');
    }

    ngOnInit() {
        this.gridSource = this.service.getODataStore();
    }

    createRecord() {
        let x: RtRouteParticipatingRailroad = new RtRouteParticipatingRailroad();

        const dialogRef = this.dialog.open(BdsRouteParticipatingRailroadDialogComponent, {
            width: '550px',
            data: { item: x },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.service.add(result).subscribe(
                    (res) => {
                        this.snackbar.open('Participating railroad added', 'Ok', {
                            duration: 3000,
                        });
                    },
                    (err) => {
                        this.snackbar.open('Adding participating railroad failed', 'Ok', {
                            duration: 8000,
                        });
                    },
                    () => {
                        this.grid.instance.refresh();
                    },
                );
            }
            x = result;
        });
    }

    deleteRecords($event: any) {
        const confirmInfo: BdsDialogConfirmModel = {
            title: 'Delete Participating Railroads',
            content: `Are you sure you want to delete ${this.selectedRowKeys.length} participating railroads?`,
            actionText: 'Delete Participating Railroads',
        };

        const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
            data: confirmInfo,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const svcCalls: Observable<boolean>[] = [];
                this.selectedRowKeys.forEach((key) => {
                    svcCalls.push(this.service.delete(key));
                });
                forkJoin(svcCalls).subscribe(
                    (res) => {
                        if (res.some((sm) => sm !== true)) {
                            this.snackbar.open(
                                'Some participating railroads could not be deleted',
                                'Ok',
                                { duration: 8000 },
                            );
                        } else {
                            this.snackbar.open('Selected participating railroad deleted', 'Ok', {
                                duration: 3000,
                            });
                        }
                    },
                    (err) => {
                        this.snackbar.open(
                            'Some participating railroads encountered errors',
                            'Ok',
                            { duration: 8000 },
                        );
                        console.warn('Some participating railroads encountered errors', err);
                    },
                    () => {
                        this.grid.instance.refresh();
                    },
                );
            }
        });
    }
}
