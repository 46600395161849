import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ApiErrorHandlerService } from '@bds/helpers';
import { EquipComponentRemovalCode } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class EquipComponentRemovalCodeService {
    controllerName = 'EquipComponentRemovalCodes';

    constructor(
        private http: HttpClient,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    getAll(): Observable<EquipComponentRemovalCode[]> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
        return this.http.get<any>(`${this.odatabaseUrl}${this.controllerName}`).pipe(
            map((data) =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                data.value.map((i) => {
                    const adapt = i as EquipComponentRemovalCode;
                    adapt.removalDscr = (adapt.removalDscr || '').toUpperCase();
                    return adapt;
                }),
            ),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }
}
