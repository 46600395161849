import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RtNewDiversionComponent } from '../rt-new-diversion/rt-new-diversion.component';

@Component({
    selector: 'rt-multi-diversion',
    templateUrl: './rt-multi-diversion.component.html',
    styleUrls: ['./rt-multi-diversion.component.scss'],
})
export class RtMultiDiversionComponent implements AfterViewInit, OnChanges, OnDestroy {
    currentlyCreatingDiversion$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    currentTripId$: BehaviorSubject<number[]> = new BehaviorSubject([]);

    @Input() creatingDiversion: boolean = false;
    @Input() tripIds: number[];
    @Output() diversionCancelled: EventEmitter<any> = new EventEmitter();
    @Output() diversionCreated: EventEmitter<any> = new EventEmitter();

    @ViewChild(RtNewDiversionComponent) diversionComponent: RtNewDiversionComponent;

    constructor() {}

    ngAfterViewInit() {
        if (this.creatingDiversion) {
            this.diversionComponent.onCreateClick();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tripIds && this.tripIds && this.tripIds.length) {
            this.currentTripId$.next(this.tripIds);
        }

        if (changes.creatingDiversion && this.creatingDiversion && this.diversionComponent) {
            this.diversionComponent.onCreateClick();
        }
    }

    ngOnDestroy() {
        this.currentTripId$.complete();
    }

    getCurrentTripId() {
        return this.currentTripId$.asObservable();
    }

    onDiversionCancelled() {
        this.diversionCancelled.emit();
    }

    onDiversionCreated() {
        this.diversionCreated.emit();
    }
}
