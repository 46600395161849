<div class="sticky-top mat-elevation-z6">
    <mat-toolbar class="trip-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <span *ngIf="!!shipment">
                <!-- <fa-icon [icon]="iconFlag" [fixedWidth]="true" size="lg"></fa-icon> -->
                Multiple Open Shipments for {{ shipment.carInitials }}{{ shipment.carNumber }}
            </span>
            <span class="fill-remaining-space"></span>
            <span> </span>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<mat-tab-group>
    <mat-tab
        *ngFor="let trip of openTrips"
        label="{{ trip.carInit }} {{ trip.carNo }} {{
            trip.shipDatetime | date : 'MM/dd/yyyy HH:mm' : 'UTC'
        }}"
    >
        <rt-jeopardized-shipment-reason-common
            [trip]="trip"
            [useExpansionPanel]="false"
            (saveClick)="onTripSaveClicked($event)"
            (popoutTrip)="onPopoutTripclicked($event)"
        ></rt-jeopardized-shipment-reason-common>
    </mat-tab>
</mat-tab-group>
