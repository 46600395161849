import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BdsDialogMultiConfirmModel } from './bds-dialog-multi-confirm.model';

@Component({
    selector: 'lib-bds-dialog-multi-confirm',
    templateUrl: './bds-dialog-multi-confirm.component.html',
    styleUrls: ['./bds-dialog-multi-confirm.component.css'],
})
export class BdsDialogMultiConfirmComponent {
    constructor(
        public dialogRef: MatDialogRef<BdsDialogMultiConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BdsDialogMultiConfirmModel,
    ) {
        if (!data.actionText) {
            throw new Error('No actionText set for BdsDialogMultiConfirmComponent');
        }
        if (!data.content) {
            throw new Error('No content set for BdsDialogMultiConfirmComponent');
        }
        if (!data.dismissiveText) {
            data.dismissiveText = 'Cancel';
        }
    }
}
