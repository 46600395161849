import { Injectable } from '@angular/core';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtArrAtDest implements From<RtArrAtDest> {
    constructor(
        public customerNo?: string,
        public locationCity?: string,
        public sightCode?: string,
        public railroad?: string,
        public locationState?: string,
        public customerDeliveryHours?: number,
        public ormId?: number,
    ) {}
    from(dest: RtArrAtDest): RtArrAtDest {
        Object.keys(dest).forEach((k) => (this[k] = dest[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtArrAtDest>('customerNo'), server: 'customerNo' },
    { client: nameof<RtArrAtDest>('locationCity'), server: 'locationCity' },
    { client: nameof<RtArrAtDest>('sightCode'), server: 'sightCode' },
    { client: nameof<RtArrAtDest>('railroad'), server: 'railroad' },
    { client: nameof<RtArrAtDest>('locationState'), server: 'locationState' },
    { client: nameof<RtArrAtDest>('customerDeliveryHours'), server: 'customerDeliveryHours' },
    { client: nameof<RtArrAtDest>('ormId'), server: 'ormId' },
];

@Injectable({
    providedIn: 'root',
})
export class RtArrAtDestAdapter extends TwoWayAdapter<RtArrAtDest> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}
