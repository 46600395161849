<dx-data-grid
    bds-grid
    id="bdsRouteRailroadGrid"
    [dataSource]="dataSource"
    [columns]="displayColumns"
    height="35vh"
    minHeight="200px"
    [bdsAllowAddRecord]="!readOnly"
    [bdsAllowDeleteRecords]="!readOnly"
    (bdsAddRecordClicked)="addNewRecord()"
    (bdsDeleteRecordsClicked)="onRemoveRailRoad()"
    [(selectedRowKeys)]="selectedRowKeys"
    #bdsRouteRailroadGrid
>
    <dxi-column dataField="routeCode" caption="Route Code"></dxi-column>
    <dxi-column dataField="railroad" caption="Railroad"></dxi-column>
    <dxi-column dataField="rtRrFact.rrName" caption="Railroad Name"></dxi-column>
    <dxi-column dataField="rtRrFact.isEdi" caption="Is Edi" [visible]="false"></dxi-column>
    <dxi-column dataField="rtRrFact.rrScac" caption="RR Scac" [visible]="false"></dxi-column>
    <dxi-column dataField="rtRrFact.rrTele" caption="RR Tele" [visible]="false"></dxi-column>
    <dxi-column
        dataField="rtRrFact.ediDestSwitchJct"
        caption="Edi Dest Switch Jct"
        [visible]="false"
    ></dxi-column>
    <dxi-column
        dataField="rtRrFact.ediOrigSwitchJct"
        caption="Edi Orig Switch Jct"
        [visible]="false"
    ></dxi-column>
    <dxi-column
        dataField="rtRrFact.ediTransType"
        caption="Edi Trans Type"
        [visible]="false"
    ></dxi-column>
</dx-data-grid>
