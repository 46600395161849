/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { DynamicDropDownValues } from '@bds/models';
import {
    BdsDialogConfirmComponent,
    BdsDialogConfirmModel,
    BdsDialogInfoComponent,
    BdsDialogInfoModel,
} from '@bds/components';
import {
    EquipMechCategory,
    EquipmentCategory,
    EquipmentCategoryWithFields,
    EquipmentMechDate,
    EquipmentMechEav,
    EquipmentMechField,
} from '../../../models';
import { FormlyFieldCreatorService } from '../../../internal-services';

@Component({
    selector: 'bds-equipment-mech-fields',
    templateUrl: './equipment-mech-fields.component.html',
    styleUrls: ['./equipment-mech-fields.component.scss'],
})
export class EquipmentMechFieldsComponent implements OnInit, OnChanges {
    categoryDataSource: DataSource;
    fields: FormlyFieldConfig[] = [];
    formValues = '';
    initializingCategories = true;
    mechForm: UntypedFormGroup = new UntypedFormGroup({});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: any;
    selectedCategories: string[] = [];

    @Input() categories: EquipmentCategory[];
    @Input() dynamicDropdownValues: DynamicDropDownValues[];
    @Input() mechCategories: EquipMechCategory[];
    @Input() mechFieldsByCategory: EquipmentCategoryWithFields[];
    @Input() mechDates: EquipmentMechDate[];
    @Input() mechValues: EquipmentMechEav[];
    @Input() newMechDates: EquipmentMechDate[];
    @Output() dependenciesChange: EventEmitter<{ key: string; value: string }[]> = new EventEmitter<
        { key: string; value: string }[]
    >();
    @Output() equipCategoryChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() modelChange: EventEmitter<{ value: any; isValid: boolean }> = new EventEmitter<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any;
        isValid: boolean;
    }>();

    constructor(
        private formlyFieldCreatorService: FormlyFieldCreatorService,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.model = this.createModel();
        this.createFieldsFromCategories();
        this.createEquipCategories();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.mechValues && !changes.mechValues.firstChange) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.model = this.createModel();
        }

        if (
            (changes.mechFieldsByCategory && !changes.mechFieldsByCategory.firstChange) ||
            (changes.dynamicDropdownValues && !changes.dynamicDropdownValues.firstChange)
        ) {
            this.createFieldsFromCategories();
        }

        if (changes.mechCategories && !changes.mechCategories.firstChange) {
            this.initializingCategories = true;
            this.createEquipCategories();
        }

        if (changes.categories && this.categories) {
            this.setCategoryDataSource(this.categories);
        }
    }

    createEquipCategories(): void {
        if (this.mechCategories) {
            this.selectedCategories = this.mechCategories.map((c) => c.equipmentCategory).sort();
        } else {
            this.selectedCategories = [];
        }
    }

    createFieldsFromCategories(): void {
        const formlyFields: FormlyFieldConfig[] = [];
        const dictDependencies: { key: string; value: string }[] = [];

        const emptyCat = {
            className: 'row w-100',
            template: '<div class="empty-label">There are no fields for this category</div>',
        };

        if (this.mechFieldsByCategory && this.mechFieldsByCategory.length) {
            this.mechFieldsByCategory.forEach((c) => {
                const catHeader = {
                    className: 'section-label row w-100',
                    template: `<div class="category-header col-12">${c.categoryDscr}</div>`,
                };

                formlyFields.push(catHeader);

                if (c.mechFields && c.mechFields.length) {
                    const catFormField: FormlyFieldConfig = {
                        fieldGroupClassName: 'row',
                        className: 'w-100',
                        fieldGroup: [],
                    };

                    // Reset required expressions
                    const requiredExpressions: { id: string; value: string }[] = [];

                    // Create each field for that category and push to the fieldGroup
                    c.mechFields.forEach((f) => {
                        if (f.dataType !== 'D') {
                            // Determine if dropdown
                            const ddValues: DynamicDropDownValues[] = (
                                this.dynamicDropdownValues || []
                            ).filter((d) => d.field === f.mechFieldName);

                            let formField: FormlyFieldConfig;

                            if (!ddValues || !ddValues.length) {
                                formField =
                                    this.formlyFieldCreatorService.createAlphnumericFormField(f);
                            } else if (
                                ddValues.length === 1 &&
                                ddValues[0].valueDisplay === 'LOOK UP'
                            ) {
                                // TODO: Need to convert this to a lookup
                                formField =
                                    this.formlyFieldCreatorService.createAlphnumericFormField(f);
                            } else {
                                formField = this.formlyFieldCreatorService.createSelectFormField(
                                    f,
                                    ddValues,
                                );
                            }

                            if (f.requiredMechField) {
                                requiredExpressions.push({
                                    id: f.requiredMechField,
                                    value: `!!model.${f.mechFieldName}`,
                                });
                            }

                            catFormField.fieldGroup.push(formField);
                        }
                    });

                    // Go through the required expressions and tie to the appropriate field within the category
                    // Any required fields for other categories makes no sense
                    // Any required fields for dates will have to be dealt with later
                    requiredExpressions.forEach((e) => {
                        const fieldIndex: number = catFormField.fieldGroup.findIndex(
                            (f) => f.key === e.id,
                        );
                        if (fieldIndex > -1) {
                            catFormField.fieldGroup[fieldIndex].expressionProperties[
                                'templateOptions.required'
                            ] = e.value;
                        } else {
                            dictDependencies.push({ key: e.id, value: e.value });
                        }
                    });

                    // Push the category fields onto the main formly field config
                    formlyFields.push(catFormField);
                } else {
                    // If no fields, add the empty text to aid the user
                    formlyFields.push(emptyCat);
                }
            });

            this.dependenciesChange.emit(dictDependencies);
        }

        this.fields = [
            {
                fieldGroupClassName: 'row',
                fieldGroup: [...formlyFields],
            },
        ];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createModel(): any {
        const generatedModel = {};

        if (this.mechValues && this.mechValues.length) {
            this.mechValues.forEach((v) => {
                generatedModel[v.mechField] = v.mechValue;
            });
        }

        return generatedModel;
    }

    getFieldsToBeLost(catName: string): string[] {
        const fieldsAffected: EquipmentMechField[] =
            this.mechFieldsByCategory.filter((c) => c.categoryName === catName)[0]?.mechFields ||
            [];

        const lostFields: string[] = [];

        this.mechValues.forEach((v: EquipmentMechEav) => {
            const fieldMatch = fieldsAffected.find((f) => f.mechFieldName === v.mechField);
            if (fieldMatch) {
                lostFields.push(fieldMatch.displayName);
            }
        });

        this.mechDates.forEach((d: EquipmentMechDate) => {
            const fieldMatch = fieldsAffected.find((f) => f.mechFieldName === d.mechField);
            if (fieldMatch) {
                lostFields.push(fieldMatch.displayName);
            }
        });

        return lostFields;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onCategoryChanged(event): void {
        if (event) {
            // If a category is deleted, alert the user that fields will be lost
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (!this.initializingCategories && event.value.length < event.previousValue.length) {
                // Find the missing category
                let catDesc = 'this category';
                let catName = '';
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                event.previousValue.forEach((p) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    if (event.value.findIndex((v) => v === p) === -1) {
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        catDesc = `category ${p}`;
                        catName = p;
                        const cat: EquipmentCategory = (this.categories || []).find(
                            (c) => c.categoryName === p,
                        );
                        if (cat && cat.categoryDscr) {
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            catDesc = `category ${p} (${cat.categoryDscr})`;
                        }
                    }
                });

                // Now determine if there are any fields filled in for this category
                const fieldsToBeLost: string[] = this.getFieldsToBeLost(catName);

                if (fieldsToBeLost.length > 0) {
                    let content = `Unable to remove ${catDesc}.\nThe following associated fields have data:\n`;
                    fieldsToBeLost.forEach((f) => {
                        content = `${content} - ${f}\n`;
                    });

                    const confirmInfo: BdsDialogInfoModel = {
                        content: content,
                        title: '',
                    };

                    const dialogRef = this.dialog.open(BdsDialogInfoComponent, {
                        width: '400px',
                        data: confirmInfo,
                    });

                    dialogRef.afterClosed().subscribe(() => {
                        // revert change
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        this.selectedCategories = event.previousValue;
                    });
                } else {
                    const confirmInfo: BdsDialogConfirmModel = {
                        content: `Removing ${catDesc} may result in the loss of data`,
                        actionText: 'Remove',
                        defaultToYes: false,
                    };

                    const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
                        width: '400px',
                        data: confirmInfo,
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                        if (result) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            this.equipCategoryChange.emit(event.value);
                        } else {
                            // revert change
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            this.selectedCategories = event.previousValue;
                        }
                    });
                }
            } else if (
                !this.initializingCategories &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                event.value.length > event.previousValue.length
            ) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                event.value = event.value.sort();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                this.equipCategoryChange.emit(event.value);
            } else if (
                !this.initializingCategories &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                (event.value.length = event.previousValue.length) &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                event.value.length === 1 &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                event.value[0] === event.previousValue[0]
            ) {
                // This appears to be an odd bug in multiselect that sets the previous value to
                // the current value on the first selection
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                event.value = event.value.sort();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                this.equipCategoryChange.emit(event.value);
            } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                event.value = event.value.sort();
            }

            this.initializingCategories = false;
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    onModelChanged(event: any): void {
        if (!this.mechForm.valid) {
            this.mechForm.markAllAsTouched();
        }

        this.modelChange.emit({ value: event, isValid: this.mechForm.valid });
    }

    setCategoryDataSource(categoryList: EquipmentCategory[]): void {
        this.categoryDataSource = new DataSource({
            store: new ArrayStore({
                data: categoryList,
                key: 'categoryName',
            }),
        });
    }
}
