import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BdsDxDataGridModule, BdsMapModule } from '@bds/components';
import { BourqueCoreModule } from '@bds/core';
import { BdsDestinationCriteriaModule } from '@bds/destination-criteria';
import { BdsHelpersModule, CarIdTransformService } from '@bds/helpers';
import { BdsDateTimePickerModule, BdsSmartComponentsModule } from '@bds/smart-components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DevExtremeModule } from 'devextreme-angular';
import { BdsOriginCriteriaModule } from '../bds-origin-criteria/bds-origin-criteria.module';
import { RtClmComponent } from '../rt-clm/rt-clm.component';
import { RtTripCriteriaComponent } from '../rt-trip-criteria/rt-trip-criteria.component';
import { RailtracClmDetailsComponent } from './railtrac-clm-details/railtrac-clm-details.component';
import { RailtracClmComponent } from './railtrac-clm.component';
import { BdsFastracModule } from '@bds/fastrac';
import { ClmFilterDialogComponent } from './clm-filter-dialog/clm-filter-dialog.component';
import { ClmFilterDetailsComponent } from './clm-filter-details/clm-filter-details.component';
import { RtClmGridComponent } from '../rt-clm-grid/rt-clm-grid.component';

@NgModule({
    declarations: [
        RailtracClmComponent,
        RailtracClmDetailsComponent,
        RtClmComponent,
        RtClmGridComponent,
        RtTripCriteriaComponent,
        ClmFilterDialogComponent,
        ClmFilterDetailsComponent,
    ],
    imports: [
        BdsFastracModule,
        BdsDestinationCriteriaModule,
        BdsDxDataGridModule,
        BdsHelpersModule,
        BdsMapModule,
        BdsOriginCriteriaModule,
        BdsSmartComponentsModule,
        BourqueCoreModule,
        CommonModule,
        DevExtremeModule,
        FontAwesomeModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatDatepickerModule,
        MatDialogModule,
        BrowserAnimationsModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        ReactiveFormsModule,
        BdsDateTimePickerModule,
    ],
    providers: [CarIdTransformService],
    exports: [
        RailtracClmComponent,
        RailtracClmDetailsComponent,
        RtClmComponent,
        RtClmGridComponent,
        RtTripCriteriaComponent,
    ],
})
export class RailtracClmModule {}
