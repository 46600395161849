<div class="row">
    <div class="col-12 col-md-1 order-md-2 mt-3 mb-3" *ngIf="showHeader">
        <div class="buttons">
            <button mat-stroked-button (click)="onAdd()" [disabled]="isSaving || !canAddNew()">
                <fa-icon class="add-icon" [icon]="iconPlus"></fa-icon>
            </button>
        </div>
    </div>

    <div class="col-12 col-md-11 order-md-1">
        <div class="row mt-3 mb-3" *ngIf="showHeader">
            <div class="col-12 col-sm-3 d-flex justify-content-center header-label">Category</div>
            <div class="col-12 col-sm-3 d-flex justify-content-center header-label">
                Category Value
            </div>
            <div class="col-12 col-sm-5 d-flex justify-content-center header-label">
                Category Effective Dates
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div *ngFor="let equipCat of currentEquipCatgEquip; index as i">
                    <bds-equip-report-category
                        [equipCategories]="currentEquipCatgEquip[i].categories"
                        [values]="currentEquipCatgEquip[i].values"
                        [equipment]="currentEquipCatgEquip[i].equip"
                        [editable]="currentEquipCatgEquip[i].editable"
                        [tripCloseDate]="tripCloseDate"
                        (equipCategoryChange)="onEquipCategoryChanged($event, i)"
                    >
                    </bds-equip-report-category>
                </div>
            </div>
        </div>
    </div>
</div>
