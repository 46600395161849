import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ListRefreshService {
    private _refresh = new BehaviorSubject<boolean>(false);
    public readonly refresh$ = this._refresh.asObservable();

    constructor() {}

    refreshList(): void {
        this._refresh.next(true);
    }
}

