import { Component, Input } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { BdsRouteSegmentsService } from '../../services/bds-route-segments.service';

@Component({
    selector: 'bds-route-segments',
    templateUrl: './bds-route-segments.component.html',
    styleUrls: ['./bds-route-segments.component.css'],
})
export class BdsRouteSegmentsComponent {
    dataSource: DataSource;
    displayColumns: string[];
    selectedRowKeys: Array<number> = [];
    @Input() readOnly = false;

    constructor(private routeSegmentService: BdsRouteSegmentsService) {
        this.dataSource = new DataSource({
            store: this.routeSegmentService.getODataStore(),
            paginate: true,
            pageSize: 10,
            expand: [],
        });
    }

    @Input() set routeCode(value: string) {
        // TODO Filter Active Status
        this.dataSource.filter(['routeCode', '=', value]);
    }
}
