<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content [formGroup]="participatingRailroadForm">
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label>Railroad</mat-label>
                <input matInput bdsShoutcase formControlName="railroad" />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <bds-route-select formControlName="routeCode" label="Route">
                <mat-error *ngIf="participatingRailroadForm.controls['routeCode'].invalid">{{
                    getErrorMessage(participatingRailroadForm.controls['routeCode'])
                }}</mat-error>
            </bds-route-select>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" [mat-dialog-close]="" color="accent">Cancel</button>
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        [disabled]="!participatingRailroadForm.valid"
        (click)="onSaveClick()"
    >
        Save
    </button>
</mat-dialog-actions>
