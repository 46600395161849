<bds-equipment-details-menu
    [equipment]="selectedEquipment$ | async"
    [isSaving]="isSaving"
    [menuLinks]="menuLinks"
    (saveChanges)="onSaveEquipmentMech()"
    (selectSection)="onSelectSection($event)"
>
</bds-equipment-details-menu>

<div class="container-fluid">
    <div class="mb-3" bdsScrollSpySection="{{ mechLink }}" [scrollMargin]="116" #{{mechLink}}>
        <bds-equipment-mech
            [selectedEquipment$]="selectedEquipment$"
            [categories$]="categories$"
            [componentCategories$]="componentCategories$"
            [dynamicDropdownValues$]="dynamicDropdownValues$"
            [mechFieldsByCategory$]="mechFieldsByCategory$"
            [errors]="errors"
            [unitsOfMeasure$]="unitsOfMeasure$"
            (equipmentMechChange)="onEquipmentMechChanged($event)"
            (equipCategoryChange)="onEquipCategoryChanged($event)"
            (equipmentDatesChange)="onEquipmentDatesChanged($event)"
            (equipmentDatesSave)="onEquipmentDatesSaved()"
            (equipmentValuesChange)="onEquipmentValuesChanged($event)"
        >
        </bds-equipment-mech>
    </div>

    <div
        *ngIf="(selectedEquipment$ | async)?.ormId"
        class="mb-3"
        bdsScrollSpySection="{{ componentsLink }}"
        [scrollMargin]="116"
        #{{componentsLink}}
    >
        <mat-expansion-panel #componentsPanel id="componentsPanel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <fa-icon [icon]="iconComponents" [fixedWidth]="true"></fa-icon>
                    {{ componentsLabel }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <bds-equipment-components
                    [selectedEquipment]="selectedEquipment$ | async"
                    [dynamicDropdownValues$]="dynamicDropdownValues$"
                >
                </bds-equipment-components>
            </ng-template>
        </mat-expansion-panel>
    </div>

    <div
        *ngIf="(selectedEquipment$ | async)?.ormId"
        class="mb-3"
        bdsScrollSpySection="{{ reportsLink }}"
        [scrollMargin]="116"
        #{{reportsLink}}
    >
        <mat-expansion-panel #reportsPanel id="reportsPanel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <fa-icon [icon]="iconReports" [fixedWidth]="true"></fa-icon>
                    {{ reportsLabel }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <bds-equipment-report-categories
                    [currentUser]="userName"
                    [equipId]="(selectedEquipment$ | async)?.ormId"
                    [equipInit]="(selectedEquipment$ | async)?.equipmentInit"
                    [equipNumber]="(selectedEquipment$ | async)?.equipmentNo"
                >
                </bds-equipment-report-categories>
            </ng-template>
        </mat-expansion-panel>
    </div>
</div>
