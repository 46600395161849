import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BdsDialogConfirmComponent, BdsDialogConfirmModel } from '@bds/components';
import { RtCarHotListService } from '@bds/data-access';
import { RtTrip } from '@bds/railtrac-models';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { BdsHotListDetailsDialogComponent, BdsHotListDialogData } from '@bds/hotlist';
import { TripCarHotListDto } from '@bds/railtrac-models';

@Component({
    selector: 'rt-multi-hot-list',
    templateUrl: './rt-multi-hot-list.component.html',
    styleUrls: ['./rt-multi-hot-list.component.css'],
})
export class RtMultiHotListComponent implements OnInit {
    hotLists$: Observable<TripCarHotListDto[]>;
    iconAdd = faPlus;

    constructor(private hotListService: RtCarHotListService, private dialogService: MatDialog) {}

    private _trips: RtTrip[];

    get trips(): RtTrip[] {
        return this._trips;
    }

    @Input() set trips(value: RtTrip[]) {
        this._trips = value;
        this.setHotLists();
    }

    add() {
        if (this.trips.find((x) => x.tripStatus === 'C')) {
            const confirmInfo: BdsDialogConfirmModel = {
                content: `One or more of the selected trips are closed. Are you sure you would like to continue?`,
                actionText: 'Yes',
                dismissiveText: 'No',
                defaultToYes: false,
            };
            const dialogRef = this.dialogService.open(BdsDialogConfirmComponent, {
                width: '430px',
                data: confirmInfo,
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.addHotList();
                }
            });
        } else {
            this.addHotList();
        }
    }

    addHotList() {
        const model = this.trips.map(
            (trip) =>
                <TripCarHotListDto>{
                    tripId: trip.ormId,
                    carInit: trip.carInit,
                    carNo: trip.carNo,
                    hotListType: 'GNRL',
                    hotListCategory: 'GNRL',
                },
        );
        const dialogRef = this.dialogService.open(BdsHotListDetailsDialogComponent, {
            data: <BdsHotListDialogData>{
                action: 'add',
                title: 'Add Hot Lists',
                hotLists: model,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                tap((response) => {
                    if (response) {
                        this.setHotLists();
                    }
                }),
            )
            .subscribe();
    }

    ngOnInit() {}

    setHotLists() {
        this.hotLists$ = this.hotListService.getHotListsByTripIds(this._trips.map((x) => x.ormId));
    }
}
