import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'bds-route-screen-detail',
    template: ` <bds-route [routeCode]="routeCode$ | async"></bds-route>`,
})
export class RouteDetailScreenComponent implements OnInit {
    routeCode$: Observable<string>;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.routeCode$ = this.route.params.pipe(
            map((params) => (params['routeCode'] as string) || ''),
        );
    }
}
