import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-trip-single-screen',
    templateUrl: './trip-single-screen.component.html',
    styleUrls: ['./trip-single-screen.component.scss'],
})
export class TripSingleScreenComponent implements OnInit, OnDestroy {
    ormId: number;
    private sub: Subscription;

    isDirty: boolean = false;
    onDirty() {
        this.isDirty = true;
    }

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe((params) => {
            this.ormId = +params['ormId'];
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
