import { PageService } from '@bds/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { RtTripRefFieldDefService } from '@bds/railtrac';
import { RtTripRefFieldDef } from '@bds/railtrac-models';

@Component({
    selector: 'app-trip-detail-screen',
    templateUrl: './trip-detail-screen.component.html',
    styleUrls: ['./trip-detail-screen.component.scss'],
})
export class TripDetailScreenComponent implements OnInit, OnDestroy {
    ormId: number;
    ormId$: Observable<number>;
    tripRefFieldDefs$: Observable<RtTripRefFieldDef[]>;

    private sub: Subscription;

    isDirty: boolean = false;

    onDirty() {
        this.isDirty = true;
    }

    constructor(
        private refFieldDefService: RtTripRefFieldDefService,
        private route: ActivatedRoute,
        public pageService: PageService,
    ) {
        pageService.setHeader('Trip Details');
    }

    ngOnInit() {
        this.ormId$ = this.route.paramMap.pipe(map((x) => +x['ormId']));
        this.tripRefFieldDefs$ = this.refFieldDefService.getReferenceFieldDefs();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
