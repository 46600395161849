import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Observable } from 'rxjs/internal/Observable';
import { BDSFormlyForms, BdsFormlyService } from '../../services/bds-formly.service';

@Component({
    selector: 'bds-route-details-add',
    templateUrl: './bds-formly-dialog.component.html',
    styleUrls: ['./bds-formly-dialog.component.css'],
})
export class BdsFormlyDialogComponent implements OnInit {
    fields: Observable<FormlyFieldConfig[]>;
    form: UntypedFormGroup = new UntypedFormGroup({});
    options: FormlyFormOptions = {};

    constructor(
        public dialogRef: MatDialogRef<BdsFormlyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FormlyDialogData<any>,
        private formService: BdsFormlyService,
    ) {}

    actionBtnClicked() {
        this.data.action(this.data.model).subscribe((response) => {
            this.dialogRef.close(response);
        });
    }

    ngOnInit(): void {
        if (this.data.formName) {
            this.fields = this.formService.getForm(this.data.formName);
        }
    }
}

export interface FormlyDialogData<T> {
    action: (T) => Observable<any>;
    actionBtnText: string;
    cancelBtnText: string;
    formName: BDSFormlyForms;
    model: T;
    title: string;
}
