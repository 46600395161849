import { Subscription, Observable, of } from 'rxjs';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
    RtCommentType,
    RtMiscComment,
    RtTripComment,
    RtTripCommentAdapter,
    RtTripCommentMetadata,
} from '@bds/railtrac-models';
import { nameof } from '@bds/core';
import { FormErrorHandlerService } from '@bds/helpers';
import { RtTripCommentService } from '@bds/data-access';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'rt-trip-comment',
    templateUrl: './rt-trip-comment.component.html',
    styleUrls: ['./rt-trip-comment.component.scss'],
})
export class RtTripCommentComponent implements OnInit, OnChanges, OnDestroy {
    form: UntypedFormGroup;
    @Input() comment: RtTripComment;
    @Output() commentChange: EventEmitter<{ value: RtTripComment; valid: boolean }> =
        new EventEmitter();
    private formSubscription: Subscription;

    commentTypes: Observable<RtCommentType[]>;
    defaultComments: Observable<RtMiscComment[]>;

    constructor(
        public adapter: RtTripCommentAdapter,
        public metadata: RtTripCommentMetadata,
        public service: RtTripCommentService,
        private formErrorService: FormErrorHandlerService,
    ) {
        this.form = this.metadata.getFormGroup();
    }

    ngOnInit() {
        this.formSubscription = this.form.valueChanges.subscribe((s) => {
            this.commentChange.emit({ value: this.form.getRawValue(), valid: this.form.valid });
        });
        this.commentTypes = this.service.getCommentTypes();
        this.form.patchValue(this.comment);
        this.getDefaultComments(this.comment?.commentType);
    }

    ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {}

    getDefaultComments(key: string): void {
        if (!!key) {
            this.defaultComments = this.service.getDefaultCommentsForType(key);
        } else {
            this.defaultComments = of([]);
        }
    }

    getError(formItem: UntypedFormControl | UntypedFormGroup): string {
        return this.formErrorService.getFormError(formItem);
    }

    setDefaultComment(commentCode: string) {
        var comment = '';
        this.defaultComments.subscribe({
            next: (comments: RtMiscComment[]) => {
                comment = comments.find((x) => x.code == commentCode).comment;
                this.form.controls[nameof<RtTripComment>('comments')].setValue(comment);
            },
        });
    }

    setFlag($event: MatCheckboxChange, toggleControl: AbstractControl): void {
        toggleControl.setValue($event ? 'Y' : 'N');
    }
}
