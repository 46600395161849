import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    RtCommentType,
    RtCommentTypeAdapter,
    RtMiscComment,
    RtMiscCommentAdapter,
    RtTripComment,
    RtTripCommentAdapter,
} from '@bds/railtrac-models';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export abstract class RtTripCommentServiceOptions {
    apiUrl = 'api/commenttype';
    odataUrl = `odata/commenttype`;
    odataCommentTypesUrl = `odata/commenttype`;
    odataDefaultCommentsUrl = `/defaultcomments`;
}

@Injectable({
    providedIn: 'root',
})
export class RtTripCommentService {
    tripCommentController: string = 'TripComment';

    constructor(
        private http: HttpClient,
        private options: RtTripCommentServiceOptions,
        private commentTypeAdapter: RtCommentTypeAdapter,
        private defaultCommentAdapter: RtMiscCommentAdapter,
        private tripCommentAdapter: RtTripCommentAdapter,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
    ) {}

    read(ormId: number): Observable<RtTripComment> {
        return this.readViaOdata(ormId);
    }

    readViaOdata(ormId: number): Observable<RtTripComment> {
        return this.http
            .get<RtTripComment>(`${this.odatabaseUrl}${this.tripCommentController}/(${ormId})`)
            .pipe(
                map((data: any) => data),
                catchError((err) => this.handleError(err)),
            );
    }

    getCommentTypes(): Observable<RtCommentType[]> {
        return this.http.get<RtCommentType[]>(`${this.options.odataCommentTypesUrl}`).pipe(
            map((data: any) => [...data.value.map((v) => this.commentTypeAdapter.adapt(v))]),
            catchError(this.handleError),
        );
    }

    getDefaultCommentsForType(key: string): Observable<RtMiscComment[]> {
        return this.http
            .get<RtMiscComment[]>(
                `${this.options.odataCommentTypesUrl}('${key}')${this.options.odataDefaultCommentsUrl}`,
            )
            .pipe(
                map((data: any) => [...data.value.map((v) => this.defaultCommentAdapter.adapt(v))]),
                catchError(this.handleError),
            );
    }

    create(comment: RtTripComment): Observable<any> {
        // Only check these because they are not set through a form
        if (!comment) {
            console.error('An error occurred:', 'No comment to create');
            return throwError('A browser error occurred - no comment to create');
        } else if (!comment.carInit || !comment.carNo || !comment.shipDateTime) {
            console.error('An error occurred:', 'Not all Trip information was supplied');
            return throwError(
                'A broswer error occurred - not all Trip information was supplied - missing car init, car no, or shipment date',
            );
        } else if (!comment.userId) {
            console.error('An error occurred:', 'No username was supplied');
            return throwError('A broswer error occurred - no username was supplied');
        }

        if (!comment.commentDate) {
            comment.commentDate = new Date();
        }

        return this.http
            .post(`${this.options.apiUrl}`, this.tripCommentAdapter.toServer(comment))
            .pipe(
                map((data) => {
                    console.warn(
                        'Trip Comment may not have been saved, even with a return of 200 from the API',
                    );
                    return data;
                }),
                catchError(this.handleError),
            );
    }

    update(key: number, comment: RtTripComment): Observable<any> {
        // Only check these because they are not set through a form
        if (!comment) {
            console.error('An error occurred:', 'No comment to create');
            return throwError('A browser error occurred - no comment to create');
        } else if (!comment.carInit || !comment.carNo || !comment.shipDateTime) {
            console.error('An error occurred:', 'Not all Trip information was supplied');
            return throwError(
                'A broswer error occurred - not all Trip information was supplied - missing car init, car no, or shipment date',
            );
        } else if (!comment.userId) {
            console.error('An error occurred:', 'No username was supplied');
            return throwError('A broswer error occurred - no username was supplied');
        }

        if (!comment.commentDate) {
            comment.commentDate = new Date();
        }

        return this.http
            .put(`${this.options.apiUrl}/${key}`, this.tripCommentAdapter.toServer(comment))
            .pipe(
                map((data) => {
                    console.warn(
                        'Trip Comment may not have been saved, even with a return of 200 from the API',
                    );
                    return data;
                }),
                catchError(this.handleError),
            );
    }

    AddComments(comments: RtTripComment[]) {
        return this.http
            .post(`${this.apibaseUrl}${this.tripCommentController}/AddComments`, comments)
            .pipe(
                map((data) => {
                    return data;
                }),
                catchError(this.handleError),
            );
    }

    updateComments(comments: RtTripComment[]) {
        return this.http
            .put(`${this.apibaseUrl}${this.tripCommentController}/UpdateComments`, comments)
            .pipe(
                map((data) => {
                    return data;
                }),
                catchError(this.handleError),
            );
    }

    delete(ormId: number): Observable<any> {
        return this.http.delete(`${this.options.apiUrl}/${ormId}`);
    }

    // patch(trip: RtTrip): Observable<RtTrip> {
    //   console.warn('RailtracTripService.patchTrip() is a dummy method');
    //   return of(trip).pipe(delay(Math.random() * (5000 - 3000) + 3000));
    // }

    // https://angular.io/guide/http#getting-error-details
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);

            // return an observable with a user-facing error message
            return throwError('A browser or network error occurred');
        } else if (error.status === 404) {
            // return an observable with a user-facing error message
            return throwError('Not Found');
        } else if (error.status === 422) {
            console.error('Found but cannot be used');
            return throwError(error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}`, error);

            // return an observable with a user-facing error message
            return throwError('The server returned an unknown error');
        }
    }
}
