import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';
import { EquipmentComponentModel } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class EquipmentComponentService {
    controllerName = 'EquipmentComponents';

    constructor(
        private http: HttpClient,
        @Inject('BASE_API_URL') private apibaseUrl: string,
        @Inject('BASE_ODATA_URL') private odatabaseUrl: string,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    create(equipComponent: EquipmentComponentModel): Observable<EquipmentComponentModel> {
        return this.http
            .post<EquipmentComponentModel>(
                `${this.apibaseUrl}${this.controllerName}`,
                equipComponent,
            )
            .pipe(
                map((data: EquipmentComponentModel) => {
                    return data ? data : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    getODataStore(): ODataStore {
        const keyField = nameof<EquipmentComponentModel>('ormId');

        return new ODataStore({
            version: 4,
            url: `${this.odatabaseUrl}${this.controllerName}`,
            key: keyField,
        });
    }

    read(key: number): Observable<EquipmentComponentModel> {
        return this.http
            .get<EquipmentComponentModel>(`${this.apibaseUrl}${this.controllerName}/${key}`)
            .pipe(
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    update(
        key: number,
        equipComponent: EquipmentComponentModel,
    ): Observable<EquipmentComponentModel> {
        return this.http
            .put<EquipmentComponentModel>(
                `${this.apibaseUrl}${this.controllerName}/${key}`,
                equipComponent,
            )
            .pipe(
                map((data: EquipmentComponentModel) => {
                    return data ? data : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}
