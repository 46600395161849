<ng-container *ngIf="segmentMiles$ | async; let segmentMiles">
    <div class="sticky-top mat-elevation-z6 equipment-menu">
        <mat-toolbar class="commodity-header-bar bds-toolbar-blue">
            <mat-toolbar-row>
                <span> Segment Miles </span>
                <span class="fill-remaining-space"></span>
                <span
                    >&nbsp;
                    <button
                        mat-stroked-button
                        (click)="save(segmentMilesForm.form)"
                        [disabled]="segmentMiles | any: 'N':'isActive'"
                    >
                        <fa-icon [icon]="iconSave"> </fa-icon>
                        Save
                    </button>
                </span>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
    <div style="padding-bottom: 10px"></div>
    <bds-segment-miles-form [segmentMiles]="segmentMiles" #segmentMilesForm>
    </bds-segment-miles-form>
</ng-container>
