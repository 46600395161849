import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { nameof } from '@bds/core';
import { RtTripComment, RtTripCommentMetadata } from '@bds/railtrac-models';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RtMultiCommentFormService implements OnDestroy {
    private readonly _default = <RtTripComment>{
        commentType: 'GE',
        commentCode: 'GE',
    };
    private unsub$ = new Subject<void>();
    private refreshComment = new Subject<boolean>();
    private commentsPresent = new Subject<boolean>();

    constructor(private fb: UntypedFormBuilder, private metadata: RtTripCommentMetadata) {}

    private _form = this.fb.group({
        commentBulkForm: this.getBulkForm(),
        commentItemForms: this.fb.array([]),
        commentEditItemForms: this.fb.array([]),
    });

    get form(): UntypedFormGroup {
        return this._form;
    }

    get commentBulkForm(): UntypedFormGroup {
        return this._form.get('commentBulkForm') as UntypedFormGroup;
    }

    get commentItemForms(): UntypedFormArray {
        return this._form.get('commentItemForms') as UntypedFormArray;
    }

    get commentEditItemForms(): UntypedFormArray {
        return this._form.get('commentEditItemForms') as UntypedFormArray;
    }

    addCommentForm(value: RtTripComment, unsub$: Subject<void>, isAdd = true): UntypedFormGroup {
        this.unsub$ = unsub$;
        const formGroup = this.metadata.getFormGroup();

        //Can disable controls here, if needed
        //formGroup.get(nameof<RtTripComment>('carInit')).disable();

        if (!value.commentDate) {
            value.commentDate = new Date();
        }

        if (!value.ormId) {
            value.ormId = 0;
        }

        if (value.carInit) {
            formGroup.get(nameof<RtTripComment>('carInit')).disable();
        }
        if (value.carNo) {
            formGroup.get(nameof<RtTripComment>('carNo')).disable();
        }

        if (isAdd) {
            this.commentItemForms.push(formGroup);
        } else {
            this.commentEditItemForms.push(formGroup);
        }
        return formGroup;
    }

    ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.complete();
    }

    private getBulkForm() {
        const form = this.metadata.getFormGroup();
        form.get(nameof<RtTripComment>('carNo')).disable();
        form.get(nameof<RtTripComment>('carInit')).disable();

        this.watchChanges(nameof<RtTripComment>('commentType'), form);
        this.watchChanges(nameof<RtTripComment>('commentCode'), form);
        this.watchChanges(nameof<RtTripComment>('comments'), form);
        this.watchChanges(nameof<RtTripComment>('transmitToCust'), form);
        // form.patchValue(this._default);
        return form;
    }

    private watchChanges(controlName: string, form: UntypedFormGroup) {
        form.get(controlName)
            .valueChanges.pipe(
                takeUntil(this.unsub$),
                tap((value) => {
                    this.commentItemForms.controls.forEach((control) => {
                        control.get(controlName).setValue(value);
                    });
                }),
            )
            .subscribe();
    }

    refreshComment$ = this.refreshComment.asObservable();

    emitRefreshCommentChange(change: boolean): void {
        this.refreshComment.next(change);
    }

    // commentsPresent$ = this.commentsPresent.asObservable();

    // emitCommentPresentChange(change: boolean): void {
    //     this.commentsPresent.next(change);
    // }

    resetForm(): void {
        this._form = this.fb.group({
            commentBulkForm: this.getBulkForm(),
            commentItemForms: this.fb.array([]),
            commentEditItemForms: this.fb.array([]),
        });
    }
}
