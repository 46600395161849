<div class="mat-elevation-z6 equipment-detail-menu">
    <mat-toolbar class="customer-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <span>
                {{ description }}
            </span>
            <span *ngIf="isSaving">
                <small>
                    (saving...)
                    <fa-icon [icon]="iconSpinner" [spin]="true"></fa-icon>
                </small>
            </span>
            <span class="fill-remaining-space"></span>
            <span>
                <button
                    mat-stroked-button
                    (click)="onSaveChanges()"
                    [disabled]="isSaving || !canSave"
                >
                    <fa-icon [icon]="iconSave" [fixedWidth]="true"></fa-icon>
                    <span class="text-uppercase">{{ saveText }}</span>
                </button>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
