import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule, Routes } from '@angular/router';
import { BdsDxDataGridModule } from '@bds/components';
import { BdsFastracModule } from '@bds/fastrac';
import { BdsSmartComponentsModule } from '@bds/smart-components';
import { DevExtremeModule, DxDataGridModule } from 'devextreme-angular';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { BdsPreferredRoutesGridComponent } from './preferred-routes-grid/preferred-routes-grid.component';
import { PreferredRoutesComponent } from './preferred-routes.component';
import { BdsGridDetailsModule } from '@bds/components';
import { BdsPreferredRouteComponent } from './bds-preferred-route/bds-preferred-route.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [BdsPreferredRoutesGridComponent],
    exports: [BdsPreferredRoutesGridComponent],
    imports: [
        CommonModule,
        DxDataGridModule,
        BdsDxDataGridModule,
        BdsFastracModule,
        RouterModule,
        DxDateBoxModule,
        BdsSmartComponentsModule,
        BdsGridDetailsModule,
    ],
})
export class BdsPreferredRoutesModule {}

const routes: Routes = [
    {
        path: '',
        component: PreferredRoutesComponent,
        children: [
            { path: ':ormId', component: BdsPreferredRouteComponent },
            { path: 'create', component: BdsPreferredRouteComponent },
        ],
    },
];

@NgModule({
    imports: [
        CommonModule,
        BdsPreferredRoutesModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        DevExtremeModule,
        MatButtonModule,
        BdsSmartComponentsModule,
        FontAwesomeModule,
        RouterModule.forChild(routes),
    ],
    declarations: [PreferredRoutesComponent, BdsPreferredRouteComponent],
})
export class BdsPreferredRoutesRoutedModule {}
