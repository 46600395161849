import { Component, OnInit } from '@angular/core';
import { bdsGridModeType } from '@bds/components';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-eta-performance-shipment',
    templateUrl: './eta-performance-shipment.component.html',
    styleUrl: './eta-performance-shipment.component.css',
})
export class EtaPerformanceShipmentComponent implements OnInit {
    public gridId = 133;
    public gridMode: bdsGridModeType = 'single';
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Specialty ETA Performance-Shipment Report';
    public gridSource: any;
    public selectedRowKeys: Array<number> = [];
    public displayReport: boolean = false;
    public controlNames: string[] = ['Start Date', 'End Date'];

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit() {
        this.pageService.setHeader(this.pageHeader);
    }

    onGetReportClick(event: any) {
        this.displayReport = true;
        this.gridSource = this.reportService.CreateAspNetDatastore(
            ReportConstants.ReportDataApiCall,
            this.gridId.toString(),
            event.startDate,
            event.endDate,
        );
    }

    onCancelClick() {
        this.displayReport = false;
    }
}
