<h1 *ngIf="!!data.title" mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col-sm-6">
            <h5 class="mini-headline">Preferred City/State</h5>
        </div>
        <div class="col-sm-6">
            <h5 class="mini-headline">Variant City/State</h5>
        </div>
    </div>
    <div class="row" *ngFor="let form of forms; let i = index" [formGroup]="form">
        <div class="col-sm-3">
            <mat-form-field class="w-100">
                <mat-label>Preferred City</mat-label>
                <input
                    matInput
                    bdsShoutcase
                    formControlName="locCity"
                    [id]="i === 0 ? 'firstField' : ''"
                />
                <mat-error *ngIf="form.controls['locCity'].invalid">{{
                    getErrorMessage(form.controls['locCity'])
                }}</mat-error>
                <mat-hint *ngIf="showSuccess" class="text-success">Saved!</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-100">
                <mat-label>Preferred State</mat-label>
                <mat-select formControlName="locState">
                    <mat-option *ngFor="let option of stateList" [value]="option.code">
                        {{ option.code }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls['locState'].invalid">{{
                    getErrorMessage(form.controls['locState'])
                }}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-100">
                <mat-label>Variant City</mat-label>
                <input matInput bdsShoutcase formControlName="aliasCity" />
                <mat-error *ngIf="form.controls['aliasCity'].invalid">{{
                    getErrorMessage(form.controls['aliasCity'])
                }}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-100">
                <mat-label>Variant State</mat-label>
                <mat-select formControlName="aliasState">
                    <mat-option *ngFor="let option of stateList" [value]="option.code">
                        {{ option.code }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls['aliasState'].invalid">{{
                    getErrorMessage(form.controls['aliasState'])
                }}</mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button class="text-uppercase" (click)="onNoClick()" color="accent">Cancel</button>
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        (click)="onSaveAndNewClick()"
        [disabled]="formArray.invalid"
        *ngIf="forms.length === 1"
    >
        Save and Add New
    </button>
    <button
        mat-button
        class="text-uppercase"
        color="accent"
        (click)="onSaveAndCloseClick()"
        [disabled]="formArray.invalid"
    >
        Save and Close
    </button>
</mat-dialog-actions>
