import { PageService } from '@bds/core';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-equipment-screen',
    templateUrl: './equipment-screen.component.html',
    styleUrls: ['./equipment-screen.component.scss'],
})
export class EquipmentScreenComponent implements OnInit {
    constructor(public pageService: PageService) {
        pageService.setHeader('Equipment Profile');
    }

    ngOnInit() {}
}
