import { Component, OnInit } from '@angular/core';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { ReportConstants } from '../../models/report-parameters.model';

@Component({
    selector: 'lib-rt-transit-time-destination-state',
    templateUrl: './rt-transit-time-destination-state.component.html',
    styleUrls: ['./rt-transit-time-destination-state.component.css'],
})
export class RtTransitTimeDestinationStateComponent implements OnInit {
    public gridId = 110;
    public gridSource: any;
    public displayReport: boolean = false;
    public controlNames: string[] = ['Dest. Arrival Begin Date', 'Dest. Arrival End Date'];

    constructor(public reportService: RailtracReportsService, public pageService: PageService) {}

    ngOnInit(): void {
        this.pageService.setHeader('Transit Time By Destination State Report');
    }

    onGetReportClick(event: any) {
        this.displayReport = true;
        this.gridSource = this.reportService.CreateAspNetDatastore(
            ReportConstants.ReportDataApiCall,
            this.gridId.toString(),
            event.startDate,
            event.endDate,
            'SP',
        );
    }

    onCancelClick() {
        this.displayReport = false;
    }
}
