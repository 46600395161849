import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtFleetName implements From<RtFleetName> {
    constructor(public fleetId?: string, public fleetName?: string, public groupId?: string) {}
    from(trip: RtFleetName): RtFleetName {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtFleetName>('fleetId'), server: 'fleetId' },
    { client: nameof<RtFleetName>('fleetName'), server: 'fleetName' },
    { client: nameof<RtFleetName>('groupId'), server: 'groupId' },
];

@Injectable({
    providedIn: 'root',
})
export class RtFleetNameAdapter extends TwoWayAdapter<RtFleetName> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtFleetNameMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtFleetName>('fleetId'),
                dataType: 'string',
                caption: 'fleetId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtFleetName>('fleetName'),
                dataType: 'string',
                caption: 'fleetName',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtFleetName>('groupId'),
                dataType: 'string',
                caption: 'groupId',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtFleetName>('fleetId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtFleetName>('fleetName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtFleetName>('groupId'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
