import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorizeService, IUser } from '@bds/auth';
import { faFastrac } from '@bds/fa-svg-icons';
import { Fastrac, FastracColumn, FilterCondition } from '@bds/models';
import {
    FastracBuilderService,
    FastracSharedUser,
    FastracSharedUserApiService,
    GenerateFilterConditionsService,
} from '@bds/services';
import { faUserFriends } from '@fortawesome/pro-light-svg-icons';
import {
    faPlus,
    faSave,
    faStar as faStarR,
    faTrashAlt,
    faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faEllipsisVAlt,
    faICursor,
    faRunning,
    faStar as faStarS,
} from '@fortawesome/pro-solid-svg-icons';
import { AsyncSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FastracManagementDialogConfirmComponent } from '../fastrac-management-dialog-confirm/fastrac-management-dialog-confirm.component';
import { FastracManagementDialogInputboxComponent } from '../fastrac-management-dialog-inputbox/fastrac-management-dialog-inputbox.component';
import { FastracManagementDialogShareComponent } from '../fastrac-management-dialog-share/fastrac-management-dialog-share.component';
import { nameof } from '@bds/core';
import { IOdataOptions } from '@bds/odata';

@Component({
    selector: 'app-fastrac-management-details',
    templateUrl: './fastrac-management-details.component.html',
    styleUrls: ['./fastrac-management-details.component.scss'],
})
export class FastracManagementDetailsComponent implements OnInit, OnChanges, OnDestroy {
    destroyer$ = new AsyncSubject<void>();

    @Input() fastracId: number = null;
    @Input() dateFormat: string;

    @Output() fastracChanged = new EventEmitter<Fastrac>();
    @Output() fastracDeleted = new EventEmitter<number>();
    @Output() runFastrac = new EventEmitter<number>();

    fastracCols: FastracColumn[];
    filterConditionalTypes: FilterCondition[];
    selectedFastrac: Fastrac = null;
    fastracIsDirty = false;

    iconFastrac = faFastrac;
    iconRunFastrac = faRunning;
    iconSave = faSave;
    iconSaveAs = faSave;
    iconRename = faICursor;
    iconDelete = faTrashAlt;
    iconShare = faUsers;
    iconVerticalEllipsis = faEllipsisVAlt;
    iconPlus = faPlus;
    iconFavoriteNo = faStarR;
    iconFavoriteYes = faStarS;
    iconShared = faUserFriends;

    user: IUser;

    constructor(
        public filterConditionsService: GenerateFilterConditionsService,
        public fastracService: FastracBuilderService,
        public fastracSharedUsersService: FastracSharedUserApiService,
        public authorizeService: AuthorizeService,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
    ) {
        console.group('fastrac-management-details ctor');
        this.filterConditionalTypes = this.filterConditionsService.transformEnum();
        console.groupEnd();
    }

    ngOnInit() {
        this.authorizeService
            .getUser()
            .pipe(takeUntil(this.destroyer$))
            .subscribe((user) => {
                this.user = user;
            });
    }
    ngOnChanges(changes: SimpleChanges): void {
        console.group('fastrac-management-details ngOnChanges');
        console.log(changes);
        if (changes.fastracId && changes.fastracId.currentValue) {
            this.fastracService
                .getFastracDesignById(changes.fastracId.currentValue)
                .subscribe((ft) => {
                    this.selectedFastrac = ft;
                });
        }
        console.groupEnd();
    }
    ngOnDestroy() {
        this.destroyer$.next();
        this.destroyer$.complete();
    }

    onSaveClick() {
        if (this.selectedFastrac.ownerId !== this.user.name) {
            this.onSaveAsClick();
        } else {
            console.log('saving', this.selectedFastrac);
            this.fastracService
                .updateFastracDesign(this.selectedFastrac.id, this.selectedFastrac)
                .subscribe(
                    (s) => {
                        this.fastracId = s.id;
                        this.selectedFastrac = s;
                        this.fastracChanged.emit(s);
                        this.snackbar.open('FASTRAC saved!', '', { duration: 2000 });
                    },
                    (err) => {
                        console.warn('ERROR SAVING FASTRAC', err);
                        this.dialog.open(FastracManagementDialogConfirmComponent, {
                            data: {
                                title: `Couldn't save FASTRAC`,
                                prompt: `There was an error saving the FASTRAC`,
                                asAlert: true,
                                yesLabel: 'Got it',
                            },
                        });
                    },
                );
        }
    }
    onSaveAsClick() {
        const dialogRef = this.dialog.open(FastracManagementDialogInputboxComponent, {
            data: {
                title: 'Save FASTRAC as...',
                prompt: `Save a copy of "${this.selectedFastrac.name}" as:`,
                label: 'Name',
                value: this.selectedFastrac.name,
                yesLabel: 'Save As',
                noLabel: 'Cancel',
                maxLength: 100,
            },
            minWidth: '20vw',
        });
        dialogRef.afterClosed().subscribe(
            (result) => {
                if (!!result) {
                    console.log('attempting to save fastrac as', result);
                    this.selectedFastrac.id = 0;
                    this.selectedFastrac.name = result;
                    this.selectedFastrac.ownerId = this.user.name;
                    console.log('saving as', this.selectedFastrac);
                    this.fastracService.createFastracDesign(this.selectedFastrac).subscribe((s) => {
                        this.fastracId = s.id;
                        this.selectedFastrac = s;
                        this.fastracChanged.emit(s);
                        this.snackbar.open('FASTRAC created!', '', { duration: 2000 });
                    });
                }
            },
            (err) => {
                console.warn('ERROR SAVING FASTRAC', err);
                this.dialog.open(FastracManagementDialogConfirmComponent, {
                    data: {
                        title: `Couldn't save FASTRAC`,
                        prompt: `There was an error saving the FASTRAC`,
                        asAlert: true,
                        yesLabel: 'Got it',
                    },
                });
            },
        );
    }
    onRenameClick() {
        if (this.selectedFastrac.ownerId !== this.user.name) {
            this.dialog.open(FastracManagementDialogConfirmComponent, {
                data: {
                    prompt: 'You cannot rename a report that does not belong to you.',
                    yesLabel: 'Got it',
                    asAlert: true,
                },
            });
        } else {
            const dialogRef = this.dialog.open(FastracManagementDialogInputboxComponent, {
                data: {
                    title: 'Rename FASTRAC',
                    prompt: `Rename "${this.selectedFastrac.name}" to:`,
                    label: 'Name',
                    value: this.selectedFastrac.name,
                    yesLabel: 'Rename',
                    noLabel: 'Cancel',
                    maxLength: 100,
                },
                minWidth: '20vw',
            });
            dialogRef.afterClosed().subscribe(
                (result) => {
                    console.log('-=-=-=-=-=-=-=-= renaming result =-=-=-=-=-=-=-=-');
                    console.log(result);
                    if (!!result) {
                        this.selectedFastrac.name = result;
                        this.fastracService
                            .updateFastracDesign(this.fastracId, this.selectedFastrac)
                            .subscribe((s) => {
                                console.log(
                                    '-=-=-=-=-=-=-=-= pushing to server result =-=-=-=-=-=-=-=-',
                                );
                                console.log(s);
                                // this.fastracId = s.id;
                                // this.selectedFastrac = s;
                                this.fastracChanged.emit(s);
                                this.snackbar.open('FASTRAC renamed!', '', { duration: 2000 });
                            });
                    }
                },
                (err) => {
                    console.warn('ERROR SAVING FASTRAC', err);
                    this.dialog.open(FastracManagementDialogConfirmComponent, {
                        data: {
                            title: `Couldn't save FASTRAC`,
                            prompt: `There was an error saving the FASTRAC`,
                            asAlert: true,
                            yesLabel: 'Got it',
                        },
                    });
                },
            );
        }
    }
    onDeleteClick() {
        if (this.selectedFastrac.ownerId !== this.user.name) {
            this.dialog.open(FastracManagementDialogConfirmComponent, {
                data: {
                    prompt: 'You cannot delete a report that does not belong to you.',
                    yesLabel: 'Got it',
                    asAlert: true,
                },
            });
        } else {
            const dialogRef = this.dialog.open(FastracManagementDialogConfirmComponent, {
                data: {
                    prompt: `Delete "${this.selectedFastrac.name}"?`,
                    yesLabel: 'Delete',
                    noLabel: 'Cancel',
                },
            });
            dialogRef.afterClosed().subscribe(
                (result) => {
                    if (!!result) {
                        this.fastracService.deleteFastracDesign(this.fastracId).subscribe(() => {
                            this.fastracDeleted.emit(this.fastracId);
                            this.fastracId = null;
                            this.selectedFastrac = null;
                            this.snackbar.open('FASTRAC deleted!', '', { duration: 2000 });
                        });
                    }
                },
                (err) => {
                    console.warn('ERROR DELETING FASTRAC', err);
                    this.dialog.open(FastracManagementDialogConfirmComponent, {
                        data: {
                            title: `Couldn't delete FASTRAC`,
                            prompt: `There was an error deleting the FASTRAC`,
                            asAlert: true,
                            yesLabel: 'Got it',
                        },
                    });
                },
            );
        }
    }

    toggleFavorite() {
        this.selectedFastrac.isFavorite = !this.selectedFastrac.isFavorite;
    }

    onShareClick() {
        if (this.selectedFastrac.ownerId !== this.user.name) {
            this.dialog.open(FastracManagementDialogConfirmComponent, {
                data: {
                    prompt: 'You cannot share a report that does not belong to you.',
                    yesLabel: 'Got it',
                    asAlert: true,
                },
            });
        } else {
            const dialogRef = this.dialog.open(FastracManagementDialogShareComponent, {
                data: {
                    selectedUsers: this.fastracSharedUsersService.get(<IOdataOptions>{
                        $filter: `${nameof<FastracSharedUser>('fastracId')} eq ${this.fastracId}`,
                    }),
                },
                minWidth: '20vw',
            });
            dialogRef.afterClosed().subscribe(
                (result) => {
                    console.log('fastrac-management-details onShareclick results', result);
                    if (!!result) {
                        console.log(result);
                        this.fastracSharedUsersService
                            .setSharedUsers(this.fastracId, result)
                            .subscribe((s) => {
                                this.snackbar.open('Shared users updated successfully!', '', {
                                    duration: 2000,
                                });
                            });
                    }
                },
                (err) => {
                    console.warn('ERROR SAVING FASTRAC', err);
                    this.dialog.open(FastracManagementDialogConfirmComponent, {
                        data: {
                            title: `Couldn't save FASTRAC`,
                            prompt: `There was an error updating the share information for the FASTRAC`,
                            asAlert: true,
                            yesLabel: 'Got it',
                        },
                    });
                },
            );
        }
    }
}
