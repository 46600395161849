<ng-container *ngIf="$selectedRoute | async; let selectedRoute">
    <div class="sticky-top mat-elevation-z6">
        <mat-toolbar class="bds-toolbar-blue">
            <mat-toolbar-row class="row">
                <div class="col-11">
                    <fa-icon [icon]="duoIconRoute" [fixedWidth]="true" size="lg"></fa-icon>
                    {{ selectedRoute?.routeCode }}
                </div>
                <div class="col-1 text-right">
                    <button mat-stroked-button (click)="onEditClick()">
                        <fa-icon [icon]="this.readOnly ? iconEdit : iconSave"></fa-icon>
                        {{ this.readOnly ? 'Edit Route' : 'Save Route' }}
                    </button>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="container-fluid jump-nav">
            <mat-chip-listbox>
                <mat-chip-option
                    (click)="segments.open()"
                    color="accent"
                    [selectable]="false"
                    [highlighted]="(scrollSpyService.currentSection | async) === 'segments'"
                    [bdsScrollSpyLink]="'segments'"
                >
                    <fa-icon [icon]="iconRouteSegments" class="bds-toolbar-button-icon"></fa-icon>
                    Route Segments
                </mat-chip-option>
                <mat-chip-option
                    (click)="preferred.open()"
                    [selectable]="false"
                    [highlighted]="(scrollSpyService.currentSection | async) === 'preferred'"
                    [bdsScrollSpyLink]="'preferred'"
                    >Preferred Route</mat-chip-option
                >
                <mat-chip-option
                    (click)="participating.open()"
                    [selectable]="false"
                    [highlighted]="(scrollSpyService.currentSection | async) === 'participating'"
                    [bdsScrollSpyLink]="'participating'"
                    >Participating Railroad</mat-chip-option
                >
                <mat-chip-option
                    (click)="details.open()"
                    [selectable]="false"
                    [highlighted]="(scrollSpyService.currentSection | async) === 'details'"
                    [bdsScrollSpyLink]="'details'"
                >
                    <fa-icon [icon]="iconRouteDetail" class="bds-toolbar-button-icon"></fa-icon>
                    Route Detail
                </mat-chip-option>
            </mat-chip-listbox>
        </div>
    </div>
    <div class="container-fluid">
        <bds-route-code
            [rtRouteCode]="selectedRoute"
            [readOnly]="readOnly"
            #form
            formType="update"
        ></bds-route-code>
        <mat-accordion>
            <mat-expansion-panel #segments id="segments">
                <mat-expansion-panel-header>
                    <mat-panel-title bdsScrollSpySection="segments">
                        Route Segments</mat-panel-title
                    >
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <bds-route-segments
                        [routeCode]="selectedRoute.routeCode"
                        [readOnly]="readOnly"
                    ></bds-route-segments>
                </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel #preferred id="preferred">
                <mat-expansion-panel-header>
                    <mat-panel-title bdsScrollSpySection="preferred">
                        Preferred Route</mat-panel-title
                    >
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <bds-rt-preferred-route
                        [routeCode]="selectedRoute.routeCode"
                        [originSplc]="selectedRoute.originSplc"
                        [readOnly]="readOnly"
                    ></bds-rt-preferred-route>
                </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel #participating id="participating">
                <mat-expansion-panel-header>
                    <mat-panel-title bdsScrollSpySection="participating">
                        Participating Railroad</mat-panel-title
                    >
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <rt-route-participating-railroad
                        [routeCode]="selectedRoute.routeCode"
                        [readOnly]="readOnly"
                    ></rt-route-participating-railroad>
                </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel #details id="details">
                <mat-expansion-panel-header>
                    <mat-panel-title bdsScrollSpySection="details"> Route Details</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <bds-rt-route-details
                        [routeCode]="selectedRoute.routeCode"
                        [readOnly]="readOnly"
                    ></bds-rt-route-details>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-container>
