<h1 *ngIf="!!data.title" mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
    <p>{{ data.prompt }}</p>
    <div *ngIf="data.allowIgnore && !!data.promptId">
        <mat-checkbox [(ngModel)]="forgetMe">Don't tell me this again.</mat-checkbox>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        *ngIf="!data.asAlert"
        mat-button
        class="text-uppercase"
        (click)="onNoClick()"
        color="accent"
        cdkFocusInitial
    >
        {{ !!data.noLabel ? data.noLabel : 'No' }}
    </button>
    <button
        *ngIf="!data.asAlert"
        mat-button
        class="text-uppercase"
        (click)="onYesClick()"
        [mat-dialog-close]="true"
        color="accent"
    >
        {{ !!data.yesLabel ? data.yesLabel : 'Yes' }}
    </button>
    <button
        *ngIf="!!data.asAlert"
        mat-button
        class="text-uppercase"
        (click)="onOkClick()"
        [mat-dialog-close]="true"
        color="accent"
        cdkFocusInitial
    >
        {{ !!data.yesLabel ? data.yesLabel : 'Yes' }}
    </button>
</mat-dialog-actions>
