import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormErrorHandlerService, dxMatStyle } from '@bds/helpers';
import { ClmFilterFormService } from '../clm-filter-dialog/clm-filter-form.service';

@Component({
    selector: 'clm-filter-details',
    templateUrl: './clm-filter-details.component.html',
    styleUrls: ['./clm-filter-details.component.css'],
})
export class ClmFilterDetailsComponent {
    matFloatLabel = 'always';

    get dxMatStyle(): string {
        return dxMatStyle(this.matStyle);
    }

    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() dense = true;
    @Input() filterForm: UntypedFormGroup;

    constructor(
        public errorService: FormErrorHandlerService,
        public formService: ClmFilterFormService,
    ) {}

    getErrorMessage(formItem: UntypedFormControl): string {
        return this.errorService.getFormError(formItem);
    }
}

