import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    SkipSelf,
} from '@angular/core';
import { RtCommodityShippedUK } from '@bds/railtrac-models';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RtCommodityShippedForm } from '../rt-commodity-shipped-form.service';

@Component({
    selector: 'rt-multi-trip-commodity-shipped-volumes',
    templateUrl: './rt-multi-trip-commodity-shipped-volumes.component.html',
    styleUrls: ['./rt-multi-trip-commodity-shipped-volumes.component.css'],
    providers: [RtCommodityShippedForm],
})
export class RtMultiTripCommodityShippedVolumesComponent implements OnInit, OnDestroy {
    @Input() commodityShipped: RtCommodityShippedUK;
    @Input() dirty: boolean;
    @Output() dirtyChange = new EventEmitter<boolean>();
    highlightDirty = true;
    dense = true;
    form: UntypedFormGroup;

    get carId(): string {
        return `${this.commodityShipped.carInit}${this.commodityShipped.carNo}`;
    }

    private rtCommodityShippedForm: RtCommodityShippedForm;
    private unsub$ = new Subject<void>();

    constructor(
        @SkipSelf() @Optional() parentRtCommodityShippedForm: RtCommodityShippedForm,
        @Self() rtCommodityShippedForm: RtCommodityShippedForm,
    ) {
        this.rtCommodityShippedForm = parentRtCommodityShippedForm || rtCommodityShippedForm;
    }

    ngOnInit() {
        this.form = this.rtCommodityShippedForm.addCommodityShippedItemForm();
        this.rtCommodityShippedForm.setValues(this.commodityShipped, this.form);
        this.form.valueChanges.pipe(takeUntil(this.unsub$)).subscribe((s) => {
            // TODO Figure out a better way to avoid code to Emit Dirty
            if (this.form.dirty) {
                this.dirtyChange.emit(this.form.dirty);
            }
        });
    }

    ngOnDestroy(): void {
        this.rtCommodityShippedForm.removeCommodityShippedItemForm(this.commodityShipped.ormId);
        this.unsub$.next();
        this.unsub$.complete();
    }
}
