<form [formGroup]="diversionForm" (ngSubmit)="onSubmit()">
    <mat-card>
        <div formGroupName="header" class="header-fields">
            <div class="row">
                <div class="col-12 col-sm-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="true"
                    >
                        <mat-label>Diversion Type</mat-label>
                        <mat-select
                            formControlName="divertType"
                            (selectionChange)="onDivertTypeChange($event)"
                        >
                            <mat-option
                                *ngFor="let type of filteredDiversionTypes"
                                [value]="type.key"
                            >
                                {{ type.value }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="getHeader().controls.divertType.errors">
                            {{ getError(getHeader().controls.divertType) }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-4">
                    <dx-date-box
                        name="divertDateTime"
                        class="w-100 bds-dx-custom-material bds-mat-dense-form-field dx-mat-form-field-floatLabel-always"
                        formControlName="divertDateTime"
                        [stylingMode]="'filled'"
                        type="datetime"
                        displayFormat="MM/dd/yyyy HH:mm"
                        dateSerializationFormat="yyyy-MM-dd HH:mm:ss"
                        label="Divert Date Time"
                        (onPaste)="onDivertDatePasted($event)"
                        (onOpened)="onCalendarOpened($event)"
                    >
                    </dx-date-box>
                    <mat-error *ngIf="getHeader().controls.divertDateTime.errors">
                        {{ getError(getHeader().controls.divertDateTime) }}
                    </mat-error>
                    <mat-hint>Required</mat-hint>
                </div>
                <div class="col-12 col-sm-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="true"
                    >
                        <mat-label>Who Pays</mat-label>
                        <mat-select formControlName="whoPays">
                            <mat-option *ngFor="let who of whoPays" [value]="who.whoPaysCode">{{
                                who.whoPaysDescription
                            }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="getHeader().controls.whoPays.errors">
                            {{ getError(getHeader().controls.whoPays) }}
                        </mat-error>
                        <mat-hint>Required</mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="true"
                    >
                        <mat-label>Load/Empty</mat-label>
                        <input matInput bdsShoutcase formControlName="le" readonly />
                        <mat-error *ngIf="getHeader().controls.le.errors">
                            {{ getError(getHeader().controls.le) }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-4">
                    <bds-shipment-type-select
                        formControlName="shipmentType"
                    ></bds-shipment-type-select>
                </div>
            </div>

            <div class="row" *ngIf="divertType !== 'O'">
                <div class="col-12 col-sm-4">
                    <bds-customer-advanced-select
                        formControlName="newCustNo"
                        [filter]="custFilter"
                        [isRequired]="true"
                        [defaultCustomerType]="defaultCustomerType"
                        (selectionChange)="onCustomerChanged($event)"
                    >
                    </bds-customer-advanced-select>
                </div>
                <div class="col-12 col-sm-4">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="true"
                    >
                        <mat-label>Consignee City</mat-label>
                        <input matInput bdsShoutcase formControlName="newCustCity" readonly />
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-2">
                    <mat-form-field
                        floatLabel="always"
                        class="w-100"
                        appearance="fill"
                        [class.bds-mat-dense-form-field]="true"
                    >
                        <mat-label>State</mat-label>
                        <input matInput bdsShoutcase formControlName="newCustState" readonly />
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="divertType === 'O'">
                <div class="col-12 col-sm-4">
                    <dx-lookup
                        floatLabel="always"
                        [stylingMode]="'filled'"
                        [class.bds-mat-dense-form-field]="true"
                        class="bds-dx-custom-material flex-fill"
                        name="newReturnSplc"
                        formControlName="newReturnSplc"
                        [dataSource]="splcSource"
                        [valueExpr]="'splc'"
                        [displayExpr]="splcDisplayExpr"
                        [searchEnabled]="true"
                        searchTimeout="200"
                        itemTemplate="item"
                        fieldTemplate="field"
                        (onSelectionChanged)="onSplcChanged($event, 'header')"
                    >
                        <div *dxTemplate="let data of 'field'">
                            <div class="dx-placeholder">New Return Location</div>
                            <div class="custom-icon" *ngIf="data">
                                {{ data.erpcCity }}, {{ data.erpcState }} ({{ data.splc }})
                            </div>
                        </div>
                        <div *dxTemplate="let data of 'item'">
                            <div class="custom-icon">
                                {{ data.erpcCity }}, {{ data.erpcState }} ({{ data.splc }})
                            </div>
                        </div>
                    </dx-lookup>
                    <mat-error *ngIf="getHeader().controls.newReturnSplc.errors">
                        {{ getError(getHeader().controls.newReturnSplc) }}
                    </mat-error>
                    <mat-hint>Required</mat-hint>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card>
        <div class="trip-headers row">
            <div class="col-12 col-sm-4">
                <div class="row">
                    <div class="col-12 col-sm-1">Road</div>
                    <div class="col-12 col-sm-3">Car ID</div>
                    <div class="col-12 col-sm-3">CLM Date</div>
                    <div class="col-12 col-sm-1">Sight<br />Code</div>
                    <div class="col-12 col-sm-4">Current<br />Location</div>
                </div>
            </div>
            <div class="col-12 col-sm-8">
                <div class="row">
                    <div class="col-12 col-sm-2" *ngIf="divertType === 'L'">Order No.</div>
                    <div class="col-12 col-sm-2" *ngIf="divertType === 'L'">BOL No.</div>
                    <div class="col-12 col-sm-2" *ngIf="divertType === 'L'">Route Code</div>
                    <div
                        class="col-12"
                        [ngClass]="{
                            'col-sm-2': divertType === 'L',
                            'col-sm-8': divertType === 'O' || !divertType,
                            'col-sm-5': divertType === 'S' || divertType === 'Q'
                        }"
                    >
                        Route Description
                    </div>
                    <div class="col-12 col-sm-3" *ngIf="divertType === 'S' || divertType === 'Q'">
                        Return SPLC
                    </div>
                    <div class="col-12 col-sm-2">Fleet</div>
                    <div class="col-12 col-sm-2">Bus. Grp.</div>
                </div>
            </div>
        </div>

        <div formArrayName="roads">
            <div *ngFor="let item of getRoads().controls; let i = index" class="road-group">
                <div [formGroupName]="i">
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <div class="row">
                                <div class="col-12 col-sm-1">{{ item.value.road }}</div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-8">
                            <div class="row">
                                <div class="col-12 col-sm-4" *ngIf="divertType === 'L'"></div>
                                <div class="col-12 col-sm-2" *ngIf="divertType === 'L'">
                                    <bds-route-advanced-select
                                        formControlName="routeCode"
                                        class="bds-mat-dense-form-field small-field"
                                        [displayValue]="routeDisplay"
                                        (selectionChange)="onRouteChanged($event, 'road', item)"
                                    >
                                    </bds-route-advanced-select>
                                </div>
                                <div
                                    class="col-12"
                                    [ngClass]="{
                                        'col-sm-2': divertType === 'L',
                                        'col-sm-8': divertType === 'O' || !divertType,
                                        'col-sm-5': divertType === 'S' || divertType === 'Q'
                                    }"
                                >
                                    <mat-form-field
                                        floatLabel="always"
                                        class="w-100 small-field"
                                        appearance="fill"
                                        [class.bds-mat-dense-form-field]="true"
                                    >
                                        <mat-label>Route Description</mat-label>
                                        <input
                                            matInput
                                            bdsShoutcase
                                            formControlName="routeDescription"
                                            [readonly]="
                                                divertType === 'L' ||
                                                divertType === 'S' ||
                                                divertType === 'Q'
                                            "
                                            (change)="
                                                onRoadChanged($event, item, 'routeDescription')
                                            "
                                        />
                                        <mat-error
                                            *ngIf="
                                                getRoadByIndex(i).controls.routeDescription.errors
                                            "
                                        >
                                            {{
                                                getError(
                                                    getRoadByIndex(i).controls.routeDescription
                                                )
                                            }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div
                                    class="col-12 col-sm-3"
                                    *ngIf="divertType === 'S' || divertType === 'Q'"
                                >
                                    <dx-lookup
                                        floatLabel="always"
                                        [stylingMode]="'filled'"
                                        [class.bds-mat-dense-form-field]="true"
                                        class="bds-dx-custom-material flex-fill small-field"
                                        name="roadReturnSplc"
                                        formControlName="returnSplc"
                                        [dataSource]="splcSource"
                                        [valueExpr]="'splc'"
                                        [displayExpr]="splcDisplayExpr"
                                        [searchEnabled]="true"
                                        searchTimeout="200"
                                        [dropDownOptions]="popupDim"
                                        itemTemplate="item"
                                        fieldTemplate="field"
                                        (onSelectionChanged)="onSplcChanged($event, 'road', item)"
                                    >
                                        <div *dxTemplate="let data of 'field'">
                                            <div class="dx-placeholder">Return SPLC</div>
                                            <div class="custom-icon" *ngIf="data">
                                                {{ data.erpcCity }}, {{ data.erpcState }} ({{
                                                    data.splc
                                                }})
                                            </div>
                                        </div>
                                        <div *dxTemplate="let data of 'item'">
                                            <div class="custom-icon">
                                                {{ data.erpcCity }}, {{ data.erpcState }} ({{
                                                    data.splc
                                                }})
                                            </div>
                                        </div>
                                    </dx-lookup>
                                    <mat-error *ngIf="getRoadByIndex(i).controls.returnSplc.errors">
                                        {{ getError(getRoadByIndex(i).controls.returnSplc) }}
                                    </mat-error>
                                </div>
                                <div class="col-12 col-sm-2">
                                    <dx-lookup
                                        floatLabel="always"
                                        [stylingMode]="'filled'"
                                        [class.bds-mat-dense-form-field]="true"
                                        class="bds-dx-custom-material flex-fill small-field"
                                        formControlName="fleetId"
                                        label="Fleet"
                                        [dataSource]="fleetNames"
                                        [valueExpr]="'fleetId'"
                                        [displayExpr]="'fleetName'"
                                        [searchEnabled]="true"
                                        searchTimeout="200"
                                        itemTemplate="item"
                                        fieldTemplate="field"
                                        [dropDownOptions]="popupDim"
                                        (onSelectionChanged)="
                                            onRoadDropdownChanged($event, item, 'fleetId')
                                        "
                                    >
                                        <div *dxTemplate="let data of 'field'">
                                            <div class="custom-icon" *ngIf="data">
                                                {{ data.fleetId }}
                                            </div>
                                        </div>
                                        <div *dxTemplate="let data of 'item'">
                                            <div class="custom-icon">
                                                {{ data.fleetName }} ({{ data.fleetId }})
                                            </div>
                                        </div>
                                    </dx-lookup>
                                    <mat-error *ngIf="getRoadByIndex(i).controls.fleetId.errors">
                                        {{ getError(getRoadByIndex(i).controls.fleetId) }}
                                    </mat-error>
                                </div>
                                <div class="col-12 col-sm-2">
                                    <dx-lookup
                                        floatLabel="always"
                                        [stylingMode]="'filled'"
                                        [class.bds-mat-dense-form-field]="true"
                                        class="bds-dx-custom-material flex-fill small-field"
                                        formControlName="businessGroup"
                                        [dataSource]="businessGroups"
                                        [valueExpr]="'businessGroup'"
                                        [displayExpr]="'businessGroupName'"
                                        [searchEnabled]="true"
                                        searchTimeout="200"
                                        itemTemplate="item"
                                        fieldTemplate="field"
                                        [dropDownOptions]="popupDim"
                                        (onSelectionChanged)="
                                            onRoadDropdownChanged($event, item, 'businessGroup')
                                        "
                                        label="Bus. Grp."
                                    >
                                        <div *dxTemplate="let data of 'field'">
                                            <div class="custom-icon" *ngIf="data">
                                                {{ data.businessGroup }}
                                            </div>
                                        </div>
                                        <div *dxTemplate="let data of 'item'">
                                            <div class="custom-icon">
                                                {{ data.businessGroupName }} ({{
                                                    data.businessGroup
                                                }})
                                            </div>
                                        </div>
                                    </dx-lookup>
                                    <mat-error
                                        *ngIf="getRoadByIndex(i).controls.businessGroup.errors"
                                    >
                                        {{ getError(getRoadByIndex(i).controls.businessGroup) }}
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div formArrayName="diversions">
                        <div
                            [formGroupName]="j"
                            class="row"
                            *ngFor="let diversion of getDiversions(i).controls; let j = index"
                        >
                            <div class="col-12 col-sm-4">
                                <div class="row">
                                    <div class="col-12 col-sm-1">
                                        <button
                                            *ngIf="diversion.value.divert"
                                            mat-icon-button
                                            aria-label="Remove Diversion"
                                            (click)="onRemoveClick(diversion, i)"
                                        >
                                            <fa-icon
                                                class="remove-icon"
                                                [icon]="iconRemove"
                                                [fixedWidth]="true"
                                            ></fa-icon>
                                        </button>
                                        <button
                                            *ngIf="!diversion.value.divert"
                                            mat-icon-button
                                            aria-label="Undo Remove Diversion"
                                            (click)="onRemoveClick(diversion, i)"
                                        >
                                            <fa-icon
                                                class="undo-icon"
                                                [icon]="iconUndo"
                                                [fixedWidth]="true"
                                            ></fa-icon>
                                        </button>
                                    </div>
                                    <div
                                        class="col-12 col-sm-3"
                                        [class.disabled-label]="!diversion.value.divert"
                                    >
                                        {{ diversion.value.carInit }} {{ diversion.value.carNo }}
                                    </div>
                                    <div
                                        class="col-12 col-sm-3"
                                        [class.disabled-label]="!diversion.value.divert"
                                    >
                                        {{
                                            diversion.value.clmDateTime
                                                | date : 'MM/dd/yyyy HH:mm' : 'UTC'
                                        }}
                                    </div>
                                    <div
                                        class="col-12 col-sm-1"
                                        [class.disabled-label]="!diversion.value.divert"
                                    >
                                        {{ diversion.value.clmSightCode }}
                                    </div>
                                    <div
                                        class="col-12 col-sm-4"
                                        [class.disabled-label]="!diversion.value.divert"
                                    >
                                        {{ diversion.value.clmLocCity }}
                                        {{ diversion.value.clmLocState }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-8">
                                <div class="row">
                                    <div class="col-12 col-sm-2" *ngIf="divertType === 'L'">
                                        <mat-form-field
                                            floatLabel="always"
                                            class="w-100 small-field"
                                            appearance="fill"
                                            [class.bds-mat-dense-form-field]="true"
                                        >
                                            <mat-label>Order No.</mat-label>
                                            <input
                                                matInput
                                                bdsShoutcase
                                                formControlName="orderNo"
                                            />
                                            <mat-error
                                                *ngIf="
                                                    getDiversionByIndex(i, j).controls.orderNo
                                                        .errors
                                                "
                                            >
                                                {{
                                                    getError(
                                                        getDiversionByIndex(i, j).controls.orderNo
                                                    )
                                                }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-2" *ngIf="divertType === 'L'">
                                        <mat-form-field
                                            floatLabel="always"
                                            class="w-100 small-field"
                                            appearance="fill"
                                            [class.bds-mat-dense-form-field]="true"
                                        >
                                            <mat-label>BOL No.</mat-label>
                                            <input matInput bdsShoutcase formControlName="bolNo" />
                                            <mat-error
                                                *ngIf="
                                                    getDiversionByIndex(i, j).controls.bolNo.errors
                                                "
                                            >
                                                {{
                                                    getError(
                                                        getDiversionByIndex(i, j).controls.bolNo
                                                    )
                                                }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-2" *ngIf="divertType === 'L'">
                                        <bds-route-advanced-select
                                            formControlName="routeCode"
                                            class="bds-mat-dense-form-field small-field"
                                            [displayValue]="routeDisplay"
                                            [isRequired]="true"
                                            (selectionChange)="
                                                onRouteChanged($event, 'diversion', diversion)
                                            "
                                        >
                                        </bds-route-advanced-select>
                                    </div>
                                    <div
                                        class="col-12"
                                        [ngClass]="{
                                            'col-sm-2': divertType === 'L',
                                            'col-sm-8': divertType === 'O' || !divertType,
                                            'col-sm-5': divertType === 'S' || divertType === 'Q'
                                        }"
                                    >
                                        <mat-form-field
                                            floatLabel="always"
                                            class="w-100 small-field"
                                            appearance="fill"
                                            [class.bds-mat-dense-form-field]="true"
                                        >
                                            <mat-label>Route Description</mat-label>
                                            <input
                                                matInput
                                                bdsShoutcase
                                                formControlName="routeDescription"
                                                [readonly]="
                                                    divertType === 'L' ||
                                                    divertType === 'S' ||
                                                    divertType === 'Q'
                                                "
                                            />
                                            <mat-error
                                                *ngIf="
                                                    getDiversionByIndex(i, j).controls
                                                        .routeDescription.errors
                                                "
                                            >
                                                {{
                                                    getError(
                                                        getDiversionByIndex(i, j).controls
                                                            .routeDescription
                                                    )
                                                }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        class="col-12 col-sm-3"
                                        *ngIf="divertType === 'S' || divertType === 'Q'"
                                    >
                                        <dx-lookup
                                            floatLabel="always"
                                            [stylingMode]="'filled'"
                                            [class.bds-mat-dense-form-field]="true"
                                            class="bds-dx-custom-material flex-fill small-field"
                                            formControlName="returnSplc"
                                            [dataSource]="splcSource"
                                            [valueExpr]="'splc'"
                                            [displayExpr]="splcDisplayExpr"
                                            [searchEnabled]="true"
                                            searchTimeout="200"
                                            [dropDownOptions]="popupDim"
                                            itemTemplate="item"
                                            fieldTemplate="field"
                                        >
                                            <div *dxTemplate="let data of 'field'">
                                                <div class="dx-placeholder">Return SPLC</div>
                                                <div class="custom-icon" *ngIf="data">
                                                    {{ data.erpcCity }}, {{ data.erpcState }} ({{
                                                        data.splc
                                                    }})
                                                </div>
                                            </div>
                                            <div *dxTemplate="let data of 'item'">
                                                <div class="custom-icon">
                                                    {{ data.erpcCity }}, {{ data.erpcState }} ({{
                                                        data.splc
                                                    }})
                                                </div>
                                            </div>
                                        </dx-lookup>
                                        <mat-error
                                            *ngIf="
                                                getDiversionByIndex(i, j).controls.returnSplc.errors
                                            "
                                        >
                                            {{
                                                getError(
                                                    getDiversionByIndex(i, j).controls.returnSplc
                                                )
                                            }}
                                        </mat-error>
                                        <mat-hint>Required</mat-hint>
                                    </div>
                                    <div class="col-12 col-sm-2">
                                        <dx-lookup
                                            floatLabel="always"
                                            [stylingMode]="'filled'"
                                            [class.bds-mat-dense-form-field]="true"
                                            class="bds-dx-custom-material flex-fill small-field"
                                            formControlName="fleetId"
                                            [dataSource]="fleetNames"
                                            [valueExpr]="'fleetId'"
                                            [displayExpr]="'fleetId'"
                                            [searchEnabled]="true"
                                            searchTimeout="200"
                                            [dropDownOptions]="popupDim"
                                            itemTemplate="item"
                                            fieldTemplate="field"
                                            label="Fleet"
                                        >
                                            <div *dxTemplate="let data of 'field'">
                                                <div class="custom-icon" *ngIf="data">
                                                    {{ data.fleetId }}
                                                </div>
                                            </div>
                                            <div *dxTemplate="let data of 'item'">
                                                <div class="custom-icon">
                                                    {{ data.fleetName }} ({{ data.fleetId }})
                                                </div>
                                            </div>
                                        </dx-lookup>
                                        <mat-error
                                            *ngIf="
                                                getDiversionByIndex(i, j).controls.fleetId.errors
                                            "
                                        >
                                            {{
                                                getError(getDiversionByIndex(i, j).controls.fleetId)
                                            }}
                                        </mat-error>
                                    </div>
                                    <div class="col-12 col-sm-2">
                                        <dx-lookup
                                            floatLabel="always"
                                            [stylingMode]="'filled'"
                                            [class.bds-mat-dense-form-field]="true"
                                            class="bds-dx-custom-material flex-fill small-field"
                                            formControlName="businessGroup"
                                            [dataSource]="businessGroups"
                                            [valueExpr]="'businessGroup'"
                                            [displayExpr]="'businessGroup'"
                                            [searchEnabled]="true"
                                            searchTimeout="200"
                                            [dropDownOptions]="popupDim"
                                            itemTemplate="item"
                                            fieldTemplate="field"
                                            label="Bus. Grp."
                                        >
                                            <div *dxTemplate="let data of 'field'">
                                                <div class="custom-icon" *ngIf="data">
                                                    {{ data.businessGroup }}
                                                </div>
                                            </div>
                                            <div *dxTemplate="let data of 'item'">
                                                <div class="custom-icon">
                                                    {{ data.businessGroupName }} ({{
                                                        data.businessGroup
                                                    }})
                                                </div>
                                            </div>
                                        </dx-lookup>
                                        <mat-error
                                            *ngIf="
                                                getDiversionByIndex(i, j).controls.businessGroup
                                                    .errors
                                            "
                                        >
                                            {{
                                                getError(
                                                    getDiversionByIndex(i, j).controls.businessGroup
                                                )
                                            }}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="formRow">
        <div class="col-12 text-right">
            <button
                mat-button
                type="button"
                [disabled]="isSaving"
                color="accent"
                class="text-uppercase"
                (click)="onCancel()"
            >
                Cancel
            </button>
            <button
                mat-button
                type="submit"
                [disabled]="!diversionForm.valid || isSaving"
                color="accent"
                class="text-uppercase"
            >
                Save Diversion
            </button>
        </div>
    </div>
</form>
