<div *ngIf="!selectedFastrac">Loading...</div>
<div *ngIf="!!selectedFastrac">
    <div class="sticky-top mat-elevation-z6">
        <mat-toolbar class="fastrac-header-bar bds-toolbar-blue">
            <mat-toolbar-row>
                <span>
                    <fa-icon [icon]="iconFastrac" [fixedWidth]="true" size="lg"></fa-icon>
                    {{ selectedFastrac.name }}
                    <button
                        mat-icon-button
                        *ngIf="!selectedFastrac.isClassic && selectedFastrac.ownerId === user.name"
                        [class.litFavIcon]="selectedFastrac.isFavorite"
                        (click)="toggleFavorite()"
                    >
                        <fa-icon
                            *ngIf="
                                !selectedFastrac.isFavorite && selectedFastrac.ownerId === user.name
                            "
                            [icon]="iconFavoriteNo"
                            size="lg"
                        ></fa-icon>
                        <fa-icon
                            *ngIf="
                                selectedFastrac.isFavorite && selectedFastrac.ownerId === user.name
                            "
                            [icon]="iconFavoriteYes"
                            size="lg"
                        ></fa-icon>
                    </button>
                    <button
                        mat-icon-button
                        *ngIf="
                            !selectedFastrac.isClassic &&
                            selectedFastrac.ownerId !== 'BDS' &&
                            selectedFastrac.ownerId !== user.name
                        "
                    >
                        <fa-icon
                            *ngIf="selectedFastrac.ownerId !== user.name"
                            [icon]="iconShared"
                            size="lg"
                        ></fa-icon>
                    </button>
                    <!-- <span *ngIf="isDirty">*</span>
                    <span *ngIf="isSaving">
                    <small>
                        (saving...)
                        <fa-icon [icon]="iconSpinner" [spin]="true"></fa-icon>
                    </small>
                    </span> -->
                </span>
                <span class="fill-remaining-space"></span>
                <span>
                    <a
                        mat-button
                        [routerLink]="['/trips', { fId: selectedFastrac.id }]"
                        [disabled]="fastracIsDirty"
                        target="_blank"
                        ><fa-icon [icon]="iconRunFastrac" size="lg"></fa-icon> Run FASTRAC</a
                    >
                    <button
                        mat-button
                        (click)="onSaveClick()"
                        [disabled]="
                            selectedFastrac.isClassic ||
                            selectedFastrac.ownerId === 'BDS' ||
                            selectedFastrac.ownerId !== user.name
                        "
                    >
                        <fa-icon [icon]="iconSave" size="lg"></fa-icon> Save
                    </button>
                    <button mat-button (click)="onSaveAsClick()">
                        <fa-icon [icon]="iconSaveAs" size="lg"></fa-icon> Save As
                    </button>
                    <button
                        mat-button
                        (click)="onRenameClick()"
                        [disabled]="
                            selectedFastrac.isClassic ||
                            selectedFastrac.ownerId === 'BDS' ||
                            selectedFastrac.ownerId !== user.name
                        "
                    >
                        <fa-icon [icon]="iconRename" size="lg"></fa-icon> Rename
                    </button>
                    <button
                        mat-button
                        (click)="onDeleteClick()"
                        [disabled]="
                            selectedFastrac.isClassic ||
                            selectedFastrac.ownerId === 'BDS' ||
                            selectedFastrac.ownerId !== user.name
                        "
                    >
                        <fa-icon [icon]="iconDelete" size="lg"></fa-icon> Delete
                    </button>
                    <button
                        mat-button
                        (click)="onShareClick()"
                        [disabled]="
                            selectedFastrac.isClassic ||
                            selectedFastrac.ownerId === 'BDS' ||
                            selectedFastrac.ownerId !== user.name
                        "
                    >
                        <fa-icon [icon]="iconShare" size="lg"></fa-icon> Share
                    </button>
                    <!-- <button mat-icon-button *ngIf="allowPopout" (click)="popoutClicked('trip')" title="Open Trip in New Tab"><fa-icon [icon]="iconPopout"></fa-icon></button> -->
                    <button mat-icon-button [matMenuTriggerFor]="fastracOptionsMenu">
                        <fa-icon [icon]="iconVerticalEllipsis" size="lg"></fa-icon>
                    </button>
                    <!-- <button mat-icon-button (click)="tripSidenav.toggle()"><fa-icon [icon]="iconMenu"></fa-icon></button> -->
                </span>
            </mat-toolbar-row>
        </mat-toolbar>
        <!-- <div class="container-fluid jump-nav">
            <mat-chip-list>
                <mat-chip bdsScrollSpyLink="tripshipmentdetailsanchor" color="accent" [selected]="(scrollSpyService.currentSection | async) === 'tripshipmentdetailsanchor'">Shipment Details</mat-chip>
                <mat-chip bdsScrollSpyLink="tripproductshippedanchor" color="accent" [selected]="(scrollSpyService.currentSection | async) === 'tripproductshippedanchor'">Products Shipped</mat-chip>
                <mat-chip bdsScrollSpyLink="tripclmanchor" (click)="tripclmpanel.open()" color="accent" [selected]="(scrollSpyService.currentSection | async) === 'tripclmanchor'">CLM History</mat-chip>
                <mat-chip bdsScrollSpyLink="triprouteanchor" (click)="triproutepanel.open()" color="accent" [selected]="(scrollSpyService.currentSection | async) === 'triprouteanchor'">Route</mat-chip>
                <mat-chip bdsScrollSpyLink="tripcommentsanchor" (click)="tripcommentspanel.open()" color="accent" [selected]="(scrollSpyService.currentSection | async) === 'tripcommentsanchor'">Comments</mat-chip>
            </mat-chip-list>
        </div> -->
    </div>
    <mat-menu #fastracOptionsMenu="matMenu" xPosition="before">
        <button
            mat-menu-item
            (click)="onSaveClick()"
            [disabled]="
                selectedFastrac.isClassic ||
                selectedFastrac.ownerId === 'BDS' ||
                selectedFastrac.ownerId !== user.name
            "
        >
            <fa-icon [icon]="iconSave"></fa-icon> Save
        </button>
        <button mat-menu-item (click)="onSaveAsClick()">
            <fa-icon [icon]="iconSaveAs"></fa-icon> Save As
        </button>
        <button
            mat-menu-item
            (click)="onRenameClick()"
            [disabled]="
                selectedFastrac.isClassic ||
                selectedFastrac.ownerId === 'BDS' ||
                selectedFastrac.ownerId !== user.name
            "
        >
            <fa-icon [icon]="iconRename"></fa-icon> Rename
        </button>
        <button
            mat-menu-item
            (click)="onDeleteClick()"
            [disabled]="
                selectedFastrac.isClassic ||
                selectedFastrac.ownerId === 'BDS' ||
                selectedFastrac.ownerId !== user.name
            "
        >
            <fa-icon [icon]="iconDelete"></fa-icon> Delete
        </button>
    </mat-menu>

    <!--<bds-fastrac-design-page [showMenu]="false"
                             [showFastrac]="true"
                             [gridId]="selectedFastrac.gridId"
                             [fastracId]="selectedFastrac.id"
                             [(fastrac)]="selectedFastrac"
                             [(dirty)]="fastracIsDirty"
                             [dateFormat]="dateFormat">
    </bds-fastrac-design-page>-->

    <bds-fastrac-design-display
        [fastracId]="selectedFastrac.id"
        [gridId]="selectedFastrac.gridId"
        [showFastrac]="true"
    >
    </bds-fastrac-design-display>
</div>
