// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {
    BdsBusinessGroupApiServiceOptions,
    RtClmApiServiceOptions,
    //BdsCommodityClassApiServiceOptions,
    BdsOriginCriteriaApiServiceOptions,
    BdsFleetApiServiceOptions,
    //BdsHazmatApiServiceOptions,
    RtTripApiServiceOptions,
    RtJeopardizedShipmentApiServiceOptions,
    RtCommodityShippedApiServiceOptions,
} from '@bds/railtrac';
//import { RtRouteApiServiceOptions } from '@bds/data-access';
//import { BdsDestinationCriteriaApiServiceOptions } from '@bds/destination-criteria';
import {
    FormlyServiceApiServiceOptions,
    RtPrefRouteServiceApiServiceOptions,
    RtRouteDetailServiceOptions,
    RtRoutePathCodeServiceApiServiceOptions,
    RtRouteSegmentsApiServiceOptions,
} from '@bds/route';
import { BdsPathCodeApiServiceOptions } from '@bds/route';
import { PageServiceOptions } from '@bds/core';
import { AppConfig } from '@bds/app-settings';
import {
    BdsErpcOriginApiServiceOptions,
    BdsSupplierApiServiceOptions,
    //BdsCommodityApiServiceOptions,
    //BdsStateApiServiceOptions,
    RtCarAssignApiServiceOptions,
    RtCarMechApiServiceOptions,
    RtTripCommentServiceOptions,
    RtvCarHotListGridApiServiceOptions,
    //BdsCustomerApiServiceOptions,
} from '@bds/data-access';
import { BdsShipmentTypeApiServiceOptions } from '@bds/data-access';
import { BdsSysDefApiServiceOptions } from '@bds/sysdef';
import { BdsFastracBuilderApiServiceOptions } from '@bds/services';

const odatabaseUrl = 'https://localhost:44312/odata/';
const apibaseUrl = 'https://localhost:44311/api/';
const internalApibaseUrl = 'https://internal-api.test.railcl.com';
const devExpressBaseUrl = 'https://localhost:44311/dx/';

export const environment = {
    name: 'Development',
    isProduction: false,
    // bdsCustomerApiServiceOptions: {
    //     apiUrl: `~api~/customer`,
    //     odataUrl: `~odata~/customer`,
    // } as BdsCustomerApiServiceOptions,
    // bdsCommodityApiServiceOptions: {
    //     apiUrl: `~api~/commodity`,
    //     odataUrl: `~odata~/commodity`,
    // } as BdsCommodityApiServiceOptions,
    // bdsCommodityClassApiServiceOptions: {
    //     apiUrl: `~api~/commodityclass`,
    //     odataUrl: `~odata~/commodityclass`,
    // } as BdsCommodityClassApiServiceOptions,
    bdsBusinessGroupApiServiceOptions: {
        apiUrl: '',
        odataUrl: `~odata~/businessgroup`,
    } as BdsBusinessGroupApiServiceOptions,
    // bdsDestinationCriteriaApiServiceOptions: {
    //     apiUrl: `~api~/destinationcriteria`,
    //     odataUrl: `~odata~/DestinationCriteria`,
    // } as BdsDestinationCriteriaApiServiceOptions,
    bdsSysDefApiServiceOptions: {
        apiUrl: `~api~/sysdef`,
    } as BdsSysDefApiServiceOptions,
    bdsOriginCriteriaApiServiceOptions: {
        apiUrl: `~api~/origincriteria`,
        odataUrl: `~odata~/OriginCriteria`,
    } as BdsOriginCriteriaApiServiceOptions,
    bdsErpcOriginApiServiceOptions: {
        apiUrl: `~api~/ErpcOrigin`,
        odataUrl: `~odata~/ErpcOrigin`,
    } as BdsErpcOriginApiServiceOptions,
    // bdsSplcErpcApiServiceOptions: {
    //     apiUrl: ``,
    //     odataUrl: `~odata~/RtSplcErpc`,
    // } as BdsSplcErpcApiServiceOptions,
    bdsFleetApiServiceOptions: {
        apiUrl: '',
        odataUrl: `~odata~/fleet`,
    } as BdsFleetApiServiceOptions,
    // bdsHazmatApiServiceOptions: {
    //     apiUrl: '',
    //     odataUrl: `~odata~/hazmat`,
    // } as BdsHazmatApiServiceOptions,
    // bdsStateApiServiceOptions: {
    //     apiUrl: '',
    //     odataUrl: `~odata~/state`,
    // } as BdsStateApiServiceOptions,
    bdsSupplierApiServiceOptions: {
        apiUrl: '',
        odataUrl: `~odata~/supplier`,
    } as BdsSupplierApiServiceOptions,
    leafletSateliteLayerOptions: {
        // eslint-disable-next-line max-len
        attribution:
            'Tiles © Esri — Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        mapUrl: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    },
    leafletStreetLayerOptions: {
        attribution:
            '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        mapUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    },
    tripGridApiServiceOptions: {
        apiUrl: '',
        odataUrl: `~odata~/tripgrid`,
    },
    rtClmApiServiceOptions: {
        apiUrl: `~api~/clm`,
        odataUrl: `~odata~/clm`,
        sightCodeUrl: `~api~/clmsightcode`,
        latestByTripUrl: `~api~/clm/trip/`,
    } as RtClmApiServiceOptions,
    rtCommentTypeApiServiceOptions: {
        apiUrl: `~api~/tripcomment`,
        odataUrl: `~odata~/commenttype`,
        odataCommentTypesUrl: `~odata~/commenttype`,
        odataDefaultCommentsUrl: `/defaultcomments`,
    } as RtTripCommentServiceOptions,
    rtCarHotlistApiServiceOptions: {
        apiUrl: `~api~/carhotlist`,
        odataUrl: `~odata~/carhotlist`,
        tripUrl: `~odata~/tripDetail`,
    },
    rtCarHotlistGridApiServiceOptions: {
        apiUrl: '',
        odataUrl: `~odata~/carhotlistgrid`,
    } as RtvCarHotListGridApiServiceOptions,
    rtCityAliasServiceOptions: {
        apiUrl: `~api~/cityalias`,
        odataUrl: `~odata~/cityalias`,
    },
    rtCustomerAliasServiceOptions: {
        apiUrl: `~api~/customeralias`,
        odataUrl: `~odata~/customeralias`,
    },
    // rtRouteApiServiceOptions: {
    //     apiUrl: '',
    //     odataUrl: `~odata~/route`,
    // } as RtRouteApiServiceOptions,
    rtTripApiServiceOptions: {
        apiUrl: `~api~/trip`,
        odataUrl: `~odata~/trip`,
        odataTripUrl: `~odata~/tripDetail`,
        odataTripCommentsUrl: `/tripComments`,
        odataCommoditiesShippedUrl: `/commoditiesShipped`,
        apiJeopardizedShipmentsUrl: `~api~/jeopardizedshipment`,
        apiCombinedTripUrl: `~api~/uniquetrip`,
    } as RtTripApiServiceOptions,
    rtTripDiversionServiceOptions: {
        apiUrl: `~api~/diversion`,
        odataUrl: `~odata~/diversion`,
    },
    rtRouteParticipatingRailroadServiceOptions: {
        apiUrl: `~api~/ParticipatingRailroad`,
        odataUrl: `~odata~/ParticipatingRailroad`,
    },
    rtJeopardizedShipmentApiServiceOptions: {
        apiUrl: '',
        odataUrl: `~odata~/jeopardizedshipment`,
        destinationAliasOdataUrl: '',
        participatingRailroadOdataUrl: '',
    } as RtJeopardizedShipmentApiServiceOptions,
    rtCommodityShippedApiServiceOptions: {
        apiUrl: `~api~/commodityshipped/`,
        odataUrl: `~odata~/commodityshipped`,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
        canPatch: false,
        combinedApi: `~api~/uniquecommodityshipped`,
    } as RtCommodityShippedApiServiceOptions,
    bdsFastracBuilderApiServiceOptions: {
        apiUrl: `~api~/fastracitems`,
        apiSharedUserUrl: `~api~/fastracsharedusers`,
        odataUrl: `~odata~/fastrac`,
        odataSharedUserPath: `sharedusers`,
        odataSetSharedUserAction: `setsharedusers`,
    } as BdsFastracBuilderApiServiceOptions,
    pageServiceOptions: {
        applicationName: 'RAILTRAC®',
    } as PageServiceOptions,
    bdsShipmentTypeApiServiceOptions: {
        odataUrl: `~odata~/shipmenttype`,
    } as BdsShipmentTypeApiServiceOptions,
    rtCarAssignApiServiceOptions: {
        odataUrl: `~odata~/RtCarAssign`,
    } as RtCarAssignApiServiceOptions,
    rtCarMechApiServiceOptions: {
        odataUrl: `~odata~/RtCarMech`,
    } as RtCarMechApiServiceOptions,
    bdsPathCodeApiServiceOptions: {
        odataUrl: `~odata~/routepathCode`,
    } as BdsPathCodeApiServiceOptions,
    bdsSegmentMilesApiServiceOptions: {
        odataUrl: `~odata~/segmentmiles`,
        apiUrl: `~api~/segmentmiles`,
    } as BdsPathCodeApiServiceOptions,
    rtPrefRouteServiceApiServiceOptions: {
        odataUrl: `~odata~/prefroute`,
        apiUrl: `~api~/prefroute`,
    } as RtPrefRouteServiceApiServiceOptions,
    rtRouteSegmentsApiServiceOptions: {
        odataUrl: `~odata~/routesegment`,
        apiUrl: `~api~/routesegment`,
    } as RtRouteSegmentsApiServiceOptions,
    rtRouteDetailServiceOptions: {
        odataUrl: `~odata~/routedetail`,
        apiUrl: `~api~/routedetail`,
    } as RtRouteDetailServiceOptions,
    rtRoutePathCodeServiceApiServiceOptions: {
        apiUrl: `~api~/routepathcode/`,
        odataUrl: `~odata~/routepathcode`,
    } as RtRoutePathCodeServiceApiServiceOptions,

    rtFormlyServiceApiServiceOptions: {
        apiUrl: `~api~/FormlyForms/`,
        odataUrl: `~odata~/FormlyForms`,
    } as FormlyServiceApiServiceOptions,

    appConfig: {
        authorizeOptions: {
            applicationName: 'railtrac-app',
            authorityUri: 'https://auth.test.railcl.com',
            scope: [
                'openid',
                'profile',
                'customers',
                'railtrac-api',
                'railtrac-odata',
                'shipperbi-api',
                'bds-app',
                'bds-internal-api',
                'bds-internal-api.read-applications',
                'bds-internal-api.read-customers',
                'bds-internal-api.read-users',
            ],
            redirectUri: '~/authentication/login-callback',
            postLogoutRedirectUri: '~/authentication/logout-callback',
            silentRedirectUri: '~/silent-refresh.html',
        },
        apiOptions: {
            apiBaseUrl: apibaseUrl,
            odataBaseUrl: odatabaseUrl,
            internalApiBaseUrl: internalApibaseUrl,
        },
        environmentOptions: {
            environmentName: 'Development',
            isProduction: false,
            applicationCode: 'RT',
            applicationName: 'RAILTRAC',
            applicationBaseUrl: '',
            BDSINTERNAL_API_BASE_URL: 'https://api-core.test.railcl.com',
        },
        changelogInfo: {
            currentChangelogUrl: 'https://localhost/changelog-1.4.4.pdf',
            nextReleaseVersion: '1.5.0',
            nextChangelogUrl: 'https://localhost/changelog-1.5.0.pdf',
            showReleaseAnnouncement: false,
            nextReleaseAnnouncement:
                'We will be performing maintenance on January 1, 2023 at 12:00 AM. Expected downtime should be less than 30 minutes.',
            nextReleaseHashCode: 123456,
        },
        custom: {
            devExpressBaseUrl: devExpressBaseUrl,
            shipperBiOptions: {
                shipperBiApiUri: 'https://bi-api.test.railcl.com',
            },
            tempReportId: '01577e4f-6811-417f-b450-a064aa4445fc',
            tempWorkspaceId: 'bc94ac43-430a-4f9e-b1a3-de399e39a815',
        } as any,
    } as AppConfig,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
