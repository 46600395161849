import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { CLMFilterMetadata, CLMFilterModel } from '../models/clm-filter-parameters.model';
import { nameof } from '@bds/core';

@Injectable({
    providedIn: 'root',
})
export class ClmFilterFormService implements OnDestroy {
    unsub$ = new Subject<void>();

    private readonly _form: UntypedFormGroup;

    constructor(clmFilterMetadata: CLMFilterMetadata) {
        this._form = clmFilterMetadata.getFormGroup();
    }

    //#region "FormControls"
    get carInit(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<CLMFilterModel>('carInit'));
    }

    get carNo(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<CLMFilterModel>('carNo'));
    }

    get clmFromDate(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<CLMFilterModel>('clmFromDate'));
    }

    get isEdi(): UntypedFormControl {
        return <UntypedFormControl>this._form.get(nameof<CLMFilterModel>('clmToDate'));
    }

    get form(): UntypedFormGroup {
        return this._form;
    }

    //#endregion "FormControls"

    ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.complete();
    }
}

