import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata } from '@bds/core';
import { RtRouteCode } from './rt-route-code';
import { RtCustomerFact } from './rt-customer-fact';

export class RtPreferredRoute {
    constructor(
        public ormId?: number,
        public customerNo?: string,
        public originSplc?: string,
        public routeCode?: string,
        public rtRouteCode?: RtRouteCode,
        public customerFact?: RtCustomerFact,
    ) {}
    from(route: RtPreferredRoute): RtPreferredRoute {
        Object.keys(route).forEach((k) => (this[k] = route[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtPreferredRoute>('ormId'), server: 'ormId' },
    { client: nameof<RtPreferredRoute>('customerNo'), server: 'customerNo' },
    { client: nameof<RtPreferredRoute>('originSplc'), server: 'originSplc' },
    { client: nameof<RtPreferredRoute>('routeCode'), server: 'routeCode' },
];

@Injectable({
    providedIn: 'root',
})
export class RtPreferredRouteAdapter extends TwoWayAdapter<RtPreferredRoute> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtPreferredRouteMetadata implements FormGroupDescription {
    constructor() {}

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtPreferredRoute>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtPreferredRoute>('customerNo'),
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<RtPreferredRoute>('originSplc'),
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<RtPreferredRoute>('routeCode'),
            new UntypedFormControl('', [Validators.required]),
        );
        if (obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}

