<dx-data-grid
    bds-grid
    #bdsCustomerAliasGrid
    [bdsUseZeroPadDates]="true"
    id="bdsCustomerAliasGrid"
    [dataSource]="gridSource"
    height="100%"
    minHeight="200px"
    [(selectedRowKeys)]="selectedRowKeys"
    [bdsCustomMenuItems]="customButtons"
>
    <dxo-selection
        mode="multiple"
        [allowSelectAll]="false"
        showCheckBoxesMode="none"
    ></dxo-selection>
    <dxi-column dataField="custNo" caption="Consignee No."></dxi-column>
    <dxi-column dataField="custName" caption="Consignee Name"></dxi-column>
    <dxi-column dataField="custCity" caption="Consignee City"></dxi-column>
    <dxi-column dataField="custState" caption="State"></dxi-column>
</dx-data-grid>
<!-- <div>
    <button mat-fab color="primary" (click)="createCustomerAlias()" style="position: absolute; right: 16px; bottom: 16px;">
        <fa-icon [icon]="iconPlus"></fa-icon>
    </button>
</div> -->
