import { Component, OnInit } from '@angular/core';
import { ReportConstants } from '../../models/report-parameters.model';


@Component({
  selector: 'lib-rt-outbound-by-customer-product-report',
  templateUrl: './rt-outbound-by-customer-product-report.component.html',
  styleUrls: ['./rt-outbound-by-customer-product-report.component.css']
})
export class RtOutboundByCustomerProductReportComponent implements OnInit {

    public gridId = 120;
    public apiCall: string = ReportConstants.ReportDataApiCall;
    public pageHeader: string = 'Outbound At Customer By Customer & Product Report';

  constructor() { }

  ngOnInit(): void {
  }

}
