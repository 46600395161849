<div>
    <dx-data-grid id="bdsReportGrid" [dataSource]="gridSource" height="80vh" minHeight="200px">
        <dxi-column dataField="orig_city" caption="Origin"></dxi-column>
        <dxi-column dataField="orig_state" caption=""></dxi-column>
        <dxi-column dataField="car_init" caption="Car Init"></dxi-column>
        <dxi-column dataField="car_no" caption="Car No."></dxi-column>
        <dxi-column dataField="ship_date_time" caption="Ship Date"></dxi-column>
        <dxi-column dataField="elapsed_days" caption="Elapsed Days'"></dxi-column>
        <dxi-column dataField="loc_city" caption="Location"></dxi-column>
        <dxi-column dataField="loc_state" caption=""></dxi-column>
        <dxi-column dataField="clm_date_time" caption="CLM Date"></dxi-column>
        <dxi-column dataField="sight_code" caption="Code"></dxi-column>
        <dxi-column dataField="road" caption="Road"></dxi-column>
        <dxi-column dataField="le" caption="L/E"></dxi-column>
        <dxi-column dataField="orig_eda_eta_cust" caption="ETA to Cust"></dxi-column>
        <dxi-column dataField="dest_city" caption="Destination"></dxi-column>
        <dxi-column dataField="dest_state" caption=""></dxi-column>
        <dxi-column dataField="trip_no" caption="Trip No."></dxi-column>
        <dxi-column dataField="comm_code" caption="Comm Code"></dxi-column>
        <dxi-column dataField="comm_name" caption="Comm Name"></dxi-column>
    </dx-data-grid>
</div>
