import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges,
    EventEmitter,
    OnDestroy,
    Self,
    Optional,
    SkipSelf,
} from '@angular/core';
import { RtCommodityShipped } from '@bds/railtrac-models';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { MatSelectChange } from '@angular/material/select';
import { RtCommodityShippedForm } from '../railtrac-trip/rt-commodity-shipped-form.service';
import { RtUnitMeasure } from '@bds/models';
import { RtUnitMeasureService } from '@bds/services';

@Component({
    selector: 'rt-trip-commodity-shipped',
    templateUrl: './rt-trip-commodity-shipped.component.html',
    styleUrls: ['./rt-trip-commodity-shipped.component.scss'],
    providers: [RtCommodityShippedForm],
})
export class RtTripCommodityShippedComponent implements OnInit, OnChanges, OnDestroy {
    commodityShippedForm = new UntypedFormGroup({});

    groupIndent = 'pl-0';
    headerSize = 'h3';
    matInputStyle = 'fill';
    matResultStyle = 'standard';
    matFloatLabel = 'always';
    showCommodityFact = false;
    moreLessLabel = 'More';

    @Input() commodityShipped: RtCommodityShipped;
    @Output() commodityShippedChange = new EventEmitter<RtCommodityShipped>();

    @Input() dirty: boolean;
    @Output() dirtyChange = new EventEmitter<boolean>();

    @Output() dirtyValues = new EventEmitter<Map<string, any> | any[] | any | undefined>();
    @Input() highlightDirty = false;

    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() dense = true;

    private unsub$ = new Subject<void>();
    private rtCommodityShippedForm: RtCommodityShippedForm;
    unitMeasureLookup: Observable<RtUnitMeasure[]>;

    constructor(
        @SkipSelf() @Optional() parentRtCommodityShippedForm: RtCommodityShippedForm,
        @Self() rtCommodityShippedForm: RtCommodityShippedForm,
        unitMeasureService: RtUnitMeasureService,
    ) {
        this.rtCommodityShippedForm = parentRtCommodityShippedForm || rtCommodityShippedForm;
        this.unitMeasureLookup = unitMeasureService.getAll();
    }

    ngOnInit() {
        console.log('Rendering = ' + this.commodityShipped?.commodityCompart);
        this.commodityShippedForm = this.rtCommodityShippedForm.getCommodityShippedBulkForm();

        this.commodityShippedForm.valueChanges.pipe(takeUntil(this.unsub$)).subscribe((s) => {
            this.commodityShippedChange.emit(this.commodityShippedForm.value);

            // TODO Figure out a better way to avoid code to Emit Dirty
            if (this.commodityShippedForm.dirty) {
                this.dirtyChange.emit(this.commodityShippedForm.dirty);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const dirtyChange = changes['dirty'];
        if (dirtyChange && !dirtyChange.currentValue) {
            this.commodityShippedForm.markAsPristine();
        }
        const commodityShippedChange = changes['commodityShipped'];
        if (
            !!commodityShippedChange &&
            commodityShippedChange.currentValue &&
            !isEqual(commodityShippedChange.currentValue, this.commodityShippedForm.value)
        ) {
            this.commodityShippedForm.reset();
            this.rtCommodityShippedForm.setCommodityShippedBulkForm(
                commodityShippedChange.currentValue,
            );
        }
    }

    toggleCommodityFact() {
        this.showCommodityFact = !this.showCommodityFact;
        this.moreLessLabel = this.showCommodityFact ? 'Less' : 'More';
    }

    ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.complete();
    }
}
