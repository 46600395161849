import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class RtCommentType implements From<RtCommentType> {
    constructor(public category?: string, public description?: string) {}
    from(trip: RtCommentType): RtCommentType {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtCommentType>('category'), server: 'category' },
    { client: nameof<RtCommentType>('description'), server: 'description' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCommentTypeAdapter extends TwoWayAdapter<RtCommentType> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtCommentTypeMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        var columns = [
            {
                dataField: nameof<RtCommentType>('category'),
                dataType: 'string',
                caption: 'Category',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommentType>('description'),
                dataType: 'string',
                caption: 'description',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }
    getFormGroup(obj?: any): UntypedFormGroup {
        var formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtCommentType>('category'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCommentType>('description'), new UntypedFormControl(''));
        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
