import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FastracScreenBaseComponent } from '@bds/fastrac';
import { FastracColsService } from '@bds/services';
import { CommunicationService, nameof } from '@bds/core';
import { CarIdTransformService } from '@bds/helpers';
import { bdsGridModeType } from '@bds/components';
import { RailtracReportsService } from '../../railtrac-reports.service';
import { PageService } from '@bds/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ReportConstants, ReportParameterModel } from '../../models/report-parameters.model';

@Component({
  selector: 'lib-rt-transit-time-rs-summary',
  templateUrl: './rt-transit-time-rs-summary.component.html',
  styleUrls: ['./rt-transit-time-rs-summary.component.css']
})
export class RtTransitTimeRsSummaryComponent extends FastracScreenBaseComponent
    implements OnInit, OnDestroy {
    
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    reportForm: UntypedFormGroup;

    public gridId = 112;
    private reportName = this.gridId.toString();    
    public gridMode: bdsGridModeType = 'single';
    public gridSource: any;
    public selectedRowKeys: Array<number> = [];
    public controlNames: string[] = ['Ship Begin Date', 'Ship End Date'];

    public displayReport: boolean = false;
    public startDate: string;
    public endDate: string;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public gridColumnService: FastracColsService,
        public communicationService: CommunicationService,
        public carIdTransformService: CarIdTransformService,
        public reportService: RailtracReportsService,
        public pageService: PageService) { 
            super(router, route, gridColumnService, communicationService, carIdTransformService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.pageService.setHeader("Transit Time By Route Segment Summary Report"); 
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    onGetReportClick(event: any) {
        this.displayReport = true;
        this.gridSource = this.reportService.CreateAspNetDatastore(ReportConstants.ReportDataApiCall,
            this.gridId.toString(),
            event.startDate,
            event.endDate
        );
    }

    onCancelClick() {        
        this.displayReport = false;
    }
}
