import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata, From } from '@bds/core';

export class BlEdiContactCode implements From<BlEdiContactCode> {
    constructor(public ediContactCode?: string, public description?: string) {}
    from(trip: BlEdiContactCode): BlEdiContactCode {
        Object.keys(trip).forEach((k) => (this[k] = trip[k]));
        return this;
    }
}

export const relationship: IModelMetadata[] = [
    { client: nameof<BlEdiContactCode>('ediContactCode'), server: 'ediContactCode' },
    { client: nameof<BlEdiContactCode>('description'), server: 'description' },
];

@Injectable({
    providedIn: 'root',
})
export class BlEdiContactCodeAdapter extends TwoWayAdapter<BlEdiContactCode> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class BlEdiContactCodeMetadata implements FormGroupDescription {
    constructor() {}

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(
            nameof<BlEdiContactCode>('ediContactCode'),
            new UntypedFormControl('', [Validators.required, Validators.maxLength(2)]),
        );
        formGroup.addControl(
            nameof<BlEdiContactCode>('description'),
            new UntypedFormControl('', [Validators.maxLength(50)]),
        );

        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
