<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<div *ngIf="displayReport">
    <div class="railroad-grid-container">
        <dx-data-grid
            id="bdsReportGrid"
            [dataSource]="gridSource"
            height="80vh"
            minHeight="200px"
            (onCellPrepared)="onCellPrepared($event)"
        >
            <dxi-column
                dataField="carID"
                cellTemplate="carIDTemplate"
                caption="Car ID"
                width="auto"
                [minWidth]="100"
                allowSearch="true"
            ></dxi-column>

            <div *dxTemplate="let item of 'carIDTemplate'">
                <p class="">{{ item.data.car_init }} {{ item.data.car_no }}</p>
            </div>
            <dxi-column
                dataField="Origin"
                cellTemplate="originTemplate"
                caption="Origin"
                width="auto"
                [minWidth]="100"
                allowSearch="true"
            ></dxi-column>

            <div *dxTemplate="let item of 'originTemplate'">
                <p class="">{{ item.data.orig_city }} {{ item.data.orig_state }}</p>
            </div>
            <dxi-column
                dataField="Dest"
                cellTemplate="destTemplate"
                caption="Destination"
                width="auto"
                [minWidth]="100"
                allowSearch="true"
            ></dxi-column>

            <div *dxTemplate="let item of 'destTemplate'">
                <p class="">{{ item.data.dest_city }} {{ item.data.dest_state }}</p>
            </div>

            <dxi-column dataField="fleet_id" dataType="string" caption="Fleet"></dxi-column>
            <dxi-column dataField="business_group" dataType="string" caption="Grp"></dxi-column>
            <dxi-column dataField="ship_date_time" dataType="date" caption="Ship Date"></dxi-column>
            <dxi-column
                dataField="dest_arr_date_time"
                dataType="date"
                caption="Dest Arrv"
            ></dxi-column>
            <dxi-column
                dataField="cust_rel_date_time"
                dataType="date"
                caption="Consign Rel"
            ></dxi-column>
            <dxi-column dataField="comm_code" dataType="string" caption="Commodity"></dxi-column>
            <dxi-column
                dataField="Consignee"
                cellTemplate="consigneeTemplate"
                caption="Consignee"
                width="auto"
                [minWidth]="100"
                allowSearch="true"
            ></dxi-column>

            <div *dxTemplate="let item of 'consigneeTemplate'">
                <p class="">{{ item.data.cust_no }} {{ item.data.cust_name }}</p>
            </div>
            <dxi-column caption="Days" alignment="center">
                <dxi-column caption="Held" dataField="days_held" dataType="number"></dxi-column>
                <dxi-column caption="Free" dataField="days_free" dataType="number"></dxi-column>
                <dxi-column caption="Over" dataField="days_charged" dataType="number"></dxi-column>
            </dxi-column>

            <dxi-column dataField="rate" dataType="currency" caption="Daily Rent">
                <dxo-format type="currency" [precision]="2"> </dxo-format>
            </dxi-column>
            <dxi-column dataField="amount" dataType="currency" caption="Excs Rent">
                <dxo-format type="currency" [precision]="2" valueFormat="$#0.##;($#0.##)">
                </dxo-format
            ></dxi-column>
            <dxi-column dataField="bol_no" dataType="string" caption="BOL No."></dxi-column>
            <dxi-column dataField="cust_po_no" dataType="string" caption="Cust PO No."></dxi-column>
            <dxo-summary>
                <dxi-total-item displayFormat="Total:" showInColumn="carID"></dxi-total-item>
                <dxi-total-item displayFormat="Avg:" showInColumn="carID"></dxi-total-item>
                <dxi-total-item displayFormat="Min:" showInColumn="carID"></dxi-total-item>
                <dxi-total-item displayFormat="Max:" showInColumn="carID"></dxi-total-item>
                <dxi-total-item
                    column="days_held"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInColumn="days_held"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_free"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInColumn="days_free"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_charged"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInColumn="days_charged"
                ></dxi-total-item>
                <dxi-total-item
                    column="amount"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInColumn="amount"
                    valueFormat="$#0.##;($#0.##)"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_held"
                    summaryType="avg"
                    displayFormat="{0}"
                    showInColumn="days_held"
                    valueFormat="###0.##"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_free"
                    summaryType="avg"
                    displayFormat="{0}"
                    showInColumn="days_free"
                    valueFormat="###0.##"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_charged"
                    summaryType="avg"
                    displayFormat="{0}"
                    showInColumn="days_charged"
                    valueFormat="###0.##"
                ></dxi-total-item>
                <dxi-total-item
                    column="amount"
                    summaryType="avg"
                    displayFormat="{0}"
                    showInColumn="amount"
                    valueFormat="$#0.##;($#0.##)"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_held"
                    summaryType="min"
                    displayFormat="{0}"
                    showInColumn="days_held"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_free"
                    summaryType="min"
                    displayFormat="{0}"
                    showInColumn="days_free"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_charged"
                    summaryType="min"
                    displayFormat="{0}"
                    showInColumn="days_charged"
                ></dxi-total-item>
                <dxi-total-item
                    column="amount"
                    summaryType="min"
                    displayFormat="{0}"
                    showInColumn="amount"
                    valueFormat="$#0.##;($#0.##)"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_held"
                    summaryType="max"
                    displayFormat="{0}"
                    showInColumn="days_held"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_free"
                    summaryType="max"
                    displayFormat="{0}"
                    showInColumn="days_free"
                ></dxi-total-item>
                <dxi-total-item
                    column="days_charged"
                    summaryType="max"
                    displayFormat="{0}"
                    showInColumn="days_charged"
                ></dxi-total-item>
                <dxi-total-item
                    column="amount"
                    summaryType="max"
                    displayFormat="{0}"
                    showInColumn="amount"
                    valueFormat="$#0.##;($#0.##)"
                ></dxi-total-item>
            </dxo-summary>
        </dx-data-grid>
    </div>
</div>
