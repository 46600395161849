import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    SimpleChanges,
    EventEmitter,
    Output,
} from '@angular/core';
import { RtTrip, RtClm } from '@bds/railtrac-models';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';

export type RtJeopardizedShipmentResolutions =
    | 'hotlist'
    | 'addComment'
    | 'divert'
    | 'addDestinationBypass'
    | 'addParticipatingRoad'
    | 'newTrip'
    | 'removeFleet'
    | 'addOriginCriteria'
    | 'addDestinationCriteria';

@Component({
    selector: 'rt-jeopardized-shipment-reason-options',
    templateUrl: './rt-jeopardized-shipment-reason-options.component.html',
    styleUrls: ['./rt-jeopardized-shipment-reason-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RtJeopardizedShipmentReasonOptionsComponent implements OnChanges {
    @Input() trip: Observable<RtTrip> | RtTrip;
    @Input() clms: Observable<RtClm[]> | RtClm[];
    @Output() change: EventEmitter<void> = new EventEmitter<void>();
    @Output() resolve: EventEmitter<RtJeopardizedShipmentResolutions> =
        new EventEmitter<RtJeopardizedShipmentResolutions>();

    currentTripId: number;
    iconMore = faEllipsisV;

    private trip$: BehaviorSubject<RtTrip> = new BehaviorSubject<RtTrip>(null);
    private clms$: BehaviorSubject<RtClm[]> = new BehaviorSubject<RtClm[]>(null);
    private currentClm$: BehaviorSubject<RtClm> = new BehaviorSubject<RtClm>(null);

    constructor(public dialog: MatDialog, public snackbar: MatSnackBar) {}

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes['trip']) {
            const tripCurrVal: Observable<RtTrip> | RtTrip = changes['trip'].currentValue;
            if (tripCurrVal instanceof RtTrip) {
                this.currentTripId = tripCurrVal.ormId;
                this.trip$.next(tripCurrVal);
            } else {
                tripCurrVal.pipe(take(1)).subscribe((itm) => {
                    this.currentTripId = itm.ormId;
                    return this.trip$.next(itm);
                });
            }
        }
        if (!!changes['clms']) {
            const clmsCurrVal: Observable<RtClm[]> | RtClm[] = changes['clms'].currentValue;
            if (Array.isArray(clmsCurrVal)) {
                this.clms$.next(clmsCurrVal);
                this.currentClm$.next(this.findCurrentClm(clmsCurrVal));
            } else {
                clmsCurrVal.pipe(take(1)).subscribe((itm) => {
                    this.clms$.next(itm);
                    this.currentClm$.next(this.findCurrentClm(itm));
                });
            }
        }
    }

    private findCurrentClm(clms: RtClm[]) {
        return clms.reduce((p, c) => (!p ? c : p.clmDateTime < c.clmDateTime ? c : p));
    }

    addToHotlist(): void {
        this.resolve.emit('hotlist');
    }

    createDestinationBypass(): void {
        this.resolve.emit('addDestinationBypass');
    }

    createComment(): void {
        this.resolve.emit('addComment');
    }

    addParticipatingRailroad(): void {
        this.resolve.emit('addParticipatingRoad');
    }

    removeFromFleet(): void {
        this.resolve.emit('removeFleet');
    }

    newTrip(): void {
        this.resolve.emit('newTrip');
    }

    addOriginCriteria(): void {
        this.resolve.emit('addOriginCriteria');
    }

    addDestinationCriteria(): void {
        this.resolve.emit('addDestinationCriteria');
    }
}
