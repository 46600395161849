import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BdsDialogConfirmComponent, BdsDialogConfirmModel } from '@bds/components';
import { RtDestinationAliasDialogComponent } from '@bds/railtrac';
import { RtDestinationAliasService } from '@bds/data-access';
import { RtDestinationAlias } from '@bds/railtrac-models';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import { Observable, forkJoin } from 'rxjs';
import { PageService } from '@bds/core';

@Component({
    templateUrl: './destination-alias-screen.component.html',
    styleUrls: ['./destination-alias-screen.component.scss'],
})
export class DestinationAliasScreenComponent implements OnInit {
    public gridSource: DataSource | RtDestinationAlias[];
    public selectedRowKeys: Array<number | any> = [];

    @ViewChild('bdsDestinationAliasGrid') grid: DxDataGridComponent;

    constructor(
        public service: RtDestinationAliasService,
        public pageService: PageService,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
    ) {
        pageService.setHeader('Destination Aliases');
    }

    ngOnInit() {
        this.gridSource = this.service.getODataStore();
    }

    createRecord() {
        let x: RtDestinationAlias = new RtDestinationAlias();

        const dialogRef = this.dialog.open(RtDestinationAliasDialogComponent, {
            width: '550px',
            data: { item: x },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (!!result) {
                this.service.create(result).subscribe(
                    (res) => {
                        this.snackbar.open('Destination alias added', 'Ok', { duration: 3000 });
                    },
                    (err) => {
                        this.snackbar.open('Adding destination alias failed', 'Ok', {
                            duration: 8000,
                        });
                    },
                    () => {
                        this.grid.instance.refresh();
                    },
                );
            }
            x = result;
        });
    }

    deleteRecords($event: any) {
        const confirmInfo: BdsDialogConfirmModel = {
            title: 'Delete Destination Aliases',
            content: `Are you sure you want to delete ${this.selectedRowKeys.length} destination aliases?`,
            actionText: 'Delete Aliases',
        };

        const dialogRef = this.dialog.open(BdsDialogConfirmComponent, {
            data: confirmInfo,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const svcCalls: Observable<boolean>[] = [];
                this.selectedRowKeys.forEach((key) => {
                    svcCalls.push(this.service.delete(key));
                });
                forkJoin(svcCalls).subscribe(
                    (res) => {
                        if (res.some((sm) => sm !== true)) {
                            this.snackbar.open(
                                'Some destination aliases could not be deleted',
                                'Ok',
                                { duration: 8000 },
                            );
                        } else {
                            this.snackbar.open('Selected destination aliases deleted', 'Ok', {
                                duration: 3000,
                            });
                        }
                    },
                    (err) => {
                        this.snackbar.open('Some destination aliases encountered errors', 'Ok', {
                            duration: 8000,
                        });
                        console.warn('Some destination aliases encountered errors', err);
                    },
                    () => {
                        this.grid.instance.refresh();
                    },
                );
            }
        });
    }
}
