<rt-report-parameters
    [controlNames]="controlNames"
    (getReport)="onGetReportClick($event)"
    (cancelReport)="onCancelClick()"
>
</rt-report-parameters>
<!-- <div class="container-fluid">
    <div class="col-12" [formGroup]="reportForm">
        <div class="row">
            <div class="col-3">
                <div class="dx-field-label">Trip Close Begin Date</div>
                <dx-date-box
                    name="startDate"
                    formControlName="startDate"
                    class="w-100 bds-dx-custom-material"
                    [stylingMode]="dxMatStyle"
                    type="date"
                    displayFormat="MM/dd/yy"
                    label="MM/dd/yy"
                    [showClearButton]="true"
                    [useMaskBehavior]="true"
                >
                </dx-date-box>
            </div>
            <div class="col-3">
                <div class="dx-field-label">Trip Close End Date</div>
                <dx-date-box
                    name="endDate"
                    formControlName="endDate"
                    class="w-100 bds-dx-custom-material"
                    [stylingMode]="dxMatStyle"
                    type="date"
                    displayFormat="MM/dd/yy"
                    label="MM/dd/yy" 
                    [showClearButton]="true"
                    [useMaskBehavior]="true"                   
                >
                </dx-date-box>
            </div>
            <div class="col-1">
                <button mat-button class="text-uppercase" color="accent" (click)="onGetReportClick()">
                Get Report
                </button>
            </div>
            <div class="col-1">
                <button mat-button class="text-uppercase" color="accent" (click)="onCancelClick()">
                Cancel
                </button>
            </div>
        </div>
    </div>
</div> -->
<div *ngIf="displayReport">
    <div class="container-fluid">
        <bds-fastrac-design-display
            [gridId]="gridId"
            [fastracId]="fastracId"
            [showFastrac]="showFastrac"
            [showGrouping]="true"
            (showFastracChange)="onFastracVisibleChanged($event)"
            (fastracIdChange)="onFastracIdChanged($event)"
            (fastracDesignsChange)="onFastracDesignsChanged($event)"
            (applyFastrac)="onApplyFastrac($event)"
        >
        </bds-fastrac-design-display>
    </div>

    <div class="railroad-grid-container">
        <dx-data-grid
            bds-grid
            #bdsReportGrid
            id="bdsReportGrid"
            [dataSource]="gridSource"
            [(selectedRowKeys)]="selectedRowKeys"
            height="80vh"
            minHeight="200px"
            [gridId]="gridId"
            (bdsShowFastrac)="onShowFastrac()"
            (fastracIdChange)="onGridFastracIdChanged($event)"
            [bdsFastracDesignList]="fastracList"
            [bdsFastracVisible]="showFastrac"
            [bdsUseFastrac]="true"
            [fastracDesign]="currentFastrac"
            [fastracId]="fastracId"
            [bdsCustomizeColumns]="customizeColumns"
            [(bdsGridMode)]="gridMode"
            [bdsAllowAddRecord]="false"
            [bdsAllowDeleteRecords]="false"
            [bdsAllowGrouping]="true"
            [bdsUseZeroPadDates]="true"
        >
            <dxo-summary>
                <dxi-total-item
                    column="unit_train_yorn"
                    displayFormat="Totals:"
                    alignment="left"
                ></dxi-total-item>
                <dxi-total-item
                    column="no_of_trips"
                    summaryType="sum"
                    displayFormat="{0}"
                ></dxi-total-item>
                <dxi-total-item
                    column="avg_loaded"
                    summaryType="avg"
                    valueFormat="###0.##"
                    displayFormat="Avg: {0}"
                    alignment="left"
                ></dxi-total-item>
                <dxi-total-item
                    column="avg_at_cust"
                    summaryType="avg"
                    valueFormat="###0.##"
                    displayFormat="{0}"
                ></dxi-total-item>
                <dxi-total-item
                    column="avg_unloaded"
                    summaryType="avg"
                    valueFormat="###0.##"
                    displayFormat="{0}"
                ></dxi-total-item>
                <dxi-total-item
                    column="total_days"
                    summaryType="avg"
                    valueFormat="###0.##"
                    displayFormat="{0}"
                ></dxi-total-item>
                <dxi-total-item
                    column="avg_idle_days"
                    summaryType="avg"
                    valueFormat="###0.##"
                    displayFormat="{0}"
                ></dxi-total-item>
                <dxi-total-item
                    column="cycle_days"
                    summaryType="avg"
                    valueFormat="###0.##"
                    displayFormat="{0}"
                ></dxi-total-item>
            </dxo-summary>
        </dx-data-grid>
    </div>
</div>
