<div class="row">
    <div *ngIf="errors && errors.length" class="error-warning">
        <div *ngFor="let error of errors">{{ error.errorMessage }}</div>
    </div>
</div>
<div class="container-fluid">
    <bds-fastrac-design-display
        [gridId]="gridId"
        [fastracId]="fastracId"
        [showFastrac]="showFastrac"
        (showFastracChange)="onFastracVisibleChanged($event)"
        (fastracIdChange)="onFastracIdChanged($event)"
        (fastracDesignsChange)="onFastracDesignsChanged($event)"
        (applyFastrac)="onApplyFastrac($event)"
    >
    </bds-fastrac-design-display>
</div>
<bds-grid-details>
    <ng-container grid>
        <bds-fastrac-dx-grid-wrapper
            #dxGridWrapper
            bdsScrollSpySection="bdsTripGrid"
            (bdsAddRecordClicked)="createRecord()"
            (bdsDeleteRecordsClicked)="deleteTrips()"
            (bdsShowFastrac)="onShowFastrac()"
            (fastracIdChange)="onGridFastracIdChanged($event)"
            [selectionMode]="gridMode ?? 'multiple'"
            [bdsAllowAddRecord]="true"
            [bdsAllowDeleteRecords]="true"
            [bdsAllowCloneRecords]="true"
            (bdsCloneRecordsClicked)="copyCarsToClipboard()"
            bdsCloneRecordsHint="Copy cars"
            [bdsCustomizeColumns]="customizeColumns"
            [bdsFastracDesignList]="fastracList"
            [bdsFastracVisible]="showFastrac"
            [bdsUseFastrac]="true"
            [bdsUseZeroPadDates]="true"
            [fastracDesign]="currentFastrac"
            [fastracId]="fastracId"
            id="bdsTripGrid"
            [gridId]="gridId"
            [dataSource]="gridSource"
            [(selectedRowKeys)]="selectedRowKeys"
            (selectedRowKeysChange)="navigate($event)"
            [key]="'ormId'"
            [showColumnChooser]="true"
        ></bds-fastrac-dx-grid-wrapper>
    </ng-container>
    <ng-container details>
        <router-outlet></router-outlet>
    </ng-container>
</bds-grid-details>
