<dx-lookup
    floatLabel="always"
    [stylingMode]="'filled'"
    [class.bds-mat-dense-form-field]="true"
    class="bds-dx-custom-material flex-fill bds-readonly-wrapper"
    name="roadReturnSplc"
    [(ngModel)]="value"
    [dataSource]="dataSource"
    [valueExpr]="key"
    [displayExpr]="displayExpr"
    [searchEnabled]="true"
    searchTimeout="200"
    itemTemplate="item"
    fieldTemplate="field"
    label="{{ placeholder }}"
    [disabled]="disabled"
    (onValueChanged)="valueChanged()"
>
    <div *dxTemplate="let data of 'field'">
        <div class="dx-placeholder">
            {{ placeholder }}
        </div>
        <div class="custom-icon" *ngIf="data">
            {{ getDisplayValue(data) }}
        </div>
    </div>
    <div *dxTemplate="let data of 'item'">
        <div class="custom-icon">
            {{ defaultDisplay(data) }}
        </div>
    </div>
</dx-lookup>
<ng-content></ng-content>
<div></div>
