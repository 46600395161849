<mat-toolbar style="font-size: 0.66rem" class="mat-elevation-z3">
    <button
        mat-button
        (click)="reapplyClms()"
        [disabled]="
            reapplyClmsLoading ||
            !clm ||
            clm?.clmType?.toString() !== '67' ||
            selectedRowKeys.length > 1
        "
    >
        <fa-icon [icon]="iconReapply"></fa-icon>
        Reapply CLMs
        <fa-icon *ngIf="reapplyClmsLoading" [icon]="iconSpinner" [spin]="true"></fa-icon>
    </button>
    <button mat-button (click)="addClm()">
        <fa-icon [icon]="iconAdd"></fa-icon>
        Add CLM
    </button>
    <button mat-button (click)="deleteClm()" [disabled]="!isDeleteEnabled">
        <fa-icon [icon]="iconDelete"></fa-icon>
        Delete CLM
    </button>
    <button mat-button (click)="addToDestinationCriteria()" [disabled]="selectedRowKeys.length > 1">
        <fa-icon [icon]="iconPopout"></fa-icon>
        Add to Destination Criteria
    </button>
    <button mat-button (click)="addToOriginCriteria()" [disabled]="selectedRowKeys.length > 1">
        <fa-icon [icon]="iconPopout"></fa-icon>
        Add to Origin Criteria
    </button>
</mat-toolbar>
<bds-grid-details maxHeight="85vh">
    <ng-container grid>
        <bds-fastrac-design-display
            [gridId]="gridId"
            [fastracId]="fastracId"
            [showFastrac]="showFastrac"
            (showFastracChange)="onFastracVisibleChanged($event)"
            (fastracIdChange)="onFastracIdChanged($event)"
            (fastracDesignsChange)="onFastracDesignsChanged($event)"
            (applyFastrac)="onApplyFastrac($event)"
        >
        </bds-fastrac-design-display>
        <bds-fastrac-dx-grid-wrapper
            #dxGridWrapper
            id="bdsClmGrid"
            [dataSource]="dataSource$ | async"
            [(selectedRowKeys)]="selectedRowKeys"
            (selectedRowKeysChange)="onSelectionChanged($event)"
            minHeight="200px"
            [selectionMode]="gridMode ?? 'multiple'"
            [gridId]="gridId"
            (bdsShowFastrac)="onShowFastrac()"
            (fastracIdChange)="onGridFastracIdChanged($event)"
            [bdsFastracDesignList]="fastracList"
            [bdsFastracVisible]="showFastrac"
            [bdsUseFastrac]="true"
            [fastracDesign]="currentFastrac"
            [fastracId]="fastracId"
            [bdsCustomizeColumns]="customizeColumns"
            [(bdsGridMode)]="gridMode"
            [bdsAllowAddRecord]="false"
            [bdsUseZeroPadDates]="true"
            [bdsAllowCloneRecords]="false"
            (bdsCloneRecordsClicked)="copyCarsToClipboard()"
            bdsCloneRecordsHint="Copy cars"
            [key]="'ormId'"
            [showColumnChooser]="true"
        >
        </bds-fastrac-dx-grid-wrapper>
    </ng-container>
    <ng-container details>
        <rt-clm-x *ngIf="selectedRowKeys.length === 1" [clm]="clm"></rt-clm-x>

        <mat-tab-group *ngIf="clms && selectedRowKeys.length > 1">
            <mat-tab *ngFor="let clmItem of clms" [label]="tabLabelString(clmItem)">
                <rt-clm-x [clm]="clmItem"></rt-clm-x>
            </mat-tab>
        </mat-tab-group>
    </ng-container>
</bds-grid-details>
