<div class="sticky-top mat-elevation-z6 equipment-details-menu">
    <mat-toolbar class="commodity-header-bar bds-toolbar-blue">
        <mat-toolbar-row>
            <span> {{ equipment?.equipmentInit }} {{ equipment?.equipmentNo }} </span>
            <span *ngIf="isSaving">
                <small>
                    (saving...)
                    <fa-icon [icon]="iconSpinner" [spin]="true"></fa-icon>
                </small>
            </span>
            <span class="fill-remaining-space"></span>
            <span *ngIf="canSave">
                <button mat-stroked-button (click)="onSaveChanges()" [disabled]="isSaving">
                    <fa-icon [icon]="iconSave"></fa-icon>
                    SAVE EQUIPMENT
                </button>
            </span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="container-fluid jump-nav">
        <mat-chip-listbox>
            <mat-chip-option
                *ngFor="let menuLink of filteredMenuLinks"
                bdsScrollSpyLink="{{ menuLink.jumpLink }}"
                (click)="onSelectElement(menuLink.jumpLink)"
                color="accent"
                [selectable]="false"
                [highlighted]="
                    !menuLink.noSelect
                        ? (scrollSpyService.currentSection | async) === menuLink.jumpLink
                        : null
                "
            >
                {{ menuLink.label }}
                <span *ngIf="menuLink.isDirty">*</span>
            </mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
