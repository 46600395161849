import { Component, Input } from '@angular/core';
import { RtRouteCode } from '@bds/railtrac-models';
import { faGlobeAmericas } from '@fortawesome/pro-light-svg-icons';
import { faArrowsH } from '@fortawesome/pro-regular-svg-icons';
import { faRoute } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'bds-route-menu',
    templateUrl: './bds-route-menu.component.html',
    styleUrls: ['./bds-route-menu.component.css'],
})
export class BdsRouteMenuComponent {
    duoIconRoute = faRoute;
    iconRouteDetail = faGlobeAmericas;
    iconRouteSegments = faArrowsH;
    @Input() route: RtRouteCode;

    // TODO: Add Outputs for buttons

    constructor() {}
}
