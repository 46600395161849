import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { RtTrip, RtTripRefFieldDef } from '@bds/railtrac-models';
import { FormlyFieldCreatorService } from '../services/formly-field-creator.service';

@Component({
    selector: 'rt-trip-additional-ref',
    templateUrl: './rt-trip-additional-ref.component.html',
    styleUrls: ['./rt-trip-additional-ref.component.scss'],
})
export class RtTripAdditionalRefComponent implements OnChanges {
    displayFormat = 'MM/dd/yyyy HH:mm';
    fields: FormlyFieldConfig[] = [];
    formValues = '';
    model: any;
    refForm: UntypedFormGroup = new UntypedFormGroup({});
    serializationFormat: string = 'yyyy-MM-dd HH:mm:ss';

    @Input() refFieldDefs: RtTripRefFieldDef[];
    @Input() trip: RtTrip;
    @Output() additionalRefsChange: EventEmitter<{ model: any; isValid: boolean; changes: any }> =
        new EventEmitter();

    constructor(public formlyFieldCreatorService: FormlyFieldCreatorService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.refFieldDefs) {
            this.createFieldsFromDefs();
        }

        if (changes.trip) {
            this.createModelFromData();
            this.additionalRefsChange.emit({
                model: this.model,
                isValid: this.refForm.valid,
                changes: undefined,
            });
        }
    }

    createFieldsFromDefs(): void {
        const formlyFields: FormlyFieldConfig[] = [];

        if (this.refFieldDefs && this.refFieldDefs.length) {
            // Remove the first 6 fields as these are included in the Trip Details already
            const dynamicFieldDefs: RtTripRefFieldDef[] = this.refFieldDefs.filter(
                (d) => d.lookupTable === 'RT_TRIP_REF_DATA',
            );

            // Reset required expressions
            const requiredExpressions: { id: string; value: string }[] = [];

            // Create each field for that category and push to the fieldGroup
            dynamicFieldDefs.forEach((f) => {
                let formField: FormlyFieldConfig;
                if (f.rtTripFieldValues && f.rtTripFieldValues.length) {
                    formField = this.formlyFieldCreatorService.createSelectFormField(f);
                } else if (f.dataType === 'D') {
                    formField = this.formlyFieldCreatorService.createDateFormGroup(
                        f,
                        this.displayFormat,
                        this.serializationFormat,
                    );
                } else if (f.dataType === 'N') {
                    formField = this.formlyFieldCreatorService.createAlphnumericFormField(f);
                } else {
                    formField = this.formlyFieldCreatorService.createAlphnumericFormField(f);
                }

                if (f.requiredRefField) {
                    requiredExpressions.push({
                        id: f.requiredRefField,
                        value: `!!model.${f.tripRefField}`,
                    });
                }

                formlyFields.push(formField);
            });

            // Go through the required expressions and tie to the appropriate field within Trip Refs in this table
            requiredExpressions.forEach((e) => {
                const fieldIndex: number = formlyFields.findIndex((f) => f.key === e.id);
                if (fieldIndex > -1) {
                    formlyFields[fieldIndex].expressionProperties['templateOptions.required'] =
                        e.value;
                }
            });
        }

        this.fields = [
            {
                fieldGroupClassName: 'row',
                fieldGroup: [...formlyFields],
            },
        ];
    }

    createModelFromData(): void {
        if (this.trip) {
            const generatedModel = {};

            Object.keys(this.trip).forEach((key) => {
                if (key.startsWith('trip_ref')) {
                    // trim off "trip_ref" and make sure number is > 6
                    const refNum: number = parseInt(key.substring(8), 10);

                    if (refNum > 6) {
                        generatedModel[key] = this.trip[key];
                    }
                }
            });

            this.model = generatedModel;
        } else {
            this.model = {};
        }
    }

    getDirtyValues(
        form: UntypedFormGroup | UntypedFormArray | UntypedFormControl,
    ): Map<string, any> | any[] | any | undefined {
        if (!form.dirty) {
            return;
        }

        if (form instanceof UntypedFormControl) {
            return form.value;
        }

        if (form instanceof UntypedFormGroup) {
            const result = new Map();

            for (const [key, control] of Object.entries(form.controls)) {
                const formControl = control as
                    | UntypedFormGroup
                    | UntypedFormArray
                    | UntypedFormControl;
                const nestedResult = this.getDirtyValues(formControl);
                if (nestedResult) {
                    result.set(key, this.getDirtyValues(formControl));
                }
            }

            return result;
        }

        if (form instanceof UntypedFormArray) {
            const result = new Array();
            form.controls.forEach((control) => {
                const formControl = control as
                    | UntypedFormGroup
                    | UntypedFormArray
                    | UntypedFormControl;
                const nestedResult = this.getDirtyValues(formControl);
                if (nestedResult) {
                    result.push(nestedResult);
                }
            });

            return result;
        }
    }

    onModelChanged(event): void {
        if (!this.refForm.valid) {
            this.refForm.markAllAsTouched();
        }

        const changes: Map<string, any> | any[] | any | undefined = this.getDirtyValues(
            this.refForm,
        );
        this.additionalRefsChange.emit({
            model: event,
            isValid: this.refForm.valid,
            changes: changes,
        });
    }
}
