import { Injectable } from '@angular/core';
import { FormGroupDescription, GridColumnDescription, IColumnDescription } from './adapter_t';
import { IModelMetadata, nameof, TwoWayAdapter } from '@bds/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export interface RtCommodityShippedUK {
    carInit?: string;
    carNo?: string;
    shipDate?: Date;
    commodityCompart?: number;
    ormId?: number;
    rtTripOrmId?: number;
    commodityAmt?: number;
    commodityUnits?: string;
    commodityRef1?: string;
    commodityRef2?: string;
    commodityRef3?: string;
    commodityRef4?: string;
}

export class RtCommodityShipped implements RtCommodityShippedUK {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public commodityCompart?: number,
        public shipDate?: Date,
        public commodityCode?: string,
        public commodityAmt?: number,
        public commodityUnits?: string,
        public commodityRef1?: string,
        public commodityRef2?: string,
        public commodityRef3?: string,
        public commodityRef4?: string,
        public ormId?: number,
        public rtTripOrmId?: number,
        public userId?: string,
        public updateDateTime?: Date,
    ) {}

    static from(trip: RtCommodityShipped): RtCommodityShipped {
        const obj = new RtCommodityShipped();
        Object.keys(trip).forEach((k) => (obj[k] = trip[k]));
        return obj;
    }
}

export var relationship: IModelMetadata[] = [
    { client: nameof<RtCommodityShipped>('carInit'), server: 'carInit' },
    { client: nameof<RtCommodityShipped>('carNo'), server: 'carNo' },
    { client: nameof<RtCommodityShipped>('commodityCompart'), server: 'commodityCompart' },
    { client: nameof<RtCommodityShipped>('shipDate'), server: 'shipDate' },
    { client: nameof<RtCommodityShipped>('commodityCode'), server: 'commodityCode' },
    { client: nameof<RtCommodityShipped>('commodityAmt'), server: 'commodityAmt' },
    { client: nameof<RtCommodityShipped>('commodityUnits'), server: 'commodityUnits' },
    { client: nameof<RtCommodityShipped>('commodityRef1'), server: 'commodityRef1' },
    { client: nameof<RtCommodityShipped>('commodityRef2'), server: 'commodityRef2' },
    { client: nameof<RtCommodityShipped>('commodityRef3'), server: 'commodityRef3' },
    { client: nameof<RtCommodityShipped>('commodityRef4'), server: 'commodityRef4' },
    { client: nameof<RtCommodityShipped>('ormId'), server: 'ormId' },
    { client: nameof<RtCommodityShipped>('rtTripOrmId'), server: 'rtTripOrmId' },
    { client: nameof<RtCommodityShipped>('userId'), server: 'userId' },
    { client: nameof<RtCommodityShipped>('updateDateTime'), server: 'updateDateTime' },
];

@Injectable({
    providedIn: 'root',
})
export class RtCommodityShippedAdapter extends TwoWayAdapter<RtCommodityShipped> {
    constructor() {
        super();
    }

    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtCommodityShippedMetadata implements GridColumnDescription, FormGroupDescription {
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        const columns = [
            {
                dataField: nameof<RtCommodityShipped>('carInit'),
                dataType: 'string',
                caption: 'carInit',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('carNo'),
                dataType: 'string',
                caption: 'carNo',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('commodityCompart'),
                dataType: 'number',
                caption: 'commodityCompart',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('shipDate'),
                dataType: 'Date',
                caption: 'shipDate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('commodityCode'),
                dataType: 'string',
                caption: 'commodityCode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('commodityAmt'),
                dataType: 'number',
                caption: 'commodityAmt',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('commodityUnits'),
                dataType: 'string',
                caption: 'commodityUnits',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('commodityRef1'),
                dataType: 'string',
                caption: 'commodityRef1',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('commodityRef2'),
                dataType: 'string',
                caption: 'commodityRef2',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('commodityRef3'),
                dataType: 'string',
                caption: 'commodityRef3',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('commodityRef4'),
                dataType: 'string',
                caption: 'commodityRef4',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('ormId'),
                dataType: 'number',
                caption: 'ormId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('rtTripOrmId'),
                dataType: 'number',
                caption: 'rtTripOrmId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('userId'),
                dataType: 'string',
                caption: 'userId',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtCommodityShipped>('updateDateTime'),
                dataType: 'Date',
                caption: 'updateDateTime',
                visible: defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtCommodityShipped>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCommodityShipped>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCommodityShipped>('commodityAmt'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCommodityShipped>('commodityCode'),
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(nameof<RtCommodityShipped>('commodityCompart'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCommodityShipped>('commodityRef1'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCommodityShipped>('commodityRef2'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCommodityShipped>('commodityRef3'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCommodityShipped>('commodityRef4'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCommodityShipped>('commodityUnits'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCommodityShipped>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtCommodityShipped>('rtTripOrmId'),
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(
            nameof<RtCommodityShipped>('shipDate'),
            new UntypedFormControl('', [Validators.required]),
        );
        formGroup.addControl(nameof<RtCommodityShipped>('updateDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtCommodityShipped>('userId'), new UntypedFormControl(''));

        if (!!obj) {
            formGroup.setValue(obj);
        }
        return formGroup;
    }
}
