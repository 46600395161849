import {
    AfterViewInit,
    Component,
    DoCheck,
    Inject,
    InjectionToken,
    Input,
    OnInit,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import * as moment from 'moment';
import { DxDateBoxComponent } from 'devextreme-angular';
import { tap } from 'rxjs';
import { DatePipe } from '@angular/common';

export function toDatabaseTime(date: Date | string = new Date()): string {
    return moment(date).utc(true).toISOString();
}

export abstract class BdsAbstractControlValueAccessor<T> implements ControlValueAccessor {
    val!: T;

    get value(): T {
        return this.val;
    }

    set value(value: T) {
        this.val = value;
        this.onChange(this.value);
        this.onTouch(this.value);
    }

    protected constructor(public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    onChange(value: T): void {}

    onTouch(value: T): void {}

    registerOnChange(fn: (value: T) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: (value: T) => void): void {
        this.onTouch = fn;
    }

    writeValue(obj: T): void {
        this.val = obj;
        this.val = this.value;
    }
}

export const BDS_DATE_PICKER_CONFIG = new InjectionToken<BDS_DATE_PICKER_CONFIG>(
    'BDS_DATE_PICKER_CONFIG',
);

export interface BDS_DATE_PICKER_CONFIG {
    displayFormat: string;
    dateSerializationFormat: string;
    showClearButton: boolean;
    useMaskBehavior: boolean;
}

@Component({
    selector: 'bds-date-time-picker',
    templateUrl: './bds-date-time-picker.component.html',
    styleUrls: ['./bds-date-time-picker.component.css'],
    providers: [DatePipe],
})
export class BdsDateTimePickerComponent
    extends BdsAbstractControlValueAccessor<string>
    implements DoCheck
{
    @ViewChild('datebox') dateBox: DxDateBoxComponent;
    @Input() type: 'date' | 'time' | 'datetime' = 'datetime';
    @Input() acceptsCustomValue = true;
    @Input() showClearButton = true;
    @Input() useMaskBehavior = true;
    @Input() isDefaultTime = false;
    @Input() readOnly = false;
    @Input() disabled = false;
    @Input() isValid = true;

    @Input() displayFormat = 'shortdate';
    @Input() label: string;
    @Input() min: Date | string | number | undefined = undefined;
    @Input() max: Date | string | number | undefined = undefined;
    @Input() dateSerializationFormat: string | undefined = undefined;
    @Input() disabledDates: Array<Date> | undefined = undefined;

    formControl = new FormControl();
    tempDateTime: Date;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() @Inject('BDS_DATE_PICKER_CONFIG') appConfig: BDS_DATE_PICKER_CONFIG,
        private datePipe: DatePipe,
    ) {
        super(ngControl);

        if (appConfig?.displayFormat) {
            this.displayFormat = appConfig.displayFormat;
        }
        if (appConfig?.dateSerializationFormat) {
            this.dateSerializationFormat = appConfig.dateSerializationFormat;
        }
        if (appConfig?.showClearButton) {
            this.showClearButton = appConfig.showClearButton;
        }
        if (appConfig?.useMaskBehavior) {
            this.useMaskBehavior = appConfig.useMaskBehavior;
        }

        this.formControl.valueChanges
            .pipe(
                tap((response) => {
                    this.setValue(response);
                }),
            )
            .subscribe();
    }

    ngDoCheck(): void {
        const dxDateTimeDispay = this.datePipe.transform(this.value, this.displayFormat, 'UTC');
        const formControlDisplay = this.datePipe.transform(
            this.formControl.value,
            this.displayFormat,
        );
        if (dxDateTimeDispay !== formControlDisplay) {
            const dxDateTimeDispay = this.datePipe.transform(this.value, this.displayFormat, 'UTC');
            this.formControl.patchValue(dxDateTimeDispay);
        }
    }

    onDataChange(): void {
        if (!this.formControl.value) {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            this.formControl.patchValue(now);
            this.tempDateTime = now;
        }
    }

    onOpened($event): void {
        if ($event?.event) {
            return;
        }
        this.tempDateTime = null;
        if (this.isDefaultTime && !this.formControl.value) {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            this.formControl.patchValue(now);
            this.tempDateTime = now;
        }
    }

    get _innerDisabled() {
        return this.ngControl?.disabled || this.disabled;
    }

    setValue($event) {
        this.value = toDatabaseTime($event);
    }
}
