import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bds-hotlist',
    template: ` <bds-hotlist-grid></bds-hotlist-grid> `,
    styles: [],
})
export class BdsHotlistComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
